import { CAREER_SITE_IDENTIFIERS, COMMON_GLOBAL_FILTERS, LOOKER_FILTER_KEYS } from "../../constants/AppConstants"
import { getModel } from "../../utils/helpers"

export const getFiltersForDashboard = (filters) => {
  const csFilterKeys = [
    ...CAREER_SITE_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => ![LOOKER_FILTER_KEYS.DASHBOARD_ID, LOOKER_FILTER_KEYS.FILTER_ID].includes(item))
  ]

  const csFilters = csFilterKeys.reduce((acc, key) => {
    if (!!filters?.[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {})

  return csFilters
}

export const buildPayload = (filters, field, models, identifiers) => {
  if (!models) return null
  const payload = {
    "model": getModel(models, CAREER_SITE_IDENTIFIERS.modelKey),
    "view": "career_site_events",
    "fields": [
      field
    ],
    "filters": {
      "career_site_events.event_month": filters[LOOKER_FILTER_KEYS.DATE] || "this month",
      "career_site_events.account_id": filters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      "career_site_events.sub_account_id": filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      "career_site_events.website_id": filters[LOOKER_FILTER_KEYS.SITE_ID],
    },
    "sorts": [],
    "limit": "-1",
    "total": false,
    "cache": true
  }

  return payload
}

export const isProductLocked = ({
  products,
  hierarchy,
  globalFilters,
  currentProduct
}) => {
  return !products?.length ||
    !hierarchy ||
    !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
    !products.find(
      (item) => item.productKey === currentProduct?.productKey
    ) ||
    !hierarchy?.[currentProduct?.productKey]?.find(
      (item) => item?.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]
    );
}

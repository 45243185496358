import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const SidebarContainer = styled.div`
  width: ${(props) => (!!props.isExpanded ? "14rem" : "5rem")};
  transition: width 0.3s ease-out;
  background: #fff;
  padding: 1rem;
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  height: calc(100vh - 56px);
  z-index: 9;

  &:hover {
    .CollapseToggleContainer {
      display: flex;
    }
  }

  .CollapseToggleContainer {
    display: none;
  }

  @media screen and ${devices.mobile} {
    display: none;
  }
`;
export const CollapseToggleContainer = styled.div`
  position: absolute;
  top: 1.8rem;
  right: -0.75rem;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #0000000f;
  padding: ${(props) => (!!props.isExpanded ? "0 0.1rem 0 0" : "0 0 0 0.1rem")};
  padding-left: 0.1rem;
  cursor: pointer;

  &:hover {
    background: #e4e8ff;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0.5rem 1rem 0.3rem 1rem;

  span {
    padding-top: 0.4rem;
  }
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  color: #3d4759;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 56px);
`;
export const OverviewContainer = styled.div`
  img {
    height: 20px;
    width: 20px;
  }
`;
export const MediaPlannerLOGOContainer = styled.div`
  margin-top: auto;
  img {
    height: 20px;
    width: 24px;
  }
`;
export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SubContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

import React from 'react';

import { BadgeContainer } from "./styles"

const Badge = ({ type = "active" }) => {
  return (
    <BadgeContainer type={type}>
      {type}
    </BadgeContainer>
  )
}

export default Badge;

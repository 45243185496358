import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useShallow } from 'zustand/react/shallow';

import {
  JobQualityContainer,
  FiltersContainer,
  GroupFilter,
  ScoreContainer,
  BetaText,
  InfoContainer,
  Arrow,
  Link,
  InfoTitle,
  Header,
  ContentContainer,
  JobsContainer,
} from "./styles";

import Icon from "../../components/common/Icon";
import Title from "../../components/common/Title";
import SearchBox from "../../components/common/SearchBox";
import JobQualityWidget from "../../widgets/Programmatic/JobQualityWidget"
import JobDetails from "./JobDetails";
import ParameterVsScore from "./ParameterVsScore";
import Modal from "../../components/common/Modal";
import Dialog from "../../components/common/Dialog";
import JobOptimisation from "./JobOptimisation";
import GroupDropdown from "./GroupDropdown";
import ScoreModal from "./ScoreModal";

import { JOB_QUALITY_CONFIG } from "../../configs/Programmatic/JobQuality";
import {
  useFetchJobInsights,
  useFetchJobsAggregated,
  useFetchJobsOverview,
} from "../../api/jobs";
import {
  AGGREGATED_TYPE,
  CAMPAIGN_GROUP,
  INSIGHTS_TYPE,
  JOBGROUP_GROUP,
  JOBS_API_CONFIG,
  JOBS_BREAKDOWN_JOB_TITLE_KEY,
  JOB_AGGREGATED_CAMPAIGN_FIELD,
  JOB_AGGREGATED_JOBGROUP_FIELD,
  SCORE_TYPE,
} from "./constants";
import {
  buildPayload,
  parseFilters,
  parseJobInsights,
  parseJobScore,
} from "./helpers";
import {
  DEVICE_SIZES_PX,
  LOOKER_FILTER_INITIAL_VALUES,
  LOOKER_FILTER_KEYS,
  MIXPANEL_ATTRIBUTES_KEY,
  UNIFIED_ANALYTICS_FEEDBACK_EMAIL,
} from "../../constants/AppConstants";
import useOnClickOutside from "../../utils/useOnClickOutside";
import Mixpanel from "../../services/MixPanel";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { PROGRAMMATIC_ROUTE } from "../../constants/AppRoutes";

const ICON_STYLE = { cursor: "pointer" };

const GROUP_OPTIONS = [
  { id: 1, label: JOBGROUP_GROUP },
  { id: 2, label: CAMPAIGN_GROUP },
];

const BETA_TEXT = [
  "We are improving the way to score jobs quality. If you have any feedback to share, ",
  "Click here",
];

const JobQuality = () => {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const { models } = useGlobalDataStore(
    useShallow((state) => ({
      models: state.models,
    }))
  );
  const { setEmbedUrl, globalFilters } = useGlobalFiltersStore(
    useShallow((state) => ({
      setEmbedUrl: state.setEmbedUrl,
      globalFilters: state.globalFilters,
    }))
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [groupFilter, setGroupFilter] = useState(GROUP_OPTIONS[0].label);
  const [isGroupDropdownOpen, toggleGroupDropdown] = useState(false);
  const [isJobExpanded, toggleJobExpansion] = useState(false);
  const [expandedJob, setExpandedJob] = useState(null);
  const [isGetScoreExpanded, toggleGetScore] = useState(false);
  const [scoreJob, setScoreJob] = useState(null);
  const [isBetaPopupExpanded, toggleBetaPopup] = useState(false);
  const popupRef = useRef(null);

  const filters = useMemo(() => {
    const params = globalFilters;
    if (!Object.entries(params || {})?.length)
      return LOOKER_FILTER_INITIAL_VALUES;
    return params;
  }, [globalFilters]);

  const {
    data: { data: jobsAggregated = [] } = {},
    isLoading: isJobsAggregationLoading,
  } = useFetchJobsAggregated(
    buildPayload(filters, JOBS_API_CONFIG[AGGREGATED_TYPE][groupFilter], models),
    parseFilters(filters, JOBS_API_CONFIG[AGGREGATED_TYPE][groupFilter]?.FIELDS)
  );

  const { data: { data: jobScore = [] } = {}, isLoading: isJobsScoreLoading } =
    useFetchJobsOverview(
      buildPayload(filters, JOBS_API_CONFIG[SCORE_TYPE], models),
      parseFilters(filters, JOBS_API_CONFIG.SCORE.FIELDS)
    );

  const {
    data: { data: jobInsights = [] } = {},
    isLoading: isJobsInsightsLoading,
  } = useFetchJobInsights(
    buildPayload(filters, JOBS_API_CONFIG[INSIGHTS_TYPE], models),
    parseFilters(filters, JOBS_API_CONFIG.INSIGHTS.FIELDS)
  );

  const handleNavigateToHome = () => {
    const params = Object.fromEntries([...searchParams]);
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    setEmbedUrl("")
    navigate({
      pathname: PROGRAMMATIC_ROUTE,
      search: urlSearchParams,
    });
  };

  const handleJobExpand = (job) => {
    setExpandedJob(job);
    toggleGetScore(false);
    toggleJobExpansion(true);
    Mixpanel.trackEvent('Job Details Page Opened', {
      Job:
        job?.[JOBS_BREAKDOWN_JOB_TITLE_KEY] || "",
    });
  };

  const handleGetScore = (job) => {
    console.log({ job });
    setScoreJob(job);
    toggleGetScore(true);
  };

  const searchColumn =
    groupFilter === JOBGROUP_GROUP
      ? JOB_AGGREGATED_JOBGROUP_FIELD
      : JOB_AGGREGATED_CAMPAIGN_FIELD;

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const {
      [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
      [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
      [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
    } = params || {};
    if (!(accountId && subAccountId && clientId)) {
      handleNavigateToHome();
    }
  }, [searchParams]);

  useEffect(() => {
    Mixpanel.trackEvent("Detailed Insights Opened", {
      [MIXPANEL_ATTRIBUTES_KEY.WIDGET_NAME]: 'Quality of jobs',
    });
  }, [])

  useOnClickOutside(popupRef, () => {
    toggleBetaPopup(false);
  });

  return (
    <JobQualityContainer>
      <Header>
        <Icon
          name="chevron-left-black"
          style={{ cursor: "pointer" }}
          onClick={handleNavigateToHome}
        />
        <Title
          text={"Quality of jobs"}
          styles={{
            color: "var(--gray-900, #101828)",
            fontSize: "1.25rem",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "2.25rem",
          }}
        />
        <BetaText
          onClick={() => toggleBetaPopup(!isBetaPopupExpanded)}
        >
          beta
          <Icon name="info" alt="info" />
          {isBetaPopupExpanded && (
            <div ref={popupRef}>
              <InfoContainer>
                <InfoTitle>Beta feature</InfoTitle>
                {BETA_TEXT[0]}
                <Link
                  href={`mailto:${UNIFIED_ANALYTICS_FEEDBACK_EMAIL}`}
                >
                  {BETA_TEXT[1]}
                </Link>
              </InfoContainer>
              <Arrow />
            </div>
          )}
        </BetaText>
      </Header>
      <ContentContainer>
        <JobsContainer>
          <FiltersContainer>
            <SearchBox
              placeholder={`Search ${groupFilter.toLocaleLowerCase()}`}
              value={searchQuery}
              className="Job-quality__SearchBox"
              onChange={setSearchQuery}
            />
            <GroupFilter
              onClick={() => toggleGroupDropdown(!isGroupDropdownOpen)}
            >
              <Icon name="group" alt="group by" style={ICON_STYLE} />
              {`Group by: ${groupFilter}`}
              {isGroupDropdownOpen && (
                <GroupDropdown
                  options={GROUP_OPTIONS}
                  selected={groupFilter}
                  className="JobQuality__Group-dropdown"
                  onSelect={(opt) => setGroupFilter(opt.label)}
                  onClose={() => {
                    toggleGroupDropdown(false);
                    setExpandedJob(null);
                  }}
                />
              )}
            </GroupFilter>
          </FiltersContainer>
          <JobDetails
            jobs={jobsAggregated?.filter((item) =>
              item[searchColumn]
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            )}
            filters={filters}
            type={groupFilter}
            isLoading={isJobsAggregationLoading}
            onGetScore={handleGetScore}
            onJobExpand={handleJobExpand}
          />
        </JobsContainer>
        <ScoreContainer>
          <JobQualityWidget
            config={{
              ...JOB_QUALITY_CONFIG,
              showTitle: false,
              viewMore: false,
              showSubtitle: true,
            }}
            data={parseJobScore(jobScore)}
            isLoading={isJobsScoreLoading}
          />
          <ParameterVsScore
            scores={parseJobInsights(jobInsights)}
            isLoading={isJobsInsightsLoading}
          />
        </ScoreContainer>
      </ContentContainer>
      {isJobExpanded && (
        <Modal onClose={() => toggleJobExpansion(false)}>
          <Dialog onClose={() => toggleJobExpansion(false)}>
            <JobOptimisation
              job={expandedJob}
              filters={filters}
              type={groupFilter}
              onClose={() => toggleJobExpansion(false)}
            />
          </Dialog>
        </Modal>
      )}
      {isGetScoreExpanded && (
        <ScoreModal
          job={scoreJob}
          onExpand={handleJobExpand}
          onClose={() => toggleGetScore(false)}
        />
      )}
    </JobQualityContainer>
  );
};

export default JobQuality;

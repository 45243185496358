import styled from "styled-components";
import { devices } from "../../constants/AppConstants";
import { MultiLevelDropdownContainer } from "../common/MultiLevelDropdown/styles";

export const NavbarFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  height: 100%;

  @media screen and ${devices.mobile} {
    .Navbar__dropdown {
      display: none;
    }
  }

  .FilterChangeModal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AccountsDropdown {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 3.5rem;
    min-width: 17.8rem;
  }
`;
export const NavbarFilters = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`
export const NavbarTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  background-color: ${props => props.type === "account" ? "#202E49" : "#313A7C"};
  min-width: 8rem;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.type === "account" ? "#313557" : "#394281"};
  }

  @media screen and ${devices.mobile} {
   &:first-child {
    display: none;
   }
  }
`

export const SelectionText = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: #929AAF;

  @media screen and ${devices.mobile} {
    display: none;
  }
`
export const LabelContainer = styled.div`
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: #E2E6F1;

  .SelectView__arrow {
    transform: ${props => props.isOpen ? "rotate(90deg)" : "rotate(270deg)"};
  }
`

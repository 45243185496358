import React from "react";
import EmptyText from "../EmptyText";
import { EmptyContainer } from "./styles";

const WidgetEmptyState = ({ text = "No data to display" }) => {
  return (
    <EmptyContainer>
      <EmptyText text={text} />
    </EmptyContainer>
  );
};
export default WidgetEmptyState;

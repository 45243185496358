import styled from "styled-components";

export const LockedContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex: 1;
  gap: 0.8rem;
  padding: 0 35%;
  text-align: center;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: -15%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Title = styled.h2`
  color: #3D4759;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Text = styled.p`
   color: #3D4759;
    font-size: 0.9rem;
    font-weight: 400;
`;
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { useShallow } from "zustand/react/shallow";

import {
  ViewMoreContainer,
  FilterContainer,
  ViewMoreDBContainer,
  ViewMoreDb,
  LoaderContainer,
  MultiSelectDropdownContainerMobileView,
  DropdownButton,
  DropdownButtonTitle,
} from "./styles";

import Icon from "../common/Icon";
import Loader from "../common/Loader";
import BottomModal from "../common/BottomModal";
import FilterWidget from "../../widgets/Programmatic/FilterWidget";
import JobCompare from "../JobCompare";

import {
  LOOKER_FILTER_KEYS,
  MIXPANEL_ATTRIBUTES_KEY,
} from "../../constants/AppConstants";

import { parseFilterExpressions } from "../../utils/helpers";
import {
  buildFilterDataPayload,
  getLookerFilterWithoutDashboardId,
} from "./helpers";
import Mixpanel from "../../services/MixPanel";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { useFetchFilterDataViewMore } from "../../api/common";

const ViewMore = ({
  dbDetails,
  advancedFilters,
  advancedFilterOptions,
  isFullPage = false,
  loaderContainerStyle = {},
}) => {
  const {
    dashboards,
    models
  } = useGlobalDataStore(useShallow((state) => ({
    dashboards: state.dashboards,
    models: state.models,
  })))

  const {
    globalFilters
  } = useGlobalFiltersStore()

  const [dashboard, setDashboard] = useState(null);
  const [lookerFilters, setLookerFilters] = useState(null);
  const [viewMoreDbId, setViewMoreDbId] = useState("");
  const [isDbSetupDone, toggleDbSetup] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [jobCompareLinkModalOpen, setJobCompareLinkModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);
  const initializationTimer = useRef(null);
  const runTimer = useRef(null);
  let initializationDuration = null;
  let runDuration = null;

  const {
    data: { data: filterDataRes = [] } = {},
    isLoading: isFilterDataLoading
  } = useFetchFilterDataViewMore(
    buildFilterDataPayload({ dbDetails, globalFilters, models }),
    { dbDetails, globalFilters },
  )

  useEffect(() => {
    if (!!filterDataRes && Array.isArray(filterDataRes)) {
      const filterData = filterDataRes.filter(
        (filterDataValue) => !!filterDataValue?.["filter_data.attributes"]
      );
      setFilterData(filterData);
    }
  }, [filterDataRes])

  const setupDashboard = (d) => {
    console.log("view more dashboard setup done");
    setDashboard(d);
  };

  const filterWidgetFilters = useMemo(() => {
    let filters = null;
    if (!!dbDetails?.filtersConfig?.defaultFilters) {
      filters = dbDetails?.filtersConfig.defaultFilters.map(
        (item) => item.name
      );
    }
    return filters;
  }, [dbDetails?.filtersConfig]);

  useEffect(() => {
    Mixpanel.trackEvent("Detailed Insights Opened", {
      [MIXPANEL_ATTRIBUTES_KEY.WIDGET_NAME]: dbDetails?.title,
    });
  }, [dbDetails]);

  useEffect(() => {
    console.log(
      "setting id for view more",
      dashboards?.[dbDetails?.dashboardKey]?.id,
      dbDetails,
      dashboards
    );
    if (!!dashboards && dbDetails) {
      setViewMoreDbId(dashboards?.[dbDetails?.dashboardKey]?.id);
    }
  }, [dashboards, dbDetails]);

  useEffect(() => {
    console.log("view more db", viewMoreDbId, globalFilters, advancedFilters, dashboard);
    if (!!viewMoreDbId && !!globalFilters && !dashboard) {
      const combined = advancedFilters?.reduce(
        (acc, item) => ({
          ...acc,
          ...parseFilterExpressions(item, advancedFilterOptions),
        }),
        {}
      );
      const initFilters = {
        ...getLookerFilterWithoutDashboardId(globalFilters),
        [LOOKER_FILTER_KEYS.DASHBOARD_ID]: viewMoreDbId,
        ...combined,
        pixelConfig: JSON.stringify({
          width: window.innerWidth,
        }),
      };
      let lookerUrl = window.__RUNTIME_CONFIG__
        ? window.__RUNTIME_CONFIG__.LOOKER_URL
        : "";
      lookerUrl = lookerUrl.startsWith("https")
        ? lookerUrl
        : `https://${lookerUrl}`;
      console.log(
        "Initialising view more dashboard",
        lookerUrl,
        viewMoreDbId,
        initFilters
      );
      // Start initialization timer
      initializationTimer.current = performance.now();
      LookerEmbedSDK.init(lookerUrl);
      LookerEmbedSDK.createDashboardWithId(viewMoreDbId)
        .appendTo(document.getElementById("view-more-db"))
        .withClassName("view-more-db-iframe")
        .withFilters(getLookerFilterWithoutDashboardId(initFilters))
        .on("dashboard:loaded", () => {
          // Calculate initialization time
          initializationDuration = performance.now() - initializationTimer.current;
          console.log("Dashboard loaded, initialization time:", initializationDuration);
        })
        .on("dashboard:run:start", () => {
          console.log("Dashboard run started");
          // Start run timer
          runTimer.current = performance.now();
        })
        .on("dashboard:run:complete", () => {
          console.log("Dashboard run complete");
          // Calculate all timing metrics
          runDuration = performance.now() - runTimer.current;
          const totalTime = initializationDuration + runDuration;

          // Single Mixpanel event with all metrics
          Mixpanel.trackEvent('Dashboard Performance Metrics', {
            dashboardId: viewMoreDbId,
            totalDuration: totalTime,
            initializationDuration,
            runDuration,
          });

          console.log("Dashboard Performance Metrics:", {
            dashboardId: viewMoreDbId,
            totalTime: `${totalTime.toFixed(2)}ms`,
            initializationTime: `${initializationDuration.toFixed(2)}ms`,
            runTime: `${runDuration.toFixed(2)}ms`
          });
          toggleDbSetup(true);
        })
        .on("dashboard:edit:start", () => {
          console.log("Dashboard edit started");
        })
        .on("dashboard:edit:cancel", () => console.log("Dashboard edit cancel"))
        .on("dashboard:save:complete", () => {
          console.log("Dashboard save complete");
        })
        .on("dashboard:delete:complete", () =>
          console.log("Dashboard delete complete")
        )
        .build()
        .connect()
        .then(setupDashboard)
        .catch((error) => {
          console.error("An unexpected error occurred", error);
        });
    }
  }, [
    viewMoreDbId,
    globalFilters,
    advancedFilters,
    dashboard,
    advancedFilterOptions,
  ]);

  useEffect(() => {
    if (isDbSetupDone) {
      if (!!dashboard && (!!lookerFilters || !!globalFilters)) {
        console.log("Updating filters", {
          ...lookerFilters,
          ...globalFilters,
          pixelConfig: JSON.stringify({
            width: window.innerWidth,
          }),
        });
        dashboard.updateFilters({
          ...lookerFilters,
          ...globalFilters,
          pixelConfig: JSON.stringify({
            width: window.innerWidth,
          }),
        });
        dashboard.run();
      }
    }
  }, [lookerFilters, dashboard, globalFilters, isDbSetupDone]);

  useEffect(() => {
    function listener(event) {
      console.log("view more event", event);
      if (event.data?.type === "viewMoreFilters") {
        console.log("Filter change", event.data);
        setLookerFilters((lf) => ({
          ...lf,
          ...event?.data?.payload,
        }));
      }
      if (event.data?.type === "jobCompareLink") {
        console.log("Filter change", event.data);
        setJobCompareLinkModalOpen(true);
        setModalData(event.data.payload);
      }
    }
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);


  const onFilterChange = (filters) => {
    setLookerFilters((lf) => ({
      ...lf,
      ...filters,
    }));
  };

  const handleModalClose = () => {
    setModalData(null);
    setJobCompareLinkModalOpen(false);
  };

  const loaderPosition = (() => {
    if (!isDbSetupDone && isFilterDataLoading) return "centre";
    if (!isFilterDataLoading && !isDbSetupDone) return "right";
    return "left";
  })();

  const handleDropdownOpen = () => {
    setIsDropdownModalOpen(true);
  };

  const handleBottomModalClose = () => {
    setIsDropdownModalOpen(false);
  };

  if (!viewMoreDbId) return null;

  return (
    <ViewMoreContainer isFullPage={isFullPage}>
      <MultiSelectDropdownContainerMobileView>
        <DropdownButton onClick={handleDropdownOpen}>
          <DropdownButtonTitle>
            {dbDetails?.filtersConfig?.title || "Performance Metrics"}
          </DropdownButtonTitle>
          <Icon name="chevron-down" />
        </DropdownButton>
        {isDropdownModalOpen && (
          <BottomModal
            isOpen={isDropdownModalOpen}
            onClose={handleBottomModalClose}
          >
            {!!filterData && !isFilterDataLoading && (
              <FilterWidget
                data={filterData}
                config={dbDetails?.filtersConfig}
                filters={filterWidgetFilters}
                onFilterChange={onFilterChange}
                dashboardKey={dbDetails?.dashboardKey}
                setIsOpen={setIsDropdownModalOpen}
                uiConfig={{ width: window.innerWidth }}
              />
            )}
          </BottomModal>
        )}
      </MultiSelectDropdownContainerMobileView>
      <FilterContainer
        screenWidth={window.innerWidth}
        isFullPage={isFullPage}
        setupDone={!isFilterDataLoading}
      >
        {!!filterData && !isFilterDataLoading && (
          <FilterWidget
            data={filterData}
            config={dbDetails?.filtersConfig}
            filters={filterWidgetFilters}
            onFilterChange={onFilterChange}
            dashboardKey={dbDetails?.dashboardKey}
            widgetTitle={dbDetails?.title}
          />
        )}
        {isFilterDataLoading && isDbSetupDone && (
          <LoaderContainer align="left" style={loaderContainerStyle}>
            <Loader size="medium" />
          </LoaderContainer>
        )}
      </FilterContainer>
      <ViewMoreDBContainer
        screenWidth={window.innerWidth}
        isFullPage={isFullPage}
      >
        {(!isDbSetupDone || (!isDbSetupDone && isFilterDataLoading)) && (
          <LoaderContainer align={loaderPosition} style={loaderContainerStyle}>
            <Loader size="medium" />
          </LoaderContainer>
        )}
        {/* {(!isFilterDataLoading) && <ViewMoreDb id="view-more-db" setupDone={isDbSetupDone} />} */}
        <ViewMoreDb id="view-more-db" setupDone={isDbSetupDone} />
      </ViewMoreDBContainer>
      {jobCompareLinkModalOpen && (
        <JobCompare modalData={modalData} onClose={handleModalClose} />
      )}
    </ViewMoreContainer>
  );
};

export default ViewMore;

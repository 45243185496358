import styled from "styled-components";

export const HamburgerMenuContainer = styled.div`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RouteName = styled.div`
  color: ${(props) => (props.isActive ? "#AEB9FF" : "#ffffff")};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  border-radius: ${(props) => props.isActive && "10px"};
`;

export const HamburgerMenuList = styled.div`
  height: 80vh;
  width: 90vw;
  overflow-y: auto;
`;

export const HamburgerMenuItem = styled.div`
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.isActive ? "none" : "auto")};
  width: max-content;
  background: ${(props) => props.isActive && "rgba(174, 185, 255, 0.10)"};
  border-radius: ${(props) => props.isActive && "10px"};
  padding-left: 1.75rem;
  padding-right: 1.75rem;
`;

export const DashboardName = styled.div`
  color: ${(props) => (props.isActive ? "#AEB9FF" : "#ffffff")};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  cursor: pointer;
  border-radius: ${(props) => props.isActive && "10px"};
`;

export const DashboardItem = styled.div`
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  padding-left: ${props => props.padded ? "3.5rem" : "1.75rem"};
  padding-right: 1.75rem;
  cursor: pointer;
  border-radius: ${(props) => props.isActive && "10px"};
  background: ${(props) => props.isActive && "rgba(174, 185, 255, 0.1)"};
`;

export const CustomLink = styled.div`
  display: flex;
  margin: 0.5rem 0;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;

export const DashboardEntry = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

import React from "react";

import {
  EntityCountSummaryContainer,
  EntityCountSummaryDiv,
  EntityCountSummaryLabel,
  EntityCountSummaryValue,
  EntityCountSummaryContainerDiv,
  VerticalSeperator,
} from "./styles";

import { getStats } from "./helper";
import { useGlobalFiltersStore } from "../../stores/global";

const EntityCountSummary = ({ entityCountData }) => {
  const globalFilters = useGlobalFiltersStore(state => state.globalFilters)

  const stats = getStats({ lookerFilter: globalFilters, entityCountData });

  if (!entityCountData) {
    return null;
  }

  return (
    <EntityCountSummaryContainer>
      {
        stats?.map((statSectionData, index1) => (
          <EntityCountSummaryContainerDiv key={JSON.stringify(statSectionData)}>
            {statSectionData.list.map((stat, index2) => (
              <EntityCountSummaryDiv
                key={JSON.stringify(stat)}
                className={index1 === 0 && index2 === 0 ? "first-child" : ""}
              >
                <EntityCountSummaryLabel>{stat.label}</EntityCountSummaryLabel>
                <EntityCountSummaryValue>
                  {stat.value.toLocaleString()}
                </EntityCountSummaryValue>
              </EntityCountSummaryDiv>
            ))}
            {index1 < stats?.length - 1 && <VerticalSeperator />}
          </EntityCountSummaryContainerDiv>
        ))
      }
    </EntityCountSummaryContainer>
  );
};

export default EntityCountSummary;

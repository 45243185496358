import styled from "styled-components";

export const Table = styled.div`
  height: 467px;
  overflow: auto;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead {
    background-color: #f9fafb;
    position: sticky;
    top: -1px;
    z-index: 1;

    thead::first-letter {
      text-transform: uppercase;
    }
  }

  th {
    border: 1px solid #edf2f7;
    text-align: left;
    padding: 0.7rem 0.5rem 0.7rem 1.4rem;
    min-width: 8rem;
    max-width: 20rem;
    word-break: break-word;
    /* white-space: nowrap; */
    /* text-overflow: clip; */
    overflow-wrap: break-word;
  }

  th:last-child {
    padding-right: 1.3rem;
  }

  td {
    border: 1px solid #edf2f7;
    text-align: left;
    padding: 0.7rem 0.5rem 0.7rem 1.4rem;
    min-width: 8rem;
    max-width: 20rem;
    word-break: break-word;
  }

  td:last-child {
    padding-right: 1.3rem;
  }

  thead tr > :first-child {
    background: #f9fafb;
    /* position: sticky;
    left: 0;
    top: 0; */
  }

  tbody tr > :first-child {
    background: #fff;
    /* position: sticky;
    left: 0; */
  }

  .Table__title {
    color: #6b7280;
    font-size: 0.9rem;
    font-weight: 500;
    /* white-space: nowrap; */
    text-transform: capitalize;
    /* word-break: auto-phrase; */

    &::first-letter {
      text-transform: uppercase;
    }
  }
  .Table__text {
    color: #374151;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .link {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
    text-align: center;

    &:hover, &:active {
      opacity: 1; 
    }
  }

  .right-aligned {
    text-align: right;
    padding-right: 0.8rem;
  }
`;

import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import RadioButton from "../../../../components/common/RadioButton";
import Checkbox from "../../../../components/common/Checkbox";
import Icon from "../../../../components/common/Icon";

import {
  DragAndDropIconContainer,
  GroupContainer,
  GroupTitle,
  Option,
  Options,
  GroupTitleContainer,
} from "./styles";

const FilterGroup = ({
  config,
  item,
  groupIndex,
  onDragEnd,
  isDragAndDropEnabled,
  selected,
  handleSelect,
  isPreSelectedItem,
  handleRadioButtonSelect,
  collapsible = false,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    if (collapsible) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <GroupContainer key={item.name} border={config?.groupSeparator}>
      {item.name && item?.options?.length ? (
        <GroupTitleContainer isFirstChild={groupIndex === 0}>
          {" "}
          <GroupTitle>{item.name}</GroupTitle>
          {collapsible && (
            <Icon
              name={isOpen ? "minus" : "plus"}
              onClick={toggleCollapse}
            />
          )}
        </GroupTitleContainer>
      ) : null}
      {isOpen && (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, item.name)}>
          <Droppable
            droppableId={item.name}
            isDropDisabled={!isDragAndDropEnabled}
          >
            {(provided) => (
              <Options ref={provided.innerRef} {...provided.droppableProps}>
                {!!item?.options &&
                  item.options.map((option, index) => (
                    <Draggable
                      key={option.displayValue}
                      draggableId={option.displayValue}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        config?.inputType === "checkbox" ? (
                          <Option
                            key={option.displayValue}
                            ref={provided.innerRef}
                            isHiringFunnelViewMore={isDragAndDropEnabled}
                            className={snapshot.isDragging ? "isDraging" : ""}
                            {...provided.draggableProps}
                          >
                            <Checkbox
                              type={item.inputType}
                              id={option.displayValue}
                              label={option.displayValue}
                              checked={selected.includes(option.filterValue)}
                              name={item.name}
                              disabled={
                                !selected.includes(option.filterValue) &&
                                selected.length === config?.maxSelection
                              }
                              onChange={() => handleSelect(option.filterValue)}
                              isPreSelected={isPreSelectedItem(
                                option.filterValue
                              )}
                            />
                            <DragAndDropIconContainer
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              shouldShow={isDragAndDropEnabled}
                            >
                              <Icon
                                name={
                                  snapshot.isDragging
                                    ? "activeDragAndDrop"
                                    : "dragAndDrop"
                                }
                                alt="Drag and Drop icon"
                              />
                            </DragAndDropIconContainer>
                          </Option>
                        ) : (
                          <Option
                            key={option.displayValue}
                            ref={provided.innerRef}
                            isRadioType={true}
                          >
                            <RadioButton
                              id={option.displayValue}
                              label={option.displayValue}
                              checked={selected.includes(option.filterValue)}
                              name={option.displayValue}
                              disabled={
                                !selected.includes(option.filterValue) &&
                                selected.length === config?.maxSelection
                              }
                              onChange={() =>
                                handleRadioButtonSelect(option, groupIndex)
                              }
                            />
                          </Option>
                        )
                      }
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Options>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </GroupContainer>
  );
};

export default FilterGroup;

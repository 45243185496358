import { LOOKER_FILTER_KEYS } from "../../constants/AppConstants";

const ENTITY_KEYS = {
  TOTAL_CLIENTS: "totalClients",
  ACTIVE_CLIENTS: "activeClients",
  PAUSED_CLIENTS: "pausedClients",
  TOTAL_CAMPAIGNS: "totalCampaigns",
  ACTIVE_CAMPAIGNS: "activeCampaigns",
  PAUSED_CAMPAIGNS: "pausedCampaigns",
  TOTAL_JOB_GROUPS: "totalJobGroups",
  ACTIVE_JOB_GROUPS: "activeJobGroups",
  PAUSED_JOB_GROUPS: "pausedJobGroups",
  JOB_STATS: "jobStats",
  TOTAL_JOBS: "totalJobCount",
  SPONSORED_JOBS: "sponsoredJobCount",
};

const getTotalClients = (entityCountData) =>
  entityCountData[ENTITY_KEYS.TOTAL_CLIENTS] ?? 0;

const getActiveClients = (entityCountData) =>
  entityCountData[ENTITY_KEYS.ACTIVE_CLIENTS] ?? 0;

const getPausedClients = (entityCountData) =>
  entityCountData[ENTITY_KEYS.PAUSED_CLIENTS] ?? 0;

const getTotalCampaigns = (entityCountData) =>
  entityCountData[ENTITY_KEYS.TOTAL_CAMPAIGNS] ?? 0;

const getActiveCampaigns = (entityCountData) =>
  entityCountData[ENTITY_KEYS.ACTIVE_CAMPAIGNS] ?? 0;

const getPausedCampaigns = (entityCountData) =>
  entityCountData[ENTITY_KEYS.PAUSED_CAMPAIGNS] ?? 0;

const getTotalJobGroups = (entityCountData) =>
  entityCountData[ENTITY_KEYS.TOTAL_JOB_GROUPS] ?? 0;

const getActiveJobGroups = (entityCountData) =>
  entityCountData[ENTITY_KEYS.ACTIVE_JOB_GROUPS] ?? 0;

const getPausedJobGroups = (entityCountData) =>
  entityCountData[ENTITY_KEYS.PAUSED_JOB_GROUPS] ?? 0;

const getTotalJobs = (entityCountData) =>
  entityCountData?.[ENTITY_KEYS.JOB_STATS]?.[ENTITY_KEYS.TOTAL_JOBS] ?? 0;

const getSponsoredJobs = (entityCountData) =>
  entityCountData?.[ENTITY_KEYS.JOB_STATS]?.[ENTITY_KEYS.SPONSORED_JOBS] ?? 0;

const getInstanceStats = (entityCountData) => [
  {
    list: [
      {
        label: "Total Clients",
        value: getTotalClients(entityCountData),
      },
      {
        label: "Active Clients",
        value: getActiveClients(entityCountData),
      },
      {
        label: "Paused Clients",
        value: getPausedClients(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Campaigns",
        value: getTotalCampaigns(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Job groups",
        value: getTotalJobGroups(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Total Jobs",
        value: getTotalJobs(entityCountData),
      },
      {
        label: "Sponsored Jobs",
        value: getSponsoredJobs(entityCountData),
      },
    ],
  },
];

const getClientStats = (entityCountData) => [
  {
    list: [
      {
        label: "Total campaigns",
        value: getTotalCampaigns(entityCountData),
      },
      {
        label: "Active campaigns",
        value: getActiveCampaigns(entityCountData),
      },
      {
        label: "Paused campaigns",
        value: getPausedCampaigns(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Job groups",
        value: getTotalJobGroups(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Total Jobs",
        value: getTotalJobs(entityCountData),
      },
      {
        label: "Sponsored Jobs",
        value: getSponsoredJobs(entityCountData),
      },
    ],
  },
];

const getCampaignStats = (entityCountData) => [
  {
    list: [
      {
        label: "Total job groups",
        value: getTotalJobGroups(entityCountData),
      },
      {
        label: "Active job groups",
        value: getActiveJobGroups(entityCountData),
      },
      {
        label: "Paused job groups",
        value: getPausedJobGroups(entityCountData),
      },
    ],
  },
  {
    list: [
      {
        label: "Total Jobs",
        value: getTotalJobs(entityCountData),
      },
      {
        label: "Sponsored Jobs",
        value: getSponsoredJobs(entityCountData),
      },
    ],
  },
];

const getJobGroupStats = (entityCountData) => [
  {
    list: [
      {
        label: "Total Jobs",
        value: getTotalJobs(entityCountData),
      },
      {
        label: "Sponsored Jobs",
        value: getSponsoredJobs(entityCountData),
      },
    ],
  },
];

export const getStats = ({ lookerFilter, entityCountData }) => {
  if (!entityCountData) return []

  if (lookerFilter[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) {
    return getJobGroupStats(entityCountData);
  }

  if (lookerFilter[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) {
    return getCampaignStats(entityCountData);
  }

  if (lookerFilter[LOOKER_FILTER_KEYS.CLIENT_ID]) {
    return getClientStats(entityCountData);
  }

  return getInstanceStats(entityCountData);
};

import styled from 'styled-components'
import { devices } from "../../constants/AppConstants";

export const JgBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  background: #f5f5f5;
  height: 100%;
  padding: 1.5rem;

  @media only screen and ${devices.mobile} {
    width: 100vw;
    height: calc(100vh - 3.5rem);
  }

  @media only screen and (min-width: 736px) and (max-width: 960px) {
    width: 95vw;
  }

  @media only screen and (min-width: 960px) and (max-width: 1080px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1440px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1440px) {
    width: 80vw;
  }

  .ColumnSelectionModal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .JobBreakdownTable {
    overflow: auto scroll;

    @media only screen and (max-height: 650px) {
      height: 55vh;
    }
    @media only screen and (min-height: 650px) and (max-height: 700px) {
      height: 58vh;
    }
    @media only screen and (min-height: 700px) and (max-height: 750px) {
      height: 61vh;
    }
    @media only screen and (min-height: 750px) and (max-height: 800px) {
      height: 63vh;
    }
    @media only screen and (min-height: 800px) and (max-height: 850px) {
      height: 66vh;
    }
    @media only screen and (min-height: 850px) and (max-height: 900px) {
      height: 68vh;
    }
    @media only screen and (min-height: 900px) and (max-height: 1000px) {
      height: 69vh;
    }
    @media only screen and (min-height: 1000px) {
      height: 72vh;
    }

    table {
      border: none;
    }
  }
`;
export const Header = styled.div`
  display: none;

  @media only screen and ${devices.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
`
export const Subtitle = styled.p`
  color: #6A7185;
  font-size: 0.8rem;
  font-weight: 500;
  padding-left: 1rem;
`
export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;

  @media only screen and ${devices.mobile} {
    flex: 1 1 auto;
  }
`;
export const ReportContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  flex: 1;
  padding: 0.6rem 0;
  overflow: scroll;

  @media only screen and ${devices.mobile} {
    overflow: auto;
    flex: 1 1 auto;
  }
`
export const ReportHeader = styled.div`
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;

  @media only screen and ${devices.mobile} {
    flex-direction: column-reverse;
    flex: 0 1 auto;
  }

  .Job-breakdown__SearchBox {
    flex: 1;
    width: 100%;
  }
`
export const Button = styled.button`
  background-color: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 0.3rem;
  padding: 0.6rem 0.7rem;
  color: #374151;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.9rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  @media only screen and ${devices.mobile} {
    width: 100%;
    justify-content: center;
  }
`;
export const TableContainer = styled.div`
  border-bottom: 1px solid #E5E7EB;
  flex: 1;

  @media only screen and ${devices.mobile} {
    flex: 1 1 auto;
    overflow-y: auto;
  }
`
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.6rem;
  justify-self: flex-end;

  @media only screen and ${devices.mobile} {
    flex: 0 0 auto;
  }
`
import React from "react";

import {
  StyledBackdrop,
  StyledModalContainer,
  StyledModalContent,
  HeaderBar,
  BottomBar,
  HeaderBarContainer,
  BottomBarContainer,
} from "./styles";

const BottomModal = ({ isOpen = false, onClose, children, style }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <StyledModalContainer style={style}>
        <StyledModalContent>
          <HeaderBarContainer>
            <HeaderBar></HeaderBar>
          </HeaderBarContainer>
          {children}
          <BottomBarContainer>
            <BottomBar></BottomBar>
          </BottomBarContainer>
        </StyledModalContent>
      </StyledModalContainer>
      {/* Backdrop */}
      {isOpen && <StyledBackdrop onClick={onClose} />}
    </>
  );
};

export default BottomModal;

import styled from 'styled-components'

export const FilterLabelContainer = styled.div`
  position: relative;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  border-radius: 0.4rem;
`
export const Text = styled.span`
  font-size: 0.9rem;
  font-weight: ${props => props.bold ? "600" : "400"};
  color: #374151;
  max-width: ${props => props.overflow ? "10rem" : "auto"};
  overflow: ${props => props.overflow ? "hidden" : "visible"};
  text-overflow: ${props => props.overflow ? "ellipsis" : "auto"};
  white-space: nowrap;
`

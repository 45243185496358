import styled, { css } from "styled-components";
import { devices } from "../../constants/AppConstants";

export const UpgradeContainer = styled.div`
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 3.4rem);
  overflow: auto;

  @media screen and ${devices.mobile} {
    padding: 2rem;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const HeroContainer = styled.div`
  display: flex;
  gap: 5rem;
  border-style: solid;
  border-width: 1px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-image: radial-gradient(#9A9A9A, #F1F1F1) 1;

  @media screen and ${devices.mobile} {
    padding-bottom: 2rem;
  }

`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex: 3;
  padding: 2rem;

  @media screen and ${devices.mobile} {
    padding: 0;
  }
`
export const Title = styled.div`
  color: #D4980E;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  span {
    padding-top: 0.2rem;
  }
`
export const EntityText = styled.div`
  color: #3D4759;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 0.5rem;
`
export const HeroText = styled.div`
  color: #111928;
  font-weight: 600;
  font-size: 1.6rem;
  padding-top: 0.8rem;

  @media screen and ${devices.mobile} {
    padding-top: 0;
  }
`
export const Subtext = styled.div`
  color: #637381;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.2rem;
`
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 2.5rem;
`
export const ImageContainer = styled.div`
  display: flex;
  flex: 5;
  overflow: hidden;
  height: 600px;
  padding-right: 2rem;
  border-radius: 0.4rem;

  @media screen and ${devices.mobile} {
    display: none;
  }

  @keyframes slideVertical { 
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(calc(var(--imageHeight) * -1));
    }
  }

  ${(props) =>
    !!props.slide ?
      css`
      img {
        --imageHeight: 400px;
        height: calc(var(--imageHeight) * 2);
        animation: slideVertical 10s linear infinite;
        object-fit: cover;
        width: 100%;
    }
    ` :
      css`
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `}
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .scroller {
    max-width: 90vw;
  }

  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }


  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 10%,
      white 90%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
  }

  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    height: 300px;
    flex-wrap: nowrap;
    animation: scroll 40s forwards linear infinite;
  }
`
export const ContentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 25%;
  text-align: center;

  @media screen and ${devices.mobile} {
    padding: 0;
  }
`
export const VideoContainer = styled.div`
  flex: 1;
  margin: 1rem 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
`

import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: ${props => !!props.showSelected ? "relative" : "static"};
  z-index: 1;
  ${(props) => props.style};
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.3rem;
  color: #374151;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  min-width: 100px;

  img {
    transform: ${(props) => (props.active ? "rotateX(180deg)" : "initial")};
  }
`;
export const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  overflow-y: scroll;
  max-height: 35vh;
  z-index: 2;
  width: 100%;
  word-wrap: break-word;
`;
export const Option = styled.div`
  padding: 0.7rem;
  font-weight: 400;
  font-size: 0.9rem;
  color: #374151;
  cursor: pointer;
  width: auto;
  /* word-break: break-all; */

  &:hover {
    background-color: #edf2f7;
  }
`;

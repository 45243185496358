import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const AutocompleteContainer = styled.div`
  box-shadow: 0px 8px 10px 0px #00000024;
  border-radius: 0.6rem;
  background-color: #ffffff;
  min-height: 40vh;
  min-width: 25vw;
  display: flex;
  flex-direction: column;
  cursor: default;
  /* border: 1px solid #CED4DA; */

  .Close_Icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media screen and ${devices.mobile} {
    max-height: 20rem;
  }
`;
export const Header = styled.div`
  font-size: 1.1rem;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  font-weight: 600;
  color: #252f3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252f3f;
`;
export const Body = styled.div`
  min-height: 30vh;
  max-height: ${(props) => (props.isPublisher ? "30vh" : "none")};
  overflow-y: scroll;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  @media screen and ${devices.mobile} {
    min-height: auto;
    max-height: auto;
  }
`;
export const Footer = styled.div`
  padding: 1.2rem 1rem;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: #f9fafb;
  }
`;
export const Submit = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  color: #3f51b5;
`;
export const FilterName = styled.div`
  font-size: 0.9rem;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  color: #252f3f;
  cursor: pointer;
`;
export const Banner = styled.div`
  border: 1px solid #edf2f7;
  background-color: #f7fafc;
  padding: 0.8rem;
  color: #6b7280;
  font-weight: 400;
  font-size: 0.7rem;
`;

import React, { useRef, useState } from 'react';

import {
  ActionContainer,
  IconContainer
} from './styles';

import Icon from '../../../components/common/Icon';
import OptionsList from '../../../components/common/OptionsList';

import useOnClickOutside from '../../../utils/useOnClickOutside';

const OPTIONS = [
  { title: "Edit", icon: "edit" },
  { title: "Delete", icon: "delete" }
]

const Action = ({
  onSelect
}) => {
  const [isOptionsOpen, toggleOptions] = useState(false)

  const optionsRef = useRef(null)

  useOnClickOutside(optionsRef, () => {
    toggleOptions(false)
  });

  return (
    <ActionContainer>
      <IconContainer
        onClick={(e) => {
          e.stopPropagation()
          toggleOptions(!isOptionsOpen)
        }}
      >
        <Icon
          name="three-dots"
          alt="options"
          onClick={() => toggleOptions(!isOptionsOpen)}
        />
        {
          isOptionsOpen && (
            <OptionsList
              ref={optionsRef}
              className="Action__options"
              options={OPTIONS}
              idKey="title"
              onSelect={onSelect}
            />
          )
        }
      </IconContainer>
    </ActionContainer>
  );
}

export default Action;

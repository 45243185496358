import {
  ACCESS_TOKEN_LS_KEY,
  EMAIL_LS_KEY,
  IDENTITY_PROVIDER_LS_KEY,
  LOOKER_ACCESS_TOKEN_LS_KEY,
  REFRESH_TOKEN_LS_KEY,
  SHOW_MARKET_INSIGHTS_LS_KEY,
  UA_PRODUCT_ID
} from "../constants/AppConstants";
import Mixpanel from "../services/MixPanel";
import { getAccessToken } from "../services/apis";
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from "./commonHelper";

export const fetchAndSaveAccessToken = async () => {
  if (getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)) {
    return getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY);
  }

  const payload = {
    email: getItemFromLocalStorage("email"),
    productId: UA_PRODUCT_ID,
  };

  const identityProvider = getItemFromLocalStorage(IDENTITY_PROVIDER_LS_KEY);
  if (
    identityProvider
    && identityProvider !== 'undefined'
    && identityProvider !== 'null') {
    payload['identityProvider'] = identityProvider
  }

  const apiResponse = await getAccessToken(payload);
  const accessToken = apiResponse?.data?.accessToken;
  setItemInLocalStorage(ACCESS_TOKEN_LS_KEY, accessToken);
  return accessToken;
};

export const handleFetchAndSaveAccessToken = async () => {
  if (!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)) {
    await fetchAndSaveAccessToken();
  }
};

export const getAccountAppUrl = () => {
  if (window.location.origin.includes("localhost")) {
    return "http://localhost:4300";
  }

  let runtimeConf = window.__RUNTIME_CONFIG__;
  return runtimeConf
    ? runtimeConf.ACCOUNTS_APP_URL
    : window.location.origin.replace("analytics", "accounts");
};

const cleanUpUACookies = () => {
  // if (window["safari"] !== undefined) {
  document.cookie = `refreshToken=; expires=${new Date(
    0
  ).toUTCString()};  domain=joveo.com`;
  document.cookie = `email=; expires=${new Date(
    0
  ).toUTCString()};  domain=joveo.com`;
  document.cookie = `identityProvider=; expires=${new Date(
    0
  ).toUTCString()};  domain=joveo.com`;
  // }
};

export const cleanupAllCookies = () => {
  const cookies = document.cookie.split(";");
  console.log("cookies before cleanup", cookies)
  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  })
  console.log("cookies after cleanup", document.cookie)
}

const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const redirectToAccountApp = () => {
  const redirectURL = `${window.location.origin}`;

  const url = `${getAccountAppUrl()}/validate?redirect=${redirectURL}&productId=${UA_PRODUCT_ID}`;
  window.location.href = url;
};

export const cleanUp = () => {
  cleanUpUACookies();
  removeItemFromLocalStorage(ACCESS_TOKEN_LS_KEY);
  removeItemFromLocalStorage(EMAIL_LS_KEY);
  removeItemFromLocalStorage(REFRESH_TOKEN_LS_KEY);
  removeItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY);
  removeItemFromLocalStorage(IDENTITY_PROVIDER_LS_KEY);
  removeItemFromLocalStorage(SHOW_MARKET_INSIGHTS_LS_KEY)
};

export const logoutFromUAApp = () => {
  cleanUp();
  redirectToAccountApp();
};

export const logoutAndInvalidate = () => {
  cleanUp();
  const url = `${getAccountAppUrl()}/validate?redirect=${window.location.origin
    }&productId=${UA_PRODUCT_ID}&invalidate=true`;
  window.location.href = url;
};

export const logoutAndValidate = () => {
  cleanUp();
  const url = `${getAccountAppUrl()}/validate?redirect=${window.location.origin
    }&productId=${UA_PRODUCT_ID}&logoutAndValidate=true`;
  window.location.href = url;
};

export const refreshAndRefetchAccessToken = () => {
  removeItemFromLocalStorage("accessToken");
  removeItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY);
  window.location.reload();
};

export const logout = () => {
  cleanUp();
  const url = getAccountAppUrl();
  window.location.href = url;
};

export const setLocalStorageFromCookies = () => {
  const refreshToken = getCookie("refreshToken")
  const email = getCookie("email")
  const identityProvider = getCookie("identityProvider")
  if (refreshToken) {
    setItemInLocalStorage(REFRESH_TOKEN_LS_KEY, refreshToken);
  }
  if (email) {
    setItemInLocalStorage(EMAIL_LS_KEY, email);
    Mixpanel.identifyUser(email);
  }
  if (identityProvider) {
    setItemInLocalStorage(IDENTITY_PROVIDER_LS_KEY, identityProvider);
  }
  cleanUpUACookies();
};

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import {
  DropdownButton,
  DropdownMenuItemList,
  DropdownMenuItemListContainer,
  ProfileLogoContainer,
  Username,
  Email,
  Separator,
} from "./styles";

import DropdownMenuItem from "../common/DropdownMenuItem";

import {
  CURRENCY_KEYS,
  EMAIL_LS_KEY,
  EXCHANGE_VISIBILITY,
  EXCHANGE_VISIBILITY_LS_KEY,
  EXCHANGE_VISIBILITY_VALUES,
  LOOKER_FILTER_KEYS,
} from "../../constants/AppConstants";

import {
  getAvatarText,
  getMenuItems,
} from "./helpers";
import { logoutAndInvalidate } from "../../helpers/authHelper";
import useOnClickOutside from "../../utils/useOnClickOutside";
import { getItemFromLocalStorage, setItemInLocalStorage } from "../../helpers/commonHelper";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import CURRENCY_LIST_OBJ from "../../constants/currenciesList";

const SUPPORTED_CURRENCIES = Object.keys(CURRENCY_LIST_OBJ)

const ProfileLogo = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const {
    username,
    primaryCurrency,
    exchangeVisibility
  } = useGlobalDataStore(useShallow((state) => ({
    username: state.username,
    primaryCurrency: state.primaryCurrency,
    exchangeVisibility: state.exchangeVisibility
  })))

  const {
    globalFilters, currentProduct
  } = useGlobalFiltersStore()

  const [isOpen, setIsOpen] = useState(false);
  const [isExchangeSet, toggleExchange] = useState(false)
  const dropdownRef = useRef(null);

  const email = getItemFromLocalStorage(EMAIL_LS_KEY)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setIsOpen(false);
    logoutAndInvalidate();
  };

  const handleUpdateCurrency = (currencyKey) => {
    setIsOpen(!isOpen);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      [LOOKER_FILTER_KEYS.CURRENCY_RATE]: currencyKey,
    });
  };

  const handleJAXExchange = () => {
    const toSet = !isExchangeSet
    const jaxValue = toSet ? EXCHANGE_VISIBILITY_VALUES.OPEN : EXCHANGE_VISIBILITY_VALUES.CLOSE
    toggleExchange(toSet)
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: jaxValue,
    });
    setItemInLocalStorage(EXCHANGE_VISIBILITY_LS_KEY, jaxValue)
    setIsOpen(false)
  }

  useEffect(() => {
    if (exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) {
      const params = Object.fromEntries([...searchParams])
      const exchangeSet = params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] === EXCHANGE_VISIBILITY_VALUES.OPEN
      toggleExchange(exchangeSet)
    }
  }, [searchParams, exchangeVisibility])

  const MENU_ITEMS = getMenuItems({
    lookerFilter: globalFilters,
    primaryCurrency,
    supportedCurrencies: SUPPORTED_CURRENCIES,
    handleLogout,
    handleUpdateCurrency,
    exchangeVisibility,
    isJaxSet: isExchangeSet,
    handleJAXExchange,
    searchParams,
    navigate,
    setIsOpen,
    currentProduct
  })

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false)
  });

  if (!username) return null

  return (
    <ProfileLogoContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {getAvatarText(username)}
      </DropdownButton>
      <DropdownMenuItemListContainer className={isOpen ? "active" : ""}>
        <Username>{username}</Username>
        <Email>{email}</Email>
        <Separator />
        <DropdownMenuItemList>
          {MENU_ITEMS.map((menuItem) => (
            <span key={menuItem.text} onClick={menuItem.onClick}>
              {
                !!menuItem?.element
                  ? menuItem.element()
                  : (
                    <DropdownMenuItem
                      key={menuItem.text}
                      menuItem={menuItem}
                    />
                  )
              }
              {menuItem.isSeparatorRequired ? <Separator /> : null}
            </span>
          ))}
        </DropdownMenuItemList>
      </DropdownMenuItemListContainer>
    </ProfileLogoContainer>
  );
};

export default ProfileLogo;
import React, { useEffect } from 'react';

import {
  ButtonsContainer,
  Wrapper,
  HeroContainer,
  ContentContainer,
  EntityText,
  HeroText,
  ImageContainer,
  Subtext,
  TextContainer,
  Title,
  UpgradeContainer,
  ContentTextContainer,
  VideoContainer,
} from './styles';

import Icon from '../../components/common/Icon';
import Button from '../../components/common/Button';

import { getImageUrl } from '../../constants/ApiRoutes';
import { UNIFIED_ANALYTICS_ORIGIN } from '../../constants/AppConstants';

const PROD_EMAILS = "sales@joveo.com, akash@joveo.com, rohan@joveo.com, bharat@joveo.com"
const DEV_EMAILS = "akash@joveo.com, rohan@joveo.com, bharat@joveo.com"

const Upgrade = ({
  entity = "",
  unlockTitle = "",
  unlockText = "",
  bannerSrc = "",
  widgetsSrc = "",
  productTitle = "",
  productText = ""
}) => {
  const handleRequestDemo = () => {
    const emails = window.location.hostname === UNIFIED_ANALYTICS_ORIGIN.PROD ? PROD_EMAILS : DEV_EMAILS
    window.location.href = `mailto:${emails}`
  }

  useEffect(() => {

    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, [])

  return (
    <UpgradeContainer>
      <Wrapper>
        <HeroContainer border={!!productTitle}>
          <TextContainer>
            <Title>
              <Icon height="24" width="24" name="premium" alt="premium" />
              <span>Unlock analytics</span>
            </Title>
            <EntityText>for <strong>{entity}</strong> with our</EntityText>
            <HeroText>{unlockTitle}</HeroText>
            <Subtext>{unlockText}</Subtext>
            <ButtonsContainer>
              <Button
                type="submit"
                text={"Request demo"}
                onClick={handleRequestDemo}
              />
            </ButtonsContainer>
          </TextContainer>
          <ImageContainer slide={!(!!productTitle)}>
            <img src={getImageUrl(bannerSrc)} alt={bannerSrc} />
          </ImageContainer>
        </HeroContainer>
        {
          (!!productTitle && !!productText) && (
            <ContentContainer>
              <ContentTextContainer>
                <HeroText>{productTitle}</HeroText>
                <Subtext>{productText}</Subtext>
              </ContentTextContainer>
              <VideoContainer className="scroller">
                <div className="scroller__inner">
                  <img src={getImageUrl(widgetsSrc)} alt={widgetsSrc} />
                </div>
              </VideoContainer>
            </ContentContainer>
          )
        }
      </Wrapper>
    </UpgradeContainer >
  );
}

export default Upgrade;

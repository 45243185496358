import styled from 'styled-components'
import { Text } from '../../common/EmptyText/styles';

export const ReorderContainer = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 0.4rem;
  background-color: #FAFAFA;
`
export const Header = styled.div`
  background-color: #CBD5E059;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #252F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D7DAEB;
`;
export const SectionContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(100% - 2.5rem);
  background-color: #FAFAFA;
`
export const Section = styled.div`
  border-top: 1px solid #E2E8F0;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem;
`
export const SectionTitle = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: #292929;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  .Arrow {
    cursor: pointer;
    height: 0.7rem;
    width: 0.7rem;
  }
  .Arrow__rotate {
    transform: rotateZ(270deg);
  }
`
export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`
export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #EFF3F8;
  color: #3D4759;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 0.7rem 1rem;
  border-radius: 0.3rem;
`
export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 15vh;

  ${Text} {
    font-size: 0.8rem;
  }
`

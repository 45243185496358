import React from 'react';

import { ButtonContainer } from './styles'

import Icon from '../Icon';

const Button = ({
  text,
  icon,
  ...props
}) => {
  return (
    <ButtonContainer {...props}>
      {
        !!icon && (
          <Icon name={icon} alt={icon} />
        )
      }
      {
        !!text && text
      }
    </ButtonContainer>
  );
}

export default Button;

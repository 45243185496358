import { LOOKER_FILTER_KEYS } from "../../../../constants/AppConstants"

export const MAIN_DB_NOTE_TEXT = "This view will be emailed as a PNG file, with all the filters applied throughout the main dashboard."
export const REPORTS_NOTE_TEXT = "This view will be emailed as a CSV file, with all the filters applied throughout reports."

export const FREQUENCY_OPTIONS = ["Daily", "Weekly", "Monthly", "Send now"]

export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

export const TIMINGS = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]

export const DATES = Array.from({ length: 28 }, (_, i) => `${i + 1}`)

export const DESTINATION_OPTIONS = [
  {
    name: "Email",
    value: "email"
  }
]

export const DB_FORMAT_OPTIONS = [
  // {
  //   name: "PDF",
  //   value: "wysiwyg_pdf"
  // },
  // {
  //   name: "CSV",
  //   value: "csv_zip"
  // },
  {
    name: "PNG",
    value: "wysiwyg_png"
  }
]

export const REPORT_FORMAT_OPTIONS = [
  {
    name: "CSV",
    value: "csv"
  },
]

export const FILTERS_TO_HIDE = [
  LOOKER_FILTER_KEYS.ACCOUNT_ID,
  LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
  LOOKER_FILTER_KEYS.AGENCY_ID,
  LOOKER_FILTER_KEYS.CLIENT_ID,
  LOOKER_FILTER_KEYS.CAMPAIGN_ID,
  LOOKER_FILTER_KEYS.JOB_GROUP_ID,
  LOOKER_FILTER_KEYS.CURRENCY_RATE,
  LOOKER_FILTER_KEYS.DATE,
  LOOKER_FILTER_KEYS.DASHBOARD_ID,
  LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND,
  LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY,
  LOOKER_FILTER_KEYS.ACCOUNT_ID,
  LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
  LOOKER_FILTER_KEYS.SITE_ID,
  "widgetFilter",
  "performanceMetricSort",
  "periodSelection",
  "usersParam",
  "allUsersParam",
  "usersDiffParam",
  "utmSource",
  "sources",
  "utmCampaign",
  "campaigns",
  "pageWiseSort",
  "pixelConfig",
  "breakdown_source",
  "breakdown_candidates",
  "talent_pool_candidates",
  "talent_pool_location",
  "locations",
  "outreach_campaign",
  "diversityParam",
  "diversityValuesParam",
  "compareDatePeriod",
  "sourceWiseDatePeriod",
  "periodOverPeriodDatePeriod",
  "performanceBreakdownEntityFilter",
  "sort_by",
  "visitorsParam",
  "visitorsByCampaignParam"
]

export const getCronExpressionFromState = ({ recurrence, time, day, date }) => {
  switch (recurrence.name) {
    case "Daily":
      return `0 ${time.name.split(":")[0]} * * *`

    case "Weekly":
      return `0 ${time.name.split(":")[0]} * * ${day.name}`

    case "Monthly":
      return `0 ${time.name.split(":")[0]} ${date.name} * *`

    default:
      return ""
  }
}

export const getStateFromCronExpression = (cron) => {
  if (!cron) return null
  const [_, time, date, __, day] = cron.split(" ")
  if (date !== "*") {
    return {
      recurrence: { name: "Monthly", id: "Monthly" },
      time: { name: `${time}:00`, id: `${time}:00` },
      date: { name: date, id: date },
      day: { name: DAYS[0], id: DAYS[0] }
    }
  } else if (day !== "*") {
    return {
      recurrence: { name: "Weekly", id: "Weekly" },
      time: { name: `${time}:00`, id: `${time}:00` },
      day: { name: day, id: day },
      date: { name: DATES[0], id: DATES[0] }
    }
  } else {
    return {
      recurrence: { name: "Daily", id: "Daily" },
      time: { name: `${time}:00`, id: `${time}:00` },
      day: { name: DAYS[0], id: DAYS[0] },
      date: { name: DATES[0], id: DATES[0] }
    }
  }
}

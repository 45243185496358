import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  JgBreakdownContainer,
  LoaderContainer,
  ReportContainer,
  ReportHeader,
  Button,
  PaginationContainer,
  TableContainer,
  Header,
  TitleContainer,
  Subtitle
} from './styles'

import Loader from '../common/Loader';
import AdvancedFilters from '../AdvancedFilters';
import SearchBox from '../common/SearchBox';
import DataTable from '../common/DataTable';
import Icon from '../common/Icon';
import Title from '../common/Title';
import Modal from '../common/Modal';
import Pagination from '../common/Pagination';
import BottomModal from '../common/BottomModal';

import {
  DEVICE_SIZES_PX,
  LOOKER_FILTER_KEYS
} from "../../constants/AppConstants";
import {
  JOB_PERFORMANCE_COLUMN_DEFAULTS,
  JOB_PERFORMANCE_DIMENSION_GROUPS,
  JOB_PERFORMANCE_REPORT_TITLE,
  JOB_PERFORMANCE_VIEW,
} from "../../constants/ReportsConstants"
import { useFetchCIFFields, useFetchJobPerformanceData, useFetchJobPerformanceMetrics } from '../../api/reports';
import { formatNumberThousandSeparated, formatUAData, parseCurrency, parseFilterExpressions } from "../../utils/helpers"
import {
  getJobFields,
  parseLookerFilters as parseJobLookerFilters,
  buildJobPayload,
  withAdvancedFilters,
  buildJobMetricsPayload as buildMetricPayload,
  parseJobLookerFilters as parseLookerFiltersForMetric,
} from './helpers';
import { parseColumnSelected, parseMetrics } from '../../pages/Reports/helpers';
import ColumnSelection from '../ColumnSelection';
import ColumnSelectionMobileView from '../ColumnSelectionMobileView';
import { useGlobalDataStore } from '../../stores/global';

const JOB_PERFORMANCE_PARSE_KEY = "job_performance.conversion_type"
const JOB_PERFORMANCE_TYPE_KEY = "job_performance.conversion"
const CURRENCY_KEY = "job_performance.currency"

const JobLevelBreakdown = ({
  jobGroupDetails,
  advancedFiltersFromParent,
  showPublisherFilter
}) => {
  let [searchParams] = useSearchParams()
  let navigate = useNavigate();
  let location = useLocation()

  const { models } = useGlobalDataStore(useShallow((state) => ({
    models: state.models,
  })))

  const [advancedFilters, setAdvancedFilters] = useState([])
  const [advancedFiltersForApi, setAdvancedFiltersForApi] = useState(null)
  const [columnSelections, setColumnSelections] = useState(null)
  const [isColumnSelectionOpen, toggleColumnSelection] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [pinnedColumns, setPinnedColumns] = useState([])
  const [isColumnSelectionBottomModalOpen, setIsColumnSelectionBottomModalOpen] = useState(false);

  const jgDetails = useMemo(() => {
    if (!!location?.state) {
      return location.state
    } else if (!!jobGroupDetails) {
      return {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: jobGroupDetails?.[LOOKER_FILTER_KEYS.ACCOUNT_ID],
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: jobGroupDetails?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
        [LOOKER_FILTER_KEYS.AGENCY_ID]: jobGroupDetails?.[LOOKER_FILTER_KEYS.AGENCY_ID],
        [LOOKER_FILTER_KEYS.CLIENT_ID]: jobGroupDetails[LOOKER_FILTER_KEYS.CLIENT_ID],
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: jobGroupDetails[LOOKER_FILTER_KEYS.CAMPAIGN_ID],
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupDetails[LOOKER_FILTER_KEYS.JOB_GROUP_ID],
        [LOOKER_FILTER_KEYS.DATE]: jobGroupDetails[LOOKER_FILTER_KEYS.DATE],
        [LOOKER_FILTER_KEYS.CURRENCY_RATE]: jobGroupDetails[LOOKER_FILTER_KEYS.CURRENCY_RATE],
      }
    }
    return null
  }, [jobGroupDetails, location])

  const {
    data: { data: { fields: cifFields } = {} } = {},
  } = useFetchCIFFields(jobGroupDetails?.[LOOKER_FILTER_KEYS.CLIENT_ID]);

  const {
    data: { data: jobMetrics = [] } = {},
    isLoading: isJobMetricsLoading,
  } = useFetchJobPerformanceMetrics(
    buildMetricPayload(jgDetails, "job", "job", models),
    parseLookerFiltersForMetric(jgDetails, "job")
  );

  const jobMetricKeys = useMemo(() => {
    return parseMetrics(jobMetrics, JOB_PERFORMANCE_PARSE_KEY, JOB_PERFORMANCE_TYPE_KEY, JOB_PERFORMANCE_REPORT_TITLE)
  }, [jobMetrics])

  const advancedFilterOptions = useMemo(() => {
    const cifs = Object.entries(cifFields || {})
    let jobFilters = [
      ...getJobFields().map(item => ({
        ...item,
        key: item.name,
        filter: item.field,
        suggestionsEnabled: true
      }))
    ]
    if (!!cifs?.length) {
      const cifs = Object.entries(cifFields).map(([key, value]) => ({ id: key, name: key, key, filter: `job_performance.cif_${value.split("cif")[1]}`, filterKey: `job_performance.cif_${value.split("cif")[1]}` }))
      jobFilters = [
        ...jobFilters,
        ...cifs?.map(item => ({
          ...item,
          enabled: true,
          field: item.key,
          suggestionsEnabled: true
        })),
      ]
    }
    return jobFilters
  }, [showPublisherFilter, cifFields])

  const dimensions = useMemo(() => {
    let dimensions = JOB_PERFORMANCE_DIMENSION_GROUPS.slice(0, 3)
    if (!!Object.entries(cifFields || {})?.length) {
      const cifs = Object.entries(cifFields).map(([key, value]) => ({ id: key, name: key, key: `job_performance.cif_${value.split("cif")[1]}`, filterKey: `job_performance.cif${value.split("cif")[1]}` }))
      const jobAdditonalFields = {
        title: "Job additional nodes",
        options: cifs
      }
      dimensions.push(jobAdditonalFields)
    }
    if (showPublisherFilter) {
      return dimensions
    }
    if (!dimensions) {
      return null;
    }
    return dimensions.filter(dimensionOption => dimensionOption.title.toLowerCase() !== 'publisher')
  }, [showPublisherFilter, cifFields])


  const columnsSelected = useMemo(() => {
    const metricKeys = jobMetricKeys
    if (!metricKeys?.length) return columnSelections
    if (!dimensions?.length) return columnSelections

    if (!columnSelections) {
      return {
        dimensions: JOB_PERFORMANCE_COLUMN_DEFAULTS.dimensions,
        metrics: JOB_PERFORMANCE_COLUMN_DEFAULTS.metrics
      }
    }
    const selectedDimensions = parseColumnSelected(dimensions, columnSelections, "dimensions")
    const selectedMetrics = parseColumnSelected(metricKeys, columnSelections, "metrics")
    return {
      dimensions: selectedDimensions,
      metrics: selectedMetrics
    }
  }, [columnSelections, jobMetrics])

  const colselections = useMemo(() => {
    if (!columnsSelected) return null
    const selectedDimensions = columnsSelected.dimensions
    const selectedMetrics = columnsSelected.metrics.flatMap(item => item.options.map(i => ({ ...i })))
    return {
      dimensions: selectedDimensions,
      metrics: selectedMetrics
    }
  }, [columnsSelected])

  const {
    data: { data = [] } = {},
    isLoading: isDataLoading,
  } = useFetchJobPerformanceData(
    buildJobPayload(withAdvancedFilters(jgDetails, advancedFiltersForApi), colselections, advancedFilterOptions, models),
    parseJobLookerFilters(withAdvancedFilters(jgDetails, advancedFiltersForApi), colselections, advancedFilterOptions)
  );

  const cifDimensions = useMemo(() => {
    const fields = Object.entries(cifFields || {})
    if (!!fields?.length) {
      const cifs = fields.map(([key, value]) => ({ id: key, name: key, key: `job_performance.cif_${value.split("cif")[1]}`, filterKey: `job_performance.cif${value.split("cif")[1]}` }))
      return cifs
    }
    return []
  }, [cifFields])

  const columns = useMemo(() => {
    if (!colselections?.dimensions || !colselections?.metrics) return []

    const currency = (() => {
      for (let i = 0; i < data.length; i++) {
        if (!!data[i]?.[CURRENCY_KEY]) {
          return data[i][CURRENCY_KEY]
        }
      }
    })()

    const dimensions = colselections.dimensions.flatMap(item => item.options.map(opt => ({ label: opt.name, dataTargetKey: opt.key })))
    const metrics = colselections.metrics.map(item => ({ label: item.name, dataTargetKey: item.key }))

    return [
      ...dimensions.map((item, index) => ({
        header: item.label,
        accessorFn: (row) => row[item.dataTargetKey],

        id: `${JSON.stringify(item)}`,
        backgroundColor: "linear-gradient(0deg, #F9FAFB, #F9FAFB),linear-gradient(0deg, #E8E8E8, #E8E8E8)",
        cell: (cell) => {
          const formatted = `${formatUAData({ value: cell.getValue(), currency, column: cell.column.columnDef.header, placeholder: "-", type: "standard" })}`
          if (typeof cell.getValue() === "number") {
            return <div style={{ textAlign: "right" }}>{formatted}</div>
          }
          return (index === 0 || index === 1) ? <div style={{ minWidth: "16rem", wordBreak: "break-word" }}>{formatted}</div> : formatted
        },
        sortingFn: "alphanumeric"
      })),
      ...metrics.map((item, index) => ({
        header: <div style={{ textAlign: "right", flex: 1 }}>{item.label}</div>,
        accessorFn: (row) => row[item.dataTargetKey] || "",
        id: `${JSON.stringify(item)}`,
        backgroundColor: "linear-gradient(0deg, #F9FAFB, #F9FAFB),linear-gradient(0deg, #E8E8E8, #E8E8E8)",
        cell: (cell) => {
          let formatted = ""
          if (item.label.toLocaleLowerCase() === "spend") {
            formatted = formatNumberThousandSeparated(cell.getValue() ? cell.getValue() : 0, parseCurrency(currency), "standard")
          } else {
            formatted = `${formatUAData({ value: cell.getValue(), currency, column: item.label, placeholder: "-", type: "standard" })}`
          }
          return <div style={{ textAlign: "right" }}>{formatted}</div>
        },
        sortingFn: "alphanumeric"
      }))
    ]
  }, [data, colselections])

  const handleFilterSelection = (filters) => {
    setAdvancedFilters(filters)
    const combined = filters?.reduce((acc, item) => ({ ...acc, ...parseFilterExpressions(item, advancedFilterOptions) }), {})
    setAdvancedFiltersForApi(combined)
  }

  const handleColumnSelection = (columns) => {
    setColumnSelections(columns)
    toggleColumnSelection(false)
  }

  useEffect(() => {
    if (!!advancedFiltersFromParent) {
      setAdvancedFilters(af => [...af, ...advancedFiltersFromParent.filter(item => !!advancedFilterOptions.find(i => i.name === item.key))])
      const combined = advancedFiltersFromParent?.reduce((acc, item) => ({ ...acc, ...parseFilterExpressions(item, advancedFilterOptions) }), {})
      setAdvancedFiltersForApi(combined)
    }
  }, [advancedFiltersFromParent, advancedFilterOptions, cifDimensions])

  if (!Object.keys(jgDetails || {})?.length) return null;

  return (
    <JgBreakdownContainer>
      <Header>
        <TitleContainer>
          <Icon
            name="arrow-left"
            alt="back"
            onClick={() => {
              const params = Object.fromEntries([...searchParams]);
              const urlSearchParams = "?" + new URLSearchParams(params).toString();
              navigate({
                pathname: "/",
                search: urlSearchParams,
              });
            }}
          />
          <Title text="Job level breakdown" />
        </TitleContainer>
        <Subtitle>Job group : {jobGroupDetails?.jobGroupName || ""}</Subtitle>
      </Header>
      <AdvancedFilters
        filters={advancedFilterOptions}
        view={JOB_PERFORMANCE_VIEW}
        cifDimensions={cifDimensions}
        onChange={handleFilterSelection}
        showPublisherFilter={showPublisherFilter}
        value={advancedFilters}
        disabled={isDataLoading}
      />
      <ReportContainer>
        <ReportHeader>
          <SearchBox
            placeholder="Search jobs with title/req id"
            value={searchText}
            className="Job-breakdown__SearchBox"
            onChange={setSearchText}
          />
          <Button onClick={() => {
            window.innerWidth <= DEVICE_SIZES_PX.MOBILE ? setIsColumnSelectionBottomModalOpen(true) : toggleColumnSelection(!isColumnSelectionOpen)
          }}
            disabled={isDataLoading}
          >
            <Icon name="column" />
            Manage columns
          </Button>
        </ReportHeader>
        {
          (!isDataLoading && !!columns?.length) && (
            <TableContainer>
              <DataTable
                data={data}
                columns={columns}
                searchText={searchText}
                page={page}
                className={window.innerWidth > DEVICE_SIZES_PX.MOBILE ? "JobBreakdownTable" : ""}
                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={setPinnedColumns}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
              />
            </TableContainer>
          )
        }
        {
          (pageCount > 0 && !isDataLoading) && (
            <PaginationContainer>
              <Pagination
                pageCount={pageCount}
                currentPage={page}
                onPageChange={setPage}
              />
            </PaginationContainer>
          )
        }
        {
          (isDataLoading || isJobMetricsLoading) && (
            <LoaderContainer>
              <Loader size="medium" />
            </LoaderContainer>
          )
        }
      </ReportContainer>
      {
        isColumnSelectionOpen && (
          <Modal position="center" className="ColumnSelectionModal" onClose={toggleColumnSelection}>
            <ColumnSelection
              dimensions={dimensions}
              metrics={jobMetricKeys}
              selections={columnsSelected}
              onSubmit={handleColumnSelection}
              onClose={() => {
                toggleColumnSelection(false)
              }} />
          </Modal>
        )
      }
      {isColumnSelectionBottomModalOpen && (
        <BottomModal
          isOpen={isColumnSelectionBottomModalOpen}
          onClose={() => {
            setIsColumnSelectionBottomModalOpen(false)
          }}
        >
          <ColumnSelectionMobileView
            dimensions={dimensions}
            metrics={jobMetricKeys}
            selections={columnsSelected}
            onSubmit={handleColumnSelection}
            onClose={() => {
              setIsColumnSelectionBottomModalOpen(false)
            }}
          />
        </BottomModal>
      )}
    </JgBreakdownContainer>
  );
}

export default JobLevelBreakdown;

import { useQuery } from 'react-query';

import { getLookerData } from '../services/apis';
import { getItemFromLocalStorage } from '../helpers/commonHelper';
import { LOOKER_ACCESS_TOKEN_LS_KEY } from '../constants/AppConstants';

export const useFetchCampaigns = (payload) => {
  return useQuery(
    ["campaigns", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY) && !!payload?.["model"]
    },
  );
}

export const useFetchSources = (payload) => {
  return useQuery(
    ["sources", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY) && !!payload?.["model"]
    },
  );
}

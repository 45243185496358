import styled from 'styled-components'

export const SearchFilterContainer = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 5px 5px 0px #00000033;
  box-shadow: 0px 3px 14px 0px #0000001F;
  box-shadow: 0px 8px 10px 0px #00000024;
`
export const DropdownSearch = styled.div`
  padding: 0.6rem 1rem;
  border-top: 1px solid #edf2f7;
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;

  input {
    color: #4a5568;
  }

  input::placeholder {
    color: #9fa6b2;
    font-size: 0.9rem;
  }

  .SearchImg {
    width: 14px;
    height: 14px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.9rem;
`;

export const DropdownOptions = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 16rem;
  overflow: scroll;
  background-color: #FFFFFF;
  /* border-radius: 0.5rem; */
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  color: #374151;

  &:hover {
    background-color: #edf2f7;
  }
`;
export const EmptySearchResult = styled.p`
  padding: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;
export const OptionText = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
`;
import {
  Result,
  SearchMSAContainer,
  EmptyContainer
} from "./styles";

import Icon from "../../../../components/common/Icon";
import EmptyText from "../../../../components/common/EmptyText";

const SearchMSA = ({
  results,
  className,
  onSelect,
  selected
}) => {
  return (
    <SearchMSAContainer className={className}>
      {
        !results?.length && (
          <EmptyContainer>
            <EmptyText text="No results found" />
          </EmptyContainer>
        )
      }
      {
        results?.map((result) => (
          <Result
            key={result.id}
            selected={result.id === selected}
            onClick={() => onSelect(result)}
          >
            {`${result.countryLabel} > ${result.value}`}
            {
              (result.id === selected) && (
                <Icon
                  name="tick"
                  style={{ cursor: "pointer" }}
                  width="14"
                  height="14"
                />
              )
            }
          </Result>
        ))
      }
    </SearchMSAContainer>
  );
}

export default SearchMSA;

import {
  CAREER_SITE_IDENTIFIERS,
  CRM_IDENTIFIERS,
  JOB_SITE_IDENTIFIERS,
  LOOKER_FILTER_KEYS,
  PROGRAMMATIC_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS
} from "../../constants/AppConstants"
import { DROPDOWN_ENTITY } from "./helpers"

const getProgrammaticMetadata = ({
  accountOptions,
  subAccountOptions,
  clientOptions,
  campaignOptions,
  jobGroupOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  return {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.ACCOUNTS,
      count: accountOptions?.length || 0,
      options: accountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      onSelect: (opt) => {
        setNavFilters({
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        })
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      }
    },
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.SUB_ACCOUNTS,
      count: subAccountOptions?.length || 0,
      options: subAccountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      back: LOOKER_FILTER_KEYS.ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.CLIENT_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
      }
    },
    [LOOKER_FILTER_KEYS.CLIENT_ID]: {
      title: DROPDOWN_ENTITY.CLIENT,
      count: clientOptions?.length || 0,
      options: clientOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.CLIENT_ID],
      back: LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.CLIENT_ID]: opt,
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.CAMPAIGN_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
      }
    },
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: {
      title: DROPDOWN_ENTITY.CAMPAIGN,
      count: campaignOptions?.length || 0,
      options: campaignOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.CAMPAIGN_ID],
      back: LOOKER_FILTER_KEYS.CLIENT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: opt,
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.JOB_GROUP_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.CLIENT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
      }
    },
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: {
      title: DROPDOWN_ENTITY.JOB_GROUP,
      count: jobGroupOptions?.length || 0,
      options: jobGroupOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.JOB_GROUP_ID],
      back: LOOKER_FILTER_KEYS.CAMPAIGN_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: opt
        }))
        setSearchValue("")
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.CAMPAIGN_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
        }))
      }
    }
  }
}

const getCareerSiteMetadata = ({
  accountOptions,
  subAccountOptions,
  siteOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  return {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.ACCOUNTS,
      count: accountOptions?.length || 0,
      options: accountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      onSelect: (opt) => {
        setNavFilters({
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.SITE_ID]: ""
        })
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      }
    },
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.SUB_ACCOUNTS,
      count: subAccountOptions?.length || 0,
      options: subAccountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      back: LOOKER_FILTER_KEYS.ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SITE_ID]: ""
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SITE_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.SITE_ID]: ""
        }))
      }
    },
    [LOOKER_FILTER_KEYS.SITE_ID]: {
      title: DROPDOWN_ENTITY.SITES,
      count: siteOptions?.length || 0,
      options: siteOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SITE_ID],
      back: LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SITE_ID]: opt
        }))
        setSearchValue("")
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SITE_ID]: ""
        }))
      }
    }
  }
}

const getCRMMetadata = ({
  accountOptions,
  subAccountOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  return {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.ACCOUNTS,
      count: accountOptions?.length || 0,
      options: accountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      onSelect: (opt) => {
        setNavFilters({
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
        })
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      }
    },
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.SUB_ACCOUNTS,
      count: subAccountOptions?.length || 0,
      options: subAccountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      back: LOOKER_FILTER_KEYS.ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: opt,
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
        }))
      }
    },
  }
}
const getJobSiteMetaData = ({
  accountOptions,
  subAccountOptions,
  siteOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  return {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.ACCOUNTS,
      count: accountOptions?.length || 0,
      options: accountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      onSelect: (opt) => {
        setNavFilters({
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.SITE_ID]: "",
        });
        setSearchValue("");
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID);
        }
      },
    },
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.SUB_ACCOUNTS,
      count: subAccountOptions?.length || 0,
      options: subAccountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      back: LOOKER_FILTER_KEYS.ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters((nf) => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SITE_ID]: "",
        }));
        setSearchValue("");
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SITE_ID);
        }
      },
      onBack: () => {
        setSearchValue("");
        setCurrentEntity(LOOKER_FILTER_KEYS.ACCOUNT_ID);
        setNavFilters((nf) => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
          [LOOKER_FILTER_KEYS.SITE_ID]: "",
        }));
      },
    },
    [LOOKER_FILTER_KEYS.SITE_ID]: {
      title: DROPDOWN_ENTITY.SITES,
      count: siteOptions?.length || 0,
      options: siteOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SITE_ID],
      back: LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters((nf) => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SITE_ID]: opt,
        }));
        setSearchValue("");
      },
      onBack: () => {
        setSearchValue("");
        setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID);
        setNavFilters((nf) => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SITE_ID]: "",
        }));
      },
    },
  };
}

const getUnifiedViewMetadata = ({
  accountOptions,
  subAccountOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  return {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.ACCOUNTS,
      count: accountOptions?.length || 0,
      options: accountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID],
      onSelect: (opt) => {
        setNavFilters({
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: opt,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
        })
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      }
    },
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: {
      title: DROPDOWN_ENTITY.SUB_ACCOUNTS,
      count: subAccountOptions?.length || 0,
      options: subAccountOptions,
      selected: navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
      back: LOOKER_FILTER_KEYS.ACCOUNT_ID,
      onSelect: (opt) => {
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: opt,
        }))
        setSearchValue("")
        if (!!opt) {
          setCurrentEntity(LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID)
        }
      },
      onBack: () => {
        setSearchValue("")
        setCurrentEntity(LOOKER_FILTER_KEYS.ACCOUNT_ID)
        setNavFilters(nf => ({
          ...nf,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
        }))
      }
    },
  }
}

export const getProductMetadata = ({
  product,
  clientOptions,
  campaignOptions,
  jobGroupOptions,
  accountOptions,
  subAccountOptions,
  siteOptions,
  setNavFilters,
  setSearchValue,
  setCurrentEntity,
  navFilters
}) => {
  if (!product) return null

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return getProgrammaticMetadata({
        accountOptions,
        subAccountOptions,
        clientOptions,
        campaignOptions,
        jobGroupOptions,
        setNavFilters,
        setSearchValue,
        setCurrentEntity,
        navFilters
      })
    case CAREER_SITE_IDENTIFIERS.productKey:
      return getCareerSiteMetadata({
        accountOptions,
        subAccountOptions,
        siteOptions,
        setNavFilters,
        setSearchValue,
        setCurrentEntity,
        navFilters
      })
    case CRM_IDENTIFIERS.productKey:
      return getCRMMetadata({
        accountOptions,
        subAccountOptions,
        setNavFilters,
        setSearchValue,
        setCurrentEntity,
        navFilters
      })
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return getUnifiedViewMetadata({
        accountOptions,
        subAccountOptions,
        setNavFilters,
        setSearchValue,
        setCurrentEntity,
        navFilters,
      });
    case JOB_SITE_IDENTIFIERS.productKey:
      return getJobSiteMetaData({
        accountOptions,
        subAccountOptions,
        siteOptions,
        setNavFilters,
        setSearchValue,
        setCurrentEntity,
        navFilters
      })
    default:
      return ""
  }
}

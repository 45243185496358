export const JOB_COMPENSATION_CONFIG_HOME = {
  showTitle: true,
  title: "Compensations",
  viewMore: true,
  viewMoreDbKey: "jobCompensation",
  viewMorePageTitle: 'Job compensation'
};

export const JOB_COMPENSATION_FILTER_CONFIG = {
  title: "Compensation by",
  inputType: "radio",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  showBetaFeature: true,
  betaText: ["We are improving the way to calculate jobs compensation. If you have any feedback to share, ", "Click here"],
  filtersToUpdate: [
    {
      name: "selectDimension",
    },
  ],
  defaultFilters: [
    {
      name: "Category",
    },
  ],
};

export const JOB_COMPENSATION_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  maxRows: 8,
  dynamicColumns: true,
  dynamicRows: false,
  currencyTargetKey: "inbound_norm_jobs.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "Job title",
      dataTargetKey: "inbound_norm_jobs.job_title",
      dataValueKey: "value",
    },
    {
      title: "$$1",
      dataTargetKey: "inbound_norm_jobs.selected_dimension",
      dataValueKey: "value",
    },
    {
      title: "Compensation",
      dataTargetKey: "inbound_norm_jobs.avg_compensation",
      dataValueKey: "value",
      type: "annualCompensation",
    },
    {
      title: "Median Market rate",
      dataTargetKey: "inbound_norm_jobs.avg_median_market_rate",
      dataValueKey: "value",
      type: "annualCompensation"
    },
    {
      title: "Difference from median",
      dataTargetKey: "inbound_norm_jobs.difference_from_median",
      dataValueKey: "rendered",
      type: "compensationDifference"
    },
    {
      title: "Distribution",
      type: "link",
      dataTargetKey: "inbound_norm_jobs.avg_compensation",
      dataValueKey: "value",
    },
  ],
  lookerFilters: [
    {
      name: "inbound_norm_jobs.select_dimension",
    },
  ],
  search: true,
  searchPlaceholder: 'Search by ',
  searchFields: [
    {
      dataTargetKey: "inbound_norm_jobs.job_title",
      dataValueKey: "value",
      title: 'Job Title'
    },
  ]
};

export const JOB_COMPENSATION_VIEW_MORE_CONFIG = {
  showTitle: true,
  title: "Compensation by $$1",
  optionSelection: false,
  legends: true,
  dimensionKey: 'inbound_norm_jobs.selected_dimension',
  dataColumns: [
    {
      name: "Paying more than market",
      color: "#64B4F6",
      key: "inbound_norm_jobs.paying_more_than_market",
    },
    {
      name: "Normal range",
      color: "#87C88C",
      key: "inbound_norm_jobs.paying_in_range_of_market",
    },
    {
      name: "Paying less than market",
      color: "#F8B788",
      key: "inbound_norm_jobs.paying_less_than_market",
    },
    {
      name: "No salary data",
      color: "#DBDBDB",
      key: "inbound_norm_jobs.jobs_without_salary_data",
    },
  ],
  viewMore: false,
  xAxisKey: "inbound_norm_jobs.selected_dimension",
  xAxisKeyType: "string",
  yAxisTitle: '% of jobs',
  lookerFilters: [
    {
      key: "entity",
      filterTargetKey: "inbound_norm_jobs.select_dimension",
      filterValueKey: "value",
    },
  ],
};

export const JOB_COMPENSATION_COMPENSATION_VS_PERFORMANCE_VIEW_MORE_CONFIG = {
  showTitle: true,
  title: "Compensation vs Performance",
  viewMore: false,
  multipleDropdowns: true,
  publisherTargetKey: "publisher_analysis.publisher",
  publisherValueKey: "value",
  optionSelection: true,
  addTotalRowInData: true,
  isJobPerformancePieChart: true,
  jobCountKey: 'inbound_norm_jobs_and_stats.count',
  valueKey2: 'value2',
  dimensionKey: 'inbound_norm_jobs_and_stats.selected_dimension',
  filterTargetKey: 'inbound_norm_jobs_and_stats.select_dimension',
  filterValueKey: 'value',
  legends: [
    {
      name: "Paying more than market",
      color: "#64B4F6",
      key: "inbound_norm_jobs_and_stats.paying_more_than_market",
    },
    {
      name: "Normal range",
      color: "#87C88C",
      key: "inbound_norm_jobs_and_stats.paying_in_range_of_market",
    },
    {
      name: "Paying less than market",
      color: "#F8B788",
      key: "inbound_norm_jobs_and_stats.paying_less_than_market",
    },
    {
      name: "No salary data",
      color: "#DBDBDB",
      key: "inbound_norm_jobs_and_stats.jobs_without_salary_data",
    },
  ],
  isInitialDropdown: true,
  selectAllOptionNames: {
    'Category': 'All Categories',
    'City': 'All Cities',
    'Country': 'All Countries',
    'State': 'All States',
    'Campaign': 'All Campaigns',
    'Job group': 'All Job Groups'
  },
  options: [
    {
      key: "inbound_norm_jobs_and_stats.CPA",
      label: "CPA",
      display: "CPA",
    },
    {
      key: "inbound_norm_jobs_and_stats.CPC",
      label: "CPC",
      display: "CPC",
    },
    {
      key: "inbound_norm_jobs_and_stats.CTA",
      label: "CTA%",
      display: "CTA%",
    },
  ],
};


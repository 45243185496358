import {
  startOfToday,
  startOfYesterday,
  startOfWeek,
  endOfWeek,
  endOfToday,
  endOfYesterday,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  sub,
} from "date-fns";
import { formatDate } from "../utils/dateHelpers";
import { HIRING_FUNNEL_FILTER_CONFIG } from "../configs/Programmatic/HiringFunnel";
import { PUBLISHER_ANALYSIS_FILTER_CONFIG } from "../configs/Programmatic/PublisherAnalysis";
import { COMPARE_FILTER_CONFIG } from "../configs/Programmatic/Compare";
import { TIME_DISTRIBUTION_METRICS_FILTER_CONFIG } from "../configs/Programmatic/TimeDistribution";
import { GEOGRAPHIC_DISTRIBUTION_METRICS_FILTER_CONFIG } from "../configs/Programmatic/GeographicDistribution";
import { JOB_COMPENSATION_FILTER_CONFIG } from "../configs/Programmatic/JobCompensation";
import { SOURCE_WISE_FILTER_CONFIG } from "../configs/Programmatic/SourceWise";
import { CAREER_SITE_ROUTE, CRM_ROUTE, PROGRAMMATIC_ROUTE, UNIFIED_VIEW_ROUTE, JOB_SITE_ROUTE, MARKET_INSIGHTS_ROUTE, JOB_QUALITY_ROUTE, COMPETITOR_INSIGHTS_ROUTE } from "./AppRoutes";

export const AMAZON_DEMO_AGENCY_ID = "amazondemo"
export const AMAZON_DEMO_EMAIL = "amazonwfs@joveodemo.com"

export const DEFAULT_COUNTRY_MAP_AMAZON = [
  {
    email: "vargaspg@amazon.com",
    defaultCountry: "Mexico"
  },
  {
    email: "cmoravia@amazon.com",
    defaultCountry: "Brazil"
  }
]

export const UA_PRODUCT_ID = "UNIFIED_ANALYTICS"

export const STRING_SEPARATOR = "$$$"
export const API_CACHE_STALE_TIME = 30000

export const CAMPAIGN_PERFORMANCE_VIEW = "entity_performance"
export const JOB_PERFORMANCE_VIEW = "job_performance"
export const HOME_DASHBOARD_VIEW = "view_combined_event"

export const UNIFIED_ANALYTICS_FEEDBACK_EMAIL = "feedback_ua@joveo.com"

export const LOOKER_USER_ID_KEY = "lookerUserId"
export const ACCESS_TOKEN_LS_KEY = "accessToken"
export const REFRESH_TOKEN_LS_KEY = "refreshToken"
export const LOOKER_ACCESS_TOKEN_LS_KEY = "lookerAccessToken"
export const EMAIL_LS_KEY = "email"
export const IDENTITY_PROVIDER_LS_KEY = "identityProvider"
export const USERNAME_LS_KEY = "username"
export const TIMEZONE_LS_KEY = "timezone"
export const LOOKER_USER_ID_LS_KEY = "lookerUserId"
export const SHOW_MARKET_INSIGHTS_LS_KEY = "showMarketInsights"
export const SEEN_ROADMAP_FEATURES_LS_KEY = "seenRoadmapFeatures"
export const SIDEBAR_EXPANSION_LS_KEY = "isSidebarExpanded"
export const EXCHANGE_VISIBILITY_LS_KEY = "exchangeVisibility"

export const PUBLISHER_FILTERS = ["Publisher name", "Publisher type"]

export const LOOKER_FILTER_KEYS = {
  ACCOUNT_ID: "accountId",
  SUB_ACCOUNT_ID: "subAccountId",
  SITE_ID: "websiteId",
  AGENCY_ID: "agencyId",
  CLIENT_ID: "clientId",
  CAMPAIGN_ID: "campaignId",
  JOB_GROUP_ID: "jobgroupId",
  DASHBOARD_ID: "dashboardId",
  CURRENCY_RATE: "currencyRate",
  DATE: "Date",
  DATE_PROJECTED_SPEND: "dateProjectedSpend",
  CUSTOM_DASHBOARD: "isCustomDashboard",
  FILTER_ID: "filterId",
  EXCHANGE_VISIBILITY: "exchangeVisibility",
};

export const COMMON_GLOBAL_FILTERS = [
  LOOKER_FILTER_KEYS.CURRENCY_RATE,
  LOOKER_FILTER_KEYS.DASHBOARD_ID,
  LOOKER_FILTER_KEYS.DATE,
  LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY
]

const PROGRAMMATIC_UNLOCK_TITLE = "Transparent Programmatic Job Advertising Platform"
const PROGRAMMATIC_UNLOCK_TEXT = "Get your job ads in front of the right people – at the right place and time, for the right price! With our AI-driven approach to media planning and optimization, you can spend with precision on sources that deliver."
const PROGRAMMATIC_PRODUCT_TITLE = "All the programmatic insights you need, right at your fingertips."
const PROGRAMMATIC_PRODUCT_TEXT = "With predictive analytics and an easy-to-use dashboard, you can review and compare performance and costs across all your talent sources in real time. Whether it's a job site, search engine, or social channel, get 360° visibility, including unique stats from your job ad exchange—unlike other programmatic platforms."


const CAREER_SITE_UNLOCK_TITLE = "Hyper Personalized and Dynamic Career Sites That Let Your Brand Personality Shine Through"
const CAREER_SITE_UNLOCK_TEXT = "Make your career site experience personal. Give job seekers relevant, up-to-date information about your brand and values. Real time tracking and analytics to improve engagement and conversion."
const CAREER_SITE_PRODUCT_TITLE = "All the career site insights you need, right at your fingertips."
const CAREER_SITE_PRODUCT_TEXT = "Optimize your career site with data-driven decisions and real-time analytics. Leverage insights on engagement and conversion to tailor the experience, ensuring job seekers get relevant brand information. Continuously enhance the site with actionable data to attract top talent."

const CRM_UNLOCK_TITLE = "AI-Powered Multi-Channel Engagement Platform"
const CRM_UNLOCK_TEXT = "Make the most of your candidate database! Send automated yet personalized messages to job seekers on channels they like to use. Forge enduring relationships with your future hires, even before they apply to your jobs. Cut your dependence and spend on paid media in your hunt for great-fit talent."
const CRM_PRODUCT_TITLE = "All the CRM analytics you need, right at your fingertips."
const CRM_PRODUCT_TEXT = "Experience a seamless platform that consolidates data from every aspect of your CRM. Access real-time analytics and insights with just a click. Empower your team to collaborate more effectively by leveraging data-driven insights for success."

const OVERVIEW_UNLOCK_TITLE = "All Your Talent Sourcing Data and Predictive, Actionable Insights Unified in a Single View"
const OVERVIEW_UNLOCK_TEXT = "Effortlessly integrate internal and third-party data into a unified platform. Say goodbye to fragmented information from career sites, CRMs, job boards, and more. Our solution empowers you to make informed decisions that elevate your sourcing strategy."


export const PROGRAMMATIC_IDENTIFIERS = {
  productKey: "PROGRAMMATIC",
  apiIdentifierKey: "programmatic",
  display: "Programmatic",
  dashboardMapIdentifierKey: "client",
  route: PROGRAMMATIC_ROUTE,
  icon: "programmatic",
  modelKey: "programmatic",
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
    LOOKER_FILTER_KEYS.CLIENT_ID,
    LOOKER_FILTER_KEYS.CAMPAIGN_ID,
    LOOKER_FILTER_KEYS.JOB_GROUP_ID,
    LOOKER_FILTER_KEYS.AGENCY_ID,
    LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY,
    LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND,
    LOOKER_FILTER_KEYS.CUSTOM_DASHBOARD
  ],
  subtitle: "Track, manage and forecast your campaign performance",
  unlockDetails: {
    bannerSrc: "Programmatic_banner.png",
    widgetsSrc: "Programmatic_widgets.png",
    unlockTitle: PROGRAMMATIC_UNLOCK_TITLE,
    unlockText: PROGRAMMATIC_UNLOCK_TEXT,
    productTitle: PROGRAMMATIC_PRODUCT_TITLE,
    productText: PROGRAMMATIC_PRODUCT_TEXT
  },
  isActive: (location) => (location.pathname === PROGRAMMATIC_ROUTE || location.pathname === JOB_QUALITY_ROUTE),
  isLocked: (products) => {
    return !products?.length ||
      !products.find(item => item.productKey === PROGRAMMATIC_IDENTIFIERS.productKey)
  }
}

export const CAREER_SITE_IDENTIFIERS = {
  productKey: "CS",
  apiIdentifierKey: "career-site",
  display: "Career site",
  dashboardMapIdentifierKey: "careerSite",
  route: CAREER_SITE_ROUTE,
  icon: "career-site",
  modelKey: "cs",
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SITE_ID,
  ],
  subtitle: "Track, manage, and forecast your career site performance, etc",
  unlockDetails: {
    bannerSrc: "CS_banner.png",
    widgetsSrc: "CS_widgets.png",
    unlockTitle: CAREER_SITE_UNLOCK_TITLE,
    unlockText: CAREER_SITE_UNLOCK_TEXT,
    productTitle: CAREER_SITE_PRODUCT_TITLE,
    productText: CAREER_SITE_PRODUCT_TEXT
  },
  isActive: (location) => location.pathname.includes(CAREER_SITE_ROUTE),
  isLocked: (products) => {
    return !products?.length ||
      !products.find(item => item.productKey === CAREER_SITE_IDENTIFIERS.productKey)
  }
}

export const CRM_IDENTIFIERS = {
  productKey: "CRM",
  apiIdentifierKey: "crm",
  display: "CRM",
  dashboardMapIdentifierKey: "crm",
  icon: "crm",
  modelKey: "crm",
  route: CRM_ROUTE,
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
  ],
  subtitle: "This is the spot to track, manage, and forecast your job performance",
  unlockDetails: {
    bannerSrc: "CRM_banner.png",
    widgetsSrc: "CRM_widgets.png",
    unlockTitle: CRM_UNLOCK_TITLE,
    unlockText: CRM_UNLOCK_TEXT,
    productTitle: CRM_PRODUCT_TITLE,
    productText: CRM_PRODUCT_TEXT
  },
  isActive: (location) => location.pathname.includes(CRM_ROUTE),
  isLocked: (products) => {
    return !products?.length ||
      !products.find(item => item.productKey === CRM_IDENTIFIERS.productKey)
  }
}

export const UNIFIED_VIEW_IDENTIFIERS = {
  productKey: "ALL",
  apiIdentifierKey: "unified-view",
  display: "Overview",
  icon: "overview",
  modelKey: "all",
  dashboardMapIdentifierKey: "unified-view",
  route: UNIFIED_VIEW_ROUTE,
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
    LOOKER_FILTER_KEYS.CLIENT_ID,
  ],
  subtitle: "Track, manage, and forecast your job performance, etc.",
  unlockDetails: {
    bannerSrc: "Overview_widgets.png",
    unlockTitle: OVERVIEW_UNLOCK_TITLE,
    unlockText: OVERVIEW_UNLOCK_TEXT,
  }
}

export const MARKET_INSIGHTS_IDENTIFIERS = {
  productKey: "PROGRAMMATIC",
  apiIdentifierKey: "market-insight",
  display: "Programmatic",
  dashboardMapIdentifierKey: "marketInsights",
  route: MARKET_INSIGHTS_ROUTE,
  icon: "programmatic",
  modelKey: "marketInsights",
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
    LOOKER_FILTER_KEYS.CLIENT_ID
  ],
}

export const COMPETITOR_INSIGHTS_IDENTIFIERS = {
  productKey: "PROGRAMMATIC",
  apiIdentifierKey: "competitor-insight",
  display: "Programmatic",
  dashboardMapIdentifierKey: "competitorInsights",
  route: COMPETITOR_INSIGHTS_ROUTE,
  icon: "programmatic",
  modelKey: "competitorInsights",
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
    LOOKER_FILTER_KEYS.CLIENT_ID
  ],
}

export const JOB_SITE_IDENTIFIERS = {
  productKey: "JOB_SITE",
  apiIdentifierKey: "job-site",
  display: "Job site",
  dashboardMapIdentifierKey: "jobSite",
  icon: "live-jobs",
  route: JOB_SITE_ROUTE,
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
  ],
  isActive: (location) => location.pathname.includes(JOB_SITE_ROUTE)
}

export const CAREER_AND_JOB_SITE_IDENTIFIERS = {
  productKey: "CAREER_AND_JOB_SITE",
  apiIdentifierKey: "job-site",
  display: "Career & Job site",
  dashboardMapIdentifierKey: "careerSite",
  icon: "career-site",
  route: CAREER_SITE_ROUTE,
  globalFilters: [
    LOOKER_FILTER_KEYS.ACCOUNT_ID,
    LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID,
  ],
  isActive: (location) => location.pathname.includes(JOB_SITE_ROUTE) || location.pathname.includes(CAREER_SITE_ROUTE)
}

export const PRODUCTS_MAP = {
  [PROGRAMMATIC_IDENTIFIERS.productKey]: {
    id: PROGRAMMATIC_IDENTIFIERS.productKey,
    ...PROGRAMMATIC_IDENTIFIERS
  },
  [CAREER_SITE_IDENTIFIERS.productKey]: {
    id: CAREER_SITE_IDENTIFIERS.productKey,
    ...CAREER_SITE_IDENTIFIERS
  },
  [CRM_IDENTIFIERS.productKey]: {
    id: CRM_IDENTIFIERS.productKey,
    ...CRM_IDENTIFIERS
  },
  [UNIFIED_VIEW_IDENTIFIERS.productKey]: {
    id: UNIFIED_VIEW_IDENTIFIERS.productKey,
    ...UNIFIED_VIEW_IDENTIFIERS
  },
  [JOB_SITE_IDENTIFIERS.productKey]: {
    id: JOB_SITE_IDENTIFIERS.productKey,
    ...JOB_SITE_IDENTIFIERS
  },
}

const MONTH_DATE_FILTER_STRING = `${formatDate(
  startOfMonth(new Date()),
  "start"
)} to ${formatDate(endOfMonth(new Date()), "end")}`;

const THIS_MONTH_FILTER_STRING = `${formatDate(
  startOfMonth(new Date()),
  "start"
)} to ${formatDate(new Date(), "end")}`;

export const DATE_OPTIONS = [
  {
    key: "today",
    display: "Today",
    getDateRange: () => {
      return [startOfToday(), endOfToday()];
    },
    getDateFilterString: () => {
      return `${formatDate(startOfToday(), "start")} to ${formatDate(
        endOfToday(),
        "end"
      )}`;
    },
  },
  {
    key: "yesterday",
    display: "Yesterday",
    getDateRange: () => {
      return [startOfYesterday(), endOfYesterday()];
    },
    getDateFilterString: () => {
      return `${formatDate(startOfYesterday(), "start")} to ${formatDate(
        endOfYesterday(),
        "end"
      )}`;
    },
  },
  {
    key: "week",
    display: "Week",
    expandable: true,
    getDateFilterString: () => ""
    // getDateRange: () => {
    //   try {
    //     const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
    //     return [startOfWeek(new Date(), { weekStartsOn }), new Date()];
    //   } catch (e) {
    //     return [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()];
    //   }
    // },
    // getDateFilterString: () => {
    //   try {
    //     const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
    //     return `${formatDate(
    //       startOfWeek(new Date(), { weekStartsOn }),
    //       "start"
    //     )} to ${formatDate(new Date(), "end")}`;
    //   } catch (e) {
    //     return `${formatDate(
    //       startOfWeek(new Date(), { weekStartsOn: 1 }),
    //       "start"
    //     )} to ${formatDate(new Date(), "end")}`;
    //   }
    // },
  },
  // {
  //   key: "this week",
  //   display: "This week",
  //   getDateRange: () => {
  //     try {
  //       const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
  //       return [startOfWeek(new Date(), { weekStartsOn }), new Date()];
  //     } catch (e) {
  //       return [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()];
  //     }
  //   },
  //   getDateFilterString: () => {
  //     try {
  //       const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
  //       return `${formatDate(
  //         startOfWeek(new Date(), { weekStartsOn }),
  //         "start"
  //       )} to ${formatDate(new Date(), "end")}`;
  //     } catch (e) {
  //       return `${formatDate(
  //         startOfWeek(new Date(), { weekStartsOn: 1 }),
  //         "start"
  //       )} to ${formatDate(new Date(), "end")}`;
  //     }
  //   },
  // },
  // {
  //   key: "last week",
  //   display: "Last week",
  //   getDateRange: () => {
  //     try {
  //       const today = new Date();
  //       const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
  //       const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
  //         weekStartsOn,
  //       });
  //       const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn });
  //       return [startOfLastWeek, endOfLastWeek];
  //     } catch (e) {
  //       const today = new Date();
  //       const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
  //         weekStartsOn: 1,
  //       });
  //       const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
  //       return [startOfLastWeek, endOfLastWeek];
  //     }
  //   },
  // getDateFilterString: () => {
  //   try {
  //     const today = new Date();
  //     const weekStartsOn = !!localStorage ? (localStorage?.getItem("email") === AMAZON_DEMO_EMAIL ? 0 : 1) : 1
  //     const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
  //       weekStartsOn,
  //     });
  //     const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn });
  //     return `${formatDate(startOfLastWeek, "start")} to ${formatDate(
  //       endOfLastWeek,
  //       "end"
  //     )}`;
  //   } catch (e) {
  //     const today = new Date();
  //     const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
  //       weekStartsOn: 1,
  //     });
  //     const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
  //     return `${formatDate(startOfLastWeek, "start")} to ${formatDate(
  //       endOfLastWeek,
  //       "end"
  //     )}`;
  //   }
  // },
  // },
  {
    key: "this month",
    display: "This month",
    getDateRange: () => {
      return [startOfMonth(new Date()), new Date()];
    },
    getDateFilterString: () => {
      return `${formatDate(startOfMonth(new Date()), "start")} to ${formatDate(
        new Date(),
        "end"
      )}`;
    },
    getDateFilterStringTillEnd: () => MONTH_DATE_FILTER_STRING,
  },
  {
    key: "last month",
    display: "Last month",
    getDateRange: () => {
      const today = new Date();
      const startOfLastMonth = startOfMonth(subMonths(today, 1));
      const endOfLastMonth = endOfMonth(subMonths(today, 1));
      return [startOfLastMonth, endOfLastMonth];
    },
    getDateFilterString: () => {
      const today = new Date();
      const startOfLastMonth = startOfMonth(subMonths(today, 1));
      const endOfLastMonth = endOfMonth(subMonths(today, 1));
      return `${formatDate(startOfLastMonth, "start")} to ${formatDate(
        endOfLastMonth,
        "end"
      )}`;
    },
  },
  {
    key: "30 days",
    display: "Last 30 days",
    getDateRange: () => {
      const yesterday = endOfYesterday();
      const thirtyDaysAgo = sub(yesterday, { days: 30 });
      return [thirtyDaysAgo, yesterday];
    },
    getDateFilterString: () => {
      const yesterday = endOfYesterday();
      const thirtyDaysAgo = sub(yesterday, { days: 30 });
      return `${formatDate(thirtyDaysAgo, "start")} to ${formatDate(
        yesterday,
        "end"
      )}`;
    },
  },
  {
    key: "custom",
    display: "Custom range",
    getDateRange: () => [null, null],
    getDateFilterString: (range) => {
      if (!range) return "";
      return `${formatDate(range[0], "start")} to ${formatDate(
        range[1],
        "end"
      )}`;
    },
  },
];

export const STATUS_OPTIONS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PAUSED: "PAUSED",
};

export const CURRENCY_KEYS = {
  DOLLAR: "usd",
  EURO: "eur",
  POUND: "gbp",
  CANADIAN_DOLLAR: "cad",
  SWISS_FRANC: "chf",
  AUSTRALIAN_DOLLAR: "aud",
  INDIAN_RUPEE: "inr",
  SINGAPORE_DOLLAR: "sgd",
  MEXICAN_PESO: "mxn",
  BRAZILLIAN_REAL: "brl",
};

export const CURRENCY_SIGNS = {
  DOLLAR: "$",
  EURO: "€",
  POUND: "£",
  CANADIAN_DOLLAR: "C$",
  SWISS_FRANC: "CHF",
  AUSTRALIAN_DOLLAR: "A$",
  INDIAN_RUPEE: "₹",
  SINGAPORE_DOLLAR: "S$",
  MEXICAN_PESO: "Mex$",
  BRAZILLIAN_REAL: "R$",
};

export const CURRENCY_KEY_TO_SIGN_MAP = {
  [CURRENCY_KEYS.DOLLAR]: CURRENCY_SIGNS.DOLLAR,
  [CURRENCY_KEYS.EURO]: CURRENCY_SIGNS.EURO,
  [CURRENCY_KEYS.POUND]: CURRENCY_SIGNS.POUND,
  [CURRENCY_KEYS.CANADIAN_DOLLAR]: CURRENCY_SIGNS.CANADIAN_DOLLAR,
  [CURRENCY_KEYS.SWISS_FRANC]: CURRENCY_SIGNS.SWISS_FRANC,
  [CURRENCY_KEYS.AUSTRALIAN_DOLLAR]: CURRENCY_SIGNS.AUSTRALIAN_DOLLAR,
  [CURRENCY_KEYS.INDIAN_RUPEE]: CURRENCY_SIGNS.INDIAN_RUPEE,
  [CURRENCY_KEYS.SINGAPORE_DOLLAR]: CURRENCY_SIGNS.SINGAPORE_DOLLAR,
  [CURRENCY_KEYS.MEXICAN_PESO]: CURRENCY_SIGNS.MEXICAN_PESO,
  [CURRENCY_KEYS.BRAZILLIAN_REAL]: CURRENCY_SIGNS.BRAZILLIAN_REAL,
};

export const CURRENCY_SIGN_TO_KEY_MAP = {
  [CURRENCY_SIGNS.DOLLAR]: CURRENCY_KEYS.DOLLAR,
  [CURRENCY_SIGNS.EURO]: CURRENCY_KEYS.EURO,
  [CURRENCY_SIGNS.POUND]: CURRENCY_KEYS.POUND,
  [CURRENCY_SIGNS.CANADIAN_DOLLAR]: CURRENCY_KEYS.CANADIAN_DOLLAR,
  [CURRENCY_SIGNS.SWISS_FRANC]: CURRENCY_KEYS.SWISS_FRANC,
  [CURRENCY_SIGNS.AUSTRALIAN_DOLLAR]: CURRENCY_KEYS.AUSTRALIAN_DOLLAR,
  [CURRENCY_SIGNS.INDIAN_RUPEE]: CURRENCY_KEYS.INDIAN_RUPEE,
  [CURRENCY_SIGNS.SINGAPORE_DOLLAR]: CURRENCY_KEYS.SINGAPORE_DOLLAR,
  [CURRENCY_SIGNS.MEXICAN_PESO]: CURRENCY_KEYS.MEXICAN_PESO,
  [CURRENCY_SIGNS.BRAZILLIAN_REAL]: CURRENCY_KEYS.BRAZILLIAN_REAL,
};

export const PUBLISHER_TYPES = [
  { id: 6, name: "Organic", value: "Organic" },
  { id: 2, name: "CPC", value: "CPC" },
  { id: 7, name: "CPA", value: "CPA" },
  { id: 4, name: "Target CPA", value: "TARGET_CPA" },
  { id: 8, name: "Flat CPC", value: "FLAT_CPC" },
  { id: 9, name: "Flat CPH", value: "FLAT_CPH" },
  { id: 1, name: "Job Slots", value: "CPS" },
  { id: 3, name: "Job Postings", value: "CPJ" },
  { id: 5, name: "Pay per posting", value: "PPP" },
]

export const EXPANSIONS_TYPES = [
  { id: 6, name: "All", value: "" },
  { id: 2, name: "Expanded", value: "Expanded" },
  { id: 7, name: "Not expanded", value: "Not expanded" },
]

export const HOME_ADVANCED_FILTERS = [
  { key: "Campaign name", filter: "campaignName" },
  { key: "Job group name", filter: "jobgroupName" },
  { key: "Publisher name", filter: "publisherName" },
  { key: "Publisher type", filter: "publisherType" },
]

export const UNIFIED_ANALYTICS_ORIGIN = {
  STAGING: "https://analytics.staging.joveo.com",
  PRE_PROD: "https://fe-unified-analytics-v1.prod.joveo.com",
  PROD: "https://analytics.joveo.com",
};

export const DASHBOARD_MAP = {
  campaign: "campaign",
  client: "client",
  jobGroup: "jobGroup",
  filter: {
    dashboardKey: "viewMoreHiringFunnel",
  },
  hiringFunnel: {
    title: "Hiring funnel",
    dashboardKey: "hiringFunnel",
    filterConfigKey: "hiringfunnel",
    filtersConfig: HIRING_FUNNEL_FILTER_CONFIG
  },
  publisherAnalysis: {
    title: "Publisher analysis",
    dashboardKey: "publisherAnalysis",
    filterConfigKey: "publisheranalysis",
    filtersConfig: PUBLISHER_ANALYSIS_FILTER_CONFIG
  },
  compareWidget: {
    title: "Comparison analysis",
    dashboardKey: "comparisonAnalysis",
    filterConfigKey: "compare",
    filtersConfig: COMPARE_FILTER_CONFIG
  },
  timeDistribution: {
    title: "Time distribution",
    dashboardKey: "rationalDistributionTime",
    filterConfigKey: "time",
    filtersConfig: TIME_DISTRIBUTION_METRICS_FILTER_CONFIG
  },
  geographicDistribution: {
    title: "Geographic distribution",
    dashboardKey: "rationalDistributionLocation",
    filterConfigKey: "geographic",
    filtersConfig: GEOGRAPHIC_DISTRIBUTION_METRICS_FILTER_CONFIG
  },
  jobCompensation: {
    title: "Job Compensation",
    dashboardKey: "jobCompensation",
    filterConfigKey: "job^_compensation",
    filtersConfig: JOB_COMPENSATION_FILTER_CONFIG
  },
  sourceWise: {
    title: "Source wise distribution",
    dashboardKey: "sourceWise",
    filterConfigKey: "source^_wise",
    filtersConfig: SOURCE_WISE_FILTER_CONFIG
  },
  marketInsights: "marketInsights",
  competitorInsights: "competitorInsights",
  programmatic: {
    dashboardKey: "client",
    route: "/"
  },
  careerSite: {
    dashboardKey: "careerSite",
    route: CAREER_SITE_ROUTE
  },
  crm: {
    dashboardKey: "crm",
    route: CRM_ROUTE
  },
  unifiedView: {
    dashboardKey: "unified-view",
    route: UNIFIED_VIEW_ROUTE
  },
  jobSite: {
    dashboardKey: "jobSite",
    route: JOB_SITE_ROUTE
  }
};

export const EXCHANGE_VISIBILITY = {
  NA: "NA",
  OPEN: "OPEN",
};

export const EXCHANGE_VISIBILITY_VALUES = {
  OPEN: "OPEN",
  CLOSE: "CLOSE"
};

export const LOOKER_FILTER_INITIAL_VALUES = {
  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: "",
  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
  [LOOKER_FILTER_KEYS.AGENCY_ID]: "",
  [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
  [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
  [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: "",
  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: "",
  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
  [LOOKER_FILTER_KEYS.SITE_ID]: "",
  [LOOKER_FILTER_KEYS.CUSTOM_DASHBOARD]: false,
  [LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND]: MONTH_DATE_FILTER_STRING,
  [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: "",
  [LOOKER_FILTER_KEYS.DATE]: THIS_MONTH_FILTER_STRING,
  [LOOKER_FILTER_KEYS.FILTER_ID]: "",
  [LOOKER_FILTER_KEYS.CURRENCY_RATE]: ""
};

export const GLOBAL_FILTERS_SUGGESTIONS_MAP = {
  ACCOUNT_ID: "view_combined_event.account_id",
  SUB_ACCOUNT_ID: "view_combined_event.sub_account_id",
  CLIENT_ID: "view_combined_event.clientid",
  CAMPAIGN_ID: "view_combined_event.campaignid",
  JOB_GROUP_ID: "view_combined_event.jobgroupid",
  DATE: "view_combined_event.date",
}

export const DEVICE_SIZES_PX = {
  MOBILE_SMALL: 320,
  MOBILE: 736,
  TABLET: 1024,
  DESKTOP: 2560,
}

export const SIZES = {
  MOBILE_SMALL: '320px',
  MOBILE: '736px',
  TABLET: '1024px',
  DESKTOP: '2560px',
};

export const SIMPLE_TABLE_WIDGET_COLUMN_SIZE = {
  NUMBER: 120,
}

export const devices = {
  mobileSmall: `(max-width: ${SIZES.MOBILE_SMALL})`,
  mobile: `(max-width: ${SIZES.MOBILE})`,
  tablet: `(max-width: ${SIZES.TABLET})`,
  desktop: `(max-width: ${SIZES.DESKTOP})`,
};

export const MIXPANEL_ATTRIBUTES_KEY = {
  USER_NAME: 'User Name',
  USER_EMAIL: 'User Email',
  TIMEFRAME: 'Timeframe',
  INSTANCE_NAME: 'Instance Name',
  ACCOUNT_NAME: 'Account name',
  SUB_ACCOUNT_NAME: 'Subaccount name',
  CLIENT_NAME: 'Client Name',
  CAMPAIGN_NAME: 'Campaign Name',
  JOB_GROUP_NAME: 'Job Group Name',
  WIDGET_NAME: 'Widget Name',
  FILTERS: 'Filters'
}

export const MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY = {
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'email',
  TIMEFRAME: 'TIMEFRAME',
  INSTANCE_NAME: 'INSTANCE_NAME',
  ACCOUNT_NAME: 'ACCOUNT_NAME',
  SUB_ACCOUNT_NAME: 'SUB_ACCOUNT_NAME',
  CLIENT_NAME: 'CLIENT_NAME',
  CAMPAIGN_NAME: 'CAMPAIGN_NAME',
  JOB_GROUP_NAME: 'JOB_GROUP_NAME',
  WIDGET_NAME: 'WIDGET_NAME',
  FILTERS: 'FILTERS'
}

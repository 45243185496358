import { UNITS_CONFIG } from "../../constants/Units";

export const TIME_DISTRIBUTION_OPTIONS = [
  { targetKey: "combined_view.spend_converted", valueKey: "value", display: "Spend" },
  { targetKey: "combined_view.clicks", valueKey: "value", display: "Clicks" },
  { targetKey: "combined_view.applies", valueKey: "value", display: "Applies" },
  { targetKey: "combined_view.hires", valueKey: "value", display: "Hires" },
];

export const TIME_DISTRIBUTION_CONFIG_HOME = {
  showTitle: true,
  title: "Time distribution on",
  optionSelection: true,
  viewMore: true,
  options: [...TIME_DISTRIBUTION_OPTIONS],
  timeSlotTargetKey: "combined_view.time_split",
  timeSlotValueKey: "value",
};

export const TIME_DISTRIBUTION_CONFIG_VIEW_MORE = {
  showTitle: false,
  title: "Time distribution on",
  optionSelection: false,
  viewMore: false,
  timeSlotTargetKey: "combined_view.time_split",
  timeSlotValueKey: "value",
  metricTargetKey: "combined_view.dimension_1",
  metricValueKey: "value",
  lookerFilterKey: "popover.parameter_1"
};

export const TIME_DISTRIBUTION_METRICS_FILTER_CONFIG = {
  title: "Performance metrics",
  inputType: "radio",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  filtersToUpdate: [
    {
      name: "statsDisplayParameter",
    },
  ],
  defaultFilters: [
    {
      name: "Spend",
    },
  ],
};

export const TIME_DISTRIBUTION_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  dynamicRows: false,
  dynamicColumns: false,
  dataTargetColumn: "combined_view.dimension_1",
  titleKey: "combined_view.dimension_selected",
  currencyTargetKey: "combined_view.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "Time vs Day",
      dataTargetKey: "combined_view.time_split",
      dataValueKey: "value",
    },
    {
      title: "Sunday",
      dataTargetKey: "Sunday|FIELD|6",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Monday",
      dataTargetKey: "Monday|FIELD|0",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Tuesday",
      dataTargetKey: "Tuesday|FIELD|1",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Wednesday",
      dataTargetKey: "Wednesday|FIELD|2",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Thursday",
      dataTargetKey: "Thursday|FIELD|3",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Friday",
      dataTargetKey: "Friday|FIELD|4",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
    {
      title: "Saturday",
      dataTargetKey: "Saturday|FIELD|5",
      dataValueKey: "value",
      applyPrefix: true,
      type: "number"
    },
  ],
  units: [...UNITS_CONFIG],
};

import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const ScoreBannerContainer = styled.div`

`
export const ScoreIndicator = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  color: ${props => props.type === "original" ? "#3A4BC9" : "#373F50"};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.type === "optimised" && props.length === "short" ? "0.55rem" : "0.3rem"};
  white-space: nowrap;

  .Position__right {
    align-self: flex-end;
  }
`
export const Banner = styled.div`
  background: ${props => props.type === "original" ? "#3A4BC9" : "#161E2E"};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.6rem 0.8rem;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.4rem;
  position: relative;

  &::after{
    position: absolute;
    left: ${props => props.position === "right" ? "calc(90% + 4px)" : "calc(50% - 5px)"};
    top: 100%;
    content: '';
    width: 1px;
    height: 1px;
    border: 5px solid #353535;
    border-color: ${props => props.type === "original" ? "#3A4BC9 transparent transparent transparent" : "#161E2E transparent transparent transparent"};
    }

    @media only screen and ${devices.mobile} {
      right: 30%;

      &::after {
        left: 77%;
      }
    }
  

`

import {
  MSASelectionContainer,
} from "./styles";

import FilterSelection from "../../../../components/NavBarFilter/FilterSelection";

const MSASelection = ({
  metadata,
  className,
  currentEntity,
  setCurrentEntity
}) => {
  return (
    <MSASelectionContainer className={className}>
      {
        metadata?.map((item, index) => (
          <FilterSelection
            key={item.title}
            title={item.title}
            count={item.count}
            options={item.options}
            selected={item.selected?.id || ""}
            currentLevel={currentEntity}
            setCurrentLevel={setCurrentEntity}
            expandable={index !== (metadata.length - 1)}
            returnOption={true}
            onSelect={item.onSelect}
          />
        ))
      }
    </MSASelectionContainer>
  );
}

export default MSASelection;

import styled, { css } from "styled-components";
import { devices } from "../../constants/AppConstants";
import { DialogContainer } from "../../components/common/Dialog/styles";
import { ModalContainer } from "../../components/common/Modal/styles";

export const ProgrammaticContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;

  .SearchFilter {
    top: 2.5rem !important;
  }

  .ExtendedFilter {
    top: 2.5rem !important;
  }

  .Multiselect {
    top: 2.5rem !important;
  }

  .open {
    display: initial;
  }

  .close {
    display: none;
  }

  ${(props) =>
    !!props.isSalesDemo &&
    css`
      ${DialogContainer} {
        width: 1276px;
        min-width: fit-content;
      }
    `}
`;
export const ContentContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and ${devices.mobile} {
    padding: 1rem 0;
  }
`
export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;

  @media screen and ${devices.mobile} {
    flex: 1;
    width: 100%;
    padding: 0 1rem 0.5rem 1rem;
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: flex-start;
    border-bottom: 1px solid rgb(203, 213, 224);

    .Datepicker__mobile {
      width: 100%;
    }
  }
`
export const EmptyContainer = styled.div`
  min-height: 65vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";

export const TableContainer = styled.div`
  height: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: scroll;
  
  table,
  .divTable {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
  }

  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    height: 100%;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
`
export const EmptyContainer = styled.div`
  min-height: 65vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 0;
`

export const Thead = styled.thead`
  position: sticky;
  top: -1px;
  z-index: 1;

  .tr, tr {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .th, th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;
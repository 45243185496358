import React, { useState } from "react";
import {
  ContentContainer,
  ContentImage,
  ContentInfoContainer,
  Description,
  LearnMoreButton,
  LearnMoreContainer,
  LikeDislikeContainer,
  RoadmapCardContainer,
  RoadmapCardHeader,
  RoadmapContentText,
  RoadmapDate,
  Status,
  Title,
} from "./styles";
import Icon from "../../common/Icon";
import { getItemFromLocalStorage } from "../../../helpers/commonHelper";
import { EMAIL_LS_KEY, SEEN_ROADMAP_FEATURES_LS_KEY } from "../../../constants/AppConstants";

const RoadmapCard = ({ data , onVoteRoadmapFeature , setRoadmapIframeDetails, setSeenRoadmapFeatures }) => {
  const { title, description, status, id, detailUrls, imageUrls, updatedAt } = data;
  const imageUrl = imageUrls?.[0];

  const email = getItemFromLocalStorage(EMAIL_LS_KEY);
  const likedByAccountIds = data?.likedByAccountIds;
  const dislikedByAccountIds = data?.dislikedByAccountIds;

  const isLiked = likedByAccountIds?.includes(email);
  const isDisliked = dislikedByAccountIds?.includes(email);

  const [localLikedState, setLocalLikedState] = useState(isLiked);
  const [localDislikedState, setLocalDislikedState] = useState(isDisliked);

  const handleVote = async (id, isLike) => {
    try {
      setLocalLikedState(isLike);
      setLocalDislikedState(!isLike);
      await onVoteRoadmapFeature({featureId: id, isLike});
    } catch (error) {
      console.log(`error in ${isLike ? 'like' : 'dislike'}`, error);
    }
  };

  const handleLearnMore = (id, status) => {
    const seenRoadmapFeatures = JSON.parse(getItemFromLocalStorage(SEEN_ROADMAP_FEATURES_LS_KEY)) || [];
    if(!seenRoadmapFeatures.includes(id)){
      const newSeenRoadmapFeatures = [...seenRoadmapFeatures, id];
      setSeenRoadmapFeatures(newSeenRoadmapFeatures);
      localStorage.setItem(SEEN_ROADMAP_FEATURES_LS_KEY, JSON.stringify(newSeenRoadmapFeatures));
    }
    let navigateUrl;
    if(status === "UPCOMING"){
      navigateUrl = detailUrls?.["FigmaProto"];
    }
    if(status === "RELEASED"){
      navigateUrl = detailUrls?.["ReleaseNotes"];
    }
    if(!!navigateUrl){
      setRoadmapIframeDetails({navigateUrl , status});
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  return (
    <RoadmapCardContainer>
      <RoadmapCardHeader>
        <Status status={status}>{status}</Status>
        <LikeDislikeContainer>
          <Icon
            name={localLikedState ? "thumbUp-blue" : "thumbUp"}
            alt="like"
            style={{ cursor: "pointer"}}
            onClick={() => handleVote(id, true)}
            height={20}
            width={20}
          />
          <Icon
            name={localDislikedState ? "thumbDown-blue" : "thumbDown"}
            alt="dislike"
            style={{ cursor: "pointer" }}
            onClick={() => handleVote(id, false)}
            height={20}
            width={20}
          />
        </LikeDislikeContainer>
      </RoadmapCardHeader>
      <RoadmapDate>{formatDate(updatedAt, status)}</RoadmapDate>
      <ContentContainer>
        <ContentInfoContainer>
          <RoadmapContentText>
            <Title>{title}</Title>
            <Description 
            dangerouslySetInnerHTML={{ 
              __html: description 
              }} 
            />
          </RoadmapContentText>
          <LearnMoreContainer onClick={() => handleLearnMore(id, status)}>
            <LearnMoreButton>{status === "UPCOMING" ? "View demo" : "View release notes"}</LearnMoreButton>
            <Icon
              name="arrow-full-right"
              alt="arrow-full-right"
              className="arrow-full-right"
            />
          </LearnMoreContainer>
        </ContentInfoContainer>
        {imageUrl && <ContentImage src={imageUrl} alt="roadmap" />}
      </ContentContainer>
    </RoadmapCardContainer>
  );
};

export default RoadmapCard;

import React from 'react';

import { ReportName, ReportType, TableText } from './styles';

import Badge from './Badge';
import Action from './Action';
import Loader from '../../components/common/Loader';
import Selection from './Selection';

import { getReadableCronDescription } from '../../utils/helpers';
import { getStateFromCronExpression } from '../../components/common/SaveSchedule/ScheduleReportForm/helpers';

export const TITLE_TEXT = "Scheduled Reports"
export const SUBTITLE_TEXT = "This view will be saved with all the filters applied throughout the main dashboard"

export const ALL_PRODUCTS = {
  key: "",
  display: "All"
}

export const getColumns = ({
  hierarchyData,
  currentProduct,
  dashboards,
  handleOptionSelect,
  isDeleting,
  scheduleToDelete,
  products,
  lookerQueryMap
}) => {
  return [
    {
      name: "Status",
      accessorFn: (row) => row["status"],
      cellProcessor: (cell) => <Badge type={cell.getValue()} />
    },
    {
      name: "Report name",
      accessorFn: (row) => ({ name: row["name"], type: row["lookml_dashboard_id"] }),
      cellProcessor: (cell) => <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
        <ReportName>{cell.getValue().name}</ReportName>
        <ReportType>{Object.values(dashboards || {})?.find(item => `${item.id}_report` === cell.getValue().type) ? "Analytics" : "Reports"}</ReportType>
      </div>
    },
    {
      name: "Selection",
      accessorFn: (row) => row,
      cellProcessor: (cell) => {
        return <Selection
          schedule={cell.getValue()}
          hierarchyData={hierarchyData}
          product={currentProduct}
          dashboards={dashboards}
          products={products}
          lookerQueryMap={lookerQueryMap}
        />
      }
    },
    {
      name: "Scheduled",
      accessorFn: (row) => row["crontab"],
      cellProcessor: (cell) => <TableText>{`${getStateFromCronExpression(cell.getValue())?.recurrence.name}. ${getReadableCronDescription(cell.getValue())}`}</TableText>
    },
    {
      name: "Action",
      accessorFn: () => "",
      cellProcessor: (cell) => {
        const row = cell.row.original
        if (isDeleting && scheduleToDelete.id === row.id) return <Loader size="small" height="32" width="32" />
        return <Action onSelect={(opt) => handleOptionSelect(opt, row)} />
      }
    }
  ]
}
import styled, { css } from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  background-color: #E2E8F0;

  a {
    text-decoration: none;
  }
`;

export const Option = styled.div`
  position: relative;
  padding: 1rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #ffffff;
  color: ${(props) =>
    props.active ? "#283593" : props.disabled ? "#252F3F66" : "#252F3F"};
  border-bottom: ${(props) =>
    props.active
      ? "2px solid #283593"
      : props.disabled
      ? "none"
      : "2px solid #FFFFFF"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: #edf2f7;
    border-bottom: ${(props) =>
      props.active ? "2px solid #283593" : "2px solid #EDF2F7"};
  }
  ${(props) =>
    props.tabOptionStyle &&
    typeof props.tabOptionStyle === "function" &&
    css`
      ${props.tabOptionStyle(props)}
    `}
`;

export const Badge = styled.div`
  background-color: #64748b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: 500;
  padding: 0.3rem;
  border-radius: 0.3rem;
`;
export const InfoContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #252f3f;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  position: absolute;
  top: calc(100% + 0.8rem);
  left: 0;
  width: 100%;
  min-width: 10rem;
  padding: 1rem 1.2rem;
  z-index: 3;
  background-color: #ffffff;
`;
export const Arrow = styled.div`
  position: absolute;
  bottom: calc(-1rem + 3px);
  left: 45%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e2e8f0;
`;

import styled from "styled-components";
import { TabContainer } from "../../../components/common/Tab/styles";

export const JobOptimisationContainer = styled.div`

`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #E2E6F1;
`
export const Title = styled.h5`
  font-size: 1.2rem;
  font-weight: 600;
  color: #252F3F;
`
export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`
export const ScoreText = styled.h6`
  color: #373F50;
  font-size: 1rem;
  font-weight: 600;
`
export const ScoreBarContainer = styled.div`
  background: #F1F5F9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  position: relative;
  margin-top: 5rem;

  .JobOptimisation__ScoreBanner {
    position: absolute;
    bottom: 0.4rem;
  }
`
export const Label = styled.span`
  color: #6A7185;
  font-size: 0.9rem;
  font-weight: 500;
  position: absolute;
  bottom: -1.5rem;
  left: ${props => props.type === "low" ? "1%" : "96%"};
`
export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #ffffff;
  margin-top: 2rem;

  ${TabContainer} {
    background-color: #ffffff;
  }

  a {
    text-decoration: none;
  }
`;
export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 1rem 1rem 2rem;
`
export const TabSectionTitle = styled.h6`
  color: #373F50;
  font-weight: 700;
  font-size: 1rem;
`
export const JdSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid #E2E6F1;
  padding-top: 1rem;
`
export const JdScoreSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid #E2E6F1;
  padding-top: 1rem;
`
export const JDContainerOuter = styled.div`
  width: 100%;
  height: ${props => props.expanded ? "100%" : props.type === "opt" ? "19vh" : "15vh"};
  padding: 1rem;
  background: #F9F9FF;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #6A7185;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  @media only screen and (max-width: 960px) {
    width: calc(95vw - 4rem);
  }

  @media only screen and (min-width: 960px) and (max-width: 1080px) {
    width: calc(90vw - 4rem);
  }
  @media only screen and (min-width: 1080px) and (max-width: 1440px) {
    width: calc(90vw - 4rem);
  }
  @media only screen and (min-width: 1440px) {
    width: calc(80vw - 4rem);
  }
`
export const JDContainerInner = styled.div`
  height: ${props => props.expanded ? "100%" : props.type === "opt" ? "16vh" : "10vh"};
  width: 100%;
  overflow: ${props => props.expanded ? "auto" : "hidden"};

  *, *:before, *:after {
      margin: revert;
      padding: revert;
      outline: revert;
  }
`
export const ExpansionText = styled.p`
  color: #5665D1;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
`
export const CopyButton = styled.button`
  cursor: pointer;
  color: #3A4BC9;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.3rem;
  padding: 0.4rem 0.2rem;
  border: 1px solid #3A4BC9;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: 6rem;
`
export const CopyContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

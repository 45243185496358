import React from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Option from "../../../components/common/Sidebar/Section/Option";
import { Box } from "@mui/material";

const SideSMPOption = ({ isExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handleOptionClick = (route) => {
    // Preserve existing query params
    navigate({
      pathname: route,
      search: searchParams.toString(),
    });
  };

  return (
    <Box
      className="sideSmpOptionContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        zIndex: 1000,
        "& p": {
          fontFamily: "San Francisco !important",
          fontWeight: "500 !important",
          lineHeight: "1.42 !important",
          fontSize: "14px !important",
        },
      }}
    >
      <Box
        className="sideSmpOptionContainerInner"
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
      >
        <Option
          icon="storefront-grey"
          hoverIcon="storefront-blue"
          text="Market Insights"
          active={location.pathname.includes(
            "/media-planner/market-mp-insights"
          )}
          isExpanded={isExpanded}
          onClick={() => handleOptionClick("/media-planner/market-mp-insights")}
        />
        <Option
          icon="newsmode-grey"
          hoverIcon="newsmode-blue"
          text="Media Plan"
          active={location.pathname.includes("/media-planner/media-plan")}
          isExpanded={isExpanded}
          onClick={() => handleOptionClick("/media-planner/media-plan")}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          "& img": {
            height: "20px",
            width: "24px",
          },
        }}
      >
        <Option
          icon="ua"
          hoverIcon="programmatic-active"
          text="UA Dashboard"
          isExpanded={isExpanded}
          onClick={() => handleOptionClick("/")}
        />
      </Box>
    </Box>
  );
};

export default SideSMPOption;

import styled from "styled-components";
import { SIZES, devices } from "../../constants/AppConstants";

export const CustomDashboardsContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 3.5rem);
  /* display: flex; */

  @media only screen and (min-width: 1215px) and (max-width: 1536px) {
    padding: 2rem 3rem;
  }
  @media only screen and (min-width: 1536px) {
    padding: 2rem 5rem;
  }

  .embeddedDashboard {
    height: calc(-3.5rem + 100vh);
    width: calc(100% + 7rem);
    margin: 0 1.5rem 0 -3.5rem;
  }

  @media screen and ${devices.tablet} {
    .embeddedDashboard {
      height: calc(-11.5rem + 100vh);
      width: calc(100% + 4rem);
      margin: 0px 0rem 0 0;
    }
  }

  @media only screen and (min-width: ${SIZES.TABLET}) and (max-width: 1215px) {
    .embeddedDashboard {
      width: calc(100% + 2rem);
      height: calc(-11.5rem + 100vh);
      margin: 0 0.75rem 0 -1rem;
    }
  }

  @media only screen and (min-width: 1215px) and (max-width: 1536px) {
    .embeddedDashboard {
      width: calc(100% + 3rem);
      height: calc(-13rem + 100vh);
      margin: 0 0.76rem 0 -1.5rem;
    }
  }
`

import React, { useCallback, useEffect, useState } from 'react';

import { TooltipContainer, TooltipContent, TooltipTarget, Box } from './styles';

const Tooltip = ({
	text, position = 'top', children, ...props
}) => {
	const [isHovering, toggleHovering] = useState(false);

	const showTooltip = useCallback(() => {
		toggleHovering(true);
	}, []);

	const hideTooltip = useCallback(() => {
		toggleHovering(false);
	}, []);

	useEffect(() => {
		if (!text) {
			toggleHovering(false)
		}
	}, [text])

	if (text) {
		return (
			<TooltipContainer {...props}>
				<TooltipTarget
					role="none"
					onMouseEnter={showTooltip}
					onMouseLeave={hideTooltip}
					onClick={hideTooltip}
					onBlur={hideTooltip}
				>
					{children}
				</TooltipTarget>
				{
					isHovering && (
						<TooltipContent className={`content-${position}`}>
							<Box className={`box-${position}`}>{text}</Box>
						</TooltipContent>
					)
				}
			</TooltipContainer>
		);
	}

	return <>{children}</>;
};

export default Tooltip;

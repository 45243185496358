import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { css } from "styled-components";
import { useShallow } from "zustand/react/shallow";

import {
  CareerAndJobSiteContainer,
  ContentContainer,
  EmptyContainer,
  HeaderContainer,
  TabContainer,
} from "./styles";

import Loader from "../../components/common/Loader";
import Tab from "../../components/common/Tab";
import Subheader from "../../components/common/Subheader";
import EmbeddedDashboard from "../../components/EmbeddedDashboard";
import Upgrade from "../Upgrade";

import {
  CAREER_SITE_IDENTIFIERS,
  JOB_SITE_IDENTIFIERS,
  LOOKER_FILTER_KEYS,
  EMAIL_LS_KEY,
  DEFAULT_COUNTRY_MAP_AMAZON,
} from "../../constants/AppConstants";
import Mixpanel from "../../services/MixPanel";

import { CAREER_SITE_ROUTE, JOB_SITE_ROUTE } from "../../constants/AppRoutes";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import {
  getSelectedEntityName,
  searchHierarchyAcrossProducts,
} from "../../utils/searchHelpers";
import { buildPayload, getFiltersForDashboard, isProductLocked } from "./helpers";
import { useFetchCampaigns, useFetchSources } from "../../api/careerSite";
import { getItemFromLocalStorage } from "../../helpers/commonHelper";

export const TAB_OPTIONS = [
  {
    title: "Career site",
    redirect: CAREER_SITE_IDENTIFIERS,
    route: CAREER_SITE_ROUTE,
  },
  {
    title: "Job site",
    redirect: JOB_SITE_IDENTIFIERS,
    route: JOB_SITE_ROUTE,
  },
];

const TAB_CUSTOM_STYLES = (props) => css`
    border-bottom: none;
    background-color: ${props.active ? "#fff" : "#E2E8F0"};
    color: #3d4759;
    width: 132px;
    border-radius: 6px;
    height: 32px;
    &:hover {
      background-color: #eff3f8;
      border-bottom: none;
    }
  `;

const CareerAndJobSite = ({ localWidgetFilters }) => {
  let [searchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();

  const [isDbLoading, toggleDbLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [tabIdentifiers, setTabIdentifiers] = useState(TAB_OPTIONS[0]?.redirect);

  const { hierarchy, products, dashboards, models } = useGlobalDataStore(
    useShallow((state) => ({
      hierarchy: state.hierarchy,
      products: state.products,
      dashboards: state.dashboards,
      models: state.models,
    }))
  );

  const {
    embedUrl,
    globalFilters,
    setEmbedUrl,
    currentProduct,
  } =
    useGlobalFiltersStore(
      useShallow((state) => ({
        embedUrl: state.embedUrl,
        globalFilters: state.globalFilters,
        setEmbedUrl: state.setEmbedUrl,
        currentProduct: state.currentProduct,
      }))
    );

  const { data: { data: campaigns = [] } = [], isLoading: isCampaignsLoading } =
    useFetchCampaigns(
      buildPayload(
        getFiltersForDashboard(localWidgetFilters),
        "career_site_events.utm_campaign",
        models
      )
    );

  const { data: { data: sources = [] } = [], isLoading: isSourcesLoading } =
    useFetchSources(
      buildPayload(
        getFiltersForDashboard(localWidgetFilters),
        "career_site_events.utm_source",
        models
      )
    );

  const combinedFilters = useMemo(() => {
    const email = getItemFromLocalStorage(EMAIL_LS_KEY)
    const defaultCountry = (() => {
      const country = DEFAULT_COUNTRY_MAP_AMAZON.find(item => item.email === email)
      return country?.defaultCountry || ""
    })()
    const combined = {
      ...getFiltersForDashboard(localWidgetFilters),
      ...(!!campaigns?.length && selectedTab === "Career site" && { campaigns: JSON.stringify(campaigns) }),
      ...(!!sources?.length && selectedTab === "Career site" && { sources: JSON.stringify(sources) }),
      ...(selectedTab === "Career site" && { defaultCountry }),
    };
    return combined;
  }, [localWidgetFilters, selectedTab, campaigns, sources]);

  const handleTabChange = (tab) => {
    setEmbedUrl("");
    setSelectedTab(tab?.title)
    setTabIdentifiers(tab?.redirect);
    const params = Object.fromEntries([...searchParams]);
    if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
      delete params[LOOKER_FILTER_KEYS.FILTER_ID]
    }
    const dashboardId = dashboards[tab?.redirect?.dashboardMapIdentifierKey]?.id
    params[LOOKER_FILTER_KEYS.DASHBOARD_ID] = dashboardId
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    navigate({
      pathname: tab?.route,
      search: urlSearchParams,
    });
  }

  useEffect(() => {
    const tab = TAB_OPTIONS.find((item) => item.route === location.pathname);
    const toSet = tab?.title;
    setSelectedTab(toSet);
    setTabIdentifiers(tab?.redirect);
  }, [location.pathname]);

  useEffect(() => {
    Mixpanel.trackEvent("Career and Job Site Analytics Opened", {});
  }, []);

  const doesBothCsJsExist = products?.filter(item => [CAREER_SITE_IDENTIFIERS.productKey, JOB_SITE_IDENTIFIERS.productKey].includes(item.productKey))?.length == 2

  const isLoading = !products?.length || !hierarchy || !embedUrl;

  const isLocked = isProductLocked({ products, hierarchy, globalFilters, currentProduct });

  if (isLocked && !isLoading) {
    const results = searchHierarchyAcrossProducts(hierarchy, globalFilters);
    const entity =
      results?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
      results?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
      "";
    return (
      <Upgrade entity={entity} {...CAREER_SITE_IDENTIFIERS.unlockDetails} />
    );
  }

  return (
    <CareerAndJobSiteContainer>
      <HeaderContainer isTabView={doesBothCsJsExist}>
        {
          doesBothCsJsExist && (
            <TabContainer>
              <Tab
                options={TAB_OPTIONS}
                selected={selectedTab}
                onTabChange={handleTabChange}
                linkKey="route"
                tabOptionStyle={TAB_CUSTOM_STYLES}
              />
            </TabContainer>
          )
        }
        <Subheader
          title={doesBothCsJsExist ? "" : currentProduct?.display}
          entity={
            getSelectedEntityName(hierarchy, globalFilters, tabIdentifiers).name
          }
          entityType={
            getSelectedEntityName(hierarchy, globalFilters, tabIdentifiers).type
          }
          filtersDisabled={isDbLoading}
        />
      </HeaderContainer>
      <ContentContainer>
        {
          (!!embedUrl && !isLoading) && (
            <EmbeddedDashboard
              embedURL={embedUrl}
              lookerFilter={globalFilters}
              localWidgetFilters={combinedFilters}
              onDbLoad={toggleDbLoading}
            />
          )}
        {isLoading && (
          <EmptyContainer>
            <Loader size="medium" />
          </EmptyContainer>
        )}
      </ContentContainer>
    </CareerAndJobSiteContainer>
  );
};

export default CareerAndJobSite;

import React from 'react';

import {
  FilterLabelContainer,
  Text,
} from './styles'

import Tooltip from '../../Tooltip';

const FilterLabel = ({
  label,
  text,
}) => {
  return (
    <FilterLabelContainer>
      <Text bold>{label}</Text>
      <Tooltip text={text}>
        <Text overflow>{text}</Text>
      </Tooltip>
    </FilterLabelContainer>
  );
}

export default FilterLabel;
import React from 'react';

import { DialogContainer, Header, Title, Subtitle } from './styles'

import Icon from '../Icon'

const Dialog = ({
  title,
  subtitle,
  onClose,
  children,
  dialogContainerStyle
}) => {
  return (
    <DialogContainer style={dialogContainerStyle}>
      <Header subtitle={!!subtitle} title={!!title}>
        {
          title && (
            <>
              <Title>{title}</Title>
              <Icon name="close" width="14" height="14" style={{ cursor: 'pointer' }} onClick={onClose} />
            </>
          )
        }
      </Header>
      <Subtitle>{subtitle}</Subtitle>
      {children}
    </DialogContainer>
  );
}

export default Dialog;
import React, { useMemo, useRef, useState } from 'react';

import {
  EmptyContainer,
  Line,
  MultiLevelDropdownContainer,
  Option,
  OptionsContainer,
  Sections,
  SectionsContainer,
  Title,
  TitleContainer
} from './styles';

import EmptyText from '../EmptyText';
import SearchBox from '../SearchBox';

import useOnClickOutside from '../../../utils/useOnClickOutside';

const MultiLevelDropdown = ({
  placeholder,
  emptyPlaceholder,
  sections = [],
  selectedSection,
  selected,
  className,
  onSelect,
  onClose
}) => {
  const [searchText, setSearchText] = useState("")
  const wrapperRef = useRef(null)

  const filteredSections = useMemo(() => {
    if (!searchText) return sections
    const filtered = sections
      ?.filter(section =>
        section?.title?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
        section?.options?.some(option => option?.display?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()))
      )
      ?.map(section => ({ ...section, options: section?.options?.filter(option => option?.display?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) }))
    return filtered
  }, [sections, searchText])


  useOnClickOutside(wrapperRef, () => {
    onClose();
  });

  return (
    <MultiLevelDropdownContainer className={className} ref={wrapperRef}>
      <SearchBox
        placeholder={placeholder}
        clearIcon={false}
        onChange={setSearchText}
      />
      <Sections>
        {
          !!filteredSections?.length && filteredSections.map(section => (
            <SectionsContainer key={section.title}>
              <TitleContainer>
                <Title>{section?.title || ""}</Title>
                <Line />
              </TitleContainer>
              {
                !!section?.options?.length && (
                  <OptionsContainer>
                    {
                      section.options?.map(option => (
                        <Option
                          key={option.id}
                          active={option.id === selected?.id && selectedSection?.name === section?.title}
                          onClick={() => onSelect(option)}
                        >
                          {option.display}
                        </Option>
                      ))
                    }
                  </OptionsContainer>
                )
              }
            </SectionsContainer>
          ))
        }
      </Sections>
      {
        (!filteredSections?.length) && (
          <EmptyContainer>
            <EmptyText text={emptyPlaceholder} />
          </EmptyContainer>
        )
      }
    </MultiLevelDropdownContainer>
  );
}

export default MultiLevelDropdown;

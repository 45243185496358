import React from 'react';

import { PaginationContainer, Text, Button } from './styles';

import Icon from "../Icon"

const Pagination = ({
  currentPage,
  pageCount,
  itemsPerPage = 10,
  onPageChange,
}) => {
  const changeToPage = (page) => {
    if ((currentPage !== 1) || (currentPage !== pageCount)) {
      onPageChange(page);
    }
  }

  return (
    <PaginationContainer>
      <Text>{`Page ${currentPage} of ${pageCount}`}</Text>
      <div>
        <Button
          disabled={currentPage === 1}
          onClick={() => changeToPage(currentPage - 1)}
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          disabled={currentPage === pageCount}
          onClick={() => changeToPage(currentPage + 1)}
        >
          <Icon name="arrow-right" />
        </Button>
      </div>
    </PaginationContainer>
  );
}

export default Pagination;

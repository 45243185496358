import React from 'react';
import {
  FilterSelectionContainer,
  Title,
  OptionsContainer,
  OptionListContainer,
  Option,
  Label,
  EmptyContainer
} from './styles';

import Icon from '../../common/Icon';
import EmptyText from '../../common/EmptyText';

const FilterSelection = ({
  title,
  count,
  options,
  displayKey = "value",
  valueKey = "id",
  currentLevel,
  setCurrentLevel,
  expandable = true,
  selected,
  returnOption = false,
  onSelect
}) => {
  const isCurrentLevel = currentLevel === title

  return (
    <FilterSelectionContainer isCurrentLevel={isCurrentLevel}>
      <Title>{`${title} ${count !== 0 ? `(${count})` : ""}`}</Title>
      {
        !options?.length && (
          <EmptyContainer>
            <EmptyText />
          </EmptyContainer>
        )
      }
      {
        !!options?.length && (
          <OptionsContainer isLast={!expandable}>
            <OptionListContainer>
              {
                options.map((option) => (
                  <Option
                    key={JSON.stringify(option)}
                    onClick={() => onSelect(returnOption ? option : option[valueKey])}
                    isSelected={option[valueKey] === selected}
                    isCurrentLevel={isCurrentLevel}
                    onMouseEnter={() => setCurrentLevel(title)}
                  >
                    <Label
                      isSelected={option[valueKey] === selected}
                      isCurrentLevel={isCurrentLevel}
                    >
                      {option[displayKey]}
                    </Label>
                    {
                      isCurrentLevel && (
                        <Icon
                          name={(option[valueKey] === selected) ? "tick" : expandable ? "arrow-right" : ""}
                          style={{ cursor: "pointer" }}
                          width="14"
                          height="14"
                        />
                      )
                    }
                  </Option>
                ))
              }
            </OptionListContainer>
          </OptionsContainer>
        )
      }
    </FilterSelectionContainer>
  );
}

export default FilterSelection;

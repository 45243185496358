import React from 'react';

import {
  JDScoreTableContainer
} from "./styles"

import ScoreIndicatorBar from "../ScoreIndicatorBar"
import Table from '../../../components/common/Table';

const COLUMNS = [
  <div className="Table__title">Name</div>,
  <div className="Table__title">Score</div>,
  <div className="Table__title">Insights</div>,
]

const getData = (data) => data?.map((item) => ({
  id: JSON.stringify(item),
  elements: [
    {
      id: 1,
      element: <span className="JDScore__table">{item?.name}</span>
    },
    {
      id: 2,
      element: <ScoreIndicatorBar type={item.score} />,
    },
    {
      id: 3,
      element: <div className="JDScore__table">{item?.explanation}</div>,
    },
  ]
})) || []


const JDScoreTable = ({
  insights
}) => {
  return (
    <JDScoreTableContainer>
      <Table
        className="JobRow__table"
        columns={COLUMNS}
        data={getData(insights || [])}
      />
    </JDScoreTableContainer>
  );
}

export default JDScoreTable;

import React, { useRef, useState, useEffect } from 'react';

import {
  ContentWrapper,
  MultiselectContainer,
  MultiselectItem,
  SearchInput,
  Container,
  DropdownOptions,
  Option,
  OptionText,
  EmptySearchResult
} from './styles'

import Icon from '../Icon'
import Loader from '../Loader';

import useOnClickOutside from '../../../utils/useOnClickOutside';
import useDebounce from '../../../utils/useDebounce';

const MultiselectArea = ({
  selections,
  suggestions,
  type = "input",
  suggestionsEnabled,
  isLoading,
  onSelect,
  onRemove,
  onTextChange
}) => {
  const [textInput, setTextInput] = useState("")
  const debouncedInputValue = useDebounce(textInput, 600);
  const [isSuggestionOpen, toggleSuggestion] = useState(false)

  const suggestionsRef = useRef(null);
  const contentRef = useRef(null)

  const handleTextChange = (e) => {
    if (e.keyCode === 13) {
      onSelect(textInput)
      setTextInput("")
      const textarea = contentRef.current;
      textarea.scrollTop = textarea.scrollHeight;
    } else {
      setTextInput(e.target.value)
    }
  }

  const handleOptionSelect = (option) => {
    onSelect(option)
    toggleSuggestion(false)
    setTextInput("")
  }

  useEffect(() => {
    if (type === "autocomplete") {
      onTextChange(debouncedInputValue)
    }
  }, [debouncedInputValue])

  useEffect(() => {
    if ((isLoading || !!suggestions?.length) && suggestionsEnabled && !!debouncedInputValue) {
      toggleSuggestion(true)
    }
  }, [isLoading, suggestions, suggestionsEnabled, debouncedInputValue])

  useOnClickOutside(suggestionsRef, () => {
    toggleSuggestion(false)
  });

  return (
    <Container>
      <MultiselectContainer>
        <ContentWrapper ref={contentRef}>
          {
            !!selections.length && selections.map(selection => (
              <MultiselectItem key={selection}>
                {selection}
                <Icon name="close" width="10" height="10" onClick={() => onRemove(selection)} />
              </MultiselectItem>
            ))
          }
          <SearchInput
            autoFocus
            type="text"
            placeholder={""}
            value={textInput}
            onKeyDown={handleTextChange}
            onChange={handleTextChange}
          />
        </ContentWrapper>
        {
          isSuggestionOpen && (
            <DropdownOptions ref={suggestionsRef}>
              {
                isLoading && (
                  <EmptySearchResult className='Empty'>
                    <Loader size='small' />
                  </EmptySearchResult>
                )
              }
              {
                (suggestions?.length > 0 && !isLoading) && (
                  suggestions.map((option) => (
                    <Option
                      key={option}
                      onClick={() => handleOptionSelect(option)}
                    >
                      <OptionText>{option}</OptionText>
                    </Option>
                  ))
                )
              }
              {
                (!isLoading && !suggestions?.length && debouncedInputValue?.length > 0) && (
                  <EmptySearchResult className='Empty'>No results found</EmptySearchResult>
                )
              }
            </DropdownOptions>
          )
        }
      </MultiselectContainer>
    </Container>
  );
}

export default MultiselectArea;
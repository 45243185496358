import { EXCHANGE_VISIBILITY_VALUES, LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import { DEVICE_TYPES } from "../../constants/ReportsConstants";

const HIERARCHY_FIELDS = [
  "Campaign name",
  "Job group name"
]

export const parseSuggestions = (suggestions, field) => {
  return suggestions?.map(item => item[field])
}

export const getSuggestionsFromHierarchy = (hierarchy, filters, search, type = "campaign") => {
  const results = []
  const {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
  } = filters || {};
  for (const account of hierarchy) {
    if (!!accountId && account.id !== accountId) continue
    for (const subAccount of account.subAccounts) {
      if (!!subAccountId && subAccount.id !== subAccountId) continue
      for (const client of subAccount.clients) {
        if (!!clientId && client.id !== clientId) continue
        for (const campaign of client.campaigns) {
          if (type.startsWith("campaign")) {
            if (campaign.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
              results.push(campaign)
            }
          }
          if (type.startsWith("job")) {
            for (const jobGroup of campaign.jobGroups) {
              if (jobGroup.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                results.push(jobGroup)
              }
            }
          }
        }
      }
    }
  }
  return results
}

export const isJobFilter = (currentFilter) => {
  if (!currentFilter) return false
  if (HIERARCHY_FIELDS.includes(currentFilter.name)) return false
  if (currentFilter.name.toLocaleLowerCase() === "publisher name") return false
  if (currentFilter.name === "Device Type") return false
  return true
}

export async function getSuggestions({
  globalFilters,
  currentFilter,
  search,
  hierarchyData,
  publishers,
  product
}) {
  const hierarchy = hierarchyData[product.productKey]
  const params = globalFilters;
  if (HIERARCHY_FIELDS.includes(currentFilter.name)) {
    const sugs = getSuggestionsFromHierarchy(hierarchy, params, search, currentFilter.name.toLocaleLowerCase())
    return sugs.map(item => item.name)
  } else if (currentFilter.name.toLocaleLowerCase() === "publisher name") {
    return publishers.filter(item => item.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  } else if (currentFilter.name === "Device Type") {
    return DEVICE_TYPES.filter(item => item.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }
}

export const buildParams = (globalFilters) => {
  const params = globalFilters;
  if (!Object.entries(params || {})?.length) return null

  const exchangeVisibility = params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]
  const payload = {
    ...(params[LOOKER_FILTER_KEYS.ACCOUNT_ID] && {
      accountIds: params[LOOKER_FILTER_KEYS.ACCOUNT_ID],
    }),
    ...(params[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] && {
      subAccountIds: params[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
    }),
    ...(params[LOOKER_FILTER_KEYS.CLIENT_ID] && {
      clientIds: params[LOOKER_FILTER_KEYS.CLIENT_ID],
    }),
    exchangeEnabled: !!(exchangeVisibility === EXCHANGE_VISIBILITY_VALUES.OPEN)
  }
  return payload
}

export const buildJobFilterPayload = (globalFilters, currentFilter, search) => {
  if (!search) return null
  const params = globalFilters;
  if (!params[LOOKER_FILTER_KEYS.CLIENT_ID]) return null
  const filterKey = currentFilter?.mojoKey || currentFilter.name
  const payload = {
    clientId: params[LOOKER_FILTER_KEYS.CLIENT_ID],
    query: `${filterKey}:${search}`
  }
  return payload
}
import { useQuery } from "react-query";

import { getLookerData } from "../services/apis";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { LOOKER_ACCESS_TOKEN_LS_KEY } from "../constants/AppConstants";

export const useFetchCategories = (payload, apiEnabled) => {
  const enabled = apiEnabled && !!payload && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY);

  return useQuery(
    ["categories", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled
    },
  );
}

export const useFetchMarketLocations = (payload, apiEnabled) => {
  const enabled = apiEnabled && !!payload && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY);

  return useQuery(
    ["marketLocations", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled
    },
  );
}

export const useFetchCompetitorsLocations = (payload, apiEnabled) => {
  const enabled = apiEnabled && !!payload && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY);

  return useQuery(
    ["competitorsLocations", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled
    },
  );
}

export const useFetchCompetitors = (payload, apiEnabled) => {
  const enabled = apiEnabled && !!payload && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)

  return useQuery(
    ["competitors", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled
    },
  );
}

export const useFetchLastUpdatedAt = (payload) => {
  const enabled = !!payload && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)

  return useQuery(
    ["lastUpdatedAt", { ...payload?.filters }],
    () => (
      getLookerData(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled
    },
  );
}


import React, { useEffect, useRef, useState } from "react";
import { compareAsc } from "date-fns";

import useOnClickOutside from "../../../../utils/useOnClickOutside";
import {
  CheckboxContainer,
  Options,
  EmptySearchResult,
  WeekSelectionContainer,
  Body,
  Footer,
  Submit,
  TextContainer,
  Title,
  Text
} from "./styles";

import Checkbox from '../../Checkbox'
import Tooltip from "../../Tooltip";


const WeekSelection = ({
  options,
  selected,
  idKey = "id",
  displayKey = "name",
  valueKey = "value",
  className = "",
  onSubmit,
  onClose,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const wrapperRef = useRef(null);

  const handleOnChange = (option) => {
    const doesExist = selectedOptions.find(item => item[idKey] === option[idKey])
    if (!!doesExist) {
      setSelectedOptions(so => so.filter(item => item[idKey] !== option[idKey]))
    } else {
      const sorted = [...selectedOptions, option].sort((a, b) => compareAsc(a[idKey], b[idKey]))
      setSelectedOptions(sorted)
    }
  }

  const handleSubmit = () => {
    const all = selectedOptions.find(item => item.name === "All")
    if (!!all) {
      onSubmit(options.filter(item => item.name !== "All"))
    } else {
      onSubmit(selectedOptions);
    }
  }

  const isDisabled = (option) => {
    if (!selectedOptions?.length) return false
    const isSelected = selectedOptions.find(item => item[idKey] === option[idKey])
    if (!!isSelected) return false
    const prev = options.findIndex(item => item[idKey] === (option[idKey] - 1))
    const next = options.findIndex(item => item[idKey] === (option[idKey] + 1))
    const isPreviousSelected = !!selectedOptions.find(item => item[idKey] === options?.[prev]?.[idKey])
    const isNextSelected = !!selectedOptions.find(item => item[idKey] === options?.[next]?.[idKey])
    return !(isPreviousSelected || isNextSelected)
  }

  const isSelectionValid = () => {
    if (!selectedOptions?.length) return false
    const weekNumbers = selectedOptions.map(item => item[idKey])
    const isConsecutive = weekNumbers.every((item, index) => {
      if (index === 0) return true
      return item - weekNumbers[index - 1] === 1
    })

    return isConsecutive
  }

  const disabledText = !selectedOptions?.length ? "Select at least one week" : isSelectionValid() ? "" : "Select adjacent weeks"

  useEffect(() => {
    setSelectedOptions(!!selected?.length ? selected : [])
  }, [selected]);

  useOnClickOutside(wrapperRef, () => {
    onClose()
  });

  return (
    <WeekSelectionContainer className={className ? className : ""} ref={wrapperRef} {...props}>
      <Body>
        <Options>
          {options.length > 0 ? (
            options.map((option, index) => (
              <Tooltip
                key={JSON.stringify(option)}
                text={isDisabled(option) ? "Select adjacent weeks" : ""}
                position={index === 0 ? "bottom" : "top"}
              >
                <CheckboxContainer disabled={isDisabled(option)} onClick={() => {
                  if (!isDisabled(option)) {
                    handleOnChange(option)
                  }
                }}>
                  <Checkbox
                    key={option[idKey]}
                    id={option[idKey]}
                    disabled={isDisabled(option)}
                    checked={!!selectedOptions?.find(s => s[idKey] === option[idKey])}
                    onChange={(e) => {
                      if (!isDisabled(option)) {
                        handleOnChange(option)
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                  <TextContainer>
                    <Title>{`Week ${option?.weekNumber || ""} ${!!option?.current ? " (Current)" : ""}`}</Title>
                    <Text>{option?.display || ""}</Text>
                  </TextContainer>
                </CheckboxContainer>
              </Tooltip>
            ))
          ) : (
            <EmptySearchResult>No results found</EmptySearchResult>
          )}
        </Options>
      </Body>
      <Footer disabled={!isSelectionValid()} onClick={isSelectionValid() ? handleSubmit : null}>
        <Tooltip text={disabledText}>
          <Submit>DONE</Submit>
        </Tooltip>
      </Footer>
    </WeekSelectionContainer>
  );
};

export default WeekSelection;

import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const ErrorContainer = styled.div`
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .page-not-found {
    color: #101828;
    font-size: 2rem;
    font-weight: 600;
    line-height: 4rem;
    margin-top: 0.75rem;
  }

  .error-description {
    color: #475467;
    font-size: 1rem;
    font-weight: 400;
    max-width: 40vw;
    line-height: 1.5rem;
    text-align: center;
  }

  .buttons-container {
    margin-top: 2rem;

    .homepage-button {
      color: #ffffff;
      background-color: #303f9f;
      border: 1px solid #283593;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0.5rem;
      padding: 0.8rem 1.1rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-left: 0.75rem;
      cursor: pointer;
    }
  }

  @media screen and ${devices.mobile} {
    .page-not-found {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-top: 1.2rem;
      margin-bottom: 1rem;
    }

    .error-description {
      margin-top: 0;
      font-size: 1rem;
      max-width: 60vw;
    }

    .buttons-container {
      margin-top: 1rem;
    }

    .error-text {
      margin-top: 1rem;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

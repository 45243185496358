import { useCallback, useState } from "react";

import {
  DropdownMenuItemContainter,
  DropdownMenuItemElem,
  SubmenuItem,
  DropdownSubMenuItemListContainer,
} from "./styles";

import Icon from "../Icon"

const DropdownMenuItem = ({ menuItem, onClick }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsSubMenuOpen(true);
    setIsSubMenuHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsSubMenuOpen(false);
    setIsSubMenuHovered(false);
  }, []);

  return (
    <DropdownMenuItemContainter
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={menuItem.isDisabled ? "disabled" : ""}
    >
      <DropdownMenuItemElem onClick={onClick}>
        {menuItem.prefixIconRequired ? (
          <Icon
            className="dropdown-menu-item-icon"
            name={menuItem.prefixIconSrc}
            alt={menuItem.prefixIconAltText}
          />
        ) : null}
        {menuItem.prefixTextRequired ? (
          <span className="time-zone">{menuItem.prefixText}</span>
        ) : null}
        <span>{menuItem.text}</span>
        {menuItem.submenu && menuItem.submenu.length > 1 ? (
          <Icon
            className="dropdown-menu-item-end-icon"
            name={menuItem.endIconSrc}
            alt={menuItem.prefixIconAltText}
          />
        ) : null}
        <DropdownSubMenuItemListContainer
          open={(isSubMenuOpen || isSubMenuHovered) && menuItem.submenu} // Update open prop
          onMouseEnter={() => setIsSubMenuHovered(true)} // Add onMouseEnter event handler
          onMouseLeave={() => setIsSubMenuHovered(false)} // Add onMouseLeave event handler
        >
          {menuItem.submenu &&
            menuItem.submenu.map((submenuItem, index) => (
              <SubmenuItem
                key={index}
                onClick={submenuItem.onClick}
                className={submenuItem.isSelected ? "selected" : ""}
              >
                <span>{submenuItem.text}</span>
                {submenuItem.isSelected ? (
                  <Icon name="profile-option-selected" />
                ) : null}
              </SubmenuItem>
            ))}
        </DropdownSubMenuItemListContainer>
      </DropdownMenuItemElem>
    </DropdownMenuItemContainter>
  );
};

export default DropdownMenuItem;

import styled from "styled-components"

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  font-family: 'San Francisco';
`
export const ScoreText = styled.h2`
  font-weight: 700;
  font-size: 2rem;
  color: #374151;
`
export const LabelText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  color: #929AAF;
`

import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { DEVICE_SIZES_PX } from "../../../constants/AppConstants";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import ArrowBox from "../ArrowBox";
import Icon from "../Icon";

import {
  PremiumFeatureContainer,
  PremiumFeatureText,
  ArrowBoxTextContainer,
  ArrowBoxTextHeading,
  ArrowBoxText,
  ArrowBoxTextHeadingText,
} from "./styles";

const PremiumFeature = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const wrapperRef = useRef(null);

  const handleToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  useOnClickOutside(wrapperRef, () => {
    if (tooltipVisible) {
      setTooltipVisible(false);
    }
  });

  const getDirection = () => {
    if (window.innerWidth < DEVICE_SIZES_PX.MOBILE) {
      return "top";
    }

    return "left";
  };

  const getArrowBoxText = () => {
    return (
      <ArrowBoxTextContainer>
        <ArrowBoxTextHeading>
          <ArrowBoxTextHeadingText>
            <span className="pro-text">Pro</span>
            <span className="feature-text">feature</span>
          </ArrowBoxTextHeadingText>
          <Icon
            name="close"
            height="10"
            width="10"
            onClick={handleToggle}
            style={{ cursor: "pointer" }}
            alt={"remove"}
          />
        </ArrowBoxTextHeading>
        <ArrowBoxText>
          Market Insights is a Paid Premium Feature which is available for free
          access for a limited time period.
        </ArrowBoxText>
      </ArrowBoxTextContainer>
    );
  };

  return (
    <PremiumFeatureContainer ref={wrapperRef}>
      <PremiumFeatureText onClick={handleToggle}>
        <Icon height="16" width="16" name="premium" alt="premium" />
        <span className="pro-text">Pro</span>
        <span className="feature-text">feature</span>
      </PremiumFeatureText>
      {!!tooltipVisible && (
        <ArrowBox renderBoxText={getArrowBoxText} direction={getDirection()} />
      )}
    </PremiumFeatureContainer>
  );
};

export default PremiumFeature;

import React from "react";

import Logo from "../../components/common/Logo";

import { ErrorContainer, ContentContainer } from "./styles";
import { logoutAndInvalidate } from "../../helpers/authHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../../components/common/Icon";
import EmptyTableSVG from "../../modules/mediaplanner/components/Nodata";

const TITLE = "Something went wrong!";
const CONTENT_TEXT =
  "Unified analytics has encountered an error. Please login again later to resolve this issue. If the problem persists, please contact the system administrator.";

const ErrorPage = () => {
  const [searchParams] = useSearchParams();
  const errorAPP = searchParams.get("errorAPP");
  console.log("errorAPP", errorAPP);
  const navigate = useNavigate();
  const handleMediaPlannerClick = () => {
    navigate("/media-planner");
  };
  return (
    <ErrorContainer>
      <ContentContainer>
        {errorAPP === "SALES_MEDIA_PLANNER" ? (
          <>
            <EmptyTableSVG />
            <h4 className="page-not-found">Error in Media Planner</h4>
            <p className="error-description">
              Media Planner has encountered an error. Please return to Media
              Planner. If the problem persists, please contact the system
              administrator.
            </p>
            <div className="buttons-container">
              <button
                className="homepage-button"
                onClick={handleMediaPlannerClick}
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name="fast-forward-blue"
                  alt="SMp-logo"
                  height="18"
                  width="18"
                />
                Media Planner
              </button>
            </div>
          </>
        ) : (
          <>
            <Logo className="ua-logo" name="uaLogo" alt="ua-logo" />
            <h4 className="page-not-found">{TITLE}</h4>
            <p className="error-description">{CONTENT_TEXT}</p>
            <div className="buttons-container">
              <button className="homepage-button" onClick={logoutAndInvalidate}>
                Login again
              </button>
            </div>
          </>
        )}
      </ContentContainer>
    </ErrorContainer>
  );
};

export default ErrorPage;

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { LabelText, ScoreText, TextContainer } from "./styles";

export const constructChart = (value, width, height) => {
  const tickColor = (() => {
    if (value <= 60) return "#D76A66"
    if (value <= 80) return "#E2A54D"
    return "#6CC986"
  })()

  return {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 180,
      ...(!!width && {
        height: 130,
        spacingBottom: 0,
        spacingTop: 0
      }),
      ...(!!height && {
        height,
      })
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [{
        backgroundColor: '#fff',
        borderWidth: 0
      }],
      center: ['50%', '95%'],
      size: '150%'
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 100,
      lineColor: 'transparent',
      tickPositions: [0, 60, 80],
      tickWidth: 8,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: 'white',
      labels: {
        enabled: false,
        step: 0,
        distance: 12,
        y: 16
      },
      minorTickInterval: null,
      title: {
        enabled: false,
        text: '0',
        y: -100
      },
      plotBands: [
        {
          from: 0,
          to: 60,
          color: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#D76A66'],
              [1, 'rgba(255, 93, 1, 0.8)'],
            ]
          },
        },
        {
          from: 60,
          to: 80,
          color: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
            stops: [
              [0, 'rgba(255, 198, 117, 0.9)'],
              [1, '#E2A54D'],
            ]
          },
        },
        {
          from: 80,
          to: 100,
          color: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
            stops: [
              [0, 'rgba(0, 255, 71, 0.8)'],
              [1, '#6CC986'],
            ]
          },
        }
      ]
    },

    series: [{
      name: 'Series',
      data: [value]
    }],
    plotOptions: {
      gauge: {
        dataLabels: {
          y: 0,
          borderWidth: 0,
          useHTML: true,
          formatter: function () {
            return renderToStaticMarkup(
              <TextContainer>
                <ScoreText>{`${value}%`}</ScoreText>
                <LabelText>Your overall score</LabelText>
              </TextContainer>
            );
          },
        },
        dial: {
          isCirclePoint: true,
          circleRadius: 8,
          backgroundColor: 'white',
          style: `stroke: ${tickColor}; stroke-width: 6;`,
          rearLength: '-120%'
        },
        pivot: {
          radius: 0
        }
      }
    }
  }
}

export const tickPrototype = (Highcharts) => {
  Highcharts.seriesTypes.gauge.prototype.translate = function () {
    var series = this,
      yAxis = series.yAxis,
      options = series.options,
      center = yAxis.center,
      merge = Highcharts.merge,
      pInt = Highcharts.pInt,
      pick = Highcharts.pick,
      isNumber = Highcharts.isNumber;

    series.generatePoints();

    for (var i = 0; i < series.points.length; i++) {
      var point = series.points[i];
      window.ppp = point;
      var dialOptions = merge(options.dial, point.dial),
        radius = (pInt(pick(dialOptions.radius, 80)) * center[2]) / 200,
        baseLength = (pInt(pick(dialOptions.baseLength, 70)) * radius) / 100,
        rearLength = (pInt(pick(dialOptions.rearLength, 10)) * radius) / 100,
        baseWidth = dialOptions.baseWidth || 3,
        topWidth = dialOptions.topWidth || 1,
        overshoot = options.overshoot,
        dialStyle = dialOptions.style,
        isCirclePoint = dialOptions.isCirclePoint,
        circleRadius = dialOptions.circleRadius || 6,
        rotation = yAxis.startAngleRad + yAxis.translate(point.y, null, null, null, true);

      // Handle the wrap and overshoot options
      if (isNumber(overshoot)) {
        overshoot = overshoot / 180 * Math.PI;
        rotation = Math.max(yAxis.startAngleRad - overshoot, Math.min(yAxis.endAngleRad + overshoot, rotation));

      } else if (options.wrap === false) {
        rotation = Math.max(yAxis.startAngleRad, Math.min(yAxis.endAngleRad, rotation));
      }


      rotation = rotation * 180 / Math.PI;

      // Checking series to draw dots
      if (isCirclePoint) {  //draw new dial
        point.shapeType = 'circle';
        point.shapeArgs = {
          cx: -rearLength,
          cy: -baseWidth / 2,
          r: circleRadius,
          translateX: center[0],
          translateY: center[1],
          rotation: rotation,
          style: dialStyle
        };
        point.backgroundColor = 'white';

      } else {  //draw standard dial
        point.shapeType = 'path';
        point.shapeArgs = {
          d: dialOptions.path || [
            'M', -rearLength, -baseWidth / 2,
            'L',
            baseLength, -baseWidth / 2,
            radius, -topWidth / 2,
            radius, topWidth / 2,
            baseLength, baseWidth / 2, -rearLength, baseWidth / 2,
            'z'
          ],
          translateX: center[0],
          translateY: center[1],
          rotation: rotation
        };

      }

      // Positions for data label
      point.plotX = center[0];
      point.plotY = center[1];
    }
  }
}
import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const Box = styled.div`
  width: 17rem;
  border-radius: 4px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1),
    0px 10px 10px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  padding: 8px 16px;

  &.arrow-left {
    top: -4px;
    left: calc(100% + 10px);
    z-index: 2;
  }

  &.arrow-top {
    top: calc(100% + 10px);
    left: 0%;
    z-index: 2;
  }

  &.arrow-top:after {
    content: " ";
    position: absolute;
    top: -10px;
    left: 20px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  &.arrow-right:after {
    content: " ";
    position: absolute;
    right: -10px;
    top: 10px;
    border-top: 10px solid transparent;
    border-right: none;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
  }

  &.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 230px;
    bottom: -10px;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }

  &.arrow-left:after {
    content: " ";
    position: absolute;
    left: -10px;
    top: 10px;
    border-top: 10px solid transparent;
    border-right: 10px solid #fff;
    border-left: none;
    border-bottom: 10px solid transparent;
  }

  @media screen and ${devices.mobile} {
    &.arrow-top {
      top: calc(100% + 10px);
      right: 0%;
      z-index: 2;
      left: auto;
    }

    &.arrow-top:after {
      content: " ";
      position: absolute;
      top: -10px;
      left: auto;
      right: 20px;
      border-top: none;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }
`;

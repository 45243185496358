import React from "react";

import { DetailedInsightsContainer } from "./styles";

import Icon from "../Icon"

const DetailedInsights = ({ disabled = false }) => {
  return (
    <DetailedInsightsContainer disabled={disabled}>
      Detailed insights <Icon name="blueLeft" />
    </DetailedInsightsContainer>
  );
};

export default DetailedInsights;

import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const ReportsLoaderContainer = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  padding: 0 35%;

  .ReportsLoaderImage {
    height: 10rem;
    width: 10rem;
  }

  @media screen and ${devices.mobile} {
    padding: 0 25%;
  }
`
export const ReportsImage = styled.img`
  height: 10rem;
  width: 10rem;
`
export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: #252F3F;
`
export const Text = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: #6B7280;
  text-align: center;
`
export const DownloadReport = styled.button`
  background-color: #283593;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.6rem 1.25rem;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  transition: all 0.3s ease;

  .buttonTextContainer {
    display: flex;
    align-items: center;
  }

  .buttonText {
    color: #ffffff;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading-svg {
    animation: rotate 3s linear infinite;
  }
`;
import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const ViewSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  overflow-x: auto;
`
export const Header = styled.div`
  display: flex;
  gap: 1rem;
`
export const ViewsContainer = styled.div`
  max-height: 40vh;
  overflow: auto;
  min-height: ${props => props.containViews ? "5.5rem" : "0"};

  @media screen and ${devices.mobile} {
   max-height: 50vh;
  }
`
export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  flex: 1;
`
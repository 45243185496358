import styled from "styled-components";

export const ScoreIndicatorBarContainer = styled.div`

`
export const BaseProgressBar = styled.div`
  position: relative;
  background: #DFE4FF;
  width: 100%;
  min-width: 10rem;
  height: 3px;
  border-radius: 0.2rem;
`
export const Label = styled.span`
  font-size: 0.7rem;
  font-weight: 600;
  color: #929AAF;
  position: absolute;
  bottom: -1.7rem;
  left: ${props => props.type === "low" ? "0" : "87%"};
  word-break: keep-all;
`
export const IconContainer = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  background: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -0.8rem;
  left: ${props => props.type === "low" ? "0" : props.type === "medium" ? "45%" : "calc(100% - 1.8rem)"};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 40px 0px #CCD3FF3D;
  border: 0.5px solid #EAECFD;
`
export const ProgressBar = styled.div`
  height: 3px;
  border-radius: 0.2rem;
  background: #727ED9;
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.type === "low" ? "0" : props.type === "medium" ? "45%" : "calc(100% - 1.8rem)"};
  transition: width 0.2s linear;
`

import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const DialogContainer = styled.div`
  height: 100vh;
  background: #f5f5f5;
  min-width: 70vw;
  animation: slideInFromRight 0.3s ease-out;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media only screen and (max-width: 960px) {
    min-width: 95vw;
  }

  @media only screen and (min-width: 960px) and (max-width: 1080px) {
    min-width: 90vw;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1440px) {
    min-width: 90vw;
  }
  @media only screen and (min-width: 1440px) {
    min-width: 80vw;
  }

  @media only screen and ${devices.mobile} {
    min-width: 90vw;
    max-width: 90vw;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.title ? "1.5rem" : "0"};
  padding-bottom: ${props => props.subtitle ? "0.5rem" : props.title ? "1rem" : "0"};
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  color: #252F3F;
`;
export const Subtitle = styled.div`
  padding: 0 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #4B5563;
`
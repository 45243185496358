import styled from "styled-components";

export const SearchMSAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  background-color: #FFFFFF;
  padding: 0.2rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #E2E8F0;
  border-radius: 0.3rem;
`;

export const Result = styled.span`
  padding: 0.8rem 0.8rem;
  cursor: pointer;
  font-size: 0.9rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ selected }) => selected ? "#E8EAF6" : "inherit"};
  border-radius: ${({ selected }) => selected ? "0.3rem" : "0"};
  color: ${({ selected }) => selected ? "#303F9F" : "#3D4759"};
  font-weight: ${({ selected }) => selected ? 600 : 500};
  font-size: 0.9rem;

  &:hover {
    background-color: #EDF2F7;
    border-radius: 0.3rem;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 20rem;
  min-width: 15rem;
  flex: 1;
`;

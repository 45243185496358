import { eachWeekOfInterval, endOfWeek, format, getWeek, isFuture, startOfYear } from "date-fns"

export const getWeekOptionsForYear = (weekStartsOn) => {
  const yearStart = startOfYear(new Date())
  const yearEnd = new Date()
  const startOfWeeks = eachWeekOfInterval({
    start: yearStart,
    end: yearEnd
  }, { weekStartsOn })
  const weekRanges = startOfWeeks.reverse().map((week, index) => {
    const weekEnd = endOfWeek(week, { weekStartsOn })
    const end = isFuture(weekEnd) ? new Date() : weekEnd
    return {
      start: week,
      end,
      display: `${format(week, "d MMM yyyy")} - ${format(end, "d MMM yyyy")}`,
      weekNumber: getWeek(week),
      current: index === 0
    }
  })
  return weekRanges
}

import React, { useEffect, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import * as Sentry from "@sentry/react";

import { LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import Mixpanel from "../../services/MixPanel";

const getLookerFilterWithoutDashboardId = (lookerFilter) => {
  const lookerFilterCopy = { ...lookerFilter };
  delete lookerFilterCopy[LOOKER_FILTER_KEYS.DASHBOARD_ID];
  return lookerFilterCopy;
};

const shouldDashboardChange = (current, previous) => {
  return (!!current && !!previous) && current !== previous
}

const EmbeddedDashboard = ({
  id = "looker-dashboard",
  embedURL,
  lookerFilter,
  localWidgetFilters,
  onDbLoad = () => { },
}) => {
  const [dashboard, setDashboard] = useState(null);
  const [inInit, toggleInInit] = useState(false)
  const previousLocalFilters = useRef(null)
  const previousDashboard = useRef(null)
  const initializationTimer = useRef(null);
  const runTimer = useRef(null);
  let initializationDuration = null;
  let runDuration = null;
  

  const setupDashboard = (d) => {
    console.log("dashboard setup done");
    setDashboard(d);
    window.lookerDashboardRef = d;
  };

  useEffect(() => {
    let lookerUrl = window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.LOOKER_URL : "";
    lookerUrl = lookerUrl.startsWith("https") ? lookerUrl : `https://${lookerUrl}`
    if (!!embedURL && !inInit && !dashboard) {
      console.log("Initialising dashboard with embed url", embedURL, inInit, dashboard);
      toggleInInit(true)
      let dbLoadSpan
      
      // Start initialization timer
      initializationTimer.current = performance.now();
      
      LookerEmbedSDK.init(lookerUrl);
      LookerEmbedSDK.createDashboardWithUrl(embedURL)
        .appendTo(document.getElementById(id))
        .withClassName("embeddedDashboard")
        .on("dashboard:loaded", () => {
          // Calculate initialization time
          initializationDuration = performance.now() - initializationTimer.current;
          console.log("Dashboard loaded, initialization time:", initializationDuration);
        })
        .on("dashboard:run:start", () => {
          // Start run timer
          runTimer.current = performance.now();
          console.log("Dashboard run started");
          onDbLoad(true)
          toggleInInit(false)
          dbLoadSpan = Sentry.startInactiveSpan({
            name: "Dashboard query",
            op: "ui.render",
            description: "Dashboard query run"
          });
        })
        .on("dashboard:run:complete", () => {
          // Calculate all timing metrics
          runDuration = performance.now() - runTimer.current;
          const totalTime = initializationDuration + runDuration;

          // Single Mixpanel event with all metrics
          Mixpanel.trackEvent('Dashboard Performance Metrics', {
            dashboardId: lookerFilter?.dashboardId,
            totalDuration: totalTime,
            initializationDuration,
            runDuration,
          });

          console.log("Dashboard Performance Metrics:", {
            dashboardId : lookerFilter?.dashboardId,
            totalTime: `${totalTime.toFixed(2)}ms`,
            initializationTime: `${initializationDuration.toFixed(2)}ms`,
            runTime: `${runDuration.toFixed(2)}ms`
          });
          onDbLoad(false)
          dbLoadSpan?.finish()
        })
        .on("dashboard:edit:start", () => {
          console.log("Dashboard edit started");
        })
        .on("dashboard:edit:cancel", () => {
          console.log("Dashboard edit started");
        })
        .on("dashboard:save:complete", () => {
          console.log("Dashboard save complete");
        })
        .on("dashboard:delete:complete", () => {
          console.log("Dashboard delete complete");
        })
        .build()
        .connect()
        .then((d) => {
          setupDashboard(d)
        })
        .catch((error) => {
          console.error("An unexpected error occurred", error);
        });
    }
  }, [embedURL, inInit, dashboard]);

  useEffect(() => {
    let lookerUrl = window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.LOOKER_URL : "";
    lookerUrl = lookerUrl.startsWith("https") ? lookerUrl : `https://${lookerUrl}`

    if (!previousDashboard.current) {
      previousDashboard.current = lookerFilter?.dashboardId
    }
    if (lookerFilter?.dashboardId && dashboard && shouldDashboardChange(lookerFilter.dashboardId, previousDashboard.current)) {
      console.log("Dashboard update", lookerFilter, previousDashboard.current);
      onDbLoad(true)
      console.log("Updating dashboard", {
        ...lookerFilter,
        pixelConfig: JSON.stringify({
          width: window.innerWidth,
        }),
      });
      previousDashboard.current = lookerFilter.dashboardId
      document.getElementById(id).innerHTML = "";
      let dbUpdateSpan
      LookerEmbedSDK.init(lookerUrl);
      LookerEmbedSDK.createDashboardWithId(lookerFilter.dashboardId)
        .appendTo(document.getElementById(id))
        .withClassName("embeddedDashboard")
        .on("dashboard:loaded", () => {
          console.log("Dashboard loaded")
        })
        .on("dashboard:run:start", () => {
          console.log("Dashboard run started during update")
          onDbLoad(true)
          dbUpdateSpan = Sentry.startInactiveSpan({
            name: "Dashboard query",
            op: "ui.render",
            description: "Dashboard query run"
          });
        })
        .on("dashboard:run:complete", () => {
          console.log("Dashboard loaded during update");
          onDbLoad(false)
          dbUpdateSpan?.finish()
        })
        .on("dashboard:edit:start", () =>
          console.log("Dashboard edit started")
        )
        .on("dashboard:edit:cancel", () =>
          console.log("Dashboard edit cancelled")
        )
        .on("dashboard:save:complete", () =>
          console.log("Dashboard save complete")
        )
        .on("dashboard:delete:complete", () =>
          console.log("Dashboard delete complete")
        )
        .withFilters(
          getLookerFilterWithoutDashboardId({
            ...lookerFilter,
            pixelConfig: JSON.stringify({
              width: window.innerWidth,
            }),
          })
        )
        .build()
        .connect()
        .then(setupDashboard)
        .catch((error) => {
          console.error("An unexpected error occurred", error);
        });
    }
  }, [lookerFilter]);

  useEffect(() => {
    if (!!dashboard && !inInit) {
      let combined = getLookerFilterWithoutDashboardId(localWidgetFilters)
      Object.keys(previousLocalFilters?.current || {})?.forEach(item => {
        if (!combined[item]) {
          combined[item] = ""
        }
      })
      const widgetFilter = (() => {
        if (!!localWidgetFilters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) return "Jobgroups"
        if (!!localWidgetFilters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) return "Campaigns"
        return "Clients"
      })()
      console.log("Updating filters", {
        ...combined,
        pixelConfig: JSON.stringify({
          width: window.innerWidth,
        }),
        widgetFilter
      });
      dashboard.updateFilters({
        ...combined,
        pixelConfig: JSON.stringify({
          width: window.innerWidth,
        }),
        widgetFilter
      });
      dashboard.run();
      previousLocalFilters.current = { ...combined }
    }
  }, [localWidgetFilters, dashboard, inInit]);

  return <div id={id} />;
};

export default EmbeddedDashboard;
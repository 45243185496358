import React, { useState } from "react";
import {
  FeedbackButtonContainer,
  FeedbackCancelButton,
  FeedbackContainer,
  FeedbackHeader,
  FeedbackInput,
  FeedbackSubmitButton,
  InsightContentAnswer,
  InsightContentAnswerContainer,
  InsightContentCardContainer,
  InsightContentQuestion,
  InsightVoteContainer,
} from "./styles";
import Icon from "../../common/Icon";
import { alert } from "../../common/Toast";

const InsightContentCard = ({ content , submitFeedback }) => {
  const { insightName, insightDescription, likedBy, dislikedBy , id } = content;
  const [displayAnswer, setDisplayAnswer] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const email = localStorage.getItem("email");
  const likedByAccountIds = likedBy;
  const dislikedByAccountIds = dislikedBy;

  const isLiked = likedByAccountIds?.includes(email);
  const isDisliked = dislikedByAccountIds?.includes(email);

  const [isFeedbackLiked, setIsFeedbackLiked] = useState(isLiked);
  const [isFeedbackDisliked, setIsFeedbackDisliked] = useState(isDisliked);

  const handleFeedback = async (type) => {
    if (type === "like") {
      const payload = {
        "eventId": id,
        "featureType": "INSIGHTS",
        "userId": email,
        "liked": true,
        "comments": "" 
      }
      try {
        setIsFeedbackLiked(true);
        setIsFeedbackDisliked(false);
        setIsFeedbackOpen(false);
        await submitFeedback(payload);
      } catch (error) {
        console.log(error);
      }
    }
    if (type === "dislike") {
      setIsFeedbackLiked(false);
      setIsFeedbackDisliked(true);
      setIsFeedbackOpen(true);
    }
  };

  const handleFeedbackSubmit = async () => {
    // feedback is only when the insight is disliked
    setIsFeedbackOpen(false);
    const payload = {
      "eventId": id,
      "featureType": "INSIGHTS",
      "userId": email,
      "liked": false,
      "comments": feedback
    }
    try {
      await submitFeedback(payload);
      alert("Feedback submitted successfully");
      setFeedback("");
    } catch (error) {
      console.log(error);
      alert("Error in submitting feedback", "error");
    }
  };

  return (
    <InsightContentCardContainer>
      <InsightContentQuestion onClick={() => setDisplayAnswer(!displayAnswer)}>
        {insightName}
        <Icon
          name="chevron-down"
          className="chevron-down"
          alt="chevron-down"
          style={{
            cursor: "pointer",
            transform: displayAnswer ? "rotate(180deg)" : "rotate(270deg)",
            height: 24,
            width: 24,
          }}
        />
      </InsightContentQuestion>
      {displayAnswer && (
        <InsightContentAnswerContainer>
          <InsightContentAnswer>{insightDescription}</InsightContentAnswer>
          <InsightVoteContainer>
            <Icon
              name= {isFeedbackLiked ? "thumbUp-blue" : "thumbUp"}
              className="thumbUp"
              alt="thumb-up"
              height={16}
              width={16}
              onClick={() => handleFeedback("like")}
              style={{ cursor: "pointer" }}
            />
            <Icon
              name= {isFeedbackDisliked ? "thumbDown-blue" : "thumbDown"}
              className="thumbDown"
              alt="thumb-down"
              height={16}
              width={16}
              onClick={() => handleFeedback("dislike")}
              style={{ cursor: "pointer" }}
            />
          </InsightVoteContainer>
          {isFeedbackOpen && (
            <FeedbackContainer>
              <FeedbackHeader>Additional Feedback :</FeedbackHeader>
              <FeedbackInput value={feedback} onChange={(e) => setFeedback(e.target.value)} />
              <FeedbackButtonContainer>
                <FeedbackCancelButton onClick={() => {
                  setIsFeedbackLiked(isLiked);
                  setIsFeedbackDisliked(isDisliked);
                  setIsFeedbackOpen(false);
                }}>Cancel</FeedbackCancelButton>
                <FeedbackSubmitButton onClick={handleFeedbackSubmit}>Submit</FeedbackSubmitButton>
              </FeedbackButtonContainer>
            </FeedbackContainer>
          )}
        </InsightContentAnswerContainer>
      )}
    </InsightContentCardContainer>
  );
};

export default InsightContentCard;

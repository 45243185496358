export const UNITS_CONFIG = [
  {
    name: "spend",
    type: "currency",
  },
  {
    name: "cta",
    type: "percentage",
  },
  {
    name: "cpc",
    type: "currency",
  },
  {
    name: "cpa",
    type: "currency",
  },
  {
    name: "cph",
    type: "currency",
  },
  {
    name: "ath",
    type: "percentage"
  }
];

import { LOOKER_FILTER_KEYS } from "../../../constants/AppConstants";
import { REPORTS_CONFIG_MAP } from "../../../constants/ReportsConstants";
import { getFiltersFromQueryString } from "../../../utils/helpers";
import { searchHierarchyData } from "../../../utils/searchHelpers";

export const getSelectionText = ({ schedule, query, hierarchyData, product, dashboards, products }) => {
  if (!schedule || !product || !products?.length) return ""
  if (!schedule?.query_id) {
    const filters = getFiltersFromQueryString(schedule?.filters_string)
    let prod = product
    if (!product?.key) {
      const dashboardKey = Object.entries(dashboards || {}).find(([key, value]) => `${value.id}_report` === schedule.lookml_dashboard_id)
      if (!dashboardKey) return ""
      prod = products.find(item => item.dashboardMapIdentifierKey === dashboardKey)
    }
    const selection = searchHierarchyData(
      hierarchyData,
      filters,
      prod
    );
    const selectionText = Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
    return selectionText
  }
  if (!!schedule?.query_id && !!Object.keys(query)?.length) {
    const { filters, view } = query
    const filterMap = Object.values(REPORTS_CONFIG_MAP).find(item => item.VIEW === view)?.GLOBAL_FILTERS
    const filterObj = {
      [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters[filterMap.ACCOUNT_ID],
      [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters[filterMap.SUB_ACCOUNT_ID],
      [LOOKER_FILTER_KEYS.CLIENT_ID]: filters[filterMap.CLIENT_ID],
      [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters[filterMap.CAMPAIGN_ID],
      [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters[filterMap.JOB_GROUP_ID],
    }
    const selection = searchHierarchyData(
      hierarchyData,
      filterObj
    );
    const selectionText = Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
    return selectionText
  }
  return ""
}
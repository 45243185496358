import React from "react";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import {
  NavBarContainer,
  TitleAndFilterContainer,
  TitleContainer,
  Title,
  ProfileContainer,
  LogoContainer,
  HamburgerMenuContainer,
  AppSwitcher,
  NotificationIcon,
  RoadmapLogo,
  InsightIconContainer,
  InsightText,
} from "./styles";

import ProfileLogo from "../ProfileLogo";
import NavBarFilter from "../NavBarFilter";
import Icon from "../common/Icon";
import Logo from "../common/Logo";

import { useDeleteFilter, useEditFilter } from "../../api/filters";
import { filterApiUtil } from "./helpers";
import Mixpanel from "../../services/MixPanel";
import { getScreenName } from "../../utils/helpers";
import { useGlobalDataStore } from "../../stores/global";
import { getAccountAppUrl } from "../../helpers/authHelper";
import {
  MEDIA_PLANNER_ROUTE,
  MEDIA_PLANNER_ROUTE_IDENTIFIER,
  PROGRAMMATIC_ROUTE,
  ROADMAP_ROUTE,
} from "../../constants/AppRoutes";
import RoadmapNavBar from "../Roadmap/RoadmapNavBar";
import Tooltip from "../common/Tooltip";
import CustomerSelect from "../../modules/mediaplanner/components/CustomerSelect";

const NavBar = ({
  saveViewEnabled = true,
  setIsHamburgerMenuOpen,
  isHamburgerMenuOpen,
  isNavbarFilterVisible,
  roadmapIframeDetails,
  setRoadmapIframeDetails,
  isRoadmapFeatureEnabled,
  unseenRoadmapFeaturesCount,
  isInsightsFeatureEnabled,
  openInsightSidebar,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  let location = useLocation();

  const { dashboards } = useGlobalDataStore(
    useShallow((state) => ({
      dashboards: state.dashboards,
    }))
  );

  const { mutateAsync: editFilter, isLoading: isEditLoading } = useEditFilter();
  const { mutateAsync: deleteFilter, isLoading: isDeleteLoading } =
    useDeleteFilter();

  const handleHamburgerClick = (event) => {
    event.preventDefault();
    setIsHamburgerMenuOpen(true);
  };

  const handleHamburgerClose = (event) => {
    event.preventDefault();
    setIsHamburgerMenuOpen(false);
  };

  const handleViewOptionChange = async (opt, payload) => {
    try {
      await filterApiUtil({
        option: opt,
        searchParams,
        setSearchParams,
        payload,
        editFilter,
        deleteFilter,
        dashboards,
        navigate,
      });
      Mixpanel.trackEvent("Saved View Selected", {
        "Screen name": getScreenName(),
      });
    } catch (e) {
      console.log("error in filters", e);
    }
  };

  // useEffect(() => {
  //   initAppSwitcher();
  // }, []);

  // const initAppSwitcher = () => {
  //   console.log("Implementing initAppSwitcher");
  //   window["appSwitcher"] &&
  //     window["appSwitcher"].init(
  //       {
  //         hostUrl: getAccountAppUrl(),
  //         botName: "appSwitch",
  //         widgetId: "app-switch",
  //         widgetContainerName: "appswitch-web-widget-container",
  //       },
  //       "app-switch"
  //     );
  // };

  const isRoadmapRoute = location.pathname === ROADMAP_ROUTE;
  const isMediaPlannerRoute = location.pathname.includes(
    MEDIA_PLANNER_ROUTE_IDENTIFIER
  );
  const isDefaultNavbar = !isRoadmapRoute;
  const isRoadmapIframeNavbar = isRoadmapRoute && !!roadmapIframeDetails;
  const showRoadmapIframe = !!roadmapIframeDetails?.navigateUrl;
  const isInsightsIconVisible = location.pathname === PROGRAMMATIC_ROUTE;
  const handleRoadmapIframeBackClick = () => {
    setRoadmapIframeDetails(null);
  };

  return (
    <NavBarContainer>
      {isDefaultNavbar && (
        <TitleAndFilterContainer>
          <TitleContainer>
            <Logo name="ua" height="22" width="22" />
            <Title>Unified Analytics</Title>
          </TitleContainer>
          <LogoContainer>
            <HamburgerMenuContainer>
              {!isHamburgerMenuOpen && (
                <Icon
                  name="hamburger"
                  alt="hamburger"
                  onClick={handleHamburgerClick}
                />
              )}
              {isHamburgerMenuOpen && (
                <Icon
                  name="hamburger-close"
                  alt="hamburger-close"
                  onClick={handleHamburgerClose}
                />
              )}
            </HamburgerMenuContainer>
            {isMediaPlannerRoute ? (
              <CustomerSelect />
            ) : (
              <NavBarFilter
                loadingStates={{
                  edit: isEditLoading,
                  delete: isDeleteLoading,
                }}
                onFilterChange={handleViewOptionChange}
                isNavbarFilterVisible={isNavbarFilterVisible}
              />
            )}
          </LogoContainer>
        </TitleAndFilterContainer>
      )}

      {isRoadmapRoute && (
        <RoadmapNavBar
          isRoadmapIframeNavbar={isRoadmapIframeNavbar}
          handleRoadmapIframeBackClick={handleRoadmapIframeBackClick}
        />
      )}
      {/* <AppSwitcher id="app-switch"/> */}
      <ProfileContainer>
        {!showRoadmapIframe && (
          <>
            {isInsightsFeatureEnabled && (
              <InsightIconContainer onClick={openInsightSidebar}>
                <Icon
                  name="insightsIcon"
                  className="insights-icon"
                  style={{ height: 20, width: 20, filter: "brightness(2)" }}
                />
                <InsightText>Insights</InsightText>
              </InsightIconContainer>
            )}
            {isRoadmapFeatureEnabled && (
              <Link
                to={`${ROADMAP_ROUTE}?${searchParams.toString()}`}
                state={{ prevLocation: location.pathname }}
              >
                <RoadmapLogo>
                  <Tooltip text="What's new" position="bottom">
                    <Icon
                      name="giftbox"
                      alt="roadmap-logo"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    {isRoadmapFeatureEnabled &&
                      !!unseenRoadmapFeaturesCount &&
                      unseenRoadmapFeaturesCount > 0 && (
                        <NotificationIcon>
                          {unseenRoadmapFeaturesCount}
                        </NotificationIcon>
                      )}
                  </Tooltip>
                </RoadmapLogo>
              </Link>
            )}
            <ProfileLogo />
          </>
        )}
      </ProfileContainer>
    </NavBarContainer>
  );
};

export default NavBar;

import { useQueries, useQuery } from 'react-query';

import {
  getJobNameSuggestions,
  getJobScore,
  getLookerData
} from '../services/apis';
import { withTracing } from '../utils/helpers';
import { ACCESS_TOKEN_LS_KEY, LOOKER_ACCESS_TOKEN_LS_KEY } from '../constants/AppConstants';
import { getItemFromLocalStorage } from '../helpers/commonHelper';

export const useFetchJobsSuggestions = (payload) => {
  return useQuery(
    ["jobsSuggestions", { ...payload }],
    () => getJobNameSuggestions(payload),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!payload && !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    }
  )
}

export const useFetchJobsAggregated = (payload, filters) => {
  return useQuery(
    ['jobsGrouped', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && !!payload && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobs = (payload, filters) => {
  return useQuery(
    ['jobs', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobsOverview = (payload, filters) => {
  return useQuery(
    ['job', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && !!payload && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobInsights = (payload, filters) => {
  return useQuery(
    ['jobInsights', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && !!payload && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobScore = (clientId, jobId) => {
  return useQuery(
    ['jobScore', { clientId, jobId }],
    () => (
      getJobScore(clientId, jobId)
    ),
    {
      staleTime: Infinity,
      retry: 0,
      enabled: (!!clientId && !!jobId) && !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  );
}

import { UNIFIED_ANALYTICS_ORIGIN } from "./AppConstants"
import {
  JOB_PERFORMANCE_REPORT_ROUTE,
  JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER,
  CAMPAIGN_PERFORMANCE_REPORT_ROUTE,
  CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER,
  JOB_SPONSORSHIP_REPORT_ROUTE,
  JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER,
  EXPANSIONS_REPORT_ROUTE_IDENTIFIER,
  EXPANSIONS_REPORT_ROUTE,
  EVENT_REPORT_ROUTE,
  EVENT_REPORT_ROUTE_IDENTIFIER,
  TOTAL_STATS_REPORT_ROUTE_IDENTIFIER,
  TOTAL_STATS_REPORT_ROUTE
} from './AppRoutes';

export const CAMPAIGN_PERFORMANCE_VIEW = "entity_performance"
export const JOB_PERFORMANCE_VIEW = "job_performance"
export const JOB_SPONSORSHIP_VIEW = "job_count_at_entity_level"
export const EXPANSIONS_VIEW = "expansion_report"
export const EVENT_VIEW = "ip_report"
export const TOTAL_STATS_VIEW = "total_stats_report"

export const CAMPAIGN_REPORT_TITLE = 'Campaign Performance'
export const JOB_PERFORMANCE_REPORT_TITLE = 'Job Performance'
export const JOB_SPONSORSHIP_REPORT_TITLE = 'Job Sponsorship'
export const EXPANSIONS_REPORT_TITLE = 'Expansions'
export const EVENT_REPORT_TITLE = 'Event'
export const TOTAL_STATS_REPORT_TITLE = "Non-Joveo Stats"

const JOB_PERFORMANCE_REPORT_DISABLED_TEXT = "Select the instance and client to enable the job performance report"
const EVENT_REPORT_DISABLED_TEXT = "Select the instance and client to enable the event report"
const EXPANSIONS_REPORT_DISABLED_TEXT = "Select the instance and client to enable the expansions report"
const TOTAL_STATS_REPORT_DISABLED_TEXT = "Select the instance and client to enable the non-joveo stats report"

export const DEVICE_TYPES = ["Mobile", "Desktop", "Unknown"]

export const DATE_SORT_TYPES = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month"
}

export const CAMPAIGN_PERFORMANCE_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${CAMPAIGN_PERFORMANCE_VIEW}.agency_id`,
  CLIENT_ID: `${CAMPAIGN_PERFORMANCE_VIEW}.client_id`,
  CAMPAIGN_ID: `${CAMPAIGN_PERFORMANCE_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${CAMPAIGN_PERFORMANCE_VIEW}.job_group_id`,
  CURRENCY_RATE: `${CAMPAIGN_PERFORMANCE_VIEW}.currency_rate`,
  DATE: `${CAMPAIGN_PERFORMANCE_VIEW}.event_published_date`,
  EXCHANGE_VISIBILITY: `${CAMPAIGN_PERFORMANCE_VIEW}.exchange_visibility`
}

export const JOB_PERFORMANCE_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_PERFORMANCE_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_PERFORMANCE_VIEW}.client_id`,
  CAMPAIGN_ID: `${JOB_PERFORMANCE_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${JOB_PERFORMANCE_VIEW}.job_group_id`,
  CURRENCY_RATE: `${JOB_PERFORMANCE_VIEW}.currency_rate`,
  DATE: `${JOB_PERFORMANCE_VIEW}.event_published_date`,
  EXCHANGE_VISIBILITY: `${JOB_PERFORMANCE_VIEW}.exchange_visibility`,

}

export const JOB_SPONSORSHIP_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_SPONSORSHIP_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_SPONSORSHIP_VIEW}.client_id`,
  CAMPAIGN_ID: `${JOB_SPONSORSHIP_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${JOB_SPONSORSHIP_VIEW}.job_group_id`,
  DATE: `${JOB_SPONSORSHIP_VIEW}.date_filter`,
}

export const EVENT_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${EVENT_VIEW}.agency_id`,
  CLIENT_ID: `${EVENT_VIEW}.client_id`,
  CAMPAIGN_ID: `${EVENT_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${EVENT_VIEW}.job_group_id`,
  CURRENCY_RATE: `${EVENT_VIEW}.currency_rate`,
  DATE: `${EVENT_VIEW}.event_publisher_date`,
  EXCHANGE_VISIBILITY: `${EVENT_VIEW}.exchange_visibility`
}

export const EXPANSIONS_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${EXPANSIONS_VIEW}.agency_id`,
  CLIENT_ID: `${EXPANSIONS_VIEW}.client_id`,
  CAMPAIGN_ID: `${EXPANSIONS_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${EXPANSIONS_VIEW}.job_group_id`,
  CURRENCY_RATE: `${EXPANSIONS_VIEW}.currency_rate`,
  DATE: `${EXPANSIONS_VIEW}.event_publisher_date`
}

export const TOTAL_STATS_GLOBAL_FILTERS = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${TOTAL_STATS_VIEW}.agency_id`,
  CLIENT_ID: `${TOTAL_STATS_VIEW}.client_id`,
  CAMPAIGN_ID: `${TOTAL_STATS_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${TOTAL_STATS_VIEW}.job_group_id`,
  DATE: `${TOTAL_STATS_VIEW}.event_published_date`
}

export const REPORTS_TABS = [
  { title: CAMPAIGN_REPORT_TITLE, redirect: CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER, route: CAMPAIGN_PERFORMANCE_REPORT_ROUTE },
  { title: JOB_PERFORMANCE_REPORT_TITLE, redirect: JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER, route: JOB_PERFORMANCE_REPORT_ROUTE, disabledText: JOB_PERFORMANCE_REPORT_DISABLED_TEXT },
  { title: JOB_SPONSORSHIP_REPORT_TITLE, redirect: JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER, route: JOB_SPONSORSHIP_REPORT_ROUTE },
  { title: EXPANSIONS_REPORT_TITLE, redirect: EXPANSIONS_REPORT_ROUTE_IDENTIFIER, route: EXPANSIONS_REPORT_ROUTE, disabledText: EXPANSIONS_REPORT_DISABLED_TEXT },
  { title: EVENT_REPORT_TITLE, redirect: EVENT_REPORT_ROUTE_IDENTIFIER, route: EVENT_REPORT_ROUTE, disabledText: EVENT_REPORT_DISABLED_TEXT },
  { title: TOTAL_STATS_REPORT_TITLE, redirect: TOTAL_STATS_REPORT_ROUTE_IDENTIFIER, route: TOTAL_STATS_REPORT_ROUTE, disabledText: TOTAL_STATS_REPORT_DISABLED_TEXT }
]

export const CAMPAIGN_PERFORMANCE_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" },
  { name: "Publisher name", field: `${CAMPAIGN_PERFORMANCE_VIEW}.publisher_name` },
  { name: "Publisher type", field: `${CAMPAIGN_PERFORMANCE_VIEW}.publisher_bid_type` },
]

export const JOB_PERFORMANCE_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" },
  { name: "Publisher name", field: `${JOB_PERFORMANCE_VIEW}.publisher_name` },
  { name: "Publisher type", field: `${JOB_PERFORMANCE_VIEW}.publisher_bid_type` },
  { name: "Expansion status", field: `${JOB_PERFORMANCE_VIEW}.is_job_expanded` },
]

export const JOB_SPONSORSHIP_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" },
]

export const EXPANSIONS_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" },
  { name: "Publisher name", field: `${EXPANSIONS_VIEW}.publisher_name` },
  { name: "Publisher type", field: `${EXPANSIONS_VIEW}.publisher_bid_type` },
]

export const EVENT_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" },
  { name: "Publisher type", field: `${EVENT_VIEW}.publisher_bid_type` },
];

export const TOTAL_STATS_ADVANCED_FILTERS = [
  { name: "Campaign name", field: "campaign_info.campaign_name" },
  { name: "Job group name", field: "jg_info.job_group_name" }
]

export const CAMPAIGN_PERFORMANCE_DIMENSION_GROUPS = [
  {
    title: "Client",
    options: [
      { name: "Client name", key: "client_info.client_name" },
      { name: "Client ID", key: "entity_performance.client_id" }
    ]
  },
  {
    title: "Campaign",
    options: [
      { name: "Campaign name", key: "campaign_info.campaign_name" },
      { name: "Campaign ID", key: "entity_performance.campaign_id" }
    ]
  },
  {
    title: "Job group",
    options: [
      { name: "Job group name", key: "jg_info.job_group_name" },
      { name: "Job group ID", key: "entity_performance.job_group_id" }
    ]
  },
  {
    title: "Publisher",
    options: [
      { name: "Publisher name", key: "entity_performance.publisher_name" },
    ]
  },
  {
    title: "Date",
    options: [
      { name: "Day wise", key: "entity_performance.formated_date", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.DAY },
      { name: "Week wise", key: "entity_performance.week", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.WEEK },
      { name: "Month wise", key: "entity_performance.month", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.MONTH },
    ]
  },
  {
    title: "Week",
    options: [
      { name: "Week number", key: "entity_performance.week_num" },
    ]
  }
]

export const JOB_PERFORMANCE_DIMENSION_GROUPS = [
  {
    title: "Job",
    options: [
      { name: "Job title", key: "job_performance.job_title", mojoKey: "title" },
      { name: "Req ID", key: "job_performance.job_ref_number", mojoKey: "refNumber" },
      { name: "Job company", key: "job_performance.job_company", mojoKey: "company" },
    ]
  },
  {
    title: "Job location(Client/Joveo)",
    options: [
      { name: "Zip", key: "job_performance.job_zip", mojoKey: "zip" },
      { name: "Job City", key: "job_performance.job_city", mojoKey: "city" },
      { name: "Joveo City", key: "job_performance.joveo_city", mojoKey: "city" },
      { name: "Job State", key: "job_performance.job_state", mojoKey: "state" },
      { name: "Joveo State", key: "job_performance.joveo_state", mojoKey: "state" },
      { name: "Job Country", key: "job_performance.job_country", mojoKey: "country" },
      { name: "Joveo Country", key: "job_performance.joveo_country", mojoKey: "country" },
    ]
  },
  {
    title: "Job category",
    options: [
      { name: "Category", key: "job_performance.job_category", mojoKey: "category" },
    ]
  },
  {
    title: "Client",
    options: [
      { name: "Client name", key: "client_info.client_name" },
      { name: "Client ID", key: "job_performance.client_id" }
    ]
  },
  {
    title: "Campaign",
    options: [
      { name: "Campaign name", key: "campaign_info.campaign_name" },
      { name: "Campaign ID", key: "job_performance.campaign_id" }
    ]
  },
  {
    title: "Job group",
    options: [
      { name: "Job group name", key: "jg_info.job_group_name" },
      { name: "Job group ID", key: "job_performance.job_group_id" }
    ]
  },
  {
    title: "Publisher",
    options: [
      { name: "Publisher name", key: "job_performance.publisher_name" },
    ]
  },
  {
    title: "Date",
    options: [
      { name: "Day wise", key: "job_performance.formated_date", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.DAY },
      { name: "Week wise", key: "job_performance.week", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.WEEK },
      { name: "Month wise", key: "job_performance.month", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.MONTH },
    ]
  },
  {
    title: "Week",
    options: [
      { name: "Week number", key: "job_performance.week_num" },
    ]
  }
]

export const JOB_SPONSORSHIP_DIMENSIONS_GROUPS = [
  {
    title: "Event",
    options: [
      { name: "Event date", key: "job_count_at_entity_level.event_date" }
    ]
  },
]

export const EXPANSIONS_DIMENSION_GROUPS = [
  {
    title: "Job",
    options: [
      { name: "Original Req ID", key: "inbound_jobs.job_ref_number", mojoKey: "refNumber" },
      { name: "Expanded Req ID", key: "expansion_report.job_ref_number" },
      { name: "Original job title", key: "inbound_jobs.job_title", mojoKey: "title" },
      { name: "Expanded job title", key: "expansion_report.job_title" },
      { name: "Job company", key: "expansion_report.job_company", mojoKey: "company" },
    ]
  },
  {
    title: "Job location",
    options: [
      { name: "Original Zip", key: "inbound_jobs.job_zip", mojoKey: "zip" },
      { name: "Expanded Zip", key: "expansion_report.job_zip" },
      { name: "Original City", key: "inbound_jobs.job_city", mojoKey: "city" },
      { name: "Expanded Job City", key: "expansion_report.job_city" },
      { name: "Expanded Joveo City", key: "expansion_report.joveo_city" },
      { name: "Original State", key: "inbound_jobs.job_state", mojoKey: "state" },
      { name: "Expanded Job State", key: "expansion_report.job_state" },
      { name: "Expanded Joveo State", key: "expansion_report.joveo_state" },
      { name: "Original Country", key: "inbound_jobs.job_country", mojoKey: "country" },
      { name: "Expanded Job Country", key: "expansion_report.job_country" },
      { name: "Expanded Joveo Country", key: "expansion_report.joveo_country" },
    ]
  },
  {
    title: "Job category",
    options: [
      { name: "Category", key: "inbound_jobs.job_category", mojoKey: "category" },
    ]
  },
  {
    title: "Date",
    options: [
      { name: "Day wise", key: "expansion_report.formated_date", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.DAY },
      { name: "Week wise", key: "expansion_report.week", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.WEEK },
      { name: "Month wise", key: "expansion_report.month", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.MONTH },
    ]
  },
  {
    title: "Week",
    options: [
      { name: "Week number", key: "expansion_report.week_num" },
    ]
  }
]

export const EVENT_DIMENSION_GROUPS = [
  {
    title: "Job",
    options: [
      {
        name: "Job Req ID",
        key: "ip_report.original_job_ref_number",
        mojoKey: "refNumber",
      },
      {
        name: "Expanded Req ID",
        key: "ip_report.expanded_job_ref_number",
        mojoKey: "refNumber",
      },
      {
        name: "Job Title",
        key: "ip_report.job_title",
        mojoKey: "title",
      },
      {
        name: "Job company",
        key: "ip_report.job_company",
        mojoKey: "company"
      },
      {
        name: "Job City",
        key: "ip_report.job_city",
        mojoKey: "city",
      },
      {
        name: "Joveo City",
        key: "ip_report.joveo_city",
        mojoKey: "city",
      },
      {
        name: "Job Country",
        key: "ip_report.job_country",
        mojoKey: "country",
      },
      {
        name: "Joveo Country",
        key: "ip_report.joveo_country",
        mojoKey: "country",
      },
      {
        name: "Job State",
        key: "ip_report.job_state",
        mojoKey: "state",
      },
      {
        name: "Joveo State",
        key: "ip_report.joveo_state",
        mojoKey: "state",
      },
    ],
  },
  {
    title: "Publisher",
    options: [
      {
        name: "Publisher Name",
        key: "ip_report.publisher_name",
        mojoKey: "title",
      },
    ],
  },
  {
    title: "Click",
    options: [
      {
        name: "Conversion Click ID",
        key: "ip_report.conversion_click_id",
        mojoKey: "refNumber",
      },
    ],
  },
  {
    title: "Event",
    options: [
      {
        name: "Event City",
        key: "ip_report.event_city",
        mojoKey: "city",
      },
      {
        name: "Event Timestamp Time",
        key: "ip_report.event_timestamp_time",
        mojoKey: "title",
      },
      {
        name: "Event IP",
        key: "ip_report.event_ip",
        mojoKey: "title",
      },
      {
        name: "Click ID",
        key: "ip_report.click_id",
        mojoKey: "title",
      },
      {
        name: "Device Type",
        key: "ip_report.device_type",
        mojoKey: "title",
      },
      {
        name: "Event Operating System",
        key: "ip_report.event_operating_system",
        mojoKey: "refNumber",
      },
      {
        name: "Event Type",
        key: "ip_report.event_type",
        mojoKey: "refNumber",
      },
      {
        name: "Event Count",
        key: "ip_report.event_count",
        mojoKey: "title",
      },
    ],
  },
];

export const TOTAL_STATS_DIMENSION_GROUPS = [
  {
    title: "Job",
    options: [
      { name: "Job title", key: "total_stats_report.job_title", mojoKey: "title" },
      { name: "Req ID", key: "total_stats_report.job_ref_number", mojoKey: "refNumber" },
      { name: "Job company", key: "total_stats_report.job_company", mojoKey: "company" },
    ]
  },
  {
    title: "Job location(Client/Joveo)",
    options: [
      { name: "Job Zip", key: "total_stats_report.job_zip", mojoKey: "zip" },
      { name: "Job City", key: "total_stats_report.job_city", mojoKey: "city" },
      { name: "Job State", key: "total_stats_report.job_state", mojoKey: "state" },
      { name: "Job Country", key: "total_stats_report.job_country", mojoKey: "country" },
    ]
  },
  {
    title: "Job category",
    options: [
      { name: "Category", key: "total_stats_report.job_category", mojoKey: "category" },
    ]
  },
  {
    title: "Client",
    options: [
      { name: "Client name", key: "client_info.client_name" },
      { name: "Client ID", key: "total_stats_report.client_id" }
    ]
  },
  {
    title: "Campaign",
    options: [
      { name: "Campaign name", key: "campaign_info.campaign_name" },
      { name: "Campaign ID", key: "total_stats_report.campaign_id" }
    ]
  },
  {
    title: "Job group",
    options: [
      { name: "Job group name", key: "jg_info.job_group_name" },
      { name: "Job group ID", key: "total_stats_report.job_group_id" }
    ]
  },
  {
    title: "Date",
    options: [
      { name: "Day wise", key: "total_stats_report.formated_date", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.DAY },
      { name: "Week wise", key: "total_stats_report.week", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.WEEK },
      { name: "Month wise", key: "total_stats_report.month", sortingFn: "dateSortingFn", sortType: DATE_SORT_TYPES.MONTH },
    ]
  },
  {
    title: "Week",
    options: [
      { name: "Week number", key: "total_stats_report.week_num" },
    ]
  }
]

export const JOB_DIMENSION_OPTIONS_HOME = [
  {
    title: "Job",
    options: [
      { name: "Job title", key: "jobTitle", mojoKey: "title" },
      { name: "Req ID", key: "jobRefNumber", mojoKey: "refNumber" }
    ]
  },
  {
    title: "Job location(Client/Joveo)",
    options: [
      { name: "Zip", key: "jobZip", mojoKey: "zip" },
      { name: "Job City", key: "jobCity", mojoKey: "city" },
      { name: "Joveo City", key: "joveoCity", mojoKey: "city" },
      { name: "Job State", key: "jobState", mojoKey: "state" },
      { name: "Joveo State", key: "joveoState", mojoKey: "state" },
      { name: "Job Country", key: "jobCountry", mojoKey: "country" },
      { name: "Joveo Country", key: "joveoCountry", mojoKey: "country" },
    ]
  },
  {
    title: "Job category",
    options: [
      { name: "Category", key: "jobCategory", mojoKey: "category" },
    ]
  }
]

export const CAMPAIGN_PERFORMANCE_METRIC_GROUPS = [
  {
    title: "Clicks",
    options: [
      { name: "Clicks", key: "entity_performance.clicks" },
      { name: "CPC", key: "entity_performance.CPC" },
      { name: "Latent clicks", key: "entity_performance.latent_clicks" },
      { name: "Foreign clicks", key: "entity_performance.foreign_clicks" },
      { name: "Bot clicks", key: "entity_performance.bot_clicks" },
      { name: "Duplicate clicks", key: "entity_performance.duplicate_clicks" },
    ],
  },
  {
    title: "Applies",
    options: [
      { name: "Applies", key: "entity_performance.applies" },
      { name: "CPA", key: "entity_performance.CPA" },
      { name: "Target CPA", key: "entity_performance.cpa_target" },
      { name: "CPAS", key: "entity_performance.CPAS" },
      { name: "CTA", key: "entity_performance.CTA" },
      { name: "Apply starts", key: "entity_performance.apply_starts" },
      { name: "Average Apply Time (Minute)", key: "entity_performance.avg_apply_duration" },
      { name: "CTAS", key: "entity_performance.CTAS" },
    ],
  },
  {
    title: "Spend",
    options: [
      { name: "Spend", key: "entity_performance.spend_converted" },
      { name: "Media Spend", key: "entity_performance.media_spend" },
    ]
  },
  {
    title: "Hires",
    options: [
      { name: "Hires", key: "entity_performance.hires" },
      { name: "CPH", key: "entity_performance.CPH" },
      { name: "ATH", key: "entity_performance.ATH" },
      { name: "Avg. Time to Hire (Days)", key: "entity_performance.avg_time_to_hire" }
    ]
  },
  {
    title: "Goals",
    options: [
      { name: "Spend goal", key: "entity_performance.budget" },
      { name: "CPA goal", key: "entity_performance.cpa_goal" },
      { name: "COE goal", key: "entity_performance.apply_goal" },
    ]
  },
]

export const JOB_PERFORMANCE_METRIC_GROUPS = [
  {
    title: "Expansions",
    options: [
      { name: "No of expansions", key: "job_performance.no_of_expansions" },
    ]
  },
  {
    title: "Clicks",
    options: [
      { name: "Clicks", key: "job_performance.clicks" },
      { name: "CPC", key: "job_performance.CPC" },
      { name: "Latent clicks", key: "job_performance.latent_clicks" },
      { name: "Foreign clicks", key: "job_performance.foreign_clicks" },
      { name: "Bot clicks", key: "job_performance.bot_clicks" },
      { name: "Duplicate clicks", key: "job_performance.duplicate_clicks" },
    ]
  },
  {
    title: "Applies",
    options: [
      { name: "Applies", key: "job_performance.applies" },
      { name: "CPA", key: "job_performance.CPA" },
      { name: "Target CPA", key: "job_performance.cpa_target" },
      { name: "CPAS", key: "job_performance.CPAS" },
      { name: "CTA", key: "job_performance.CTA" },
      { name: "Apply starts", key: "job_performance.apply_starts" },
      { name: "Average Apply Time (Minute)", key: "job_performance.avg_apply_duration" },
      { name: "CTAS", key: "job_performance.CTAS" },
    ]
  },
  {
    title: "Spend",
    options: [
      { name: "Spend", key: "job_performance.spend_converted" },
      { name: "Media Spend", key: "job_performance.media_spend" },
    ]
  },
  {
    title: "Hires",
    options: [
      { name: "Hires", key: "job_performance.hires" },
      { name: "CPH", key: "job_performance.CPH" },
      { name: "ATH", key: "job_performance.ATH" },
      { name: "Avg. Time to Hire (Days)", key: "job_performance.avg_time_to_hire" }
    ]
  }
]

export const JOB_SPONSORSHIP_METRIC_GROUPS = [
  {
    title: "Jobs",
    options: [
      { name: "Sponsored Jobs Count", key: "job_count_at_entity_level.sponsored_jobs_count" },
      { name: "Total Jobs Count", key: "job_count_at_entity_level.total_jobs_count" },
    ]
  },
]

export const EXPANSIONS_METRIC_GROUPS = [
  {
    title: "Clicks",
    options: [
      { name: "Clicks", key: "expansion_report.clicks" },
      { name: "CPC", key: "expansion_report.CPC" },
      { name: "Latent clicks", key: "expansion_report.latent_clicks" },
      { name: "Foreign clicks", key: "expansion_report.foreign_clicks" },
      { name: "Bot clicks", key: "expansion_report.bot_clicks" },
      { name: "Duplicate clicks", key: "expansion_report.duplicate_clicks" },
    ]
  },
  {
    title: "Applies",
    options: [
      { name: "Applies", key: "expansion_report.applies" },
      { name: "CPA", key: "expansion_report.CPA" },
      { name: "CTA", key: "expansion_report.CTA" },
      { name: "Apply starts", key: "expansion_report.apply_starts" },
      { name: "Average Apply Time (Minute)", key: "expansion_report.avg_apply_duration" },
    ]
  },
  {
    title: "Spend",
    options: [
      { name: "Spend", key: "expansion_report.spend_converted" },
      { name: "Media Spend", key: "expansion_report.media_spend" },
    ]
  },
  {
    title: "Hires",
    options: [
      { name: "Hires", key: "expansion_report.hires" },
      { name: "CPH", key: "expansion_report.CPH" },
      { name: "ATH", key: "expansion_report.ATH" },
      { name: "Avg. Time to Hire (Days)", key: "expansion_report.avg_time_to_hire" }
    ]
  }
]

export const EVENT_METRIC_GROUPS = [
  {
    title: "Spend",
    options: [
      { name: "Spend", key: "ip_report.spend_converted" },
      { name: "Media Spend", key: "ip_report.media_spend" },
    ]
  },
]

export const TOTAL_STATS_METRIC_GROUPS = [
  {
    title: "Clicks",
    options: [
      { name: "Clicks", key: "total_stats_report.clicks" },
      { name: "Bot clicks", key: "total_stats_report.bot_clicks" },
    ]
  },
  {
    title: "Applies",
    options: [
      { name: "Applies", key: "total_stats_report.applies" },
      { name: "CTA", key: "total_stats_report.CTA" },
      { name: "Apply starts", key: "total_stats_report.apply_starts" },
    ]
  },
  {
    title: "Hires",
    options: [
      { name: "Hires", key: "total_stats_report.hires" },
      { name: "ATH", key: "total_stats_report.ATH" },
    ]
  }
]

export const CAMPAIGN_PERFORMANCE_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Client",
      options: [
        { name: "Client name", key: "client_info.client_name" }
      ]
    },
    {
      title: "Campaign",
      options: [
        { name: "Campaign name", key: "campaign_info.campaign_name" }
      ]
    },
    {
      title: "Job group",
      options: [
        { name: "Job group name", key: "jg_info.job_group_name" },
      ]
    },
  ],
  metrics: [
    {
      title: "Clicks",
      options: [
        { name: "Clicks", key: "entity_performance.clicks" },
        { name: "CPC", key: "entity_performance.CPC" }
      ],
    },
    {
      title: "Spend",
      options: [
        { name: "Spend", key: "entity_performance.spend_converted" },
      ]
    },
    {
      title: "Hires",
      options: [
        { name: "Hires", key: "entity_performance.hires" },
        { name: "CPH", key: "entity_performance.CPH" },
      ]
    },
    {
      title: "Applies",
      options: [
        { name: "Applies", key: "entity_performance.applies" },
        { name: "CPA", key: "entity_performance.CPA" },
        { name: "CTA", key: "entity_performance.CTA" },
      ],
    },
  ]
}

export const JOB_PERFORMANCE_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Job",
      options: [
        { name: "Job title", key: "job_performance.job_title", mojoKey: "title" },
        { name: "Req ID", key: "job_performance.job_ref_number", mojoKey: "refNumber" }
      ]
    },
  ],
  metrics: [
    {
      title: "Expansions",
      options: [
        { name: "No of expansions", key: "job_performance.no_of_expansions" },
      ]
    },
    {
      title: "Clicks",
      options: [
        { name: "Clicks", key: "job_performance.clicks" },
        { name: "CPC", key: "job_performance.CPC" },
      ]
    },
    {
      title: "Spend",
      options: [
        { name: "Spend", key: "job_performance.spend_converted" },
      ]
    },
    {
      title: "Hires",
      options: [
        { name: "Hires", key: "job_performance.hires" },
        { name: "CPH", key: "job_performance.CPH" },
      ]
    },
    {
      title: "Applies",
      options: [
        { name: "Applies", key: "job_performance.applies" },
        { name: "CPA", key: "job_performance.CPA" },
        { name: "CTA", key: "job_performance.CTA" },
      ]
    },
  ]
}

export const JOB_SPONSORSHIP_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Event",
      options: [
        { name: "Event date", key: "job_count_at_entity_level.event_date" }
      ]
    },
  ],
  metrics: [
    {
      title: "Jobs",
      options: [
        { name: "Sponsored Jobs Count", key: "job_count_at_entity_level.sponsored_jobs_count" },
        { name: "Total Jobs Count", key: "job_count_at_entity_level.total_jobs_count" },
      ]
    },
  ]
}

export const EXPANSIONS_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Job",
      options: [
        { name: "Original Req ID", key: "inbound_jobs.job_ref_number", mojoKey: "refNumber" },
        { name: "Expanded Req ID", key: "expansion_report.job_ref_number" },
        { name: "Original job title", key: "inbound_jobs.job_title", mojoKey: "title" },
        { name: "Expanded job title", key: "expansion_report.job_title" },
      ]
    },
    {
      title: "Job location",
      options: [
        { name: "Original City", key: "inbound_jobs.job_city", mojoKey: "city" },
        { name: "Expanded Job City", key: "expansion_report.job_city" },
      ]
    }
  ],
  metrics: [
    {
      title: "Clicks",
      options: [
        { name: "Clicks", key: "expansion_report.clicks" },
        { name: "CPC", key: "expansion_report.CPC" },
      ]
    },
    {
      title: "Spend",
      options: [
        { name: "Spend", key: "expansion_report.spend_converted" },
      ]
    },
    {
      title: "Hires",
      options: [
        { name: "Hires", key: "expansion_report.hires" },
        { name: "CPH", key: "expansion_report.CPH" },
      ]
    },
    {
      title: "Applies",
      options: [
        { name: "Applies", key: "expansion_report.applies" },
        { name: "CPA", key: "expansion_report.CPA" },
        { name: "CTA", key: "expansion_report.CTA" },
      ]
    },
  ]
}

export const EVENT_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Job",
      options: [
        {
          name: "Job Req ID",
          key: "ip_report.original_job_ref_number",
          mojoKey: "refNumber",
        },
        {
          name: "Expanded Req ID",
          key: "ip_report.expanded_job_ref_number",
          mojoKey: "refNumber",
        },
        {
          name: "Job Title",
          key: "ip_report.job_title",
          mojoKey: "title",
        },
        {
          name: "Job City",
          key: "ip_report.job_city",
          mojoKey: "city",
        },
      ],
    },
    {
      title: "Publisher",
      options: [
        {
          name: "Publisher Name",
          key: "ip_report.publisher_name",
          mojoKey: "title",
        },
      ],
    },
    {
      title: "Event",
      options: [
        {
          name: "Event Timestamp Time",
          key: "ip_report.event_timestamp_time",
          mojoKey: "title",
        },
        {
          name: "Event IP",
          key: "ip_report.event_ip",
          mojoKey: "title",
        },
        {
          name: "Click ID",
          key: "ip_report.click_id",
          mojoKey: "title",
        },
        {
          name: "Device Type",
          key: "ip_report.device_type",
          mojoKey: "title",
        },
        {
          name: "Event Operating System",
          key: "ip_report.event_operating_system",
          mojoKey: "refNumber",
        },
        {
          name: "Event Type",
          key: "ip_report.event_type",
          mojoKey: "refNumber",
        },
        {
          name: "Event Count",
          key: "ip_report.event_count",
          mojoKey: "title",
        },
      ],
    },
  ],
  metrics: [
    {
      title: "Spend",
      options: [
        { name: "Spend", key: "ip_report.spend_converted" },
      ]
    }
  ],
};

export const TOTAL_STATS_COLUMN_DEFAULTS = {
  dimensions: [
    {
      title: "Job",
      options: [
        { name: "Job title", key: "total_stats_report.job_title", mojoKey: "title" },
        { name: "Req ID", key: "total_stats_report.job_ref_number", mojoKey: "refNumber" }
      ]
    },
  ],
  metrics: [
    {
      title: "Clicks",
      options: [
        { name: "Clicks", key: "total_stats_report.clicks" },
      ]
    },
    {
      title: "Hires",
      options: [
        { name: "Hires", key: "total_stats_report.hires" },
      ]
    },
    {
      title: "Applies",
      options: [
        { name: "Applies", key: "total_stats_report.applies" },
        { name: "CTA", key: "total_stats_report.CTA" },
      ]
    },
  ]
}

export const REPORTS_CONFIG_MAP = {
  [CAMPAIGN_REPORT_TITLE]: {
    VIEW: CAMPAIGN_PERFORMANCE_VIEW,
    GLOBAL_FILTERS: CAMPAIGN_PERFORMANCE_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: CAMPAIGN_PERFORMANCE_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: CAMPAIGN_PERFORMANCE_ADVANCED_FILTERS,
    DIMENSIONS: CAMPAIGN_PERFORMANCE_DIMENSION_GROUPS,
    METRICS: CAMPAIGN_PERFORMANCE_METRIC_GROUPS,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [`${CAMPAIGN_PERFORMANCE_VIEW}.currency`],
    ADDITIONAL_FIELDS_IN_API: [`${CAMPAIGN_PERFORMANCE_VIEW}.currency`],
    ADDITIONAL_ADVANCED_FILTERS: false,
    SORTS: [`${CAMPAIGN_PERFORMANCE_VIEW}.clicks desc`, `${CAMPAIGN_PERFORMANCE_VIEW}.spend_converted desc`],
    CURRENCY_KEY: `${CAMPAIGN_PERFORMANCE_VIEW}.currency`
  },
  [JOB_PERFORMANCE_REPORT_TITLE]: {
    VIEW: JOB_PERFORMANCE_VIEW,
    GLOBAL_FILTERS: JOB_PERFORMANCE_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: JOB_PERFORMANCE_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: JOB_PERFORMANCE_ADVANCED_FILTERS,
    DIMENSIONS: JOB_PERFORMANCE_DIMENSION_GROUPS,
    METRICS: JOB_PERFORMANCE_METRIC_GROUPS,
    PREFIX: `${JOB_PERFORMANCE_VIEW}.cif`,
    JOB_NODES_LOCATION: 3,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [`${JOB_PERFORMANCE_VIEW}.currency`],
    ADDITIONAL_FIELDS_IN_API: [`${JOB_PERFORMANCE_VIEW}.currency`],
    ADDITIONAL_ADVANCED_FILTERS: true,
    SORTS: [`${JOB_PERFORMANCE_VIEW}.clicks desc`, `${JOB_PERFORMANCE_VIEW}.spend_converted desc`],
    JOB_ADDITIONAL_NODE_INDEX: 3,
    CURRENCY_KEY: `${JOB_PERFORMANCE_VIEW}.currency`
  },
  [JOB_SPONSORSHIP_REPORT_TITLE]: {
    VIEW: JOB_SPONSORSHIP_VIEW,
    GLOBAL_FILTERS: JOB_SPONSORSHIP_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: JOB_SPONSORSHIP_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: JOB_SPONSORSHIP_ADVANCED_FILTERS,
    DIMENSIONS: JOB_SPONSORSHIP_DIMENSIONS_GROUPS,
    METRICS: JOB_SPONSORSHIP_METRIC_GROUPS,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [],
    ADDITIONAL_FIELDS_IN_API: [],
    ADDITIONAL_ADVANCED_FILTERS: false,
    SORTS: [`${JOB_SPONSORSHIP_VIEW}.event_date desc`],
  },
  [EXPANSIONS_REPORT_TITLE]: {
    VIEW: EXPANSIONS_VIEW,
    GLOBAL_FILTERS: EXPANSIONS_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: EXPANSIONS_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: EXPANSIONS_ADVANCED_FILTERS,
    DIMENSIONS: EXPANSIONS_DIMENSION_GROUPS,
    METRICS: EXPANSIONS_METRIC_GROUPS,
    PREFIX: `${EXPANSIONS_VIEW}.cif`,
    JOB_NODES_LOCATION: 3,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [`${EXPANSIONS_VIEW}.currency`],
    ADDITIONAL_FIELDS_IN_API: [`${EXPANSIONS_VIEW}.currency`],
    ADDITIONAL_ADVANCED_FILTERS: true,
    SORTS: [`${EXPANSIONS_VIEW}.clicks desc`, `${EXPANSIONS_VIEW}.spend_converted desc`],
    JOB_ADDITIONAL_NODE_INDEX: 3,
    CURRENCY_KEY: `${EXPANSIONS_VIEW}.currency`
  },
  [EVENT_REPORT_TITLE]: {
    VIEW: EVENT_VIEW,
    GLOBAL_FILTERS: EVENT_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: EVENT_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: EVENT_ADVANCED_FILTERS,
    DIMENSIONS: EVENT_DIMENSION_GROUPS,
    METRICS: EVENT_METRIC_GROUPS,
    PREFIX: `${EVENT_VIEW}.cif`,
    JOB_NODES_LOCATION: 1,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [`${EVENT_VIEW}.currency`],
    ADDITIONAL_FIELDS_IN_API: [`${EVENT_VIEW}.currency`],
    ADDITIONAL_ADVANCED_FILTERS: true,
    SORTS: [`${EVENT_VIEW}.event_timestamp_time desc`],
    JOB_ADDITIONAL_NODE_INDEX: 1,
    CURRENCY_KEY: `${EVENT_VIEW}.currency`
  },
  [TOTAL_STATS_REPORT_TITLE]: {
    VIEW: TOTAL_STATS_VIEW,
    GLOBAL_FILTERS: TOTAL_STATS_GLOBAL_FILTERS,
    DEFAULT_COLUMNS: TOTAL_STATS_COLUMN_DEFAULTS,
    BASE_ADVANCED_FILTERS: TOTAL_STATS_ADVANCED_FILTERS,
    DIMENSIONS: TOTAL_STATS_DIMENSION_GROUPS,
    METRICS: TOTAL_STATS_METRIC_GROUPS,
    PREFIX: `${TOTAL_STATS_VIEW}.cif`,
    JOB_NODES_LOCATION: 3,
    ADDITIONAL_FIELDS_IN_DOWNLOAD: [`${TOTAL_STATS_VIEW}.currency`],
    ADDITIONAL_FIELDS_IN_API: [`${TOTAL_STATS_VIEW}.currency`],
    ADDITIONAL_ADVANCED_FILTERS: true,
    SORTS: [`${TOTAL_STATS_VIEW}.clicks desc`, `${TOTAL_STATS_VIEW}.spend_converted desc`],
    JOB_ADDITIONAL_NODE_INDEX: 3,
    UTM_TAGS_LOCATION: 4,
    CURRENCY_KEY: `${TOTAL_STATS_VIEW}.currency`
  },
}

import React, { useState, useEffect } from 'react';
import { useShallow } from "zustand/react/shallow";
import { useSearchParams } from 'react-router-dom';
import {
  endOfMonth,
  endOfWeek,
  parse,
  startOfMonth,
  startOfWeek
} from 'date-fns';

import {
  SubheaderContainer,
  TitleContainer,
  Title,
  Text,
  SaveScheduleContainer,
  WelcomeTextContainer
} from './styles';

import Icon from '../Icon';
import Datepicker from '../Datepicker';
import SaveOrSchedule from '../SaveSchedule';

import { useGlobalDataStore, useGlobalFiltersStore } from "../../../stores/global";
import { DATE_OPTIONS, LOOKER_FILTER_KEYS } from '../../../constants/AppConstants';
import { formatDate } from '../../../utils/dateHelpers';
import { onTrackMixPanelEvent } from '../../../helpers/eventHandler';

const Subheader = ({
  title,
  entity,
  entityType,
  advancedFilters,
  advancedFilterOptions,
  showAdvancedFiltersInForm = false,
  showWelcomeText = false,
  filtersDisabled = false
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const { username, weekStart } = useGlobalDataStore(useShallow((state) => ({
    username: state.username,
    weekStart: state.weekStart
  })))

  const { currentProduct, globalFilters } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
    globalFilters: state.globalFilters,
  })))

  const [selectedDate, setSelectedDate] = useState(null);

  const onDateChange = (date, option) => {
    let toEndDate = date;
    if (option.key === "this month") {
      const start = startOfMonth(new Date());
      const end = endOfMonth(new Date());
      toEndDate = `${formatDate(start, "start")} to ${formatDate(end, "end")}`;
    }
    if (option.key === "week") {
      const THIS_WEEK = `${formatDate(
        startOfWeek(new Date(), { weekStartsOn: weekStart }),
        "start"
      )} to ${formatDate(new Date(), "end")}`

      if (date === THIS_WEEK) {
        toEndDate = `${formatDate(
          startOfWeek(new Date(), { weekStartsOn: weekStart }),
          "start"
        )} to ${formatDate(endOfWeek(new Date(), { weekStartsOn: weekStart }), "end")}`
      }
    }
    if (option.key === "custom") {
      const dateOption = DATE_OPTIONS.find((item) => item.key === "this month");
      if (date === dateOption.getDateFilterString()) {
        // toEndDate = dateOption.getDateFilterStringTillEnd();
      }
    }
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      Date: date,
      dateProjectedSpend: toEndDate,
    });
    onTrackMixPanelEvent(
      "Date filter selected",
      {
        "Screen name": "Programmatic Analytics",
        "Date Selected": date
      }
    );
  };

  useEffect(() => {
    if (!!globalFilters) {
      const { [LOOKER_FILTER_KEYS.DATE]: date } = globalFilters;
      if (date) {
        const [d1, d2] = date.split(" to ");
        const start = parse(d1?.split(" ")[0], "yyyy-MM-dd", new Date());
        const end = parse(d2?.split(" ")[0], "yyyy-MM-dd", new Date());
        setSelectedDate([start, end]);
      } else {
        setSelectedDate(null);
      }
    }
  }, [globalFilters]);

  return (
    <SubheaderContainer>
      <TitleContainer isTitlePresent={!!title || !!showWelcomeText}>
        {!!showWelcomeText && (
          <>
            <WelcomeTextContainer>
              <strong>Welcome,</strong>&nbsp;{username}&nbsp;
              <Icon name="wave" height="24" width="24" />
            </WelcomeTextContainer>
            <Text>{currentProduct?.subtitle || ""}</Text>
          </>
        )}
        {!showWelcomeText && (
          <>
            {!!title && <Title>{title}</Title>}
            {
              !!entity && (
                <Text>
                  Showing analytics for {entityType} <strong>{entity}</strong>
                </Text>
              )
            }
          </>
        )}
      </TitleContainer>
      <SaveScheduleContainer>
        <SaveOrSchedule
          advancedFilters={advancedFilters}
          advancedFilterOptions={advancedFilterOptions}
          showAdvancedFiltersInForm={showAdvancedFiltersInForm}
        />
        <Datepicker
          selected={selectedDate}
          className="Datepicker__mobile"
          onChange={onDateChange}
          disabled={filtersDisabled}
        />
      </SaveScheduleContainer>
    </SubheaderContainer>
  );
}

export default Subheader;

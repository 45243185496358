import styled from "styled-components";

export const InsightContentCardContainer = styled.div`
   display: flex;
   padding: 16px 20px;
   flex-direction: column;
   align-self: stretch;
   border-radius: 8px;
   border: 1px solid #E2E8F0;
   background: #FFF;
`;

export const InsightContentTitle = styled.div`
   display: flex;
   flex: 1;
   gap: 8px;
   justify-content: space-between;
   color: #3D4759;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
`;

export const InsightContentQuestion = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: var(--gray-text-04, #3D4759);
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   cursor: pointer;
   word-break: break-word;
`;

export const InsightContentAnswerContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 12px;
`;

export const InsightContentAnswer = styled.div`
   color: #3D4759;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px; 
   word-break: break-word;
`;

export const InsightVoteContainer = styled.div`
   display: flex;
   gap: 8px;
   justify-content: flex-end;
   align-items: center;
   margin-top: 8px;
`;

export const FeedbackContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

export const FeedbackHeader = styled.div`
   color: #718096;
   font-size: 12px;
   font-weight: 400;
   line-height: 18px;
`;

export const FeedbackInput = styled.input`
   width: 100%;
   padding: 8px;
   border: 1px solid #E2E8F0;
   border-radius: 4px;
   margin-top: 4px;
   color: #3D4759;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
`;

export const FeedbackButtonContainer = styled.div`
   display: flex;
   gap: 12px;
   justify-content: flex-end;
   margin-top: 8px;
`;

export const FeedbackCancelButton = styled.button`
   color: #718096;
   font-size: 12px;
   font-weight: 500;
   line-height: 18px;
   border: none;
   background: none;
   cursor: pointer;
`;

export const FeedbackSubmitButton = styled.button`
   color: #303F9F;
   font-size: 12px;
   font-weight: 500;
   line-height: 18px;
   border: none;
   background: none;
   cursor: pointer;
`;

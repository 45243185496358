import styled from "styled-components";

export const JDScoreTableContainer = styled.div`

  .JobRow__table {
    height: 100%;

    td {
      color: #373F50;
      font-size: 0.9rem;
      font-weight: 500;
      padding: 1rem 1rem 1.5rem 1rem;
    }
  }
`
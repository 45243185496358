import React from 'react';

import {
  LockedContainer,
  TextContainer,
  Title,
  Text,
  ImageContainer
} from './styles';

import { getImageUrl } from '../../../constants/ApiRoutes';

const COMPETITOR_INSIGHTS_BANNER = "Competitor_insights.png"

const Locked = ({
  title,
  texts
}) => {
  return (
    <LockedContainer>
      {
        <TextContainer>
          {
            title && <Title>{title}</Title>
          }
          {
            texts?.map((text) => (
              <Text key={text}>{text}</Text>
            ))
          }
        </TextContainer>
      }
      <ImageContainer>
        <img src={getImageUrl(COMPETITOR_INSIGHTS_BANNER)} alt="competitor insights" />
      </ImageContainer>
    </LockedContainer>
  );
}

export default Locked;

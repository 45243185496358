import styled from "styled-components"

export const RoadmapContainer = styled.div`
   padding: ${({ setPadding }) => setPadding ? "20px 20px 20px 6rem" : "0px"};
   height: 100vh;
   width: 100vw;
`

export const RoadmapHeader = styled.div`
   display: flex;
   flex-direction: column;
   gap: 6px;
   margin-bottom: 32px;
`

export const RoadmapBodyContainer = styled.div`
   display: flex;
   height: 100%;
   width: 100%;
`

export const RoadmapHeaderText = styled.div`
   color: #161E2E;
   font-size: 20px;
   font-weight: 600;
   line-height: 28px;
`

export const RoadmapSubHeaderText = styled.div`
   color: #565D70;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
`

export const ContentContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 24px;
   height: 100%;
   width: 100%;
   overflow-y: auto;
   padding-bottom: 150px;
`

export const EmptyStateContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 100%;
   width: 100%;
   gap: 24px;
`

export const EmptyStateTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 8px;
`

export const EmptyStateHeader = styled.span`
   color: #374151;
   text-align: center;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
`  

export const EmptyStateText = styled.span`
   color: #374151;
   text-align: center;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   width: 318px;
   text-wrap: balance;
`

export const IframeContainer = styled.div`
   width: 100%;
   height: 100%;
   overflow: hidden;
`
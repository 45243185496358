import React from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../components/common/Logo"

import { PageNotFoundContainer, ContentContainer } from "./styles";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoToHomePage = () => {
    navigate("/");
  };

  return (
    <PageNotFoundContainer>
      <ContentContainer>
        <Logo className="ua-logo" name="uaLogo" alt="ua-logo" />
        <p className="error-text">404 error</p>
        <h1 className="page-not-found">Page not found</h1>
        <p className="error-description">
          The page URL you're searching for either doesn't exist, or an error
          has occurred. Please return to the previous page or click the button
          below to navigate to the home page.
        </p>
        <div className="buttons-container">
          <button className="homepage-button" onClick={handleGoToHomePage}>
            Go to homepage
          </button>
        </div>
      </ContentContainer>
    </PageNotFoundContainer>
  );
};

export default PageNotFound;

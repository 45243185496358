import { LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from "../../constants/AppConstants"
import { JOB_PERFORMANCE_DIMENSION_GROUPS, JOB_PERFORMANCE_VIEW, JOB_PERFORMANCE_GLOBAL_FILTERS } from "../../constants/ReportsConstants"
import { getModel } from "../../utils/helpers"

const ADDITIONAL_FIELDS = ["job_performance.currency"]

export const getAdvancedFilterOptions = (showPublisherFilter) => [
  { id: 1, name: "Campaign name", enabled: true, field: "campaign_info.campaign_name" },
  { id: 2, name: "Job group name", enabled: true, field: "jg_info.job_group_name" },
  { id: 3, name: "Publisher name", enabled: showPublisherFilter, field: "view_combined_event.final_publisher_name" },
  { id: 4, name: "Publisher type", enabled: showPublisherFilter, field: "view_combined_event.publisher_bid_type" },
]

export const getJobFields = () => [
  ...JOB_PERFORMANCE_DIMENSION_GROUPS
    .slice(0, 3)
    .flatMap(item => item.options)
    .map(item => ({ id: JSON.stringify(item), name: item.name, enabled: true, field: item.key }))
]

export const withAdvancedFilters = (filters, advancedFilters) => {
  return {
    ...filters,
    ...advancedFilters
  }
}

const parseAdvancedFilters = (filters, advancedFilters) => {
  const payload = {}
  if (!advancedFilters) return payload
  advancedFilters.forEach(item => {
    if (item?.filterKey) {
      if (!!filters?.[item.filterKey]) {
        payload[item.filterKey] = filters[item.filterKey]
      }
    } else {
      if (!!filters?.[item.field]) {
        payload[item.field] = filters[item.field]
      }
    }
  })
  return payload
}

const parseColumnsForFields = (columns) => {
  const dimensions = [...columns.dimensions].flatMap(item => item.options.map(opt => opt.key))
  const metrics = [...columns.metrics].map(item => item.key)
  const combined = [...dimensions, ...metrics, ...ADDITIONAL_FIELDS]
  return combined
}

const parseColumnsForFilters = (columns) => {
  const dimensions = [...columns.dimensions].flatMap(item => item.options.map(opt => ({ key: opt.name, filterKey: opt.filterKey }))).filter(item => !!item.filterKey)
  const metrics = [...columns.metrics].filter(item => !!item.filterKey).reduce((acc, item) => ({ ...acc, [item.filterKey]: item.name }), {})
  const filters = {
    ...metrics,
    ...dimensions.reduce((acc, item) => ({ ...acc, [item.filterKey]: item.key }), {})
  }
  return filters
}

export const parseLookerFilters = (filters, columns, advancedFilters) => {
  if (!Object.keys(filters || {})?.length) return null
  if (Object.keys(filters)[0].startsWith("entity_performance")) return null
  if (!columns?.dimensions?.length || !columns?.metrics?.length) return null
  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
    [LOOKER_FILTER_KEYS.DATE]: filters?.[LOOKER_FILTER_KEYS.DATE] || "this month",
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] || "",
    // advanced filters
    ...parseAdvancedFilters(filters, advancedFilters),
    ...parseColumnsForFields(columns),
    ...parseColumnsForFilters(columns)
  }
  return payload
}

export const buildJobPayload = (filters, columns, advancedFilters, models) => {
  if (!Object.keys(filters || {})?.length) return null
  if (Object.keys(filters)[0].startsWith("entity_performance")) return null
  if (!columns?.dimensions?.length || !columns?.metrics?.length) return null
  const advancedFiltersSelected = parseAdvancedFilters(filters, advancedFilters)
  const fields = parseColumnsForFields(columns)
  const customFilters = parseColumnsForFilters(columns)

  const payload = {
    "model": getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey),
    "view": JOB_PERFORMANCE_VIEW,
    "pivots": null,
    "filters": {
      [JOB_PERFORMANCE_GLOBAL_FILTERS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.CURRENCY_RATE]: filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] || "",
      [JOB_PERFORMANCE_GLOBAL_FILTERS.DATE]: filters?.[LOOKER_FILTER_KEYS.DATE] || "this month",
      ...advancedFiltersSelected,
      ...customFilters,
    },
    "fill_fields": null,
    "limit": "100",
    "sorts": ["job_performance.clicks desc", "job_performance.spend_converted desc"],
    "column_limit": "100",
    "total": false,
    "row_total": null,
    "subtotals": null,
    "fields": fields,
    "cache": true
  }
  return payload
}

export const parseJobLookerFilters = (filters) => {
  if (!Object.keys(filters || {})?.length) return null
  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
    [LOOKER_FILTER_KEYS.DATE]: filters?.[LOOKER_FILTER_KEYS.DATE] || "this month",
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] || "",
  }
  return payload
}

export const buildJobMetricsPayload = (filters, models) => ({
  "model": getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey),
  "view": JOB_PERFORMANCE_VIEW,
  "fields": [
    "job_performance.conversion_type",
    "job_performance.conversion"
  ],
  "pivots": null,
  "fill_fields": null,
  "filters": {
    [JOB_PERFORMANCE_GLOBAL_FILTERS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.DATE]: filters?.[LOOKER_FILTER_KEYS.DATE] || "this month",
    [JOB_PERFORMANCE_GLOBAL_FILTERS.CURRENCY_RATE]: filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] || "",
    "job_performance.conversion_type": "{{ _user_attributes['conversion_type_filter'] }}"
  },
  "sorts": [],
  "limit": "100",
  "column_limit": "100",
  "total": false,
  "row_total": null,
  "subtotals": null,
  "cache": true
})

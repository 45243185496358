import React from 'react';
import { toast, Slide } from "react-toastify"

const OPTIONS = {
  position: "bottom-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  // draggable: true,
  progress: undefined,
  theme: "light",
  transition: Slide,
}

export const alert = (text, type = "success") => {
  switch (type) {
    case "success":
      toast.success(text, OPTIONS);
      break

    case "error":
      toast.error(text, OPTIONS);
      break

    default:
      toast.info(text, OPTIONS);
  }
}

import styled from "styled-components";

export const InsightOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

export const InsightSidebarContainer = styled.div`
  width: 440px;
  height: 100vh;
  background: #eff3f8;
  box-shadow: -3px 4px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 0;
`;

export const InsightSidebarHeader = styled.div`
  height: 64px;
  padding: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cbd5e0;
  justify-content: space-between;
`;

export const InsightSidebarContent = styled.div`
  height: calc(100vh - 64px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

export const InsightSidebarHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #161e2e;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const InsightSidebarContentHeader = styled.span`
  color: #718096;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const TextBold = styled.span`
  color: #3d4759;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const InsightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InsightContentTitle = styled.div`
  display: flex;
  color: #718096;;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  justify-content: space-between;
  padding: 4px;

  .vertical-line {
    flex: 1;
    height: 1px;
    background-color: #E2E8F0;
    stroke-width: 1px;
  }
`;


export const EmptyStateContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 100%;
   width: 100%;
   gap: 24px;
`

export const EmptyStateTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 8px;
`

export const EmptyStateHeader = styled.span`
   color: #374151;
   text-align: center;
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
`  

export const EmptyStateText = styled.span`
   color: #374151;
   text-align: center;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   width: 318px;
   text-wrap: balance;
`

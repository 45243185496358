import { CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, JOB_SITE_IDENTIFIERS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from "../../constants/AppConstants"
import { searchHierarchyAcrossProducts, searchHierarchyData } from "../../utils/searchHelpers"

export const getSelectionTextBreadcrumbWeb = (hierarchyData, filters, product) => {
  const text = {
    selectionText: "",
    selectionBreadcrumb: ""
  }
  if (!product?.productKey || !hierarchyData) return text

  const selection = searchHierarchyData(hierarchyData, filters, product)
  text.selectionText = Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
  text.selectionBreadcrumb = selection?.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "All accounts"
  return text
}

export const getBreadcrumbTextGlobal = ({ hierarchy, filters, product, views }) => {
  const results = searchHierarchyData(hierarchy, filters, product)
  const selection = {
    productFilterTitle: "",
    productFilterSelection: ""
  }
  if (!product?.productKey || !results) return selection
  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey: {
      const resultsToShow = { ...results }
      if (resultsToShow?.name) {
        delete resultsToShow.name[LOOKER_FILTER_KEYS.ACCOUNT_ID]
        delete resultsToShow.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]
      }
      if (!!filters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
        const view = views?.find(item => item.filterId === filters[LOOKER_FILTER_KEYS.FILTER_ID])?.name
        if (!!view) {
          selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
          selection.productFilterSelection = view
        }
      } else if (!!filters?.[LOOKER_FILTER_KEYS.CLIENT_ID]) {
        selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
        selection.productFilterSelection = resultsToShow?.name?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || resultsToShow?.name?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || results?.name?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "All clients"
      } else {
        selection.productFilterTitle = "Clients"
        selection.productFilterSelection = results?.name?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "All clients"
      }
      break
    }
    case CAREER_SITE_IDENTIFIERS.productKey: {
      const resultsToShow = { ...results }
      if (resultsToShow?.name) {
        delete resultsToShow.name[LOOKER_FILTER_KEYS.ACCOUNT_ID]
        delete resultsToShow.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]
      }
      if (!!filters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
        const view = views?.find(item => item.filterId === filters[LOOKER_FILTER_KEYS.FILTER_ID])?.name
        if (!!view) {
          selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
          selection.productFilterSelection = view
        }
      } else if (!!filters?.[LOOKER_FILTER_KEYS.SITE_ID]) {
        selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
        selection.productFilterSelection = resultsToShow?.name?.[LOOKER_FILTER_KEYS.SITE_ID]
      } else {
        selection.productFilterTitle = "Sites"
        selection.productFilterSelection = resultsToShow?.name?.[LOOKER_FILTER_KEYS.SITE_ID] || "All sites"
      }
      break
    }
    case CRM_IDENTIFIERS.productKey: {
      if (!!filters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
        const view = views?.find(item => item.filterId === filters[LOOKER_FILTER_KEYS.FILTER_ID])?.name
        if (!!view) {
          selection.productFilterTitle = Object.values(results?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
          selection.productFilterSelection = view
        }
      } else {
        selection.productFilterTitle = "Saved views"
        selection.productFilterSelection = "No view selected"
      }
      break
    }
    case UNIFIED_VIEW_IDENTIFIERS.productKey: {
      if (!!filters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
        const view = views?.find(item => item.filterId === filters[LOOKER_FILTER_KEYS.FILTER_ID])?.name
        if (!!view) {
          selection.productFilterTitle = Object.values(results?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
          selection.productFilterSelection = view
        }
      } else {
        selection.productFilterTitle = "Saved views"
        selection.productFilterSelection = "No view selected"
      }
      break
    }
    case JOB_SITE_IDENTIFIERS.productKey: {
      const resultsToShow = { ...results }
      if (resultsToShow?.name) {
        delete resultsToShow.name[LOOKER_FILTER_KEYS.ACCOUNT_ID]
        delete resultsToShow.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]
      }
      if (!!filters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
        const view = views?.find(item => item.filterId === filters[LOOKER_FILTER_KEYS.FILTER_ID])?.name
        if (!!view) {
          selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
          selection.productFilterSelection = view
        }
      } else if (!!filters?.[LOOKER_FILTER_KEYS.SITE_ID]) {
        selection.productFilterTitle = Object.values(resultsToShow?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
        selection.productFilterSelection = resultsToShow?.name?.[LOOKER_FILTER_KEYS.SITE_ID]
      } else {
        selection.productFilterTitle = "Sites"
        selection.productFilterSelection = resultsToShow?.name?.[LOOKER_FILTER_KEYS.SITE_ID] || "All sites"
      }
      break
    }
    default: {
      selection.productFilterTitle = "Saved views"
      selection.productFilterSelection = "No view selected"
    }
  }
  return selection
}

const getProgrammaticBreadcrumbText = ({
  selection,
  filters,
  selectedAccountOption,
  currentEntity,
  clientOptions,
  campaignOptions,
  jobGroupOptions,
}) => {
  if (!selection) return ""

  const crumbs = [selectedAccountOption?.value || ""]
  if (selection[LOOKER_FILTER_KEYS.CLIENT_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.CLIENT_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.CLIENT_ID) {
    crumbs.push(clientOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.CLIENT_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.CAMPAIGN_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.CAMPAIGN_ID) {
    crumbs.push(campaignOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.CAMPAIGN_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.JOB_GROUP_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.JOB_GROUP_ID) {
    crumbs.push(jobGroupOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.JOB_GROUP_ID])?.value || "")
  }

  return crumbs.filter(Boolean).join(" / ")
}

const getCareerSiteBreadcrumbText = ({
  selection,
  filters,
  selectedAccountOption,
  currentEntity,
  subAccountOptions,
  siteOptions,
}) => {
  if (!selection) return ""

  const crumbs = [selectedAccountOption?.value || ""]
  if (selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID) {
    crumbs.push(subAccountOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.SITE_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SITE_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SITE_ID) {
    crumbs.push(siteOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SITE_ID])?.value || "")
  }

  return crumbs.filter(Boolean).join(" / ")
}

const getCRMBreadcrumbText = ({
  selection,
  filters,
  selectedAccountOption,
  currentEntity,
  subAccountOptions,
  siteOptions,
}) => {
  if (!selection) return ""

  const crumbs = [selectedAccountOption?.value || ""]
  if (selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID) {
    crumbs.push(subAccountOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.SITE_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SITE_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SITE_ID) {
    crumbs.push(siteOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SITE_ID])?.value || "")
  }

  return crumbs.filter(Boolean).join(" / ")
}

const getUnifiedViewBreadcrumbText = ({
  selection,
  filters,
  selectedAccountOption,
  currentEntity,
  subAccountOptions,
  siteOptions,
}) => {
  if (!selection) return ""

  const crumbs = [selectedAccountOption?.value || ""]
  if (selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID) {
    crumbs.push(subAccountOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.SITE_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SITE_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SITE_ID) {
    crumbs.push(siteOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SITE_ID])?.value || "")
  }

  return crumbs.filter(Boolean).join(" / ")
}

const getJobSiteBreadcrumbText = ({
  selection,
  filters,
  selectedAccountOption,
  currentEntity,
  subAccountOptions,
  siteOptions,
}) => {
  if (!selection) return ""

  const crumbs = [selectedAccountOption?.value || ""]
  if (selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID) {
    crumbs.push(subAccountOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])?.value || "")
  }

  if (selection[LOOKER_FILTER_KEYS.SITE_ID]) {
    crumbs.push(selection[LOOKER_FILTER_KEYS.SITE_ID])
  } else if (currentEntity === LOOKER_FILTER_KEYS.SITE_ID) {
    crumbs.push(siteOptions?.find(item => item.id === filters[LOOKER_FILTER_KEYS.SITE_ID])?.value || "")
  }

  return crumbs.filter(Boolean).join(" / ")
}

const getBreadCrumbText = ({
  product,
  filters,
  selection,
  selectedAccountOption,
  currentEntity,
  clientOptions,
  campaignOptions,
  jobGroupOptions,
  accountOptions,
  subAccountOptions,
  siteOptions
}) => {
  if (!selection) return ""

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return getProgrammaticBreadcrumbText({
        selection,
        filters,
        selectedAccountOption,
        currentEntity,
        clientOptions,
        campaignOptions,
        jobGroupOptions,
      })
    case CAREER_SITE_IDENTIFIERS.productKey:
      return getCareerSiteBreadcrumbText({
        selection,
        filters,
        selectedAccountOption,
        currentEntity,
        accountOptions,
        subAccountOptions,
        siteOptions
      })
    case CRM_IDENTIFIERS.productKey:
      return getCRMBreadcrumbText({
        selection,
        filters,
        selectedAccountOption,
        currentEntity,
        accountOptions,
        subAccountOptions,
        siteOptions
      })
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return getUnifiedViewBreadcrumbText({
        selection,
        filters,
        selectedAccountOption,
        currentEntity,
        accountOptions,
        subAccountOptions,
        siteOptions
      })
    case JOB_SITE_IDENTIFIERS.productKey:
      return getJobSiteBreadcrumbText({
        selection,
        filters,
        selectedAccountOption,
        currentEntity,
        accountOptions,
        subAccountOptions,
        siteOptions
      })
    default:
      return ""
  }
};

export const getSelectionTextBreadcrumbMobile = ({
  hierarchyData,
  filters,
  product,
  selectedAccountOption,
  currentEntity,
  clientOptions,
  campaignOptions,
  jobGroupOptions,
  accountOptions,
  subAccountOptions,
  siteOptions
}) => {
  if (!product?.productKey) return ""

  const selection = searchHierarchyData(hierarchyData, filters, product)
  const text = getBreadCrumbText({
    selection: selection?.name,
    filters,
    product,
    selectedAccountOption,
    currentEntity,
    clientOptions,
    campaignOptions,
    jobGroupOptions,
    accountOptions,
    subAccountOptions,
    siteOptions
  })
  return text
}

export const getSelectionText = (hierarchy, globalFilters, product) => {
  let filterDetails = searchHierarchyData(hierarchy, globalFilters, product)
  if (!filterDetails) {
    filterDetails = searchHierarchyAcrossProducts(hierarchy, globalFilters)
  }
  if (!filterDetails || !product) return ""


  const accountSelection = filterDetails?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || filterDetails?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "All accounts"
  let productSelection

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      productSelection = filterDetails?.name?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] ||
        filterDetails?.name?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] ||
        filterDetails?.name?.[LOOKER_FILTER_KEYS.CLIENT_ID] ||
        "All clients"
      break
    case CAREER_SITE_IDENTIFIERS.productKey:
      productSelection = filterDetails?.name?.[LOOKER_FILTER_KEYS.SITE_ID] ||
        "All sites"
      break
    case CRM_IDENTIFIERS.productKey:
      productSelection = ""
      break
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      productSelection = ""
      break
    case JOB_SITE_IDENTIFIERS.productKey:
      productSelection = filterDetails?.name?.[LOOKER_FILTER_KEYS.SITE_ID] ||
        "All sites"
      break
    default:
      productSelection = ""
  }
  return {
    accountSelection, productSelection
  }
}

import React, { useEffect, useMemo, useState, useRef } from "react";
import { useShallow } from "zustand/react/shallow";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import {
  SidebarContainer,
  Title,
  Header,
  ContentContainer,
  EmptyContainer,
  OverviewContainer,
  CollapseToggleContainer,
  MediaPlannerLOGOContainer,
} from "./styles";

import Section from "./Section";
import Logo from "../Logo";
import Loader from "../Loader";
import Option from "./Section/Option";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

import {
  useGlobalDataStore,
  useGlobalFiltersStore,
} from "../../../stores/global";
import { getSections } from "./helpers";
import {
  JOB_SITE_IDENTIFIERS,
  LOOKER_FILTER_KEYS,
  UNIFIED_VIEW_IDENTIFIERS,
  COMMON_GLOBAL_FILTERS,
  CAREER_AND_JOB_SITE_IDENTIFIERS,
  CAREER_SITE_IDENTIFIERS,
} from "../../../constants/AppConstants";
import SideSMPOption from "../../../modules/mediaplanner/components/SideSMPOption";

const Sidebar = ({
  dashboardList,
  expanded = false,
  handleSidebarToggle,
  updateDashboard,
}) => {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let location = useLocation();
  const containerRef = useRef(null);

  const { products, dashboards } = useGlobalDataStore(
    useShallow((state) => ({
      products: state.products,
      dashboards: state.dashboards,
    }))
  );

  const { currentProduct, setCurrentProduct, setEmbedUrl } =
    useGlobalFiltersStore(
      useShallow((state) => ({
        currentProduct: state.currentProduct,
        setCurrentProduct: state.setCurrentProduct,
        setEmbedUrl: state.setEmbedUrl,
      }))
    );

  const [selectedOption, setSelectedOption] = useState(null);
  const [isHovering, toggleHovering] = useState(false);

  const shouldSidebarExpand = useMemo(() => {
    if (!!expanded) return true;
    return isHovering;
  }, [expanded, isHovering]);

  const sections = getSections(dashboardList, products);

  const handleProductSelect = (opt) => {
    if (!!opt?.route) {
      const params = Object.fromEntries([...searchParams]);
      const dashboardId = !!opt.dashboardMapIdentifierKey
        ? dashboards[opt.dashboardMapIdentifierKey].id
        : "";
      if (!!dashboardId) {
        params[LOOKER_FILTER_KEYS.DASHBOARD_ID] =
          dashboards[opt.dashboardMapIdentifierKey].id;
      }
      if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
        delete params[LOOKER_FILTER_KEYS.FILTER_ID];
      }
      if (opt?.globalFilters) {
        const commonFilters = [...COMMON_GLOBAL_FILTERS, ...opt?.globalFilters];
        Object.keys(params).forEach((item) => {
          if (!commonFilters.includes(item)) {
            delete params[item];
          }
        });
      }
      const urlSearchParams = "?" + new URLSearchParams(params).toString();
      setEmbedUrl("");
      navigate({
        pathname: opt.route,
        search: urlSearchParams,
      });
    }
  };

  useEffect(() => {
    if (!!currentProduct) {
      const doesCSExist = products.find(
        (item) => item.productKey === CAREER_SITE_IDENTIFIERS.productKey
      );
      const doesJSExist = products.find(
        (item) => item.productKey === JOB_SITE_IDENTIFIERS.productKey
      );
      const doesBothCsJsExist = !!doesCSExist && !!doesJSExist;
      const isCurrentProductCsJs = [
        CAREER_SITE_IDENTIFIERS.productKey,
        JOB_SITE_IDENTIFIERS.productKey,
      ].includes(currentProduct.productKey);
      if (!!doesBothCsJsExist && isCurrentProductCsJs) {
        setSelectedOption({
          ...CAREER_AND_JOB_SITE_IDENTIFIERS,
          title: CAREER_AND_JOB_SITE_IDENTIFIERS.display,
        });
      } else {
        setSelectedOption({
          ...currentProduct,
          title: currentProduct?.title || currentProduct.display,
        });
      }
    }
  }, [currentProduct, products]);

  const shouldShowOverview = (() => {
    const doesHaveAccess = !!products?.find(
      (item) => item?.productKey === UNIFIED_VIEW_IDENTIFIERS.productKey
    );
    return doesHaveAccess;
  })();
  const isSalesMediaPlannerRoute = location.pathname.includes("/media-planner");
  const checkJoveoUserfromEmail = () => {
    const email = localStorage.getItem("email");
    if (email?.toLocaleLowerCase().endsWith("joveo.com")) {
      return true;
    } else return false;
  };
  const isSMPViewUser = checkJoveoUserfromEmail();
  return (
    <SidebarContainer
      isExpanded={shouldSidebarExpand}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      ref={containerRef}
    >
      {!!products?.length && (
        <ContentContainer>
          {!isSalesMediaPlannerRoute && shouldShowOverview && (
            <OverviewContainer>
              <Option
                icon={UNIFIED_VIEW_IDENTIFIERS.icon}
                hoverIcon={`${UNIFIED_VIEW_IDENTIFIERS.icon}-active`}
                text={UNIFIED_VIEW_IDENTIFIERS.display}
                active={location.pathname === UNIFIED_VIEW_IDENTIFIERS.route}
                isExpanded={shouldSidebarExpand}
                onClick={() => {
                  handleProductSelect(UNIFIED_VIEW_IDENTIFIERS);
                }}
              />
            </OverviewContainer>
          )}
          {!isSalesMediaPlannerRoute &&
            sections?.map((section) => (
              <Section
                key={section.title}
                title={section.title}
                options={section.options}
                active={selectedOption}
                expanded={shouldSidebarExpand}
                onChange={handleProductSelect}
                onSelectExpandedOption={updateDashboard}
              />
            ))}
          {isSalesMediaPlannerRoute && (
            <SideSMPOption isExpanded={shouldSidebarExpand} />
          )}
          {!isSalesMediaPlannerRoute && isSMPViewUser ? (
            <MediaPlannerLOGOContainer>
              <Option
                icon={"fast-forward-blue"}
                text="Media Planner"
                isExpanded={shouldSidebarExpand}
                onClick={() => navigate("/media-planner")}
              />
            </MediaPlannerLOGOContainer>
          ) : null}
        </ContentContainer>
      )}
      {!products?.length && (
        <EmptyContainer>
          <Loader />
        </EmptyContainer>
      )}
      <CollapseToggleContainer
        onClick={() => {
          handleSidebarToggle();
          if (expanded) {
            containerRef.current.style.pointerEvents = "none";
            setTimeout(() => {
              containerRef.current.style.pointerEvents = "auto";
            }, 100);
          }
        }}
        isExpanded={expanded}
        className="CollapseToggleContainer"
      >
        <Tooltip text={expanded ? "Collapse" : "Expand"}>
          <Icon name={`arrow-${expanded ? "left" : "right"}`} />
        </Tooltip>
      </CollapseToggleContainer>
    </SidebarContainer>
  );
};

export default Sidebar;

import React from 'react';

import {
  FilterChangeModalContainer,
  Header,
  Title,
  Body,
  Footer,
  Button
} from "./styles"

import Icon from "../../common/Icon"
import Modal from "../../common/Modal"

const TITLE = "Filter change alert"
const ALERT_TEXT = "The change in entity selection will remove certain filters that are not applicable. Are you sure?"

const FilterChangeModal = ({
  onSubmit,
  onClose
}) => {
  return (
    <Modal position="center" className="FilterChangeModal" onClose={onClose}>
      <FilterChangeModalContainer>
        <Header>
          <Title>{TITLE}</Title>
          <Icon name="close" alt="close" onClick={onClose} />
        </Header>
        <Body>{ALERT_TEXT}</Body>
        <Footer>
          <Button onClick={onClose}>No</Button>
          <Button type="submit" onClick={onSubmit}>Yes</Button>
        </Footer>
      </FilterChangeModalContainer>
    </Modal>
  );
}

export default FilterChangeModal;

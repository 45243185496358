import styled from "styled-components";
import { devices } from "../../constants/AppConstants";
import { SaveScheduleContainer, Text } from "../../components/common/Subheader/styles";

export const CareerAndJobSiteContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;

  @media screen and ${devices.mobile}{
    padding: 1rem 0;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  ${SaveScheduleContainer} {
    margin-top: ${props => props.isTabView ? "-5.5rem" : "0"};
    @media screen and ${devices.mobile}{
      margin-top: 0rem;
    }
  }
  ${Text} {
    /* margin-top: ${props => props.isTabView ? "2rem" : "3rem"}; */

    @media screen and ${devices.mobile}{
      margin-top: 0rem;
    }
  }
`
export const EmptyContainer = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #e2e8f0;
  padding: 6px;
  width: fit-content;
  border-radius: 6px;
  div {
    gap: 4px;
    @media screen and ${devices.mobile} {
      display: flex;
      width: 100%;
    }
  }
  a {
    text-decoration: none;
  }
  @media screen and ${devices.mobile} {
    display: flex;
    width: 100%;
    position: static;
  }
`;
export const AGGREGATED_TYPE = "AGGREGATED"
export const SCORE_TYPE = "SCORE"
export const INSIGHTS_TYPE = "INSIGHTS"
export const BREAKDOWN_TYPE = "JOBS_BREAKDOWN"
export const JOB_DETAILS_TYPE = "JOB_DETAILS"
export const JOBGROUP_GROUP = "Jobgroup"
export const CAMPAIGN_GROUP = "Campaign"

export const JOB_GROUPED_VIEW = "job_quality_per_entity"
export const JOB_QUALITY_VIEW = "job_quality"
export const JOB_INSIGHTS_VIEW = "view_more_job_quality"
export const JOB_BREAKDOWN_VIEW = "view_more_job_quality"
export const JOB_DETAILS_VIEW = "view_more_job_quality"

export const JOB_GROUPED_FILTER_MAP = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_GROUPED_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_GROUPED_VIEW}.client_id`,
  CAMPAIGN_ID: `${JOB_GROUPED_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${JOB_GROUPED_VIEW}.job_group_id`,
}

export const JOB_QUALITY_FILTER_MAP = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_QUALITY_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_QUALITY_VIEW}.client_id`,
  CAMPAIGN_ID: `${JOB_QUALITY_VIEW}.campaign_id`,
  JOB_GROUP_ID: `${JOB_QUALITY_VIEW}.job_group_id`,
}

export const JOB_INSIGHTS_FILTER_MAP = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `job_content_optimized_data.agency_id`,
  CLIENT_ID: `job_content_optimized_data.client_id`,
  CAMPAIGN_ID: `job_content_optimized_data.campaign_id`,
  JOB_GROUP_ID: `job_content_optimized_data.job_group_id`,
}

export const JOB_BREAKDOWN_FILTER_MAP = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_BREAKDOWN_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_BREAKDOWN_VIEW}.client_id`,
}

export const JOB_DETAILS_FILTER_MAP = {
  ACCOUNT_ID: `sub_accounts.account_id`,
  SUB_ACCOUNT_ID: `sub_accounts.sub_account_id`,
  AGENCY_ID: `${JOB_DETAILS_VIEW}.agency_id`,
  CLIENT_ID: `${JOB_DETAILS_VIEW}.client_id`,
  JOB_ID: `${JOB_DETAILS_VIEW}.job_id`,
}

export const JOB_AGGREGATED_CAMPAIGN_FIELD = "campaign_info.campaign_name"
export const JOB_AGGREGATED_CAMPAIGN_ID_FIELD = "job_quality_per_entity.campaign_id"
export const JOB_AGGREGATED_JOBGROUP_FIELD = "jg_info.job_group_name"
export const JOB_AGGREGATED_JOBGROUP_ID_FIELD = "job_quality_per_entity.job_group_id"
export const JOB_AGGREGATED_TOTAL_JOBS_KEY = "job_quality_per_entity.total_jobs"
export const JOB_AGGREGATED_ORIGINAL_JOBS_SCORE_KEY = "job_quality.original_job_overall_score"

export const JOB_SCORE_ORIGINAL_SCORE_KEY = "job_quality.optimized_job_overall_score"
export const JOB_SCORE_OPTIMISED_SCORE_KEY = "job_quality.original_job_overall_score"
export const JOB_SCORE_TOTAL_JOBS_KEY = "job_quality.total_jobs"

export const JOB_INSIGHTS_JOB_NAME_KEY = "job_content_optimized_data.original_job_name"
export const JOB_INSIGHTS_JOB_SCORE_KEY = "job_content_optimized_data.original_job_score"

export const JOBS_BREAKDOWN_JOB_ID_KEY = "view_more_job_quality.job_id"
export const JOBS_BREAKDOWN_JOB_TITLE_KEY = "view_more_job_quality.job_title"
export const JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY = "job_content_optimized_data.original_jd_overall_score"
export const JOBS_BREAKDOWN_ORIGINAL_JD_KEY = "job_content_optimized_data.original_job_description"
export const JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY = "job_content_optimized_data.original_jd_scores"
export const JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY = "job_content_optimized_data.optimized_jd_overall_score"
export const JOBS_BREAKDOWN_OPTIMISED_JD_KEY = "job_content_optimized_data.optimized_job_description"
export const JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY = "job_content_optimized_data.optimized_jd_scores"
export const JOBS_BREAKDOWN_JOB_GROUP_FIELD = "jg_info.job_group_name"
export const JOBS_BREAKDOWN_CAMPAIGN_FIELD = "campaign_info.campaign_name"
export const JOBS_BREAKDOWN_CAMPAIGN_ID_FILTER_KEY = "view_more_job_quality.campaign_id"
export const JOBS_BREAKDOWN_JOBGROUP_ID_FILTER_KEY = "view_more_job_quality.job_group_id"
export const JOBS_BREAKDOWN_CAMPAIGN_ID_FIELD = "job_quality_per_entity.campaign_id"
export const JOBS_BREAKDOWN_JOBGROUP_ID_FIELD = "job_quality_per_entity.job_group_id"
export const JOBS_BREAKDOWN_SORT_FIELD = "job_content_optimized_data.original_jd_overall_score"

export const JOB_AGGREGATED_FIELDS = [
  JOB_AGGREGATED_TOTAL_JOBS_KEY,
  JOB_AGGREGATED_ORIGINAL_JOBS_SCORE_KEY
]

export const JOB_BREAKDOWN_FIELDS = [
  JOBS_BREAKDOWN_JOB_ID_KEY,
  JOBS_BREAKDOWN_JOB_TITLE_KEY,
  JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY,
  JOBS_BREAKDOWN_ORIGINAL_JD_KEY,
  JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY,
  JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY,
  JOBS_BREAKDOWN_OPTIMISED_JD_KEY,
  JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY
]

const parseJobsAggregated = (data, type) => {
  return {
    title: type === CAMPAIGN_GROUP ? data[JOB_AGGREGATED_CAMPAIGN_FIELD] : data[JOB_AGGREGATED_JOBGROUP_FIELD],
    label: data[JOB_AGGREGATED_TOTAL_JOBS_KEY],
    score: `${parseInt(data?.[JOB_AGGREGATED_ORIGINAL_JOBS_SCORE_KEY] * 100)}%`
  }
}

export const JOBS_API_CONFIG = {
  AGGREGATED: {
    [JOBGROUP_GROUP]: {
      VIEW: JOB_GROUPED_VIEW,
      FIELDS: [...JOB_AGGREGATED_FIELDS, JOB_AGGREGATED_JOBGROUP_FIELD, JOB_AGGREGATED_JOBGROUP_ID_FIELD],
      FILTER_MAP: JOB_GROUPED_FILTER_MAP,
      dataParser: parseJobsAggregated
    },
    [CAMPAIGN_GROUP]: {
      VIEW: JOB_GROUPED_VIEW,
      FIELDS: [...JOB_AGGREGATED_FIELDS, JOB_AGGREGATED_CAMPAIGN_FIELD, JOB_AGGREGATED_CAMPAIGN_ID_FIELD],
      FILTER_MAP: JOB_GROUPED_FILTER_MAP,
      dataParser: parseJobsAggregated
    }
  },
  SCORE: {
    VIEW: JOB_QUALITY_VIEW,
    FIELDS: [JOB_SCORE_ORIGINAL_SCORE_KEY, JOB_SCORE_OPTIMISED_SCORE_KEY, JOB_SCORE_TOTAL_JOBS_KEY],
    FILTER_MAP: JOB_QUALITY_FILTER_MAP,
  },
  INSIGHTS: {
    VIEW: JOB_INSIGHTS_VIEW,
    FILTER_MAP: JOB_INSIGHTS_FILTER_MAP,
    FIELDS: [JOB_INSIGHTS_JOB_NAME_KEY, JOB_INSIGHTS_JOB_SCORE_KEY]
  },
  JOBS_BREAKDOWN: {
    [JOBGROUP_GROUP]: {
      VIEW: JOB_BREAKDOWN_VIEW,
      FIELDS: [...JOB_BREAKDOWN_FIELDS, JOBS_BREAKDOWN_CAMPAIGN_FIELD],
      FILTER_MAP: JOB_BREAKDOWN_FILTER_MAP,
      SORTS: [JOBS_BREAKDOWN_SORT_FIELD],
      getAdditionalFilters: (filters) => ({ [JOBS_BREAKDOWN_JOBGROUP_ID_FILTER_KEY]: filters[JOBS_BREAKDOWN_JOBGROUP_ID_FIELD] }),
    },
    [CAMPAIGN_GROUP]: {
      VIEW: JOB_BREAKDOWN_VIEW,
      FIELDS: [...JOB_BREAKDOWN_FIELDS, JOBS_BREAKDOWN_JOB_GROUP_FIELD],
      FILTER_MAP: JOB_BREAKDOWN_FILTER_MAP,
      SORTS: [JOBS_BREAKDOWN_SORT_FIELD],
      getAdditionalFilters: (filters) => ({ [JOBS_BREAKDOWN_CAMPAIGN_ID_FILTER_KEY]: filters[JOBS_BREAKDOWN_CAMPAIGN_ID_FIELD] }),
    }
  }
}

export const JOB_SCORE_WIDGET_CONFIG = [
  { targetKey: JOB_SCORE_ORIGINAL_SCORE_KEY, valueKey: "rendered" },
  { targetKey: JOB_SCORE_OPTIMISED_SCORE_KEY, valueKey: "rendered" },
  { targetKey: JOB_SCORE_TOTAL_JOBS_KEY, valueKey: "value" }
]

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCBellcurve from "highcharts/modules/histogram-bellcurve";

import { formatNumberThousandSeparated } from "../../utils/helpers";

import Modal from "../common/Modal";
import Dialog from "../common/Dialog";
import { getChartDataOptions } from "./helpers";
import { DEVICE_SIZES_PX } from "../../constants/AppConstants";

HCBellcurve(Highcharts);

const JobCompare = ({ modalData, onClose }) => {
  const { row, value, currency } = modalData;

  const title = "Compensation positioning";
  const avgMedianCompensation =
    row["inbound_norm_jobs.avg_median_market_rate"]["value"];
  const job = row["inbound_norm_jobs.job_title"]["value"];
  const avgMinMarketCompensation =
    row["inbound_norm_jobs.avg_min_market_rate"]["value"];
  const avgMaxMarketCompensation =
    row["inbound_norm_jobs.avg_max_market_rate"]["value"];
  const subText = (
    <p
      style={{
        color: "#6B7280",
        fontSize: "0.9rem",
        fontStyle: "normal",
        fontWeight: 400,
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        maxWidth: window.innerWidth < DEVICE_SIZES_PX.MOBILE ? "100vw" : "80vw",
      }}
    >
      Typical compensation ranges from{" "}
      {formatNumberThousandSeparated(avgMinMarketCompensation, currency)} to{" "}
      {formatNumberThousandSeparated(avgMaxMarketCompensation, currency)} for{" "}
      <span style={{ fontWeight: 600 }}>{job}</span> job
    </p>
  );

  const chartDataOptions = getChartDataOptions({
    value,
    currency,
    avgMedianCompensation,
  });

  return (
    <Modal
      position="center"
      onClose={onClose}
      modalDialogStyle={{
        width: window.innerWidth < DEVICE_SIZES_PX.MOBILE ? "100vw" : "80vw",
        height: "550px",
      }}
      modalContentStyle={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "100%",
      }}
    >
      <Dialog
        title={title}
        onClose={onClose}
        dialogContainerStyle={{
          backgroundColor: "white",
          width: window.innerWidth < DEVICE_SIZES_PX.MOBILE ? "100vw" : "80vw",
          height: "550px",
          borderRadius: "0.4rem",
        }}
      >
        <div>{subText}</div>
        <div style={{ margin: "1.5rem 1.5rem 0rem" }}>
          <HighchartsReact highcharts={Highcharts} options={chartDataOptions} />
        </div>
      </Dialog>
    </Modal>
  );
};

export default JobCompare;

import { formatNumberThousandSeparatedWithoutCurrency } from "../../../utils/helpers"

export const getJobCount = (data, config) => {
  return formatNumberThousandSeparatedWithoutCurrency(data?.[config.jobCountTargetKey]?.[config.jobCountValueKey] || 0, "compact", 0, 0)
}

export const getOriginalScore = (data, config) => {
  return data?.[config.originalScoreTargetKey]?.[config.originalScoreValueKey] || "0"
}

export const getOptimisedScore = (data, config) => {
  return data?.[config.optimisedScoreTargetKey]?.[config.optimisedScoreValueKey] || "0"
}

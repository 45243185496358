import React, { useState, useRef, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { isSameDay, format, compareAsc } from 'date-fns';
import { useShallow } from "zustand/react/shallow";
import 'react-datepicker/dist/react-datepicker.css';

import { DatepickerContainer, OptionsContainer, ButtonsContainer, CalendarButton, Option } from './styles';

import Icon from "../Icon"
import CustomInput from "./CustomInput"
import BottomModal from '../BottomModal';
import WeekSelection from './WeekSelection';

import { DATE_OPTIONS, DEVICE_SIZES_PX } from '../../../constants/AppConstants'
import Mixpanel from '../../../services/MixPanel';
import { getScreenName } from '../../../utils/helpers';
import { useGlobalDataStore } from "../../../stores/global";
import { getWeekOptionsForYear } from './helpers';
import { formatDate } from '../../../utils/dateHelpers';

const getFormattedDate = (range) => {
  return `${!!range[0] ? format(range[0], "dd MMM yyyy") : ""} - ${!!range[1] ? format(range[1], "dd MMM yyyy") : ""}`
}

const Datepicker = ({
  selected,
  disabled = false,
  className = "",
  onChange
}) => {
  const { weekStart } = useGlobalDataStore(
    useShallow((state) => ({
      weekStart: state.weekStart
    }))
  );

  const [dateRange, setDateRange] = useState(null);
  const [dateOption, setDateOption] = useState(null);
  const [prevState, setPrevState] = useState({
    range: null,
    option: null
  });
  const [isMobileView, toggleMobileView] = useState(false)
  const [isOpen, togglePicker] = useState(false)
  const [isWeekOptionOpen, toggleWeekOption] = useState(false)
  const [weekOptions, setWeekOptions] = useState(null)
  const ref = useRef(null);

  const onOptionChange = (opt) => {
    if (opt.key === "week") {
      toggleWeekOption(true)
    } else {
      setDateOption(opt);
      if (opt.key !== 'custom') {
        setDateRange(opt.getDateRange());
      }
    }
  }

  const onSubmit = () => {
    if (!!dateOption && isValid) {
      const dateString = dateOption.key !== "week" ? dateOption.getDateFilterString(dateRange) : `${formatDate(
        dateRange[0],
        "start"
      )} to ${formatDate(dateRange[1], "end")}`;
      onChange(dateString, dateOption);
      ref.current.setOpen(false);
      Mixpanel.trackEvent("Date Filter Applied", {
        'Screen name': getScreenName(),
        'Selected Time Frame': dateOption?.display
      })
      if (isOpen) {
        togglePicker(false)
      }
    }
    toggleWeekOption(false)
  }

  const handleWeekSelection = (selection) => {
    const sorted = selection.sort((a, b) => compareAsc(a.start, b.start))
    setDateOption(DATE_OPTIONS.find(item => item.key === "week"))
    setDateRange([selection[0].start, selection.at(-1).end])
    setWeekOptions(selection)
    toggleWeekOption(false)
  }

  const isValid = useMemo(() => {
    if (!!dateOption) {
      if (dateOption.key === 'custom') {
        return dateRange?.every(item => item != null)
      }
      return true;
    }
    return false
  }, [dateRange, dateOption]);

  const weeks = getWeekOptionsForYear(weekStart)

  useEffect(() => {
    const thisMonth = DATE_OPTIONS.find(item => item.key === 'this month');
    setDateRange(thisMonth.getDateRange());
    setDateOption(thisMonth);
    setPrevState({
      range: thisMonth.getDateRange(),
      option: thisMonth
    })
  }, []);

  useEffect(() => {
    const optionsContainer = document.querySelector(".Options")
    let opt = null
    if (selected?.length) {
      const prev = {
        range: selected,
        option: null
      }
      setDateRange(selected);
      const option = DATE_OPTIONS.find((item) => {
        if (!!item?.getDateRange) {
          const dr = item.getDateRange();
          const d1Match = dr?.[0] && isSameDay(dr[0], selected[0])
          const d2Match = dr?.[1] ? isSameDay(dr[1], selected[1]) : true;
          return d1Match && d2Match;
        }
        return false
      })
      if (option) {
        setDateOption(option);
        setWeekOptions([])
        prev.option = option;
        opt = option
      } else {
        // Check for week option
        const weeks = getWeekOptionsForYear(weekStart)
        const doesStartMatch = weeks?.find(item => isSameDay(item.start, selected[0]))
        if (!!doesStartMatch) {
          const doesEndMatch = weeks?.find(item => isSameDay(item.end, selected[1]))
          if (!!doesEndMatch) {
            const week = DATE_OPTIONS.find(item => item.key === 'week')
            setDateOption(week)
            prev.option = week;
            opt = week
            const startIndex = weeks?.findIndex(item => isSameDay(item.start, selected[0]))
            const endIndex = weeks?.findIndex(item => isSameDay(item.end, selected[1]))
            const weekSelected = weeks?.slice(endIndex, startIndex + 1).sort((a, b) => compareAsc(a.start, b.start))
            setWeekOptions(weekSelected)
          } else {
            setWeekOptions([])
          }
        } else {
          const custom = DATE_OPTIONS.find(item => item.key === 'custom')
          setDateOption(custom)
          setWeekOptions([])
          prev.option = custom;
          opt = custom
        }
      }
      setPrevState(prev)
    } else {
      const thisMonth = DATE_OPTIONS.find(item => item.key === 'this month');
      setDateRange(thisMonth.getDateRange());
      setDateOption(thisMonth)
      setWeekOptions([])
      setPrevState({
        range: thisMonth.getDateRange(),
        option: thisMonth
      })
      opt = thisMonth
    }
    if (isOpen) {
      const optionNode = [...optionsContainer.childNodes].find(item => item.innerText === opt.display)
      optionsContainer.scrollTo({ left: optionNode.offsetLeft - 50 })
    }
  }, [selected, isOpen, weekStart]);

  useEffect(() => {
    if (window.innerWidth < DEVICE_SIZES_PX.MOBILE) {
      toggleMobileView(true)
    } else {
      toggleMobileView(false)
    }
  }, [window.innerWidth])

  return (
    <DatepickerContainer className={className}>
      {
        isMobileView ? (
          <>
            <CustomInput
              value={getFormattedDate(dateRange)}
              label={dateOption?.display}
              disabled={disabled}
              onClick={() => {
                togglePicker(true)
                if (!isOpen) {
                  Mixpanel.trackEvent("Date Selector Clicked", {
                    "Screen name": getScreenName()
                  });
                }
              }}
            />
            <BottomModal isOpen={isOpen} onClose={() => togglePicker(false)}>
              {
                isOpen && (
                  <DatePicker
                    calendarClassName="Datepicker"
                    placeholderText='Please select a date'
                    ref={ref}
                    selected={dateRange?.[0]}
                    selectsRange={dateOption?.key !== 'today' && dateOption?.key !== 'yesterday'}
                    startDate={dateRange?.[0]}
                    endDate={dateRange?.[1]}
                    maxDate={new Date()}
                    calendarStartDay={weekStart}
                    locale="en-GB"
                    showWeekNumbers
                    monthsShown={1}
                    dateFormat="dd MMM yyyy"
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                    customInput={<CustomInput label={dateOption?.display} disabled={disabled} />}
                    shouldCloseOnSelect={false}
                    openToDate={dateRange?.[0]}
                    disabled={disabled}
                    onCalendarOpen={() => {
                      Mixpanel.trackEvent("Date Selector Clicked", {
                        "Screen name": getScreenName(),
                      });
                    }}
                    inline
                    onChange={(d) => {
                      const selectsRange = dateOption?.key !== 'today' && dateOption?.key !== 'yesterday';
                      if (!Array.isArray(d)) {
                        setDateOption(DATE_OPTIONS.find(item => item.key === 'custom'));
                        setDateRange([d])
                      } else {
                        setDateRange(d)
                        if (selectsRange) {
                          setDateOption(DATE_OPTIONS.find(item => item.key === 'custom'));
                        }
                      }
                    }}
                  >
                    <>
                      <OptionsContainer className="Options">
                        {
                          DATE_OPTIONS.map((opt) => (
                            <Option
                              key={opt.key}
                              active={dateOption?.key === opt.key}
                              onClick={() => onOptionChange(opt)}
                            >
                              {opt.display}
                              {dateOption?.key === opt.key && <Icon name="tick" />}
                            </Option>
                          ))
                        }
                      </OptionsContainer>
                      <ButtonsContainer>
                        <CalendarButton
                          onClick={() => {
                            ref.current.setOpen(false);
                            setDateOption(prevState.option)
                            setDateRange(prevState.range)
                            if (isOpen) {
                              togglePicker(false)
                            }
                          }}
                        >
                          Cancel
                        </CalendarButton>
                        <CalendarButton
                          submit
                          disabled={!isValid}
                          onClick={onSubmit}
                        >
                          Apply
                        </CalendarButton>
                      </ButtonsContainer>
                    </>
                  </DatePicker>
                )
              }
            </BottomModal>
          </>
        )
          : (
            <>
              <DatePicker
                calendarClassName="Datepicker"
                placeholderText='Please select a date'
                ref={ref}
                selected={dateRange?.[0]}
                selectsRange={dateOption?.key !== 'today' && dateOption?.key !== 'yesterday'}
                startDate={dateRange?.[0]}
                endDate={dateRange?.[1]}
                maxDate={new Date()}
                calendarStartDay={weekStart}
                onChange={(d) => {
                  const selectsRange = dateOption?.key !== 'today' && dateOption?.key !== 'yesterday';
                  if (!Array.isArray(d)) {
                    setDateOption(DATE_OPTIONS.find(item => item.key === 'custom'));
                    setDateRange([d])
                  } else {
                    setDateRange(d)
                    if (selectsRange) {
                      setDateOption(DATE_OPTIONS.find(item => item.key === 'custom'));
                    }
                  }
                }}
                monthsShown={2}
                dateFormat="dd MMM yyyy"
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                customInput={<CustomInput label={dateOption?.display} disabled={disabled} />}
                shouldCloseOnSelect={false}
                openToDate={dateRange?.[0]}
                disabled={disabled}
                showWeekNumbers
                onClickOutside={() => {
                  toggleWeekOption(false)
                }}
              >
                <>
                  <OptionsContainer>
                    {
                      DATE_OPTIONS.map((opt) => (
                        <Option
                          key={opt.key}
                          active={dateOption?.key === opt.key}
                          onClick={() => onOptionChange(opt)}
                        >
                          {opt.display}
                          {!!opt?.expandable && <Icon name="arrow-right" />}
                          {dateOption?.key === opt.key && <Icon name="tick" />}
                        </Option>
                      ))
                    }
                  </OptionsContainer>
                  {
                    !!isWeekOptionOpen && (
                      <WeekSelection
                        selected={weekOptions}
                        options={weeks}
                        className="WeekSelection"
                        idKey="weekNumber"
                        displayKey="display"
                        onSubmit={handleWeekSelection}
                        onClose={() => {
                          toggleWeekOption(false)
                        }}
                      />
                    )
                  }
                  <ButtonsContainer>
                    <CalendarButton
                      onClick={() => {
                        ref.current.setOpen(false);
                        setDateOption(prevState.option)
                        setDateRange(prevState.range)
                        toggleWeekOption(false)
                      }}
                    >
                      Cancel
                    </CalendarButton>
                    <CalendarButton
                      submit
                      disabled={!isValid}
                      onClick={onSubmit}
                    >
                      Apply
                    </CalendarButton>
                  </ButtonsContainer>
                </>
              </DatePicker>
            </>
          )
      }
    </DatepickerContainer>
  );
}

export default Datepicker;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainApp from "./components/MainApp";
import AuthenticatedRoute from "./components/common/AuthenticatedRoute";
import PageNotFound from "./pages/PageNotFound";

import { getItemFromLocalStorage } from "./helpers/commonHelper";
import { ACCESS_TOKEN_LS_KEY } from "./constants/AppConstants";
import { NOT_FOUND_ROUTE_IDENTIFIER } from "./constants/AppRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled:
        !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatedRoute>
        <Router>
          <SentryRoutes>
            <Route path="/*" element={<MainApp />} />
            <Route path={NOT_FOUND_ROUTE_IDENTIFIER} exact element={<PageNotFound />} />
          </SentryRoutes>
        </Router>
      </AuthenticatedRoute>
      <ToastContainer />
    </QueryClientProvider>
  );
}
export default App;

import * as Sentry from "@sentry/react";
import cronstrue from "cronstrue";

import {
  CURRENCY_KEYS,
  CURRENCY_SIGN_TO_KEY_MAP,
  CURRENCY_SIGNS,
  STRING_SEPARATOR,
  UNIFIED_ANALYTICS_ORIGIN,
  MIXPANEL_ATTRIBUTES_KEY,
  MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY,
  DEVICE_SIZES_PX,
  PROGRAMMATIC_IDENTIFIERS,
  CURRENCY_KEY_TO_SIGN_MAP,
} from "../constants/AppConstants";
import { CAREER_SITE_ROUTE, CRM_ROUTE, MARKET_INSIGHTS_ROUTE, REPORTS_ROUTE, JOB_SITE_ROUTE } from "../constants/AppRoutes";
import { UNITS_CONFIG } from "../constants/Units";
import { getItemFromLocalStorage } from "../helpers/commonHelper";

const COMMA_ESCAPE = "^,";
const SPACE_ESCAPE = "^ ^ ";

const CUSTOM_OPTION_STYLE = {
  maxWidth: "100%",
  minWidth: "auto",
};

const DROPDOWN_BUTTON_STYLE = {
  width: "100%",
  marginTop: "1rem",
};

const CUSTOM_OPTIONS_CONTAINER_STYLE = {
  width: "100%",
};

export const getModel = (models, key) => {
  if (!models) return ""
  if (!!key) return models[key]
  return models[PROGRAMMATIC_IDENTIFIERS.modelKey]
};

export const formatNumberThousandSeparated = (
  num,
  currency = CURRENCY_KEYS.DOLLAR,
  type = "standard",
  minDecimal = 0,
  maxDecimal = 2,
  placeholder = "N/A"
) => {
  if (typeof num !== "number") return placeholder;
  const formattedNumber = new Intl.NumberFormat("en-US", {
    notation: type,
    style: "currency",
    currency,
    useGrouping: true,
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  }).format(num);

  if (currency.toLowerCase() === "cad") {
    return formattedNumber.replace("CA$", "C$");
  }

  if (currency.toLowerCase() === "mxn") {
    return formattedNumber.replace("MX$", "Mex$");
  }

  if (currency.toLowerCase() === "sgd") {
    return formattedNumber.replace("SGD", "S$");
  }

  return formattedNumber;
};

export const formatNumberThousandSeparatedWithoutCurrency = (
  num,
  type = "standard",
  minDecimal = 0,
  maxDecimal = 2
) => {
  if (typeof num !== "number") return "N/A";
  const formattedNumber = new Intl.NumberFormat("en-US", {
    notation: type,
    useGrouping: true,
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  }).format(num);
  return formattedNumber;
};

export const getFormattedDate = (date) => {
  const formattedDay = new Date(date).toLocaleDateString(undefined, {
    day: "numeric",
    timeZone: "UTC",
  });
  const formattedMonth = new Date(date).toLocaleDateString(undefined, {
    month: "short",
    timeZone: "UTC",
  });
  const formattedYear = new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    timeZone: "UTC",
  });
  return formattedDay + " " + formattedMonth + ", " + formattedYear;
};

const notNil = (i) => !(typeof i === "undefined" || i === null);

const isArrayEmpty = (arr) => {
  return arr.every((element) => {
    // Check if element is null, undefined, or an empty object
    return (
      element === null ||
      element === undefined ||
      (typeof element === "object" && Object.keys(element).length === 0)
    );
  });
};

export const isColorAxisRequired = (data) => {
  if (data.length === 0) {
    return false;
  }

  if (isArrayEmpty(data)) {
    return false;
  }

  const valuesArray = data
    .map((dataObj) => dataObj.value)
    .filter((value) => notNil(value));

  const maxValue = Math.max(...valuesArray);
  const minValue = Math.min(...valuesArray);

  if (Math.floor(maxValue) === Math.floor(minValue)) {
    return false; // if both max and min values are same, no need for color axis
  }

  return true;
};

export const getCurrencySign = (data, currencyKey = "") => {
  if (!data || data.length === 0) {
    return CURRENCY_SIGNS.DOLLAR;
  }

  const firstDataObj = data[0];

  if (!!currencyKey) {
    if (!!firstDataObj[currencyKey]) {
      return firstDataObj[currencyKey]
    }
  }

  for (const key in firstDataObj) {
    if (key.endsWith("currency") && firstDataObj[key].value) {
      return firstDataObj[key].value;
    }
  }
  return CURRENCY_SIGNS.DOLLAR;
};

export const getCurrencyKey = (data) => {
  if (!data || data.length === 0) {
    return CURRENCY_KEYS.DOLLAR;
  }

  const firstDataObj = data[0];

  for (const key in firstDataObj) {
    if (key.endsWith("currency") && firstDataObj[key].value) {
      return CURRENCY_SIGN_TO_KEY_MAP[firstDataObj[key].value];
    }
  }
  return CURRENCY_KEYS.DOLLAR;
};

export const parseCurrency = (symbol) => {
  if (!symbol) return CURRENCY_KEYS.DOLLAR;
  const sign = [...Object.entries(CURRENCY_SIGNS)].find(
    ([key, value]) => value === symbol
  );
  if (!sign) {
    const currKey = [...Object.entries(CURRENCY_KEYS)].find(
      ([key, value]) => value === symbol
    );
    if (currKey) return symbol;
  }
  const key = CURRENCY_KEYS[sign[0]];
  return key;
};

export const formatUAData = ({
  value,
  currency = "usd",
  column,
  placeholder = "N/A",
  type = "compact",
  minDecimalNo = 0,
  maxDecimal = 2,
}) => {
  if (!value && value !== 0) return placeholder;
  if (typeof value === "string") return value;
  if (!column) return value;

  if (column === "annualCompensation") {
    return formatNumberThousandSeparated(
      value,
      parseCurrency(currency),
      type,
      minDecimalNo,
      maxDecimal
    );
  }

  const unit = UNITS_CONFIG.find((item) =>
    column.toLocaleLowerCase().includes(item.name)
  )?.type;


  switch (unit) {
    case "currency": {
      const maxDec = column.toLocaleLowerCase().includes("spend")
        ? 0
        : maxDecimal;
      const minDec = column.toLocaleLowerCase().includes("spend")
        ? 0
        : minDecimalNo;
      return formatNumberThousandSeparated(
        value,
        parseCurrency(currency),
        type,
        minDec,
        maxDec
      );
    }
    case "percentage": {
      return `${formatNumberThousandSeparatedWithoutCurrency(
        value,
        type,
        minDecimalNo
      )}%`;
    }
    default: {
      return formatNumberThousandSeparatedWithoutCurrency(
        value,
        type,
        minDecimalNo
      );
    }
  }
};

const escapeSpecialChars = (value) => {
  let escaped = value;
  if (escaped.includes(",")) {
    escaped = value.replaceAll(",", COMMA_ESCAPE);
  }
  const spaceAtEnd = (() => {
    let count = 0;
    for (let i = escaped.length - 1; i >= 0; i--) {
      if (escaped[i] === " ") {
        count++;
      } else {
        break;
      }
    }
    return count;
  })();
  if (spaceAtEnd > 0) {
    const trimmed = escaped.trimEnd();
    escaped = `${trimmed.padEnd(
      trimmed.length + 4 * spaceAtEnd,
      SPACE_ESCAPE
    )}`;
  }
  return escaped;
};

export const parseFilterExpressions = (
  { key, matcher, value },
  filterKeyMap,
  parseKey = "key"
) => {
  const filterKey = filterKeyMap.find((item) => item[parseKey] === key)?.filter;
  if (!filterKey) return {};
  const split = value
    .split(STRING_SEPARATOR)
    .map((item) => escapeSpecialChars(item));
  const escapedValue = escapeSpecialChars(value);
  switch (matcher) {
    case "is":
      return { [filterKey]: escapedValue.replaceAll(STRING_SEPARATOR, ",") };
    case "is not":
      return {
        [filterKey]: `-${escapedValue.replaceAll(STRING_SEPARATOR, ",")}`,
      };
    case "contains":
      return { [filterKey]: split.map((item) => `%${item}%`).join(",") };
    case `doesn't contain`:
      return { [filterKey]: split.map((item) => `-%${item}%`).join(",") };
    case "starts with":
      return { [filterKey]: split.map((item) => `${item}%`).join(",") };
    case "ends with":
      return { [filterKey]: split.map((item) => `%${item}`).join(",") };
    default:
      return escapedValue;
  }
};

export const toFilterExpression = (
  filter,
  filterKeyMap,
  parseKey = "filter"
) => {
  if (!filter || !filterKeyMap?.length) return null;

  const [key, value] = Object.entries(filter)[0];
  const option = filterKeyMap.find((item) => item[parseKey] === key);
  if (!!option) {
    // is not
    if (value.startsWith("-") && !value.includes("%")) {
      return {
        key: option.name,
        matcher: "is not",
        value: value.slice(1).replaceAll(",", `${STRING_SEPARATOR}$$`),
      };
    }
    // contains
    if (value.startsWith("%") && value.endsWith("%")) {
      return {
        key: option.name,
        matcher: "contains",
        value: value
          .replaceAll("%", "")
          .replaceAll(",", `${STRING_SEPARATOR}$$`),
      };
    }
    // doesn't contain
    if (value.startsWith("-%") && value.endsWith("%")) {
      return {
        key: option.name,
        matcher: `doesn't contain`,
        value: value
          .replaceAll("-%", "")
          .replaceAll("%", "")
          .replaceAll(",", `${STRING_SEPARATOR}$$`),
      };
    }
    // starts With
    if (value.endsWith("%")) {
      return {
        key: option.name,
        matcher: `starts with`,
        value: value
          .replaceAll("%", "")
          .replaceAll(",", `${STRING_SEPARATOR}$$`),
      };
    }
    // ends With
    if (value.startsWith("%")) {
      return {
        key: option.name,
        matcher: `ends with`,
        value: value
          .replaceAll("%", "")
          .replaceAll(",", `${STRING_SEPARATOR}$$`),
      };
    }
    return {
      key: option.name,
      matcher: "is",
      value: value.replaceAll(",", `${STRING_SEPARATOR}$$`),
    };
  }
  return null;
};

export const withTracing = (operation, payload) => {
  const apiCallSpan = Sentry.startInactiveSpan({
    name: "Looker API call",
    op: "http",
    description: "API call to fetch data from Looker",
  });
  return new Promise((resolve, reject) => {
    operation(payload)
      .then((res) => {
        apiCallSpan?.finish();
        resolve(res);
      })
      .catch((err) => {
        apiCallSpan?.finish();
        reject(err);
      });
  });
};

export const getPixelConfig = (queryResponse) => {
  const pixelConfigKey = Object.keys(queryResponse?.applied_filters || {})?.find(
    (key) => key.includes("pixel_config")
  );
  if (!!queryResponse?.applied_filters?.[pixelConfigKey]?.value) {
    return JSON.parse(queryResponse.applied_filters?.[pixelConfigKey]?.value);
  } else {
    return null;
  }
};

export const chunkArray = (array, size) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};

export const getUserAndOtherAttributes = () => {
  return {
    [MIXPANEL_ATTRIBUTES_KEY.USER_NAME]: getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.USER_NAME
    ),
    [MIXPANEL_ATTRIBUTES_KEY.USER_EMAIL]: getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.USER_EMAIL
    ),
    [MIXPANEL_ATTRIBUTES_KEY.TIMEFRAME]: JSON.stringify(
      getItemFromLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.TIMEFRAME)
    ),
    ...(getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.INSTANCE_NAME
    ) && {
      [MIXPANEL_ATTRIBUTES_KEY.INSTANCE_NAME]: getItemFromLocalStorage(
        MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.INSTANCE_NAME
      ),
    }),
    ...(getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CLIENT_NAME
    ) && {
      [MIXPANEL_ATTRIBUTES_KEY.CLIENT_NAME]: getItemFromLocalStorage(
        MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CLIENT_NAME
      ),
    }),
    ...(getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CAMPAIGN_NAME
    ) && {
      [MIXPANEL_ATTRIBUTES_KEY.CAMPAIGN_NAME]: getItemFromLocalStorage(
        MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CAMPAIGN_NAME
      ),
    }),
    ...(getItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.JOB_GROUP_NAME
    ) && {
      [MIXPANEL_ATTRIBUTES_KEY.JOB_GROUP_NAME]: getItemFromLocalStorage(
        MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.JOB_GROUP_NAME
      ),
    }),
    "Device Type": detectDeviceType(),
  };
};

export const getReadableCronDescription = (expr) => {
  return cronstrue.toString(expr, { use24HourTimeFormat: true });
};

export const getFiltersFromQueryString = (query) => {
  if (!query) return null;
  let str = query?.startsWith("?") ? query.split("?")[1] : query;
  return str.split("&").reduce((acc, item) => {
    const [key, value] = item.split("=");
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

const getTwoDigitMonth = (num) => (num < 10 ? "0" : "") + num;

export const getDateStringFromMonthAndYear = (month, year) => {
  // Construct a date string in the format "YYYY-MM-DD" where day is set to 1
  const dateString = `${year}-${getTwoDigitMonth(month)}-01`;
  // Parse the date string and return the timestamp
  return dateString;
};

export const generateScheduledEmailMessage = ({ name, title }) => {
  return `Dear ${name},\n\nPlease find attached the scheduled \"${title}\" report.\n\n Please don't reply on this mail. In case you have any query, reach out to your respective customer success manager.`
}

export const detectDeviceType = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? "Mobile"
    : "Desktop";

export const getScreenName = () => {
  if (window.location.pathname === REPORTS_ROUTE) {
    return "Reports";
  }
  if (window.location.pathname.includes(MARKET_INSIGHTS_ROUTE)) {
    return "Market Insights";
  }
  if (window.location.pathname === CRM_ROUTE) {
    return "CRM Analytics";
  }
  if (window.location.pathname === CAREER_SITE_ROUTE) {
    return "Career Analytics";
  }
  if (window.location.pathname === JOB_SITE_ROUTE) {
    return "Job Site Analytics";
  }
  return "Programmatic Analytics";
};

export const convertNegativePercentageToPositive = (percentage) =>
  typeof percentage === "string" ?
    percentage.replace("-", "") : percentage;


export const getDropdownOptionsStyles = (uiConfig) => ({
  customOptionStyle: CUSTOM_OPTION_STYLE,
  dropdownButtonStyle:
    !!uiConfig && !!uiConfig.width && uiConfig.width < DEVICE_SIZES_PX.MOBILE
      ? DROPDOWN_BUTTON_STYLE
      : {},
  customOptionsContainerStyle: CUSTOM_OPTIONS_CONTAINER_STYLE,
});

export const getYAxisLabel = (displayKey, currency) => {
  const type = UNITS_CONFIG.find(
    (item) => item.name === displayKey.toLowerCase()
  )?.type;
  if (type === "currency") {
    return `${displayKey} (${CURRENCY_KEY_TO_SIGN_MAP[currency]})`;
  }
  if (type === "percentage") {
    return `${displayKey} (%)`;
  }
  return displayKey;
};

export const getChartHeight = (screenWidth, config, defaultHeightMobile, defaultHeightTablet, defaultHeight) => {
  if (screenWidth) {
    if (screenWidth < DEVICE_SIZES_PX.MOBILE)
      return config?.chartHeightMobile || defaultHeightMobile;
    if (screenWidth < DEVICE_SIZES_PX.TABLET)
      return config?.chartHeight || defaultHeightTablet;
    return config?.chartHeight || defaultHeight;
  }
  return defaultHeight;
}
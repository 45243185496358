import { UNITS_CONFIG } from "../../constants/Units";

export const PUBLISHER_ANALYSIS_OPTIONS = [
  {
    keys: ["publisher_analysis.Spend", "publisher_analysis.CTA"],
    display: "Spend and CTA",
  },
  {
    keys: ["publisher_analysis.Applies", "publisher_analysis.CTA"],
    display: "Applies and CTA",
  },
  {
    keys: ["publisher_analysis.Spend", "publisher_analysis.CPA"],
    display: "Spend and CPA",
  },
  {
    keys: ["publisher_analysis.Applies", "publisher_analysis.CPA"],
    display: "Applies and CPA",
  },
  {
    keys: ["publisher_analysis.Clicks", "publisher_analysis.CPA"],
    display: "Clicks and CPA",
  },
  {
    keys: ["publisher_analysis.Spend", "publisher_analysis.CPC"],
    display: "Spend and CPC",
  },
  {
    keys: ["publisher_analysis.Clicks", "publisher_analysis.CPC"],
    display: "Clicks and CPC",
  },
];

export const PUBLISHER_ANALYSIS_CONFIG_HOME = {
  showTitle: true,
  title: "Publisher comparison on",
  viewMore: true,
  viewMoreDbKey: "publisherAnalysis",
  optionSelection: true,
  publisherTargetKey: "publisher_analysis.publisher",
  publisherValueKey: "value",
  options: [
    {
      keys: ["publisher_analysis.Spend_converted", "publisher_analysis.CTA"],
      label: "Spend and CTA",
      display: ["Spend", "CTA"],
    },
    {
      keys: ["publisher_analysis.Applies", "publisher_analysis.CTA"],
      label: "Applies and CTA",
      display: ["Applies", "CTA"],
    },
    {
      keys: ["publisher_analysis.Spend_converted", "publisher_analysis.CPA_Converted"],
      label: "Spend and CPA",
      display: ["Spend", "CPA"],
    },
    {
      keys: ["publisher_analysis.Applies", "publisher_analysis.CPA_Converted"],
      label: "Applies and CPA",
      display: ["Applies", "CPA"],
    },
    {
      keys: ["publisher_analysis.Clicks", "publisher_analysis.CPA_Converted"],
      label: "Clicks and CPA",
      display: ["Clicks", "CPA"],
    },
    {
      keys: [
        "publisher_analysis.Spend_converted",
        "publisher_analysis.CPC_Converted",
      ],
      label: "Spend and CPC",
      display: ["Spend", "CPC"],
    },
    {
      keys: ["publisher_analysis.Clicks", "publisher_analysis.CPC_Converted"],
      label: "Clicks and CPC",
      display: ["Clicks", "CPC"],
    },
  ],
};

export const PUBLISHER_ANALYSIS_FILTER_CONFIG = {
  title: "Performance metrics",
  maxSelection: 2,
  selectionText: "Select any 2 metrics",
  selectMax: true,
  inputType: "checkbox",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  filtersToUpdate: [
    {
      name: "statsDisplayParameter1",
    },
    {
      name: "statsDisplayParameter2",
    },
  ],
  defaultFilters: [
    {
      name: "CPA",
    },
    {
      name: "Clicks",
    },
  ],
};

export const PUBLISER_ANALYSIS_CONFIG_VIEW_MORE = {
  showTitle: false,
  optionSelection: false,
  plotRatio: false,
  legends: true,
  viewMore: false,
  xAxisKey: "view_combined_event.final_publisher_name",
  xAxisKeyType: "string",
  multiColor: true,
  dataColumns: [
    {
      dataTargetKey:
        "view_combined_event.publisher_analysis_dimension_details_1",
      dataValueKey: "value",
    },
    {
      dataTargetKey:
        "view_combined_event.publisher_analysis_dimension_details_2",
      dataValueKey: "value",
    },
  ],
  filters: [
    {
      dataTargetKey:
        "view_combined_event.publisher_analysis_parameter_details_1",
      dataValueKey: "value",
    },
    {
      dataTargetKey:
        "view_combined_event.publisher_analysis_parameter_details_2",
      dataValueKey: "value",
    },
  ],
};

export const PUBLISHER_ANALYSIS_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  dynamicColumns: true,
  dynamicRows: false,
  currencyTargetKey: "view_combined_event.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "Publisher",
      dataTargetKey: "view_combined_event.final_publisher_name",
      dataValueKey: "value",
    },
    {
      title: "$$1",
      dataTargetKey:
        "view_combined_event.publisher_analysis_dimension_details_1",
      dataValueKey: "value",
      type: "number"
    },
    {
      title: "$$2",
      dataTargetKey:
        "view_combined_event.publisher_analysis_dimension_details_2",
      dataValueKey: "value",
      type: "number"
    },
    {
      title: "$$1 vs $$2 ratio",
      dataTargetKey: "ratio",
      dataValueKey: "value",
      type: "number"
    },
  ],
  lookerFilters: [
    {
      name: "view_combined_event.publisher_analysis_parameter_details_1",
    },
    {
      name: "view_combined_event.publisher_analysis_parameter_details_2",
    },
  ],
  units: [...UNITS_CONFIG],
};

import React, { useMemo } from 'react';
import Icon from "../Icon"

const Loader = ({ size = 'small', ...props }) => {
    const pixelCount = useMemo(() => {
        switch (size) {
            case 'small':
                return '50'
            case 'medium':
                return '100'
            case 'large':
                return '150'
            default:
                return '50'
        }
    }, [size]);

    return (
        <Icon name="loader" height={pixelCount} width={pixelCount} alt="loader" {...props} />
    );
}

export default Loader;
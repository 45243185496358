export const PROGRAMMATIC_ROUTE_IDENTIFIER = "programmatic";
export const PROGRAMMATIC_ROUTE = `/${PROGRAMMATIC_ROUTE_IDENTIFIER}`;
export const CUSTOM_DASHBOARDS_ROUTE_IDENTIFIER = "custom-dashboards";
export const CUSTOM_DASHBOARDS_ROUTE = `${PROGRAMMATIC_ROUTE}/${CUSTOM_DASHBOARDS_ROUTE_IDENTIFIER}`;
export const MARKET_INSIGHTS_ROUTE_IDENTIFIER = "market-insights";
export const MARKET_INSIGHTS_ROUTE = `/${MARKET_INSIGHTS_ROUTE_IDENTIFIER}`;
export const COMPETITOR_INSIGHTS_ROUTE_IDENTIFIER = "competitor";
export const COMPETITOR_INSIGHTS_ROUTE = `/${MARKET_INSIGHTS_ROUTE_IDENTIFIER}/${COMPETITOR_INSIGHTS_ROUTE_IDENTIFIER}`;
export const CAREER_SITE_ROUTE_IDENTIFIER = "career-site";
export const CAREER_SITE_ROUTE = `/${CAREER_SITE_ROUTE_IDENTIFIER}`;
export const CRM_ROUTE_IDENTIFIER = "crm";
export const CRM_ROUTE = `/${CRM_ROUTE_IDENTIFIER}`;
export const UNIFIED_VIEW_ROUTE_IDENTIFIER = "unified-view";
export const UNIFIED_VIEW_ROUTE = `/${UNIFIED_VIEW_ROUTE_IDENTIFIER}`;
export const JOB_SITE_ROUTE_IDENTIFIER = "job-site";
export const JOB_SITE_ROUTE = `/${JOB_SITE_ROUTE_IDENTIFIER}`;
export const ROADMAP_ROUTE_IDENTIFIER = "roadmap";
export const ROADMAP_ROUTE = `/${ROADMAP_ROUTE_IDENTIFIER}`;

export const REPORTS_ROUTE_IDENTIFIER = "reports";
export const REPORTS_ROUTE = `/${PROGRAMMATIC_ROUTE_IDENTIFIER}/${REPORTS_ROUTE_IDENTIFIER}`;
export const CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER =
  "campaign-performance";
export const JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER = "job-performance";
export const JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER = "job-sponsorship";
export const EXPANSIONS_REPORT_ROUTE_IDENTIFIER = "expansions";
export const EVENT_REPORT_ROUTE_IDENTIFIER = "event";
export const TOTAL_STATS_REPORT_ROUTE_IDENTIFIER = "total-stats";
export const CAMPAIGN_PERFORMANCE_REPORT_ROUTE = `${REPORTS_ROUTE}/${CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER}`;
export const JOB_PERFORMANCE_REPORT_ROUTE = `${REPORTS_ROUTE}/${JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER}`;
export const JOB_SPONSORSHIP_REPORT_ROUTE = `${REPORTS_ROUTE}/${JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER}`;
export const EXPANSIONS_REPORT_ROUTE = `${REPORTS_ROUTE}/${EXPANSIONS_REPORT_ROUTE_IDENTIFIER}`;
export const EVENT_REPORT_ROUTE = `${REPORTS_ROUTE}/${EVENT_REPORT_ROUTE_IDENTIFIER}`;
export const TOTAL_STATS_REPORT_ROUTE = `${REPORTS_ROUTE}/${TOTAL_STATS_REPORT_ROUTE_IDENTIFIER}`;

export const DETAILED_INSIGHTS_ROUTE_IDENTIFIER = "detailed-insights";
export const DETAILED_INSIGHTS_ROUTE = `${PROGRAMMATIC_ROUTE}/${DETAILED_INSIGHTS_ROUTE_IDENTIFIER}`;

export const JOB_QUALITY_ROUTE_IDENTIFIER = "job-quality";
export const JOB_QUALITY_ROUTE = `${PROGRAMMATIC_ROUTE}/${JOB_QUALITY_ROUTE_IDENTIFIER}`;

export const JOBS_BREAKDOWN_ROUTE_IDENTIFIER = "jobs-breakdown";
export const JOBS_BREAKDOWN_ROUTE = `${PROGRAMMATIC_ROUTE}/${JOBS_BREAKDOWN_ROUTE_IDENTIFIER}`;

export const SCHEDULES_ROUTE_IDENTIFIER = "schedules";
export const SCHEDULES_ROUTE = `/${SCHEDULES_ROUTE_IDENTIFIER}`;

export const ERROR_ROUTE_IDENTIFIER = "error";
export const ERROR_ROUTE = `/${ERROR_ROUTE_IDENTIFIER}`;

export const NOT_FOUND_ROUTE_IDENTIFIER = "404";
export const NOT_FOUND_ROUTE = `/${NOT_FOUND_ROUTE_IDENTIFIER}`;

export const UPGRADE_ROUTE_IDENTIFIER = "upgrade";
export const UPGRADE_ROUTE = `/${UPGRADE_ROUTE_IDENTIFIER}`;

export const MEDIA_PLANNER_ROUTE_IDENTIFIER = "media-planner";
export const MEDIA_PLANNER_ROUTE = `/${MEDIA_PLANNER_ROUTE_IDENTIFIER}`;
export const SMP_JOB_INGESTION_ROUTE_IDENTIFIER = "smp-job-ingestion";

export const EMBED_URL_REFRETCH_ROUTES = [
  PROGRAMMATIC_ROUTE,
  MARKET_INSIGHTS_ROUTE,
  COMPETITOR_INSIGHTS_ROUTE,
  CAREER_SITE_ROUTE,
  CRM_ROUTE,
  UNIFIED_VIEW_ROUTE,
  CUSTOM_DASHBOARDS_ROUTE,
  JOB_SITE_ROUTE,
];

import styled from "styled-components";
import { devices } from "../../../../constants/AppConstants";

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 0.3rem;
  padding: 1rem;
  background-color: ${props => props.selected ? "#DFE4FF" : "inherit"};

  &:hover {
    background-color: ${props => !props.selected ? "#EDF2F7" : "#DFE4FF"};
  }

  &:last-child {
    .View__options {
      right: 100%;
      top: -130%;
    }
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`
export const Selections = styled.div`
  display: inline-block;
  color: #6A7185;
  font-size: 0.8rem;
  font-weight: 400;
  /* width: 35vw; */
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and ${devices.mobile} {
   width: 85vw;
  }
`
export const Title = styled.h2`
  color: #475569;
  font-size: 1rem;
  font-weight: 500;
`
export const OptionsContainer = styled.div`
  position: relative;
  height: 1.4rem;
  width: 1.2rem;
  border-radius: 0.3rem;
  border: 1px solid #E2E6F1;
  border-color: ${props => props.selected ? "#d0d5dd" : "#E8E8E8"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  cursor: pointer;

  ${ViewContainer}:hover & {
    border-color: #d0d5dd;
  }

  .View__options {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
  }
`
export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .View__edit_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.6rem;

    &:hover {
      background-color: #E2E6F1;
    }

    img {
      height: 0.8rem;
      width: 0.8rem;
    }
  }

  .View__text_input {
    
    ${ViewContainer}:hover & {
      border-color: #d0d5dd;
      border-radius: 0.3rem;
    }
  }
`
export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const HIRING_FUNNEL_CONFIG_HOME = {
  showTitle: true,
  title: "Hiring funnel",
  viewMore: true,
  viewMoreDbKey: "hiringFunnel",
  dataConfig: {
    titleTargetKey: "hiring_funnel.metric",
    titleValueKey: "value",
    statTargetKey: "hiring_funnel.value",
    statValueKey: "value",
    dropTargetKey: "drop_from_previous",
    dropValueKey: "rendered",
  },
};

export const HIRING_FUNNEL_FILTER_CONFIG = {
  title: "Funnel metrics",
  inputType: "checkbox",
  maxSelection: 8,
  selectionText: "Maximum selection : 8",
  combineFilterValues: true,
  getInitFiltersFromData: true,
  updateFilterOnLoad: true,
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  filtersToUpdate: [
    {
      name: "statSelection",
    },
  ],
};

export const HIRING_FUNNEL_CONFIG_VIEW_MORE = {
  showTitle: true,
  title: "Hiring funnel",
  viewMore: false,
  dataConfig: {
    titleTargetKey: "view_more_hiring_funnel.attribute",
    titleValueKey: "value",
    statTargetKey: "view_more_hiring_funnel.attribute_values",
    statValueKey: "value",
    dropTargetKey: "drop_from_previous",
    dropValueKey: "rendered",
  },
};

export const HIRING_FUNNEL_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  maxRows: 8,
  dynamicRows: true,
  dynamicColumns: false,
  currencyTargetKey: "hiring_funnel.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "Funnel metric",
      dataTargetKey: "hiring_funnel.metric",
      dataValueKey: "value",
    },
    {
      title: "Number of events",
      dataTargetKey: "hiring_funnel.value",
      dataValueKey: "value",
      type: "number"
    },
    {
      title: "Cost per event",
      dataTargetKey: "cost_per_event",
      dataValueKey: "value",
      type: "currency",
    },
    {
      title: "Drop from previous stage",
      dataTargetKey: "drop_from_previous",
      dataValueKey: "rendered",
      type: "percentage"
    },
    {
      title: "Conversion from previous stage",
      dataTargetKey: "conversion_from_prev",
      dataValueKey: "rendered",
      type: "percentage"
    },
  ],
};

import React from 'react';

import {
  ScoreProgressBarContainer,
  Bar,
  ProgressBar
} from "./styles"

const ScoreProgressBar = ({
  stage = 1,
  value = 0
}) => {
  return (
    <ScoreProgressBarContainer>
      <Bar>
        {
          stage === 1 ? <ProgressBar width={`${value}%`} /> : stage > 1 && <ProgressBar width="100%" />
        }
      </Bar>
      <Bar>
        {
          stage === 2 ? <ProgressBar width={`${value}%`} /> : stage > 2 && <ProgressBar width="100%" />
        }
      </Bar>
      <Bar>
        {
          stage === 3 ? <ProgressBar width={`${value}%`} /> : stage > 3 && <ProgressBar width="100%" />
        }
      </Bar>
    </ScoreProgressBarContainer>
  );
}

export default ScoreProgressBar;

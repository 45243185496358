import styled from "styled-components";
import { Title } from "../../../../components/NavBarFilter/FilterSelection/styles";

export const MSASelectionContainer = styled.div`
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000001A, 0px 2px 4px 0px #0000000F;
  box-shadow: 0px 2px 4px 0px #0000000F;
  border-radius: 0.5rem;

  ${Title} {
    text-transform: none;
    letter-spacing: 0;
    padding-top: 0.5rem;
    padding-left: 0.8rem;
  }
`;

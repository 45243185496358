import { LOOKER_FILTER_KEYS } from "../../constants/AppConstants"

const PAGE_TYPE = {
  AGENCY_SUMMARY: "AGENCY_SUMMARY",
  CLIENT_SUMMARY: "CLIENT_SUMMARY",
  CAMPAIGN_SUMMARY: "CAMPAIGN_SUMMARY",
  JOB_GROUP_SUMMARY: "JOB_GROUP_SUMMARY",
};

const COLUMN_AND_ORDER_FILTER_KEYS = ["fields", "columnFilters", "order"]

export const getAdvancedFiltersFromFilters = (filterId, views) => {
  if (!filterId || !views?.length) return []
  const currentView = views.find(item => item.filterId === filterId)
  if (!!currentView) {
    const filtersInView = Object.fromEntries(new URLSearchParams(currentView.value))
    const globalFilters = [...Object.values(LOOKER_FILTER_KEYS), ...COLUMN_AND_ORDER_FILTER_KEYS]
    const advancedFilters = Object.keys(filtersInView)
      .filter(item => !globalFilters.includes(item))
      .map(item => ({ [item]: filtersInView[item] }))
    return advancedFilters
  }
  return []
}

const getEntityCountPayload = (entityDataObj) => {
  const nonEmptyEntities = Object.keys(entityDataObj).filter(
    (entityKey) => !!entityDataObj[entityKey]
  );

  const entityPayload = {
    filters: nonEmptyEntities.map((entityKey) => ({
      operator: "IN",
      field: entityKey,
      values: entityDataObj[entityKey]?.split(","),
    })),
    projectionFields: [],
  };
  return entityPayload;
};

const getPageType = ({ clientId, campaignId, jobgroupId: jobGroupId }) => {
  if (!!jobGroupId) {
    return PAGE_TYPE.JOB_GROUP_SUMMARY;
  }
  if (!!campaignId) {
    return PAGE_TYPE.CAMPAIGN_SUMMARY;
  }
  if (!!clientId) {
    return PAGE_TYPE.CLIENT_SUMMARY;
  }
  return PAGE_TYPE.AGENCY_SUMMARY;
};

export const buildEntityCountSummaryPayload = (params) => {
  if (!params) return null
  const { accountId, subAccountId, clientId, campaignId, jobgroupId } = params
  const payload = getEntityCountPayload({ accountId, subAccountId, clientId, campaignId, jobGroupId: jobgroupId });
  const pageType = getPageType(params);
  return { payload, pageType }
}

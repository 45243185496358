import { UNITS_CONFIG } from "../../constants/Units";

export const SOURCE_WISE_OPTIONS = [
  {
    targetKey: "entity_performance.clicks",
    valueKey: "value",
    display: "Clicks",
  },
  {
    targetKey: "entity_performance.applies",
    valueKey: "value",
    display: "Applies",
  },
  {
    targetKey: "entity_performance.hires",
    valueKey: "value",
    display: "Hires",
  },
]

export const SOURCE_WISE_CONFIG_HOME = {
  showTitle: true,
  title: "Source-wise distribution on cumulative",
  optionSelection: true,
  viewMore: true,
  dateKey: "entity_performance.event_published_date",
  viewMoreDbKey: "sourceWise",
  options: [...SOURCE_WISE_OPTIONS],
}

export const SOURCE_WISE_VIEW_MORE_CONFIG = {
  showTitle: false,
  optionSelection: false,
  plotRatio: false,
  legends: false,
  viewMore: false,
  xAxisKey: "combined_view.dynamic_dimension",
  xAxisKeyType: "string",
  multiColor: false,
  dataColumns: [
    {
      dataTargetKey: "combined_view.dimension_1",
      dataValueKey: "value",
    }
  ],
};

export const SOURCE_WISE_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  dynamicColumns: true,
  dynamicRows: false,
  columns: [
    {
      title: "$$1",
      dataTargetKey: "combined_view.dynamic_dimension",
      dataValueKey: "value",
    },
    {
      title: "$$2",
      dataTargetKey: "combined_view.dimension_1",
      dataValueKey: "value",
      type: "number"
    },
  ],
  lookerFilters: [
    {
      name: "combined_view.compare_by",
    },
    {
      name: "combined_view.parameter_1",
    }
  ],
  units: [...UNITS_CONFIG],
}

export const SOURCE_WISE_FILTER_CONFIG = {
  title: "",
  inputType: "radio",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  groupSeparator: true,
  groupedFilters: true,
  filtersToUpdate: [
    {
      name: "source",
    },
    {
      name: "compareBy",
    },
    {
      name: "metric",
    },
  ],
  defaultFilters: [
    {
      name: "Joveo",
    },
    {
      name: "Campaign",
    },
    {
      name: "Clicks",
    }
  ],
};

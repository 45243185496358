import styled, { css } from "styled-components";
import { DEVICE_SIZES_PX } from "../../constants/AppConstants";

export const ViewMoreFullPageContainer = styled.div`
  padding: 0 2rem;
`;

export const HeaderContainer = styled.div`
  margin-left: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      margin-top: 1rem;
    `}
`;

export const HomeButtonLink = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-left: -0.3rem;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      display: none;
    `}
`;

export const HomeButtomText = styled.div`
  color: var(--indigo-800, #283593);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;

  img {
    display: none;
  }

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      img {
        display: block;
      }
    `}
`;

export const Arrow = styled.div`
  display: initial;
  position: absolute;
  top: 75%;
  right: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e2e8f0;
  transform: rotateZ(180deg);
`;

export const Link = styled.a`
  color: #303f9f;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
`;

export const InfoTitle = styled.h4`
  font-weight: 600;
  font-size: 0.9rem;
  color: #3d4759;
  margin-bottom: 0.3rem;
`;

export const BetaText = styled.p`
  color: #3d5afe;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;

  img {
    margin-top: 0.1rem;
  }
`;

export const InfoContainer = styled.div`
  display: initial;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #252f3f;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  position: absolute;
  top: 100%;
  left: -9rem;
  width: 20rem;
  padding: 1rem 1.2rem;
  z-index: 1;
  cursor: default;
  background-color: #ffffff;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      top: 100%;
      left: -12rem;
    `}
`;

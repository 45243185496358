import React from 'react';
import { Text } from './styles';

const EmptyText = ({ text = 'No data to display', className, ...restProps }) => {
  return (
    <Text className={className} {...restProps}>
      {text}
    </Text>
  );
}

export default EmptyText;

import {
  EXCHANGE_VISIBILITY,
  LOOKER_FILTER_KEYS,
  PROGRAMMATIC_IDENTIFIERS,
  STATUS_OPTIONS,
  CAREER_SITE_IDENTIFIERS,
  CRM_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS,
  JOB_SITE_IDENTIFIERS
} from "../../constants/AppConstants";

const ACCOUNTS = [{ id: "", value: "All Accounts" }]

const SUB_ACCOUNTS = [{ id: "", value: "All Subaccounts" }];

const SITES = [{ id: "", value: "All Sites" }];

const AGENCIES = [{ id: "", value: "All Instances" }];

const CLIENTS = [{ id: "", value: "All Clients" }];

const CAMPAIGNS = [{ id: "", value: "All Campaigns" }];

const JOB_GROUPS = [{ id: "", value: "All Job Groups" }];

export const DROPDOWN_ENTITY = {
  ACCOUNTS: "Accounts",
  SUB_ACCOUNTS: "Subaccounts",
  SITES: "Sites",
  CLIENT: "Clients",
  CAMPAIGN: "Campaigns",
  JOB_GROUP: "Job Groups",
};

export const getAccounts = (hierarchyData = []) => {
  return ACCOUNTS.concat(hierarchyData.map(item => ({ ...item, value: item.name, id: item.id })));
}

export const getSubAccounts = (accountId, accounts) => {
  if (!accountId || !accounts?.length) {
    return SUB_ACCOUNTS;
  }

  const account = accounts.find(acc => acc.id === accountId)
  if (!!account?.subAccounts?.length) {
    return SUB_ACCOUNTS.concat(account.subAccounts.map(item => ({ ...item, value: item.name, id: item.id })))
  }

  return SUB_ACCOUNTS
};

export const getSites = (subAccountId, subAccounts) => {
  if (!subAccountId || !subAccounts?.length) {
    const allSubAccounts = subAccounts.filter(item => !!item.id)
    const allSites = allSubAccounts.flatMap(item => item?.sites).filter(Boolean)
    return [
      ...SITES,
      ...allSites?.map(item => ({ ...item, value: item.name, id: item.id }))
    ]
  }

  const subAccount = subAccounts.find(acc => acc.id === subAccountId)
  if (!!subAccount?.sites?.length) {
    return SITES.concat(subAccount.sites.map(item => ({ ...item, value: item.name, id: item.id })))
  }

  return SITES
}

export const getAgencyOptions = (hierarchyData, exchangeVisibility) => {
  if (!hierarchyData || hierarchyData.length === 0) {
    return AGENCIES;
  }

  const activeAgencyOptions = hierarchyData.filter(
    (hierarchyDataObj) =>
      hierarchyDataObj.status === STATUS_OPTIONS.ACTIVE ||
      hierarchyDataObj.status === STATUS_OPTIONS.PAUSED
  );

  if (exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) {
    return activeAgencyOptions;
  }

  return AGENCIES.concat(activeAgencyOptions);
};

export const getClientOptions = (subAccountId, subAccountOptions) => {
  if (!subAccountId) {
    const allSubAccounts = subAccountOptions.filter(item => !!item.id)
    const allClients = allSubAccounts.flatMap(item => item.clients).filter(Boolean)
    return [
      ...CLIENTS,
      ...allClients
        .filter(client => client.status === STATUS_OPTIONS.ACTIVE || client.status === STATUS_OPTIONS.PAUSED)
        .map(item => ({ ...item, value: item.name, id: item.id }))
    ]
  }

  const subAccount = subAccountOptions.find(acc => acc.id === subAccountId)

  if (!!subAccount?.clients?.length) {
    return [
      ...CLIENTS,
      ...subAccount.clients
        .filter(client => client.status === STATUS_OPTIONS.ACTIVE || client.status === STATUS_OPTIONS.PAUSED)
        .map(item => ({ ...item, value: item.name, id: item.id }))
    ]
  }
  return CLIENTS
};

export const getCampaignOptions = (clientFilter, clientOptions) => {
  if (!clientFilter) {
    return CAMPAIGNS;
  }

  const campaignOptions = clientFilter.split(",").reduce((acc, curr) => {
    const currentClient = clientOptions.find((agency) => agency.id === curr);
    if (currentClient?.campaigns?.length > 0) {
      acc = acc.concat(currentClient?.campaigns);
    }
    return acc;
  }, []);

  if (!campaignOptions || campaignOptions.length === 0) {
    return CAMPAIGNS;
  }

  const activeCampaignOptions = campaignOptions.filter(
    (campaignDataObj) =>
      campaignDataObj.status === STATUS_OPTIONS.ACTIVE ||
      campaignDataObj.status === STATUS_OPTIONS.PAUSED
  );

  const mappedCampaignOptions = activeCampaignOptions.map(
    (campaignDataObj) => ({
      ...campaignDataObj,
      value: campaignDataObj.name,
    })
  );

  return CAMPAIGNS.concat(mappedCampaignOptions);
};

export const getJobGroupOptions = (campaignFilter, campaignOptions) => {
  if (!campaignFilter) {
    return JOB_GROUPS;
  }

  const jobGroupOptions = campaignFilter.split(",").reduce((acc, curr) => {
    const currentJobGroup = campaignOptions.find(
      (agency) => agency.id === curr
    );
    if (currentJobGroup?.jobGroups?.length > 0) {
      acc = acc.concat(currentJobGroup?.jobGroups);
    }
    return acc;
  }, []);

  if (!jobGroupOptions || jobGroupOptions.length === 0) {
    return JOB_GROUPS;
  }

  const activeJobGroupOptions = jobGroupOptions.filter(
    (jobGroupDataObj) =>
      jobGroupDataObj.status === STATUS_OPTIONS.ACTIVE ||
      jobGroupDataObj.status === STATUS_OPTIONS.PAUSED
  );

  const mappedJobGroupOptions = activeJobGroupOptions.map(
    (jobGroupDataObj) => ({
      ...jobGroupDataObj,
      value: jobGroupDataObj.name,
    })
  );

  return JOB_GROUPS.concat(mappedJobGroupOptions);
};

export const getAccountSubAccountOptions = (hierarchy) => {
  if (!hierarchy?.length) {
    return [];
  }
  return hierarchy.map(account => ({
    id: account.id,
    title: account.name,
    options: [
      { id: SUB_ACCOUNTS[0].id, display: SUB_ACCOUNTS[0].value, accountId: account.id },
      ...account.subAccounts.map(subAccount => ({ id: subAccount.id, display: subAccount.name, accountId: account.id })),
    ]
  }))
}

export const getCurrentLevel = (navFilters, product) => {
  if (!product) return ""

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      if (!!navFilters[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) return DROPDOWN_ENTITY.JOB_GROUP
      if (!!navFilters[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) return DROPDOWN_ENTITY.CAMPAIGN
      return DROPDOWN_ENTITY.CLIENT
    case CAREER_SITE_IDENTIFIERS.productKey:
      return DROPDOWN_ENTITY.SITES
    case CRM_IDENTIFIERS.productKey:
      if (!!navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return DROPDOWN_ENTITY.SUB_ACCOUNTS
      return DROPDOWN_ENTITY.ACCOUNTS
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      if (!!navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return DROPDOWN_ENTITY.SUB_ACCOUNTS
      return DROPDOWN_ENTITY.ACCOUNTS
    case JOB_SITE_IDENTIFIERS.productKey:
      return DROPDOWN_ENTITY.SITES
    default:
      return ""
  }
}

export const getInitialNavFilters = (globalFilters, navFilters, product) => {
  if (!product || !globalFilters || !navFilters) return null

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return {
        ...navFilters,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.CLIENT_ID]: globalFilters[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: globalFilters[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: globalFilters[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
      }
    case CAREER_SITE_IDENTIFIERS.productKey:
      return {
        ...navFilters,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SITE_ID]: globalFilters[LOOKER_FILTER_KEYS.SITE_ID] || "",
      }
    case CRM_IDENTIFIERS.productKey:
      return {
        ...navFilters,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
      }
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return {
        ...navFilters,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SITE_ID]: globalFilters[LOOKER_FILTER_KEYS.SITE_ID] || "",
        [LOOKER_FILTER_KEYS.CLIENT_ID]: globalFilters[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
      }
    case JOB_SITE_IDENTIFIERS.productKey:
      return {
        ...navFilters,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SITE_ID]: globalFilters[LOOKER_FILTER_KEYS.SITE_ID] || "",
      };
    default:
      return ""
  }
}

export const getCurrentEntity = (globalFilters, product) => {
  if (!product || !globalFilters) return null

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      if (!globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]) return LOOKER_FILTER_KEYS.ACCOUNT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.CLIENT_ID]) return LOOKER_FILTER_KEYS.CLIENT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) return LOOKER_FILTER_KEYS.CAMPAIGN_ID
      return LOOKER_FILTER_KEYS.JOB_GROUP_ID
    case CAREER_SITE_IDENTIFIERS.productKey:
      if (!globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]) return LOOKER_FILTER_KEYS.ACCOUNT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID
      return LOOKER_FILTER_KEYS.SITE_ID
    case CRM_IDENTIFIERS.productKey:
      if (!globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]) return LOOKER_FILTER_KEYS.ACCOUNT_ID
      return LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      if (!globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]) return LOOKER_FILTER_KEYS.ACCOUNT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID
      return LOOKER_FILTER_KEYS.SITE_ID
    case JOB_SITE_IDENTIFIERS.productKey:
      if (!globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]) return LOOKER_FILTER_KEYS.ACCOUNT_ID
      if (!globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) return LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID
      return LOOKER_FILTER_KEYS.SITE_ID
    default:
      return ""
  }
}

export const isFiltersValid = (hierarchy, filters) => {
  const {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
  } = filters;

  const accountOptions = getAccounts(hierarchy)
  const subAccountOptions = getSubAccounts(accountId, accountOptions)

  if (accountId) {
    const isAccountsValid = accountOptions.length > 1 || accountOptions?.[0]?.id !== ""
    if (!isAccountsValid) return false
    const account = accountOptions.find(item => item.id === accountId)
    if (!account) return false
  }
  if (subAccountId) {
    const isSubAccountsValid = subAccountOptions.length > 1 || subAccountOptions?.[0]?.id !== ""
    if (!isSubAccountsValid) return false
    const subAccount = subAccountOptions.find(item => item.id === subAccountId)
    if (!subAccount) return false
  }
  return true
}

export const isProductAccessible = ({
  products,
  hierarchy,
  globalFilters,
  currentProduct
}) => {
  const isLocked = !products?.length ||
    !hierarchy ||
    !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
    !products.find(item => item?.productKey === currentProduct?.productKey) ||
    !hierarchy?.[currentProduct?.productKey]?.find(item => item?.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]);
  return !isLocked
}

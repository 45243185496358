import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const RoadmapNavBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;

export const RoadmapNavBarHeader = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
`;

export const RoadmapIframeBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
import { create } from "zustand";

import {
  DATE_OPTIONS,
  EXCHANGE_VISIBILITY,
  LOOKER_FILTER_INITIAL_VALUES,
  LOOKER_FILTER_KEYS,
} from "../constants/AppConstants";

export const useGlobalDataStore = create((set) => ({
  products: [],
  dashboards: null,
  models: null,
  additionalDashboards: [],
  hierarchy: null,
  views: [],
  timezone: "",
  username: "",
  publisherFilter: false,
  primaryCurrency: "usd",
  exchangeVisibility: EXCHANGE_VISIBILITY.NA,
  showMarketInsights: false,
  showCompetitorInsights: false,
  accountCount: 0,
  weekStartForAccounts: null,
  weekStart: 0,
  isDemoDashboard: false,
  detailedInsightsFigmaLinks: null,
  isSMPDataLoading: false,
  selectedSMPCustomerId: null,

  setProducts: (products) => set({ products }),
  setModels: (models) => set({ models }),
  setDashboards: (dashboards) => set({ dashboards }),
  setAdditionalDashboards: (additionalDashboards) =>
    set({ additionalDashboards }),
  setHierarchy: (hierarchy) => set({ hierarchy }),
  setViews: (views) => set({ views }),
  setTimezone: (timezone) => set({ timezone }),
  setUsername: (username) => set({ username }),
  setPublisherFilter: (publisherFilter) => set({ publisherFilter }),
  setPrimaryCurrency: (primaryCurrency) => set({ primaryCurrency }),
  setExchangeVisibility: (exchangeVisibility) => set({ exchangeVisibility }),
  setShowMarketInsights: (showMarketInsights) => set({ showMarketInsights }),
  setShowCompetitorInsights: (showCompetitorInsights) =>
    set({ showCompetitorInsights }),
  setAccountCount: (accountCount) => set({ accountCount }),
  setWeekStartForAccounts: (weekStartForAccounts) =>
    set({ weekStartForAccounts }),
  setWeekStart: (weekStart) => set({ weekStart }),
  setIsDemoDashboard: (isDemoDashboard) => set({ isDemoDashboard }),
  setDetailedInsightsFigmaLinks: (detailedInsightsFigmaLinks) =>
    set({ detailedInsightsFigmaLinks }),
  setIsSMPDataLoading: (isSMPDataLoading) => set({ isSMPDataLoading }),
  setSelectedSMPCustomerId: (selectedSMPCustomerId) =>
    set({ selectedSMPCustomerId }),
}));

export const useGlobalFiltersStore = create((set) => ({
  embedUrl: "",
  globalFilters: {
    ...LOOKER_FILTER_INITIAL_VALUES,
  },
  currentProduct: null,
  viewMode: "",

  setGlobalFilters: (searchParams) => {
    const filters = Object.fromEntries([...searchParams]);
    for (const filter in filters) {
      filters[filter] = decodeURIComponent(filters[filter]);
    }
    filters["theme"] =
      filters[LOOKER_FILTER_KEYS.CUSTOM_DASHBOARD] === "true"
        ? "custom_dashboard_theme"
        : "";
    if (!filters[LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND]) {
      const date =
        filters[LOOKER_FILTER_KEYS.DATE] ||
        LOOKER_FILTER_INITIAL_VALUES[LOOKER_FILTER_KEYS.DATE];
      const dateOption = DATE_OPTIONS.find((item) => item.key === "this month");
      if (date !== dateOption.getDateFilterString()) {
        filters[LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND] = date;
      } else {
        filters[LOOKER_FILTER_KEYS.DATE_PROJECTED_SPEND] =
          dateOption.getDateFilterStringTillEnd();
      }
    }
    set((state) => ({
      globalFilters: { ...LOOKER_FILTER_INITIAL_VALUES, ...filters },
    }));
  },
  setCurrentProduct: (currentProduct) => set({ currentProduct }),
  setEmbedUrl: (embedUrl) => set({ embedUrl }),
  setViewMode: (viewMode) => set({ viewMode }),
}));

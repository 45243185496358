import React, { useEffect, useMemo, useState } from "react";
import {
  Routes,
  useSearchParams,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { format, parse } from "date-fns";

import {
  MarketInsightsContainer,
  Title,
  FiltersContainer,
  EmptyContainer,
  HeaderContainer,
  TabsContainer,
  ContentContainer,
  Filters,
  Label,
  ContentWrapper,
  LockedContainer
} from "./styles";

import EmbeddedDashboard from "../../components/EmbeddedDashboard";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import PremiumFeature from "../../components/common/PremiumFeature";
import Loader from "../../components/common/Loader";
import Tab from "../../components/common/Tab";
import Locked from "./Locked";
import CountryFilter from "./CountryFilter";

import {
  CATEGORY_ID,
  CATEGORY_LABEL,
  LOCATION_ID,
  LOCATION_LABEL,
  SEARCH_PARAMS_KEYS,
  TABS,
  PAGE_TITLE,
  MULTISELECT_DROPDOWN_MENU_STYLE,
  MULTISELECT_OPTIONS_CONTAINER_STYLE,
  MULTISELECT_DROPDOWN_BUTTON_STYLE,
  LOCATION_ID_COMPETITOR,
  LOCATION_LABEL_COMPETITOR,
  CATEGORY_ID_COMPETITOR,
  CATEGORY_ID_IN_LOCATION_MAPPING,
  DEFAULT_CATEGORY_ID,
  DEFAULT_CATEGORY_LABEL,
  DEFAULT_LOCATION_ID,
  DEFAULT_LOCATION_LABEL,
  CATEGORY_ID_LOCATION,
  LOCATION_COUNTRY,
  LOCATION_COUNTRY_LABEL,
  LOCATION_COUNTRY_CODE,
  LOCATION_COUNTRY_CODE_COMPETITOR,
  LOCATION_COUNTRY_LABEL_COMPETITOR,
} from "./constants";
import { getAgencyId, dropdownFormatter, UPDATED_AT_KEY } from "./helpers";

import {
  DEVICE_SIZES_PX,
  LOOKER_FILTER_KEYS,
} from "../../constants/AppConstants";
import Mixpanel from "../../services/MixPanel";
import {
  useFetchCategories,
  useFetchCompetitors,
  useFetchCompetitorsLocations,
  useFetchLastUpdatedAt,
  useFetchMarketLocations
} from "../../api/marketingInsights";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { buildCategoriesPayload, buildCompetitorsLocationsPayload, buildCompetitorsPayload, buildLastUpdatedAtPayload, buildMILocationsPayload } from "./apiHelpers";
import { COMPETITOR_INSIGHTS_ROUTE, COMPETITOR_INSIGHTS_ROUTE_IDENTIFIER } from "../../constants/AppRoutes";

const MarketInsights = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const locationGlobal = useLocation();
  const navigate = useNavigate();


  const {
    models,
    dashboards,
    hierarchy,
    showCompetitorInsights
  } = useGlobalDataStore(
    useShallow((state) => ({
      models: state.models,
      dashboards: state.dashboards,
      hierarchy: state.hierarchy,
      showCompetitorInsights: state.showCompetitorInsights
    }))
  );

  const {
    globalFilters,
    embedUrl,
    setEmbedUrl,
    setCurrentProduct
  } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      embedUrl: state.embedUrl,
      setEmbedUrl: state.setEmbedUrl,
      setCurrentProduct: state.setCurrentProduct
    }))
  );

  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [selectedTab, setSelectedTab] = useState("");

  const agencyId = getAgencyId({ hierarchy, filters: globalFilters })

  const { data: { data: categoryList = [] } = [], isLoading: isCategoriesLoading } =
    useFetchCategories(
      buildCategoriesPayload({ ...globalFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }, models),
      selectedTab === TABS[0].label ? true : !!agencyId
    );

  const { data: { data: miLocationList = [] } = [], isLoading: isMiLocationsLoading } =
    useFetchMarketLocations(
      buildMILocationsPayload(category, { ...globalFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }, models),
      selectedTab === TABS[0].label
    );

  const { data: { data: competitors = [] } = [], isLoading: isCompetitorsLoading } =
    useFetchCompetitors(
      buildCompetitorsPayload({ ...globalFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }, models),
      selectedTab === TABS[1].label
    );

  const { data: { data: competitorsLocations = [] } = [], isLoading: isCompetitorsLocationsLoading } =
    useFetchCompetitorsLocations(
      buildCompetitorsLocationsPayload({ ...globalFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }, models),
      selectedTab === TABS[1].label
    );

  const { data: { data: lastUpdatedAt = [] } = [] } =
    useFetchLastUpdatedAt(
      buildLastUpdatedAtPayload({ ...globalFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }, models),
    );

  const locationsList = useMemo(() => {
    if (selectedTab === TABS[0].label) {
      return miLocationList
    }
    return competitorsLocations
  }, [miLocationList, competitorsLocations, selectedTab])

  const combinedFilters = useMemo(() => {
    return {
      ...globalFilters,
      ...(category && { category }),
      ...(location && { location }),
      ...(!!competitors?.length && selectedTab === TABS[1].label && { location_wise_competitors: JSON.stringify(competitors) }),
    };
  }, [globalFilters, category, location, competitors, selectedTab]);

  const locations = useMemo(() => {
    if (selectedTab === TABS[0].label) {
      if (!locationsList?.length) return [{ id: DEFAULT_LOCATION_ID, value: DEFAULT_LOCATION_LABEL }]
      return locationsList
        .map((item) => ({
          id: `${item[LOCATION_ID]}`,
          value: `${item[LOCATION_LABEL]}`,
          [SEARCH_PARAMS_KEYS.CATEGORY_ID]: `${item[CATEGORY_ID_LOCATION]}`,
          countryCode: item[LOCATION_COUNTRY_CODE],
          countryLabel: item[LOCATION_COUNTRY_LABEL],
        }))
        .filter((item) => !!item.id && !!item.value && item.id !== "null" && item.value !== "null")
    }
    if (selectedTab === TABS[1].label) {
      if (!competitorsLocations?.length) return []
      const categoryId = globalFilters?.[SEARCH_PARAMS_KEYS.CATEGORY_ID]
      const locationList = competitorsLocations.filter(item => item[CATEGORY_ID_COMPETITOR] === categoryId)
      return locationList
        .map((item) => ({
          id: `${item[LOCATION_ID_COMPETITOR]}`,
          value: `${item[LOCATION_LABEL_COMPETITOR]}`,
          [SEARCH_PARAMS_KEYS.CATEGORY_ID]: `${item[CATEGORY_ID_COMPETITOR]}`,
          countryCode: item[LOCATION_COUNTRY_CODE_COMPETITOR],
          countryLabel: item[LOCATION_COUNTRY_LABEL_COMPETITOR],
        }))
        .filter((item) => !!item.id && !!item.value && item.id !== "null" && item.value !== "null")
    }
  }, [locationsList, competitorsLocations, selectedTab, globalFilters])

  const categories = useMemo(() => {
    if (selectedTab === TABS[0].label) {
      if (!categoryList?.length) return [{ id: DEFAULT_CATEGORY_ID, value: DEFAULT_CATEGORY_LABEL }]
      return categoryList
        .map((item) => ({
          id: `${item[CATEGORY_ID]}`,
          value: `${item[CATEGORY_LABEL]}`,
        }))
        .filter((item) => !!item.id && !!item.value && item.id !== "null" && item.value !== "null")
    }
    if (selectedTab === TABS[1].label) {
      if (!categoryList?.length) return []
      const categoryIdKey = CATEGORY_ID_COMPETITOR

      const filteredCategories = categoryList
        .map((item) => ({
          id: `${item[CATEGORY_ID]}`,
          value: `${item[CATEGORY_LABEL]}`,
        }))
        .filter((item) => !!item.id && !!item.value && item.id !== "null" && item.value !== "null")

      const categoriesWithLocation = filteredCategories.filter(item => {
        const loc = locationsList?.find(loc => loc[categoryIdKey] === item.id)
        return !!loc
      })

      return categoriesWithLocation
    }
  }, [categoryList, selectedTab, locationsList, competitorsLocations])

  const handleTabChange = (opt) => {
    setSelectedTab(opt?.title)
    const params = Object.fromEntries([...searchParams]);
    params[LOOKER_FILTER_KEYS.DASHBOARD_ID] = dashboards[opt?.dashboardMapIdentifierKey].id
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    setCurrentProduct(opt)
    navigate({
      pathname: opt?.route,
      search: urlSearchParams,
    });
  }

  useEffect(() => {
    if (categories?.length) {
      const params = Object.fromEntries([...searchParams]);
      const categoryId = params[SEARCH_PARAMS_KEYS.CATEGORY_ID];
      const categoryObj = categories.find(
        (obj) => obj.id === categoryId
      );

      if (!!categoryObj && !!categoryId) {
        setCategory(categoryId);
      } else {
        setCategory(categories?.[0]?.id || "");
        setSearchParams({
          ...params,
          [SEARCH_PARAMS_KEYS.CATEGORY_ID]: categories?.[0]?.id || "",
        });
      }
    }
  }, [categories, searchParams, setSearchParams]);

  useEffect(() => {
    const hasLocationsLoaded = !!locations?.length && (locations.length !== 1 && locations[0].id !== DEFAULT_LOCATION_ID)
    if (hasLocationsLoaded && !!selectedTab) {
      const params = Object.fromEntries([...searchParams]);
      const categoryId = params[SEARCH_PARAMS_KEYS.CATEGORY_ID];
      if (!!categoryId) {
        const locationId = params[SEARCH_PARAMS_KEYS.LOCATION_ID];
        if (!!locationId && !!locations.find((loc) => loc.id === locationId)) {
          setLocation(locationId);
        } else {
          const locationId = selectedTab === TABS[0].label ? DEFAULT_LOCATION_ID : locations?.[0]?.id || ""
          setLocation(locationId);
          setSearchParams({
            ...params,
            [SEARCH_PARAMS_KEYS.LOCATION_ID]: locationId,
          });
        }
      }
    }
  }, [locations, searchParams, setSearchParams, selectedTab]);

  useEffect(() => {
    if (locationGlobal?.pathname === COMPETITOR_INSIGHTS_ROUTE) {
      setSelectedTab(TABS[1].label)
    } else {
      setSelectedTab(TABS[0].label)
    }
  }, [locationGlobal])

  useEffect(() => {
    if (!searchParams.get(LOOKER_FILTER_KEYS.CLIENT_ID)) {
      setEmbedUrl("")
    }
  }, [searchParams])

  useEffect(() => {
    Mixpanel.trackEvent("Market Insights Opened", {});
  }, []);

  const handleUpdateCategory = (updatedValue) => {
    const params = Object.fromEntries([...searchParams]);
    setSearchParams({
      ...params,
      [SEARCH_PARAMS_KEYS.CATEGORY_ID]: updatedValue,
    });
    const categoryObj = categories.find(
      (item) => item.id === updatedValue
    );
    Mixpanel.trackEvent("Job Category Selected", {
      "Screen name": "Market Insights",
      "Job Category Selected": categoryObj.value,
    });
  };

  const handleUpdateLocation = (updatedValue) => {
    const params = Object.fromEntries([...searchParams]);
    setSearchParams({
      ...params,
      [SEARCH_PARAMS_KEYS.LOCATION_ID]: updatedValue,
    });
    const locationObj = locations.find(
      (item) => item.id === updatedValue
    );
    Mixpanel.trackEvent("Location Selected", {
      "Screen name": "Market Insights",
      "Location Selected": locationObj.value,
    });
  };

  const canShowCompetitorInsights = (() => {
    if (selectedTab === TABS[1].label && !!showCompetitorInsights) {
      const isClientSelected = !!globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID]
      const isCategorySelected = !!categories?.length && !!category
      const isLocationSelected = !!locations?.length && !!location
      return isClientSelected && isCategorySelected && isLocationSelected
    }
    return false
  })()
  const lockTexts = (() => {
    if (selectedTab === TABS[1].label) {
      if (!showCompetitorInsights) return TABS[1].unlockTexts.client
      if (!globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID]) return TABS[1].lockedTexts.client
      if (!category || !categories?.length) return TABS[1].unlockTexts.category
    }
  })()

  const isLoading = isCompetitorsLoading || isCompetitorsLocationsLoading || isCategoriesLoading || isMiLocationsLoading || !embedUrl || !hierarchy

  const updatedAtDate = lastUpdatedAt?.[0]?.[UPDATED_AT_KEY] ? format(parse(lastUpdatedAt?.[0]?.[UPDATED_AT_KEY], "yyyy-MM-dd", new Date()), "MMM dd, yyyy") : ""

  return (
    <MarketInsightsContainer>
      <HeaderContainer>
        <Title>{PAGE_TITLE}</Title>
        <PremiumFeature />
      </HeaderContainer>
      <FiltersContainer>
        <Filters>
          <MultiSelectDropdown
            options={categories}
            selected={category}
            onChange={handleUpdateCategory}
            title="Category"
            displayFormatterHtml={
              (selectedOption) => dropdownFormatter("Category", selectedOption?.value)
            }
            dropdownButtonStyle={
              window.innerWidth > DEVICE_SIZES_PX.MOBILE
                ? MULTISELECT_DROPDOWN_BUTTON_STYLE
                : {
                  ...MULTISELECT_DROPDOWN_BUTTON_STYLE,
                  maxWidth: "100%",
                  width: "100%",
                }
            }
            dropdownMenuStyle={
              window.innerWidth > DEVICE_SIZES_PX.MOBILE
                ? MULTISELECT_DROPDOWN_MENU_STYLE
                : { ...MULTISELECT_DROPDOWN_MENU_STYLE, width: "100%" }
            }
            customOptionsContainerStyle={MULTISELECT_OPTIONS_CONTAINER_STYLE}
            style={
              window.innerWidth > DEVICE_SIZES_PX.MOBILE ? {} : { width: "100%" }
            }
          />
          {
            selectedTab === TABS[0].label ? (
              <CountryFilter
                locations={locations}
                selected={location}
                onChange={handleUpdateLocation}
              />
            ) : (
              <MultiSelectDropdown
                options={locations}
                selected={location}
                onChange={handleUpdateLocation}
                title="Location"
                displayFormatterHtml={
                  (selectedOption) => dropdownFormatter("Location", selectedOption?.value)
                }
                dropdownButtonStyle={
                  window.innerWidth > DEVICE_SIZES_PX.MOBILE
                    ? MULTISELECT_DROPDOWN_BUTTON_STYLE
                    : {
                      ...MULTISELECT_DROPDOWN_BUTTON_STYLE,
                      maxWidth: "100%",
                      width: "100%",
                    }
                }
                dropdownMenuStyle={
                  window.innerWidth > DEVICE_SIZES_PX.MOBILE
                    ? MULTISELECT_DROPDOWN_MENU_STYLE
                    : { ...MULTISELECT_DROPDOWN_MENU_STYLE, width: "100%" }
                }
                customOptionsContainerStyle={MULTISELECT_OPTIONS_CONTAINER_STYLE}
                style={
                  window.innerWidth > DEVICE_SIZES_PX.MOBILE ? {} : { width: "100%" }
                }
              />
            )
          }
        </Filters>
        {
          (!!updatedAtDate && selectedTab === TABS[1].label) && (
            <Label>
              Last updated on {updatedAtDate || ""}
            </Label>
          )
        }
      </FiltersContainer>
      <TabsContainer>
        <Tab
          options={TABS}
          selected={selectedTab}
          displayKey="label"
          onTabChange={handleTabChange}
        />
      </TabsContainer>
      <ContentContainer>
        <Routes>
          <Route path={`/${COMPETITOR_INSIGHTS_ROUTE_IDENTIFIER}`} element={
            <ContentWrapper>
              {
                (!!embedUrl && !!hierarchy) && (
                  <>
                    <EmbeddedDashboard
                      embedURL={embedUrl}
                      lookerFilter={globalFilters}
                      localWidgetFilters={{ ...combinedFilters, [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId }}
                    />
                    {
                      (!canShowCompetitorInsights && !isLoading) && (
                        <LockedContainer>
                          <Locked {...lockTexts} />
                        </LockedContainer>
                      )
                    }
                  </>
                )
              }
            </ContentWrapper>
          } />
          <Route path={"/"} element={
            <>
              {
                !!embedUrl && (
                  <EmbeddedDashboard
                    embedURL={embedUrl}
                    lookerFilter={globalFilters}
                    localWidgetFilters={combinedFilters}
                  />
                )
              }
            </>
          } />
        </Routes>
        {
          (!!embedUrl && !isLoading && !!hierarchy) && (
            <Outlet />
          )
        }
      </ContentContainer>
      {
        isLoading && (
          <EmptyContainer>
            <Loader size="medium" />
          </EmptyContainer>
        )
      }
    </MarketInsightsContainer>
  );
};

export default MarketInsights;

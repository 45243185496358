import { useEffect, useState } from "react";
import Icon from "../../common/Icon";
import { RoadmapSidebarContainer, RoadmapSidebarOption } from "./styles";

const RoadmapSidebar = ({selectedOption, handleOptionChange, RoadmapSidebarOptions}) => {

  return (
    <RoadmapSidebarContainer>
      {RoadmapSidebarOptions.map((option, index) => (
        <RoadmapSidebarOption
          key={index}
          isSelected={selectedOption === option.value}
          onClick={() => handleOptionChange(option?.value)}
        >
          <Icon
            name={
              selectedOption === option.value ? option?.iconSelected : option?.icon
            }
            alt={option.label}
            height={20}
            width={20}
          />
          {option.label}
        </RoadmapSidebarOption>
      ))}
    </RoadmapSidebarContainer>
  );
};

export default RoadmapSidebar;

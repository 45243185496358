import React from 'react';

import { UNIFIED_ANALYTICS_CLOUDFRONT_URL } from "../../../constants/ApiRoutes"

const Icon = ({ name, type = "svg", ...props }) => {
    if (!name) return null

    return (
        <img src={`${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/icons/${name}.${type}`} {...props} alt={name} />
    );
}

export default Icon;
import {
  EXCHANGE_VISIBILITY_VALUES,
  LOOKER_FILTER_KEYS,
  PROGRAMMATIC_IDENTIFIERS,
} from "../../constants/AppConstants";
import {
  CAMPAIGN_REPORT_TITLE,
  REPORTS_CONFIG_MAP,
  TOTAL_STATS_REPORT_TITLE,
} from "../../constants/ReportsConstants"
import { getLookFilters } from "../../components/ViewMore/helpers"
import { getModel } from "../../utils/helpers";

const UTM_FIELDS = ["filter_data.attributes", "filter_data.attribute_group", "filter_data.sortBy", "filter_data.attribute_label"];

export const TOTAL_STATS_LOOKER_KEYS_CONVERSION = {
  "sub_accounts.account_id": "account_id",
  "sub_accounts.sub_account_id": "sub_account_id",
  "total_stats_report.agency_id": "agency_id",
  "total_stats_report.client_id": "client_id",
  "total_stats_report.campaign_id": "campaign_id",
  "total_stats_report.job_group_id": "job_group_id",
  "total_stats_report.event_published_date": "event_publisher_date"
}

const parseAdvancedFilters = (filters, advancedFilters) => {

  const payload = {}
  if (!advancedFilters) return payload
  advancedFilters.forEach(item => {
    if (item?.filterKey) {
      if (!!filters?.[item.filterKey]) {
        payload[item.filterKey] = filters[item.filterKey]
      }
    } else {
      if (!!filters?.[item.field]) {
        payload[item.field] = filters[item.field]
      }
    }
  })
  return payload
}

export const parseColumnsForFields = (columns, additionalFields) => {
  if (!columns?.order?.length) return []
  const dimensions = columns.order[0]?.options?.map(item => item.key)
  const metrics = columns.order[1]?.options?.map(item => item.key)
  const combined = [...dimensions, ...metrics, ...additionalFields]
  return combined
}

export const parseColumnsForFilters = (columns) => {
  const dimensions = [...columns.dimensions].flatMap(item => item.options.map(opt => ({ key: opt.name, filterKey: opt.columnFilterKey }))).filter(item => !!item.filterKey)
  const metrics = [...columns.metrics].flatMap(item => item.options).filter(item => !!item.filterKey).reduce((acc, item) => ({ ...acc, [item.filterKey]: item.name }), {})

  const filters = {
    ...metrics,
    ...dimensions.reduce((acc, item) => ({ ...acc, [item.filterKey]: item.key }), {})
  }
  return filters
}

export const buildPayload = (filters, columns, advancedFilters, type = CAMPAIGN_REPORT_TITLE, models) => {
  if (!Object.entries(filters || {})?.length) return null
  const config = REPORTS_CONFIG_MAP[type]
  const isRightView = Object.keys(filters).some(item => item.split(".")[0].startsWith(config.VIEW))
  if (!isRightView) return null
  if (!columns?.dimensions?.length || !columns?.metrics?.length) return null
  const model = getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey)
  if (!model) return null

  const advancedFiltersSelected = parseAdvancedFilters(filters, advancedFilters)
  const fields = parseColumnsForFields(columns, config.ADDITIONAL_FIELDS_IN_API)
  const customFilters = parseColumnsForFilters(columns)

  const filterMap = config.GLOBAL_FILTERS

  const payload = {
    model,
    "view": config.VIEW,
    "pivots": null,
    "filters": {
      [filterMap.ACCOUNT_ID]: filters?.[filterMap.ACCOUNT_ID] || "",
      [filterMap.SUB_ACCOUNT_ID]: filters?.[filterMap.SUB_ACCOUNT_ID] || "",
      [filterMap.AGENCY_ID]: filters?.[filterMap.AGENCY_ID] || "",
      [filterMap.CLIENT_ID]: filters?.[filterMap.CLIENT_ID] || "",
      [filterMap.CAMPAIGN_ID]: filters?.[filterMap.CAMPAIGN_ID] || "",
      [filterMap.JOB_GROUP_ID]: filters?.[filterMap.JOB_GROUP_ID] || "",
      ...(!!filterMap?.CURRENCY_RATE && { [filterMap.CURRENCY_RATE]: filters?.[filterMap.CURRENCY_RATE] || "" }),
      [filterMap.DATE]: filters?.[filterMap.DATE] || "this month",
      ...(!!filterMap?.EXCHANGE_VISIBILITY && { [filterMap.EXCHANGE_VISIBILITY]: filters?.[filterMap.EXCHANGE_VISIBILITY] || EXCHANGE_VISIBILITY_VALUES.CLOSE }),
      ...advancedFiltersSelected,
      ...customFilters,
    },
    "fill_fields": null,
    "limit": "-1",
    "sorts": config.SORTS,
    "column_limit": "100",
    "total": false,
    "row_total": null,
    "subtotals": null,
    "fields": fields,
    "cache": true
  }
  return payload
}

export const buildMetricPayload = (
  filters,
  type = CAMPAIGN_REPORT_TITLE,
  models
) => {
  if (!Object.entries(filters || {})?.length) return null
  const model = getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey)
  if (!model) return null
  const config = REPORTS_CONFIG_MAP[type]
  let accountId, subAccountId, agencyId, clientId, campaignId, jobGroupId, date, currency;
  const isCorrectView = Object.keys(filters).some(item => item.split(".")[0].startsWith(config.VIEW))
  if (!isCorrectView) return null

  const view = config.VIEW
  const filterMap = config.GLOBAL_FILTERS
  accountId = filters?.[filterMap.ACCOUNT_ID] || "";
  subAccountId = filters?.[filterMap.SUB_ACCOUNT_ID] || "";
  agencyId = filters?.[filterMap.AGENCY_ID] || "";
  clientId = filters?.[filterMap.CLIENT_ID] || "";
  campaignId = filters?.[filterMap.CAMPAIGN_ID] || "";
  jobGroupId = filters?.[filterMap.JOB_GROUP_ID] || "";
  date = filters?.[filterMap.DATE] || "this month";
  currency = filters?.[filterMap.CURRENCY_RATE] || "";

  const payload = {
    model,
    view: view,
    fields: [
      `${view}.conversion_type`,
      `${view}.conversion`,
    ],
    pivots: null,
    fill_fields: null,
    filters: {
      [filterMap.ACCOUNT_ID]: accountId,
      [filterMap.SUB_ACCOUNT_ID]: subAccountId,
      [filterMap.AGENCY_ID]: agencyId,
      [filterMap.CLIENT_ID]: clientId,
      [filterMap.CAMPAIGN_ID]: campaignId,
      [filterMap.JOB_GROUP_ID]: jobGroupId,
      [filterMap.DATE]: date,
      ...(!!filterMap?.CURRENCY_RATE && { [filterMap.CURRENCY_RATE]: currency }),
      [`${view}.conversion_type`]: "{{ _user_attributes['conversion_type_filter'] }}",
    },
    sorts: [],
    limit: "100",
    column_limit: "100",
    total: false,
    row_total: null,
    subtotals: null,
    cache: true,
  }
  return payload
};

export const buildPayloadForDownload = (
  filters,
  columns,
  type = CAMPAIGN_REPORT_TITLE,
  advancedFilters,
  models
) => {
  const config = REPORTS_CONFIG_MAP[type]

  let payload = buildPayload(filters, columns, advancedFilters, type, models)
  const fields = [
    ...payload.fields,
    ...config.ADDITIONAL_FIELDS_IN_DOWNLOAD,
  ];
  payload = {
    ...payload,
    limit: -1,
    fields,
    vis_config: {
      show_view_names: false
    }
  };
  return payload;
};

export const parseLookerFilters = (filters, columns, advancedFilters, type = CAMPAIGN_REPORT_TITLE) => {
  if (!Object.keys(filters || {})?.length) return null
  const config = REPORTS_CONFIG_MAP[type]

  const isCorrectView = Object.keys(filters).some(item => item.split(".")[0].startsWith(config.VIEW))
  if (!isCorrectView) return null
  if (!columns?.dimensions?.length || !columns?.metrics?.length) return null

  const filterMap = config.GLOBAL_FILTERS
  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[filterMap.ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[filterMap.SUB_ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: filters?.[filterMap.AGENCY_ID] || "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[filterMap.CLIENT_ID] || "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters?.[filterMap.CAMPAIGN_ID] || "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters?.[filterMap.JOB_GROUP_ID] || "",
    [LOOKER_FILTER_KEYS.DATE]: filters?.[filterMap.DATE] || "this month",
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters?.[filterMap.CURRENCY_RATE] || "",
    ...(!!filterMap?.EXCHANGE_VISIBILITY && { [filterMap.EXCHANGE_VISIBILITY]: filters?.[filterMap.EXCHANGE_VISIBILITY] || EXCHANGE_VISIBILITY_VALUES.CLOSE }),
    // advanced filters
    ...parseAdvancedFilters(filters, advancedFilters),
    ...parseColumnsForFields(columns, config.ADDITIONAL_FIELDS_IN_API),
    ...parseColumnsForFilters(columns)
  }
  return payload
}

export const parseLookerFiltersForMetrics = (filters, type = CAMPAIGN_REPORT_TITLE) => {
  if (!Object.keys(filters || {})?.length) return null
  const config = REPORTS_CONFIG_MAP[type]

  const isCorrectView = Object.keys(filters).some(item => item.split(".")[0].startsWith(config.VIEW))
  if (!isCorrectView) return null

  let accountId, subAccountId, agencyId, clientId, campaignId, jobGroupId, date, currency;
  const filterMap = config.GLOBAL_FILTERS

  accountId = filters?.[filterMap.ACCOUNT_ID] || "";
  subAccountId = filters?.[filterMap.SUB_ACCOUNT_ID] || "";
  agencyId = filters?.[filterMap.AGENCY_ID] || "";
  clientId = filters?.[filterMap.CLIENT_ID] || "";
  campaignId = filters?.[filterMap.CAMPAIGN_ID] || "";
  jobGroupId = filters?.[filterMap.JOB_GROUP_ID] || "";
  date = filters?.[filterMap.DATE] || "this month";
  currency = filters?.[filterMap.CURRENCY_RATE] || "";

  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
    [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId,
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
    [LOOKER_FILTER_KEYS.DATE]: date,
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: currency,
    type
  }

  return payload
};

export const buildUTMPayload = (filters, type, models) => {
  if (!Object.entries(filters || {})?.length) return null
  if (type !== TOTAL_STATS_REPORT_TITLE) return null

  const model = getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey)
  if (!model) return null

  const payload = {
    model,
    view: "filter_data",
    fields: UTM_FIELDS,
    pivots: null,
    fill_fields: null,
    filters: {
      ...getLookFilters(filters, TOTAL_STATS_LOOKER_KEYS_CONVERSION),
      "filter_data.view_more_filter": "sourceWiseReport",
    },
    sorts: ["filter_data.sortBy", "filter_data.attributes"],
    limit: "100",
    column_limit: "100",
    total: false,
    row_total: null,
    subtotals: null,
  }

  return payload
}

export const parseUTMLookerFilters = (filters, type) => {
  if (!Object.entries(filters || {})?.length) return null
  if (type !== TOTAL_STATS_REPORT_TITLE) return null

  const config = REPORTS_CONFIG_MAP[type]
  const filterMap = config.GLOBAL_FILTERS
  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[filterMap.ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[filterMap.SUB_ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: filters?.[filterMap.AGENCY_ID] || "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[filterMap.CLIENT_ID] || "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters?.[filterMap.CAMPAIGN_ID] || "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters?.[filterMap.JOB_GROUP_ID] || "",
    [LOOKER_FILTER_KEYS.DATE]: filters?.[filterMap.DATE] || "this month",
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters?.[filterMap.CURRENCY_RATE] || "",
    // advanced filters
    // ...parseAdvancedFilters(filters, advancedFilters),
    // ...parseColumnsForFields(columns, config.ADDITIONAL_FIELDS_IN_API),
    // ...parseColumnsForFilters(columns)
  }
  return payload
}

export const parseColumnsForTable = (columns) => {
  if (!columns?.dimensions?.length || !columns?.metrics?.length) return []
  return {
    dimensions: columns.dimensions.flatMap(item => item.options.map(opt => ({ ...opt, label: opt.name, dataTargetKey: opt.key }))),
    metrics: columns.metrics.flatMap(item => item.options.map(opt => ({ ...opt, label: opt.name, dataTargetKey: opt.key })))
  }
}

export const parsePinnedColumns = (columns) => {
  if (!columns?.order?.length) return []
  return columns.order?.[0].options
    .map(item => ({ ...item, label: item.name, dataTargetKey: item.key }))
    .map(item => `${JSON.stringify(item)}`)
}

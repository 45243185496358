import React, { useEffect, useState } from 'react';

import {
  ColumnSelectionContainer,
  Header,
  Title,
  Footer,
  Body
} from './styles'

import Icon from '../common/Icon'
import Button from '../common/Button'
import MultiselectList from '../common/MultiselectList';
import Tooltip from '../common/Tooltip';
import Mixpanel from '../../services/MixPanel';
import Reorder from './Reorder';

import { COLUMN_ORDER, MAX_FREEZE_LENGTH } from '../../pages/Reports/helpers';

const ColumnSelection = ({
  dimensions,
  metrics,
  selections,
  onSubmit,
  onClose
}) => {
  const [selectedDimensions, setSelectedDimensions] = useState([])
  const [selectedMetrics, setSelectedMetrics] = useState([])
  const [columnOrder, setColumnOrder] = useState([])

  const handleSubmit = () => {
    onSubmit({
      dimensions: selectedDimensions,
      metrics: selectedMetrics,
      order: columnOrder
    })
    Mixpanel.trackEvent("Columns Selected", {
      Dimensions: selectedDimensions.map(dimension => ({
        [dimension.title]: dimension.options.map(option => option.name)
      })),
      Metrics: selectedMetrics.map(metric => ({
        [metric.title]: metric.options.map(option => option.name)
      }))
    });

  }

  const handleReset = () => {
    setSelectedDimensions([])
    setSelectedMetrics([])
    setColumnOrder([])
  }

  const handleDimensionSelection = (dimens) => {
    const currentDate = dimens?.find(item => item.title === "Date")
    let newDimensions = dimens
    if (!currentDate) {
      setSelectedDimensions(dimens)
    } else if (currentDate.options.length === 1) {
      setSelectedDimensions(dimens)
    } else {
      const prevDate = selectedDimensions.find(item => item.title === "Date")
      const options = currentDate.options.filter(item => item?.name !== prevDate.options[0]?.name)
      if (!!options) {
        newDimensions = [...dimens.filter(item => item.title !== "Date"), { ...prevDate, options }]
        setSelectedDimensions(newDimensions)
      }
    }
    setColumnOrder(colOrder => {
      const selected = [
        ...newDimensions.flatMap(item => item.options),
        ...selectedMetrics.flatMap(item => item.options)
      ]
      const newEntry = newDimensions
        .flatMap(item => item.options)
        .filter(item => !colOrder[1]?.options?.find(opt => opt.key === item.key))
        .find(item => !colOrder[0]?.options?.find(opt => opt.key === item.key))
      const removed = [...colOrder[0].options, ...colOrder[1].options].find(item => !selected.find(opt => opt.key === item.key))
      let newOrder = [
        {
          title: COLUMN_ORDER[0].title,
          options: colOrder[0]?.options || []
        },
        {
          ...colOrder[1],
          options: colOrder[1]?.options || []
        }
      ]
      if (!!removed) {
        const dimensions = [...newOrder.map(item => {
          const filtered = [...item.options.filter(opt => opt.key !== removed.key)]
          return {
            ...item,
            options: filtered
          }
        })]
        newOrder = [...dimensions]
      }
      if (!!newEntry) {
        const dimensions = [...newOrder[0]?.options || [], newEntry]
        newOrder[0].options = dimensions.slice(0, MAX_FREEZE_LENGTH)
        newOrder[1].options = [...dimensions.slice(MAX_FREEZE_LENGTH), ...newOrder[1]?.options || []]
      }
      return newOrder
    })
  }

  const handleMetricsSelection = (selection) => {
    setSelectedMetrics(selection)
    setColumnOrder(colOrder => {
      const selected = [
        ...selection.flatMap(item => item.options),
        ...selectedDimensions.flatMap(item => item.options)
      ]
      const newEntry = selection
        .flatMap(item => item.options)
        .filter(item => !colOrder?.[0]?.options?.find(opt => opt.key === item.key))
        .find(item => !colOrder?.[1]?.options?.find(opt => opt.key === item.key))
      let metrics
      if (!!newEntry) {
        metrics = [...colOrder?.[1]?.options || [], newEntry]
        return [
          {
            title: COLUMN_ORDER[0].title, options: colOrder?.[0]?.options || []
          },
          {
            title: COLUMN_ORDER[1].title, options: metrics
          }
        ]
      } else {
        const combined = [...colOrder[0].options, ...colOrder[1].options]
        const removed = combined.find(item => !selected.find(opt => opt.key === item.key))
        metrics = colOrder.map(item => ({ ...item, options: item.options.filter(opt => opt.key !== removed.key) }))
        return metrics
      }
    })
  }

  useEffect(() => {
    if (!!selections?.dimensions?.length) {
      setSelectedDimensions(selections.dimensions)
    } else {
      setSelectedDimensions([])
    }
    if (!!selections?.metrics?.length) {
      setSelectedMetrics(selections.metrics)
    } else {
      setSelectedMetrics([])
    }
    if (!!selections?.order?.length) {
      setColumnOrder(selections.order)
    } else {
      setColumnOrder([])
    }
  }, [selections])

  const disabled = selectedDimensions.length === 0 || selectedMetrics.length === 0 || !metrics?.length

  return (
    <ColumnSelectionContainer>
      <Header>
        <Title>Manage columns</Title>
        <Icon name="close" width="16" height="16" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>
      <Body>
        <MultiselectList
          type="dimension"
          options={dimensions}
          value={selectedDimensions}
          title="Dimensions"
          max={3}
          onChange={handleDimensionSelection}
        />
        <MultiselectList
          type="metric"
          options={metrics}
          value={selectedMetrics}
          title="Metrics"
          max={5}
          onChange={handleMetricsSelection}
        />
        <Reorder
          title="Column Layout"
          frozen={selectedDimensions}
          scrolls={selectedMetrics}
          order={columnOrder}
          onChange={setColumnOrder}
        />
      </Body>
      <Footer>
        <Button
          text="Reset"
          onClick={handleReset}
        />
        <Tooltip text={disabled ? "Select atleast 1 column in each type" : ""}>
          <Button
            text="Apply changes"
            type="submit"
            disabled={disabled}
            onClick={handleSubmit}
          />
        </Tooltip>
      </Footer>
    </ColumnSelectionContainer>
  );
}

export default ColumnSelection;
import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const UnifiedViewContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;

  @media screen and ${devices.mobile} {
    padding: 0;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`
export const EmptyContainer = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

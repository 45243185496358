import React from 'react';

import { TdContainer } from './styles'

const Td = ({
  children,
  ...props
}) => {
  return (
    <TdContainer {...props}>
      {children}
    </TdContainer>
  );
}

export default Td;
import { COMMON_GLOBAL_FILTERS, LOOKER_FILTER_KEYS, UNIFIED_VIEW_IDENTIFIERS } from "../../constants/AppConstants"

export const getFiltersForDashboard = (filters) => {
  const uvFilterKeys = [
    ...UNIFIED_VIEW_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => ![LOOKER_FILTER_KEYS.DASHBOARD_ID, LOOKER_FILTER_KEYS.FILTER_ID].includes(item))
  ]

  const uvFilters = uvFilterKeys.reduce((acc, key) => {
    if (!!filters?.[key]) {
      acc[key] = filters[key]
    }
    return acc
  }, {})

  return uvFilters
}

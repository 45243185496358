import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../../utils/useOnClickOutside";
import {
  CheckboxContainer,
  Options,
  EmptySearchResult,
  MultiselectContainer,
  Body,
  Footer,
  Submit,
  Header,
  Title,
  Banner
} from "./styles";

import Icon from '../Icon'
import Checkbox from '../Checkbox'
import Tooltip from "../Tooltip";

import { PUBLISHER_FILTERS } from "../../../constants/AppConstants";

const MultiSelect = ({
  options,
  title = "",
  selected,
  idKey = "id",
  displayKey = "name",
  valueKey = "value",
  className = "",
  onSubmit,
  onClose,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const wrapperRef = useRef(null);

  const handleOnChange = (option) => {
    if (option[displayKey] === "All") {
      const doesExist = selectedOptions.find(item => item[displayKey] === option[displayKey])
      if (!!doesExist) {
        setSelectedOptions([])
      } else {
        setSelectedOptions(options)
      }
    } else {
      const doesExist = selectedOptions.find(item => item[idKey] === option[idKey])
      if (!!doesExist) {
        setSelectedOptions(so => so.filter(item => item[idKey] !== option[idKey]))
      } else {
        setSelectedOptions(so => [...so, option])
      }
    }
  }

  const handleSubmit = () => {
    const all = selectedOptions.find(item => item.name === "All")
    if (!!all) {
      onSubmit(options.filter(item => item.name !== "All"))
    } else {
      onSubmit(selectedOptions);
    }
  }

  useEffect(() => {
    if (!!options?.length) {
      if (selected?.find(item => item.name === "All")) {
        setSelectedOptions(options)
      } else {
        setSelectedOptions(selected || [])
      }
    }
  }, [selected, options]);

  useEffect(() => {
    if (options?.find(item => item.name === "All")) {
      if (!selectedOptions?.find(item => item.name === "All") && selectedOptions.length === options.length - 1) {
        setSelectedOptions(options)
      } else if (selectedOptions?.find(item => item.name === "All") && selectedOptions.length === options.length - 1) {
        setSelectedOptions(so => so.filter(item => item[displayKey] !== "All"))
      }
    }
  }, [selectedOptions, options])

  useOnClickOutside(wrapperRef, () => {
    onClose()
  });

  return (
    <MultiselectContainer className={className ? className : ""} ref={wrapperRef} {...props}>
      <Header>
        <Title>{title}</Title>
        <Icon name="close" className="Close_Icon" onClick={onClose} />
      </Header>
      <Body>
        <Options isPublisher={PUBLISHER_FILTERS.includes(title)}>
          {options.length > 0 ? (
            options.map((option) => (
              <CheckboxContainer key={JSON.stringify(option)}>
                <Checkbox
                  key={option[idKey]}
                  id={option[idKey]}
                  label={option[displayKey]}
                  checked={!!selectedOptions?.find(s => s[idKey] === option[idKey])}
                  onChange={() => handleOnChange(option)}
                />
              </CheckboxContainer>
            ))
          ) : (
            <EmptySearchResult>No results found</EmptySearchResult>
          )}
        </Options>
      </Body>
      {
        PUBLISHER_FILTERS.includes(title) && (
          <Banner>
            <strong>Note</strong> : Sponsored job count will not be impacted with this filter
          </Banner>
        )
      }
      <Footer disabled={!selectedOptions.length} onClick={!!selectedOptions.length ? handleSubmit : null}>
        <Tooltip text={!selectedOptions.length ? "Select atleast 1 option" : ""}>
          <Submit>DONE</Submit>
        </Tooltip>
      </Footer>
    </MultiselectContainer>
  );
};

export default MultiSelect;

import styled from "styled-components";

export const RoadmapSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 280px;
  align-items: center;
  margin-right: 5rem;
`;

export const RoadmapSidebarOption = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  border-radius:8px;
  width: 100%;
  padding: 0px 16px;
  background: ${props => props.isSelected ? "#E4E8FF" : "inherit"};
  &:hover {
    background-color: ${props => !props.isSelected ? "#E2E8F0" : "#E4E8FF"};
    color: ${props => !props.isSelected ? "#3D4759" : "#303F9F"};
  }
  
  // text styles
  color: ${props => props.isSelected ? "#303F9F" : "#3D4759"};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

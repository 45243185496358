import styled from "styled-components";

export const GroupDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  box-shadow: 0px 2px 4px -2px #1018280F;
  border: 1px solid #E2E8F0;
  background: #FFFFFF;
`
export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
  color: #475569;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.6rem 0.8rem;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: #D8DDFF;
  }
`

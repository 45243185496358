import React, { useState } from 'react';

import {
  JobDetailsContainer,
  EmptyContainer
} from "./styles";

import JobRow from '../JobRow';
import EmptyText from '../../../components/common/EmptyText';
import Loader from '../../../components/common/Loader';

import { LOOKER_FILTER_KEYS } from '../../../constants/AppConstants';

const JobDetails = ({
  jobs = [],
  filters,
  type,
  isLoading,
  onGetScore,
  onJobExpand
}) => {
  const [expandedRow, setExpandedRow] = useState(0)

  const filtersStripped = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
  }

  return (
    <JobDetailsContainer>
      {
        isLoading && (
          <EmptyContainer>
            <Loader size="medium" />
          </EmptyContainer>
        )
      }
      {
        !jobs?.length && !isLoading && (
          <EmptyContainer>
            <EmptyText />
          </EmptyContainer>
        )
      }
      {
        (!!jobs?.length && !isLoading) && jobs?.map((job, index) => (
          <JobRow
            key={JSON.stringify(job)}
            job={job}
            filters={filtersStripped}
            type={type}
            isExpanded={index === (expandedRow - 1)}
            onRowExpand={() => {
              if (expandedRow === (index + 1)) {
                setExpandedRow(0)
              } else {
                setExpandedRow(index + 1)
              }
            }}
            onGetScore={onGetScore}
            onExpand={onJobExpand}
          />
        ))
      }
    </JobDetailsContainer>
  );
}

export default JobDetails;

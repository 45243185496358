import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRoadmapFeature, getRoadmapFeatures, voteRoadmapFeature } from "../services/apis";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { ACCESS_TOKEN_LS_KEY } from "../constants/AppConstants";

export const useFetchRoadmapFeatures = ({status, page = 0, size = 10, disable = false}) => {
  return useQuery(
    ["roadmap-features", status, page, size],
    () => getRoadmapFeatures(status, page, size),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !disable
    },
  );
}

export const useFetchRoadmapFeature = (featureId) => {
  return useQuery(
    ["roadmap-feature", featureId],
    () => getRoadmapFeature(featureId),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  );
}

export const usePostRoadmapFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => {
    return voteRoadmapFeature(payload);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(["roadmap-features"]);
    },
  });
}

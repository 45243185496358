import styled from "styled-components";

export const Text = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #252f3f;

  /* Custom styles passed via styles prop */
  ${props => props.styles};
`;

import React, { useMemo, useState } from 'react';

import {
  FilterLabelContainer,
  Text,
  RemoveContainer
} from './styles'

import Tooltip from '../Tooltip';
import Icon from '../Icon';
import { STRING_SEPARATOR } from '../../../constants/AppConstants';

const FilterLabel = ({
  label,
  text,
  disabled,
  onClick,
  onRemove
}) => {
  const [isHovering, toggleHovering] = useState(false)

  const labelText = useMemo(() => {
    const split = text?.split(STRING_SEPARATOR)
    let finalString = ""
    for (let i = 0; i < split.length; i++) {
      if ((finalString.length + split[i].length) >= 10) {
        const slice = split[i].slice(0, 10)
        if (!!finalString) {
          return `${finalString} + ${(split.length - 1) - i + 1} more`
        } else {
          return `${slice} ${i < (split.length - 1) ? `${finalString} + ${(split.length - 1) - i} more` : ""}`
        }
      } else {
        finalString = !!finalString ? `${finalString},${split[i]}` : split[i]
      }
    }
    return finalString
  }, [text])

  return (
    <FilterLabelContainer
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClick()
        }
      }}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      <Text bold>{label}</Text>
      <Tooltip text={text.replaceAll(STRING_SEPARATOR, ", ")}>
        <Text>{labelText}</Text>
      </Tooltip>
      <RemoveContainer show={!disabled && isHovering} onClick={(e) => {
        e.stopPropagation()
        onRemove()
      }}>
        <Tooltip text="Remove filter">
          <Icon name="close" alt="remove" />
        </Tooltip>
      </RemoveContainer>
    </FilterLabelContainer>
  );
}

export default FilterLabel;
import styled from "styled-components";
import { devices } from "../../constants/AppConstants";
import { JobQualityContainer as JobQualityWidgetContainer } from "../../widgets/Programmatic/JobQualityWidget/styles"
import { EmptyContainer } from "../../components/common/WidgetEmptyState/styles";

export const JobQualityContainer = styled.div`
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0 1rem 0;
  height: calc(100vh - 3.5rem);

  .ScoreModal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and ${devices.mobile} {
    display: block;
    padding-left: 1rem;
    height: inherit;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 0 2rem;
`;
export const BetaText = styled.p`
  color: #3d5afe;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
  position: relative;

  img {
    margin-top: 0.1rem;
  }
`;
export const InfoContainer = styled.div`
  display: initial;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #252f3f;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-20%);
  left: calc(100% + 0.9rem);
  width: 20rem;
  padding: 1rem 1.2rem;
  z-index: 1;
  cursor: default;
  background-color: #ffffff;

  @media screen and ${devices.mobile} {
    top: 3.2rem;
    left: -8rem;
  }
`;
export const Arrow = styled.div`
  display: initial;
  position: absolute;
  top: 20%;
  left: calc(100%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e2e8f0;
  transform: rotateZ(90deg);

  @media screen and ${devices.mobile} {
    top: 1.2rem;
    transform: rotateZ(0deg);
    left: 1.8rem;
  }
`;
export const Link = styled.a`
  color: #303f9f;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
`;
export const InfoTitle = styled.h4`
  font-weight: 600;
  font-size: 0.9rem;
  color: #3d4759;
  margin-bottom: 0.3rem;
`;
export const ContentContainer = styled.div`
  display: flex;
  padding: 2rem 3rem;
  gap: 1.2rem;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
`;
export const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: scroll;
  flex: 3;
`;
export const ScoreContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  min-width: 20rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e6f1;

  ${JobQualityWidgetContainer} {
    height: auto;
    padding: 1rem 0.6rem;
  }

  ${EmptyContainer} {
    min-height: 40vh;
  }

  @media screen and ${devices.mobile} {
    width: 100%;
    height: calc(-13rem + 100vh);
  }
`;
export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: visible;

  .Job-quality__SearchBox {
    width: 20rem;
  }

  @media screen and ${devices.mobile} {
    flex-direction: column-reverse;

    .Job-quality__SearchBox {
      width: 100%;
    }
  }
`;
export const GroupFilter = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 0.5rem;
  background: #e9ecff;
  color: #3a4bc9;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;

  .JobQuality__Group-dropdown {
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 0;
    z-index: 2;
    width: 100%;
    border-radius: 0.3rem;
  }

  @media screen and ${devices.mobile} {
    width: 100%;
    justify-content: center;
  }
`;

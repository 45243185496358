import React, { useEffect, useRef, useState } from 'react';

import {
  AutocompleteContainer,
  Header,
  Title,
  Body,
  Footer,
  Submit,
  FilterName,
  Banner
} from './styles'

import Icon from '../Icon'
import MultiselectArea from '../MultiselectArea';
import Tooltip from '../Tooltip';

import useOnClickOutside from '../../../utils/useOnClickOutside';

import { PUBLISHER_FILTERS, STRING_SEPARATOR } from '../../../constants/AppConstants';

const AUTOCOMPLETE_FILTERS = ["is", "is not"]

const AutocompleteSearch = ({
  title,
  suggestions,
  filters,
  selected,
  className,
  suggestionsEnabled = true,
  isLoading,
  onClose,
  onSubmit,
  onTextChange,
  onSelect,
  ...props
}) => {
  const [expandedFilter, setExpandedFilter] = useState("")
  const [selections, setSelections] = useState([])
  const wrapperRef = useRef(null);

  const handleSubmit = () => {
    onSubmit({
      key: title,
      matcher: expandedFilter,
      value: selections.join(STRING_SEPARATOR)
    })
  }

  const handleSelect = (option) => {
    const doesExist = selections.find(item => item.toLocaleLowerCase() === option.toLocaleLowerCase())
    if (!doesExist) {
      setSelections(s => [...s, option])
    }
    onSelect()
  }

  useEffect(() => {
    if (!!selected) {
      setExpandedFilter(selected.matcher)
      setSelections(selected.value.split(STRING_SEPARATOR))
    } else if (!!filters?.length) {
      setExpandedFilter(filters[0].title)
    }
  }, [filters, selected]);

  useOnClickOutside(wrapperRef, () => {
    onClose()
  });

  return (
    <AutocompleteContainer className={className} ref={wrapperRef} {...props}>
      <Header>
        <Title>{title}</Title>
        <Icon name="close" className="Close_Icon" onClick={onClose} />
      </Header>
      <Body isPublisher={PUBLISHER_FILTERS.includes(title)}>
        {
          !!filters?.length && filters.map(item => (
            <div key={JSON.stringify(item)} style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
              <FilterName
                selected={expandedFilter === item.title}
                onClick={() => {
                  setExpandedFilter(item.title)
                  if (selected?.matcher !== item.title) {
                    setSelections([])
                  } else {
                    setSelections(selected.value.split(","))
                  }
                }}>
                {item.title}
              </FilterName>
              {
                expandedFilter === item.title && (
                  <MultiselectArea
                    suggestions={suggestions}
                    type={AUTOCOMPLETE_FILTERS.includes(item.title) ? "autocomplete" : "input"}
                    selections={selections}
                    suggestionsEnabled={suggestionsEnabled}
                    isLoading={isLoading}
                    onSelect={handleSelect}
                    onRemove={(opt) => setSelections(s => s.filter(item => item !== opt))}
                    onTextChange={onTextChange}
                  />
                )
              }
            </div>
          ))
        }
      </Body>
      {
        PUBLISHER_FILTERS.includes(title) && (
          <Banner>
            <strong>Note</strong> : Sponsored job count will not be impacted with this filter
          </Banner>
        )
      }
      <Footer onClick={!!selections.length ? handleSubmit : null} disabled={!selections.length}>
        <Tooltip text={!selections.length ? "Select atleast 1 option" : ""}>
          <Submit>DONE</Submit>
        </Tooltip>
      </Footer>
    </AutocompleteContainer>
  );
}

export default AutocompleteSearch;
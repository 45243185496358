import styled from "styled-components";

export const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .Action__options {
    position: absolute;
    top: 1.4rem;
    left: 1.4rem;
    z-index: 2;
  }
`
export const IconContainer = styled.div`
  height: 1.4rem;
  width: 1.2rem;
  border-radius: 0.3rem;
  border: 1px solid #E2E6F1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  cursor: pointer;
`

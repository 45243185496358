import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const ReportDownloadFormContainer = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 35vw;
  padding: 1.2rem;
  gap: 1rem;

  @media screen and ${devices.mobile} {
    max-width: 100%;
  }
`
export const Header = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ${devices.mobile} {
    img {
      display: none;
    }
  }
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .DownloadSchedule__dropdown {
    z-index: auto;
  }
`
export const Selections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: height 0.3s ease-in;
`
export const Label = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  color: #374151;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

import { CRM_IDENTIFIERS, LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import { getModel } from "../../utils/helpers";

const COUNTRY_ID = "crm_talent_pool_data.country";
const CAMPAIGN_FIELD_ID = "crm_engagement_rate.campaign";

export const buildCountryListPayload = (params, models) => {
  const payload = {
    model: getModel(models, CRM_IDENTIFIERS.modelKey),
    view: "crm_talent_pool_data",
    fields: [COUNTRY_ID],
    filters: {
      "crm_talent_pool_data.created_date":
        params[LOOKER_FILTER_KEYS.DATE] || "this month",
      [COUNTRY_ID]: "-NULL",
    },
    sorts: [COUNTRY_ID],
    limit: -1,
    cache: true,
  };
  return payload;
};

export const buildCampaignListPayload = (params, models) => {
  const payload = {
    model: getModel(models, CRM_IDENTIFIERS.modelKey),
    view: "crm_engagement_rate",
    fields: [CAMPAIGN_FIELD_ID],
    filters: {
      "crm_engagement_rate.date_date":
        params[LOOKER_FILTER_KEYS.DATE] || "this month",
      [CAMPAIGN_FIELD_ID]: "-NULL",
    },
    sorts: [CAMPAIGN_FIELD_ID],
    limit: -1,
    cache: true,
  };
  return payload;
};

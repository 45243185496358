import React, { useEffect, useState } from "react";

import {
  MultiselectContainer,
  Section,
  SectionTitle,
  SectionBody,
  SectionContainer,
  EmptyContainer,
} from "./styles";

import Checkbox from "../Checkbox";
import Loader from "../Loader";
import Icon from "../Icon";
import SearchBox from "../SearchBox";

const MultiSelectListMobileView = ({
  options,
  value,
  valueKey = "name",
  onChange,
}) => {
  const [openSections, setOpenSections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedOptions, setSearchedOptions] = useState(options);

  const handleFilterOptionsBySearchText = (searchText) => {
    return options.reduce((acc, curr) => {
      const filteredOptions = curr.options.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredOptions.length > 0) {
        acc.push({ title: curr.title, options: filteredOptions });
      }
      return acc;
    }, []);
  };

  const handleSelect = (option, title) => {
    const isSelected = value.find((item) => item.title === title);
    if (!!isSelected) {
      const isOptSelected = isSelected.options.find(
        (item) => JSON.stringify(item) === JSON.stringify(option)
      );
      if (!!isOptSelected) {
        const toUpdate = (() => {
          if (isSelected.options.length === 1) {
            return value.filter((item) => item.title !== title);
          }
          return [
            ...value.filter((item) => item.title !== title),
            {
              title,
              options: [
                ...isSelected.options.filter(
                  (item) => JSON.stringify(item) !== JSON.stringify(option)
                ),
              ],
            },
          ];
        })();
        onChange(toUpdate);
      } else {
        const toUpdate = [
          ...value.filter((item) => item.title !== title),
          { title, options: [...isSelected.options, option] },
        ];
        onChange(toUpdate);
      }
    } else {
      const toUpdate = [...value, { title, options: [option] }];
      onChange(toUpdate);
    }
  };

  const handleSearch = (updatedSearchText) => {
    setSearchText(updatedSearchText);
    if (!updatedSearchText) {
      setSearchedOptions(options);
      return;
    }
    const searchedOptions = handleFilterOptionsBySearchText(updatedSearchText);
    setSearchedOptions(searchedOptions);
  };

  useEffect(() => {
    if (value?.length) {
      setOpenSections(value.map((item) => item.title));
    }
  }, [value]);

  useEffect(() => {
    if (options) {
      setSearchedOptions(options);
    }
  }, [options]);

  return (
    <MultiselectContainer>
      <SearchBox
        placeholder={"Search"}
        value={searchText}
        onChange={handleSearch}
      />
      <SectionContainer>
        {!!searchedOptions?.length &&
          searchedOptions.map((option) => (
            <Section key={option.title}>
              <SectionTitle
                onClick={() => {
                  if (!openSections.includes(option.title)) {
                    setOpenSections((os) => [...os, option.title]);
                  } else {
                    setOpenSections((os) =>
                      os.filter((i) => i !== option.title)
                    );
                  }
                }}
              >
                <Icon
                  name="down"
                  className={`Arrow ${!openSections.includes(option.title) ? " Arrow__rotate" : ""
                    }`}
                />
                {option.title}
              </SectionTitle>
              {openSections.includes(option.title) && (
                <SectionBody>
                  {!!option.options.length &&
                    option.options.map((item) => (
                      <Checkbox
                        key={JSON.stringify(item)}
                        id={JSON.stringify(item)}
                        label={item[valueKey]}
                        checked={
                          !!value
                            .find((s) => s.title === option.title)
                            ?.options.some(
                              (o) =>
                                JSON.stringify(o) === JSON.stringify(item) &&
                                o[valueKey] === item[valueKey]
                            )
                        }
                        onChange={() => handleSelect(item, option.title)}
                      />
                    ))}
                </SectionBody>
              )}
            </Section>
          ))}
        {!options?.length && (
          <EmptyContainer className="Empty">
            <Loader size="small" />
          </EmptyContainer>
        )}
      </SectionContainer>
    </MultiselectContainer>
  );
};

export default MultiSelectListMobileView;

import React, { useRef, useState } from 'react';

import {
  ThContainer,
  OptionsContainer,
  ImageContainer
} from './styles'

import OptionsList from '../OptionsList';
import Icon from "../Icon"

import useOnClickOutside from '../../../utils/useOnClickOutside';

const OPTIONS = [
  { id: 1, title: "Freeze" },
  { id: 2, title: "Autosize column" }
]

const Th = ({
  header,
  render,
  isPinned,
  onSort,
  onOptionSelect,
  isLastHeaderColumn,
  optionsEnabled = true,
  sortingEnabled = true,
  ...props
}) => {
  const [isOptionsOpen, toggleOptions] = useState(false)
  const optionsRef = useRef(null)

  const handleOptionSelect = (opt) => {
    onOptionSelect(opt)
    toggleOptions(false)
  }

  useOnClickOutside(optionsRef, () => {
    toggleOptions(false)
  });

  return (
    <ThContainer isPinned={isPinned} {...props}>
      {
        header.isPlaceholder
          ? null
          : render(
            header.column.columnDef.header,
            header.getContext()
          )
      }
      {
        (optionsEnabled || sortingEnabled) && (
          <OptionsContainer className={isLastHeaderColumn && 'lastColumnOption'}>
            {
              optionsEnabled && (
                <ImageContainer active={isOptionsOpen} onClick={() => {
                  toggleOptions(!isOptionsOpen)
                }}>
                  <Icon name="three-dots" />
                </ImageContainer>
              )
            }
            <ImageContainer sort={header.column.getIsSorted()} onClick={header.column.getToggleSortingHandler()}>
              <Icon name="arrow-down-solid" alt="sort" />
            </ImageContainer>
          </OptionsContainer>
        )
      }
      {
        optionsEnabled && (
          <div
            {...{
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),
              className: `resizer ${header.column.getIsResizing() ? "isResizing" : ""}`,
            }}
          />
        )
      }
      {
        isOptionsOpen && (
          <OptionsList
            ref={optionsRef}
            className={`OptionsPosition ${isLastHeaderColumn && 'lastHeaderColumn'}`}
            options={OPTIONS.map(item => (item.title === "Freeze" && isPinned) ? ({ ...item, title: "Unfreeze" }) : item)}
            onSelect={handleOptionSelect}
          />
        )
      }
    </ThContainer>
  );
}

export default Th;
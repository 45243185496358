import styled from "styled-components";
import { devices } from "../../../../constants/AppConstants";
import { TooltipContainer } from "../../../common/Tooltip/styles";
import { FilterLabelContainer } from "../FilterLabel/styles";

export const SaveFiltersContainer = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 35vw;
  padding: 1.2rem;
  gap: 1rem;

  @media screen and ${devices.mobile} {
    max-width: 100%;
  }
`
export const Header = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ${devices.mobile} {
    img {
      display: none;
    }
  }
`
export const Note = styled.p`
  color: #6A7185;
  font-weight: 400;
  font-size: 0.9rem;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  flex: 1;
`
export const FiltersContainer = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 0.3rem;
  padding: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  overflow: visible;

  ${FilterLabelContainer} {
    background: #E2E8F0;
    border-radius: 10rem;
    cursor: default;
  }
`
export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`
export const Label = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  color: #374151;
`
export const Selections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  ${TooltipContainer} {
    width: auto;
  }
`
export const TextBox = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 0.3rem;
  color: #6A7185;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: not-allowed;
  padding: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
import React from 'react';

import { UNIFIED_ANALYTICS_CLOUDFRONT_URL } from "../../../constants/ApiRoutes"

const Logo = ({ name, ...props }) => {
  return (
    <img src={`${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/logos/${name}.svg`} {...props} alt={name} />
  );
}

export default Logo;
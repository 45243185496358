import { createGlobalStyle } from "styled-components";

import { UNIFIED_ANALYTICS_CLOUDFRONT_URL } from "../constants/ApiRoutes"

// const Regular = `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/fonts/SF_regular.woff`;
// const Medium = `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/fonts/SF_medium.woff`;
// const SemiBold = `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/fonts/SF_semibold.woff`;
// const Bold = `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/fonts/SF_bold.woff`;
// import SFUltraLight from "../assets/fonts/SF_ultralight.woff";
// import SFThin from "../assets/fonts/SF_thin.woff";
import Regular from "../assets/fonts/SF_regular.woff";
import Medium from "../assets/fonts/SF_medium.woff";
import SemiBold from "../assets/fonts/SF_semibold.woff";
import Bold from "../assets/fonts/SF_bold.woff";

export const FontStyles = createGlobalStyle`
  /** Regular */
  @font-face {
    font-family: 'San Francisco';
    font-weight: 400;
    src: url(${Regular}) format('woff');
  }

  /** Medium */
  @font-face {
    font-family: 'San Francisco';
    font-weight: 500;
    src: url(${Medium}) format('woff');
  }

  /** Semi Bold */
  @font-face {
    font-family: 'San Francisco';
    font-weight: 600;
    src: url(${SemiBold}) format('woff');
  }

  /** Bold */
  @font-face {
    font-family: 'San Francisco';
    font-weight: 700;
    src: url(${Bold}) format('woff');
  }
`;

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
  }
  body {
    font-family: 'San Francisco';
    /* font-family: 'San Francisco', Helvetica, Sans-Serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #FFFFFF;
    overflow: hidden;
  }
  button {
    font-family: inherit;
  }
`;
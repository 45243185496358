import { UNITS_CONFIG } from "../../constants/Units";

export const COMPARE_OPTIONS = [
  {
    keys: [
      "job_count_and_stats.spend",
      "job_count_and_stats.sponsored_jobs_count",
    ],
    display: "Spend vs Job count",
  },
  {
    keys: [
      "job_count_derived_table.applies",
      "job_count_derived_table.sponsored_jobs_count",
    ],
    display: "Applies vs Job count",
  },
  // {
  //   keys: [
  //     "job_count_derived_table.Clicks",
  //     "job_count_derived_table.sponsored_jobs_count",
  //   ],
  //   display: "Clicks vs Jobs",
  // },
  // {
  //   keys: ["job_count_derived_table.Spend", "job_count_derived_table.CTA"],
  //   display: "Spend vs CTA",
  // },
  // {
  //   keys: ["job_count_derived_table.Applies", "job_count_derived_table.CTA"],
  //   display: "Applies vs CTA",
  // },
];

export const COMPARE_CONFIG_HOME = {
  options: [...COMPARE_OPTIONS],
  showTitle: true,
  title: "Compare",
  optionSelection: true,
  plotRatio: true,
  legends: false,
  viewMore: true,
  viewMoreDbKey: "comparisonAnalysis",
  xAxisKey: "job_count_and_stats.date",
  xAxisKeyType: "date",
  timeFilter: true,
  multiColor: false,
};

export const COMPARE_VIEW_MORE_CONFIG = {
  showTitle: false,
  title: "Compare",
  optionSelection: false,
  plotRatio: true,
  legends: true,
  viewMore: false,
  xAxisKey: "view_combined_event_view_more.event_publisher_date",
  xAxisKeyType: "date",
  multiColor: false,
  dataColumns: [
    {
      dataTargetKey:
        "view_combined_event_view_more.compare_analysis_dimension_1",
      dataValueKey: "value",
    },
    {
      dataTargetKey:
        "view_combined_event_view_more.compare_analysis_dimension_2",
      dataValueKey: "value",
    },
  ],
};

export const COMPARE_FILTER_CONFIG = {
  title: "Performance Metrics",
  maxSelection: 2,
  selectionText: "Select any 2 metrics",
  selectMax: true,
  inputType: "checkbox",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.attributes",
  filterValueKey: "value",
  filtersToUpdate: [
    {
      name: "statsDisplayParameter1",
    },
    {
      name: "statsDisplayParameter2",
    },
  ],
  defaultFilters: [
    {
      name: "CPA",
    },
    {
      name: "Clicks",
    },
  ],
};

export const COMPARE_TABLE_CONFIG = {
  pagination: true,
  fillEmptyRows: true,
  dynamicColumns: true,
  dynamicRows: false,
  currencyTargetKey: "view_combined_event_view_more.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "Date",
      dataTargetKey: "view_combined_event_view_more.event_publisher_date",
      dataValueKey: "value",
    },
    {
      title: "$$1",
      dataTargetKey:
        "view_combined_event_view_more.compare_analysis_dimension_1",
      dataValueKey: "value",
      type: "number"
    },
    {
      title: "$$2",
      dataTargetKey:
        "view_combined_event_view_more.compare_analysis_dimension_2",
      dataValueKey: "value",
      type: "number"
    },
    {
      title: "$$1 vs $$2 ratio",
      dataTargetKey: "ratio",
      dataValueKey: "value",
      type: "number"
    },
  ],
  lookerFilters: [
    {
      name: "view_combined_event_view_more.compare_analysis_parameter_1",
    },
    {
      name: "view_combined_event_view_more.compare_analysis_parameter_2",
    },
  ],
  units: [...UNITS_CONFIG],
};
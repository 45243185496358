import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";
import { SearchBoxContainer } from "../../common/SearchBox/styles";

export const NavbarFilterContainerMobile = styled.div`
  display: none;

  @media screen and ${devices.mobile} {
    display: block;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;

  .NavbarFilterDropdown__button {
    padding: 0.4rem 0.6rem;
    white-space: nowrap;
  }

  ${SearchBoxContainer} {
    width: 100%;
  }
`
export const HeaderFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
`
export const SearchHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .Back__icon {
    cursor: pointer;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  gap: 0.2rem;
`;
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.9rem;
`;

export const DropdownOptionsContainer = styled.div`
  display: flex;
  padding: 0.75rem;
  padding-left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.01) inset;
  background-color: white;
  margin-top: 1rem;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadCrumbText = styled.span`
  color: var(--Plain-Gray-700, #374151);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const EntityTextContainer = styled.div`
  color: var(--Neutral-600, #6a7185);
  font-weight: 400;
  padding: 0.25rem 1.5rem;
`;

export const DropdownOptionListContainer = styled.div`
  width: 100%;
  padding: 0.25rem 0.75rem;
  height: 220px;
  overflow-y: scroll;
`;

export const DropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "rgba(170, 178, 232, 0.50);" : "initial"};
  border-radius: ${(props) => (props.isSelected ? "0.25rem" : "0")};
`;

export const DropdownLabel = styled.span`
  color: var(--Plain-Gray-700, #374151);
  font-weight: 400;
`;

export const ApplyButton = styled.button`
  border-radius: 0.25rem;
  background: var(--Primary-700, #202a76);
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: none;
  color: var(--White, #fff);
  border: none;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const NavbarDashboardName = styled.span`
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.25rem;
`;

export const ViewSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  gap: 1rem;
`
export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;

  a {
    text-decoration: none;
  }
`;
export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  flex: 1;
`
import { CAREER_AND_JOB_SITE_IDENTIFIERS, CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, JOB_SITE_IDENTIFIERS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from "../../../constants/AppConstants"
import { CUSTOM_DASHBOARDS_ROUTE, MARKET_INSIGHTS_ROUTE, REPORTS_ROUTE } from "../../../constants/AppRoutes"

const SECTIONS = [
  {
    title: "Products",
    options: [
      PROGRAMMATIC_IDENTIFIERS,
      CAREER_SITE_IDENTIFIERS,
      CRM_IDENTIFIERS
    ]
  },
  {
    title: "Others",
    options: [
      {
        ...PROGRAMMATIC_IDENTIFIERS,
        title: "Custom dashboards",
        expandable: true,
        route: CUSTOM_DASHBOARDS_ROUTE,
        icon: "custom-dashboards",
        isActive: (location) => location.pathname.includes(CUSTOM_DASHBOARDS_ROUTE),
        isLocked: false
      },
      {
        ...PROGRAMMATIC_IDENTIFIERS,
        title: "Reports",
        route: REPORTS_ROUTE,
        icon: "reports",
        isActive: (location) => location.pathname.includes(REPORTS_ROUTE),
        isLocked: false
      },
      {
        ...PROGRAMMATIC_IDENTIFIERS,
        title: "Market insights",
        route: MARKET_INSIGHTS_ROUTE,
        dashboardMapIdentifierKey: "marketInsights",
        icon: "market-insights",
        modelKey: "marketInsights",
        isActive: (location) => location.pathname.includes(MARKET_INSIGHTS_ROUTE),
        isLocked: false
      }
    ]
  }
]

export const getSections = (customDashboards, products) => {
  if (!products?.length) return SECTIONS
  const customDbs = { ...SECTIONS[1].options[0], expandableOptions: customDashboards }

  const doesCSExist = products.find(item => item.productKey === CAREER_SITE_IDENTIFIERS.productKey)
  const doesJSExist = products.find(item => item.productKey === JOB_SITE_IDENTIFIERS.productKey)

  const csJsOption = (!!doesCSExist && !!doesJSExist) ?
    CAREER_AND_JOB_SITE_IDENTIFIERS : (!!doesCSExist ? CAREER_SITE_IDENTIFIERS : (!!doesJSExist ? JOB_SITE_IDENTIFIERS : CAREER_SITE_IDENTIFIERS))

  const productOptions = [
    PROGRAMMATIC_IDENTIFIERS,
    csJsOption,
    CRM_IDENTIFIERS
  ]

  return [
    ...[{
      ...SECTIONS[0], options: productOptions
        .map(item => ({
          ...item,
          title: item.display,
          isLocked: item.isLocked ? item.isLocked(products) : false
        }))
    }],
    {
      ...SECTIONS[1], options: [
        ...(!!customDashboards?.length ? [customDbs] : []),
        ...SECTIONS[1].options.slice(1)]
    }
  ]
}
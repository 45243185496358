import React from 'react';

import { DetailedInsightsContainer } from './styles';

const DetailedInsights = ({
  iframeSrc
}) => {
  if (!iframeSrc) return null;
  return (
    <DetailedInsightsContainer>
      <iframe
        src={iframeSrc}
        title="Detailed insights"
        style={{
          width: '100%',
          height: "100%",
          border: 'none',
        }}
      />
    </DetailedInsightsContainer>
  );
}

export default DetailedInsights;

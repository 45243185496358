import React, { useEffect, useState } from 'react';

import {
  JobOptimisationContainer,
  Header,
  Title,
  ScoreText,
  ScoreBarContainer,
  ScoreContainer,
  Label,
  TabsContainer,
  TabContentContainer,
  TabSectionTitle,
  JDContainerOuter,
  JDContainerInner,
  JdSection,
  JdScoreSection,
  ExpansionText,
  CopyButton,
  CopyContainer
} from "./styles"

import Tab from '../../../components/common/Tab';
import JDScoreTable from '../JDScoreTable';
import ScoreBanner from '../../../components/common/ScoreBanner';
import Icon from '../../../components/common/Icon';

import { getBannerPosition, parseJobDetails } from './helpers';

const TABS = [
  { title: "Original job description", },
  { title: "Optimised job description", badge: "New" },
]
const JobOptimisation = ({
  job,
  onClose
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS[0].title)
  const [isOgJDExpanded, toggleOgJd] = useState(false)
  const [isOptJDExpanded, toggleOptJd] = useState(false)
  const [isCopied, toggleCopied] = useState(false)

  const {
    title,
    originalJobScore,
    originalJd,
    originalJdInsights,
    optimisedJobScore,
    optimisedJd,
    optimisedJdInsights
  } = (() => {
    return parseJobDetails(job)
  })()

  const handleCopyJD = async () => {
    try {
      await navigator.clipboard.writeText(optimisedJd);
      toggleCopied(true)
    } catch (error) {
      console.error("Error in copying JD to clipboard", error.message);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      toggleCopied(copied => copied ? !copied : copied)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [isCopied])

  const optimisedJobScoreValue = optimisedJobScore.split("%")[0]
  const originalJobScoreValue = originalJobScore.split("%")[0]

  return (
    <JobOptimisationContainer>
      <Header>
        <Title>{title}</Title>
        <Icon name="close" width="14" height="14" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>
      <ScoreContainer>
        <ScoreText>Your score</ScoreText>
        <ScoreBarContainer>
          <Icon name="scoreProgressBarLong" width="100%" alt="score scale" />
          <Label type="low">Low</Label>
          <Label type="high">High</Label>
          <ScoreBanner
            originalScore={originalJobScore}
            type="original"
            length="long"
            className="JobOptimisation__ScoreBanner"
            style={{ left: getBannerPosition(originalJobScoreValue) }}
          />
          <ScoreBanner
            optmisedScore={optimisedJobScore}
            type="optimised"
            length="long"
            className=" JobOptimisation__ScoreBanner"
            style={{ left: getBannerPosition(optimisedJobScoreValue) }}
          />
        </ScoreBarContainer>
        <TabsContainer>
          <Tab
            options={TABS}
            selected={selectedTab}
            onTabChange={(opt) => setSelectedTab(opt?.title)} />
          <TabContentContainer>
            {
              selectedTab === TABS[0].title && (
                <>
                  <JdSection>
                    <TabSectionTitle>Original Job description</TabSectionTitle>
                    <JDContainerOuter expanded={isOgJDExpanded}>
                      <JDContainerInner dangerouslySetInnerHTML={{ __html: originalJd }} expanded={isOgJDExpanded} />
                      <ExpansionText onClick={() => toggleOgJd(!isOgJDExpanded)}>
                        {isOgJDExpanded ? "Show less" : "Show more"}
                      </ExpansionText>
                    </JDContainerOuter>
                  </JdSection>
                  <JdScoreSection>
                    <TabSectionTitle>Original Job description score</TabSectionTitle>
                    <JDScoreTable insights={typeof (originalJdInsights) === "string" ? JSON.parse(originalJdInsights) : originalJdInsights} />
                  </JdScoreSection>
                </>
              )
            }
            {
              selectedTab === TABS[1].title && (
                <>
                  <JdSection>
                    <TabSectionTitle>Optimised Job description</TabSectionTitle>
                    <JDContainerOuter expanded={isOptJDExpanded} type="opt">
                      <JDContainerInner dangerouslySetInnerHTML={{ __html: optimisedJd }} expanded={isOptJDExpanded} type="opt" />
                      <ExpansionText onClick={() => toggleOptJd(!isOptJDExpanded)}>
                        {isOptJDExpanded ? "Show less" : "Show more"}
                      </ExpansionText>
                      <CopyContainer>
                        <CopyButton onClick={handleCopyJD}>
                          <Icon name="copy" alt="copy" />
                          {isCopied ? "Copied!" : "Copy JD"}
                        </CopyButton>
                      </CopyContainer>
                    </JDContainerOuter>
                  </JdSection>
                  <JdScoreSection>
                    <TabSectionTitle>Optimised Job description score</TabSectionTitle>
                    <JDScoreTable insights={typeof (optimisedJdInsights) === "string" ? JSON.parse(optimisedJdInsights) : optimisedJdInsights} />
                  </JdScoreSection>
                </>
              )
            }
          </TabContentContainer>
        </TabsContainer>
      </ScoreContainer>
    </JobOptimisationContainer>
  );
}

export default JobOptimisation;

import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 6px;
    top: 6px;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: transparent;
    border: 1.2px solid #808192;
    padding: 0.1rem;
  }

  input[type="radio"]:checked + label:before {
    background-color: #3F51B5;
    box-shadow: inset 0 0 0 2.5px #FFFFFF;
  }
`
export const Label = styled.label`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: #ededed;
    border-radius: 50%;
  }
`;
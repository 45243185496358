import React from 'react';

import { TabFilterContainer, Option, Text } from './styles'

const TabFilter = ({ options, selected, onChange }) => {
  return (
    <TabFilterContainer>
      {
        !!options && options.map((option) => (
          <Option
            active={selected?.key === option.key}
            key={option.key}
            onClick={() => {
              onChange(option);
            }}
          >
            <Text active={selected?.key === option.key}>{option.display}</Text>
          </Option>
        ))
      }
    </TabFilterContainer>
  );
}

export default TabFilter;

export const getTokenExpirationTime = (token) => {
  if (!token) {
    return null;
  }

  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    return null;
  }

  const payload = JSON.parse(atob(tokenParts[1]));
  if (!payload.exp) {
    return null;
  }

  const expirationTime = payload.exp * 1000;

  return new Date(expirationTime);
};

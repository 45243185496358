import React from 'react';

import { SubText, Text, WeeklyAxisLabelContainer } from "./styles";

const WeeklyAxisLabel = ({ weekNo, range }) => {
  return (
    <WeeklyAxisLabelContainer>
      <Text>{weekNo}</Text>
      {/* <SubText>{range}</SubText> */}
    </WeeklyAxisLabelContainer>
  );
}

export default WeeklyAxisLabel;

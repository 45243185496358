import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const ReportsContainer = styled.div`
  height: calc(100vh - 3.5rem);
  display: flex;
  flex-direction: column;
  .ColumnSelectionModal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and ${devices.mobile} {
    display: block;
  }
`;
export const LeftFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .AdvancedFilters_Reports {
    padding: 0.2rem 0.3rem;
  }

  .SearchFilter {
    top: 2.5rem !important;
  }

  .ExtendedFilter {
    top: 2.5rem !important;
  }

  .Multiselect {
    top: 4rem !important;
  }

  @media screen and ${devices.mobile} {
    display: block;

    .SearchFilter {
      top: 2.5rem !important;
    }

    .ExtendedFilter {
      top: 2.5rem !important;
    }

    .Multiselect {
      top: 2.5rem !important;
    }
  }
`;
export const RightFilters = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
  align-items: stretch;

  @media screen and ${devices.mobile} {
    display: block;

    .Report__button {
      display: none;
    }
  }

  .Report__button {
    padding: 0.5rem 0.8rem;
  }
`;
export const FiltersContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 0.5rem;

  @media screen and ${devices.mobile} {
    display: none;
  }

  .Report__button {
    padding: 0.5rem 0.8rem;
    align-self: center;
  }

  .Report__button_dropdown {
    padding: 0.8rem 0.8rem !important;
  }
`
export const MoreFilter = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #283593;
  cursor: pointer;
  position: relative;

  .SearchFilter {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 10rem;
  }
`;
export const SelectColumnButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.3rem;
  padding: 0.6rem 0.7rem;
  color: #374151;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.9rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: box-shadow 0.3s;
    transform: scale(${(props) => (props.disabled ? "1" : "1.02")});
  }

  @media screen and ${devices.mobile} {
    margin-top: 0.75rem;
    width: 100%;
  }
`;
export const Content = styled.div`
  padding: 2rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex : 1;
  overflow: auto;

  @media screen and ${devices.mobile} {
    padding: 2rem 1rem;
  }
`;
export const WidgetContainer = styled.div`
  overflow-y: scroll;
  flex: 1 1 auto;
`;
export const ReportsTableContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  iframe {
    display: ${(props) => (props.setupDone ? "initial" : "none")};
  }

  .performance-reports-table-iframe {
    height: 100%;
    width: 100%;
  }
`;
export const HeaderMask = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 2.5rem;
  width: 20%;
  background-color: #ffffff;
  display: ${(props) => (props.setupDone ? "initial" : "none")};
`;
export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;

  a {
    text-decoration: none;
  }

  @media screen and ${devices.mobile} {
    display: none;
  }
`;
export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 3rem;
  gap: 1rem;

  @media screen and ${devices.mobile} {
    display: none;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading-svg {
    img {
      animation: rotate 3s linear infinite;
    }
  }
`;

export const PaginationTextContainer = styled.div`
  display: flex;
  align-items: center;
  color: #374151;
  font-weight: 400;
  font-size: 0.8rem;
`
export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const MobileFiltersContainer = styled.div`
  display: none;

  button:nth-child(2) {
    flex: 1;
    width: auto;
  }

  .Report__button {
    padding: 0.5rem 0.8rem;
    justify-content: center;
  }

  .Report__button-img-filter {
    img {
      filter: invert(75%);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading-svg {
    img {
      animation: rotate 3s linear infinite;
    }
  }

  .Report__button_dropdown {
    padding: 0.8rem 0.8rem !important;
  }

  @media screen and ${devices.mobile} {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    justify-content: ${props => !!props.viewMode ? "space-between" : "flex-end"};
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    button {
      font-size: 0.7rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.05rem;
  position: relative;

  .Report__button {
    padding: 0.5rem 0.8rem;
    justify-content: center;
    white-space: nowrap;
  }

  .Report__button_dropdown-arrow {
    img {
      filter: invert(1);
    }
  }
`

export const ButtonsDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  justify-content: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  .Report__button {
    padding: 0.5rem 0.8rem;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
  }
`

export const TabDropdownContainer = styled.div`
  display: none;

  @media screen and ${devices.mobile} {
    display: block;
    border: 1px solid var(--Neutral-300, #e2e6f1);
    background: var(--White, #fff);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

export const TabDropdownButton = styled.button`
  width: 100%;
  padding: 1rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TabDropdownButtonText = styled.span`
  color: var(--Neutral-800, #373f50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
`;

export const TabListContainer = styled.div`
  padding-top: 10%;
  padding-bottom: 10%;
`;
export const TabList = styled.div``;
export const TabListItem = styled.div`
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  color: ${(props) =>
    props.isSelected
      ? "var(--Indigo-700, #303F9F)"
      : props.isDisabled
        ? "var(--Neutral-500, #929AAF)"
        : "#374151"};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
`;

export const InfoContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #252f3f;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  position: absolute;
  padding: 1rem 1.2rem;
  z-index: 2;
  background-color: #ffffff;
  left: -3rem;
  width: 15rem;
  bottom: 2rem;
`;

export const Arrow = styled.div`
  position: absolute;
  top: -0.7rem;
  right: -1px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e2e8f0;
  transform: rotateZ(180deg);
`;

export const TabListItemTitleContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.span`
  position: relative;
`;

import React from 'react';

import { LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from "../../constants/AppConstants";
import { searchHierarchyData } from "../../utils/searchHelpers";

export const UPDATED_AT_KEY = "updated_date.updated_date"

export const dropdownFormatter = (key, selectedOptionLabel) => (
  <>
    <strong>{`${key}`} :&nbsp;</strong>
    <span>{selectedOptionLabel || ""}</span>
  </>
);

export const getAgencyId = ({ hierarchy, filters }) => {
  const result = searchHierarchyData(hierarchy, filters, PROGRAMMATIC_IDENTIFIERS);
  return result?.id?.[LOOKER_FILTER_KEYS.AGENCY_ID] || ""
}

import React from 'react';
import { useShallow } from "zustand/react/shallow";

import { CustomDashboardsContainer } from './styles';

import EmbeddedDashboard from '../../components/EmbeddedDashboard';

import { useGlobalFiltersStore } from "../../stores/global";

const CustomDashboards = () => {
  const { globalFilters, embedUrl } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      embedUrl: state.embedUrl,
    }))
  );

  return (
    <CustomDashboardsContainer>
      <EmbeddedDashboard
        embedURL={embedUrl}
        lookerFilter={{
          ...globalFilters,
          theme: "custom_dashboard_theme"
        }}
        onDbLoad={() => { }}
      />
    </CustomDashboardsContainer>
  );
}

export default CustomDashboards;

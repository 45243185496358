import styled from "styled-components";

export const FilterSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: ${props => props.isCurrentLevel ? "20vw" : "14vw"};
  min-width: 12vw;
  padding-bottom: 0;
  transition: width 0.2s linear;
  border-bottom: 1px solid #E2E6F1;

  &:only-child {
    width: 25vw;
  }

  @media screen and (max-width: 1100px) {
    width: ${props => props.isCurrentLevel ? "30vw" : "15vw"};
  }
`
export const Title = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: #929AAF;
  text-transform: uppercase;
  padding-left: 0.5rem;
  letter-spacing: 0.1rem;
`
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  border-top: 1px solid #E2E6F1;
  border-right: ${props => props.isLast ? "none" : "1px solid #E2E6F1"};
  padding: 1rem 0.8rem;
  height: 30vh;

  @media screen and (max-height: 500px) {
    height: 40vh;
  }
`
export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  height: 100%;
  overflow: auto;
`
export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0.5rem 0.9rem;
  cursor: pointer;
  background-color: ${(props) => (props.isCurrentLevel &&
    props.isSelected) ? "rgba(170, 178, 232, 0.50);" : (!props.isCurrentLevel && props.isSelected) ? "#EFF3F8" : "initial"};
  border-radius: ${(props) => (props.isSelected ? "0.25rem" : "0")};
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  min-width: 13rem;

  &:hover {
    background-color: ${props => props.isCurrentLevel && props.isSelected ? "rgba(170, 178, 232, 0.50)" : "#EDF2F7"};
    border-radius: 0.25rem;
  }
`
export const Label = styled.span`
  color: ${(props) => (props.isCurrentLevel &&
    props.isSelected) ? "#283593" : (!props.isCurrentLevel && props.isSelected) ? "#373F50" : "#252F3F"};
  font-weight: 500;
  font-size: 0.9rem;
`;
export const EmptyContainer = styled.div`
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

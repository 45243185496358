import React, { useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';

import { EventReportContainer } from "./styles";

import DataTable from "../../../components/common/DataTable";
import ReportsLoader from "../../../components/common/ReportsLoader";

import { EVENT_REPORT_ROUTE_IDENTIFIER } from "../../../constants/AppRoutes";
import { useFetchEventReportData } from "../../../api/reports";
import { formatNumberThousandSeparated, formatUAData, getCurrencySign, parseCurrency } from "../../../utils/helpers";
import { buildPayload, parseColumnsForTable, parseLookerFilters, parsePinnedColumns } from "../apiHelpers";
import { EVENT_REPORT_TITLE, REPORTS_CONFIG_MAP } from "../../../constants/ReportsConstants";
import { useGlobalDataStore } from '../../../stores/global';

const EventReport = ({
  lookFilters,
  advancedFilters,
  columnSelections,
  pinnedColumns,
  page,
  onPageCountChange,
  isDownloading,
  onPinnedColumnsChange,
  onLoad,
  onDownload,
}) => {
  const { models } = useGlobalDataStore(useShallow((state) => ({
    models: state.models,
  })))

  const { data: { data = [] } = {}, status } = useFetchEventReportData(
    buildPayload(lookFilters, columnSelections, advancedFilters, EVENT_REPORT_TITLE, models),
    parseLookerFilters(lookFilters, columnSelections, advancedFilters, EVENT_REPORT_TITLE)
  );

  const columns = useMemo(() => {
    if (!columnSelections?.dimensions || !columnSelections?.metrics) return [];

    const currency = getCurrencySign(data, REPORTS_CONFIG_MAP[EVENT_REPORT_TITLE].CURRENCY_KEY)

    const { dimensions, metrics } = parseColumnsForTable({ dimensions: [columnSelections?.order[0]], metrics: [columnSelections?.order[1]] })

    if (!dimensions?.length && !metrics?.length) return []

    return [
      ...dimensions.map((item, index) => ({
        header: item.label,
        accessorFn: (row) => row[item.dataTargetKey],
        id: JSON.stringify(item),
        backgroundColor:
          "linear-gradient(0deg, #E8EAF6, #E8EAF6),linear-gradient(0deg, #C1CAFF, #C1CAFF)",
        cell: (cell) => {
          const formatted = `${formatUAData({
            value: cell.getValue(),
            column: cell.column.columnDef.header,
            placeholder: "-",
            type: "standard",
          })}`;
          if (typeof cell.getValue() === "number") {
            return <div style={{ textAlign: "right" }}>{formatted}</div>;
          }
          return index === 0 || index === 1 ? (
            <div style={{ minWidth: "16rem", wordBreak: "break-word" }}>
              {formatted}
            </div>
          ) : (
            formatted
          );
        },
        sortingFn: item.sortingFn || "alphanumeric"
      })),
      ...metrics.map((item, index) => ({
        header: <div style={{ textAlign: "right", flex: 1 }}>{item.label}</div>,
        accessorFn: (row) => row[item.dataTargetKey] || "",
        id: `${JSON.stringify(item)}`,
        backgroundColor:
          "linear-gradient(0deg, #E0F2F1, #E0F2F1),linear-gradient(0deg, #9EDFDC, #9EDFDC)",
        cell: (cell) => {
          let formatted = ""
          if (item.label.toLocaleLowerCase() === "spend") {
            formatted = formatNumberThousandSeparated(cell.getValue() ? cell.getValue() : 0, parseCurrency(currency), "standard")
          } else {
            formatted = `${formatUAData({ value: cell.getValue(), currency, column: item.label, placeholder: "-", type: "standard" })}`
          }
          return <div style={{ textAlign: "right" }}>{formatted}</div>;
        },
        sortingFn: "alphanumeric",
      })),
    ];
  }, [columnSelections, data]);

  useEffect(() => {
    // Push a page view event to the data layer
    window.dataLayer.push({
      event: "pageview",
      page: `reports/${EVENT_REPORT_ROUTE_IDENTIFIER}`,
    });
  }, []);

  useEffect(() => {
    onLoad(status !== "success");
  }, [onLoad, status]);

  return (
    <EventReportContainer>
      {
        status !== "success" && (
          <ReportsLoader
            isDownloading={isDownloading}
            onDownload={onDownload}
          />
        )
      }
      {
        (status === "success" && !!columns?.length) && (
          <DataTable
            data={data}
            columns={columns}
            pinnedColumns={parsePinnedColumns(columnSelections)}
            page={page}
            pageSize={25}
            onPageCountChange={onPageCountChange}
            className="EventReportTable"
            onPinnedColumnsChange={onPinnedColumnsChange}
          />
        )
      }
    </EventReportContainer>
  );
};

export default EventReport;

import { useQuery } from 'react-query';

import { getPublisherSuggestions } from '../services/apis';
import { getItemFromLocalStorage } from '../helpers/commonHelper';
import { ACCESS_TOKEN_LS_KEY, LOOKER_ACCESS_TOKEN_LS_KEY } from '../constants/AppConstants';

export const useFetchPublishers = (payload, filters) => {
  return useQuery(
    ['publishers', { ...filters }],
    () => (
      getPublisherSuggestions(payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}
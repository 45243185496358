import styled from "styled-components";

export const SearchBoxContainer = styled.div`
  padding: 0.2rem 1rem;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  background-color: inherit;

  input {
    color: #4a5568;
    flex: 1;
  }

  input::placeholder {
    color: #9fa6b2;
    font-size: 14px;
  }

  .SearchImg {
    width: 0.9rem;
    height: 0.9rem;
  }
`

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  background-color: inherit;
`;

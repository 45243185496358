import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const DropdownMenuItemElem = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #374151;
  }
`;

export const DropdownMenuItemContainter = styled.div`
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #374151;
  padding: 1rem 0 1rem 1.24rem;
  font-size: 0.9rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.028px;
  line-height: 1.25rem;

  &:hover {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  &.active {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  .time-zone {
    font-weight: 600;
  }

  .dropdown-menu-item-icon {
    margin-right: 0.6rem;
  }

  .dropdown-menu-item-end-icon {
    right: 1rem;
    position: absolute;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

export const DropdownMenuItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const DropdownSubMenuItemListContainer = styled.div`
  position: absolute;
  top: 0;
  right: 14rem;
  width: 10rem;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1),
    0px 0.25rem 6px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  display: ${({ open }) => (open ? "block" : "none")};

  &:hover {
    display: block;
  }

  @media screen and ${devices.mobile} {
    right: auto;
    left: 0;
    z-index: 2;
    max-height: 16rem;
    overflow: scroll;
    width: max-content;
  }
`;

export const SubmenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 2rem 0.7rem 1.24rem;
  cursor: pointer;
  background-color: #fff;

  span {
    color: #374151;
    line-height: 18px;
    font-weight: 400;
    font-size: 0.9rem;
  }

  &:hover {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &.selected span {
    color: #283593;
    font-weight: 500;
  }

  &.selected img {
    position: absolute;
    right: 1.25rem;
  }
`;

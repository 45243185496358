const CURRENCY_LIST_OBJ = {
  usd: {
    currencyKey: "usd",
    currencyName: "Dollar",
    currencySymbol: "$",
  },
  eur: {
    currencyKey: "eur",
    currencyName: "Euro",
    currencySymbol: "€",
  },
  gbp: {
    currencyKey: "gbp",
    currencyName: "Pound",
    currencySymbol: "£",
  },
  cad: {
    currencyKey: "cad",
    currencyName: "Canadian Dollar",
    currencySymbol: "C$",
  },
  chf: {
    currencyKey: "chf",
    currencyName: "Swiss Franc",
    currencySymbol: "CHF",
  },
  aud: {
    currencyKey: "aud",
    currencyName: "Australian Dollar",
    currencySymbol: "A$",
  },
  inr: {
    currencyKey: "inr",
    currencyName: "Indian Rupee",
    currencySymbol: "₹",
  },
  mxn: {
    currencyKey: "mxn",
    currencyName: "Mexican Peso",
    currencySymbol: "Mex$",
  },
  sgd: {
    currencyKey: "sgd",
    currencyName: "Singapore Dollar",
    currencySymbol: "S$",
  },
  // brl: {
  //   currencyKey: "brl",
  //   currencyName: "Brazilian Real",
  //   currencySymbol: "R$",
  // },
};

export default CURRENCY_LIST_OBJ;

import { COMPETITOR_INSIGHTS_IDENTIFIERS, LOOKER_FILTER_KEYS, MARKET_INSIGHTS_IDENTIFIERS } from "../../constants/AppConstants";
import { getModel } from "../../utils/helpers";
import { UPDATED_AT_KEY } from "./helpers";

export const buildCategoriesPayload = (lookerFilter, models) => {
  if (!Object.entries(lookerFilter || {})?.length || !models) return null;
  let accountId, subAccountId, clientId, agencyId;

  accountId = lookerFilter?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "";
  subAccountId = lookerFilter?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "";
  agencyId = lookerFilter?.[LOOKER_FILTER_KEYS.AGENCY_ID] || ""
  clientId = lookerFilter?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "";

  if (!accountId || !subAccountId) return null;

  const payload = {
    model: getModel(models, MARKET_INSIGHTS_IDENTIFIERS.modelKey),
    view: "category_mapping",
    fields: [
      "category_mapping.talent_profile_id",
      "category_mapping.talent_profile_label",
    ],
    filters: {
      [`sub_accounts.account_id`]: accountId,
      [`sub_accounts.sub_account_id`]: subAccountId,
      ...(clientId && {
        [`category_mapping.client_id`]: clientId,
        [`category_mapping.agency_id`]: agencyId,
      }),
    },
    sorts: ["category_mapping.talent_profile_label"],
    limit: -1,
    cache: true,
  };
  return payload;
};

export const buildMILocationsPayload = (categoryId, lookerFilter, models) => {
  if (!Object.entries(lookerFilter || {})?.length || !models || !categoryId) return null;

  const payload = {
    model: getModel(models, MARKET_INSIGHTS_IDENTIFIERS.modelKey),
    view: "location_mapping",
    fields: [
      "location_mapping.country_code",
      "location_mapping.country_label",
      "location_mapping.location_id",
      "location_mapping.location_label",
      "location_mapping.talent_profile_id",
    ],
    filters: {
      "location_mapping.talent_profile_id": categoryId,
      // "location_mapping.main_agency_id": lookerFilter[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      // "location_mapping.main_client_id": lookerFilter[LOOKER_FILTER_KEYS.CLIENT_ID] || ""
    },
    sorts: ["location_mapping.location_label"],
    limit: -1,
    cache: true,
  };
  return payload;
};

export const buildCompetitorsLocationsPayload = (filters, models) => {
  if (!models || !filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || !filters?.[LOOKER_FILTER_KEYS.AGENCY_ID]) return null;

  const payload = {
    model: getModel(models, COMPETITOR_INSIGHTS_IDENTIFIERS.modelKey),
    view: "category_location",
    fields: [
      "competitor_inbound_norm_jobs.category_id",
      "competitor_inbound_norm_jobs.category_label",
      "competitor_inbound_norm_jobs.job_country_code",
      "competitor_inbound_norm_jobs.job_country"
    ],
    filters: {
      "category_location.main_agency_id": filters[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      "category_location.main_client_id": filters[LOOKER_FILTER_KEYS.CLIENT_ID] || ""
    },
    sorts: ["competitor_inbound_norm_jobs.category_label"],
    limit: -1,
    cache: true,
  };
  return payload;
};

export const buildCompetitorsPayload = (filters, models) => {
  if (!models || !filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || !filters?.[LOOKER_FILTER_KEYS.AGENCY_ID]) return null;

  const payload = {
    model: getModel(models, COMPETITOR_INSIGHTS_IDENTIFIERS.modelKey),
    "view": "competitor_clients_config",
    "pivots": null,
    "filters": {
      "competitor_clients_config.main_agency_id": filters[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      "competitor_clients_config.main_client_id": filters[LOOKER_FILTER_KEYS.CLIENT_ID] || ""
    },
    "fill_fields": null,
    "limit": "-1",
    "sorts": [
      "clients.client_name"
    ],
    "column_limit": "100",
    "total": false,
    "row_total": null,
    "subtotals": null,
    "fields": [
      "clients.client_name",
      "clients.client_id"
    ],
    "cache": true
  };
  return payload;
}

export const buildLastUpdatedAtPayload = (filters, models) => {
  if (!models || !filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || !filters?.[LOOKER_FILTER_KEYS.AGENCY_ID]) return null;

  const payload = {
    model: getModel(models, COMPETITOR_INSIGHTS_IDENTIFIERS.modelKey),
    "view": "updated_date",
    "fields": [
      UPDATED_AT_KEY
    ],
    "filters": {
      "updated_date.client_id": filters[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
      "updated_date.agency_id": filters[LOOKER_FILTER_KEYS.AGENCY_ID] || ""
    },
    "limit": 1,
    "cache": true
  }
  return payload;
}
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  SaveOrScheduleContainer,
  DropdownContent,
  DropdownItem,
  SubDropdownContent,
  DropdownItemText
} from './styles';

import Button from '../Button';
import Icon from '../Icon';
import BottomModal from '../BottomModal';
import SaveFiltersForm from './SaveFilterForm';
import ScheduleReportForm from './ScheduleReportForm';
import Modal from '../Modal';
import { alert } from '../Toast';

import useOnClickOutside from '../../../utils/useOnClickOutside';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../../stores/global';
import { useCreateFilter, useEditFilter } from '../../../api/filters';
import { REPORTS_CONFIG_MAP } from '../../../constants/ReportsConstants';
import { DASHBOARD_MAP, DEVICE_SIZES_PX, LOOKER_FILTER_KEYS, LOOKER_USER_ID_KEY } from '../../../constants/AppConstants';
import { buildPayloadForDownload, parseColumnsForFields, parseColumnsForFilters, parseColumnsForTable } from '../../../pages/Reports/apiHelpers';
import Mixpanel from '../../../services/MixPanel';
import { searchHierarchyData } from '../../../utils/searchHelpers';
import { useCreateLookerQueryMap, useEditScheduleReport, useFetchSchedules, useScheduleReport } from '../../../api/schedules';
import { getItemFromLocalStorage } from '../../../helpers/commonHelper';
import { useCreateReportQuery } from '../../../api/reports';
import { getStateFromCronExpression } from './ScheduleReportForm/helpers';

const SAVE_SCHEDULE_OPTIONS = [
  {
    title: 'Save view',
    disabled: false,
    icon: "file",
    options: [
      { title: 'Save changes to existing view', },
      { title: 'Save view as new' },
    ]
  },
  {
    title: 'Schedule report',
    disabled: false,
    icon: "page"
  },
]

const SaveOrSchedule = ({
  type = "dashboard",
  selectedTab,
  columnSelections,
  advancedFilters,
  advancedFilterOptions,
  showAdvancedFiltersInForm = false,
  filters,
  schedule,
  mode,
  onClose
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const { dashboards,
    hierarchy,
    views,
    models
  } = useGlobalDataStore(useShallow((state) => ({
    dashboards: state.dashboards,
    hierarchy: state.hierarchy,
    views: state.views,
    models: state.models,
  })))
  const {
    currentProduct,
    globalFilters,
    viewMode,
  } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
    globalFilters: state.globalFilters,
    viewMode: state.viewMode,
  })))

  const [isOpen, toggleIsOpen] = useState(false);
  const [isSaveOptionsOpen, toggleSaveOptions] = useState(false);
  const [isScheduleModalOpen, toggleScheduleModal] = useState(false);
  const containerRef = useRef(null);


  const [saveFilterOption, setSaveFilterOption] = useState(SAVE_SCHEDULE_OPTIONS[0])
  const [isSaveFiltersModalOpen, toggleSaveFiltersModal] = useState(false)
  const [filterToEdit, setFilterToEdit] = useState(null)

  const {
    data: { data: schedules = [] } = {},
  } = useFetchSchedules({ user_id: getItemFromLocalStorage(LOOKER_USER_ID_KEY) })

  const saveSchuduleOptions = useMemo(() => {
    if (!viewMode) {
      return SAVE_SCHEDULE_OPTIONS.map((item, index) => ({
        ...item,
        ...(index === 0 ? {
          disabled: true,
          icon: "check-circle",
          title: "Saved view"
        } : {})
      }))
    }
    if (viewMode === "new") {
      return SAVE_SCHEDULE_OPTIONS.map((item) => ({ ...item, options: null }))
    }
    if (viewMode === "update") {
      return SAVE_SCHEDULE_OPTIONS
    }
  }, [viewMode])

  const { mutateAsync: createFilter, isLoading: isCreateFilterLoading } = useCreateFilter();
  const { mutateAsync: updateFilter, isLoading: isUpdateFilterLoading } = useEditFilter();
  const { mutateAsync: createQuery, isLoading: isCreateQueryLoading } = useCreateReportQuery();
  const { mutateAsync: scheduleReport, isLoading: isScheduleLoading } = useScheduleReport();
  const { mutateAsync: editSchedule, isLoading: isEditScheduleLoading } = useEditScheduleReport()
  const { mutateAsync: createLookerQueryMap, isLoading: isLookerQueryMapLoading } = useCreateLookerQueryMap()

  const handleSaveFilterForReports = async (payload) => {
    const config = REPORTS_CONFIG_MAP[selectedTab]
    const fields = parseColumnsForFields(columnSelections, config.ADDITIONAL_FIELDS_IN_API)
    const columnFilters = parseColumnsForFilters(columnSelections)
    const order = parseColumnsForTable({ dimensions: [columnSelections?.order[0]], metrics: [columnSelections?.order[1]] })
    const filterStringWithFields = `${payload.value}&${new URLSearchParams({ fields, columnFilters: JSON.stringify(columnFilters), order: JSON.stringify(order) }).toString()}`

    const payloadWithFields = {
      ...payload,
      value: filterStringWithFields,
    }

    try {
      if (saveFilterOption.title === SAVE_SCHEDULE_OPTIONS[0]?.options[0]?.title) {
        await updateFilter({
          payload: {
            ...filterToEdit,
            ...payloadWithFields
          },
          filterId: filterToEdit?.filterId
        });
        alert("View updated successfully");
        toggleSaveFiltersModal(false);
        Mixpanel.trackEvent("Filter Saved", {
          'Default view': payload.isDefault ? 'Yes' : 'No'
        });
      } else {
        const { data } = await createFilter({
          ...payloadWithFields,
          dashboardId: selectedTab
        });
        alert("View created successfully");
        toggleSaveFiltersModal(false);
        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          [LOOKER_FILTER_KEYS.FILTER_ID]: data?.filterId,
        });
        Mixpanel.trackEvent("Filter Saved", {
          'Default view': payload.isDefault ? 'Yes' : 'No'
        });
      }
    } catch (error) {
      const { message } = error;
      console.error("Error when creating filter", message);
      alert("Error in creating view", "error");
    }
  }

  const handleSaveFilterForDb = async (payload) => {
    try {
      if (saveFilterOption.title === SAVE_SCHEDULE_OPTIONS[0]?.options[0]?.title) {
        await updateFilter({
          payload: {
            ...filterToEdit,
            ...payload
          },
          filterId: filterToEdit.filterId
        });
        alert("View updated successfully");
        toggleSaveFiltersModal(false);
        Mixpanel.trackEvent("Filter Saved", {
          'Default view': payload.isDefault ? 'Yes' : 'No'
        });
      } else {
        const { data } = await createFilter({
          ...payload,
          dashboardId:
            Object.fromEntries([...searchParams])[
            LOOKER_FILTER_KEYS.DASHBOARD_ID
            ] || dashboards?.[DASHBOARD_MAP.client]?.id,
        });
        alert("View created successfully");
        toggleSaveFiltersModal(false);
        setSearchParams({
          ...Object.fromEntries([...searchParams]),
          [LOOKER_FILTER_KEYS.FILTER_ID]: data?.filterId,
        });
        Mixpanel.trackEvent("Filter Saved", {
          'Default view': payload.isDefault ? 'Yes' : 'No'
        });
      }
    } catch (error) {
      const { message } = error;
      console.error("Error when creating filter", message);
      alert("Error in creating view", "error");
    }
  };

  const handleSaveFilter = (payload) => {
    if (type === "dashboard") {
      handleSaveFilterForDb(payload)
    } else if (type === "reports") {
      handleSaveFilterForReports(payload)
    }
  }

  const handleScheduleCreateForReports = async (payload) => {
    try {
      if (!!payload) {
        let { once, filters_string, ...body } = payload
        const queryPayload = buildPayloadForDownload(filters, columnSelections, selectedTab, advancedFilterOptions, models)
        const queryRes = await createQuery(queryPayload)
        if (!!queryRes?.data) {
          const { id: queryId, slug: querySlug } = queryRes.data
          await createLookerQueryMap({
            queryId, querySlug
          })
          body = { ...body, query_id: queryId }
          await scheduleReport({ payload: body, type: once ? "once" : "" })
        }
        alert("Report scheduled successfully")
        toggleScheduleModal(false)
        Mixpanel.trackEvent("Report Scheduled", {
          Frequency: getStateFromCronExpression(body.cron)?.recurrence?.name,
        });
      }
    } catch (e) {
      console.log("Error when scheduling", e)
      alert("Report schedule failed", "error")
    }
  }

  const handleScheduleCreateForDb = async (payload) => {
    try {
      if (!!payload) {
        let { once, ...body } = payload;
        const lookml_dashboard_id = `${globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID]}_report`;
        body = { ...body, lookml_dashboard_id };
        await scheduleReport({ payload: body, type: once ? "once" : "" });
        alert("Report scheduled successfully");
        toggleScheduleModal(false);
        Mixpanel.trackEvent("Report Scheduled", {
          Frequency: getStateFromCronExpression(body.cron)?.recurrence?.name,
        });
      }
    } catch (e) {
      console.log("Error when scheduling", e);
      alert("Report schedule failed", "error");
    }
  };

  const handleScheduleUpdate = async (payload) => {
    try {
      if (!!payload) {
        let { once, ...body } = payload;
        await editSchedule({ payload: body, scheduleId: schedule.id });
        alert("Schedule updated successfully");
        toggleScheduleModal(false);
        onClose()
      }
    } catch (e) {
      console.log("Error when scheduling", e);
      alert("Schedule update failed", "error");
    }
  }

  const handleScheduleSubmit = (payload) => {
    if (!!schedule && mode === "edit") {
      handleScheduleUpdate(payload)
      return
    }
    if (type === "dashboard") {
      handleScheduleCreateForDb(payload)
    } else if (type === "reports") {
      handleScheduleCreateForReports(payload)
    }
  }

  const checkFilterUniqueness = (name) => {
    return views?.some(item => item.name === name)
  }

  const checkScheduleUniqueness = (name) => {
    return schedules?.some(item => item.name === name)
  }

  const isMobile = window.innerWidth <= DEVICE_SIZES_PX.MOBILE

  const selection = searchHierarchyData(hierarchy, globalFilters, currentProduct)

  const handleOptionSelect = (option) => {
    if (option.title === SAVE_SCHEDULE_OPTIONS[1].title) {
      toggleScheduleModal(true)
    }
    if (option.title === SAVE_SCHEDULE_OPTIONS[0].title) {
      setSaveFilterOption(option)
      toggleSaveFiltersModal(true)
      setFilterToEdit(null)
    }
    if ([
      SAVE_SCHEDULE_OPTIONS[0].options[0].title,
      SAVE_SCHEDULE_OPTIONS[0].options[1].title,
    ].includes(option.title)) {
      if (option.title === SAVE_SCHEDULE_OPTIONS[0].options[1].title) {
        setSaveFilterOption(option)
        setFilterToEdit(null)
        toggleSaveFiltersModal(true)
      } else {
        const toEdit = views?.find(item => item.filterId === globalFilters[LOOKER_FILTER_KEYS.FILTER_ID])
        setFilterToEdit(toEdit)
        setSaveFilterOption(option)
        toggleSaveFiltersModal(true)
      }
    }
    toggleSaveOptions(false)
    toggleIsOpen(false)
  }

  const handleScheduleModalClose = () => {
    toggleScheduleModal(false)
    onClose()
  }

  useOnClickOutside(containerRef, () => {
    toggleIsOpen(false);
    toggleSaveOptions(false);
  });

  useEffect(() => {
    toggleScheduleModal(mode === "edit")
  }, [mode])

  if (mode === "edit") {
    return <SaveOrScheduleContainer>
      {
        isScheduleModalOpen && (
          isMobile ? <BottomModal isOpen onClose={handleScheduleModalClose} style={{ height: "auto" }}>
            <ScheduleReportForm
              type={type}
              schedule={schedule}
              checkUniqueness={checkScheduleUniqueness}
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
              showAdvancedFilters={showAdvancedFiltersInForm}
              isLoading={isScheduleLoading || isCreateQueryLoading || isEditScheduleLoading || isLookerQueryMapLoading}
              onClose={handleScheduleModalClose}
              onSubmit={handleScheduleSubmit}
            />
          </BottomModal> :
            <Modal position="center" className="SaveFilterModal" onClose={handleScheduleModalClose}>
              <ScheduleReportForm
                type={type}
                schedule={schedule}
                checkUniqueness={checkScheduleUniqueness}
                advancedFilters={advancedFilters}
                advancedFilterOptions={advancedFilterOptions}
                showAdvancedFilters={showAdvancedFiltersInForm}
                isLoading={isScheduleLoading || isCreateQueryLoading || isEditScheduleLoading || isLookerQueryMapLoading}
                onClose={handleScheduleModalClose}
                onSubmit={handleScheduleSubmit}
              />
            </Modal>
        )
      }
    </SaveOrScheduleContainer>
  }

  return (
    <SaveOrScheduleContainer ref={containerRef}>
      <Button
        text="Save or Schedule"
        icon="arrow-down-solid"
        onClick={() => toggleIsOpen(!isOpen)}
      />
      {
        isOpen && (
          <DropdownContent>
            {
              saveSchuduleOptions.map(option => (
                <DropdownItem
                  key={option.title}
                  disabled={option.disabled}
                  onMouseEnter={() => option.options && toggleSaveOptions(true)}
                  onMouseLeave={() => option.options && toggleSaveOptions(false)}
                  onClick={() => {
                    if (option.disabled) return
                    handleOptionSelect(option)
                  }}
                >
                  <DropdownItemText>
                    {option.icon && <Icon name={option.icon} />}
                    {option.title}
                  </DropdownItemText>
                  {(option.options && !option.disabled) && <Icon name="arrow-right" />}
                  {
                    (option.options && !option.disabled) && isSaveOptionsOpen && (
                      <SubDropdownContent>
                        {
                          option.options.map(subOption => (
                            <DropdownItem
                              key={subOption.title}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOptionSelect(subOption)
                              }}
                            >
                              {subOption.title}
                            </DropdownItem>
                          ))
                        }
                      </SubDropdownContent>
                    )
                  }
                </DropdownItem>
              ))
            }
          </DropdownContent>
        )
      }
      {
        isSaveFiltersModalOpen && (
          isMobile ? <BottomModal isOpen onClose={() => toggleSaveFiltersModal(false)} style={{ height: "auto" }}>
            <SaveFiltersForm
              type={type}
              title={saveFilterOption?.title}
              selection={selection}
              filter={filterToEdit}
              checkUniqueness={checkFilterUniqueness}
              isLoading={isCreateFilterLoading || isUpdateFilterLoading}
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
              showAdvancedFilters={showAdvancedFiltersInForm}
              onSubmit={handleSaveFilter}
              onClose={() => toggleSaveFiltersModal(false)}
            />
          </BottomModal> :
            <Modal position="center" className="SaveFilterModal" onClose={() => toggleSaveFiltersModal(false)}>
              <SaveFiltersForm
                type={type}
                title={saveFilterOption?.title}
                selection={selection}
                filter={filterToEdit}
                checkUniqueness={checkFilterUniqueness}
                advancedFilters={advancedFilters}
                advancedFilterOptions={advancedFilterOptions}
                showAdvancedFilters={showAdvancedFiltersInForm}
                isLoading={isCreateFilterLoading || isUpdateFilterLoading}
                onSubmit={handleSaveFilter}
                onClose={() => toggleSaveFiltersModal(false)}
              />
            </Modal>
        )
      }
      {
        isScheduleModalOpen && (
          isMobile ? <BottomModal isOpen onClose={() => toggleScheduleModal(false)} style={{ height: "auto" }}>
            <ScheduleReportForm
              type={type}
              selection={selection}
              checkUniqueness={checkScheduleUniqueness}
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
              showAdvancedFilters={showAdvancedFiltersInForm}
              isLoading={isScheduleLoading || isCreateQueryLoading}
              onClose={() => {
                toggleScheduleModal(false)
              }}
              onSubmit={handleScheduleSubmit}
            />
          </BottomModal> :
            <Modal position="center" className="SaveFilterModal" onClose={() => toggleScheduleModal(false)}>
              <ScheduleReportForm
                type={type}
                selection={selection}
                checkUniqueness={checkScheduleUniqueness}
                advancedFilters={advancedFilters}
                advancedFilterOptions={advancedFilterOptions}
                showAdvancedFilters={showAdvancedFiltersInForm}
                isLoading={isScheduleLoading || isCreateQueryLoading}
                onClose={() => {
                  toggleScheduleModal(false)
                }}
                onSubmit={handleScheduleSubmit}
              />
            </Modal>
        )
      }
    </SaveOrScheduleContainer>
  );
};

export default SaveOrSchedule;

import { COMPETITOR_INSIGHTS_IDENTIFIERS, MARKET_INSIGHTS_IDENTIFIERS, PROGRAMMATIC_IDENTIFIERS } from "../../constants/AppConstants";

export const DEFAULT_CATEGORY_ID = "132054"
export const DEFAULT_CATEGORY_LABEL = "Account Manager"
export const DEFAULT_LOCATION_ID = "268"
export const DEFAULT_LOCATION_LABEL = "New York-Newark-Jersey City MSA, NY"

export const CATEGORY_ID = "category_mapping.talent_profile_id";
export const CATEGORY_LABEL = "category_mapping.talent_profile_label";
export const LOCATION_ID = "location_mapping.location_id";
export const LOCATION_LABEL = "location_mapping.location_label";
export const LOCATION_COUNTRY_CODE = "location_mapping.country_code";
export const LOCATION_COUNTRY_LABEL = "location_mapping.country_label";
export const CATEGORY_ID_LOCATION = "location_mapping.talent_profile_id";

export const CATEGORY_ID_COMPETITOR = "competitor_inbound_norm_jobs.category_id";
export const CATEGORY_LABEL_COMPETITOR = "competitor_inbound_norm_jobs.job_category_label";
export const LOCATION_ID_COMPETITOR = "competitor_inbound_norm_jobs.job_country_code";
export const LOCATION_LABEL_COMPETITOR = "competitor_inbound_norm_jobs.job_country";
export const LOCATION_COUNTRY_CODE_COMPETITOR = "competitor_inbound_norm_jobs.job_country_code";
export const LOCATION_COUNTRY_LABEL_COMPETITOR = "competitor_inbound_norm_jobs.job_country";

export const CATEGORY_ID_IN_LOCATION_MAPPING = "location_mapping.talent_profile_id";

const UNLOCK_TITLE = "Unlock premium access"
const UNLOCK_TEXT_1 = "Elevate your recruitment strategy with our advanced Competitive Insights feature. Gain a comprehensive view of your competitors' performance and stay ahead."
const UNLOCK_TEXT_2 = "Interested? Contact your account manager, and our team will reach out!"

const LOCKED_TITLE_CLIENT = "Select a specific client to view insights"
const LOCKED_TEXT_CLIENT = "This page provides insights tailored to specific clients, helping you understand their competitive landscape."

const UNLOCK_TITLE_CATEGORY = "Unlock premium access"
const UNLOCK_TEXT_CATEGORY_1 = "Competitive insights are not enabled for the selected client. To gain a comprehensive view of your competitors' performance and stay ahead, please contact our team."

export const SEARCH_PARAMS_KEYS = {
  CATEGORY_ID: "categoryId",
  LOCATION_ID: "locationId",
};

export const PAGE_TITLE = "Market Insights";

export const NEW_YORK_LOCATION_ID = "268";

export const MULTISELECT_DROPDOWN_MENU_STYLE = {
  marginTop: "0",
  zIndex: "3",
};

export const MULTISELECT_OPTIONS_CONTAINER_STYLE = {
  maxHeight: "10rem",
};

export const MULTISELECT_DROPDOWN_BUTTON_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  backgroundColor: "#ffffff",
  padding: "0.5rem 1rem",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "0.3rem",
  color: "#374151",
  fontSize: "0.9rem",
  fontWeight: "500",
  cursor: "pointer",
  maxWidth: "20rem",
};


export const TABS = [
  {
    ...PROGRAMMATIC_IDENTIFIERS,
    ...MARKET_INSIGHTS_IDENTIFIERS,
    label: "Overview",
    icon: "chart-in-box",
    activeIcon: "chart-in-box-blue",
  },
  {
    ...PROGRAMMATIC_IDENTIFIERS,
    ...COMPETITOR_INSIGHTS_IDENTIFIERS,
    label: "Competitive Insights",
    title: "Market insights",
    icon: "badge-grey",
    activeIcon: "badge-blue",
    lockedTexts: {
      client: {
        title: LOCKED_TITLE_CLIENT,
        texts: [LOCKED_TEXT_CLIENT]
      },
    },
    unlockTexts: {
      client: {
        title: UNLOCK_TITLE,
        texts: [UNLOCK_TEXT_1, UNLOCK_TEXT_2]
      },
      category: {
        title: UNLOCK_TITLE_CATEGORY,
        texts: [UNLOCK_TEXT_CATEGORY_1]
      }
    }
  }
]

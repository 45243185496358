import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";
import { ButtonContainer } from "../Button/styles";

export const SubheaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ${devices.mobile} {
    padding: 0 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  @media screen and ${devices.mobile} {
    position: static;
  }
`
export const WelcomeTextContainer = styled.div`
  color: #1A202C;
  font-size: 1.2rem;
  font-weight: 400;
  display: flex;
  align-items: center;
`
export const Title = styled.div`
  color: #161E2E;
  font-weight: 600;
  font-size: 1.2rem;
`
export const Text = styled.div`
  color: #565D70;
  font-weight: 400;
  font-size: 0.9rem;
`
export const SaveScheduleContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 1rem;

  .Subheader__button {
    padding: 0.5rem 0.8rem;

    img {
      filter: invert(75%);
    }
  }

  @media screen and ${devices.mobile} {
    justify-content: center;
    width: 100%;
    flex-direction: column;

    ${ButtonContainer} {
      width: 100%;
    }

    .Subheader__button {
      width: 100%;
      flex: 1;
      justify-content: center;
    }
  }
`

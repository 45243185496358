import styled from "styled-components";
import { devices, SIZES } from "../../constants/AppConstants";

export const MainAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #F4F5FB;
`;

export const EmptyContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  /* margin-left: 4rem; */
`;

export const ContentContainer = styled.div`
  margin-left: ${props => props.isRoadmapRoute ? "0" : props.isSidebarExpanded ? "14rem" : "5rem"};
  transition: ${props => props.isRoadmapRoute ? "none" : "margin-left 0.3s ease-out"};
  /* padding: 0 8rem; */

  .embeddedDashboard {
    height: ${props => !!props.doesHaveFilters ? "calc(-13rem + 100vh)" : "calc(-12rem + 100vh)"};
    width: calc(100% + 7rem);
    margin: 0 1.5rem 0 -3.5rem;
  }

  @media screen and ${devices.mobile} {
    padding: 2rem 1rem;

    .embeddedDashboard {
      width: 100%;
      height: calc(100vh - 19rem);
      margin: 0;
    }
  }

  @media screen and (min-width: ${SIZES.MOBILE}) and (max-width: ${SIZES.TABLET}) {
    .embeddedDashboard {
      height: calc(-11.5rem + 100vh);
      width: calc(100% + 4rem);
      margin: 0;
    }
  }

  @media only screen and (min-width: ${SIZES.TABLET}) and (max-width: 1215px) {
    .embeddedDashboard {
      width: calc(100% + 2rem);
      height: ${props => !!props.doesHaveFilters ? "calc(-15rem + 100vh)" : "calc(-12rem + 100vh)"};
      margin: 0 0.75rem 0 -1rem;
    }
  }

  @media only screen and (min-width: 1215px) and (max-width: 1376px) {
    .embeddedDashboard {
      width: ${props => props.isSidebarExpanded ? "calc(100% + 2.5rem)" : "calc(100% + 7rem)"};
      height: ${props => !!props.doesHaveFilters ? "calc(-13rem + 100vh)" : "calc(-12rem + 100vh)"};
      margin: ${props => props.isSidebarExpanded ? "0 0.76rem 0 -1.5rem" : "0 0.76rem 0 -3.5rem"};
    }
  }

  @media only screen and (min-width: 1376px) and (max-width: 1536px) {
    .embeddedDashboard {
      width: calc(100% + 7rem);
      height: calc(-13rem + 100vh);
      margin: 0 0.76rem 0 -3.5rem;
    }
  }

  @media screen and ${devices.mobile} {
    margin-left: 0;
  }
`;

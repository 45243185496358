import styled from "styled-components";

export const DetailedInsightsContainer = styled.div`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  img {
    margin-left: 0.2rem;
  }
`;

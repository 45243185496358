import React from "react";

import { CheckboxContainer, Label } from "./styles";

const Checkbox = ({
  id,
  label,
  checked,
  onChange,
  onClick,
  disabled,
  isPreSelected = false,
}) => {
  return (
    <CheckboxContainer isPreSelected={isPreSelected} htmlFor={id} onClick={(e) => {
      if (onClick) onClick(e)
    }}>
      <input
        type="checkbox"
        checked={checked}
        id={id}
        disabled={disabled}
        onChange={onChange}
      />
      <Label htmlFor={id} disabled={disabled} />
      <span htmlFor={id}>{label}</span>
    </CheckboxContainer>
  );
};

export default Checkbox;

import React from 'react';

import {
  ParameterVsScoreContainer,
  Title,
  ScoreContainer,
  Label,
  ContentWrapper,
  EmptyContainer
} from "./styles"

import ScoreIndicatorBar from '../ScoreIndicatorBar';
import Tooltip from '../../../components/common/Tooltip';
import EmptyText from '../../../components/common/EmptyText';
import Loader from '../../../components/common/Loader';

const ParameterVsScore = ({
  scores = [],
  isLoading
}) => {
  return (
    <ParameterVsScoreContainer>
      {!isLoading && <Title>PARAMETER VS SCORE</Title>}
      <ContentWrapper>
        {
          isLoading && (
            <EmptyContainer>
              <Loader />
            </EmptyContainer>
          )
        }
        {
          (!isLoading && !scores?.length) && (
            <EmptyContainer>
              <EmptyText />
            </EmptyContainer>
          )
        }
        {
          (!isLoading && !!scores?.length) && scores?.map(item => (
            <ScoreContainer key={JSON.stringify(item)}>
              <Tooltip text={item.parameter} position="right">
                <Label>{item.parameter}</Label>
              </Tooltip>
              <ScoreIndicatorBar type={item.score} />
            </ScoreContainer>
          ))
        }
      </ContentWrapper>
    </ParameterVsScoreContainer>
  );
}

export default ParameterVsScore;

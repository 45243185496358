export const getCountries = (locations) => {
  if (!locations?.length) return [];
  return [...new Set(locations.map((location) => location.countryLabel)
    .filter((country) => !!country))].map(item => ({ id: item, value: item }))
    .sort((a, b) => a.value.localeCompare(b.value));
}

export const getMSAs = (locations, country) => {
  if (!locations?.length) return [];
  if (!country) return locations;
  return locations.filter((location) => location.countryLabel === country)
}

export const getCountryMSAMetadata = ({
  locations,
  selections,
  setSelections,
  setCurrentEntity,
  setSearchText,
  onChange,
}) => {
  const countries = getCountries(locations);
  const msas = getMSAs(locations, selections.country?.id);

  return {
    country: {
      title: "Country",
      count: 0,
      options: countries,
      selected: selections.country,
      onSelect: (opt) => {
        setSelections({
          country: opt,
          msa: null
        })
        setSearchText("")
        if (!!opt) {
          setCurrentEntity("msa")
        }
      }
    },
    msa: {
      title: "MSA",
      count: 0,
      options: msas,
      selected: selections.msa,
      onSelect: (opt) => {
        setSearchText("")
        onChange(opt.id)
      },
      onBack: () => {
        setSearchText("")
        setCurrentEntity("country")
        setSelections(selections => ({
          ...selections,
          msa: null
        }))
      }
    },
  }
}

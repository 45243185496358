import styled from 'styled-components'

export const FilterLabelContainer = styled.div`
  position: relative;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  /* border: 1px solid #CED4DA; */
  border-radius: 0.4rem;
`
export const Text = styled.span`
  font-size: 0.9rem;
  font-weight: ${props => props.bold ? "600" : "400"};
  color: #374151;
  white-space: nowrap;
`
export const RemoveContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  /* background-color: #ECEFF1; */
  background-color: #F7F7F7;
  display: ${props => props.show ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 0.6rem;
    height: 0.6rem;
  }
`
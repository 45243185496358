import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  ReorderContainer,
  Header,
  Section,
  SectionTitle,
  SectionBody,
  SectionContainer,
  Option,
  EmptyContainer
} from './styles'

import Icon from '../../common/Icon';
import EmptyText from '../../common/EmptyText';

import { COLUMN_ORDER, MAX_FREEZE_LENGTH } from '../../../pages/Reports/helpers';

const reorderWithinSection = (source, destination, options) => {
  const sectionIndex = options.findIndex(item => item.title === source.droppableId);
  const targetOption = options[sectionIndex].options[source.index];
  const newOptions = Array.from(options[sectionIndex].options);
  newOptions.splice(source.index, 1);
  newOptions.splice(destination.index, 0, targetOption);
  return [
    ...options.slice(0, sectionIndex),
    { ...options[sectionIndex], options: newOptions },
    ...options.slice(sectionIndex + 1),
  ];
};

const reorderBetweenSections = (source, destination, options) => {
  const sourceSectionIndex = options.findIndex(item => item.title === source.droppableId);
  const destinationSectionIndex = options.findIndex(item => item.title === destination.droppableId);
  const sourceOption = options[sourceSectionIndex].options[source.index];
  const newSourceOptions = Array.from(options[sourceSectionIndex].options);
  newSourceOptions.splice(source.index, 1);
  const newDestinationOptions = Array.from(options[destinationSectionIndex].options);
  newDestinationOptions.splice(destination.index, 0, sourceOption);
  const frozenOptions = sourceSectionIndex === 0 ? newSourceOptions : newDestinationOptions
  const scrollOptions = sourceSectionIndex === 0 ? newDestinationOptions : newSourceOptions
  return [
    { title: COLUMN_ORDER[0].title, options: frozenOptions },
    { title: COLUMN_ORDER[1].title, options: scrollOptions }
  ]
};

const Reorder = ({
  title,
  valueKey = "name",
  frozen = [],
  scrolls = [],
  order,
  onChange
}) => {
  const [options, setOptions] = useState([])

  const onReorder = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    let newOptions;
    if (source.droppableId === destination.droppableId) {
      newOptions = reorderWithinSection(source, destination, options);
    } else {
      if (destination.droppableId === COLUMN_ORDER[0].title) {
        if (options?.[0]?.options?.length === MAX_FREEZE_LENGTH) return
      }
      newOptions = reorderBetweenSections(source, destination, options);
    }
    setOptions(newOptions);
    onChange(newOptions)
  }

  useEffect(() => {
    if (!order?.length) {
      setOptions([
        { title: COLUMN_ORDER[0].title, options: [] },
        { title: COLUMN_ORDER[1].title, options: [] }
      ])
    } else if (!!order?.length) {
      setOptions(order)
    }
  }, [frozen, scrolls, order])

  return (
    <ReorderContainer>
      <Header>
        <span>{title}</span>
      </Header>
      <DragDropContext onDragEnd={onReorder}>
        <SectionContainer>
          {
            !!options?.length && options.map((option, index) => (
              <Droppable
                key={option.title}
                droppableId={option.title}
                index={index}
              >
                {
                  (provided) => (
                    <Section
                      key={option.title}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <SectionTitle>
                        {option.title}
                      </SectionTitle>
                      <SectionBody>
                        {
                          !!option.options.length && option.options.map((item, index) => (
                            <Draggable
                              key={item?.[valueKey]}
                              draggableId={item?.[valueKey]}
                              index={index}
                            >
                              {
                                (provided, snapshot) => (
                                  <Option
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  // isDragging={snapshot.isDragging}
                                  >
                                    <Icon name="activeDragAndDrop" />
                                    {item?.[valueKey] || ""}
                                  </Option>
                                )
                              }
                            </Draggable>
                          ))
                        }
                        {
                          !option.options?.length && (
                            <EmptyContainer>
                              <EmptyText text="No columns selected" />
                            </EmptyContainer>
                          )
                        }
                      </SectionBody>
                      {provided.placeholder}
                    </Section>
                  )
                }
              </Droppable>
            ))
          }
        </SectionContainer>
      </DragDropContext>
    </ReorderContainer>
  );
}

export default Reorder;

import React, { useEffect, useMemo, useState } from "react";

import {
  FilterWidgetContainer,
  Header,
  Option,
  Options,
  Selection,
  Footer,
  Body,
  BlankLine,
} from "./styles";

import Checkbox from "../../../components/common/Checkbox";
import Button from "../../../components/common/Button";
import RadioButton from "../../../components/common/RadioButton";
import Title from "../../../components/common/Title";
import WidgetEmptyState from "../../../components/common/WidgetEmptyState";

import {
  DEVICE_SIZES_PX,
  MIXPANEL_ATTRIBUTES_KEY,
} from "../../../constants/AppConstants";
import { parseGroupData, filterSourceWiseData } from "./helpers";
import FilterGroup from "./FilterGroup";
import Mixpanel from "../../../services/MixPanel";
import { HIRING_FUNNEL_CONFIG_HOME } from "../../../configs/Programmatic/HiringFunnel";
import { SOURCE_WISE_CONFIG_HOME } from "../../../configs/Programmatic/SourceWise";
import { useGlobalFiltersStore } from "../../../stores/global";
import { useShallow } from "zustand/react/shallow";

const FilterWidget = ({
  data,
  config,
  filters,
  onFilterChange,
  dashboardKey,
  setIsOpen = () => { },
  widgetTitle,
}) => {
  const [selected, setSelected] = useState([]);
  const [previousSelected, setPreviousSelected] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const [previousGroups, setPreviousGroups] = useState([]);
  const [isInitFiltersSet, toggleInitFiltersSet] = useState(false);

  const handleSelect = (option) => {
    if (!selected.includes(option)) {
      setSelected((sel) => [...sel, option]);
    } else {
      setSelected((sel) => sel.filter((item) => item !== option));
    }
  };

  const { globalFilters } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
    }))
  );

  const onSubmit = () => {
    if (onFilterChange) {
      let payload = null;
      const ordered = filterGroups
        .flatMap(item => item.options.map(opt => opt.filterValue))
        .filter(item => selected.includes(item))
      if (config?.combineFilterValues) {
        payload = {
          [config?.filtersToUpdate[0].name]: ordered.join(","),
        };
      } else {
        payload = {
          ...config?.filtersToUpdate.reduce(
            (acc, lf, index) => ({
              ...acc,
              [lf.name]: selected[index],
            }),
            {}
          ),
        };
      }
      if (dashboardKey === HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey) {
        payload["sortOrder"] = ordered.join(",");
        for (let index = 0; index < ordered.length; index++) {
          payload[`row${index + 1}`] = ordered[index];
        }
      }
      setPreviousSelected(selected);
      setFilterGroups([...filterGroups]);
      setPreviousGroups([...filterGroups]);
      setIsOpen(false);
      onFilterChange(payload);
      Mixpanel.trackEvent("Detailed Insights Action", {
        [MIXPANEL_ATTRIBUTES_KEY.WIDGET_NAME]: widgetTitle,
        "Option Selected": Object.values(payload),
      });
    }
  };

  const handleRadioButtonSelect = (option, groupIndex = 0) => {
    let payload = {
      [config.filtersToUpdate[!!config?.groupedFilters ? groupIndex : 0].name]:
        option.filterValue,
    };
    if (!config?.groupedFilters) {
      setSelected([option.filterValue]);
    } else if (groupIndex === 0) {
      const sourceWise = filterSourceWiseData(
        data,
        option.filterValue,
        config,
        "Compare by"
      );
      const grouped = parseGroupData(sourceWise, config);
      const toSet = [
        ...selected.slice(0, groupIndex),
        option.filterValue,
        ...selected.slice(groupIndex + 1),
      ];
      toSet.forEach((item, index) => {
        const isSelected = grouped[index].options.find(
          (opt) => opt.filterValue === item
        );
        if (!isSelected) {
          payload[config.filtersToUpdate[index].name] =
            grouped[index].options[0].filterValue;
          toSet[index] = grouped[index].options[0].filterValue;
        }
      });
      setSelected(toSet);
    } else {
      setSelected((sel) => [
        ...sel.slice(0, groupIndex),
        option.filterValue,
        ...sel.slice(groupIndex + 1),
      ]);
    }
    if (
      dashboardKey === SOURCE_WISE_CONFIG_HOME.viewMoreDbKey &&
      groupIndex === 1
    ) {
      payload["compareByLabel"] = option.displayValue;
    }
    setIsOpen(false);
    onFilterChange(payload);
    const selectedOptionsCopy = [...selected];
    selectedOptionsCopy[groupIndex] = option?.displayValue;
    Mixpanel.trackEvent("Detailed Insights Action", {
      [MIXPANEL_ATTRIBUTES_KEY.WIDGET_NAME]: widgetTitle,
      "Option Selected": selectedOptionsCopy.filter(value => !!value),
    });
  };

  const onReset = () => {
    setSelected(previousSelected);
    setFilterGroups(previousGroups);
  };

  const filteredData = useMemo(() => {
    if (!!data) {
      if (dashboardKey === SOURCE_WISE_CONFIG_HOME.viewMoreDbKey) {
        let filter = "";
        if (!selected?.length) {
          filter = filters?.[0];
        } else {
          filter = selected[0];
        }
        return filterSourceWiseData(data, filter, config, "Compare by");
      }
      return data;
    }
    return [];
  }, [data, selected, filters, config, dashboardKey]);

  const filterOptionsBasedOnFilteredData = (filterGroup, filteredData) => {
    const allowedFilterOptions = new Set(
      filteredData?.map((option) => option?.["filter_data.attributes"])
    );
    return filterGroup?.map((group) => {
      const filteredOptions = group.options.filter((option) =>
        allowedFilterOptions.has(option.filterValue)
      );
      return { ...group, options: filteredOptions };
    });
  }


  useEffect(() => {
    if (!!filteredData?.length && !!config) {
      const key = `${dashboardKey}-${globalFilters?.clientId || "order"}`
      const storedFilterGroup = JSON.parse(localStorage.getItem(key));
      const updatedStoredFilterGroup = filterOptionsBasedOnFilteredData(storedFilterGroup, filteredData);
      const initialFilterGroups = updatedStoredFilterGroup || parseGroupData(filteredData, config);
      setFilterGroups(initialFilterGroups);
      setPreviousGroups(initialFilterGroups);
    }
  }, [filteredData, config, dashboardKey, globalFilters]);

  useEffect(() => {
    let toSet = null;
    if (
      !selected?.length &&
      isInitFiltersSet === false &&
      !!config &&
      !!filterGroups?.length
    ) {
      const allOptions = filterGroups
        .flatMap((group) => group.options.map((opt) => opt.filterValue))
        .filter(Boolean);
      if (config?.getInitFiltersFromData) {
        toSet = allOptions.slice(0, config?.maxSelection);
        setSelected(toSet);
        setPreviousSelected(toSet);
      } else {
        toSet = allOptions.filter((item) => filters?.includes(item));
        setSelected(toSet);
        setPreviousSelected(toSet);
      }
      toggleInitFiltersSet(true);
      if (config?.updateFilterOnLoad) {
        let payload = null;
        if (config?.combineFilterValues) {
          payload = {
            [config?.filtersToUpdate[0].name]: toSet.join(","),
          };
        }
        if (dashboardKey === HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey) {
          payload["sortOrder"] = toSet.join(",");
          for (let index = 0; index < toSet.length; index++) {
            payload[`row${index + 1}`] = toSet[index];
          }
        }
        onFilterChange(payload);
      }
    }
  }, [selected, filterGroups, config, filters, dashboardKey]);

  const isInitialAndCurrentFiltersSame = useMemo(() => {
    if (!previousSelected || !selected) {
      return false;
    }

    const initialFiltersString = previousSelected.slice().join(", ");
    const selectedFiltersString = selected.slice().join(", ");
    return initialFiltersString === selectedFiltersString;
  }, [selected, previousSelected]);

  const isPreSelectedItem = (filterValue) => {
    if (!previousSelected) {
      return false;
    }
    return previousSelected.includes(filterValue);
  };

  const onDragEnd = (result, key) => {
    if (
      !result.destination ||
      dashboardKey !== HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey
    ) {
      return;
    }

    if (key) {
      // for grouped checkboxes
      const { source, destination } = result;

      const filterGroupsCopy = JSON.parse(JSON.stringify(filterGroups));

      // Find the source and destination groups
      const sourceGroup = filterGroupsCopy.find(
        (group) => group.name === source.droppableId
      );
      const destinationGroup = filterGroupsCopy.find(
        (group) => group.name === destination.droppableId
      );

      if (!sourceGroup || !destinationGroup) {
        return; // Invalid source or destination
      }

      // Get the dragged option
      const draggedOption = sourceGroup.options[source.index];

      // Remove the dragged option from the source group
      sourceGroup.options.splice(source.index, 1);

      // Insert the dragged option into the destination group
      destinationGroup.options.splice(destination.index, 0, draggedOption);

      const final = [];

      filterGroupsCopy.forEach((item) => {
        item.options.forEach((option) => {
          if (selected.includes(option.filterValue)) {
            final.push(option.filterValue);
          }
        });
      });

      const key = `${dashboardKey}-${globalFilters?.clientId || "order"}`
      localStorage.setItem(
        key,
        JSON.stringify(filterGroupsCopy)
      );
      setSelected(final);
      setFilterGroups(filterGroupsCopy);
    }
  };

  const isDragAndDropEnabled =
    dashboardKey === HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey;

  return (
    <FilterWidgetContainer>
      <Header addBottomPadding={config.inputType === "radio" && config?.title}>
        {!!config?.title && (
          <Title text={!!config?.title ? config.title : ""} />
        )}
        {!!config?.selectionText && (
          <Selection>{config.selectionText}</Selection>
        )}
      </Header>
      {filteredData?.length &&
        config.inputType === "radio" &&
        !!config?.title && <BlankLine />}
      {!filteredData?.length && (
        <WidgetEmptyState />
      )}
      <Body>
        {!!filteredData?.length &&
          filterGroups.length &&
          filterGroups.map((item, groupIndex) => (
            <FilterGroup
              key={item.name}
              item={item}
              config={config}
              groupIndex={groupIndex}
              onDragEnd={onDragEnd}
              isDragAndDropEnabled={isDragAndDropEnabled}
              selected={selected}
              handleSelect={handleSelect}
              isPreSelectedItem={isPreSelectedItem}
              handleRadioButtonSelect={handleRadioButtonSelect}
              collapsible={window.innerWidth < DEVICE_SIZES_PX.MOBILE}
            />
          ))}
        {!!filteredData?.length && !filterGroups && !!config?.options && (
          <Options>
            {config.options.map((option) => (
              <Option
                key={option.displayValue}
                isRadioType={config.inputType === "radio"}
              >
                {config?.inputType === "checkbox" ? (
                  <Checkbox
                    id={option.displayValue}
                    label={option.displayValue}
                    checked={selected.includes(option.filterValue)}
                    name={option.displayValue}
                    disabled={
                      !selected.includes(option.filterValue) &&
                      selected.length === config?.maxSelection
                    }
                    onChange={() => handleSelect(option.filterValue)}
                    isPreSelected={isPreSelectedItem(option.filterValue)}
                  />
                ) : (
                  <RadioButton
                    id={option.displayValue}
                    label={option.displayValue}
                    checked={selected.includes(option.filterValue)}
                    name={option.displayValue}
                    disabled={
                      !selected.includes(option.filterValue) &&
                      selected.length === config?.maxSelection
                    }
                    onChange={() => handleRadioButtonSelect(option)}
                  />
                )}
              </Option>
            ))}
          </Options>
        )}
      </Body>
      {filteredData?.length > 0 && config?.inputType === "checkbox" && (
        <Footer>
          <Button
            text="Reset"
            disabled={
              selected.length === 0 ||
              (config?.selectMax && selected.length !== config?.maxSelection) ||
              isInitialAndCurrentFiltersSame
            }
            onClick={onReset}
            style={{ flex: "1" }}
          />
          <Button
            text="Apply"
            style={{ flex: "1" }}
            type="submit"
            disabled={
              selected.length === 0 ||
              (config?.selectMax && selected.length !== config?.maxSelection) ||
              isInitialAndCurrentFiltersSame
            }
            onClick={onSubmit}
          />
        </Footer>
      )}
    </FilterWidgetContainer>
  );
};

export default FilterWidget;

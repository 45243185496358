import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../../common/Icon";
import { RoadmapNavBarContainer, RoadmapIframeBackButton, RoadmapNavBarHeader } from "./styles";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useGlobalDataStore } from "../../../stores/global";
import { LOOKER_FILTER_KEYS, UNIFIED_VIEW_IDENTIFIERS } from "../../../constants/AppConstants";

const RoadmapNavBar = ({ isRoadmapIframeNavbar, handleRoadmapIframeBackClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [prevLocation, setPrevLocation] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()

  const { products, dashboards } = useGlobalDataStore(
    useShallow((state) => ({
      products: state.products,
      dashboards: state.dashboards
    }))
  );

  const defaultRoute = (() => {
    if (!products?.length) return "";
    const unified = products.find(
      (item) => item?.productKey === UNIFIED_VIEW_IDENTIFIERS.productKey
    );
    const product = unified || products[0];
    const dashboardId = dashboards?.[product?.dashboardMapIdentifierKey]?.id;
    return {
      route: product?.route,
      dashboardId: dashboardId
    }
  })();

  const handleRedirectToDashboard = () => {
    if(!!prevLocation) {
      navigate(`${prevLocation}?${searchParams.toString()}`);
    }
    else {
      navigate(`${defaultRoute.route}?${[LOOKER_FILTER_KEYS.DASHBOARD_ID]}=${defaultRoute.dashboardId}`);
    }
  }

  useEffect(() => {
    if(!!location.state?.prevLocation) {
      setPrevLocation(location.state?.prevLocation);
    }
  }, [location.state?.prevLocation]);

  return (
    <RoadmapNavBarContainer>
      {isRoadmapIframeNavbar ? (
        <RoadmapIframeBackButton onClick={handleRoadmapIframeBackClick}>
         <Icon
           name="arrow-circle-left"
           alt="arrow-circle-left"
           height="20"
           width="20"
           style={{ cursor: "pointer", filter: "invert(1) brightness(10)" }}
         />
         <RoadmapNavBarHeader>Back</RoadmapNavBarHeader>
       </RoadmapIframeBackButton>
     ) : (
       <RoadmapIframeBackButton>
         <Icon
           name="arrow-circle-left"
           alt="arrow-circle-left"
           height="20"
           width="20"
           style={{ filter: "invert(1) brightness(10)", cursor: "pointer" }}
           onClick={handleRedirectToDashboard}
         />
         <RoadmapNavBarHeader>Return to dashboard</RoadmapNavBarHeader>
       </RoadmapIframeBackButton>
     )}
   </RoadmapNavBarContainer>
  );
};

export default RoadmapNavBar;
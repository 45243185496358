import React, { useEffect, useMemo, useState } from "react";

import {
  ColumnSelectionContainer,
  Header,
  Title,
  Footer,
  Body,
  EntityListContainer,
  EntityList,
  EntityListItem,
  EntityCount,
  EntityCountContainer,
} from "./styles";

import Icon from "../common/Icon";
import Button from "../common/Button";
import Tooltip from "../common/Tooltip";
import MultiSelectListMobileView from "../common/MultiSelectListMobileView";

const DIMENSIONS = "Dimensions";
const METRICS = "Metrics";

const ColumnSelectionMobileView = ({
  dimensions,
  metrics,
  selections,
  onSubmit,
  onClose,
}) => {
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(DIMENSIONS);

  const ENTITY_LIST = [
    {
      name: DIMENSIONS,
      count: dimensions.length,
      isSelected: selectedEntity === DIMENSIONS,
    },
    {
      name: METRICS,
      count: metrics.length,
      isSelected: selectedEntity === METRICS,
    },
  ];

  const handleSubmit = () => {
    onSubmit({
      dimensions: selectedDimensions,
      metrics: selectedMetrics,
    });
    onClose();
  };

  const handleReset = () => {
    setSelectedDimensions([]);
    setSelectedMetrics([]);
  };

  const handleDimensionSelection = (dimens) => {
    const currentDate = dimens?.find((item) => item.title === "Date");
    if (!currentDate) {
      setSelectedDimensions(dimens);
    } else if (currentDate.options.length === 1) {
      setSelectedDimensions(dimens);
    } else {
      const prevDate = selectedDimensions.find((item) => item.title === "Date");
      const options = currentDate.options.filter(
        (item) => item?.name !== prevDate.options[0]?.name
      );
      if (!!options) {
        setSelectedDimensions([
          ...dimens.filter((item) => item.title !== "Date"),
          { ...prevDate, options },
        ]);
      }
    }
  };

  useEffect(() => {
    if (!!selections?.dimensions?.length) {
      setSelectedDimensions(selections.dimensions);
    } else {
      setSelectedDimensions([]);
    }
    if (!!selections?.metrics?.length) {
      setSelectedMetrics(selections.metrics);
    } else {
      setSelectedMetrics([]);
    }
  }, [selections]);

  const disabled =
    selectedDimensions.length === 0 ||
    selectedMetrics.length === 0 ||
    !metrics?.length;

  const handleEntityClick = (entityName) => {
    if (entityName === selectedEntity) return;
    setSelectedEntity(entityName);
  };

  const multiSelectListOptions = useMemo(() => {
    if (selectedEntity === DIMENSIONS) return dimensions;
    return metrics;
  }, [dimensions, metrics, selectedEntity]);

  const multiSelectListValue = useMemo(() => {
    if (selectedEntity === DIMENSIONS) return selectedDimensions;
    return selectedMetrics;
  }, [selectedDimensions, selectedEntity, selectedMetrics]);

  const handleMuliSelectListChange = (selectedOptions) => {
    if (selectedEntity === DIMENSIONS) {
      handleDimensionSelection(selectedOptions);
    } else {
      setSelectedMetrics(selectedOptions);
    }
  };

  return (
    <ColumnSelectionContainer>
      <Header>
        <Title>Select columns</Title>
        <Icon
          name="close"
          width="16"
          height="16"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Header>
      <Body>
        <EntityListContainer>
          <EntityList>
            {ENTITY_LIST.map((entityItem) => (
              <EntityListItem
                key={entityItem.name}
                isSelected={selectedEntity === entityItem.name}
                onClick={() => handleEntityClick(entityItem.name)}
              >
                {" "}
                <EntityCountContainer>
                  <span>{entityItem.name}</span>{" "}
                  <EntityCount isSelected={selectedEntity === entityItem.name}>
                    {entityItem.count}
                  </EntityCount>{" "}
                </EntityCountContainer>
                <Icon
                  style={{
                    visibility:
                      selectedEntity === entityItem.name ? "visible" : "hidden",
                  }}
                  name="chevron-right-blue"
                />
              </EntityListItem>
            ))}
          </EntityList>
        </EntityListContainer>
        <MultiSelectListMobileView
          options={multiSelectListOptions}
          value={multiSelectListValue}
          onChange={handleMuliSelectListChange}
        />
      </Body>
      <Footer>
        <Button
          text="Clear All"
          onClick={handleReset}
          style={{
            flex: "1",
            borderRadius: "0.25rem",
            border: "1px solid var(--Neutral-300, #E2E6F1)",
            background: "var(--White, #FFF)",
            fontWeight: "400",
          }}
        />
        <Tooltip text={disabled ? "Select atleast 1 column in each type" : ""}>
          <Button
            text="Apply"
            type="submit"
            disabled={disabled}
            onClick={handleSubmit}
            style={{
              flex: "1",
              borderRadius: "0.25rem",
              background: "var(--Primary-700, #202A76)",
              fontWeight: "400",
            }}
          />
        </Tooltip>
      </Footer>
    </ColumnSelectionContainer>
  );
};

export default ColumnSelectionMobileView;

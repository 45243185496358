import Icon from "../common/Icon";
import Switch from "../common/Switch";

import { JAXItemContainer, JAXItemText } from "./styles";

import {
  COMPETITOR_INSIGHTS_IDENTIFIERS,
  CURRENCY_KEYS,
  CURRENCY_KEY_TO_SIGN_MAP,
  EXCHANGE_VISIBILITY,
  LOOKER_FILTER_KEYS,
  MARKET_INSIGHTS_IDENTIFIERS,
  PROGRAMMATIC_IDENTIFIERS,
} from "../../constants/AppConstants";
import { SCHEDULES_ROUTE } from "../../constants/AppRoutes";
import CURRENCY_LIST_OBJ from "../../constants/currenciesList";



const CURRENCY_ICON_MAP = {
  [CURRENCY_KEYS.DOLLAR]: "dollar",
  [CURRENCY_KEYS.EURO]: "euro",
  [CURRENCY_KEYS.POUND]: "gbp",
  [CURRENCY_KEYS.INDIAN_RUPEE]: "rupee",
  [CURRENCY_KEYS.CANADIAN_DOLLAR]: "dollar",
  [CURRENCY_KEYS.SWISS_FRANC]: "chf",
  [CURRENCY_KEYS.AUSTRALIAN_DOLLAR]: "dollar",
  [CURRENCY_KEYS.SINGAPORE_DOLLAR]: "dollar",
  [CURRENCY_KEYS.MEXICAN_PESO]: "dollar",
};

const getExchangeVisibility = (value, handleChange) => {
  return (
    <JAXItemContainer>
      <Icon
        className="dropdown-menu-item-icon jax"
        name="jax"
        alt="JAX visibility toggle"
      />
      <JAXItemText>Exchange visibility</JAXItemText>
      <Switch value={value} onChange={handleChange} style={{ marginTop: "0.2rem", marginLeft: "0.7rem" }} />
    </JAXItemContainer>
  )
}

export const getCurrencySymbolImg = ({
  lookerFilter,
  primaryCurrency,
  supportedCurrencies,
}) => {
  const { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: lookerCurrencyKey } =
    lookerFilter || {};

  if (!!lookerCurrencyKey && supportedCurrencies.includes(lookerCurrencyKey)) {
    return CURRENCY_ICON_MAP[lookerCurrencyKey];
  }

  return CURRENCY_ICON_MAP[primaryCurrency];
};

export const getAvatarText = (username) => {
  if (!username) {
    return "U";
  }

  return username
    .split(" ")
    .map((name) => name[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
};

export const getCurrencyLabel = (lookerFilter, primaryCurrency, supportedCurrencies) => {
  const { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: lookerCurrencyKey } =
    lookerFilter || {};

  let selectedCurrency = primaryCurrency;

  if (
    !!lookerCurrencyKey &&
    supportedCurrencies.includes(lookerCurrencyKey)
  ) {
    selectedCurrency = lookerCurrencyKey;
  }

  return primaryCurrency === selectedCurrency
    ? "Primary Currency"
    : "Currency";
};

export const getCurrencySymbol = (lookerFilter, primaryCurrency, supportedCurrencies) => {
  const { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: lookerCurrencyKey } =
    lookerFilter || {};

  if (
    !!lookerCurrencyKey &&
    supportedCurrencies.includes(lookerCurrencyKey)
  ) {
    return CURRENCY_KEY_TO_SIGN_MAP[lookerCurrencyKey];
  }

  return CURRENCY_KEY_TO_SIGN_MAP[primaryCurrency];
};

export const getCurrencySubmenuList = (lookerFilter, primaryCurrency, supportedCurrencies, handleUpdateCurrency) => {
  if (!supportedCurrencies || supportedCurrencies.length === 0) {
    return null;
  }

  const currencySubmenuList = supportedCurrencies.map((currency) => ({
    text: `${CURRENCY_LIST_OBJ[currency].currencyName} (${CURRENCY_KEY_TO_SIGN_MAP[currency]})`,
    isSelected: isSelectedCurrency(CURRENCY_LIST_OBJ[currency].currencyKey, lookerFilter, primaryCurrency, supportedCurrencies),
    onClick: (event) => {
      event.stopPropagation();
      handleUpdateCurrency(CURRENCY_LIST_OBJ[currency].currencyKey);
    },
  }));

  return currencySubmenuList;
};

export const isSelectedCurrency = (currencyKey, lookerFilter, primaryCurrency, supportedCurrencies) => {
  const { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: lookerCurrencyKey } =
    lookerFilter || {};

  if (
    !!lookerCurrencyKey &&
    supportedCurrencies.includes(lookerCurrencyKey)
  ) {
    return currencyKey === lookerCurrencyKey;
  }

  return currencyKey === primaryCurrency;
};

export const getMenuItems = ({
  lookerFilter,
  primaryCurrency,
  supportedCurrencies,
  handleUpdateCurrency,
  handleLogout,
  isJaxSet,
  exchangeVisibility,
  searchParams,
  handleJAXExchange,
  navigate,
  setIsOpen,
  currentProduct
}) => (
  [
    {
      text: "English",
      prefixIconRequired: true,
      prefixIconSrc: "world",
      prefixIconAltText: "world",
      endIconSrc: "left",
      isDisabled: true,
      onClick: (event) => {
        event.stopPropagation();
      },
      submenu: [
        {
          text: "English",
          isSelected: true,
          onClick: () => { },
        },
      ],
    },
    {
      text: `${getCurrencyLabel(lookerFilter, primaryCurrency, supportedCurrencies)}
        (${getCurrencySymbol(lookerFilter, primaryCurrency, supportedCurrencies)}
        )`,
      prefixIconRequired: true,
      prefixIconSrc: getCurrencySymbolImg({
        lookerFilter,
        primaryCurrency,
        supportedCurrencies,
      }),
      prefixIconAltText: "currencyIcon",
      isSeparatorRequired: true,
      endIconSrc: "left",
      submenu: getCurrencySubmenuList(lookerFilter, primaryCurrency, supportedCurrencies, handleUpdateCurrency),
      onClick: (event) => {
        event.stopPropagation();
      },
      isDisabled:
        !getCurrencySubmenuList(lookerFilter, primaryCurrency, supportedCurrencies, handleUpdateCurrency) || getCurrencySubmenuList(lookerFilter, primaryCurrency, supportedCurrencies, handleUpdateCurrency).length <= 1,
    },
    ...((currentProduct?.productKey === PROGRAMMATIC_IDENTIFIERS.productKey
      && currentProduct?.apiIdentifierKey !== MARKET_INSIGHTS_IDENTIFIERS.apiIdentifierKey
      && currentProduct?.apiIdentifierKey !== COMPETITOR_INSIGHTS_IDENTIFIERS.apiIdentifierKey
      && exchangeVisibility === EXCHANGE_VISIBILITY.OPEN
    ) ? [{
      text: "Exchange visibility",
      element: () => {
        return getExchangeVisibility(isJaxSet, handleJAXExchange)
      }
    }] : []),
    {
      text: "Scheduled reports",
      prefixIconRequired: true,
      onClick: () => {
        const params = Object.fromEntries([...searchParams]);
        const urlSearchParams = "?" + new URLSearchParams(params).toString();
        navigate({
          pathname: SCHEDULES_ROUTE,
          search: urlSearchParams,
        });
        setIsOpen(false)
      },
      prefixIconSrc: "scheduledreport",
      prefixIconAltText: "schedules",
    },
    {
      text: "Logout",
      prefixIconRequired: true,
      onClick: handleLogout,
      prefixIconSrc: "logout",
      prefixIconAltText: "logout",
    },
  ]
)

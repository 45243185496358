import styled from 'styled-components';
import { devices } from '../../../constants/AppConstants';

export const DatepickerContainer = styled.div`

    .react-datepicker-popper {
      z-index: 2;
    }

    .Datepicker {
      font-family: 'San Francisco';
      background: #FFFFFF;
      border: 1px solid #CBD5E0;
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
      border-radius: 0.25rem;
      padding: 1rem;
      padding-bottom: 4rem;
      display: flex;
      justify-content: flex-end;
      position: relative;
      min-width: 720px;

      .WeekSelection {
        position: absolute;
        top: 0.6rem;
        left: 9rem;
        z-index: 2;
        max-width: fit-content;
      }

      @media screen and (min-width: 320px) and (max-width: 767px) {
        height: 35rem;
        box-shadow: none;
        border: none;
        margin-top: 0.5rem;
        padding-top: 0.8rem;
        background: #F9FAFB;
        min-width: 100%;
        width: 100%;
      }

      .react-datepicker__children-container {
        width: initial;
      }

      .react-datepicker__month-container {

        .react-datepicker__week {
          display: flex;
        }

        .react-datepicker__header {
          background: #FFFFFF;
          border: none;

          .react-datepicker__current-month {
            color: #374151;
            font-weight: 500;
            font-size: 0.9rem;
            opacity: 0.7;
            padding-bottom: 0.8rem;
          }

          .react-datepicker__day-name {
            font-weight: 600;
            font-size: 0.8rem;
            color: #6B7280;
            margin: 0;
            padding: 0.1rem;
            width: 2rem;
            height: 2rem;
          }
        }
        .react-datepicker__day {
          color: #374151;
          font-weight: 400;
          font-size: 0.8rem;
          margin: 0;
          padding: 0.1rem 0.3rem;
          border-radius: 0;
          width: 2rem;
          height: 2rem;

          &:not(.react-datepicker__day--in-range):hover {
            background-color: #ECEFFF;
            border-radius: 4px;
          }
        }

        .react-datepicker__day--selected {
          background-color: #303F9F !important;
          color: #FFFFFF !important;
        }

        .react-datepicker__day--in-selecting-range {
          background-color: #ECEFFF;
          color: #374151;
        }

        .react-datepicker__day--keyboard-selected {
          background-color: #FFFFFF;
          border: none;
        }
        .react-datepicker__day--outside-month {
          color: #ffffff !important;
          background-color: transparent !important;
        }

        .react-datepicker__day--today:not(.react-datepicker__day--outside-month, .react-datepicker__day--in-range) {
          border: 1px solid #374151;
          border-radius: 4px;
          background-color: #FFFFFF;
            /* opacity: 0.5; */
        }

        .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
          background: #ECEFFF;
        }

        .react-datepicker__day--in-range, .react-datepicker__day--outside-month {
          background: #FFFFFF;
        }

        .react-datepicker__day--range-start:not(.react-datepicker__day--outside-month), .react-datepicker__day--range-end:not(.react-datepicker__day--outside-month) {
          background-color: #303F9F;
          color: #FFFFFF;
          
          &:hover {
            color: #FFFFFF;
          }
        }
        .react-datepicker__day--range-start {
          border-radius: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        .react-datepicker__day--range-end {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .react-datepicker__day--disabled {
          cursor: not-allowed;
        }
      }
      .react-datepicker__navigation {
        top: 20px;
      }
      .react-datepicker__navigation--previous {
        left: 25.5%;
      }
      .react-datepicker__navigation--next {
        right: 6%;
      }

      @media screen and (min-width: 350px) and (max-width: 375px) {
        .react-datepicker__day-name {
          width: 2.8rem !important;
          height: 2.8rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__day {
          width: 2.8rem !important;
          height: 2.8rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
      }

      @media screen and (min-width: 375px) and (max-width: 400px) {
        .react-datepicker__day-name {
          width: 3rem !important;
          height: 3rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__day {
          width: 3rem !important;
          height: 3rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
      }

      @media screen and (min-width: 400px) and (max-width: 430px) {
        .react-datepicker__day-name {
          width: 3.2rem !important;
          height: 3.2rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__day {
          width: 3.2rem !important;
          height: 3.2rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
      }

      @media screen and (min-width: 431px) and (max-width: 550px) {
        .react-datepicker__day-name {
          width: 3.6rem !important;
          height: 3.6rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__day {
          width: 3.6rem !important;
          height: 3.6rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__week {
          display: flex;
          justify-content: center;
        }
        .react-datepicker__navigation--previous {
          left: 3rem;
        }
        .react-datepicker__navigation--next {
          right: 3rem;
        }
      }

      @media screen and (min-width: 550px) and (max-width: 767px) {
        .react-datepicker__day-name {
          width: 2.6rem !important;
          height: 2.6rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__day {
          width: 2.6rem !important;
          height: 2.6rem !important;
          font-size: 1.2rem !important;
          line-height: 2.7rem;
        }
        .react-datepicker__month-container {
          position: absolute;
          top: 6rem;
          left: 1%;
          width: 100%;
          padding: 0 0.5rem;
        }
        .react-datepicker__navigation {
          position: absolute;
          top: 6.5rem;
          height: 4rem;
          width: 4rem;
        }
        .react-datepicker__children-container {
          width: 100%;
        }
        .react-datepicker__week {
          display: flex;
          justify-content: center;
        }
        .react-datepicker__navigation--previous {
          left: 3rem;
        }
        .react-datepicker__navigation--next {
          right: 3rem;
        }
      }

      @media screen and (min-width: 350px) and (max-width: 767px) {
        .react-datepicker__month-container {
          position: absolute;
          top: 6rem;
          left: 1%;
          width: 100%;
          padding: 0 0.5rem;
        }
        .react-datepicker__navigation {
          position: absolute;
          top: 6.5rem;
          height: 4rem;
          width: 4rem;
        }
        .react-datepicker__navigation--previous {
          left: 0;
        }
        .react-datepicker__navigation--next {
          right: 0;
        }

        .react-datepicker__current-month {
          font-size: 1.2rem !important;
        }
        .react-datepicker__navigation-icon {
          font-size: 3rem;
        }
        .react-datepicker__header {
          background: #F9FAFB !important;
        }
        .react-datepicker__children-container {
          width: 100%;
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1023px) {
        .react-datepicker__triangle {
          left: -30% !important;
        }
      }
    }
`;

export const Button = styled.button`
    background-color: #FFFFFF;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.3rem;
    padding: 0.6rem 0.7rem;
    color: #374151;
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.9rem;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

    &:hover {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      transition: box-shadow 0.3s;
      transform: scale(${(props) => (props.disabled ? "1" : "1.02")});
  }

    @media screen and ${devices.mobile} {
        width: 100%
    }
`;

export const Label = styled.div`
  background: rgba(83, 71, 71, 0.1);
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;
  color: #344054;
  font-weight: 500;
  font-size: 0.8rem;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem;
  position: absolute;
  top: 0.6rem;
  left: 1rem;

  span {
    cursor: pointer;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: row;
    width: 87vw;
    overflow-x: auto;
    left: 0;
    top: 0;
    padding: 0 0 1.4rem 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      right: 0;
      background-color: #E5E7EB;
    }
  }
`;

export const Option = styled.span`
  cursor: pointer;
  color: ${props => props.active ? '#303F9F' : '#374151'};
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  &:hover {
    color: #303F9F;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0.5rem 1rem;
    border: 1px solid #E2E6F1;
    border-radius: 0.3rem;
    gap: 0;
    font-size: 1rem;
    white-space: nowrap;
    background: ${props => props.active ? "#E2E6F1" : "#FFFFFF"};
    color:  ${props => props.active ? '#565D70' : '#374151'};

    img {
      display: none;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  right: 3rem;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: calc(100vw - 2rem);
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    right: 0;
    left: 0;
  }
`;

export const CalendarButton = styled.button`
  background-color: ${props => props.submit ? '#303F9F' : '#FFFFFF'};
  color: ${props => props.submit ? '#FFFFFF' : '#374151'};
  border: ${props => props.submit ? 'none' : '1px solid #CBD5E0'};
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex: 1;
    font-size: 1.1rem;
  }
`;

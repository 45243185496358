import styled, { css } from "styled-components";
import { DEVICE_SIZES_PX } from "../../../constants/AppConstants";

export const JobQualityContainer = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.6rem;
  position: relative;
  height: 100%;
  flex: 1;
`
export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.4rem 0rem 0rem 0.4rem;
  box-sizing: border-box;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.TABLET &&
    css`
      display: block;
    `}
`
export const ScoreTitle = styled.h5`
  color: #6A7185;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 1rem;
  bottom: 1rem;
  padding: 0 1rem;
  padding-right: 2rem;
    

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      left: 50%;
      transform: translate(-50%, -50%);
      right: 50%;
      width: max-content;
      bottom: 1.5rem;
    `}
`
export const Link = styled.a`
  color: #283593;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  align-self: flex-end;
`;
export const EmptyContainer = styled.div`
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9fa6b2;
  }
`;
export const ScoreBanner = styled.div`
  background-color: #f7f9fb;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-radius: 0.6rem;
`
export const IconContainer = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background: #e7ebf7;
  border: 0.36px solid #F2F2F2;
  box-shadow: 0px 2.857142686843872px 21.428569793701172px 0px #0000000A;
`
export const BannerText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  color: #6A7185;
`
export const ScoreComparisonBanner = styled.div`
  position: relative;
  background: #f9f9f9;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  padding-top: 3rem;

  .JobQuality__ScoreBanner-og {
    position: absolute;
    top: 1.25rem;
  }
  .JobQuality__ScoreBanner-opt {
    position: absolute;
    top: 0.7rem;
  }
`
export const Labels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const LabelText = styled.span`
  color: #929AAF;
  font-size: 0.8rem;
  font-weight: 400;
`
export const InfoContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  color: #252f3f;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  width: 20rem;
  padding: 1rem 1.2rem;
  z-index: 1;
  background-color: #ffffff;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      right: auto;
        width: 14rem;
        left: -2rem;
    `}
`;
export const Arrow = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e2e8f0;
`;

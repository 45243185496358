import styled from "styled-components";

export const MultiselectContainer = styled.div`
  box-shadow: 0px 5px 5px 0px #00000033;
  box-shadow: 0px 3px 14px 0px #0000001F;
  box-shadow: 0px 8px 10px 0px #00000024;
  border-radius: 0.6rem;
  background-color: #FFFFFF;
  min-height: 32vh;
  min-width: 25vw;
  display: flex;
  flex-direction: column;
  cursor: default;
  /* border: 1px solid #CED4DA; */

  .Close_Icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
`;
export const Header = styled.div`
  font-size: 1.1rem;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  font-weight: 600;
  color: #252F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252F3F;
`
export const Body = styled.div`
  min-height: 20vh;
  padding: 1rem 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`
export const Options = styled.div`
  padding: 0;
  max-height: ${props => props.isPublisher ? "12rem" : "16rem"};
  overflow: scroll;
`;
export const CheckboxContainer = styled.div`
  padding: 0.3rem 1.5rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: #252F3F;
  cursor: pointer;

  &:hover {
    background-color: #edf2f7;
  }
`;
export const EmptySearchResult = styled.p`
  padding: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;
export const Footer = styled.div`
  padding: 1.2rem 1rem;
  border: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: #F9FAFB;
  }
`
export const Submit = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  color: #3F51B5;
`
export const Banner = styled.div`
  border: 1px solid #EDF2F7;
  background-color: #F7FAFC;
  padding: 0.8rem;
  color: #6B7280;
  font-weight: 400;
  font-size: 0.7rem;
`

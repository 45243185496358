import React, { useState } from 'react';

import {
  DropdownDiv,
  DashboardDiv,
  DashboardName,
  OptionContainer,
  Text,
  TextContainer
} from "./styles";

import Icon from "../../../Icon";

import { CUSTOM_DASHBOARDS_ROUTE } from '../../../../../constants/AppRoutes';
import Tooltip from '../../../Tooltip';

const Option = ({
  icon,
  hoverIcon,
  text,
  active = false,
  expandable = false,
  isLocked = false,
  expandableOptions = [],
  isExpanded = true,
  onSelectExpandedOption,
  onClick
}) => {
  const [isHovering, toggleHovering] = useState(false);

  return (
    <OptionContainer
      active={active}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      isExpanded={isExpanded}
      onClick={() => {
        if (!expandable) {
          onClick()
        }
      }}
    >
      <Tooltip text={!isExpanded ? text : ""} position='right'>
        <Icon name={(active) ? hoverIcon : icon} alt={icon} height="18" width="18" />
        {
          isExpanded && (
            <TextContainer>
              <Text active={active}>{text}</Text>
            </TextContainer>
          )
        }
        {
          (!!isLocked && isExpanded) && <Icon name="lock" />
        }
        {
          (!!expandable && !!expandableOptions?.length && isHovering) && (
            <DropdownDiv>
              {expandableOptions.map((opt) => (
                <DashboardDiv
                  key={opt.id}
                  selected={opt.isActive}
                  onClick={() => {
                    onSelectExpandedOption(opt?.id, CUSTOM_DASHBOARDS_ROUTE)
                    toggleHovering(false)
                  }}
                >
                  <Icon
                    name={
                      opt.iconName
                        ? opt.iconName
                        : "custom-dashboard"
                    }
                  />
                  <DashboardName selected={opt.isActive}>
                    {opt.name}
                  </DashboardName>
                </DashboardDiv>
              ))}
            </DropdownDiv>
          )
        }
      </Tooltip>
    </OptionContainer>
  );
}

export default Option;

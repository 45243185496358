import { CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, DASHBOARD_MAP, JOB_SITE_IDENTIFIERS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from "../../constants/AppConstants";
import { REPORTS_TABS } from "../../constants/ReportsConstants";
import { getFiltersFromQueryString } from "../../utils/helpers";
import { alert } from "../common/Toast";

const getFiltersForView = (payload, searchParams) => {
  const filters = getFiltersFromQueryString(payload?.value)
  switch (payload.product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey: {
      const params = {
        ...Object.fromEntries([...searchParams]),
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
        ...(!!payload?.filterId && { [LOOKER_FILTER_KEYS.FILTER_ID]: payload?.filterId }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] && { [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: filters[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] })
      }
      return params
    }
    case CAREER_SITE_IDENTIFIERS.productKey: {
      const params = {
        ...Object.fromEntries([...searchParams]),
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SITE_ID]: filters?.[LOOKER_FILTER_KEYS.SITE_ID] || "",
        ...(!!payload?.filterId && { [LOOKER_FILTER_KEYS.FILTER_ID]: payload?.filterId }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      }
      return params
    }
    case CRM_IDENTIFIERS.productKey: {
      const params = {
        ...Object.fromEntries([...searchParams]),
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        ...(!!payload?.filterId && { [LOOKER_FILTER_KEYS.FILTER_ID]: payload?.filterId }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      }
      return params
    }
    case UNIFIED_VIEW_IDENTIFIERS.productKey: {
      const params = {
        ...Object.fromEntries([...searchParams]),
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        ...(!!payload?.filterId && { [LOOKER_FILTER_KEYS.FILTER_ID]: payload?.filterId }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      }
      return params
    }
    case JOB_SITE_IDENTIFIERS.productKey: {
      const params = {
        ...Object.fromEntries([...searchParams]),
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
        [LOOKER_FILTER_KEYS.SITE_ID]: filters?.[LOOKER_FILTER_KEYS.SITE_ID] || "",
        ...(!!payload?.filterId && { [LOOKER_FILTER_KEYS.FILTER_ID]: payload?.filterId }),
        ...(!!filters?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: filters[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      }
      return params
    }
  }
}

const getRoute = (payload, dashboards) => {
  const dashboardId = payload?.dashboardId
  const report = REPORTS_TABS.find(item => item.title === dashboardId)
  if (!!report) return report.route
  if (dashboardId === dashboards[PROGRAMMATIC_IDENTIFIERS.dashboardMapIdentifierKey]?.id) return PROGRAMMATIC_IDENTIFIERS.route
  return payload.product.route
}

export const filterApiUtil = async ({
  option,
  searchParams,
  payload,
  editFilter,
  deleteFilter,
  dashboards,
  navigate,
  setSearchParams,
}) => {
  switch (option) {
    case "select":
      const params = getFiltersForView(payload, searchParams)
      if (!!payload?.dashboardId) {
        const pathname = getRoute(payload, dashboards)
        navigate({
          pathname,
          search: new URLSearchParams(params).toString()
        });
      } else {
        setSearchParams(params)
      }
      break

    case "edit":
      try {
        await editFilter({
          filterId: payload.filterId,
          payload: {
            ...payload,
            dashboardId: payload?.[LOOKER_FILTER_KEYS.DASHBOARD_ID] || dashboards?.[DASHBOARD_MAP.client]?.id
          }
        });
        alert("View updated successfully")
      } catch (error) {
        const { message } = error;
        console.error("Error when editing view", message)
        alert("Error in editing view", "error")
      }
      break
    case "delete":
      try {
        await deleteFilter({
          filterId: payload.filterId,
          params: {
            [LOOKER_FILTER_KEYS.DASHBOARD_ID]: payload?.[LOOKER_FILTER_KEYS.DASHBOARD_ID] || dashboards?.[DASHBOARD_MAP.client]?.id
          }
        });
        const params = Object.fromEntries([...searchParams])
        if (payload.filterId === params[LOOKER_FILTER_KEYS.FILTER_ID]) {
          delete params[LOOKER_FILTER_KEYS.FILTER_ID]
          setSearchParams(params)
        }
        alert("View deleted successfully")
      } catch (error) {
        const { message } = error;
        console.error("Error when deleting view", message)
        alert("Error in deleting view", "error")
      }
      break

    default:
  }
}
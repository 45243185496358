import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useShallow } from "zustand/react/shallow"

import {
  ViewContainer,
  Selections,
  OptionsContainer,
  ContentContainer,
  EditContainer,
  TextContainer,
  Title
} from "./styles";

import Icon from '../../../common/Icon';
import OptionsList from '../../../common/OptionsList';
import TextInput from '../../../common/TextInput';
import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import Loader from '../../../common/Loader';
import ErrorMessage from '../../../common/ErrorMessage';

import useOnClickOutside from '../../../../utils/useOnClickOutside';
import { getFiltersFromQueryString } from '../../../../utils/helpers';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../../../stores/global';
import { searchHierarchyData } from '../../../../utils/searchHelpers';
import { getAdvancedFiltersFromFilters } from '../../../../pages/Programmatic/helpers';

const OPTIONS = [
  { title: "Edit", icon: "edit" },
  { title: "Delete", icon: "delete" }
]

const View = ({
  view = null,
  isSelected = false,
  loadingStates,
  checkUniqueness,
  onOptionChange,
  searchValue,
}) => {
  const { hierarchy, views } = useGlobalDataStore(useShallow((state) => ({
    hierarchy: state.hierarchy,
    views: state.views,
  })))

  const { currentProduct } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
  })))

  const [isOptionsDropdownOpen, toggleOptionsDropdown] = useState(false)
  const [isEditing, toggleEditing] = useState(false)
  const [textInput, setTextInput] = useState("")
  const [isDeleting, toggleDeleting] = useState(false)
  const [isNameNotUnique, toggleNameUnique] = useState(false)

  const optionsRef = useRef(null)

  const handleOptionSelect = (opt) => {
    switch (opt.title.toLocaleLowerCase()) {
      case "edit":
        toggleEditing(true)
        break

      case "delete":
        onOptionChange("delete", view)
        toggleDeleting(true)
        break

      default:
        onOptionChange("select")
        break
    }
    toggleOptionsDropdown(false)
  }

  useEffect(() => {
    if (isEditing && !!view?.name) {
      setTextInput(view.name)
    }
  }, [isEditing, view])

  useEffect(() => {
    if (!loadingStates?.delete) {
      toggleDeleting(false)
    }
  }, [loadingStates?.delete])

  useEffect(() => {
    if (!!textInput && (view?.name !== textInput)) {
      if (checkUniqueness(textInput)) {
        toggleNameUnique(true)
      } else {
        toggleNameUnique(false)
      }
    } else {
      toggleNameUnique(false)
    }
  }, [textInput])

  useOnClickOutside(optionsRef, () => {
    toggleOptionsDropdown(false)
  });

  const selectionText = useMemo(() => {
    if (!!view?.value) {
      const filterMap = getFiltersFromQueryString(view.value)
      const advancedFilters = getAdvancedFiltersFromFilters(view.filterId, views)
      const selection = searchHierarchyData(hierarchy, filterMap, currentProduct)
      const selText = Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || ""
      return `${selText} ${!!advancedFilters?.length ? `\xa0\xa0\xa0\xa0|\xa0\xa0\xa0\xa0${advancedFilters?.length} filters applied` : ""}`
    }
    return ""
  }, [view?.value, hierarchy, currentProduct, views])

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const renderHighlightedText = (searchText = "", titleText = "") => {
    if (!searchText || searchText?.length <= 2) return titleText;
    const escapedSearchText = escapeRegExp(searchText.toLowerCase());
    const regex = new RegExp(`(${escapedSearchText})`, "gi");
    const parts = titleText.split(regex);
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === escapedSearchText ? <b key={index}>{part}</b> : part
        )}
      </span>
    );
  };

  const disabled = !textInput || isNameNotUnique

  return (
    <ViewContainer
      selected={isSelected}
      onClick={() => {
        if (!isEditing) {
          onOptionChange("select", view)
        }
      }}
    >
      <ContentContainer>
        <Selections title={selectionText}>
          {selectionText}
        </Selections>
        {
          !isEditing && (
            <Title>{renderHighlightedText(searchValue, view?.name) || ""}</Title>
          )
        }
        {
          isEditing && (
            <EditContainer>
              <TextContainer>
                <TextInput
                  className="View__text_input"
                  value={textInput}
                  disabled={!!loadingStates?.edit || !!loadingStates?.delete}
                  onChange={(e) => setTextInput(e.target.value)}
                />
                {
                  isNameNotUnique && (
                    <ErrorMessage text="Name already exists" />
                  )
                }
              </TextContainer>
              {
                !!loadingStates?.edit && (
                  <Loader size="small" />
                )
              }
              {
                !loadingStates?.edit && !loadingStates?.delete && (
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Tooltip text={isNameNotUnique ? "Enter unique view name" : "Submit"}>
                      <Button
                        icon="tick"
                        className="View__edit_button"
                        disabled={disabled}
                        onClick={() => {
                          onOptionChange("edit", { ...view, name: textInput })
                          toggleEditing(false)
                        }}
                      />
                    </Tooltip>
                    <Tooltip text="Cancel">
                      <Button icon="close" className="View__edit_button" onClick={() => toggleEditing(false)} />
                    </Tooltip>
                  </div>
                )
              }
            </EditContainer>
          )
        }
      </ContentContainer>
      {
        (isDeleting && !!loadingStates?.delete) && (
          <Loader size="small" height="40" width="40" />
        )
      }
      {
        !!view?.filterId && (
          <OptionsContainer
            selected={isSelected}
            onClick={(e) => {
              e.stopPropagation()
              toggleOptionsDropdown(!isOptionsDropdownOpen)
            }}
          >
            <Icon name="three-dots" alt="option" />
            {
              isOptionsDropdownOpen && (
                <OptionsList
                  ref={optionsRef}
                  className="View__options"
                  options={OPTIONS}
                  idKey="title"
                  onSelect={handleOptionSelect}
                />
              )
            }
          </OptionsContainer>
        )
      }
    </ViewContainer>
  );
}

export default View;

import { DATE_OPTIONS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from "../../constants/AppConstants";
import { HIRING_FUNNEL_CONFIG_HOME } from "../../configs/Programmatic/HiringFunnel";
import { getModel } from "../../utils/helpers";

export const getLookerFilterWithoutDashboardId = (lookerFilter) => {
  if (!lookerFilter) return {};
  const lookerFilterCopy = { ...lookerFilter };
  delete lookerFilterCopy[LOOKER_FILTER_KEYS.DASHBOARD_ID];
  return lookerFilterCopy;
};

export const LOOKER_KEYS_CONV = {
  "accountId": "account_id",
  "subAccountId": "sub_account_id",
  "agencyId": "agency_id",
  "clientId": "client_id",
  "campaignId": "campaign_id",
  "jobgroupId": "job_group_id",
  "Date": "event_publisher_date"
}

export const getLookFilters = (filters, conversionMap = LOOKER_KEYS_CONV) => {
  const finalFilters = {}
  for (let [key, value] of Object.entries(filters)) {
    if (!!conversionMap[key]) {
      finalFilters[`filter_data.${conversionMap[key]}`] = value
    }
  }
  if (!filters['Date']) {
    const thisMonth = DATE_OPTIONS.find(item => item.key === 'this month');
    finalFilters[`filter_data.event_publisher_date`] = thisMonth.getDateFilterString();
  }
  return finalFilters
}

const getFields = (dbDetails) => {
  const fields = [
    "filter_data.attributes",
    "filter_data.attribute_group",
    "filter_data.attribute_label",
  ];

  if (dbDetails?.dashboardKey === HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey) {
    fields.push("filter_data.value");
  } else {
    fields.push("filter_data.sortBy");
  }

  return fields;
};

export const buildFilterDataPayload = ({
  dbDetails,
  globalFilters,
  models
}) => {
  const sorts =
    dbDetails?.dashboardKey === HIRING_FUNNEL_CONFIG_HOME.viewMoreDbKey
      ? ["filter_data.value desc"]
      : ["filter_data.sortBy", "filter_data.attributes"];
  const payload = {
    model: getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey),
    view: "filter_data",
    fields: getFields(dbDetails?.filterConfigKey),
    pivots: null,
    fill_fields: null,
    filters: {
      ...getLookFilters(globalFilters),
      "filter_data.view_more_filter": dbDetails?.filterConfigKey,
    },
    sorts,
    limit: "100",
    column_limit: "100",
    total: false,
    row_total: null,
    subtotals: null,
  };
  return payload
}

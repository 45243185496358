import React, { forwardRef } from 'react';

import { Button, Label } from '../styles';

import Icon from "../../Icon"

const CustomInput = forwardRef(({ value, label, disabled, onClick }, ref) => (
  <Button onClick={onClick} ref={ref} disabled={disabled}>
    <Icon name="calendar" />
    {label && <Label>{label}</Label>}
    {value || 'Please select a date'}
  </Button>
));

export default CustomInput

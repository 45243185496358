import React, { useMemo } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more.js"
import HSGauge from "highcharts/modules/solid-gauge";

import { constructChart, tickPrototype } from './helpers';

HighchartsMore(Highcharts)
HSGauge(Highcharts)

tickPrototype(Highcharts)

const GaugeChart = ({ value, width, height }) => {
  const chartData = useMemo(() => {
    if (!value) return null

    const options = constructChart(parseInt(value.split("%")[0]), width, height)
    return options
  }, [value])

  if (!value) return null

  if (!!chartData) {
    return (
      <HighchartsReact highcharts={Highcharts} options={chartData} />
    );
  }
}

export default GaugeChart;

import React, { useMemo, useRef, useState } from 'react';

import {
  SearchFilterContainer,
  DropdownSearch,
  SearchInput,
  DropdownOptions,
  Option,
  OptionText,
  EmptySearchResult
} from './styles'

import Icon from "../Icon"
import useOnClickOutside from '../../../utils/useOnClickOutside';

const SearchFilter = ({
  options,
  placeholder,
  idKey,
  valueKey,
  className,
  onSelect,
  onClose,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("")
  const wrapperRef = useRef(null)

  const filteredOptions = useMemo(() => {
    if (!searchValue) return options

    return options.filter(option => option[valueKey].toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  }, [searchValue, options])

  const handleInputChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleOptionClick = (option) => {
    onSelect(option)
  }

  useOnClickOutside(wrapperRef, () => {
    onClose();
  });

  return (
    <SearchFilterContainer className={className} ref={wrapperRef} {...props}>
      <DropdownSearch>
        <Icon name="search" className="SearchImg" />
        <SearchInput
          type="text"
          placeholder={placeholder}
          value={searchValue}
          onChange={handleInputChange}
        />
      </DropdownSearch>
      <DropdownOptions>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <Option
              key={JSON.stringify(option)}
              onClick={() => handleOptionClick(option)}
            >
              <OptionText>{option[valueKey]}</OptionText>
            </Option>
          ))
        ) : (
          <EmptySearchResult>No results found</EmptySearchResult>
        )}
      </DropdownOptions>
    </SearchFilterContainer>
  );
}

export default SearchFilter;
import { useEffect, useMemo, useRef, useState } from "react";

import {
  CountryFilterContainer,
  Selection,
  SelectionText,
  Content
} from "./styles";

import Icon from "../../../components/common/Icon";
import MSASelection from "./MSASelection";
import SearchMSA from "./SearchMSA";
import SearchBox from "../../../components/common/SearchBox";

import {
  getCountries,
  getCountryMSAMetadata,
  getMSAs
} from "./helpers";
import useOnClickOutside from "../../../utils/useOnClickOutside";

const CountryFilter = ({
  locations,
  selected,
  onChange
}) => {
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selections, setSelections] = useState({
    country: null,
    msa: null
  });
  const [currentEntity, setCurrentEntity] = useState("country");

  const contentRef = useRef(null);

  const countryMSAMetadata = useMemo(() => {
    if (!locations?.length || (!selections.country && !selections.msa)) return [];
    const metadata = getCountryMSAMetadata({
      locations,
      selections,
      setSelections,
      setCurrentEntity,
      setSearchText,
      onChange: (id) => {
        onChange(id)
        toggleDropdown(false)
      }
    });
    return metadata;
  }, [
    locations,
    setCurrentEntity,
    selections,
    onChange,
    setCurrentEntity,
    setSearchText,
    setSelections
  ]);

  const searchResults = useMemo(() => {
    if (!searchText) return [];
    return locations?.filter((item) => {
      return item.value.toLowerCase().includes(searchText.toLowerCase());
    })
  }, [locations, searchText])

  const countries = useMemo(() => {
    return getCountries(locations)
  }, [locations])

  const msas = useMemo(() => {
    return getMSAs(locations)
  }, [locations])

  useEffect(() => {
    if (locations?.length > 1 && !!countries?.length && !!msas?.length) {
      setSelections(sel => {
        if (!!selected) {
          const msa = msas.find(item => item.id === selected)
          const country = countries.find(item => item.id === msa?.countryLabel)
          return {
            country,
            msa
          }
        }
        return sel;
      })
    }
  }, [locations, selected, countries, msas, setSelections])

  useOnClickOutside(contentRef, () => {
    setSearchText("")
    toggleDropdown(false)
    if (!selections.country || !selections.msa) {
      const msa = msas.find(item => item.id === selected)
      const country = countries.find(item => item.id === msa?.countryLabel)
      setSelections({
        country,
        msa
      })
    }
  })

  return (
    <CountryFilterContainer ref={contentRef}>
      <Selection
        active={isDropdownOpen}
        onClick={() => {
          toggleDropdown(!isDropdownOpen)
          setSearchText("")
        }}
      >
        <SelectionText>
          <>
            <strong>{`Location`} :&nbsp;</strong>
            <span>{selections.msa?.value}</span>
          </>
        </SelectionText>
        <Icon name="arrow-down-solid" />
      </Selection>
      {
        isDropdownOpen && (
          <Content>
            <SearchBox
              placeholder="Search"
              onChange={setSearchText}
            />
            {
              !!searchText ? (
                <SearchMSA
                  className="search-msa"
                  results={searchResults}
                  selected={selections.msa?.id}
                  onSelect={(option) => {
                    setSelections({
                      country: {
                        id: option.countryLabel,
                        value: option.countryLabel
                      },
                      msa: option
                    })
                    onChange(option.id)
                    toggleDropdown(false)
                  }}
                />
              ) : (
                <MSASelection
                  metadata={Object.values(countryMSAMetadata || {})}
                  currentEntity={currentEntity}
                  setCurrentEntity={setCurrentEntity}
                  className="msaselection"
                />
              )
            }
          </Content>
        )
      }
    </CountryFilterContainer>
  );
}

export default CountryFilter;

import axios from "axios";

import {
  cleanupAllCookies,
  logoutAndInvalidate,
  logoutAndValidate,
  logoutFromUAApp,
  refreshAndRefetchAccessToken,
} from "./authHelper";
import { getItemFromLocalStorage, setItemInLocalStorage } from "./commonHelper";
import {
  ACCESS_TOKEN_LS_KEY,
  LOOKER_ACCESS_TOKEN_LS_KEY,
  REFRESH_TOKEN_LS_KEY,
} from "../constants/AppConstants";

let LOOKER_URL = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.LOOKER_URL
  : "https://looker.analytics.joveo.com";
LOOKER_URL = LOOKER_URL.startsWith("https")
  ? LOOKER_URL
  : `https://${LOOKER_URL}`;

export const userClient = axios.create({
  baseURL: "/user/v1",
  headers: {
    refreshToken: getItemFromLocalStorage(REFRESH_TOKEN_LS_KEY),
  },
});

export const unifiedServiceClient = axios.create({
  baseURL: "/unified-service/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const unifiedAnalyticsClient = axios.create({
  baseURL: "/unified-analytics/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const campaignManagementClient = axios.create({
  baseURL: "/campaign-management/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const fnaDashboardClient = axios.create({
  baseURL: "/fna-dashboard/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const lookerApiClient = axios.create({
  baseURL: `${LOOKER_URL}/api/4.0`,
});

export const jobsClient = axios.create({
  baseURL: `/jobs-api/api/v1`,
  headers: {
    AccessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const publisherManagementClient = axios.create({
  baseURL: "/publisher-management/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const jobLocationServiceClient = axios.create({
  baseURL: "/job-scoring-service/v1",
  headers: {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
  },
});

export const jobFeedMappingClient = axios.create({
  baseURL: "https://api.staging.joveo.com/",
});

export const mediaPlannerClient = axios.create({
  baseURL: "/sales-media-planner/api/v1",
});

export const dsLocationClient = axios.create({
  baseURL: "/get_countries_code",
});

userClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status) {
      logoutAndValidate();
    }
    return Promise.reject(error);
  }
);

unifiedAnalyticsClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

unifiedServiceClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

campaignManagementClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

publisherManagementClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

fnaDashboardClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

jobsClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      refreshAndRefetchAccessToken();
    }
    if (error.response && error.response.status === 413) {
      cleanupAllCookies();
      logoutFromUAApp();
    }
    return Promise.reject(error);
  }
);

lookerApiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    if (response.status === 401) {
      return axios
        .get("/unified-analytics/v1/user", {
          headers: {
            accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
          },
        })
        .then((tokenRes) => {
          setItemInLocalStorage(
            LOOKER_ACCESS_TOKEN_LS_KEY,
            tokenRes?.data?.authToken
          );
          config.headers["Authorization"] = tokenRes?.data?.authToken;
          return lookerApiClient(config);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            refreshAndRefetchAccessToken();
          }
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

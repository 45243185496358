import styled from "styled-components";

export const StyledModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 600px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  border-radius: 24px 24px 0px 0px;
  opacity: var(--State, 1);
  z-index: 4;
  background: #F9FAFB;
`;

export const StyledModalContent = styled.div`
  padding: 1rem 1rem 0.5rem;
  background-color: #F9FAFB;
  height: 95%;
`;

export const StyledBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

export const HeaderBarContainer = styled.div`
  position: relative;
`;

export const HeaderBar = styled.span`
  width: 50px;
  height: 4px;
  background: #cbd5e1;
  display: block;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BottomBarContainer = styled.div`
  position: fixed;
  bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const BottomBar = styled.span`
  width: 134px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--Gray-10, #0f172a);
  display: block;
`;

import React, { useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useSearchParams } from "react-router-dom";

import { CRMContainer, EmptyContainer, ContentContainer, HeaderContainer } from "./styles";

import Subheader from "../../components/common/Subheader";
import EmbeddedDashboard from "../../components/EmbeddedDashboard";
import Loader from "../../components/common/Loader";
import Datepicker from "../../components/common/Datepicker";
import Upgrade from "../Upgrade";

import Mixpanel from "../../services/MixPanel";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { useFetchCampaigns, useFetchLocations } from "../../api/crm";
import {
  buildCampaignListPayload,
  buildCountryListPayload,
} from "./apiHelpers";
import { CRM_IDENTIFIERS, DATE_OPTIONS, LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import { parsePayloadFilters } from "./helpers";
import { getSelectedEntityName, searchHierarchyAcrossProducts, searchHierarchyData } from "../../utils/searchHelpers";
import { onTrackMixPanelEvent } from "../../helpers/eventHandler";

const CRM = ({ localWidgetFilters }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isDbLoading, toggleDbLoad] = useState(false);

  const params = Object.fromEntries([...searchParams]);

  const { hierarchy, models, products } = useGlobalDataStore(
    useShallow((state) => ({
      hierarchy: state.hierarchy,
      models: state.models,
      products: state.products,
    }))
  );

  const {
    globalFilters,
    embedUrl,
  } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      embedUrl: state.embedUrl,
    }))
  );

  const { data: { data: campaigns } = [], isLoading: isCampaignsLoading } =
    useFetchCampaigns(
      buildCampaignListPayload(params, models),
      parsePayloadFilters(params)
    );

  const { data: { data: locations } = [], isLoading: isLocationsLoading } =
    useFetchLocations(
      buildCountryListPayload(params, models),
      parsePayloadFilters(params)
    );

  const combinedFilters = useMemo(() => {
    return {
      ...localWidgetFilters,
      ...(!!campaigns?.length && {
        campaigns: JSON.stringify(campaigns),
      }),
      ...(!!locations?.length && {
        locations: JSON.stringify(locations),
      }),
    };
  }, [localWidgetFilters, campaigns, locations]);

  useEffect(() => {
    Mixpanel.trackEvent("CRM Analytics Opened", {});
  }, []);

  const isLoading = !products?.length || !hierarchy;

  const isLocked = !products?.length ||
    !hierarchy ||
    !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
    !products.find(item => item.productKey === CRM_IDENTIFIERS.productKey) ||
    !hierarchy?.[CRM_IDENTIFIERS.productKey]?.find(item => item?.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]);

  if (isLocked && !isLoading) {
    const results = searchHierarchyAcrossProducts(hierarchy, globalFilters)
    const entity = results?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || results?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
    return (
      <Upgrade
        entity={entity}
        {...CRM_IDENTIFIERS.unlockDetails}
      />
    )
  }

  return (
    <CRMContainer>
      <HeaderContainer>
        <Subheader
          title="CRM"
          entity={getSelectedEntityName(hierarchy, globalFilters, CRM_IDENTIFIERS).name}
          entityType={getSelectedEntityName(hierarchy, globalFilters, CRM_IDENTIFIERS).type}
          filtersDisabled={isDbLoading}
        />
      </HeaderContainer>
      <ContentContainer>
        {
          (!!embedUrl && !isLoading && !isLocked) && (
            <EmbeddedDashboard
              embedURL={embedUrl}
              lookerFilter={globalFilters}
              localWidgetFilters={combinedFilters}
              onDbLoad={toggleDbLoad}
            />
          )
        }
        {(isLoading || isCampaignsLoading || isLocationsLoading) && (
          <EmptyContainer>
            <Loader size="medium" />
          </EmptyContainer>
        )}
      </ContentContainer>
    </CRMContainer>
  );
};

export default CRM;

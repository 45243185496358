import styled from "styled-components";

export const RoadmapCardContainer = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  width: 1020px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
`;

export const RoadmapCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const LikeDislikeContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Status = styled.div`
  width: 84px;
  height: 24px;
  display: flex;
  flex-shrink: 0;
  border-radius: 15px;
  background: ${(props) =>
    props?.status === "UPCOMING" ? "#7681E8" : "#85CB8B"};
  color: var(--neutral-white, #fff);
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`;

export const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

export const RoadmapContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentImage = styled.img`
  max-width: 540px;
  border-radius: 4px;
`;

export const Title = styled.div`
  color: #303f9f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Description = styled.div`
  color: #3d4759;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ul {
    margin: 0; /* Remove extra margin around the list */
    padding-left: 14px; /* Ensure space for bullet points */
    list-style-position: outside; /* Align bullets outside the text */
  }
`;

export const LearnMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  margin-top: 16px;
`;

export const LearnMoreButton = styled.div`
  color: #283593;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const RoadmapDate = styled.div`
  color: #718096;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 2px;
`;

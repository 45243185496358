import React from 'react';

import {
  ScoreIndicator,
  Banner
} from "./styles"

import Icon from "../Icon"

const ScoreBanner = ({
  originalScore = "",
  optmisedScore = "",
  type = "original",
  length = "short",
  ...props
}) => {
  const ogScore = originalScore.split("%")[0]
  const optScore = optmisedScore.split("%")[0]

  if (type === "original") {
    return <ScoreIndicator type={type} {...props}>
      {
        length === "short" ? "Original score" : <Banner type={type} position={ogScore >= 90 ? "right" : "center"}>Quality score: {originalScore}</Banner>
      }
      <Icon
        name={length === "long" ? "originalScoreLong" : "originalScore"}
        className={ogScore >= 90 ? "Position__right" : ""}
        alt="score banner"
      />
    </ScoreIndicator>
  }
  if (type === "optimised") {
    return <ScoreIndicator type={type} length={length} {...props}>
      {
        length === "short" ? "Optimal score" : (
          <Banner type={type} position={optScore >= 90 ? "right" : "center"}>
            <Icon name="optimiseWhite" alt="optmised" />
            Optimised score: {optmisedScore}
          </Banner>
        )
      }
      <Icon
        name={length === "long" ? "optimisedScoreLong" : "optimisedScore"}
        className={optScore >= 90 ? "Position__right" : ""}
        alt="score banner"
      />
    </ScoreIndicator>
  }
}

export default ScoreBanner;

import styled from "styled-components";

export const WeeklyAxisLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Text = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: #3D4759;
`;
export const SubText = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
  color: #718096;
`;
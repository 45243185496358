import { CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, JOB_SITE_IDENTIFIERS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from "../../constants/AppConstants"
import { REPORTS_TABS } from "../../constants/ReportsConstants"

const searchViews = (views, searchValue, product, dashboards) => {
  let results = []
  const reportTitles = REPORTS_TABS.map(item => item.title)
  const dashboardId = dashboards[product.dashboardMapIdentifierKey].id
  const productViews = views?.filter(item => {
    if (product.productKey === PROGRAMMATIC_IDENTIFIERS.productKey) {
      if (reportTitles.includes(item.dashboardId)) {
        return true
      }
    }
    return item.dashboardId === dashboardId
  })
  const viewResults = productViews
    ?.filter(item => item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    ?.map(item => ({ ...item, product }))
  if (!!viewResults?.length) {
    results.push(...viewResults)
  }
  return results
}

const searchProgrammatic = (hierarchyData, searchValue, filters) => {
  let results = []

  for (const account of hierarchyData) {
    const isValidAccount = filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === account.id
    if (isValidAccount) {
      const filters = {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id
      }
      // results.push({ name: account.name, value: new URLSearchParams(filters).toString() })
    }
    if (!isValidAccount && !!filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) continue
    const subAccounts = account.subAccounts
    if (!!subAccounts?.length) {
      for (const subAccount of subAccounts) {
        const isValidSubAccount = filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === subAccount.id
        if (isValidSubAccount) {
          const filters = {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
          }
          // results.push({ name: subAccount.name, value: new URLSearchParams(filters).toString() })
        }
        if (!isValidSubAccount && !!filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) continue
        const clients = subAccount.clients
        if (!!clients?.length) {
          for (const client of clients) {
            if (client.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
              const filters = {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
              }
              results.push({ name: client.name, value: new URLSearchParams(filters).toString() })
            }
            const campaigns = client.campaigns
            if (!!campaigns?.length) {
              for (const campaign of campaigns) {
                if (campaign.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                  const filters = {
                    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                    [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
                    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.id
                  }
                  results.push({ name: campaign.name, value: new URLSearchParams(filters).toString() })
                }
                const jobgroups = campaign.jobGroups
                if (!!jobgroups?.length) {
                  for (const jobgroup of jobgroups) {
                    if (jobgroup.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                      const filters = {
                        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                        [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
                        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.id,
                        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobgroup.id
                      }
                      results.push({ name: jobgroup.name, value: new URLSearchParams(filters).toString() })
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return results.map(item => ({ ...item, product: PROGRAMMATIC_IDENTIFIERS }))
}
const searchCareerSites = (hierarchyData, searchValue, filters) => {
  let results = []

  for (const account of hierarchyData) {
    const isValidAccount = filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === account.id
    if (isValidAccount) {
      const filters = {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id
      }
      // results.push({ name: account.name, value: new URLSearchParams(filters).toString() })
    }
    if (!isValidAccount && !!filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) continue
    const subAccounts = account.subAccounts
    if (!!subAccounts?.length) {
      for (const subAccount of subAccounts) {
        const isValidSubAccount = filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === subAccount.id
        if (isValidSubAccount) {
          const filters = {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
          }
          // results.push({ name: subAccount.name, value: new URLSearchParams(filters).toString() })
        }
        if (!isValidSubAccount && !!filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) continue
        const sites = subAccount.sites
        if (!!sites?.length) {
          for (const site of sites) {
            if (site.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
              const filters = {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                [LOOKER_FILTER_KEYS.SITE_ID]: site.id
              }
              results.push({ name: site.name, value: new URLSearchParams(filters).toString() })
            }
          }
        }
      }
    }
  }
  return results.map(item => ({ ...item, product: CAREER_SITE_IDENTIFIERS }))
}

const searchCRM = (hierarchyData, searchValue) => {
  let results = []
  const accounts = hierarchyData?.filter(item => item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  if (!!accounts?.length) {
    results = [...results, ...accounts.map(item => ({
      name: item.name, value: new URLSearchParams({ [LOOKER_FILTER_KEYS.ACCOUNT_ID]: item.id }).toString()
    }))]
  }

  for (const account of hierarchyData) {
    const subAccounts = account.subAccounts
    if (!!subAccounts?.length) {
      for (const subAccount of subAccounts) {
        if (subAccount.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
          const filters = {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
          }
          results.push({ name: subAccount.name, value: new URLSearchParams(filters).toString() })
        }
        const sites = subAccount.sites
        if (!!sites?.length) {
          for (const site of sites) {
            if (site.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
              const filters = {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                [LOOKER_FILTER_KEYS.SITE_ID]: site.id
              }
              results.push({ name: site.name, value: new URLSearchParams(filters).toString() })
            }
          }
        }
      }
    }
  }
  return results.map(item => ({ ...item, product: CRM_IDENTIFIERS }))
}

const searchUnifiedView = (hierarchyData, searchValue) => {
  return []
}

const searchJobSite = (hierarchyData, searchValue, filters) => {
  let results = []

  for (const account of hierarchyData) {
    const isValidAccount = filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === account.id
    if (isValidAccount) {
      const filters = {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id
      }
      // results.push({ name: account.name, value: new URLSearchParams(filters).toString() })
    }
    if (!isValidAccount && !!filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) continue
    const subAccounts = account.subAccounts
    if (!!subAccounts?.length) {
      for (const subAccount of subAccounts) {
        const isValidSubAccount = filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === subAccount.id
        if (isValidSubAccount) {
          const filters = {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
          }
          // results.push({ name: subAccount.name, value: new URLSearchParams(filters).toString() })
        }
        if (!isValidSubAccount && !!filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) continue
        const sites = subAccount.sites
        if (!!sites?.length) {
          for (const site of sites) {
            if (site.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
              const filters = {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                [LOOKER_FILTER_KEYS.SITE_ID]: site.id
              }
              results.push({ name: site.name, value: new URLSearchParams(filters).toString() })
            }
          }
        }
      }
    }
  }
  return results.map(item => ({ ...item, product: CAREER_SITE_IDENTIFIERS }))
}

export const searchHierarchyAndViews = ({ hierarchyData, views = [], searchValue = "", product, dashboards, filters }) => {
  if (!product?.productKey || !hierarchyData) return []

  const hierarchy = hierarchyData[product.productKey]

  let results = []
  results = searchViews(views, searchValue, product, dashboards)

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return [...results, ...searchProgrammatic(hierarchy, searchValue, filters)]
    case CAREER_SITE_IDENTIFIERS.productKey:
      return [...results, ...searchCareerSites(hierarchy, searchValue, filters)]
    case CRM_IDENTIFIERS.productKey:
      return [...results, ...searchCRM(hierarchy, searchValue, filters)]
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return [...results, ...searchUnifiedView(hierarchy, searchValue, filters)]
    case JOB_SITE_IDENTIFIERS.productKey:
      return [...results, ...searchJobSite(hierarchy, searchValue, filters)]
    default:
      return results
  }
}

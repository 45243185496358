import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import {
  useSearchParams,
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

import {
  Wrapper,
  MainAppContainer,
  EmptyContainer,
  ContentContainer,
} from "./styles";

import NavBar from "../NavBar";
import MainContent from "../MainContent";
import Loader from "../common/Loader";
import Sidebar from "../common/Sidebar";
import HamburgerMenu from "../common/HamburgerMenu";
import ErrorPage from "../../pages/Error";
import MarketInsights from "../../pages/MarketInsights";
import CRM from "../../pages/CRM";
import UnifiedView from "../../pages/UnifiedView";
import Schedules from "../../pages/Schedules";
import CareerAndJobSite from "../../pages/CareerAndJobSite";

import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../helpers/commonHelper";

import {
  hasGlobalFiltersChanged,
  processHierarchyData,
  hasFilterBeenChangedOrApplied,
  doesDefaultFilterExist,
  hasFiltersChangedInSavedFilter,
  buildEmbedUrlParams,
  buildAccessTokenPayload,
  handleWindowEvents,
  getWebViewDashboardList,
  getMobileViewDashboardList,
  hasFilterLevelChanged,
  getWeekStartDay,
  getApiIdentifierKey,
} from "./helpers";
import {
  LOOKER_FILTER_KEYS,
  LOOKER_FILTER_INITIAL_VALUES,
  DASHBOARD_MAP,
  USERNAME_LS_KEY,
  TIMEZONE_LS_KEY,
  PRODUCTS_MAP,
  COMMON_GLOBAL_FILTERS,
  PROGRAMMATIC_IDENTIFIERS,
  CAREER_SITE_IDENTIFIERS,
  CRM_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS,
  CURRENCY_KEYS,
  JOB_SITE_IDENTIFIERS,
  CAREER_AND_JOB_SITE_IDENTIFIERS,
  SEEN_ROADMAP_FEATURES_LS_KEY,
  SIDEBAR_EXPANSION_LS_KEY,
  COMPETITOR_INSIGHTS_IDENTIFIERS,
  MARKET_INSIGHTS_IDENTIFIERS,
  EXCHANGE_VISIBILITY_LS_KEY,
  EXCHANGE_VISIBILITY_VALUES,
} from "../../constants/AppConstants";
import { useFetchCIFFields } from "../../api/reports";
import { useFetchFilters } from "../../api/filters";
import { filterApiUtil } from "../NavBar/helpers";
import { getAdvancedFiltersFromFilters } from "../../pages/Programmatic/helpers";
import { toFilterExpression } from "../../utils/helpers";
import {
  UPGRADE_ROUTE,
  CAREER_SITE_ROUTE,
  CRM_ROUTE,
  EMBED_URL_REFRETCH_ROUTES,
  ERROR_ROUTE,
  MARKET_INSIGHTS_ROUTE,
  MARKET_INSIGHTS_ROUTE_IDENTIFIER,
  NOT_FOUND_ROUTE,
  PROGRAMMATIC_ROUTE,
  SCHEDULES_ROUTE,
  UNIFIED_VIEW_ROUTE,
  JOB_SITE_ROUTE,
  ROADMAP_ROUTE,
  COMPETITOR_INSIGHTS_ROUTE,
  SMP_JOB_INGESTION_ROUTE_IDENTIFIER,
  MEDIA_PLANNER_ROUTE_IDENTIFIER,
  MEDIA_PLANNER_ROUTE,
} from "../../constants/AppRoutes";
import {
  useFetchAccessToken,
  useFetchEmbeddedUrl,
  useFetchHierarchies,
  useFetchLookerAccessToken,
  useFetchProducts,
  useFetchUserMetadata,
} from "../../api/common";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import Mixpanel from "../../services/MixPanel";
import { getSections } from "../common/Sidebar/helpers";
import Roadmap from "../../pages/Roadmap";
import { useFetchRoadmapFeatures } from "../../api/roadmap";
import InsightSidebar from "../InsightSidebar";

const JobFeedInjestion = lazy(() => import("../../pages/JobFeedInjestion"));
const MediaPlanner = lazy(() => import("../../modules/mediaplanner"));
const usePrevLocation = (location) => {
  const prevLocRef = useRef(location);
  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

function MainApp() {
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();
  console.log(location, "location");
  const {
    products,
    setProducts,
    models,
    setModels,
    dashboards,
    setDashboards,
    additionalDashboards,
    setAdditionalDashboards,
    hierarchy,
    views,
    weekStartForAccounts,
    setHierarchy,
    setViews,
    setTimezone,
    setUsername,
    setPublisherFilter,
    setPrimaryCurrency,
    setExchangeVisibility,
    setShowMarketInsights,
    setShowCompetitorInsights,
    setWeekStart,
    setAccountCount,
    setWeekStartForAccounts,
    setIsDemoDashboard,
    setDetailedInsightsFigmaLinks,
  } = useGlobalDataStore();

  const {
    globalFilters,
    setGlobalFilters,
    currentProduct,
    setCurrentProduct,
    embedUrl,
    setEmbedUrl,
    viewMode,
    setViewMode,
  } = useGlobalFiltersStore();

  const prevLocation = usePrevLocation(location);
  const [lookerFilter, setLookerFilter] = useState(null);
  const [localWidgetFilters, setLocalWidgetFilters] = useState(null);
  const [viewMoreDb, setViewMoreDb] = useState(null);
  const [jobGroupDetails, setJobGroupDetails] = useState(null);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [dbDetails, setDbDetails] = useState(null);
  const [currentDashboardId, setCurrentDashboardId] = useState(null);
  const [isDefaultFilterApplied, toggleDefaultFilterApplied] = useState(false);
  const [roadmapIframeDetails, setRoadmapIframeDetails] = useState(null);
  const [seenRoadmapFeatures, setSeenRoadmapFeatures] = useState([]);
  const [unseenRoadmapFeaturesCount, setUnseenRoadmapFeaturesCount] =
    useState(0);
  const [isSidebarExpanded, toggleSidebarExpanded] = useState(false);
  const prevFilters = useRef(null);
  const [showInsightSidebar, setShowInsightSidebar] = useState(false);

  useFetchAccessToken(buildAccessTokenPayload());

  const { data: { data: lookerAccessToken } = {} } =
    useFetchLookerAccessToken();

  const {
    data: { data: productsRes = [] } = {},
    isError: productsError,
    status: productsStatus,
    isLoading: isProductsLoading,
  } = useFetchProducts();

  const {
    data: { data: userMetadata = null } = {},
    isLoading: isUserMetadataLoading,
  } = useFetchUserMetadata();

  const {
    data: { data: embeddedUrl } = {},
    isFetching: isEmbedUrlLoading,
    isError: isEmbedUrlError,
    refetch: refetchEmbedUrl,
    status,
  } = useFetchEmbeddedUrl(
    getApiIdentifierKey(currentProduct),
    buildEmbedUrlParams(currentProduct, Object.fromEntries([...searchParams]))
  );

  const hierarchyRes = useFetchHierarchies(products);

  const { data: { data: filtersData = [] } = {}, isLoading: isFiltersLoading } =
    useFetchFilters();

  useFetchCIFFields(globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID]);

  const isRoadmapRoute = location.pathname === ROADMAP_ROUTE;
  const isRoadmapFeatureEnabled = !!userMetadata?.showRoadmapFeature;

  const { data: roadmapFeatures } = useFetchRoadmapFeatures({
    disable: !isRoadmapFeatureEnabled,
  });

  const isInsightsFeatureEnabled =
    !!userMetadata?.showInsights && location.pathname === PROGRAMMATIC_ROUTE;
  const openInsightSidebar = () => {
    if (isInsightsFeatureEnabled) {
      setShowInsightSidebar(true);
    }
  };
  const isMediaPlannerRoute = location.pathname.includes(
    MEDIA_PLANNER_ROUTE_IDENTIFIER
  );
  useEffect(() => {
    const roadmapFeatureIds = roadmapFeatures?.data?.content?.map(
      (item) => item.id
    );
    const seenRoadmapFeatures =
      JSON.parse(getItemFromLocalStorage(SEEN_ROADMAP_FEATURES_LS_KEY)) || [];
    setUnseenRoadmapFeaturesCount(
      roadmapFeatureIds?.filter((id) => !seenRoadmapFeatures.includes(id))
        .length
    );
  }, [roadmapFeatures, seenRoadmapFeatures]);

  useEffect(() => {
    setGlobalFilters(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (!!products?.length) {
      const currentRoute = products.find(
        (item) => location.pathname === item.route
      );
      const params = Object.fromEntries([...searchParams]);
      if (!!currentRoute) {
        if (!currentProduct) {
          setCurrentProduct(currentRoute);
          return;
        }
        setCurrentProduct(currentRoute);
        if (
          !Object.keys(params || {})?.length ||
          !params[LOOKER_FILTER_KEYS.DASHBOARD_ID]
        ) {
          if (!!dashboards) {
            setSearchParams({
              ...params,
              [LOOKER_FILTER_KEYS.DASHBOARD_ID]:
                dashboards?.[currentRoute.dashboardMapIdentifierKey]?.id || "",
            });
          }
          return;
        }
      } else {
        const sections = [...getSections(additionalDashboards)];
        const selected = sections
          .flatMap((item) => item.options)
          .find((item) => {
            if (!!item?.isActive) return item.isActive(location);
            return true;
          });
        if (!currentProduct) {
          setCurrentProduct(
            selected?.route === MARKET_INSIGHTS_ROUTE
              ? location.pathname.includes(COMPETITOR_INSIGHTS_ROUTE)
                ? { ...selected, ...COMPETITOR_INSIGHTS_IDENTIFIERS }
                : { ...selected, ...MARKET_INSIGHTS_IDENTIFIERS }
              : selected
          );
        } else if (selected?.title !== currentProduct?.title) {
          setCurrentProduct(selected);
        }
      }
    }
  }, [
    searchParams,
    location,
    products,
    globalFilters,
    dashboards,
    currentProduct,
    setSearchParams,
  ]);

  useEffect(() => {
    if (!!userMetadata && !models) {
      setDashboards(userMetadata.dashboards);
      setModels(userMetadata.models);
      setPrimaryCurrency(userMetadata.primaryCurrency.toLowerCase());
      setTimezone(userMetadata.timezone);
      setItemInLocalStorage(TIMEZONE_LS_KEY, userMetadata.timezone);
      setPublisherFilter(userMetadata.showPublisherFilter);
      setExchangeVisibility(userMetadata.exchangeVisibility);
      setAdditionalDashboards(userMetadata.additionalDashboards);
      setShowMarketInsights(userMetadata?.showMarketInsights);
      setShowCompetitorInsights(userMetadata?.showCompetitorInsights);
      setAccountCount(userMetadata?.accountCount || 0);
      setWeekStartForAccounts(userMetadata?.weekStartDays);
      setIsDemoDashboard(!!userMetadata?.isDemoDashboard);
      setDetailedInsightsFigmaLinks(
        userMetadata?.detailedInsightsFigmaLinks || {}
      );
    }
    if (
      !!userMetadata &&
      (userMetadata?.primaryCurrency.toLowerCase() !== CURRENCY_KEYS.DOLLAR ||
        userMetadata.exchangeVisibility === EXCHANGE_VISIBILITY_VALUES.OPEN)
    ) {
      setSearchParams((prev) => {
        const params = Object.fromEntries([...prev]);
        const jaxValue = getItemFromLocalStorage(EXCHANGE_VISIBILITY_LS_KEY);
        const jaxValueFromParams =
          params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY];
        if (!jaxValueFromParams) {
          params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] =
            jaxValue || EXCHANGE_VISIBILITY_VALUES.CLOSE;
        }
        return !!params[LOOKER_FILTER_KEYS.CURRENCY_RATE]
          ? params
          : {
              ...params,
              [LOOKER_FILTER_KEYS.CURRENCY_RATE]:
                userMetadata.primaryCurrency.toLowerCase(),
            };
      });
    }
  }, [userMetadata, setSearchParams, models]);

  useEffect(() => {
    if (!embedUrl) {
      if (!!currentProduct) {
        if (!!Object.keys(dashboards || {})?.length) {
          if (EMBED_URL_REFRETCH_ROUTES.includes(location.pathname)) {
            const dashboardId = searchParams.get(
              LOOKER_FILTER_KEYS.DASHBOARD_ID
            );
            if (!!dashboardId) {
              if (
                currentProduct.productKey ===
                PROGRAMMATIC_IDENTIFIERS.productKey
              ) {
                refetchEmbedUrl({ throwOnError: true, cancelRefetch: true });
              } else if (
                dashboards?.[currentProduct.dashboardMapIdentifierKey]?.id ===
                dashboardId
              ) {
                refetchEmbedUrl({ throwOnError: true, cancelRefetch: true });
              }
            }
          }
        }
      }
    }
  }, [location.pathname, currentProduct, searchParams, dashboards, embedUrl]);

  useEffect(() => {
    if (!!lookerAccessToken?.userName) {
      setUsername(lookerAccessToken.userName);
    } else if (getItemFromLocalStorage(USERNAME_LS_KEY)) {
      setUsername(getItemFromLocalStorage(USERNAME_LS_KEY));
    }
  }, [lookerAccessToken]);

  useEffect(() => {
    if (productsStatus === "success") {
      if (!!productsError) {
        navigate(NOT_FOUND_ROUTE);
      } else if (!!productsRes?.length) {
        setProducts(
          productsRes
            .filter((item) => !!PRODUCTS_MAP[item])
            .map((item) => PRODUCTS_MAP[item])
        );
      } else if (!productsRes?.length) {
        navigate(NOT_FOUND_ROUTE);
      }
    }
  }, [productsRes, productsError, productsStatus]);

  useEffect(() => {
    if (!!isEmbedUrlError) {
      navigate("/error");
    } else if (!!embeddedUrl) {
      setEmbedUrl(embeddedUrl.embedUrl);
    }
  }, [embeddedUrl, isEmbedUrlError]);

  useEffect(() => {
    const isValid =
      !!hierarchyRes?.length &&
      hierarchyRes.every((item) => item.status === "success") &&
      hierarchyRes.some((item) => !!item?.data?.data?.data?.length) &&
      !hierarchy;
    if (isValid) {
      const processedData = processHierarchyData(hierarchyRes, products);
      setHierarchy(processedData);
    }
  }, [hierarchyRes, products, hierarchy]);

  useEffect(() => {
    if (!!filtersData?.length) {
      setViews(filtersData);
    }
  }, [filtersData]);

  useEffect(() => {
    if (!dashboards || !views || isFiltersLoading || !currentProduct) return;

    const params = Object.fromEntries([...searchParams]);
    if (!Object.keys(params).length) return;
    console.log("Search params in main app", params);

    let dbs = Object.values(dashboards).map((item) => item.id);
    if (!!additionalDashboards?.length) {
      dbs.push(...additionalDashboards.map((item) => item.id));
    }
    const dbId = !!params[LOOKER_FILTER_KEYS.DASHBOARD_ID]
      ? decodeURIComponent(params[LOOKER_FILTER_KEYS.DASHBOARD_ID])
      : "";
    const isCorrectDbInParams = dbs.includes(dbId);
    if (!isCorrectDbInParams) {
      const dashboardId = (() => {
        if (location.pathname.includes(MARKET_INSIGHTS_ROUTE))
          return dashboards?.[DASHBOARD_MAP.marketInsights].id;
        if (location.pathname.includes(COMPETITOR_INSIGHTS_ROUTE))
          return dashboards?.[DASHBOARD_MAP.competitorInsights].id;
        return dashboards?.[DASHBOARD_MAP.client].id;
      })();
      setSearchParams({
        ...params,
        [LOOKER_FILTER_KEYS.DASHBOARD_ID]: dashboardId,
      });
      return;
    }

    const defaultFilter = doesDefaultFilterExist(views, location);
    if (
      !!defaultFilter &&
      !params[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
      !isDefaultFilterApplied
    ) {
      filterApiUtil({
        option: "select",
        payload: defaultFilter,
        navigate,
        searchParams,
      });
      toggleDefaultFilterApplied(true);
      return;
    }

    // const filterWithIdInParams = doesFilterIdExist(views, params[LOOKER_FILTER_KEYS.FILTER_ID], location)
    // if (!!params[LOOKER_FILTER_KEYS.FILTER_ID] && !filterWithIdInParams) {
    //   console.log("Clearing filter id in main app")
    //   const paramsCopy = { ...params }
    //   delete paramsCopy[LOOKER_FILTER_KEYS.FILTER_ID]
    //   setSearchParams(paramsCopy)
    //   return
    // }

    setLookerFilter({
      ...LOOKER_FILTER_INITIAL_VALUES,
      ...params,
      theme:
        params[LOOKER_FILTER_KEYS.CUSTOM_DASHBOARD] === "true"
          ? "custom_dashboard_theme"
          : "",
    });
    setLocalWidgetFilters({
      ...LOOKER_FILTER_INITIAL_VALUES,
      ...params,
    });
    const weekStart = getWeekStartDay(params, weekStartForAccounts);
    setWeekStart(weekStart);
  }, [
    searchParams,
    dashboards,
    views,
    location,
    additionalDashboards,
    isDefaultFilterApplied,
    isFiltersLoading,
    currentProduct,
    weekStartForAccounts,
  ]);

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const prev = prevFilters.current;
    if (!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
      setViewMode("new");
    } else if (hasFilterBeenChangedOrApplied(prev, params)) {
      setViewMode("");
    } else if (hasGlobalFiltersChanged(prev, params, currentProduct)) {
      if (!hasFilterLevelChanged(prev, params, currentProduct)) {
        setViewMode("update");
      } else {
        setViewMode("new");
        const paramsCopy = { ...params };
        delete paramsCopy[LOOKER_FILTER_KEYS.FILTER_ID];
        setSearchParams(paramsCopy);
      }
    } else {
      setViewMode("");
    }
    prevFilters.current = params;
  }, [searchParams, currentProduct]);

  useEffect(() => {
    window.onmessage = (event) => {
      handleWindowEvents({
        event,
        setLocalWidgetFilters,
        setViewMoreDb,
        setDbDetails,
        navigate,
        searchParams,
        hierarchyData: hierarchy?.[PROGRAMMATIC_IDENTIFIERS.productKey],
        setSearchParams,
        setJobGroupDetails,
      });
    };
    return () => {
      window.onmessage = null;
    };
  }, [dashboards, hierarchy, navigate, searchParams]);

  useEffect(() => {
    setCurrentDashboardId(globalFilters?.[LOOKER_FILTER_KEYS.DASHBOARD_ID]);
  }, [globalFilters]);

  useEffect(() => {
    if (location.pathname !== prevLocation.pathname) {
      if (EMBED_URL_REFRETCH_ROUTES.includes(location.pathname)) {
        setEmbedUrl("");
      }
    }
  }, [location.pathname, prevLocation, setEmbedUrl]);

  useEffect(() => {
    if (!!window?.clarity) {
      const email = localStorage.getItem("email");
      console.log("Identifying user by email", email);
      if (!!email) {
        window.clarity("identify", email, null, null, email);
      }
    }
    const sidebarExpansion =
      getItemFromLocalStorage(SIDEBAR_EXPANSION_LS_KEY) || "false";
    toggleSidebarExpanded(sidebarExpansion === "true");
  }, []);

  const isCurrentDashboardUADashboard = useMemo(() => {
    if (!dashboards) {
      return true;
    }

    const UADashboardList = Object.values(dashboards).map(
      (dashboardObj) => dashboardObj.id
    );
    return UADashboardList.includes(currentDashboardId);
  }, [currentDashboardId, dashboards]);

  const isNavbarFilterVisible = useMemo(() => {
    if (!dashboards || !hierarchy) return false;
    if (location.pathname === SCHEDULES_ROUTE) return false;
    if (!!hierarchy && isCurrentDashboardUADashboard) return true;
    if (location.pathname === UPGRADE_ROUTE) return true;
    return false;
  }, [dashboards, hierarchy, isCurrentDashboardUADashboard, location]);

  const handleUpdateDashboard = (dashboardId, route) => {
    const params = Object.fromEntries([...searchParams]);
    params[LOOKER_FILTER_KEYS.DASHBOARD_ID] = dashboardId;
    const isAdditional = additionalDashboards?.find(
      (item) => item?.id === dashboardId
    );
    if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
      delete params[LOOKER_FILTER_KEYS.FILTER_ID];
    }
    if (!!isAdditional) {
      Mixpanel.trackEvent("Custom Dashboard Opened", {});
    }

    if (location.pathname === route) {
      setTimeout(() => {
        setSearchParams({
          ...params,
        });
      }, 100);
    } else {
      setEmbedUrl("");
      setTimeout(() => {
        navigate({
          pathname: route,
          search: "?" + new URLSearchParams(params).toString(),
        });
      }, 0);
    }
  };

  const onAdvancedFilterChange = useCallback(
    (advancedFilters, advancedFilterOptions) => {
      const params = Object.fromEntries([...searchParams]);
      const filterId = params[LOOKER_FILTER_KEYS.FILTER_ID];
      const hasGlobalFiltersChanged = hasFiltersChangedInSavedFilter(
        filterId,
        views,
        params
      );
      if (!!filterId) {
        const advFiltersInFilter = getAdvancedFiltersFromFilters(
          filterId,
          views
        );
        if (!!advFiltersInFilter?.length) {
          if (!!advancedFilters?.length && !!advancedFilterOptions?.length) {
            const advFiltersApplied = advancedFilters.map((item) => ({
              ...advancedFilterOptions.find((opt) => opt.name === item.key),
              value: item.value,
            }));
            const isAdvancedFiltersSame =
              advFiltersApplied?.length === advFiltersInFilter?.length &&
              advFiltersInFilter.every((item) => {
                const [key] = Object.entries(item)[0];
                const entry = advFiltersApplied.find((i) => i.filter === key);
                if (!!entry) {
                  const expr = toFilterExpression(item, advancedFilterOptions);
                  return entry.value === expr.value;
                }
                return false;
              });
            const isSame = isAdvancedFiltersSame && !hasGlobalFiltersChanged;
            setViewMode(isSame ? "" : "update");
          } else {
            setViewMode("update");
          }
        } else if (!!advancedFilters?.length) {
          setViewMode("update");
        } else if (hasGlobalFiltersChanged) {
          setViewMode("update");
        } else {
          setViewMode("");
        }
      }
    },
    [views, searchParams, setViewMode]
  );

  const defaultRoute = (() => {
    if (!products?.length) return "";
    const unified = products.find(
      (item) => item?.productKey === UNIFIED_VIEW_IDENTIFIERS.productKey
    );
    if (!!unified) return unified.route;
    return products[0].route;
  })();

  return (
    <MainAppContainer>
      <Wrapper isSidebarExpanded={isSidebarExpanded}>
        <NavBar
          setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
          isHamburgerMenuOpen={isHamburgerMenuOpen}
          isNavbarFilterVisible={isNavbarFilterVisible}
          roadmapIframeDetails={roadmapIframeDetails}
          setRoadmapIframeDetails={setRoadmapIframeDetails}
          isRoadmapFeatureEnabled={isRoadmapFeatureEnabled}
          unseenRoadmapFeaturesCount={unseenRoadmapFeaturesCount}
          isInsightsFeatureEnabled={isInsightsFeatureEnabled}
          openInsightSidebar={openInsightSidebar}
        />
        {!isRoadmapRoute && (
          <Sidebar
            dashboardList={getWebViewDashboardList({
              currentDashboardId,
              currentUADashboardId: dashboards?.[DASHBOARD_MAP.client]?.id,
              additionalDashboards,
            })}
            updateDashboard={handleUpdateDashboard}
            dashboards={dashboards}
            expanded={isSidebarExpanded}
            handleSidebarToggle={() => {
              toggleSidebarExpanded((sb) => {
                setItemInLocalStorage(SIDEBAR_EXPANSION_LS_KEY, !sb);
                return !sb;
              });
            }}
          />
        )}
        <ContentContainer
          isSidebarExpanded={isSidebarExpanded}
          doesHaveFilters={
            !!(
              location?.pathname === PROGRAMMATIC_ROUTE ||
              location?.pathname.startsWith(MARKET_INSIGHTS_ROUTE)
            )
          }
          isRoadmapRoute={!!isRoadmapRoute}
        >
          <Routes>
            <Route
              path={SMP_JOB_INGESTION_ROUTE_IDENTIFIER}
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Loader size="medium" />
                    </div>
                  }
                >
                  <JobFeedInjestion />
                </Suspense>
              }
            />

            <Route
              path={`${MEDIA_PLANNER_ROUTE}/*`}
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Loader size="medium" />
                    </div>
                  }
                >
                  <MediaPlanner />
                </Suspense>
              }
            />
            <Route path={SCHEDULES_ROUTE} element={<Schedules />} />
            <Route
              path={`${MARKET_INSIGHTS_ROUTE}/*`}
              element={<MarketInsights />}
            />
            <Route
              path={CAREER_SITE_ROUTE}
              element={
                <CareerAndJobSite
                  viewMode={viewMode}
                  localWidgetFilters={localWidgetFilters}
                />
              }
            />
            <Route
              path={CRM_ROUTE}
              element={
                <CRM
                  viewMode={viewMode}
                  localWidgetFilters={localWidgetFilters}
                />
              }
            />
            <Route
              path={CAREER_SITE_ROUTE}
              element={
                <CareerAndJobSite localWidgetFilters={localWidgetFilters} />
              }
            />
            <Route
              path={CRM_ROUTE}
              element={<CRM localWidgetFilters={localWidgetFilters} />}
            />
            <Route
              path={UNIFIED_VIEW_ROUTE}
              element={<UnifiedView localWidgetFilters={localWidgetFilters} />}
            />
            <Route
              path={JOB_SITE_ROUTE}
              element={
                <CareerAndJobSite localWidgetFilters={localWidgetFilters} />
              }
            />
            <Route path={ERROR_ROUTE} element={<ErrorPage />} />
            <Route
              path={ROADMAP_ROUTE}
              element={
                <Roadmap
                  roadmapFeatures={roadmapFeatures}
                  roadmapIframeDetails={roadmapIframeDetails}
                  setRoadmapIframeDetails={setRoadmapIframeDetails}
                  setSeenRoadmapFeatures={setSeenRoadmapFeatures}
                />
              }
            />
            <Route
              path={`${PROGRAMMATIC_ROUTE}/*`}
              element={
                <MainContent
                  viewMoreDb={viewMoreDb}
                  setViewMoreDb={setViewMoreDb}
                  jobGroupDetails={jobGroupDetails}
                  setJobGroupDetails={setJobGroupDetails}
                  dbDetails={dbDetails}
                  isCurrentDashboardUADashboard={isCurrentDashboardUADashboard}
                  onAdvancedFilterChange={onAdvancedFilterChange}
                />
              }
            />
            {!!products?.length && (
              <Route path="/" exact element={<Navigate to={defaultRoute} />} />
            )}
            {!isProductsLoading && !!products?.length && (
              <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} />} />
            )}
          </Routes>
          {(isProductsLoading || isUserMetadataLoading) &&
            !isMediaPlannerRoute && (
              <EmptyContainer>
                <Loader size="medium" />
              </EmptyContainer>
            )}
          {!isEmbedUrlLoading && !!embedUrl && status === "success" && (
            <Outlet />
          )}
        </ContentContainer>
        {isHamburgerMenuOpen && (
          <HamburgerMenu
            setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
            updateDashboard={handleUpdateDashboard}
            dashboardList={getMobileViewDashboardList({
              currentDashboardId,
              currentUADashboardId: dashboards?.[DASHBOARD_MAP.client]?.id,
              additionalDashboards,
            })}
          />
        )}
        {!!showInsightSidebar && (
          <InsightSidebar
            onClose={() => setShowInsightSidebar(false)}
            params={searchParams}
          />
        )}
      </Wrapper>
    </MainAppContainer>
  );
}
export default MainApp;

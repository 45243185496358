import styled from "styled-components";

export const MultiselectContainer = styled.div`
  border-left: 1px solid #e2e8f0;
  padding: 0 0.75rem 0.5rem 0.5rem;
`;
export const Header = styled.div`
  background-color: ${(props) =>
    props.type === "dimension" ? "#D7DAEB" : "#B8E8E5"};
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #252f3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d7daeb;
`;
export const SectionContainer = styled.div`
  overflow-y: scroll;
  height: 350px;
  background-color: #fafafa;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`;
export const SectionTitle = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: #292929;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;

  .Arrow {
    cursor: pointer;
    height: 0.7rem;
    width: 0.7rem;
  }
  .Arrow__rotate {
    transform: rotateZ(270deg);
  }
`;
export const SectionBody = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
`;
export const EmptyContainer = styled.div`
  padding: 0.5rem;
  background-color: #fafafa;
  height: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;

import { CURRENCY_KEY_TO_SIGN_MAP, DEVICE_SIZES_PX } from "../../constants/AppConstants";

export const getChartDataOptions = ({
  value,
  currency,
  avgMedianCompensation,
}) => {
  const POINTS_IN_INTERVAL = 5;

  const LABELS = [
    "Low <br/>(25% below median)",
    "Median",
    "High <br/>(25% above median)",
  ];

  const data = [
    avgMedianCompensation * 0.75,
    avgMedianCompensation,
    avgMedianCompensation * 1.25,
  ];

  return {
    chart: {
      margin: [50, 0, 50, 50],
      width: window.innerWidth < DEVICE_SIZES_PX.MOBILE ? window.innerWidth * 0.9 : null,
      events: {
        load: function () {
          const seriesData = this.series[0].data;
          let currentLabelIndex = 0; // Start with the first label
          let labelAssigned = false;

          for (let i = 0; i < seriesData.length; i++) {
            const point = seriesData[i];
            if (i % POINTS_IN_INTERVAL === 0) {
              const xValue = point.x;

              if (!labelAssigned && xValue >= data[currentLabelIndex]) {
                point.update({
                  color: "black",
                  dataLabels: {
                    enabled: true,
                    format: LABELS[currentLabelIndex],
                    overflow: "none",
                    crop: false,
                    y: -2,
                    style: {
                      fontSize: "13px",
                    },
                  },
                });
                labelAssigned = true;
              } else {
                point.update({
                  dataLabels: {
                    enabled: false,
                  },
                });
              }

              if (
                xValue >= data[currentLabelIndex] &&
                currentLabelIndex < data.length - 1
              ) {
                currentLabelIndex++;
                labelAssigned = false; // Reset the labelAssigned flag
              }
            }
          }

          const extremeValuesObj = this.xAxis[0].getExtremes();
          const maxValue = value > data[2] ? value : data[2];
          if (extremeValuesObj.dataMax < maxValue) {
            extremeValuesObj.max = maxValue * 1.1;
          }

          this.xAxis[0].setExtremes(extremeValuesObj.min, extremeValuesObj.max);
        },
      },
    },
    title: null,
    credits: {
      enabled: false,
    },
    xAxis: {
      min: 0,
      alignTicks: false,
      startOnTick: true, // Start the axis on a tick
      plotLines: [
        {
          color: "#4350A5",
          width: 2,
          value,
          label: {
            formatter: function () {
              return `<div style="z-index: 200;color: var(--plain-gray-500, #6B7280);font-size: 1rem;font-style: normal;font-weight: 400;max-width: 250px">Your job lies here</div>`;
            },
            useHTML: false,
            x: value < avgMedianCompensation ? 20 : -10,
            align: value < avgMedianCompensation ? "left" : "right",
            rotation: 0,
            y: -12,
            verticalAlign: "top",
            style: {
              color: "white",
            },
          },
          zIndex: 100,
        },
      ],
      labels: {
        formatter: function () {
          if (this.value < 0) {
            return "";
          }

          let value = this.value;
          let suffix = "";

          if (value >= 1000000) {
            value /= 1000000;
            suffix = "M";
          } else if (value >= 1000) {
            value /= 1000;
            suffix = "K";
          }

          return (
            CURRENCY_KEY_TO_SIGN_MAP[currency] + Math.trunc(value) + suffix
          );
        },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "bellcurve",
        color: "#525DC2",
        pointsInInterval: POINTS_IN_INTERVAL,
        intervals: 4,
        baseSeries: 1,
        zIndex: -1,
        marker: {
          enabled: false,
        },
      },
      {
        data,
        visible: false,
      },
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        enableMouseTracking: false,
        borderWidth: 0,
      },
    },
  };
};

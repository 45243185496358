import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  EmptyContainer,
  ViewSelectionContainer,
  ViewsContainer,
} from "./styles"

import View from './View';
import EmptyText from '../../common/EmptyText';

import { LOOKER_FILTER_KEYS } from '../../../constants/AppConstants';
import { useGlobalFiltersStore } from '../../../stores/global';

const ViewSelection = ({
  views = [],
  loadingStates,
  onOptionChange,
  searchValue,
}) => {
  const {globalFilters} = useGlobalFiltersStore(useShallow((state) => ({ globalFilters: state.globalFilters })));
  const checkViewUniqueness = (name) => {
    return views?.some(item => item.name === name)
  }

  return (
    <ViewSelectionContainer>
      <ViewsContainer containViews={!!views?.length}>
        {
          (!loadingStates?.get && !!views?.length) && (
            views.map((view) => (
              <View
                key={JSON.stringify(view)}
                view={view}
                isSelected={!!view?.filterId && (view?.filterId === globalFilters[LOOKER_FILTER_KEYS.FILTER_ID])}
                checkUniqueness={checkViewUniqueness}
                loadingStates={loadingStates}
                onOptionChange={onOptionChange}
                searchValue = {searchValue}
              />
            ))
          )
        }
      </ViewsContainer>
      {
        (!views?.length && !loadingStates?.get) && (
          <EmptyContainer>
            <EmptyText text="No results found" />
          </EmptyContainer>
        )
      }
    </ViewSelectionContainer>
  );
}

export default ViewSelection;
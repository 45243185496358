import { useQuery } from "react-query";

import { getEntityCount } from "../services/apis";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { ACCESS_TOKEN_LS_KEY } from "../constants/AppConstants";

export const useFetchEntityCountSummary = ({ payload, pageType }) => {
  return useQuery(
    ['entityCountSummary', { ...payload, pageType }],
    () => (
      getEntityCount(payload, pageType)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!payload && !!pageType && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY))
    },
  );
}
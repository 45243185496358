import styled from "styled-components";
import { SearchInput, SearchBoxContainer } from "../SearchBox/styles";

export const MultiLevelDropdownContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  ${SearchInput} {
    padding: 0.5rem;
  }

  ${SearchBoxContainer} {
    border-radius: 0;
  }
`
export const Sections = styled.div`
  max-height: 38vh;
  overflow-y: auto;
`
export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem 0.5rem;
`
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`
export const Title = styled.p`
  color: #718096;
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.3rem;
  position: relative;
`
export const Line = styled.span`
  flex: 1;
  height: 1px;
  background-color: #E2E8F0;
`
export const Option = styled.div`
  font-weight: 400;
  font-size: 0.9rem;
  padding: 0.7rem 0.5rem;
  cursor: pointer;
  background-color: ${props => !!props.active ? "#E8EAF6" : "#FFFFFF"};
  color: ${props => !!props.active ? "#303F9F" : " #374151"};
  border-radius: ${props => !!props.active ? "0.2rem" : "0"};

  &:hover {
    background-color: ${props => !props.active ? "#EDF2F7" : "#E4E8FF"};
    color: ${props => !props.active ? "#374151" : "#303F9F"};
    border-radius: 0.2rem;
  }
`
export const EmptyContainer = styled.div`
  width: 100%;
  min-height: 10rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useShallow } from 'zustand/react/shallow';

import {
  ViewMoreFullPageContainer,
  HeaderContainer,
  HomeButtonLink,
  HomeButtomText,
  TitleContainer,
  BetaText,
  InfoContainer,
  InfoTitle,
  Link,
  Arrow,
} from "./styles";

import Icon from "../common/Icon";
import Title from "../common/Title";
import ViewMore from "../ViewMore";

import { UNIFIED_ANALYTICS_FEEDBACK_EMAIL } from "../../constants/AppConstants";
import useOnClickOutside from "../../utils/useOnClickOutside";
import { useGlobalFiltersStore } from "../../stores/global";
import { PROGRAMMATIC_ROUTE } from "../../constants/AppRoutes";

const ViewMoreFullPage = ({
  dbDetails,
  advancedFilters,
  advancedFilterOptions,
  uiConfig,
}) => {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const { setEmbedUrl } = useGlobalFiltersStore(
    useShallow((state) => ({
      setEmbedUrl: state.setEmbedUrl,
    }))
  );

  const [isBetaPopupExpanded, toggleBetaPopup] = useState(false);
  const popupRef = useRef(null);

  const wrapperRef = useRef(null);

  const handleNavigateToHome = useCallback(() => {
    const params = Object.fromEntries([...searchParams]);
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    setEmbedUrl("")
    navigate({
      pathname: PROGRAMMATIC_ROUTE,
      search: urlSearchParams,
    });
  }, [navigate, searchParams]);

  useOnClickOutside(wrapperRef, () => {
    if (isBetaPopupExpanded) {
      toggleBetaPopup(false);
    }
  });

  if (!dbDetails) {
    handleNavigateToHome();
    return;
  }

  return (
    <ViewMoreFullPageContainer>
      <HeaderContainer screenWidth={uiConfig?.width}>
        <HomeButtonLink screenWidth={uiConfig?.width}>
          <Icon
            name="arrow-circle-left"
            onClick={handleNavigateToHome}
            style={{ cursor: "pointer" }}
          />{" "}
          <HomeButtomText onClick={handleNavigateToHome}>Home</HomeButtomText>
        </HomeButtonLink>
        <TitleContainer screenWidth={uiConfig?.width}>
          <Icon
            name="chevron-left-black"
            onClick={handleNavigateToHome}
            style={{ cursor: "pointer", color: "#000000" }}
          />
          <Title
            text={dbDetails?.title}
            styles={{
              color: "var(--gray-900, #101828)",
              fontSize: "1.25rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "2.25rem",
            }}
          />
          {dbDetails?.filtersConfig?.showBetaFeature && (
            <BetaText
              onClick={() => toggleBetaPopup(!isBetaPopupExpanded)}
              ref={wrapperRef}
            >
              beta
              <Icon name="info" alt="info" style={{ display: "block" }} />
              {isBetaPopupExpanded && (
                <div ref={popupRef}>
                  <InfoContainer screenWidth={uiConfig?.width}>
                    <InfoTitle>Beta feature</InfoTitle>
                    {dbDetails?.filtersConfig?.betaText[0]}
                    <Link href={`mailto:${UNIFIED_ANALYTICS_FEEDBACK_EMAIL}`}>
                      {dbDetails?.filtersConfig?.betaText[1]}
                    </Link>
                  </InfoContainer>
                  <Arrow screenWidth={uiConfig?.width} />
                </div>
              )}
            </BetaText>
          )}
        </TitleContainer>
      </HeaderContainer>
      <ViewMore
        // dashboards={dashboards}
        dbDetails={dbDetails}
        // lookerFilter={lookerFilter}
        advancedFilters={advancedFilters}
        advancedFilterOptions={advancedFilterOptions}
        isFullPage={true}
        loaderContainerStyle={{ position: "absolute", top: "-10%" }}
      />
    </ViewMoreFullPageContainer>
  );
};

export default ViewMoreFullPage;

const COMMON_FILTERS = ["Campaign", "Job Group", "Job Country", "Job State", "Job Category"]
const JOVEO_FILTERS = ["Publisher"]

export const getGroups = (data, config) => {
  return Object.values(
    data.reduce((acc, item) => {
      const groupName = item["filter_data.attribute_group"];
      const displayValue = item["filter_data.attribute_label"];
      const filterValue = item["filter_data.attributes"]

      acc[groupName] = acc[groupName] || { name: groupName, options: [] };
      acc[groupName]["inputType"] = config.inputType;
      acc[groupName].options.push({ displayValue, filterValue });

      return acc;
    }, {})
  );
};

export const filterSourceWiseData = (data, filter, config, groupName) => {
  if (!data?.length || !config) {
    return []
  }

  const groups = getGroups(data, config)
  const group = groups.find(item => item.name === groupName)
  let options = null
  if (filter === "Joveo") {
    options = group.options.filter(item => [...COMMON_FILTERS, ...JOVEO_FILTERS].includes(item.displayValue))
  } else if (filter === "Non joveo") {
    options = group.options.filter(item => !JOVEO_FILTERS.includes(item.displayValue))
  }
  const grouped = data.filter(item => {
    if (item["filter_data.attribute_group"] !== groupName) return true
    return !!options.find(opt => opt.displayValue === item["filter_data.attribute_label"])
  })
  return grouped
}

export const parseGroupData = (data, config) => {
  if (!data?.length || !config) {
    return []
  }
  let groups = getGroups(data, config)
  return groups
}

import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const EntityCountSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7fafc;
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);

  @media screen and ${devices.mobile} {
    overflow-x: scroll;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* For Firefox */
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  span {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    color: #4b5563;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const EntityCountSummaryDiv = styled.div`
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:not(:first-child) {
    padding-left: 0;
  }

  &.first-child {
    padding-left: 1.8rem;
  }

  @media screen and ${devices.mobile} {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 3px;
    padding-bottom: 3px;

    &.first-child {
      padding-left: 1.5rem;
    }
  }
`;

export const EntityCountSummaryLabel = styled.span`
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #4b5563;

  @media screen and ${devices.mobile} {
    width: max-content;
  }
`;

export const EntityCountSummaryValue = styled.span`
  margin-left: 0.7rem;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #161e2e;
`;

export const EntityCountSummaryContainerDiv = styled.div`
  display: contents;
`;

export const VerticalSeperator = styled.div`
  height: 16px;
  background-color: #CBD5E0;
  margin: 4px 0;
  width: 1px;
`;
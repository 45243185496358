import styled from "styled-components";

export const TdContainer = styled.td`
  padding: 1rem;
  padding-right: ${props => props.isLast ? "2.6rem" : "2.2rem"};
  border: 1px solid #EDF2F7;
  font-size: 0.9rem;
  font-weight: 400;
  color: #374151;
  position: ${props => props.isPinned ? "sticky" : "initial"};
  background-color: #FFFFFF;
`
import styled from "styled-components";

export const ProfileLogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileLogoButton = styled.button`
 color: #ffffff;
 background-color: transparent;
 border: none;
`;

export const DropdownButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
  height: 2rem;
  width: 2rem;
  justify-content: center;
  border-radius: 50%;
  background-color: #485abb;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
  font-size: 1rem;
  margin: 0 10px;

  &:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    background-color: #4F61BE;
  }
`;

export const DropdownMenuItem = styled.li`
  cursor: pointer;
  text-decoration: none;
  color: #374151;
  padding: 1rem 0 1rem 1rem;
  display: block;
  font-size: 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.028px;
  line-height: 1.25rem;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  &.active {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  .time-zone {
    font-weight: 600;
  }

  .dropdown-menu-item-icon {
    margin-right: 0.6rem;
  }

  .dropdown-menu-item-end-icon {
    right: 1rem;
    position: absolute;
  }
`;

export const DropdownMenuItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  span:last-child div {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

`;

export const DropdownMenuItemListContainer = styled.div`
  background: #ffffff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  position: absolute;
  top: 2.8rem;
  right: 0;
  width: 14rem;
  box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 9999;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export const Username = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #161e2e;
  padding: 1rem 1rem 0;
`;

export const Email = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.25rem;
  letter-spacing: -0.028px;
  color: #374151;
  word-wrap: break-word;
  padding: 0rem 0rem 0.75rem 1rem;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #f4f5f7;
`;

export const TimezoneItem = styled.div`
  position: relative;
  text-decoration: none;
  color: #374151;
  padding: 0.4rem 0 0.4rem 1rem;
  display: block;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.028px;
  line-height: 1.25rem;
  background-color: #f4f5f7;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  word-break: break-all;
`;
export const JAXItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem;
  padding-left: 1.2rem;
  gap: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: #e3f2fd;
    color: #1565c0;
  }

  .jax {
    margin-left: -0.2rem;
  }
`
export const JAXItemText = styled.span`
  color: #374151;
  font-weight: 400;
  font-size: 0.9rem;
`

import { LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import { JOB_DIMENSION_OPTIONS_HOME } from "../../constants/ReportsConstants";

const COMPANY_FILTER = { name: "Job Company", enabled: true, field: "jobCompany" }

const getJobFields = () => [
  ...JOB_DIMENSION_OPTIONS_HOME.flatMap((item) => item.options).map((item) => ({
    ...item,
    id: JSON.stringify(item),
    name: item.name,
    enabled: true,
    field: item.key,
  })),
];

const getAdvancedFilterOptions = (showPublisherFilter) => [
  { id: 1, name: "Campaign name", enabled: true, field: "campaignName" },
  { id: 2, name: "Job group name", enabled: true, field: "jobgroupName" },
  {
    id: 3,
    name: "Publisher name",
    enabled: showPublisherFilter,
    field: "publisherName",
  },
  {
    id: 4,
    name: "Publisher type",
    enabled: showPublisherFilter,
    field: "publisherType",
  },
];


export const buildAdvancedFilterOptions = ({
  globalFilters,
  publisherFilter,
  cifFields
}) => {
  const params = globalFilters;
  const { [LOOKER_FILTER_KEYS.CLIENT_ID]: campaignId = "" } = params || {};
  if (!campaignId) {
    return getAdvancedFilterOptions(publisherFilter).map((item) => ({
      ...item,
      key: item.name,
      filter: item.field,
    }));
  }
  const cifs = Object.entries(cifFields || {});

  let jobFilters = [
    ...getAdvancedFilterOptions(publisherFilter).map((item) => ({
      ...item,
      key: item.name,
      filter: item.field,
    })),
    ...getJobFields().map((item) => ({
      ...item,
      key: item.name,
      filter: item.field,
      suggestionsEnabled: true,
    })),
    {
      ...COMPANY_FILTER,
      key: COMPANY_FILTER.name,
      filter: COMPANY_FILTER.field,
      suggestionsEnabled: false
    }
  ];
  if (!!cifs?.length) {
    const cif = cifs.map(([key, value]) => ({
      id: key,
      name: key,
      enabled: true,
      filter: value,
      key: key,
      suggestionsEnabled: true,
    }));
    jobFilters = [...jobFilters, ...cif];
  }
  return jobFilters;
}
import React, { useRef } from 'react';

import {
  GroupDropdownContainer,
  Option
} from './styles';
import Icon from '../../../components/common/Icon';

import useOnClickOutside from '../../../utils/useOnClickOutside';

const GroupDropdown = ({
  options = [],
  selected,
  labelKey = "label",
  className = "",
  onSelect,
  onClose
}) => {
  const ref = useRef(null)
  useOnClickOutside(ref, () => {
    onClose()
  });

  return (
    <GroupDropdownContainer className={className} ref={ref}>
      {
        options?.map(opt => (
          <Option active={selected === opt?.[labelKey]} onClick={() => onSelect(opt)}>
            {opt?.[labelKey]}
            {
              selected === opt?.[labelKey] && <Icon name="tick" />
            }
          </Option>
        ))
      }
    </GroupDropdownContainer>
  );
}

export default GroupDropdown;

import { REFRESH_TOKEN_LS_KEY, ACCESS_TOKEN_LS_KEY, LOOKER_ACCESS_TOKEN_LS_KEY, PROGRAMMATIC_IDENTIFIERS } from "../constants/AppConstants";
import {
  userClient,
  unifiedAnalyticsClient,
  campaignManagementClient,
  fnaDashboardClient,
  lookerApiClient,
  jobsClient,
  publisherManagementClient,
  jobLocationServiceClient,
  unifiedServiceClient,
} from "../helpers/axiosClient";
import { getItemFromLocalStorage } from "../helpers/commonHelper";

export const getAccessToken = (payload) => {
  return userClient.post("/account/token", payload, {
    headers: {
      refreshToken: getItemFromLocalStorage(REFRESH_TOKEN_LS_KEY),
    },
  });
};

export const getProductsForUser = () => {
  return unifiedServiceClient.get("/products", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getUserMetadata = () => {
  return unifiedServiceClient.get("/user/metadata", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getLookerEmbeddedURL = (productId, params) => {
  return unifiedServiceClient.get(`/${productId}/sso/embed-url`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
      embedDomain: window.location.origin,
    },
    params: { ...params },
  });
};

export const getLookerAccessToken = () => {
  return unifiedAnalyticsClient.get("/user", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getHierarchyData = (productId) => {
  const url = `/${productId}/hierarchy`
  return unifiedServiceClient.get(url, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
}

export const getEntityCount = (payload, pageType) => {
  return unifiedServiceClient.post(
    `/programmatic/entity-count/summary?pageType=${pageType}`,
    payload,
    {
      headers: {
        accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
      },
    }
  );
};

export const getQueryDetails = (queryId) => {
  return lookerApiClient.get(`queries/${queryId}`, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getLookerData = (payload) => {
  return lookerApiClient.post("queries/run/json", payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)
    },
  });
};

export const getReportsDownloadUsingRunInlineQuery = (payload, format, params) => {
  return lookerApiClient.post(`queries/run/${format}`, payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
    responseType: "blob",
    params,
  });
};

export const getSuggestions = (model, field, params) => {
  return lookerApiClient.get(
    `models/${model}/views/view_combined_event/fields/${field}/suggestions`,
    {
      params,
      headers: {
        Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
      },
    }
  );
};

export const getReportsQuery = (payload) => {
  return lookerApiClient.post("queries", payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getCIFFields = (clientId) => {
  return unifiedServiceClient.get(`/programmatic/clients/${clientId}/cifs`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getJobNameSuggestions = (params) => {
  return unifiedServiceClient.get("/programmatic/jobs/job-type-ahead", {
    headers: {
      AccessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
    params,
  });
};

export const getPublisherSuggestions = (params) => {
  return unifiedServiceClient.get("/programmatic/publishers/typeahead", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
    params,
  });
};

export const getJobScore = (clientId, jobId) => {
  return jobLocationServiceClient.get(`/client/${clientId}/job/${jobId}`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getFilters = (params) => {
  return unifiedAnalyticsClient.get("/filters", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
    params,
  });
};

export const createFilter = (payload) => {
  return unifiedAnalyticsClient.post("/filters", payload, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    }
  });
};

export const editFilter = ({ filterId, payload }) => {
  return unifiedAnalyticsClient.put(`/filter/${filterId}`, payload, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const deleteFilter = ({ filterId, params }) => {
  return unifiedAnalyticsClient.delete(`/filter/${filterId}`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
    params,
  });
};

export const getScheduledReports = (params) => {
  return lookerApiClient.get("scheduled_plans", {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const scheduleReport = (payload) => {
  return lookerApiClient.post("scheduled_plans", payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const scheduleReportOnce = (payload) => {
  return lookerApiClient.post("scheduled_plans/run_once", payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const editScheduledReport = ({ scheduleId, payload }) => {
  return lookerApiClient.patch(`scheduled_plans/${scheduleId}`, payload, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const deleteScheduledReport = (scheduleId) => {
  return lookerApiClient.delete(`scheduled_plans/${scheduleId}`, {
    headers: {
      Authorization: getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
    },
  });
}

export const getLookerQueryMap = () => {
  return unifiedServiceClient.get("/looker/queries", {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const createLookerQueryMap = (payload) => {
  return unifiedServiceClient.post("/looker/queries", payload, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    }
  });
};

export const deleteLookerQueryMap = (queryId) => {
  return unifiedServiceClient.delete(`/looker/queries/${queryId}`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

// Roadmap APIs
export const getRoadmapFeatures = (status, page = 0, size = 10) => {
  return unifiedServiceClient.get("/roadmap/features", {
    params: {
      status,
      page,
      size,
    },
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const voteRoadmapFeature = (payload) => {
  const { featureId, isLike } = payload;
  return unifiedServiceClient.post(`/roadmap/features/${featureId}/${isLike ? "like" : "dislike"}`, {}, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const getRoadmapFeature = (featureId) => {
  return unifiedServiceClient.get(`/roadmap/${featureId}`, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

// Insights APIs

export const getInsights = (params) => {
  return unifiedServiceClient.get("/insights", {
    params,
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

export const postFeedback = (payload) => {
  return unifiedServiceClient.post("/feedback", payload, {
    headers: {
      accessToken: getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY),
    },
  });
};

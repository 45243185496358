import React, { useCallback, useMemo } from "react";
import { Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import {
  CAREER_SITE_IDENTIFIERS,
  COMMON_GLOBAL_FILTERS,
  CRM_IDENTIFIERS,
  LOCAL_STORAGE_KEY,
  LOOKER_FILTER_KEYS,
  MARKET_INSIGHTS_IDENTIFIERS,
  PROGRAMMATIC_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS,
} from "../../../constants/AppConstants";

import {
  CAREER_SITE_ROUTE,
  CRM_ROUTE,
  CUSTOM_DASHBOARDS_ROUTE,
  MARKET_INSIGHTS_ROUTE,
  PROGRAMMATIC_ROUTE,
  REPORTS_ROUTE,
  UNIFIED_VIEW_ROUTE,
} from "../../../constants/AppRoutes";
import Icon from "../Icon";

import {
  HamburgerMenuContainer,
  RouteName,
  HamburgerMenuList,
  HamburgerMenuItem,
  DashboardItem,
  DashboardName,
  CustomLink,
  DashboardEntry
} from "./styles";

import { useGlobalDataStore, useGlobalFiltersStore } from "../../../stores/global";

const iconStyle = {
  height: "19px",
  width: "19px",
  marginRight: "0.5rem",
  cursor: "pointer",
}

const HamburgerMenu = ({
  setIsHamburgerMenuOpen,
  dashboardList,
  updateDashboard,
}) => {
  let [searchParams] = useSearchParams();
  const location = useLocation();
  let navigate = useNavigate();

  const { dashboards, showMarketInsights, products } = useGlobalDataStore(
    useShallow((state) => ({
      dashboards: state.dashboards,
      showMarketInsights: state.showMarketInsights,
      products: state.products
    }))
  );

  const { setEmbedUrl } = useGlobalFiltersStore(
    useShallow((state) => ({
      setEmbedUrl: state.setEmbedUrl,
    }))
  );

  const {
    currentProduct, setCurrentProduct,
  } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
    setCurrentProduct: state.setCurrentProduct,
  })))

  const queryParams = useMemo(() => {
    const params = Object.fromEntries([...searchParams]);
    return "?" + new URLSearchParams(params).toString();
  }, [searchParams]);

  const getUpdatedQueryParams = useCallback(
    (dashboardId) => {
      const params = Object.fromEntries([...searchParams]);
      return (
        "?" +
        new URLSearchParams({
          ...params,
          [LOOKER_FILTER_KEYS.DASHBOARD_ID]: dashboardId,
        }).toString()
      );
    },
    [searchParams],
    []
  );

  const handleProductSelect = (opt) => {
    if (!!opt?.route) {
      const params = Object.fromEntries([...searchParams])
      const dashboardId = !!opt.dashboardMapIdentifierKey ? dashboards[opt.dashboardMapIdentifierKey].id : ""
      if (!!dashboardId) {
        params[LOOKER_FILTER_KEYS.DASHBOARD_ID] = dashboards[opt.dashboardMapIdentifierKey].id
      }
      setCurrentProduct(opt)
      // setSelectedOption(opt)
      if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
        delete params[LOOKER_FILTER_KEYS.FILTER_ID]
      }
      if (opt?.globalFilters) {
        const commonFilters = [...COMMON_GLOBAL_FILTERS, ...opt?.globalFilters]
        Object.keys(params).forEach(item => {
          if (!commonFilters.includes(item)) {
            delete params[item]
          }
        })
      }
      const urlSearchParams = "?" + new URLSearchParams(params).toString();
      setEmbedUrl("")
      navigate({
        pathname: opt.route,
        search: urlSearchParams,
      });
    }
    setIsHamburgerMenuOpen(false)
  }

  const handleUpdateDashboard = (dashboard, route = PROGRAMMATIC_IDENTIFIERS.route) => {
    if (dashboard.disabled) {
      return;
    }

    if (location.pathname !== route) {
      setEmbedUrl("")
    }
    updateDashboard(dashboard.id, route);
    setIsHamburgerMenuOpen(false);
  };

  const shouldShowOverview = (() => {
    const doesHaveAccess = !!products?.find(item => item.productKey === UNIFIED_VIEW_IDENTIFIERS.productKey)
    return doesHaveAccess
  })()

  const routesList = [
    ...(!!shouldShowOverview ? ([
      {
        name: "Overview",
        route: UNIFIED_VIEW_ROUTE,
        iconName: window.location.pathname.includes(UNIFIED_VIEW_ROUTE)
          ? `${UNIFIED_VIEW_IDENTIFIERS.icon}-active`
          : UNIFIED_VIEW_IDENTIFIERS.icon,
        isActive: window.location.pathname.includes(UNIFIED_VIEW_ROUTE),
        isLink: false,
        iconStyle,
        onClick: () => handleProductSelect(UNIFIED_VIEW_IDENTIFIERS),
      },
    ]) : []),
    {
      name: "Products",
      isLink: false,
      dashboardList: [
        {
          name: "Programmatic",
          iconName:
            window.location.pathname.includes(PROGRAMMATIC_ROUTE) &&
              !window.location.pathname.includes("reports") &&
              !window.location.pathname.includes(CUSTOM_DASHBOARDS_ROUTE)
              ? "main-dashboard-hamburger-active"
              : "main-dashboard-hamburger-inactive",
          isActive:
            window.location.pathname.includes(PROGRAMMATIC_ROUTE) &&
            !window.location.pathname.includes("reports") &&
            !window.location.pathname.includes(CUSTOM_DASHBOARDS_ROUTE),
          iconStyle,
          onClick: () => handleProductSelect(PROGRAMMATIC_IDENTIFIERS)
        },
        {
          name: "Career & Job Site",
          route: CAREER_SITE_ROUTE,
          iconName: window.location.pathname.includes(CAREER_SITE_ROUTE)
            ? `${CAREER_SITE_IDENTIFIERS.icon}-active`
            : CAREER_SITE_IDENTIFIERS.icon,
          isActive: window.location.pathname.includes(CAREER_SITE_ROUTE),
          isLink: false,
          iconStyle,
          onClick: () => handleProductSelect(CAREER_SITE_IDENTIFIERS)
        },
        {
          name: "CRM",
          route: CRM_ROUTE,
          iconName: window.location.pathname.includes(CRM_ROUTE)
            ? `${CRM_IDENTIFIERS.icon}-active`
            : CRM_IDENTIFIERS.icon,
          isActive: window.location.pathname.includes(CRM_ROUTE),
          isLink: false,
          iconStyle,
          onClick: () => handleProductSelect(CRM_IDENTIFIERS)
        },
      ]
    },
    {
      name: "Others",
      isLink: false,
      dashboardList: [
        {
          ...PROGRAMMATIC_IDENTIFIERS,
          name: "Custom dashboards",
          iconName: "custom-dashboards",
          iconStyle,
          disabled: true,
          isActive: window.location.pathname.includes(CUSTOM_DASHBOARDS_ROUTE),
          dashboardList,
          linkStyle: {
            cursor: "auto",
          },
        },
        {
          ...PROGRAMMATIC_IDENTIFIERS,
          name: "Reports",
          route: REPORTS_ROUTE,
          iconName: window.location.pathname.includes(REPORTS_ROUTE)
            ? "report-hamburger-active"
            : "report-hamburger-inactive",
          isActive: window.location.pathname.includes(REPORTS_ROUTE),
          isLink: false,
          iconStyle,
          onClick: () => handleProductSelect({
            ...PROGRAMMATIC_IDENTIFIERS,
            route: REPORTS_ROUTE,
            name: "Reports",
            iconName: window.location.pathname.includes(REPORTS_ROUTE)
              ? "report-hamburger-active"
              : "report-hamburger-inactive",
            isActive: (location) => location.pathname.includes(REPORTS_ROUTE)
          })
        },
        ...(!!showMarketInsights ? ([
          {
            ...PROGRAMMATIC_IDENTIFIERS,
            name: "Market Insights",
            route: MARKET_INSIGHTS_ROUTE,
            iconName: window.location.pathname.includes(MARKET_INSIGHTS_ROUTE)
              ? "market-insights-hamburger-active"
              : "market-insights-hamburger-inactive",
            isActive: window.location.pathname.includes(MARKET_INSIGHTS_ROUTE),
            iconStyle,
            isLink: false,
            onClick: () => handleProductSelect({
              ...PROGRAMMATIC_IDENTIFIERS,
              name: "Market Insights",
              route: MARKET_INSIGHTS_ROUTE,
              iconName: window.location.pathname.includes(MARKET_INSIGHTS_ROUTE)
                ? "market-insights-hamburger-active"
                : "market-insights-hamburger-inactive",
              isActive: window.location.pathname.includes(MARKET_INSIGHTS_ROUTE),
              dashboardMapIdentifierKey: "marketInsights",
            })
          },
        ]) : [])
      ]
    },
  ];

  return (
    <HamburgerMenuContainer>
      <HamburgerMenuList>
        {routesList &&
          routesList.map(
            ({
              name,
              route,
              isActive = false,
              iconName,
              iconStyle,
              dashboardList,
              disabled,
              linkStyle,
              isLink = false,
              dashboardId = "",
              onClick
            }) => (
              <HamburgerMenuItem isActive={isActive} isDisabled={disabled}>
                {disabled ? (
                  <CustomLink>
                    <Icon name={iconName} style={iconStyle} />
                    <RouteName isActive={isActive} isDisabled={disabled}>
                      {name}
                    </RouteName>
                  </CustomLink>
                ) : isLink ? (
                  <CustomLink onClick={() => handleUpdateDashboard({ id: dashboardId, disabled }, route)}>
                    <Icon name={iconName} style={iconStyle} />
                    <RouteName isActive={isActive} isDisabled={disabled}>
                      {name}
                    </RouteName>
                  </CustomLink>
                ) : (
                  <CustomLink
                    onClick={() => onClick()}
                  >
                    <Icon name={iconName} style={iconStyle} />
                    <RouteName isActive={isActive}>{name}</RouteName>
                  </CustomLink>
                )}
                {dashboardList &&
                  dashboardList.map((dashboardObj) => (
                    <>
                      <DashboardItem
                        isActive={dashboardObj.isActive}
                        onClick={() => {
                          if (!!dashboardObj.onClick) {
                            dashboardObj.onClick()
                          }
                        }
                        }
                      >
                        <DashboardEntry>
                          <Icon
                            name={dashboardObj.iconName}
                            style={dashboardObj.iconStyle}
                          />
                          <DashboardName isActive={dashboardObj.isActive}>
                            {dashboardObj.name}
                          </DashboardName>
                        </DashboardEntry>
                      </DashboardItem>
                      {
                        dashboardObj?.dashboardList?.length && dashboardObj.dashboardList.map(cdb => (
                          <DashboardItem
                            padded
                            isActive={cdb.isActive}
                            onClick={() => {
                              handleUpdateDashboard(cdb, CUSTOM_DASHBOARDS_ROUTE)
                            }}
                          >
                            {/* <Icon
                              name={dashboardObj.iconName}
                              style={dashboardObj.iconStyle}
                            /> */}
                            <DashboardName isActive={cdb.isActive}>
                              {cdb.name}
                            </DashboardName>
                          </DashboardItem>
                        ))
                      }
                    </>
                  ))}
              </HamburgerMenuItem>
            )
          )}
      </HamburgerMenuList>
    </HamburgerMenuContainer>
  );
};

export default HamburgerMenu;

import React, { forwardRef } from 'react';

import { Option, OptionsListContainer } from './styles'

import Icon from '../Icon';

const OptionsList = forwardRef(({
  options,
  idKey = "id",
  iconKey = "icon",
  className,
  onSelect,
}, ref) => {
  return (
    <OptionsListContainer className={className} ref={ref}>
      {
        !!options.length && options.map(option => (
          <Option key={option[idKey]} onClick={(e) => {
            e.stopPropagation()
            onSelect(option)
          }} icon={!!option[iconKey]}>
            {
              !!option[iconKey] && (
                <Icon name={option[iconKey]} height="14" width="14" />
              )
            }
            {option?.title || ""}
          </Option>
        ))
      }
    </OptionsListContainer >
  );
})

export default OptionsList;
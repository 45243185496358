import styled, { css } from "styled-components";
import { devices, DEVICE_SIZES_PX } from "../../constants/AppConstants";

export const ViewMoreContainer = styled.div`
  position: relative;
  display: flex;
  background: #f5f5f5;
  height: ${(props) =>
    props.isFullPage ? "calc(100vh - 12rem)" : "calc(100vh - 6rem)"};

  .view-more-db-iframe {
    height: ${(props) =>
    props.isFullPage ? "calc(100vh - 12rem)" : "calc(100vh - 4rem)"};
    width: calc(100% + 4rem);
    margin: 0 0 0 -2rem;
    background: #f5f5f5;
  }

  @media only screen and (max-width: 960px) {
    .view-more-db-iframe {
      width: calc(100% - 1rem);
      margin: 0 0 0 0.5rem;
    }
  }

  @media only screen and (min-width: 960px) and (max-width: 1080px) {
    .view-more-db-iframe {
      width: calc(100% - 1rem);
      margin: 0 0 0 0.5rem;
    }
  }
  @media only screen and (min-width: 1080px) and (max-width: 1360px) {
    .view-more-db-iframe {
      width: calc(100% - 1rem);
      margin: 0 0 0 0.5rem;
    }
  }
  @media only screen and (min-width: 1360px) and (max-width: 1770px) {
    .view-more-db-iframe {
      width: calc(100% - 0.5rem);
      margin: 0 0 0 0.5rem;
    }
  }
  @media only screen and (min-width: 1770px) and (max-width: 1990px) {
    .view-more-db-iframe {
      width: calc(100% - 0.5rem);
      margin: 0 0 0 0.5rem;
    }
  }
  @media only screen and (min-width: 1900px) {
    .view-more-db-iframe {
      width: calc(100% + 3.4rem);
      margin: 0 0 0 -1.5rem;
    }
  }

  @media screen and ${devices.mobile} {
    height: calc(100vh - 11rem);
    display: block;
  }
`;
export const FilterContainer = styled.div`
  margin: 0.5rem 0 0 1.2rem;
  width: ${(props) => (props.isFullPage ? "15%" : "20%")};
  height: ${(props) => (props.isFullPage ? "calc(100% - 0.5rem)" : "100%")};
  border: ${(props) => (props.setupDone ? "1px solid #E2E8F0" : "none")};
  border-radius: 6px;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.setupDone
      ? "rgba(0, 0, 0, 0.11) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 1px 4px"
      : "none"};

  display: flex;
  gap: 1rem;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      display: none;
    `}
`;
export const ViewMoreDBContainer = styled.div`
  width: ${(props) => (props.isFullPage ? "85%" : "80%")};
  flex: 1;
  overflow-y: scroll;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      width: 100%;
      margin-left: 0;
    `}
`;
export const ViewMoreDb = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  box-shadow: ${(props) =>
    !props.setupDone
      ? "rgba(0, 0, 0, 0.11) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 1px 4px"
      : "none"};
  background: #f5f5f5;

  iframe {
    display: ${(props) => (props.setupDone ? "initial" : "none")};
  }
`;
export const ViewMoreFilterDb = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  iframe {
    display: ${(props) => (props.setupDone ? "initial" : "none")};
  }
`;
export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${(props) =>
    props.align === "left" ? "35%" : props.align === "right" ? "42%" : "30%"};
  ${(props) => props.style};
`;

export const MultiSelectDropdownContainerMobileView = styled.div`
  display: none;

  @media screen and ${devices.mobile} {
    display: block;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid var(--Neutral-300, #e2e6f1);
  background: var(--White, #fff);
  width: 100%;
  cursor: pointer;
`;

export const DropdownButtonTitle = styled.span`
  color: var(--Neutral-800, #373f50);
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
`;

import React, { useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';

import { JobSponsorshipContainer } from "./styles";

import DataTable from "../../../components/common/DataTable";
import ReportsLoader from "../../../components/common/ReportsLoader";

import { JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER } from "../../../constants/AppRoutes";
import { useFetchJobSponsorshipData } from "../../../api/reports";
import { formatUAData } from "../../../utils/helpers";
import { buildPayload, parseColumnsForTable, parseLookerFilters, parsePinnedColumns } from '../apiHelpers';
import { JOB_SPONSORSHIP_REPORT_TITLE } from "../../../constants/ReportsConstants";
import { useGlobalDataStore } from '../../../stores/global';

const JobSponsorshipReport = ({
  lookFilters,
  advancedFilters,
  columnSelections,
  page,
  onPageCountChange,
  isDownloading,
  onLoad,
  onDownload,
}) => {
  const { models } = useGlobalDataStore(useShallow((state) => ({
    models: state.models,
  })))

  const { data: { data = [] } = {}, status } = useFetchJobSponsorshipData(
    buildPayload(lookFilters, columnSelections, advancedFilters, JOB_SPONSORSHIP_REPORT_TITLE, models),
    parseLookerFilters(lookFilters, columnSelections, advancedFilters, JOB_SPONSORSHIP_REPORT_TITLE)
  );

  const columns = useMemo(() => {
    if (!columnSelections?.dimensions || !columnSelections?.metrics) return []

    const { dimensions, metrics } = parseColumnsForTable({ dimensions: [columnSelections?.order[0]], metrics: [columnSelections?.order[1]] })

    if (!dimensions?.length || !metrics?.length) return []

    return [
      ...dimensions.map((item) => ({
        header: item.label,
        accessorFn: (row) => row[item.dataTargetKey],
        id: JSON.stringify(item),
        backgroundColor:
          "linear-gradient(0deg, #E8EAF6, #E8EAF6),linear-gradient(0deg, #C1CAFF, #C1CAFF)",
        cell: (cell) => {
          const formatted = `${formatUAData({
            value: cell.getValue(),
            column: cell.column.columnDef.header,
            placeholder: "-",
            type: "standard",
          })}`;
          if (typeof cell.getValue() === "number") {
            return <div style={{ textAlign: "right" }}>{formatted}</div>;
          }
          return formatted;
        },
        sortingFn: item.sortingFn || "alphanumeric"
      })),
      ...metrics.map((item) => ({
        header: <div style={{ textAlign: "right", flex: 1 }}>{item.label}</div>,
        accessorFn: (row) => row[item.dataTargetKey] || "",
        id: `${JSON.stringify(item)}`,
        backgroundColor:
          "linear-gradient(0deg, #E0F2F1, #E0F2F1),linear-gradient(0deg, #9EDFDC, #9EDFDC)",
        cell: (cell) => {
          const formatted = `${formatUAData({
            value: cell.getValue(),
            column: item.label,
            placeholder: "-",
            type: "standard",
          })}`;
          return <div style={{ textAlign: "right" }}>{formatted}</div>;
        },
        sortingFn: "alphanumeric",
      })),
    ];
  }, [columnSelections]);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: `reports/${JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER}`,
    });
  }, []);

  useEffect(() => {
    onLoad(status !== "success");
  }, [status, onLoad]);

  return (
    <JobSponsorshipContainer>
      {status !== "success" && (
        <ReportsLoader
          isDownloading={isDownloading}
          onDownload={onDownload}
        />
      )}
      {status === "success" && !!columns?.length && (
        <DataTable
          data={data}
          columns={columns}
          pinnedColumns={parsePinnedColumns(columnSelections)}
          className="JobSponsorshipTable"
          page={page}
          pageSize={25}
          onPageCountChange={onPageCountChange}
        />
      )}
    </JobSponsorshipContainer>
  );
};

export default JobSponsorshipReport;

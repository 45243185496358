import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  SaveFiltersContainer,
  Header,
  Note,
  Body,
  Footer,
  Inputs,
  Label,
  InputContainer,
  FiltersContainer,
  Selections,
  TextBox
} from "./styles"

import Icon from '../../../common/Icon';
import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import TextInput from '../../../common/TextInput';
import Checkbox from '../../../common/Checkbox';
import ErrorMessage from '../../../common/ErrorMessage';
import FilterLabel from '../FilterLabel';

import { LOOKER_FILTER_KEYS, STRING_SEPARATOR, EXPANSIONS_TYPES, PUBLISHER_TYPES } from '../../../../constants/AppConstants';
import { parseFilterExpressions } from "../../../../utils/helpers"
import { useGlobalFiltersStore } from '../../../../stores/global';
import { MULTISELECT_FILTERS } from '../../../AdvancedFilters';

const MAIN_DBNOTE_TEXT = "This view will be saved with all the filters applied throughout the main dashboard."
const REPORTS_NOTE_TEXT = "This view will be saved with all the filters applied throughout reports."

const CHECKBOX_OPTIONS = [
  {
    name: "Make it my default view"
  },
]

const SaveFilterForm = ({
  type = "dashboard",
  title,
  selection,
  filter,
  isLoading,
  checkUniqueness,
  showAdvancedFilters = false,
  advancedFilters,
  advancedFilterOptions,
  onClose,
  onSubmit
}) => {
  let [searchParams] = useSearchParams()

  const { currentProduct } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
  })))

  const [name, setName] = useState("")
  const [isDefault, toggleDefault] = useState(false)
  const [isNameNotUnique, toggleNameUnique] = useState(false)

  const defaultSelectionText = (() => {
    if (!currentProduct) return ""
    return "All instances"
  })()

  const selectionText = Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || defaultSelectionText

  const advancedFilterSelection = useMemo(() => {
    if (!advancedFilters?.length) return <TextBox>None</TextBox>
    let appliedFiltersStr = ""
    const entry = advancedFilters[0]
    const isMultiSelect = MULTISELECT_FILTERS.find(i => i.name === entry.key)
    if (!!isMultiSelect) {
      const filterStrings = entry.value.split(STRING_SEPARATOR)
      const multiselects = MULTISELECT_FILTERS.
        flatMap(item => item.options)
        .filter(item => filterStrings.includes(item.value))
      appliedFiltersStr = multiselects.map(item => item.name).join(", ")
    } else {
      appliedFiltersStr = entry.value.split(STRING_SEPARATOR).join(", ")
    }

    return (
      <FiltersContainer>
        {
          advancedFilters?.slice(0, 1)?.map((item) => (
            <FilterLabel
              key={item.key}
              label={`${item.key} ${item.matcher}`}
              text={appliedFiltersStr}
            />
          ))
        }
        {
          advancedFilters?.length > 1 && (
            <span>+ {advancedFilters?.length - 1} filters</span>
          )
        }
      </FiltersContainer>
    )
  }, [advancedFilters])

  const handleSubmit = () => {
    const advancedFiltersMap = advancedFilters?.reduce((acc, item) => {
      return {
        ...acc,
        ...parseFilterExpressions(item, advancedFilterOptions)
      }
    }, {})
    const params = Object.fromEntries([...searchParams])
    const otherFilters = {
      ...(!!params[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: params[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      ...(!!params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] && { [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] }),
    }
    const filtersSelected = {
      ...selection.id,
      ...advancedFiltersMap,
      ...otherFilters
    }
    onSubmit({
      name,
      value: new URLSearchParams(filtersSelected).toString(),
      isDefault,
    })
  }

  useEffect(() => {
    if (!!filter) {
      setName(filter.name)
      toggleDefault(filter.isDefault)
    }
  }, [filter])

  useEffect(() => {
    if (!!name && (filter?.name !== name)) {
      if (checkUniqueness(name)) {
        toggleNameUnique(true)
      } else {
        toggleNameUnique(false)
      }
    } else {
      toggleNameUnique(false)
    }
  }, [name, checkUniqueness, filter])

  const disabled = !name || isNameNotUnique

  return (
    <SaveFiltersContainer>
      <Header>
        <span>{title}</span>
        <Icon name="close" width="14" height="14" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>
      <Note>
        {type === "dashboard" ? MAIN_DBNOTE_TEXT : REPORTS_NOTE_TEXT}
      </Note>
      <Body>
        <Inputs>
          <InputContainer>
            <Label>View name <span style={{ color: "red" }}>&nbsp;*</span></Label>
            <TextInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
            {
              isNameNotUnique && (
                <ErrorMessage text="Name already exists" />
              )
            }
          </InputContainer>
          <InputContainer>
            <Label>Selection <span style={{ color: "red" }}>&nbsp;*</span></Label>
            <TextBox title={selectionText}>
              {selectionText}
            </TextBox>
          </InputContainer>
          {
            showAdvancedFilters && (
              <InputContainer>
                <Label>Applied filters</Label>
                {advancedFilterSelection}
              </InputContainer>
            )
          }
        </Inputs>
        <Selections>
          <Checkbox
            key={CHECKBOX_OPTIONS[0].name}
            id={CHECKBOX_OPTIONS[0].name}
            label={CHECKBOX_OPTIONS[0].name}
            checked={isDefault}
            onChange={() => toggleDefault(val => !val)}
          />
        </Selections>
      </Body>
      <Footer>
        <Button
          text="Cancel"
          onClick={onClose}
        />
        <Tooltip text={disabled ? `Enter ${isNameNotUnique ? "unique" : ""} view name` : ""}>
          <Button
            text="Save view"
            type="submit"
            icon={isLoading && "loading"}
            disabled={disabled || isLoading}
            onClick={handleSubmit}
          />
        </Tooltip>
      </Footer>
    </SaveFiltersContainer>
  );
}

export default SaveFilterForm;

import styled from "styled-components";

export const ScoreProgressBarContainer = styled.div`
  display: flex;
  gap: 1rem;
`
export const Bar = styled.div`
  width: 90px;
  height: 4px;
  background-color: #E2E6F1;
  border-radius: 0.2rem;
  position: relative;
`
export const ProgressBar = styled.div`
  height: 4px;
  width: ${props => props.width};
  background-color: #5665D1;
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.4s linear;
`
import React from 'react';

import { ErrorText } from "./styles"

const ErrorMessage = ({ text }) => {
  return (
    <ErrorText>{text}</ErrorText>
  );
}

export default ErrorMessage;

import styled from "styled-components";

export const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  border: 1px solid #E2E8F0;
  border-radius: 0.3rem;
`
export const IconContainer = styled.div`
  padding: 0.3rem;
  border-radius: 0.3rem;
  margin-left: 0.6rem;
`
export const Field = styled.input`
  font-size: 0.9rem;
  font-weight: 400;
  color: #373F50;
  width: 100%;
  padding: 0.6rem;
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 0.3rem;
  cursor: ${props => props.disabled ? "not-allowed" : "auto"}
`

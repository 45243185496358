import React, { useEffect, useState } from "react";

import {
  TabContainer,
  Option,
  InfoContainer,
  Arrow,
  Badge,
} from "./styles";

import Icon from "../Icon";

const Tab = ({
  options,
  displayKey = "title",
  badgeKey = "badge",
  iconKey = "icon",
  activeIconKey = "activeIcon",
  selected,
  onTabChange,
  tabOptionStyle,
}) => {
  const [active, setActive] = useState("");
  const [isHovering, toggleHovering] = useState(false);
  const [hoverId, setHoverId] = useState("");

  useEffect(() => {
    setActive(selected || "");
  }, [selected]);

  return (
    <TabContainer>
      {!!options?.length &&
        options.map((item) => (
          <Option
            key={JSON.stringify(item)}
            active={active === item[displayKey]}
            disabled={item.disabled}
            onMouseEnter={() => {
              if (item?.disabled) {
                toggleHovering(true);
                setHoverId(JSON.stringify(item));
              }
            }}
            onMouseLeave={() => {
              if (item?.disabled) {
                toggleHovering(false);
                setHoverId("");
              }
            }}
            onClick={() => {
              if (!item?.disabled) {
                onTabChange(item);
              }
            }}
            tabOptionStyle={tabOptionStyle}
          >
            {!!item?.[iconKey] && <Icon name={active === item[displayKey] ? (item?.[activeIconKey] || item[iconKey]) : item[iconKey]} alt={item[displayKey]} />}
            {!!item?.renderTitleText
              ? item.renderTitleText()
              : item[displayKey]}
            {!!item?.[badgeKey] && <Badge>{item[badgeKey]}</Badge>}
            {isHovering &&
              hoverId === JSON.stringify(item) &&
              item?.disabledText && (
                <>
                  <InfoContainer>{item?.disabledText}</InfoContainer>
                  <Arrow />
                </>
              )}
          </Option>
        ))}
    </TabContainer>
  );
};

export default Tab;

import styled from "styled-components";

export const BadgeContainer = styled.div`
  padding: 0.6rem 1.1rem;
  border-radius: 1rem;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.type === "active" ? "#2E7D32" : props.type === "completed" ? "#3D4759" : "#E65100"};
  background: ${props => props.type === "active" ? "#E8F5E9" : props.type === "completed" ? "#EDF2F7" : "#FFF3E0"};
  width: fit-content;
`

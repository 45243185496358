import React, { useEffect, useRef } from "react";
import {
  EmptyStateContainer,
  EmptyStateHeader,
  EmptyStateText,
  EmptyStateTextContainer,
  InsightContentContainer,
  InsightContentTitle,
  InsightOverlay,
  InsightsContainer,
  InsightSidebarContainer,
  InsightSidebarContent,
  InsightSidebarContentHeader,
  InsightSidebarHeader,
  InsightSidebarHeaderTitle,
  TextBold,
} from "./styles";
import Icon from "../common/Icon";
import InsightContentCard from "./InsightContentCard";
import { useFetchInsights, usePostFeedback } from "../../api/insights";
import useOnClickOutside from "../../utils/useOnClickOutside";

const InsightSidebar = ({ onClose, params }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  const { mutateAsync: postFeedback } = usePostFeedback();

  const buildInsightPayload = () => {
    const entities = [
      { id: params.get("jobgroupId"), type: "JOBGROUP" },
      { id: params.get("campaignId"), type: "CAMPAIGN" },
      { id: params.get("clientId"), type: "CLIENT" },
      { id: params.get("subAccountId"), type: "SUBACCOUNT" },
      { id: params.get("accountId"), type: "ACCOUNT" },
    ];

    const { id: entityId, type: entityType } =
      entities.find((entity) => entity.id) || {};

    return {
      account_id: params.get("accountId"),
      entity_type: entityType,
      entity_id: entityId,
    };
  };

  const {
    data: insights,
    isLoading: isInsightsLoading,
    refetch: refetchInsights,
  } = useFetchInsights(buildInsightPayload());

  return (
    <InsightOverlay>
      <InsightSidebarContainer ref={ref}>
        <InsightSidebarHeader>
          <InsightSidebarHeaderTitle>
            <Icon
              name="insightsIcon"
              className="insights-icon"
              style={{ height: 28, width: 28, filter: "brightness(2)" }}
            />
            Insights
          </InsightSidebarHeaderTitle>
          <Icon
            name="close"
            className="close-icon"
            height={16}
            width={16}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          />
        </InsightSidebarHeader>
        {insights?.data &&
          Object.keys(insights?.data).length > 0 &&
          !isInsightsLoading && (
            <InsightSidebarContent>
              <InsightSidebarContentHeader>
                Here are the Insights to improve your overall performance.
              </InsightSidebarContentHeader>
              {Object.keys(insights?.data).map((item) => {
                const insightData = insights?.data[item];
                const insightGroupName =
                  insightData?.length > 0 && insightData[0]?.insightGroup;
                return (
                  <InsightContentContainer>
                    <InsightContentTitle>
                      {insightGroupName}
                      <span className="vertical-line"></span>
                    </InsightContentTitle>
                    <InsightsContainer>
                      {insightData.map((content) => {
                        return (
                          <InsightContentCard
                            key={content.id}
                            content={content}
                            submitFeedback={postFeedback}
                          />
                        );
                      })}
                    </InsightsContainer>
                  </InsightContentContainer>
                );
              })}
            </InsightSidebarContent>
          )}
        {(!insights?.data || Object.keys(insights?.data).length === 0) &&
          !isInsightsLoading && (
            <InsightSidebarContent
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <EmptyStateContainer>
                <Icon name="no-updates-roadmap" alt="no-updates-roadmap" />
                <EmptyStateTextContainer>
                  <EmptyStateHeader>No insights</EmptyStateHeader>
                  <EmptyStateText>
                    There are no insights at the moment. Check back later for
                    updates!
                  </EmptyStateText>
                </EmptyStateTextContainer>
              </EmptyStateContainer>
            </InsightSidebarContent>
          )}
      </InsightSidebarContainer>
    </InsightOverlay>
  );
};

export default InsightSidebar;

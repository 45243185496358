import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const NavBarContainer = styled.div`
  height: 3.5rem;
  min-height: 3.5rem;
  background-color: #1c2536;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem 0 0;

  svg {
    color: #ffffff;
  }
`;
export const TitleAndFilterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  width: 14rem;

  @media screen and ${devices.mobile} {
    display: none;
  }
`
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 100%;

  @media screen and ${devices.mobile} {
    gap: 1rem;
    padding: 0 1rem;
  }
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #e8eaf6;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HamburgerMenuContainer = styled.span`
  display: none;

  @media screen and ${devices.mobile} {
    display: block;
    z-index: 3;
    cursor: pointer;
    margin-top: 0.3rem;
  }
`;

export const AppSwitcher = styled.div`
`;

export const NotificationIcon = styled.div`
  display: ${({ display }) => display ? "block" : "none"};
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 700;
`;

export const RoadmapLogo = styled.div`
  position: relative;
  top: 3px;
  margin-right: 10px;
  @media screen and ${devices.mobile} {
      display: none;
  }
`;
export const InsightIconContainer = styled.div`
  cursor: pointer;
  width: 108px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #EDF2F7;
  gap: 8px;
  margin-right: 20px;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;

export const InsightText = styled.span`
  color: #131A39;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
`;

import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import {
  ProgrammaticContainer,
  ContentContainer,
  FiltersContainer,
  EmptyContainer
} from "./styles";

import Subheader from "../../components/common/Subheader";
import EntityCountSummaryData from "../../components/EntityCountSummaryData";
import EmbeddedDashboard from "../../components/EmbeddedDashboard";
import AdvancedFilters from "../../components/AdvancedFilters";
import Modal from "../../components/common/Modal";
import Dialog from "../../components/common/Dialog";
import ViewMore from "../../components/ViewMore";
import JobLevelBreakdown from "../../components/JobLevelBreakdown";
import Loader from "../../components/common/Loader";
import Upgrade from "../Upgrade";
import DetailedInsights from "../../components/SalesDemo/DetailedInsights";

import {
  DEVICE_SIZES_PX,
  HOME_DASHBOARD_VIEW,
  LOOKER_FILTER_KEYS,
  MIXPANEL_ATTRIBUTES_KEY,
  EXCHANGE_VISIBILITY,
  PROGRAMMATIC_IDENTIFIERS,
  EMAIL_LS_KEY,
  DEFAULT_COUNTRY_MAP_AMAZON,
} from "../../constants/AppConstants";
import { parseFilterExpressions, toFilterExpression } from "../../utils/helpers";
import { hasFiltersChangedInSavedFilter } from "../../components/MainApp/helpers"
import Mixpanel from "../../services/MixPanel";
import { buildEntityCountSummaryPayload, getAdvancedFiltersFromFilters } from "./helpers";
import { useFetchEntityCountSummary } from "../../api/programmatic";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { getSelectedEntityName, searchHierarchyAcrossProducts } from "../../utils/searchHelpers";
import { onTrackMixPanelEvent } from "../../helpers/eventHandler";
import { getItemFromLocalStorage } from "../../helpers/commonHelper";

export const Programmatic = ({
  viewMoreDb,
  jobGroupDetails,
  setViewMoreDb,
  setJobGroupDetails,
  advancedFilters,
  advancedFilterOptions,
  setAdvancedFilters,
  isCurrentDashboardUADashboard,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    dashboards,
    hierarchy,
    publisherFilter,
    exchangeVisibility,
    views,
    products,
    weekStart,
    isDemoDashboard,
    detailedInsightsFigmaLinks
  } = useGlobalDataStore(
    useShallow((state) => ({
      dashboards: state.dashboards,
      hierarchy: state.hierarchy,
      views: state.views,
      publisherFilter: state.publisherFilter,
      exchangeVisibility: state.exchangeVisibility,
      products: state.products,
      weekStart: state.weekStart,
      isDemoDashboard: state.isDemoDashboard,
      detailedInsightsFigmaLinks: state.detailedInsightsFigmaLinks
    }))
  );

  const {
    globalFilters,
    embedUrl,
  } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      embedUrl: state.embedUrl,
    }))
  );

  const [isDialogOpen, toggleDialog] = useState(false);
  const [isDbLoading, toggleDbLoad] = useState(false);
  const [isJobDialogOpen, toggleJobDialog] = useState(false);
  const prevFilters = useRef(null);

  const {
    data: { data: entityCountSummary } = {},
  } = useFetchEntityCountSummary(buildEntityCountSummaryPayload(globalFilters))

  const localFilters = useMemo(() => {
    const email = getItemFromLocalStorage(EMAIL_LS_KEY)
    const defaultCountry = (() => {
      const country = DEFAULT_COUNTRY_MAP_AMAZON.find(item => item.email === email)
      return country?.defaultCountry || ""
    })()
    if (!advancedFilters.length) return { ...globalFilters, weekStartDay: String(weekStart), defaultCountry };
    const combined = advancedFilters
      .map((item) => ({
        ...item,
        ...advancedFilterOptions.find((i) => i.name === item.key),
      }))
      .reduce(
        (acc, item) => ({
          ...acc,
          ...parseFilterExpressions(item, advancedFilterOptions),
        }),
        {}
      );
    return {
      ...globalFilters,
      ...combined,
      weekStartDay: String(weekStart),
      defaultCountry
    };
  }, [globalFilters, advancedFilters, advancedFilterOptions, weekStart]);

  const salesDemoDashboards = useMemo(() => {
    if (!isDemoDashboard) return []
    const dbs = Object.entries(detailedInsightsFigmaLinks).map(([key, value]) => ({
      title: key,
      iframeSrc: value
    }))
    return dbs
  }, [detailedInsightsFigmaLinks, isDemoDashboard])

  const handleFilterSelection = (filters) => {
    setAdvancedFilters(filters);
    onTrackMixPanelEvent(
      "Advance filter change",
      {
        "Screen name": "Programmatic Analytics",
        "Filters Selected": filters
      }
    );
  };

  useEffect(() => {
    if (!!globalFilters) {
      const {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
        [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
        [LOOKER_FILTER_KEYS.FILTER_ID]: filterId,
      } = globalFilters;
      let doesFilterHaveAdvancedFilters = false
      if (!!filterId) {
        const advancedFilters = getAdvancedFiltersFromFilters(filterId, views)
        if (!!advancedFilters?.length) {
          doesFilterHaveAdvancedFilters = true
        }
      }
      const prev = prevFilters.current;
      const shouldClear = (doesFilterHaveAdvancedFilters) ? false : (
        accountId !== prev?.accountId ||
        subAccountId !== prev?.subAccountId ||
        clientId !== prev?.clientId ||
        campaignId !== prev?.campaignId ||
        jobGroupId !== prev?.jobgroupId
      )
      if (shouldClear) {
        setAdvancedFilters([]);
      }
      prevFilters.current = globalFilters;
    }
  }, [globalFilters, views, setAdvancedFilters]);

  useEffect(() => {
    if (viewMoreDb) {
      toggleDialog(true);
    }
  }, [viewMoreDb]);

  useEffect(() => {
    return () => {
      setViewMoreDb(null);
      toggleDialog(false);
      toggleJobDialog(false);
      if (window.innerWidth > DEVICE_SIZES_PX.MOBILE) {
        setJobGroupDetails(null);
      }
    };
  }, []);

  useEffect(() => {
    if (!!jobGroupDetails && window.innerWidth > DEVICE_SIZES_PX.MOBILE) {
      toggleJobDialog(true);
    } else if (window.innerWidth > DEVICE_SIZES_PX.MOBILE) {
      toggleJobDialog(false);
    }
  }, [jobGroupDetails]);

  useEffect(() => {
    if (hierarchy) {
      if (
        (hierarchy?.length === 1 ||
          exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) &&
        hierarchy?.[0]?.clients?.length === 1 &&
        globalFilters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === "" &&
        globalFilters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === "" &&
        globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID] === ""
      ) {
        Mixpanel.trackEvent("Analytics Screen Opened", {
          [MIXPANEL_ATTRIBUTES_KEY.ACCOUNT_NAME]: hierarchy?.[0]?.name,
          [MIXPANEL_ATTRIBUTES_KEY.SUB_ACCOUNT_NAME]: hierarchy?.[0]?.subAccounts?.[0]?.name,
          [MIXPANEL_ATTRIBUTES_KEY.CLIENT_NAME]: hierarchy?.[0]?.subAccounts?.[0]?.clients?.[0]?.name,
        });

        return;
      } else if (
        (hierarchy?.length === 1 ||
          exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) &&
        globalFilters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === ""
      ) {
        Mixpanel.trackEvent("Analytics Screen Opened", {
          [MIXPANEL_ATTRIBUTES_KEY.ACCOUNT_NAME]: hierarchy?.[0]?.name,
        });

        return;
      } else {
        Mixpanel.trackEvent("Analytics Screen Opened", {});
        return;
      }
    }
  }, [
    hierarchy,
  ])

  useEffect(() => {
    const params = Object.fromEntries([...searchParams])
    const filterId = params[LOOKER_FILTER_KEYS.FILTER_ID]
    if (!!filterId && !!views?.length) {
      const advancedFilters = getAdvancedFiltersFromFilters(filterId, views)
      if (!!advancedFilters?.length) {
        const parsed = advancedFilters.map(item => toFilterExpression(item, advancedFilterOptions)).filter(Boolean)
        setAdvancedFilters(parsed)
      }
      const hasGlobalFiltersChanged = hasFiltersChangedInSavedFilter(params[LOOKER_FILTER_KEYS.FILTER_ID], views, params)
      if (hasGlobalFiltersChanged) {
        setAdvancedFilters([])
      }
    }
  }, [searchParams, views, advancedFilterOptions])

  const isSalesDemo = (() => {
    if (!isDemoDashboard) return false
    const key = viewMoreDb?.dashboardKey
    if (Object.keys(detailedInsightsFigmaLinks).includes(key)) return true
    return false
  })()

  const salesDemoModalClasses = (() => {
    if (!isDemoDashboard || !Object.keys(detailedInsightsFigmaLinks || {})?.length) return {}
    const keys = Object.keys(detailedInsightsFigmaLinks)
    const classMap = keys.reduce((acc, key) => {
      acc[key] = "close"
      return acc
    }, {})
    if (!!isDialogOpen) {
      if (keys.includes(viewMoreDb?.dashboardKey)) {
        classMap[viewMoreDb?.dashboardKey] = "open"
      }
    }
    return classMap
  })()

  const isLoading = !products?.length || !hierarchy || !embedUrl;

  const isLocked = !products?.length ||
    !hierarchy ||
    !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
    !products.find(item => item.productKey === PROGRAMMATIC_IDENTIFIERS.productKey) ||
    !hierarchy?.[PROGRAMMATIC_IDENTIFIERS.productKey]?.find(item => item?.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]);

  if (isLocked && !isLoading) {
    const results = searchHierarchyAcrossProducts(hierarchy, globalFilters)
    const entity = results?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || results?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
    return (
      <Upgrade
        entity={entity}
        {...PROGRAMMATIC_IDENTIFIERS.unlockDetails}
      />
    )
  }

  return (
    <ProgrammaticContainer
      isSalesDemo={isSalesDemo}
      className="ProgrammaticContainer"
      isCurrentDashboardUADashboard={isCurrentDashboardUADashboard}
    >
      {isCurrentDashboardUADashboard && (
        <EntityCountSummaryData
          entityCountData={entityCountSummary}
        />
      )}
      <ContentContainer>
        {isCurrentDashboardUADashboard && (
          <Subheader
            title={PROGRAMMATIC_IDENTIFIERS.display}
            entity={getSelectedEntityName(hierarchy, globalFilters, PROGRAMMATIC_IDENTIFIERS).name}
            entityType={getSelectedEntityName(hierarchy, globalFilters, PROGRAMMATIC_IDENTIFIERS).type}
            advancedFilters={advancedFilters}
            advancedFilterOptions={advancedFilterOptions}
            filtersDisabled={isDbLoading}
            showAdvancedFiltersInForm={true}
          />
        )}
        {isCurrentDashboardUADashboard && (
          <FiltersContainer>
            <AdvancedFilters
              filters={advancedFilterOptions}
              view={HOME_DASHBOARD_VIEW}
              onChange={handleFilterSelection}
              value={advancedFilters}
              showPublisherFilter={publisherFilter}
              className="AdvancedFilters_Main"
              disabled={isDbLoading}
            />
          </FiltersContainer>
        )}
        {
          (!!embedUrl && !isLoading) && (
            <EmbeddedDashboard
              embedURL={embedUrl}
              lookerFilter={globalFilters}
              localWidgetFilters={localFilters}
              onDbLoad={toggleDbLoad}
              dashboards={dashboards}
            />
          )
        }
        {
          isLoading && (
            <EmptyContainer>
              <Loader size="medium" />
            </EmptyContainer>
          )
        }
      </ContentContainer>
      {
        !isSalesDemo && (
          <>
            {
              isDialogOpen && (
                <Modal onClose={() => toggleDialog(false)}>
                  <Dialog title={viewMoreDb?.title} onClose={() => toggleDialog(false)}>
                    <ViewMore
                      dbDetails={viewMoreDb}
                      advancedFilters={advancedFilters}
                      advancedFilterOptions={advancedFilterOptions}
                    />
                  </Dialog>
                </Modal>
              )
            }
          </>
        )
      }
      {
        (!!isDemoDashboard) && (
          <>
            {
              salesDemoDashboards?.map((item) => (
                <Modal onClose={() => toggleDialog(false)} className={salesDemoModalClasses[item.title]}>
                  <Dialog title={viewMoreDb?.title} onClose={() => toggleDialog(false)}>
                    <DetailedInsights iframeSrc={item.iframeSrc} />
                  </Dialog>
                </Modal>
              ))
            }
          </>
        )
      }
      {isJobDialogOpen && (
        <Modal onClose={() => toggleJobDialog(false)}>
          <Dialog
            subtitle={`Job group : ${jobGroupDetails?.jobGroupName || ""}`}
            title="Job level breakdown"
            onClose={() => toggleJobDialog(false)}
          >
            <JobLevelBreakdown
              jobGroupDetails={jobGroupDetails}
              advancedFiltersFromParent={advancedFilters}
              showPublisherFilter={publisherFilter}
            />
          </Dialog>
        </Modal>
      )}
    </ProgrammaticContainer>
  );
}

export default Programmatic;

import { UNIFIED_ANALYTICS_ORIGIN } from "./AppConstants";

const UA_CLOUNDFRONT_STAGING = "https://doltff1luzi93.cloudfront.net"
const UA_CLOUNDFRONT_PRE_PROD = "https://doltff1luzi93.cloudfront.net"
const UA_CLOUNDFRONT_PROD = "https://doltff1luzi93.cloudfront.net"

export const UNIFIED_ANALYTICS_CLOUDFRONT_MAP = {
  [UNIFIED_ANALYTICS_ORIGIN.STAGING]: UA_CLOUNDFRONT_STAGING,
  [UNIFIED_ANALYTICS_ORIGIN.PRE_PROD]: UA_CLOUNDFRONT_PRE_PROD,
  [UNIFIED_ANALYTICS_ORIGIN.PROD]: UA_CLOUNDFRONT_PROD,
}

export const UNIFIED_ANALYTICS_CLOUDFRONT_URL = (() => {
  const url =
    UNIFIED_ANALYTICS_CLOUDFRONT_MAP[window.location.origin] ||
    UNIFIED_ANALYTICS_CLOUDFRONT_MAP[UNIFIED_ANALYTICS_ORIGIN.STAGING];
  return url;
})()

export const getIconUrl = (name) => `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/icons/${name}.svg`

export const getImageUrl = (name) => `${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/images/${name}`

import styled from "styled-components";
import { devices } from "../../constants/AppConstants";
import { SearchBoxContainer } from "../../components/common/SearchBox/styles";

export const SchedulesContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100vh - 3.5rem);

  .Schedule_report {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const LoaderContainer = styled.div`
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;

  @media only screen and ${devices.mobile} {
    flex: 1 1 auto;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
    height: 0.8rem;
    width: 0.8rem;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`
export const Title = styled.h3`
  color: #161E2E;
  font-size: 1.2rem;
  font-weight: 600;
`
export const Subtitle = styled.div`
  color: #6A7185;
  font-weight: 400;
  font-size: 0.8rem;
`
export const Body = styled.div`
  padding-bottom: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  ${SearchBoxContainer} {
    min-width: 30vw;
  }

  .ScheduleTable {
    flex: 1;
  }

  th, td, table {
    border: none !important;
  }

  td {
    border-top: 1px solid #EDF2F7 !important;
    border-bottom: 1px solid #EDF2F7 !important;
  }

  table {
    border-bottom: 1px solid #EDF2F7 !important;
  }

  @media only screen and ${devices.mobile} {
    gap: 1rem;
  }
`
export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and ${devices.mobile} {
    flex-direction: column;
    align-items: normal;
    gap: 1rem;
  }
`
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.6rem;
  justify-self: flex-end;
  align-self: flex-end;

  @media only screen and ${devices.mobile} {
    flex: 0 0 auto;
  }
`
export const ReportName = styled.p`
  color: #161E2E;
  font-size: 0.9rem;
  font-weight: 400;
`
export const ReportType = styled.p`
  color: #6B7280;
  font-size: 0.8rem;
  font-weight: 400;
`
export const TableText = styled.p`
  color: #3D4759;
  font-size: 0.9rem;
  font-weight: 400;
`

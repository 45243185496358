import React, { useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  JobRowContainer,
  RowContainer,
  LabelContainer,
  TitleText,
  TitleContainer,
  ScoreContainer,
  TableContainer,
  GetScoreButton,
  EmptyContainer,
  PaginationContainer
} from "./styles";

import Icon from '../../../components/common/Icon';
import EmptyText from '../../../components/common/EmptyText';
import Loader from '../../../components/common/Loader';
import SearchBox from "../../../components/common/SearchBox";

import { useFetchJobs } from '../../../api/jobs';
import { buildPayload, parseFilters } from './helpers';
import {
  BREAKDOWN_TYPE,
  JOBGROUP_GROUP,
  JOBS_API_CONFIG,
  JOBS_BREAKDOWN_CAMPAIGN_FIELD,
  JOBS_BREAKDOWN_JOB_GROUP_FIELD,
  JOBS_BREAKDOWN_JOB_TITLE_KEY,
  JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY
} from '../constants';
import Tooltip from '../../../components/common/Tooltip';
import DataTable from '../../../components/common/DataTable';
import Pagination from '../../../components/common/Pagination';
import { onTrackMixPanelEvent } from '../../../helpers/eventHandler';
import Mixpanel from '../../../services/MixPanel';
import { useGlobalDataStore } from '../../../stores/global';

const COLUMNS = [
  {
    label: "Job title",
    keyParser: () => JOBS_BREAKDOWN_JOB_TITLE_KEY,
    headerParser: (label) => {
      return <div className="Table__title">{label}</div>
    },
    cellParser: ({ cell }) => {
      return <div className="Table__text">{cell.getValue()}</div>
    }
  },
  {
    label: "Campaign name",
    keyParser: (type) => {
      return type === JOBGROUP_GROUP ? JOBS_BREAKDOWN_CAMPAIGN_FIELD : JOBS_BREAKDOWN_JOB_GROUP_FIELD
    },
    headerParser: (_, type) => {
      return <div className="Table__title">{type === JOBGROUP_GROUP ? "Campaign name" : "Jobgroup name"}</div>
    },
    cellParser: ({ cell }) => {
      return <div className="Table__text">{cell.getValue()}</div>
    }
  },
  {
    label: "Quality score",
    keyParser: () => JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY,
    headerParser: (label) => {
      return <div className="Table__title">{label}</div>
    },
    cellParser: ({ cell, onGetScore }) => {
      return <div className="Table__score">
        {
          !!cell.getValue()
            ? `${parseInt(cell.getValue() * 100)}%`
            : (
              <GetScoreButton
                onClick={() => {
                  onGetScore({ ...cell.row.original });
                  Mixpanel.trackEvent("Get Score Clicked", {
                    Job:
                      cell?.row?.original?.[JOBS_BREAKDOWN_JOB_TITLE_KEY] || "",
                  });
                }}
              >
                <Icon name="getScore" alt="get score" />
                Get your score
              </GetScoreButton>
            )
        }
      </div>
    }
  },
  {
    label: "Detailed view",
    keyParser: () => JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY,
    headerParser: () => <div className="Table__title">Detailed view</div>,
    cellParser: ({ cell, onExpand }) => {
      return <div className="Table__text">
        <Tooltip text={!!cell.getValue() ? "Expand job" : "Job details unavailable"}>
          <Icon
            name="link"
            alt="link"
            disabled={!cell.getValue()}
            className={`Table__link ${!cell.getValue() ? " Table__link-disabled" : " "}`}
            onClick={() => {
              if (!!cell.getValue()) {
                onExpand({ ...cell.row.original })
              }
            }}
          />
        </Tooltip>
      </div>
    }
  },
]

const getColumns = ({ type, onExpand, onGetScore }) => {
  const columns = COLUMNS.map((item) => ({
    header: item.headerParser(item.label, type),
    accessorFn: (row) => row[item.keyParser(type)],
    backgroundColor: "#F7F8FA",
    id: `${JSON.stringify(item)}`,
    cell: (cell) => item.cellParser({ cell, onExpand, onGetScore }),
    sortingFn: "alphanumeric"
  }))
  return columns
}

const JobRow = ({
  job,
  filters,
  type,
  isExpanded,
  onRowExpand,
  onGetScore,
  onExpand
}) => {
  const { models } = useGlobalDataStore(useShallow((state) => ({
    models: state.models,
  })))

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const rowRef = useRef(null)

  const { title, label, score } = (() => {
    const config = JOBS_API_CONFIG.AGGREGATED[type]

    return config.dataParser(job, type)
  })()

  const {
    data: { data: jobs = [] } = {},
    isLoading: isJobsLoading,
  } = useFetchJobs(
    buildPayload(
      filters,
      job,
      JOBS_API_CONFIG[BREAKDOWN_TYPE][type],
      models
    ),
    parseFilters(
      { filters, isExpanded },
      {
        ...JOBS_API_CONFIG[BREAKDOWN_TYPE][type].FIELDS,
        ...JOBS_API_CONFIG[BREAKDOWN_TYPE][type].getAdditionalFilters(job)
      }
    )
  );

  return (
    <JobRowContainer ref={rowRef}>
      <RowContainer onClick={onRowExpand} isOpen={isExpanded}>
        <TitleContainer>
          <TitleText isTitle={true}>{title}</TitleText>
          <LabelContainer>{label}</LabelContainer>
        </TitleContainer>
        <ScoreContainer>
          <TitleText>{score}</TitleText>
          <Icon name="arrow-left" className="JobRow__arrow" />
        </ScoreContainer>
      </RowContainer>
      <TableContainer isOpen={isExpanded}>
        {
          isJobsLoading && (
            <EmptyContainer>
              <Loader />
            </EmptyContainer>
          )
        }
        {
          (!isJobsLoading && !jobs?.length && isExpanded) && (
            <EmptyContainer>
              <EmptyText />
            </EmptyContainer>
          )
        }
        {
          (!isJobsLoading && !!jobs?.length) && (
            <>
              <SearchBox
                placeholder="Search jobs"
                value={searchQuery}
                className="Job-quality__SearchBox"
                onChange={setSearchQuery}
              />
              <DataTable
                data={jobs}
                columns={getColumns({ type, onGetScore, onExpand })}
                pinnedColumns={[]}
                className="JobRow__table"
                searchText={searchQuery}
                page={page}
                headerOptionsEnabled={false}
                onPageCountChange={(pg) => {
                  setPageCount(pg)
                  setPage(1)
                }}
                onPinnedColumnsChange={() => { }}
              />
              <PaginationContainer>
                <Pagination
                  pageCount={pageCount}
                  currentPage={page}
                  onPageChange={(p) => {
                    setPage(p)
                    if (p === 1) {
                      rowRef.current.parentNode.scrollTop = rowRef.current.offsetTop - rowRef.current.parentNode.offsetTop;
                    } else {
                      rowRef.current.parentNode.scrollTo({ top: rowRef.current.offsetTop - rowRef.current.parentNode.offsetTop, behavior: "smooth" })
                    }
                  }}
                />
              </PaginationContainer>
            </>
          )
        }
      </TableContainer>
    </JobRowContainer>
  );
}

export default JobRow;

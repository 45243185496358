import styled from "styled-components";

export const PremiumFeatureContainer = styled.div`
  position: relative;
`;

export const PremiumFeatureText = styled.div`
  display: flex;
  gap: 4px;
  padding: 0.25rem 1rem;
  border: 2px solid #e2e8f0;
  background-color: white;
  width: fit-content;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  align-items: center;

  .pro-text {
    color: #e0a10c;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }

  .feature-text {
    color: var(--gray-text-04, #3d4759);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const ArrowBoxTextContainer = styled.div``;

export const ArrowBoxTextHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const ArrowBoxText = styled.div`
  color: var(--Plain-Gray-500, #6b7280);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export const ArrowBoxTextHeadingText = styled.div`
  .pro-text {
    color: #e0a10c;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }

  .feature-text {
    color: var(--gray-text-04, #3d4759);
    margin-left: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

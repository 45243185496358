import React, { useMemo, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { MainContentContainer } from "./styles";

import Programmatic from "../../pages/Programmatic";
import JobQuality from "../../pages/JobQuality"
import ViewMoreFullPage from "../ViewMoreFullPage";
import Reports from "../../pages/Reports";
import CustomDashboards from "../../pages/CustomDashboards";

import { LOOKER_FILTER_KEYS } from "../../constants/AppConstants";
import { useFetchCIFFields } from "../../api/reports";
import JobLevelBreakdown from "../JobLevelBreakdown";
import {
  CUSTOM_DASHBOARDS_ROUTE_IDENTIFIER,
  DETAILED_INSIGHTS_ROUTE_IDENTIFIER,
  JOBS_BREAKDOWN_ROUTE,
  JOB_QUALITY_ROUTE_IDENTIFIER,
} from "../../constants/AppRoutes";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { buildAdvancedFilterOptions } from "./helpers";

function MainContent({
  viewMoreDb,
  jobGroupDetails,
  setViewMoreDb,
  setJobGroupDetails,
  dbDetails,
  isCurrentDashboardUADashboard,
  onAdvancedFilterChange
}) {
  const {
    globalFilters,
  } = useGlobalFiltersStore(useShallow((state) => ({
    globalFilters: state.globalFilters,
  })));

  const {
    publisherFilter,
  } = useGlobalDataStore(useShallow((state) => ({
    publisherFilter: state.publisherFilter,
  })))

  const [advancedFilters, setAdvancedFilters] = useState([])

  const { data: { data: { fields: cifFields } = {} } = {} } = useFetchCIFFields(
    globalFilters[LOOKER_FILTER_KEYS.CLIENT_ID]
  );

  const advancedFilterOptions = useMemo(() => {
    return buildAdvancedFilterOptions({
      globalFilters,
      publisherFilter,
      cifFields
    })
  }, [publisherFilter, cifFields, globalFilters])

  return (
    <MainContentContainer>
      <Routes>
        <Route path="reports/*" element={<Reports
          onAdvancedFilterChange={onAdvancedFilterChange}
        />} />
        <Route
          path={DETAILED_INSIGHTS_ROUTE_IDENTIFIER}
          exact
          element={
            <ViewMoreFullPage
              dbDetails={dbDetails}
              uiConfig={{
                width: window.innerWidth,
              }}
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
            />
          }
        />
        <Route
          path={JOB_QUALITY_ROUTE_IDENTIFIER}
          element={
            <JobQuality
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
            />
          }
        />
        <Route
          path={JOBS_BREAKDOWN_ROUTE}
          element={
            <JobLevelBreakdown
              jobGroupDetails={jobGroupDetails}
              advancedFiltersFromParent={advancedFilters}
            />
          }
        />
        <Route
          path={CUSTOM_DASHBOARDS_ROUTE_IDENTIFIER}
          element={
            <CustomDashboards />
          }
        />
        <Route
          path="/"
          exact
          element={
            <Programmatic
              viewMoreDb={viewMoreDb}
              setViewMoreDb={setViewMoreDb}
              jobGroupDetails={jobGroupDetails}
              setJobGroupDetails={setJobGroupDetails}
              advancedFilters={advancedFilters}
              advancedFilterOptions={advancedFilterOptions}
              setAdvancedFilters={(advFilters) => {
                setAdvancedFilters(advFilters)
                onAdvancedFilterChange(advFilters, advancedFilterOptions)
              }}
              isCurrentDashboardUADashboard={isCurrentDashboardUADashboard}
            />
          }
        />
        {/* <Route path="*" element={<Navigate to="404" />} /> */}
      </Routes>
    </MainContentContainer>
  );
}

export default MainContent;

import React, { useRef } from "react";

import { ModalContainer, ModalDialog, ModalContent } from "./styles";

import useOnClickOutside from "../../../utils/useOnClickOutside";
import useLockScroll from "../../../utils/useLockScroll";

const Modal = ({
  className,
  position = "right",
  transparent = false,
  children,
  onClose,
  modalDialogStyle = {},
  modalContentStyle = {}
}) => {
  const modalRef = useRef(null);
  useLockScroll();
  useOnClickOutside(modalRef, () => {
    if (modalRef.current.getBoundingClientRect().height > 0) {
      onClose()
    }
  });

  return (
    <ModalContainer className={className} transparent={transparent}>
      <ModalDialog position={position} style={modalDialogStyle}>
        <ModalContent ref={modalRef} position={position} style={modalContentStyle}>
          {children}
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  );
};

export default Modal;

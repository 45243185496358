import React, { useState, useMemo, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  SchedulesContainer,
  Header,
  TitleContainer,
  Title,
  Body,
  PaginationContainer,
  LoaderContainer,
  FilterContainer
} from './styles';

import Pagination from '../../components/common/Pagination';
import DataTable from '../../components/common/DataTable';
import Loader from '../../components/common/Loader';
import { alert } from '../../components/common/Toast';
import SearchBox from '../../components/common/SearchBox';
import TabFilter from '../../components/common/TabFilter';
import SaveOrSchedule from '../../components/common/SaveSchedule';

import { ALL_PRODUCTS, TITLE_TEXT, getColumns } from './helpers';
import { useDeleteLookerQueryMap, useDeleteScheduleReport, useFetchLookerQueryMap, useFetchSchedules } from '../../api/schedules';
import { LOOKER_USER_ID_KEY, PROGRAMMATIC_IDENTIFIERS } from '../../constants/AppConstants';
import { getItemFromLocalStorage } from '../../helpers/commonHelper';
import { useGlobalDataStore } from '../../stores/global';

const Schedules = () => {
  const {
    hierarchy,
    products,
    dashboards,
    setDashboards
  } = useGlobalDataStore(useShallow((state) => ({
    hierarchy: state.hierarchy,
    products: state.products,
    dashboards: state.dashboards,
    setDashboards: state.setDashboards
  })))

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [isEditing, toggleScheduleEditing] = useState(false)
  const [scheduleToEdit, setScheduleToEdit] = useState(null)
  const [scheduleToDelete, setScheduleToDelete] = useState(null)
  const [productFilter, setProductFilter] = useState()

  useEffect(() => {
    if (!!Object.keys(dashboards || {})?.length) {
      setDashboards(dashboards);
    }
  }, [dashboards, setDashboards])

  const {
    data: { data: schedules = [] } = {},
    isLoading: isSchedulesLoading
  } = useFetchSchedules({ user_id: getItemFromLocalStorage(LOOKER_USER_ID_KEY) })

  const {
    data: { data: lookerQueryMap = [] } = {},
    isLoading: isLookerQueryMapLoading
  } = useFetchLookerQueryMap()

  const { mutateAsync: deleteSchedule, isLoading: isDeleteScheduleLoading } =
    useDeleteScheduleReport();

  const { mutateAsync: deleteLookerQueryMap, isLoading: isDeleteLookerQueryMapLoading } =
    useDeleteLookerQueryMap();

  const productFilterOptions = useMemo(() => {
    if (!products) return []
    return [
      ALL_PRODUCTS,
      ...products?.map(product => ({
        ...product,
        key: product.id,
        display: product.display
      }))] || []
  }, [products])

  const filteredSchedules = useMemo(() => {
    if (!dashboards || !productFilterOptions?.length) return []
    if (!productFilter?.key && !searchText) return schedules
    if (!!searchText) {
      return schedules.filter(schedule => schedule.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
    }

    const product = productFilterOptions.find(item => item.display === productFilter.display) || PROGRAMMATIC_IDENTIFIERS
    const filtered = schedules?.filter(schedule => {
      if (!schedule?.lookml_dashboard_id && product.productKey === PROGRAMMATIC_IDENTIFIERS.productKey) return true
      return schedule?.lookml_dashboard_id === dashboards[product.dashboardMapIdentifierKey].id
    })
    return filtered
  }, [schedules, searchText, productFilter, productFilterOptions, dashboards])

  const handleOptionSelect = async (opt, payload) => {
    if (opt.title === "Edit") {
      toggleScheduleEditing(true)
      setScheduleToEdit(payload)
    } else if (opt.title === "Delete") {
      try {
        setScheduleToDelete(payload)
        if (!!payload?.query_id) {
          await deleteLookerQueryMap(payload?.query_id)
        }
        await deleteSchedule(payload?.id)
        alert("Scheduled report deleted")
      } catch (e) {
        console.log("Error in deleting schedule", e)
        alert("Schedule deletion failed", "error")
      }
    }
  }

  const columns = [
    ...getColumns({
      handleOptionSelect,
      hierarchyData: hierarchy,
      dashboards,
      isDeleting: isDeleteScheduleLoading || isDeleteLookerQueryMapLoading,
      scheduleToDelete,
      currentProduct: productFilter,
      products,
      lookerQueryMap
    }).map((item) => ({
      header: item.name,
      accessorFn: item.accessorFn,
      id: `${JSON.stringify(item)}`,
      backgroundColor: "#FFFFFF",
      cell: item.cellProcessor,
      sortingFn: "alphanumeric"
    })),
  ]

  useEffect(() => {
    if (!!searchText) {
      setProductFilter(ALL_PRODUCTS)
    }
  }, [searchText])

  useEffect(() => {
    setProductFilter(pf => pf || ALL_PRODUCTS)
  }, [productFilterOptions])

  return (
    <SchedulesContainer>
      <Header>
        <TitleContainer>
          <Title>{TITLE_TEXT}</Title>
        </TitleContainer>
      </Header>
      {
        (isSchedulesLoading || isLookerQueryMapLoading) && (
          <LoaderContainer>
            <Loader size="medium" />
          </LoaderContainer>
        )
      }
      {
        !isSchedulesLoading && (
          <Body>
            <FilterContainer>
              <SearchBox
                placeholder="Search scheduled reports"
                value={searchText}
                onChange={setSearchText}
              />
              <TabFilter
                options={productFilterOptions}
                selected={productFilter}
                onChange={setProductFilter}
              />
            </FilterContainer>
            <DataTable
              data={filteredSchedules}
              columns={columns}
              page={page}
              headerOptionsEnabled={false}
              className="ScheduleTable"
              onPageCountChange={(count) => {
                setPageCount(count)
                setPage(1)
              }}
            />
            {
              pageCount > 0 && (
                <PaginationContainer>
                  <Pagination
                    pageCount={pageCount}
                    currentPage={page}
                    onPageChange={setPage}
                  />
                </PaginationContainer>
              )
            }
          </Body>
        )
      }
      {
        isEditing && (
          <SaveOrSchedule
            mode="edit"
            schedule={scheduleToEdit}
            type={!!scheduleToEdit?.lookml_dashboard_id ? "dashboard" : "reports"}
            onClose={() => {
              setScheduleToEdit(null)
              toggleScheduleEditing(false)
            }}
          />
        )
      }
    </SchedulesContainer>
  );
}

export default Schedules;

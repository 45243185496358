import React from 'react';

import { Base, SwitchContainer, Text } from './styles';

const Switch = ({
  value = false,
  label,
  onChange,
  ...props
}) => {
  return (
    <SwitchContainer {...props}>
      <Base type="checkbox" checked={value} onChange={onChange} />
      {
        !!label && (
          <Text>{label}</Text>
        )
      }
    </SwitchContainer>
  );
}

export default Switch;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import {
  NavbarFilterContainer,
  SelectionText,
  LabelContainer,
  NavbarTextContainer,
  NavbarFilters,
} from "./styles";

import Icon from "../common/Icon";
import NavbarFilterDropdown from "./NavbarFilterDropdown";
import NavbarFilterMobile from "./Mobile";
import FilterChangeModal from "./FilterChangeModal";
import MultiLevelDropdown from "../common/MultiLevelDropdown";

import {
  getAgencyOptions,
  getClientOptions,
  getCampaignOptions,
  getJobGroupOptions,
  getAccounts,
  getSubAccounts,
  getSites,
  getCurrentLevel,
  getCurrentEntity,
  getInitialNavFilters,
  isFiltersValid,
  getAccountSubAccountOptions,
  isProductAccessible,
} from "./helpers";
import {
  CRM_IDENTIFIERS,
  DASHBOARD_MAP,
  DEVICE_SIZES_PX,
  EXCHANGE_VISIBILITY,
  LOOKER_FILTER_KEYS,
  MIXPANEL_ATTRIBUTES_KEY,
  PROGRAMMATIC_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS,
} from "../../constants/AppConstants";
import { hasFilterLevelChanged } from "../MainApp/helpers"
import useDebounce from "../../utils/useDebounce";
import Mixpanel from "../../services/MixPanel";
import { useGlobalDataStore, useGlobalFiltersStore } from "../../stores/global";
import { searchHierarchyAndViews } from "./searchHelpers";
import { searchHierarchyData } from "../../utils/searchHelpers";
import { getProductMetadata } from "./metadataHelpers";
import { getBreadcrumbTextGlobal, getSelectionText, getSelectionTextBreadcrumbMobile, getSelectionTextBreadcrumbWeb } from "./breadcrumbHelpers";
import { onTrackMixPanelEvent } from "../../helpers/eventHandler";
import { getScreenName } from "../../utils/helpers";

const NavBarFilter = ({
  loadingStates,
  isNavbarFilterVisible,
  onFilterChange
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const {
    products,
    dashboards,
    hierarchy,
    views,
    exchangeVisibility,
  } = useGlobalDataStore(useShallow((state) => ({
    products: state.products,
    dashboards: state.dashboards,
    hierarchy: state.hierarchy,
    views: state.views,
    exchangeVisibility: state.exchangeVisibility,
    setEmbedUrl: state.setEmbedUrl
  })))

  const {
    globalFilters,
    currentProduct,
    setEmbedUrl
  } = useGlobalFiltersStore(useShallow((state) => ({
    globalFilters: state.globalFilters,
    currentProduct: state.currentProduct,
    setEmbedUrl: state.setEmbedUrl
  })))

  const [isWebFilterOpen, toggleWebFilter] = useState(false);
  const [isMobileFilterOpen, toggleMobileFilter] = useState(false);
  const [isAccountsFilterOpen, toggleAccountsFilter] = useState(false)
  const [navFilters, setNavFilters] = useState({
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: "",
    [LOOKER_FILTER_KEYS.SITE_ID]: "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: ""
  })
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 500);
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, toggleSearching] = useState(false)
  const [currentEntity, setCurrentEntity] = useState(LOOKER_FILTER_KEYS.ACCOUNT_ID)
  const [isFilterChangeModalOpen, toggleFilterChangeModal] = useState(false)
  const [currentLevel, setCurrentLevel] = useState("")

  const accountOptions = useMemo(() => {
    if (!!currentProduct && !!hierarchy) {
      const accounts = hierarchy?.[currentProduct?.productKey]
      return getAccounts(accounts)
    }
  }, [hierarchy, currentProduct]);

  const subAccountOptions = useMemo(() => {
    return getSubAccounts(navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID], accountOptions)
  }, [accountOptions, navFilters])

  const siteOptions = useMemo(() => {
    return getSites(navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID], subAccountOptions)
  }, [subAccountOptions, navFilters])

  const clientOptions = useMemo(
    () => getClientOptions(navFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID], subAccountOptions),
    [navFilters, subAccountOptions]
  );

  const campaignOptions = useMemo(
    () => getCampaignOptions(navFilters[LOOKER_FILTER_KEYS.CLIENT_ID], clientOptions),
    [navFilters, clientOptions]
  );

  const jobGroupOptions = useMemo(
    () => getJobGroupOptions(navFilters[LOOKER_FILTER_KEYS.CAMPAIGN_ID], campaignOptions),
    [navFilters, campaignOptions]
  );

  const accountSubAccountOptions = useMemo(() => {
    if (!hierarchy || !currentProduct) return []
    const accounts = hierarchy[UNIFIED_VIEW_IDENTIFIERS.productKey]?.length ? hierarchy[UNIFIED_VIEW_IDENTIFIERS.productKey] : hierarchy[currentProduct?.productKey]
    return getAccountSubAccountOptions(accounts)
  }, [hierarchy, currentProduct])

  const onSubAccountSelect = (option) => {
    const params = Object.fromEntries([...searchParams])
    params[LOOKER_FILTER_KEYS.ACCOUNT_ID] = option.accountId
    params[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] = option.id
    params[LOOKER_FILTER_KEYS.SITE_ID] = ""
    params[LOOKER_FILTER_KEYS.CLIENT_ID] = ""
    params[LOOKER_FILTER_KEYS.CAMPAIGN_ID] = ""
    params[LOOKER_FILTER_KEYS.JOB_GROUP_ID] = ""
    if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
      delete params[LOOKER_FILTER_KEYS.FILTER_ID];
    }
    if (!isProductAccessible({ products, hierarchy, globalFilters: params, currentProduct })) {
      setEmbedUrl("")
    }
    setSearchParams(params)
    toggleAccountsFilter(false)
    onTrackMixPanelEvent(
      "Account filter selected",
      {
        "Screen name": getScreenName(),
        "Account Selected": option?.display
      }
    );
  }

  const handleSubmitAndCancel = (toggleFilter, type = "cancel", forceSubmit = false) => {
    toggleFilter(false);
    setSearchValue("");
    if (type === "submit") {
      const params = Object.fromEntries([...searchParams])
      let updatedSearchParams = {
        ...params,
        ...navFilters,
      };

      const hasLevelChanged = hasFilterLevelChanged(params, navFilters)
      if (hasLevelChanged && !!params[LOOKER_FILTER_KEYS.FILTER_ID] && !forceSubmit) {
        toggleFilterChangeModal(true)
        return
      }

      const selections = searchHierarchyData(hierarchy, updatedSearchParams, currentProduct)

      if (!updatedSearchParams?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
        updatedSearchParams[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] =
          selections?.id?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "";
      }

      onTrackMixPanelEvent("Navigation Filter Action", {
        "Screen name": getScreenName(),
        ...(!!selections?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] && {
          [MIXPANEL_ATTRIBUTES_KEY.ACCOUNT_NAME]: selections.name[LOOKER_FILTER_KEYS.ACCOUNT_ID],
        }),
        ...(!!selections?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] && {
          [MIXPANEL_ATTRIBUTES_KEY.SUB_ACCOUNT_NAME]: selections.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID],
        }),
        ...(!!selections?.name?.[LOOKER_FILTER_KEYS.CLIENT_ID] && {
          [MIXPANEL_ATTRIBUTES_KEY.CLIENT_NAME]: !!selections.name[LOOKER_FILTER_KEYS.CLIENT_ID],
        }),
        ...(!!selections?.name?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] && {
          [MIXPANEL_ATTRIBUTES_KEY.CAMPAIGN_NAME]: !!selections.name[LOOKER_FILTER_KEYS.CAMPAIGN_ID],
        }),
        ...(!!selections?.name?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] && {
          [MIXPANEL_ATTRIBUTES_KEY.JOB_GROUP_NAME]: !!selections.name[LOOKER_FILTER_KEYS.JOB_GROUP_ID]
        }),
      });
      if (!!forceSubmit) {
        delete updatedSearchParams[LOOKER_FILTER_KEYS.FILTER_ID]
      }
      setSearchParams(updatedSearchParams);
    }
  };

  const selectedAccountOption = accountOptions?.find(
    (accountOption) => accountOption.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]
  );

  const selectedSubAccountOption = subAccountOptions?.find(
    (sa) => sa.id === globalFilters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]
  );

  const filtersMetadata = getProductMetadata({
    product: currentProduct,
    clientOptions,
    campaignOptions,
    jobGroupOptions,
    accountOptions,
    subAccountOptions,
    siteOptions,
    setNavFilters,
    setSearchValue,
    setCurrentEntity,
    navFilters
  })

  useEffect(() => {
    if (!!currentProduct) {
      setCurrentLevel(getCurrentLevel(navFilters, currentProduct))
    }
  }, [currentProduct, navFilters])

  useEffect(() => {
    if (!!hierarchy && !!currentProduct) {
      const productHierarchy = hierarchy[currentProduct?.productKey]
      const queryParams = Object.fromEntries([...searchParams])
      if (!!productHierarchy?.length && (!queryParams?.[LOOKER_FILTER_KEYS.ACCOUNT_ID])) {
        const params = {
          ...queryParams,
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: productHierarchy?.[0]?.id,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: productHierarchy?.[0]?.subAccounts?.[0]?.id || "",
        }
        setSearchParams(params)
        return
      }
    }
  }, [
    hierarchy,
    currentProduct,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    if (!!globalFilters) {
      const {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
        [LOOKER_FILTER_KEYS.SITE_ID]: siteId = "",
        [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId = "",
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId = "",
      } = globalFilters;
      setNavFilters(nf => ({
        ...nf,
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
        [LOOKER_FILTER_KEYS.SITE_ID]: siteId,
        [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
        [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
        [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
      }))
    }
  }, [globalFilters, setNavFilters]);

  useEffect(() => {
    if (isWebFilterOpen || isMobileFilterOpen) {
      setNavFilters(nf => getInitialNavFilters(globalFilters, nf, currentProduct))
      setCurrentEntity(getCurrentEntity(globalFilters, currentProduct))
    }
  }, [isWebFilterOpen, isMobileFilterOpen, globalFilters, currentProduct, setNavFilters])

  useEffect(() => {
    const search = () => {
      if (!debouncedInputValue || debouncedInputValue.length < 1) {
        setSearchResults([])
        toggleSearching(false)
        return
      }
      toggleSearching(true)
      const results = searchHierarchyAndViews({
        hierarchyData: hierarchy,
        views,
        searchValue: debouncedInputValue,
        product: currentProduct,
        dashboards,
        filters: globalFilters
      })
      setSearchResults(results)
      toggleSearching(false)
    }
    search()
  }, [debouncedInputValue, views, hierarchy, currentProduct, dashboards, globalFilters])

  const { accountSelection, productSelection } = getSelectionText(hierarchy, globalFilters, currentProduct)
  const { productFilterTitle = "", productFilterSelection = "" } = getBreadcrumbTextGlobal({ hierarchy, filters: globalFilters, product: currentProduct, views })
  const breadCrumbTextMobile = getSelectionTextBreadcrumbMobile({
    hierarchyData: hierarchy,
    filters: navFilters,
    product: currentProduct,
    selectedAccountOption: accountOptions?.find(
      (accountOption) => accountOption.id === navFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]
    ),
    currentEntity,
    clientOptions,
    campaignOptions,
    jobGroupOptions,
    accountOptions,
    subAccountOptions,
    siteOptions
  })

  const entityDropdownOptions = (() => {
    if (!!searchValue) {
      return filtersMetadata?.[currentEntity]?.options?.filter(item => item?.value?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase())) || []
    }
    return filtersMetadata?.[currentEntity]?.options || []
  })()

  const shouldShowViewsDropdown = (() => {
    if (!isProductAccessible({ products, hierarchy, globalFilters, currentProduct })) return false
    if (Object.values(filtersMetadata || {}).length > 2) return true
    if (!views?.length || !currentProduct) return false
    const currentProductViews = views.filter(item => item.dashboardId === globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID])
    return !!currentProductViews?.length
  })()

  if (!hierarchy) {
    return null;
  }

  return (
    <NavbarFilterContainer isVisible={!!currentProduct}>
      {
        isNavbarFilterVisible && (
          <NavbarFilters>
            <NavbarTextContainer type="account">
              <SelectionText>{`Account`}</SelectionText>
              <LabelContainer
                style={{ pointerEvents: isAccountsFilterOpen ? "none" : "auto" }}
                onClick={() => {
                  toggleAccountsFilter(!isAccountsFilterOpen)
                }}
                isOpen={isAccountsFilterOpen}
              >
                {accountSelection}
                <Icon name="arrow-left" alt="arrow" className="SelectView__arrow" />
              </LabelContainer>
            </NavbarTextContainer>
            {
              shouldShowViewsDropdown && (
                <NavbarTextContainer>
                  <SelectionText>{productFilterTitle}</SelectionText>
                  <LabelContainer
                    style={{ pointerEvents: (isWebFilterOpen || isMobileFilterOpen) ? "none" : "auto" }}
                    onClick={() => {
                      if (window.innerWidth <= DEVICE_SIZES_PX.MOBILE) {
                        toggleMobileFilter(!isMobileFilterOpen)
                      }
                      else {
                        toggleWebFilter(!isMobileFilterOpen)
                      }
                      setSearchValue("")
                    }}
                    isOpen={isWebFilterOpen || isMobileFilterOpen}
                  >
                    <Icon name="navbar-filter" alt="saved reports" />
                    {productFilterSelection}
                    <Icon name="arrow-left" alt="arrow" className="SelectView__arrow" />
                  </LabelContainer>
                </NavbarTextContainer>
              )
            }
          </NavbarFilters>
        )
      }
      {
        isWebFilterOpen && (
          <NavbarFilterDropdown
            metadata={Object.values(filtersMetadata || {}).slice(2)}
            onSubmit={() => handleSubmitAndCancel(toggleWebFilter, "submit")}
            onClose={() => toggleWebFilter(false)}
            searchValue={searchValue}
            onSearchTextChange={(val) => {
              setSearchValue(val)
              if (!!val) {
                toggleSearching(true)
              }
            }}
            searchResults={searchResults}
            currentLevel={currentLevel}
            setCurrentLevel={setCurrentLevel}
            loadingStates={{ ...loadingStates, get: isSearching }}
            onFilterChange={(opt, payload) => {
              if (opt === "select") {
                toggleMobileFilter(false)
                toggleWebFilter(false)
              }
              onFilterChange(opt, payload)
            }}
          />
        )
      }
      <NavbarFilterMobile
        isOpen={isMobileFilterOpen}
        currentEntity={currentEntity}
        filtersMetadata={filtersMetadata}
        navFilters={navFilters}
        entityDropdownOptions={entityDropdownOptions}
        breadCrumbText={breadCrumbTextMobile}
        searchValue={searchValue}
        onSearchTextChange={(val) => {
          setSearchValue(val)
          if (!!val) {
            toggleSearching(true)
          }
        }}
        searchResults={searchResults}
        onSubmitAndCancel={(type) => handleSubmitAndCancel(toggleMobileFilter, type)}
        loadingStates={{ ...loadingStates, get: isSearching }}
        onFilterChange={(opt, payload) => {
          if (opt === "select") {
            toggleMobileFilter(false)
          }
          onFilterChange(opt, payload)
        }}
      />
      {
        isFilterChangeModalOpen && (
          <FilterChangeModal
            onClose={() => toggleFilterChangeModal(false)}
            onSubmit={() => {
              toggleFilterChangeModal(false)
              handleSubmitAndCancel(toggleMobileFilter, "submit", true)
            }}
          />
        )
      }
      {
        isAccountsFilterOpen && (
          <MultiLevelDropdown
            placeholder="Search accounts/sub-accounts"
            className="AccountsDropdown"
            selectedSection={selectedAccountOption}
            selected={selectedSubAccountOption}
            sections={accountSubAccountOptions}
            emptyPlaceholder="No accounts found"
            onSelect={onSubAccountSelect}
            onClose={() => toggleAccountsFilter(false)}
          />
        )
      }
    </NavbarFilterContainer>
  );
};

export default NavBarFilter;

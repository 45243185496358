import React, { useState } from 'react';

import {
  JobQualityContainer,
  HeaderContainer,
  FooterContainer,
  Link,
  ScoreBanner,
  BannerText,
  ScoreComparisonBanner,
  Labels,
  LabelText,
  EmptyContainer,
  IconContainer,
  ScoreTitle,
  InfoContainer,
  Arrow
} from "./styles"

import DetailedInsights from '../../../components/common/DetailedInsights';
import Title from "../../../components/common/Title";
import Icon from "../../../components/common/Icon";
import { default as ScoreIndicator } from "../../../components/common/ScoreBanner"
import GaugeChart from '../../../components/common/GaugeChart';
import Loader from '../../../components/common/Loader';
import WidgetEmptyState from '../../../components/common/WidgetEmptyState';

import { getOptimisedScore, getJobCount, getOriginalScore } from './helpers';

const VIEW_MORE_DISABLED_TEXT =
  "Kindly choose a specific client to access detailed analytics. The Job quality details cannot be merged across multiple Mojo clients or instances.";

const JobQualityWidget = ({
  data,
  config,
  uiConfig,
  isLoading = false,
  onViewMore
}) => {
  const [isInfoOpen, toggleInfo] = useState(false);

  return (
    <JobQualityContainer>
      {
        config?.showTitle && (
          <HeaderContainer screenWidth={uiConfig?.width}>
            <Title text={config.title} />
          </HeaderContainer>
        )
      }
      {
        (config?.showSubtitle && !isLoading) && <ScoreTitle>Overall score</ScoreTitle>
      }
      {
        isLoading && (
          <EmptyContainer>
            <Loader />
          </EmptyContainer>
        )
      }
      {
        (!isLoading && !data?.[0]) && (
          <WidgetEmptyState />
        )
      }
      {
        (!isLoading && !!data?.[0]) && (
          <>
            {
              !uiConfig?.width && (
                <ScoreBanner>
                  <IconContainer>
                    <Icon name="info" width="100%" />
                  </IconContainer>
                  <BannerText>{`Scores are derived from ${getJobCount(data?.[0], config)} sample data points`}</BannerText>
                </ScoreBanner>
              )
            }
            {
              !uiConfig?.width && (
                <>
                  <GaugeChart value={getOriginalScore(data?.[0], config)} width={uiConfig?.width} height={140} />
                  <ScoreComparisonBanner>
                    <Icon name="scoreProgressBar" width="100%" alt="Score scale" />
                    <Labels>
                      <LabelText>Low</LabelText>
                      <LabelText>High</LabelText>
                    </Labels>
                    <BannerText>{`Optimisation can increase your original score up to  ${getOptimisedScore(data?.[0], config)} 🚀`}</BannerText>
                    <ScoreIndicator type="original" className="JobQuality__ScoreBanner-og" style={{ left: getOriginalScore(data?.[0], config) }} />
                    <ScoreIndicator type="optimised" className="JobQuality__ScoreBanner-opt" style={{ left: getOptimisedScore(data?.[0], config) }} />
                  </ScoreComparisonBanner>
                </>
              )
            }
            <FooterContainer screenWidth={uiConfig?.width}>
              {
                !!uiConfig?.width && (
                  <>
                    <ScoreBanner>
                      <IconContainer>
                        <Icon name="info" width="100%" />
                      </IconContainer>
                      <BannerText>{`Scores are derived from ${getJobCount(data?.[0], config)} sample data points`}</BannerText>
                    </ScoreBanner>
                    <GaugeChart value={getOriginalScore(data?.[0], config)} width={uiConfig?.width} height={140} />
                    <ScoreComparisonBanner>
                      <Icon name="scoreProgressBar" width="100%" alt="Score scale" />
                      <Labels>
                        <LabelText>Low</LabelText>
                        <LabelText>High</LabelText>
                      </Labels>
                      <BannerText>{`Optimisation can increase your original score up to  ${getOptimisedScore(data?.[0], config)} 🚀`}</BannerText>
                      <ScoreIndicator type="original" className="JobQuality__ScoreBanner-og" style={{ left: getOriginalScore(data?.[0], config) }} />
                      <ScoreIndicator type="optimised" className="JobQuality__ScoreBanner-opt" style={{ left: getOptimisedScore(data?.[0], config) }} />
                    </ScoreComparisonBanner>
                  </>
                )
              }
              {
                config?.viewMore && !!data?.[0] && (
                  <Link
                    screenWidth={uiConfig?.width}
                    onMouseEnter={() => {
                      if (config?.viewMoreDisabled) {
                        toggleInfo(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (config?.viewMoreDisabled) {
                        toggleInfo(false);
                      }
                    }}
                    onClick={() => {
                      if (!config?.viewMoreDisabled) {
                        onViewMore();
                      }
                    }}
                  >
                    {isInfoOpen && (
                      <>
                        <InfoContainer screenWidth={uiConfig?.width}>{VIEW_MORE_DISABLED_TEXT}</InfoContainer>
                        <Arrow />
                      </>
                    )}
                    <DetailedInsights disabled={config?.viewMoreDisabled} />
                  </Link>
                )
              }
            </FooterContainer>
          </>
        )
      }
    </JobQualityContainer>
  );
}

export default JobQualityWidget;

import mixpanel from "mixpanel-browser";
import { EMAIL_LS_KEY, UNIFIED_ANALYTICS_ORIGIN } from "../constants/AppConstants";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { getUserAndOtherAttributes } from "../utils/helpers";

mixpanel.init(window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN, {
  cross_subdomain_cookie: true,
  cross_site_cookie: true,
  secure_cookie: true,
  persistence: "localStorage",
  opt_out_tracking_by_default: window.location.origin !== UNIFIED_ANALYTICS_ORIGIN.PROD
});

// Actions for interacting with Mixpanel
const Mixpanel = {
  identifyUser: (userId) => {
    try {
      if (window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN) {
        mixpanel.init(window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN, {
          cross_subdomain_cookie: true,
          cross_site_cookie: true,
          secure_cookie: true,
          persistence: "localStorage",
        });
        setTimeout(() => {
          mixpanel.identify(userId);
        }, 500);
      }
    } catch (e) {
      console.error("Mixpanel identify error", e);
    }
  },
  trackEvent: (eventName, eventProps = {}) => {
    try {
      if (window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN) {
        mixpanel.track(eventName, {
          ...getUserAndOtherAttributes(),
          ...eventProps,
        });
      }
    } catch (e) {
      console.error("Mixpanel track error", e);
      if (window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN) {
        mixpanel.init(window.__RUNTIME_CONFIG__?.MIXPANEL_TOKEN, {
          cross_subdomain_cookie: true,
          cross_site_cookie: true,
          secure_cookie: true,
          persistence: "localStorage",
        });
        const userId = getItemFromLocalStorage(EMAIL_LS_KEY);
        setTimeout(() => {
          mixpanel.identify(userId);
        }, 500);
      }
    }
  },
  people: {
    setProperties: (userProps) => {
      try {
        mixpanel.people.set(userProps);
      } catch (e) {
        console.error("Mixpanel people error", e);
      }
    },
  },
};

export default Mixpanel;

import styled, { css } from "styled-components";

export const MultiSelectDropdownContainer = styled.div`
  position: relative;
`;

export const DropdownControl = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #fff;
  background: none;
  border: none;
  cursor: ${(props) => (props.isDisabled ? "initial" : "pointer")};
  font-size: 1rem;
  gap: 1rem;
  padding-top: 0;

  img {
    transform: ${(props) => (props.active ? "rotateX(180deg)" : "initial")};
    filter: invert(75%);
  }

  .last-changed-filter {
    font-weight: 500;
  }

  ${(props) =>
    !!props.activeDropdown &&
    css`
      img {
        filter: invert(0%);
      }
    `}
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
  background-color: #fff;
  z-index: 2;
  width: 16rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-top: 0.9rem;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);

  &.active-dropdown {
    margin-top: 0.3rem;
  }
`;

export const DropdownSearch = styled.div`
  padding: 0.5rem 1.5rem;
  border-top: 1px solid #edf2f7;
  border-bottom: 1px solid #edf2f7;
  display: flex;
  align-items: center;

  input {
    color: #4a5568;
  }

  input::placeholder {
    color: #9fa6b2;
    font-size: 14px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.9rem;
`;

export const DropdownOptions = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 16rem;
  overflow: scroll;
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0.2rem;
  cursor: pointer;
  background-color: #fff;
  line-height: 18px;
  color: #4a5568;
  font-weight: 400;
  font-size: 0.9rem;

  &:hover {
    background-color: #edf2f7;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;

  &.hidden {
    display: none;
  }
`;

export const ApplyButton = styled.button`
  color: #ffffff;
  background-color: #3f51b5;
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  &.disabled {
    opacity: 0.6;
  }
`;

export const CancelButton = styled.button`
  color: #374151;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const DropdownTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: #ffffff;

  img {
    cursor: pointer;
  }
`;

export const TitleText = styled.span`
  margin-right: 0.5rem;
  color: #374151;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const CheckboxContainer = styled.div`
  padding: 0 0.7rem 0 0;
  display: flex;
  align-items: center;
`;

export const SearchImg = styled.img`
  width: 14px;
  height: 14px;
`;

export const DropdownSubText = styled.div`
  padding-left: 0.5rem;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #ffffff;
`;

export const EmptySearchResult = styled.p`
  padding: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;

export const OptionText = styled.div`
  padding: 0.75rem 0 0.75rem 1.2rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  word-break: break-all;
`;
export const Footer = styled.div`
  padding: 1.2rem 1rem;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Submit = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  color: #3f51b5;
  cursor: pointer;
`;

export const DropdownControlText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

import React, { useMemo, useState, useRef, useEffect } from "react";

import { DropdownContainer, Button, OptionsContainer, Option } from "./styles";

import useOnClickOutside from "../../../utils/useOnClickOutside";
import Tooltip from "../Tooltip";
import Icon from "../Icon";
import { DEVICE_SIZES_PX } from "../../../constants/AppConstants";

const Dropdown = ({
  options,
  selected,
  idKey = "id",
  valueKey = "value",
  placeholder = "Please select an option",
  className = "",
  showSelected = true,
  isOpen = false,
  onChange,
  onClose = () => { },
  displayFormatter = (value) => value,
  customOptionsContainerStyle = {},
  customOptionStyle = {},
  dropdownButtonStyle = {},
  style,
  uiConfig,
}) => {
  const [isDropdownActive, toggleDropdown] = useState(false);
  const wrapperRef = useRef(null);

  const displayValue = useMemo(() => {
    if (!!selected && !!options?.length) {
      return (
        options.find((option) => option[valueKey] === selected[valueKey])?.[
        valueKey
        ] || placeholder
      );
    }
    return placeholder;
  }, [selected, options, placeholder, valueKey]);

  useEffect(() => {
    if (isOpen) {
      toggleDropdown(true);
    }
  }, [isOpen]);

  useOnClickOutside(wrapperRef, () => {
    if (isDropdownActive) {
      toggleDropdown(false);
      onClose();
    }
  });

  return (
    <DropdownContainer
      className={className ? className : ""}
      ref={wrapperRef}
      style={style}
      showSelected={showSelected}
    >
      {showSelected && (
        <Button
          active={isDropdownActive}
          onClick={() => {
            if (isDropdownActive) {
              onClose();
            }
            toggleDropdown(!isDropdownActive);
          }}
          style={dropdownButtonStyle}
        >
          {uiConfig && uiConfig?.width < DEVICE_SIZES_PX.MOBILE ? (
            typeof displayFormatter(displayValue) === "string" ? (
              displayValue
            ) : (
              displayFormatter(displayValue)
            )
          ) : (
            <Tooltip text={displayValue} position="bottom">
              {typeof displayFormatter(displayValue) === "string"
                ? displayValue.length > 25
                  ? `${displayValue.substring(0, 25)}...`
                  : displayValue
                : displayFormatter(displayValue)}
            </Tooltip>
          )}
          <Icon name="arrow-down-solid" />
        </Button>
      )}
      {isDropdownActive && options?.length && (
        <OptionsContainer style={customOptionsContainerStyle}>
          {options.map((option) => (
            <Option
              key={option[idKey]}
              style={customOptionStyle}
              onClick={() => {
                onChange(option);
                toggleDropdown(false);
              }}
            >
              {option[valueKey]}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;

import React from 'react';

import { SelectionText } from "./styles"

import { useFetchQuery } from '../../../api/schedules';
import { getSelectionText } from './helpers';

const Selection = ({
  schedule,
  hierarchyData,
  product,
  dashboards,
  products,
  lookerQueryMap
}) => {
  const querySlug = lookerQueryMap?.find(item => item.queryId === schedule?.query_id)?.querySlug

  const {
    data: { data: query = {} } = {},
  } = useFetchQuery(querySlug)

  const selectionText = getSelectionText({ schedule, query, hierarchyData, product, dashboards, products })

  return (
    <SelectionText>
      {selectionText}
    </SelectionText>
  );
}

export default Selection;

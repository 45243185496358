import React, { useEffect, useRef } from 'react';

import {
  SearchBoxContainer,
  SearchInput
} from './styles'

import Icon from "../Icon"

const SearchBox = ({
  placeholder,
  value,
  clearIcon = false,
  className,
  onChange,
  searchBoxContainerStyle = {}
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (!!inputRef?.current) {
      inputRef.current?.addEventListener('focus', () => {
        setTimeout(() => {
          console.log("scrolling into view")
          if (!!inputRef?.current) {
            inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 300);
      });
    }
  }, [])

  return (
    <SearchBoxContainer className={className} style={searchBoxContainerStyle}>
      <Icon name="search" className="SearchImg" />
      <SearchInput
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {
        (clearIcon && !!value) && (
          <Icon name="close" className="ClearImg" width="12" height="12" style={{ cursor: "pointer" }} onClick={() => onChange("")} />
        )
      }
    </SearchBoxContainer>
  );
}

export default SearchBox;
import { UNITS_CONFIG } from "../../constants/Units";

export const GEOGRAPHIC_DISTRIBUTION_METRICS_FILTER_CONFIG = {
  title: "Performance metrics",
  inputType: "radio",
  metricTargetKey: "filter_data.attributes",
  metricValueKey: "value",
  filterTargetKey: "filter_data.keys",
  filterValueKey: "value",
  filtersToUpdate: [
    {
      name: "statsDisplayParameter",
    },
  ],
  defaultFilters: [
    {
      name: "Spend",
    },
  ],
};

export const GEOGRAPHIC_DISTRIBUTION_OPTIONS = [
  {
    targetKey: "view_normalised_tracking_event.spend_converted",
    valueKey: "value",
    display: "Spend",
  },
  {
    targetKey: "view_normalised_tracking_event.clicks",
    valueKey: "value",
    display: "Clicks",
  },
  {
    targetKey: "view_normalised_tracking_event.applies",
    valueKey: "value",
    display: "Applies",
  },
  {
    targetKey: "view_normalised_tracking_event.hires",
    valueKey: "value",
    display: "Hires",
  },
];

export const GEOGRAPHIC_DISTRIBUTION_CONFIG_HOME = {
  showTitle: true,
  title: "Geographic distribution on",
  optionSelection: true,
  countrySelection: true,
  countryTargetKey: "view_normalised_tracking_event.joveo_country",
  stateTargetKey: "view_normalised_tracking_event.joveo_state",
  hcTargetKey: "view_normalised_tracking_event.hc_key",
  hcValueKey: "value",
  viewMore: true,
  options: [...GEOGRAPHIC_DISTRIBUTION_OPTIONS],
};

export const GEOGRAPHIC_DISTRIBUTION_CONFIG_VIEW_MORE = {
  showTitle: false,
  title: "Geographic distribution on",
  optionSelection: false,
  countrySelection: false,
  countryTargetKey: "view_normalised_tracking_event.joveo_country",
  countryValueKey: "value",
  stateTargetKey: "view_normalised_tracking_event.joveo_state",
  stateValueKey: "value",
  hcTargetKey: "view_normalised_tracking_event.hc_key",
  hcValueKey: "value",
  metricTargetKey: "view_normalised_tracking_event.dimension_1",
  metricValueKey: "value",
  countryFilter: true,
  lookerFilters: [
    {
      key: "country",
      filterTargetKey: "view_normalised_tracking_event.joveo_country",
      filterValueKey: "value",
    },
  ],
  filtersToUpdate: [
    {
      name: "countryFilter",
    },
  ],
  viewMore: false,
};

export const GEOGRAPHIC_DISTRIBUTION_TABLE_CONFIG = {
  pagination: false,
  fillEmptyRows: true,
  dynamicColumns: true,
  dynamicRows: false,
  currencyTargetKey: "view_normalised_tracking_event.currency",
  currencyValueKey: "value",
  columns: [
    {
      title: "State",
      dataTargetKey: "view_normalised_tracking_event.joveo_state",
      dataValueKey: "value",
    },
    {
      title: "$$1",
      dataTargetKey: "view_normalised_tracking_event.dimension_1",
      dataValueKey: "value",
      type: "number"
    },
  ],
  lookerFilters: [
    {
      name: "view_normalised_tracking_event.rational_distribution_parameter",
    },
  ],
  units: [...UNITS_CONFIG],
};

import styled, { css } from "styled-components";
import { SearchBoxContainer } from "../../../components/common/SearchBox/styles";

export const CountryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;
export const Selection = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  border-radius: 0.3rem;

  img {
    transform: ${(props) => (props.active ? "rotateX(180deg)" : "initial")};
    filter: invert(75%);
  }

  ${(props) =>
    !!props.activeDropdown &&
    css`
      img {
        filter: invert(0%);
      }
    `}
`;
export const SelectionText = styled.span`
  color: #374151;
  font-size: 0.9rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const Content = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000001A, 0px 2px 4px 0px #0000000F;
  box-shadow: 0px 2px 4px 0px #0000000F;
  border-radius: 0.5rem;

  .msaselection {
    min-width: 100%;
  }

  .search-msa {
    width: 100%;
    max-height: 20rem;
    overflow-y: auto;
  }

  ${SearchBoxContainer} {
    border-radius: 0;
  }
`;

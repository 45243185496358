import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  ScoreModalContainer,
  TextContainer,
  Title,
  Text,
  TextLine,
  Bullet,
  Header,
  ContentContainer,
  ScoreText,
  Button,
  IconContainer,
  ErrorTitle,
  ErrorText,
  ErrorContainer
} from './styles';

import Modal from "../../../components/common/Modal";

import { useFetchJobScore } from '../../../api/jobs';
import { LOOKER_FILTER_KEYS } from '../../../constants/AppConstants';
import {
  JOBS_BREAKDOWN_JOB_ID_KEY,
  JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY,
  JOBS_BREAKDOWN_ORIGINAL_JD_KEY,
  JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY,
  JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY,
  JOBS_BREAKDOWN_OPTIMISED_JD_KEY,
  JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY
} from '../constants';
import ScoreProgressBar from '../ScoreProgressBar';
import Icon from '../../../components/common/Icon';
import Loader from '../../../components/common/Loader';
import Fade from './Fade';
import { useGlobalFiltersStore } from '../../../stores/global';

const STAGES = [
  { text: "Reading your job title", assets: ["jqstage1.1", "jqstage1.2", "jqstage1.3"] },
  { text: "Reading your job description", assets: ["jqstage2.1", "jqstage2.2", "jqstage2.3"] },
  { text: "Scoring your job description", assets: ["jqstage3.1", "jqstage3.2", "jqstage3.3"] }
]

const ERROR_TITLE = "Unable to Determine Job Quality Score"
const ERROR_TEXT = "We're sorry, but we are currently unable to calculate the Job Quality Score for this job. Please try again later or contact support for assistance."

const ScoreModal = ({
  job,
  onExpand,
  onClose
}) => {
  const {globalFilters} = useGlobalFiltersStore(useShallow((state) => ({ globalFilters: state.globalFilters })))
  const [currentStage, setCurrentStage] = useState(1)
  const [progressValue, setProgressValue] = useState(0)
  const [inProp, toggleInProp] = useState(false)

  const {
    data: { data: jobScore = {} } = {},
    error: jobScoreError,
    isLoading: isJobsScoreLoading,
    isError: isError
  } = useFetchJobScore(globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID], job?.[JOBS_BREAKDOWN_JOB_ID_KEY]);

  const getAssetName = () => {
    const stage = STAGES[currentStage - 1]
    const index = progressValue === 0 ? 0 : progressValue === 33 ? 1 : 2
    return stage.assets[index]
  }

  const handleJobExpand = () => {
    const mappedJob = {
      ...job,
      [JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY]: jobScore["originalJobOverallScore"],
      [JOBS_BREAKDOWN_ORIGINAL_JD_KEY]: jobScore["originalJobDescription"],
      [JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY]: jobScore["originalJobScores"],
      [JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY]: jobScore["optimizedJobOverallScore"],
      [JOBS_BREAKDOWN_OPTIMISED_JD_KEY]: jobScore["optimizedJobDescription"],
      [JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY]: jobScore["optimizedJobScores"],
    }
    onExpand(mappedJob)
  }

  useEffect(() => {
    let progressInterval = null
    let stageInterval = null
    let inpropInterval = null
    let count = 0
    if (currentStage <= 4) {
      progressInterval = setInterval(() => {
        setProgressValue(val => (currentStage === 3 && isJobsScoreLoading && val === 33) ? val : parseInt(val + 33))
      }, 2000)
      stageInterval = setInterval(() => {
        setCurrentStage(stage => (stage === 3 && isJobsScoreLoading) ? stage : stage + 1)
        setProgressValue(val => (currentStage === 3 && isJobsScoreLoading) ? val : 0)
      }, 6000);

      inpropInterval = setInterval(() => {
        if (count === 0) {
          toggleInProp(true)
        }
        if (currentStage !== 3) {
          count++
        }
        if (count === 5 && (currentStage !== 3)) {
          toggleInProp(false)
          count = 0
        }
      }, 1000)
    }

    return () => {
      clearInterval(progressInterval)
      clearInterval(stageInterval)
      clearInterval(inpropInterval)
    }
  }, [currentStage, isJobsScoreLoading])

  const isDSError = (() => {
    return isError && (jobScoreError?.response?.status === 422)
  })()

  const errorMessage = (() => {
    return isDSError ? (jobScoreError?.response?.data?.[0]?.message) : ERROR_TEXT
  })()

  return (
    <Modal className="ScoreModal" onClose={onClose}>
      <ScoreModalContainer>
        <Header>
          <Icon name="close" width="16" height="16" style={{ cursor: 'pointer' }} onClick={onClose} />
        </Header>
        {
          isError && (
            <ErrorContainer>
              <Fade in assetName="jqerror" />
              <ErrorTitle>{ERROR_TITLE}</ErrorTitle>
              <ErrorText>{errorMessage}</ErrorText>
            </ErrorContainer>
          )
        }
        {
          !isError && (
            <ContentContainer done={currentStage > 3}>
              {
                currentStage <= 3
                  ? <Fade in={inProp} assetName={getAssetName()} />
                  : <ScoreText>{!!jobScore?.["originalJobOverallScore"] ? `${parseInt(jobScore["originalJobOverallScore"] * 100)}%` : "N/A"}</ScoreText>
              }
              {
                currentStage <= 3 && <ScoreProgressBar stage={currentStage} value={progressValue} />
              }
              <Title done={currentStage > 3}>{currentStage <= 3 ? "Calculating your score..." : "Score calculated"}</Title>
              <TextContainer done={currentStage > 3}>
                {
                  STAGES.map((item, index) => (
                    <TextLine key={JSON.stringify(item)}>
                      <IconContainer>
                        {
                          (currentStage === index + 1)
                            ? <Loader size="small" height="32" width="32" />
                            : (currentStage > index + 1)
                              ? <Icon name="checked-green" alt="checked" />
                              : <Bullet />
                        }
                      </IconContainer>
                      <Text done={currentStage > index + 1}>{item.text}</Text>
                    </TextLine>
                  ))
                }
              </TextContainer>
              {
                currentStage > 3 && (
                  <Button onClick={handleJobExpand} disabled={!jobScore?.["originalJobOverallScore"]}>
                    View job details
                  </Button>
                )
              }
            </ContentContainer>
          )
        }
      </ScoreModalContainer>
    </Modal>
  );
}

export default ScoreModal;

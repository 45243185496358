import * as XLSX from 'xlsx';

import {
  EXCHANGE_VISIBILITY_VALUES,
  LOOKER_FILTER_KEYS,
} from "../../constants/AppConstants";
import {
  REPORTS_CONFIG_MAP,
  CAMPAIGN_REPORT_TITLE,
  JOB_PERFORMANCE_REPORT_TITLE,
  EXPANSIONS_REPORT_TITLE,
  TOTAL_STATS_REPORT_TITLE,
  EVENT_REPORT_TITLE,
} from "../../constants/ReportsConstants"

const FIELDS_TO_REMOVE = ["Apply", "Apply Start", "Click", "Hire", "APPLY_START", "APPLY"]
const APPLY_CUSTOM_FIELD_LABEL = "Click to apply"
const HIRE_CUSTOM_FIELD_LABEL = "Apply to hire"

const PUBLISHER_FILTERS = ["Publisher name", "Publisher type"]

export const COLUMN_ORDER = [
  {
    title: "Pinned columns (Max 4)", options: []
  },
  {
    title: "Scrollable columns", options: []
  }
]
export const MAX_FREEZE_LENGTH = 4

export const parseMetrics = (metrics, key, typeKey, type = CAMPAIGN_REPORT_TITLE) => {
  const METRIC_GROUP = REPORTS_CONFIG_MAP[type].METRICS
  const VIEW = REPORTS_CONFIG_MAP[type].VIEW
  const init = [...METRIC_GROUP].map(item => ({ ...item, options: item.options.map(opt => ({ ...opt, id: JSON.stringify(opt) })) }))
  if (!metrics?.length) return init
  const keys = metrics
    .filter(item => Boolean(item[key]))
    .filter(item => !FIELDS_TO_REMOVE.some(field => field.toLocaleLowerCase() === item[key].toLocaleLowerCase()))
    .map((item) => ({ id: JSON.stringify(item), name: item[key], type: item[typeKey] }))
  let customCount = 1, tthCount = 1
  const finalMetrics = keys.reduce((acc, item) => {
    const metricType = item.type
    if (metricType === APPLY_CUSTOM_FIELD_LABEL) {
      const applies = acc.find(mg => mg.title === "Applies")
      const options = [...applies.options, { ...item, key: `${VIEW}.custom_conversion${customCount}`, filterKey: `${VIEW}.custom_conv${customCount}` }]
      customCount++
      return [...acc.filter(a => a.title !== applies.title), { ...applies, options }]
    } else if (metricType === HIRE_CUSTOM_FIELD_LABEL) {
      const hires = acc.find(mg => mg.title === "Hires")
      const options = [...hires.options, { ...item, key: `${VIEW}.tth_${tthCount}`, filterKey: `${VIEW}.tth${tthCount}` }]
      tthCount++
      return [...acc.filter(a => a.title !== hires.title), { ...hires, options }]
    }
    return acc
  }, init)

  return finalMetrics
}

export const getBaseAdvancedFilterOptions = (showPublisherFilter, type = CAMPAIGN_REPORT_TITLE) => {
  const baseAdvancedFilters = REPORTS_CONFIG_MAP[type].BASE_ADVANCED_FILTERS
  return baseAdvancedFilters.map(item => ({ ...item, enabled: PUBLISHER_FILTERS.includes(item.name) ? showPublisherFilter : true }))
}

export const getCurrentFormattedDate = () => {
  const currentDate = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = monthNames[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return `${day}-${month}-${year}`;
}

export const getDimensionFilters = (options, slice = 0, showPublisherFilter) => {
  const filters = slice ? options.slice(0, slice) : options
  return filters
    .flatMap(item => item.options)
    .map(item => ({
      ...item,
      id: JSON.stringify(item),
      name: item.name,
      enabled: item.name.toLocaleLowerCase().startsWith("publisher") ? showPublisherFilter : true,
      field: item.key
    }))
}

export const parseColumnSelected = (data, selected, type = "dimensions") => {
  return data
    .filter(dim => !!selected?.[type]?.find(col => col.title === dim.title))
    .map(dim => {
      const opts = dim.options.filter(opt => {
        const isOptionSelected = selected?.[type]?.find(col => col.title === dim.title).options.some(o => o.key === opt.key && o.name === opt.name)
        if (isOptionSelected) return true
        return false
      })
      return { ...dim, options: opts }
    })
}

export const getLookerFiltersFromParams = ({
  filters,
  type
}) => {
  if (!type) return null

  const {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId = "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId = "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId = "",
    [LOOKER_FILTER_KEYS.CURRENCY_RATE]: currency = "",
    [LOOKER_FILTER_KEYS.DATE]: date = "this month",
    [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: exchangeVisibility = EXCHANGE_VISIBILITY_VALUES.CLOSE
  } = filters || {};
  const globalFiltersMap = REPORTS_CONFIG_MAP[type].GLOBAL_FILTERS
  return {
    [globalFiltersMap.ACCOUNT_ID]: accountId,
    [globalFiltersMap.SUB_ACCOUNT_ID]: subAccountId,
    [globalFiltersMap.AGENCY_ID]: agencyId,
    [globalFiltersMap.CLIENT_ID]: clientId,
    [globalFiltersMap.CAMPAIGN_ID]: campaignId,
    [globalFiltersMap.JOB_GROUP_ID]: jobGroupId,
    ...(!!globalFiltersMap.CURRENCY_RATE && { [globalFiltersMap.CURRENCY_RATE]: currency }),
    [globalFiltersMap.DATE]: date,
    ...(!!globalFiltersMap.EXCHANGE_VISIBILITY && { [globalFiltersMap.EXCHANGE_VISIBILITY]: exchangeVisibility }),
  }
}

export const parseCSVData = (data) => {
  let insideQuotes = false;
  let row = [];
  let cell = '';

  const rows = data.split('\n').map((line) => {
    row = [];
    cell = '';
    insideQuotes = false;
    for (let i = 0; i < line.length; i++) {
      if (line[i] === '"') {
        insideQuotes = !insideQuotes;
      } else if (line[i] === ',' && !insideQuotes) {
        row.push(cell.replace(/"/g, '')); // Remove double quotes
        cell = '';
      } else {
        cell += line[i];
      }
    }
    row.push(cell.replace(/"/g, '')); // Remove double quotes
    return row;
  });

  return rows;
};

export const downloadCSV = async (csvData, selectedTab, format) => {
  const href = window.URL.createObjectURL(csvData.data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${selectedTab} report ${getCurrentFormattedDate()}.${format}`)
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);



  // if (format === "csv") {
  //   const rows = parseCSVData(csvData.data);

  //   // Create a new Excel workbook
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.aoa_to_sheet(rows);

  //   // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   const new_workbook = XLSX.utils.book_new();
  //   workbook.SheetNames.forEach((name) => {
  //     const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[name]);
  //     const wb = XLSX.read(csv, { type: "string" });
  //     XLSX.utils.book_append_sheet(new_workbook, wb.Sheets.Sheet1, name);
  //   });

  //   // Your existing code to create the XLSX file
  //   const excelFileName =
  //     `${selectedTab} report ` + getCurrentFormattedDate() + ".xlsx";
  //   XLSX.writeFile(new_workbook, excelFileName);
  // } else {

  // }
};

export const parseUTMTags = (utms) => {
  if (!utms?.length) return []
  const utmTags = utms.filter(item => item["filter_data.attributes"].toLocaleLowerCase().startsWith("utm")).map(item => ({
    name: item["filter_data.attribute_label"],
    filterKey: `total_stats_report.${item["filter_data.attributes"]}`,
    columnFilterKey: `total_stats_report.${item["filter_data.attributes"]}_label`
  }))
  return utmTags
}

export const getColumnsFromFilters = ({
  filterId,
  views,
  dimensions,
  metrics
}) => {
  if (!filterId || !views?.length || !dimensions?.length || !metrics?.length) return []
  const currentView = views.find(item => item.filterId === filterId)
  if (!!currentView) {
    const filtersInView = Object.fromEntries(new URLSearchParams(currentView.value))
    const columnsToBeSelected = filtersInView["fields"]?.split(",") || []
    const order = JSON.parse(filtersInView["order"] || "[]")
    const selectedDimensions = dimensions
      .filter(item => !!item.options.find(opt => columnsToBeSelected.includes(opt.key)))
      .map(item => ({ ...item, options: item.options.filter(opt => columnsToBeSelected.includes(opt.key)) }))
    const selectedMetrics = metrics
      .filter(item => !!item.options.find(opt => columnsToBeSelected.includes(opt.key)))
      .map(item => ({ ...item, options: item.options.filter(opt => columnsToBeSelected.includes(opt.key)) }))
    return {
      dimensions: selectedDimensions,
      metrics: selectedMetrics,
      order: COLUMN_ORDER.map((item, index) => ({ ...item, options: index === 0 ? order?.dimensions : order?.metrics }))
    }
  }
  return []
}

export const checkMetricsLoadState = ({
  selectedTab,
  campaignMetricsStatus,
  jobPerformanceMetricsStatus,
  expansionMetricsStatus,
  totalStatsMetricsStatus,
  eventMetricKeys
}) => {
  if (!selectedTab) return false
  switch (selectedTab) {
    case CAMPAIGN_REPORT_TITLE:
      return campaignMetricsStatus === "success"
    case JOB_PERFORMANCE_REPORT_TITLE:
      return jobPerformanceMetricsStatus === "success"
    case EXPANSIONS_REPORT_TITLE:
      return expansionMetricsStatus === "success"
    case EVENT_REPORT_TITLE:
      return !!eventMetricKeys?.length
    case TOTAL_STATS_REPORT_TITLE:
      return totalStatsMetricsStatus === "success"
    default:
      return false
  }
}

export const parseColumnOrder = ({ dimensions, metrics, order }) => {
  if (!!order?.length) {
    if (order[0].options.length <= MAX_FREEZE_LENGTH) return order
    return [
      {
        ...order[0],
        options: order[0].options.slice(0, MAX_FREEZE_LENGTH)
      },
      {
        ...order[1],
        options: [
          ...order[0].options.slice(MAX_FREEZE_LENGTH),
          ...order[1].options
        ]
      }
    ]
  }
  const newOrder = COLUMN_ORDER.map((item, index) => ({ ...item, options: (index === 0 ? dimensions : metrics).flatMap(item => item.options) }))
  if (newOrder[0].options.length <= MAX_FREEZE_LENGTH) return newOrder
  return [
    {
      ...newOrder[0],
      options: newOrder[0].options.slice(0, MAX_FREEZE_LENGTH)
    },
    {
      ...newOrder[1],
      options: [
        ...newOrder[0].options.slice(MAX_FREEZE_LENGTH),
        ...newOrder[1].options
      ]
    }
  ]
}

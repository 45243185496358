import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  getScheduledReports,
  scheduleReport,
  scheduleReportOnce,
  editScheduledReport,
  deleteScheduledReport,
  getQueryDetails,
  getLookerQueryMap,
  createLookerQueryMap,
  deleteLookerQueryMap
} from "../services/apis";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { ACCESS_TOKEN_LS_KEY, LOOKER_ACCESS_TOKEN_LS_KEY } from "../constants/AppConstants";

export const useFetchSchedules = (params) => {
  return useQuery(
    ['schedules', { ...params }],
    () => (
      getScheduledReports(params)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!params && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)
    },
  );
}

export const useScheduleReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, type }) => type === "once" ? scheduleReportOnce(payload) : scheduleReport(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules']);
      }
    }
  );
}

export const useEditScheduleReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    editScheduledReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules']);
      },
    },
  );
}

export const useDeleteScheduleReport = () => {
  const queryClient = useQueryClient();

  return useMutation(
    deleteScheduledReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules']);
      },
    },
  );
}

export const useFetchLookerQueryMap = () => {
  return useQuery(
    ['lookerQueryMap'],
    () => (
      getLookerQueryMap()
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  );
}

export const useCreateLookerQueryMap = () => {
  const queryClient = useQueryClient();

  return useMutation(
    createLookerQueryMap,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['lookerQueryMap']);
      }
    }
  );
}

export const useDeleteLookerQueryMap = () => {
  const queryClient = useQueryClient();

  return useMutation(
    deleteLookerQueryMap,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['lookerQueryMap']);
      },
    },
  );
}

export const useFetchQuery = (queryId) => {
  return useQuery(
    ['query', queryId],
    () => (
      getQueryDetails(queryId)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!queryId && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)
    },
  );
}

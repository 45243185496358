import styled from "styled-components";

export const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px #0000001A;
  box-shadow: 0px 1px 2px 0px #0000000F;
  border-radius: 0.3rem;
  border: 1px solid #E8E8E8;
`
export const Option = styled.div`
  background-color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: 400;
  color: #252F3F;
  padding: ${props => props.icon ? "0.8rem 1rem" : "0.5rem 1rem"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7rem;
  white-space: nowrap;

  &:hover {
    background-color: #F6F6F6;
  }
`
import styled from "styled-components";
import { devices } from "../../constants/AppConstants";
import { Option, TabContainer } from "../../components/common/Tab/styles";

export const MarketInsightsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;

  @media screen and ${devices.mobile} {
    padding: 0;
  }
`;

export const Title = styled.h3`
  color: #161e2e;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  @media screen and ${devices.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media screen and ${devices.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Label = styled.span`
  color: #3D4759;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #E2E8F0;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;

export const EmptyContainer = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: fit-content;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #CBD5E0;

  ${TabContainer} {
    background-color: inherit;
    gap: 1.5rem;
  }

  ${Option} {
    background-color: inherit;
   padding: 0.5rem 1rem 0.5rem 0;
  }

  a {
    text-decoration: none;
  }
`;
export const ContentContainer = styled.div`
  flex: 1;

  @media screen and ${devices.mobile} {

    .embeddedDashboard {
      width: 100%;
      height: calc(100vh - 20rem);
      margin: 0;
    }
  }
`
export const ContentWrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
`
export const LockedContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(256, 256, 256, 0.9);
`
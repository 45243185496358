import styled from "styled-components";
import { TooltipContainer } from "../../../components/common/Tooltip/styles";
import { ScoreIndicatorBarContainer } from "../ScoreIndicatorBar/styles";

export const ParameterVsScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
`
export const EmptyContainer = styled.div`
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9fa6b2;
  }
`;
export const Title = styled.h3`
  font-size: 0.8rem;
  font-weight: 700;
  color: #6A7185;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`
export const Label = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  color: #373F50;
  width: 100%;
`
export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 2.8rem;

  ${TooltipContainer} {
   flex: 2;

    ${ScoreIndicatorBarContainer} {
      flex: 3;
      
    }
  }
`

import React from "react";

import { Text } from "./styles";

const Title = ({ text, title = "", styles }) => (
  <Text title={title} styles={styles}>
    {text}
  </Text>
);

export default Title;

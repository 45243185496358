import React from "react";
import { SvgIcon } from "@mui/material";
const EmptyTableSVG = (props) => {
  return (
    <SvgIcon
      sx={{ width: "163px", height: "125px", fill: "none" }}
      viewBox="0 0 163 125"
      {...props}
    >
      <g clip-path="url(#clip0_2282_135993)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.0693 0.988579C76.856 -1.15406 90.3162 -0.00386292 101.636 6.3181C113.841 13.134 121.429 25.0755 128.643 37.0514C137.993 52.5747 152.878 68.0002 149.514 85.8081C146.114 103.807 129.805 119.323 112.17 124.26C95.6362 128.889 81.228 110.319 64.0693 109.73C47.9723 109.177 32.8023 128.065 18.3084 121.039C4.18033 114.19 1.816 94.4084 0.235953 78.7841C-1.12317 65.3445 3.54785 52.2943 10.1656 40.5191C15.7878 30.5155 25.4022 24.3275 34.6548 17.542C43.9553 10.7214 52.6954 2.89446 64.0693 0.988579Z"
          fill="#F4F5F7"
        />
        <path
          d="M27.9766 116.13V66.2041C27.9766 64.671 29.2198 63.4277 30.753 63.4277H53.0331C53.9569 63.4277 54.807 63.9339 55.2444 64.7496L58.0355 69.9584C59.7161 73.0936 62.979 75.0494 66.5368 75.0494H90.64L95.5343 83.2803L101.574 87.9437L108.065 90.0715L114.375 90.1255L120.438 88.3368L122.586 87.182V111.467L121.908 113.88L120.217 115.526L117.918 116.135H27.9766V116.13Z"
          fill="#C5CAE9"
        />
        <path
          d="M111.363 91.3193C106.258 91.3193 101.344 89.5846 97.324 86.3217C87.7516 78.5576 86.2823 64.4495 94.0464 54.8819C97.8056 50.2431 103.152 47.3487 109.088 46.7296C115.024 46.1104 120.852 47.8402 125.486 51.6043C130.125 55.3684 133.019 60.7099 133.639 66.646C134.258 72.5822 132.528 78.4102 128.764 83.0441C125.005 87.6829 119.658 90.5772 113.722 91.1964C112.931 91.2799 112.145 91.3193 111.363 91.3193ZM111.447 48.5723C110.734 48.5723 110.017 48.6116 109.295 48.6854C103.879 49.2505 99.0046 51.8893 95.5746 56.1202C88.4936 64.8524 89.8351 77.7124 98.5624 84.7984C102.793 88.2284 108.105 89.8107 113.521 89.2455C118.936 88.6804 123.811 86.0416 127.24 81.8107C130.67 77.5797 132.248 72.2677 131.688 66.8524C131.127 61.4372 128.484 56.5625 124.253 53.1325C120.587 50.1596 116.105 48.5772 111.447 48.5772V48.5723Z"
          fill="#374151"
        />
        <path
          d="M111.393 84.2574C108.007 84.2574 104.597 83.1419 101.771 80.847C99.1669 78.734 97.3635 75.8986 96.5625 72.6505L98.4691 72.1787C99.1669 75.0092 100.739 77.476 103.01 79.3188C108.72 83.9527 117.133 83.0731 121.766 77.363C124.012 74.5964 125.044 71.1222 124.676 67.5792C124.307 64.0362 122.582 60.8519 119.816 58.6062C117.049 56.3605 113.575 55.3286 110.032 55.6972C106.489 56.0657 103.305 57.7905 101.059 60.5571L99.5306 59.3188C104.843 52.7684 114.499 51.761 121.049 57.078C124.223 59.6529 126.204 63.3089 126.626 67.3728C127.049 71.4367 125.865 75.422 123.29 78.5964C120.273 82.3163 115.85 84.2524 111.393 84.2524V84.2574Z"
          fill="#374151"
        />
        <path
          d="M127.402 85.4794L126.164 87.0059L131.32 91.1883L132.558 89.6618L127.402 85.4794Z"
          fill="#374151"
        />
        <path
          d="M132.106 79.6698L130.867 81.1963L135.702 85.1187L136.941 83.5922L132.106 79.6698Z"
          fill="#374151"
        />
        <path
          d="M154.904 111.875C153.218 111.875 151.518 111.32 150.108 110.175L133.675 96.8429C130.412 94.1943 129.911 89.3835 132.56 86.1206C135.208 82.8577 140.019 82.3565 143.282 85.0051L159.715 98.3368C162.977 100.985 163.479 105.796 160.83 109.059C159.326 110.912 157.125 111.875 154.904 111.875ZM138.481 85.2705C136.835 85.2705 135.199 85.983 134.083 87.3589C133.13 88.5334 132.692 90.0076 132.85 91.5063C133.007 93.01 133.739 94.3614 134.914 95.3147L151.346 108.646C153.769 110.612 157.336 110.239 159.302 107.816C161.267 105.393 160.894 101.826 158.471 99.8601L142.039 86.5284C140.992 85.6783 139.734 85.2655 138.481 85.2655V85.2705Z"
          fill="#374151"
        />
        <path
          d="M40.9969 55.5454H39.0312V28.941L59.1639 12.2383H109.051V45.1719H107.085V14.2039H59.8715L40.9969 29.8648V55.5454Z"
          fill="#374151"
        />
        <path
          d="M117.926 117.113H27.2527C24.1372 117.113 21.6016 114.577 21.6016 111.462V62.6999C21.6016 59.5845 24.1372 57.0488 27.2527 57.0488H55.2772C57.4689 57.0488 59.4787 58.3314 60.4025 60.3216L63.6753 67.3682C64.2748 68.6655 65.5868 69.5009 67.0168 69.5009H84.7367V71.4665H67.0168C64.8251 71.4665 62.8153 70.184 61.8915 68.1938L58.6188 61.1471C58.0193 59.8498 56.7072 59.0144 55.2772 59.0144H27.2527C25.2232 59.0144 23.5672 60.6655 23.5672 62.6999V111.462C23.5672 113.491 25.2183 115.147 27.2527 115.147H117.921C119.95 115.147 121.606 113.496 121.606 111.462V90.4248H123.572V111.462C123.572 114.577 121.036 117.113 117.921 117.113H117.926Z"
          fill="#374151"
        />
        <path
          d="M60.5021 30.4639H40.0156V28.4983H58.5365V13.2207H60.5021V30.4639Z"
          fill="#374151"
        />
        <path
          d="M57.8601 100.209H29.4375V102.175H57.8601V100.209Z"
          fill="#374151"
        />
        <path
          d="M71.4133 31.2L70.0234 32.5898L84.1308 46.6972L85.5207 45.3073L71.4133 31.2Z"
          fill="#374151"
        />
        <path
          d="M83.7168 31.1973L69.6094 45.3047L70.9993 46.6946L85.1067 32.5872L83.7168 31.1973Z"
          fill="#374151"
        />
        <g opacity="0.35">
          <path
            d="M136.316 25.9228L133.609 29.708L135.208 30.8514L137.915 27.0662L136.316 25.9228Z"
            fill="#374151"
          />
        </g>
        <g opacity="0.35">
          <path
            d="M132.46 31.3115L127.727 37.9307L129.325 39.074L134.059 32.4549L132.46 31.3115Z"
            fill="#374151"
          />
        </g>
        <g opacity="0.35">
          <path
            d="M146.329 38.8628L132.695 43.6855L133.351 45.5386L146.985 40.7159L146.329 38.8628Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2282_135993">
          <rect width="163" height="125" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default EmptyTableSVG;

export const JOB_QUALITY_CONFIG = {
  showTitle: true,
  showSubtitle: false,
  title: "Quality of jobs",
  viewMore: true,
  viewMoreDbKey: "jobQuality",
  jobCountTargetKey: "job_quality.total_jobs",
  jobCountValueKey: "value",
  originalScoreTargetKey: "job_quality.original_job_overall_score",
  originalScoreValueKey: "rendered",
  optimisedScoreTargetKey: "job_quality.optimized_job_overall_score",
  optimisedScoreValueKey: "rendered"
}

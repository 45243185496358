import styled from "styled-components";

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  visibility: hidden;

  &.lastColumnOption {
    margin-right: 0.4rem;
  }
`

export const ThContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
  position: relative;
  font-size: 0.9rem;
  font-weight: 500;
  color: #374151;
  padding: 0.6rem 0.5rem;
  position: ${props => props.isPinned ? "sticky" : "relative"};
  white-space: wrap;
  min-height: 3rem;

  &:hover ${OptionsContainer} {
    visibility: visible;
  }

  .OptionsPosition {
    position: absolute;
    top: 3rem;
    right: -5.5rem;
    z-index: 2;
  }

  .lastHeaderColumn {
    right: 0.5rem;
  }

`
export const ImageContainer = styled.div`
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: ${props => props.active ? "#D8DBEF" : "initial"};
  transform: ${(props) => (props.sort === "desc" ? "rotateX(180deg)" : "initial")};

  &:hover {
    background-color: #D8DBEF;
  }
`
import React, { useMemo, useState } from "react";
import {
  ContentContainer,
  EmptyStateContainer,
  EmptyStateHeader,
  EmptyStateText,
  EmptyStateTextContainer,
  IframeContainer,
  RoadmapBodyContainer,
  RoadmapContainer,
  RoadmapHeader,
  RoadmapHeaderText,
  RoadmapSubHeaderText,
} from "./styles";
import RoadmapCard from "../../components/Roadmap/RoadmapCard";
import RoadmapSidebar from "../../components/Roadmap/RoadmapSidebar";
import Icon from "../../components/common/Icon";
import { usePostRoadmapFeedback } from "../../api/roadmap";

const RoadmapSidebarOptions = [
  {
    label: "All",
    value: "",
    emptyStateText: "Check back soon for new updates.",
    icon: "four-square",
    iconSelected: "four-square-filled",
  },
  {
    label: "Upcoming",
    value: "UPCOMING",
    emptyStateText:
      "No upcoming releases are currently planned. Check back later for updates!",
    icon: "calendar-round",
    iconSelected: "calendar-round-filled",
  },
  {
    label: "Released",
    value: "RELEASED",
    emptyStateText:
      "It looks like there are no releases to show right now. Stay tuned—we’re just getting started!",
    icon: "rocket",
    iconSelected: "rocket-filled",
  },
];

const Roadmap = ({ roadmapFeatures , roadmapIframeDetails, setRoadmapIframeDetails, setSeenRoadmapFeatures }) => {
  const [selectedOption, setSelectedOption] = useState(
    RoadmapSidebarOptions[0].value
  );
  const roadmapIframeUrl = roadmapIframeDetails?.navigateUrl;
  const { mutateAsync: voteRoadmapFeature } = usePostRoadmapFeedback();

  const features = useMemo(() => {
    const allFeatures = roadmapFeatures?.data?.content || [];
    if (selectedOption) {
      return allFeatures.filter((feature) => feature.status === selectedOption);
    }
    return allFeatures;
  }, [roadmapFeatures, selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <RoadmapContainer setPadding={!roadmapIframeUrl}>
      {!roadmapIframeUrl && (
        <>
          <RoadmapHeader>
            <RoadmapHeaderText>What’s new?</RoadmapHeaderText>
            <RoadmapSubHeaderText>
              Stay ahead with our latest updates! Get a sneak peek at the
              exciting new features and improvements we’re launching soon
            </RoadmapSubHeaderText>
          </RoadmapHeader>
          <RoadmapBodyContainer>
            <RoadmapSidebar
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              RoadmapSidebarOptions={RoadmapSidebarOptions}
            />
            <ContentContainer>
              {features?.length > 0 ? (
                features?.map((roadmapItem) => {
                  return (
                    <RoadmapCard
                      data={roadmapItem}
                      key={roadmapItem?.id}
                      onVoteRoadmapFeature={voteRoadmapFeature}
                      setRoadmapIframeDetails={setRoadmapIframeDetails}
                      setSeenRoadmapFeatures={setSeenRoadmapFeatures}
                    />
                  );
                })
              ) : (
                <EmptyStateContainer>
                  <Icon name="no-updates-roadmap" alt="no-updates-roadmap" />
                  <EmptyStateTextContainer>
                    <EmptyStateHeader>No updates yet!</EmptyStateHeader>
                    <EmptyStateText>
                      {
                        RoadmapSidebarOptions.find(
                          (option) => option.value === selectedOption
                        )?.emptyStateText
                      }
                    </EmptyStateText>
                  </EmptyStateTextContainer>
                </EmptyStateContainer>
              )}
            </ContentContainer>
          </RoadmapBodyContainer>
        </>
      )}
      {roadmapIframeUrl && (
        <IframeContainer>
          <iframe 
            src={roadmapIframeUrl} 
            title="Roadmap"
            style={{ 
              width: '100%',
              height: roadmapIframeDetails?.status === "UPCOMING" ? '100%' : 'calc(100vh - 3.5rem)',
              border: 'none',
            }}
          />
        </IframeContainer>
      )}
    </RoadmapContainer>
  );
};

export default Roadmap;

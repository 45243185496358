import styled from "styled-components";

export const WeekSelectionContainer = styled.div`
  border-radius: 0.6rem;
  background-color: #FFFFFF;
  min-height: 32vh;
  width: 18vw;
  display: flex;
  flex-direction: column;
  cursor: default;
  box-shadow: 0px 4px 6px 0px #0000001A;
  box-shadow: 0px 2px 4px 0px #0000000F;
  border: 1px solid #CED4DA;

  .Close_Icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
`
export const Body = styled.div`
  min-height: 20vh;
  padding: 1rem 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`
export const Options = styled.div`
  padding: 0;
  max-height: 17rem;
  overflow: scroll;
`;
export const CheckboxContainer = styled.div`
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: #252F3F;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: #edf2f7;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`
export const Title = styled.p`
  color: #374151;
  font-size: 0.9rem;
  font-weight: 400;
`
export const Text = styled.p`
  color: #A0AEC0;
  font-size: 0.8rem;
  font-weight: 400;
`
export const EmptySearchResult = styled.p`
  padding: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;
export const Footer = styled.div`
  padding: 1rem;
  border: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: #F9FAFB;
  }
`
export const Submit = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  color: #3F51B5;
`

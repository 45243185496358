import styled from "styled-components";
import { devices } from "../../../../constants/AppConstants";

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.2rem 0;
  border-bottom: ${(props) =>
    props.border ? "1px solid var(--cool-gray-300, #e2e8f0)" : "none"};

  &:last-child {
    border-bottom: none;
  }
`;

export const GroupTitle = styled.div`
  font-size: 1rem;
  padding: 0rem 2rem;
  color: var(--plain-gray-800, #252f3f);
  font-style: normal;
  font-weight: 500;

  @media screen and ${devices.mobile} {
    display: flex;
    background-color: #f7fafc;
    padding: 0;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.7rem;
  color: #374151;
  font-weight: 500;
  font-size: 0.9rem;
  padding: ${(props) =>
    props.isRadioType ? "0.25rem 2rem 0.25rem 1.7rem" : "0.25rem 2rem"};
  border: ${(props) =>
    props.isHiringFunnelViewMore
      ? "0.5px solid var(--cool-gray-300, #E2E8F0)"
      : "none"};

  &.isDraging {
    border: 0.5px solid var(--cool-gray-300, #e2e8f0);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const DragAndDropIconContainer = styled.div`
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  display: ${(props) => (props.shouldShow ? "flex" : "none")};
  align-items: center;
`;

export const GroupTitleContainer = styled.div`
  @media screen and ${devices.mobile} {
    display: flex;
    background-color: #f7fafc;
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
    justify-content: space-between;
    border-top-left-radius: ${(props) =>
    props.isFirstChild ? "1rem" : "none"};
    border-top-right-radius: ${(props) =>
    props.isFirstChild ? "1rem" : "none"};
  }
`;

import styled, { css } from "styled-components";
import { DEVICE_SIZES_PX } from "../../../constants/AppConstants";

export const FilterWidgetContainer = styled.div`
  background: #ffffff;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
  overflow: scroll;
  height: 500px;
`}
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  color: #374151;
  font-weight: 500;
  font-size: 0.9rem;
  gap: 0.8rem;
  padding: ${(props) => (props.addBottomPadding ? "1.2rem 0" : "1.2rem 0 0")};

  span {
    padding-left: 2rem;
  }
`;
export const Selection = styled.div`
  background-color: #e2e8f0;
  align-items: center;
  padding: 0.2rem 1.2rem 0.2rem 2rem;
  color: #718096;
  font-weight: 400;
  font-size: 0.8rem;
  border: 1px solid #e2e8f0;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.7rem;
  color: #374151;
  font-weight: 500;
  font-size: 0.9rem;
  padding: ${(props) =>
    props.isRadioType ? "0.25rem 2rem 0.25rem 1.7rem" : "0.25rem 2rem"};
  border: ${(props) =>
    props.isHiringFunnelViewMore
      ? "0.5px solid var(--cool-gray-300, #E2E8F0)"
      : "none"};

  &.isDraging {
    border: 0.5px solid var(--cool-gray-300, #e2e8f0);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;
export const Input = styled.input`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
export const Footer = styled.div`
  padding: 0.7rem 0.6rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-top: 0.5px solid #e0e0e0;
`;
export const Body = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 0.5rem;
`;

export const DragAndDropIconContainer = styled.div`
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  display: ${(props) => (props.shouldShow ? "flex" : "none")};
  align-items: center;
`;

export const BlankLine = styled.div`
  height: 1px;
  border-top: 1px solid var(--cool-gray-300, #e2e8f0);
  border-bottom: 1px solid var(--cool-gray-300, #e2e8f0);
  background: var(--cool-gray-200, #edf2f7);
  /* margin-bottom: 0.75rem; */
`;

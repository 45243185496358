import styled from "styled-components";

export const JobDetailsContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
export const EmptyContainer = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9fa6b2;
  }
`;

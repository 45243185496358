import {
  JOBS_BREAKDOWN_JOB_TITLE_KEY,
  JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY,
  JOBS_BREAKDOWN_OPTIMISED_JD_KEY,
  JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY,
  JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY,
  JOBS_BREAKDOWN_ORIGINAL_JD_KEY,
  JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY
} from "../constants"

export const parseJobDetails = (job) => {
  return {
    title: job?.[JOBS_BREAKDOWN_JOB_TITLE_KEY] || "",
    originalJobScore: `${parseInt(job?.[JOBS_BREAKDOWN_ORIGINAL_SCORE_KEY] * 100)}%`,
    originalJd: job[JOBS_BREAKDOWN_ORIGINAL_JD_KEY],
    originalJdInsights: job[JOBS_BREAKDOWN_ORIGINAL_INSIGHTS_KEY],
    optimisedJobScore: `${parseInt(job?.[JOBS_BREAKDOWN_OPTIMISED_SCORE_KEY] * 100)}%`,
    optimisedJd: job[JOBS_BREAKDOWN_OPTIMISED_JD_KEY],
    optimisedJdInsights: job[JOBS_BREAKDOWN_OPTIMISED_INSIGHTS_KEY]
  }
}

export const getBannerPosition = (score) => {
  if (score < 85) return `${score}%`
  if (score >= 85 && score < 90) return `calc(${score}% - 1rem)`
  if (score >= 90 && score <= 95) return `calc(${score}% - 8rem)`
  if (score > 95 && score <= 98) return `calc(${score}% - 10rem)`
  return `calc(${score}% - 12rem)`
}

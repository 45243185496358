import styled from 'styled-components'

export const ColumnSelectionContainer = styled.div`
  background-color: #FFFFFF;
  min-width: 50vw;
  height: 70vh;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const Header = styled.div`
  font-size: 1.1rem;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  font-weight: 600;
  color: #252F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252F3F;
`
export const Body = styled.div`
  min-height: 30vh;
  padding: 1rem 1.5rem;
  display: flex;
  gap: 0.8rem;
  flex: 1;

  &>* {
    flex: 1;
    /* min-width: 50%; */
  }
`
export const Footer = styled.div`
  padding: 1.2rem 1rem;
  background-color: #F9FAFB;
  border: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from "zustand/react/shallow";

import {
  AdvancedFilterContainer,
  MoreFilter,
  FilterContainer,
} from './styles'

import FilterLabel from '../common/FilterLabel';
import SearchFilter from '../common/SearchFilter';
import AutocompleteSearch from '../common/AutocompleteSearch';
import MultiSelect from '../common/Multiselect';
import Icon from '../common/Icon';

import {
  EXPANSIONS_TYPES,
  PUBLISHER_TYPES,
  STRING_SEPARATOR,
} from '../../constants/AppConstants';
import { getSuggestions, buildParams, isJobFilter, buildJobFilterPayload } from './helpers';
import { useFetchPublishers } from '../../api/publishers';
import { useFetchJobsSuggestions } from '../../api/jobs';
import Mixpanel from '../../services/MixPanel';
import { getScreenName } from '../../utils/helpers';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../stores/global';

const EXTENDED_FILTERS = [
  { title: "is" },
  { title: "is not" },
  { title: "contains" },
  { title: "doesn't contain" },
  { title: "starts with" },
  { title: "ends with" },
]

export const MULTISELECT_FILTERS = [
  {
    name: "Publisher type",
    placeholder: "Search publishers",
    options: PUBLISHER_TYPES
  },
  {
    name: "Expansion status",
    placeholder: "Search expansions",
    options: EXPANSIONS_TYPES
  }
]

const AdvancedFilters = ({
  filters,
  className,
  disabled,
  value,
  onChange,
}) => {
  const { globalFilters } = useGlobalFiltersStore(useShallow((state) => ({ globalFilters: state.globalFilters })));

  const { hierarchy } = useGlobalDataStore(useShallow((state) => ({
    hierarchy: state.hierarchy,
  })))

  const { currentProduct } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
  })))

  const [isFilterSelectionOpen, toggleFilterSelection] = useState(false)
  const [isExtendedFiltersOpen, toggleExtendedFilters] = useState(false)
  const [currentFilter, setCurrentFilter] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [multiselectSelections, setMultiselectSelections] = useState({})
  const [suggestions, setSuggestions] = useState([])
  const [searchText, setSearchText] = useState("")
  const [clickedLabelIndex, setClickedLabelIndex] = useState(0)
  const [clickedLabel, setClickedLabel] = useState(null)
  const [jobSearchText, setJobSearchText] = useState("")

  const containerRef = useRef(null)

  const {
    data: { data: { data: publishers = [] } = {} } = {},
  } = useFetchPublishers(
    buildParams(globalFilters),
    buildParams(globalFilters)
  );

  const {
    data: { data: { data: jobSuggestions = [] } = {} } = {},
    isLoading: isJobSuggestionsLoading
  } = useFetchJobsSuggestions(
    buildJobFilterPayload(globalFilters, currentFilter, jobSearchText)
  );

  const handleFilterSelection = (option) => {
    setCurrentFilter(option)
    toggleFilterSelection(false)
    toggleExtendedFilters(true)
    // scrollToBottomAutoComplete()
  }

  const handleExtendedFilterSelection = (payload) => {
    const doesExist = !!selectedFilters.find(item => item.key === payload.key)
    if (doesExist) {
      const index = selectedFilters.findIndex(item => item.key === payload.key)
      const filterValue = [...selectedFilters.slice(0, index), payload, ...selectedFilters.slice(index + 1)]
      onChange(filterValue)
      Mixpanel.trackEvent("Filter Applied", {
        Filters: filterValue,
        'Screen name': getScreenName()
      });
    } else {
      onChange([...selectedFilters, payload])
      Mixpanel.trackEvent("Filter Applied", {
        Filters: [...selectedFilters, payload],
        'Screen name': getScreenName()
      });
    }
    toggleExtendedFilters(false)
  }

  const handleMultiselectSelection = (selections) => {
    const filterName = currentFilter.name
    const entry = { key: filterName, matcher: "is", value: selections.map(item => item.value).join(STRING_SEPARATOR) }
    const existing = selectedFilters.find(item => item.key === filterName)
    if (!!existing) {
      const index = selectedFilters.findIndex(item => item.key === filterName)
      const filterValue = [...selectedFilters.slice(0, index), entry, ...selectedFilters.slice(index + 1)]
      onChange(filterValue)
    } else {
      onChange([...selectedFilters, entry])
    }
    toggleExtendedFilters(false)
  }

  const handleRemoveFilter = (filter) => {
    const fitlerValue = selectedFilters.filter(item => item.key !== filter.key)
    onChange(fitlerValue)
  }

  const handleLabelClick = (item, index) => {
    toggleExtendedFilters(!isExtendedFiltersOpen)
    setClickedLabelIndex(index)
    setClickedLabel(item)
    setCurrentFilter(filterOptions.find(i => i.name === item.key))
    if (!isExtendedFiltersOpen) {
      // scrollToBottomAutoComplete()
    }
  }

  const getPosition = () => {
    const nodes = [...containerRef.current?.childNodes]
    return `${nodes[clickedLabelIndex].offsetLeft}px`
  }

  const filtersToDisplay = useMemo(() => {
    const others = selectedFilters.filter(item => !MULTISELECT_FILTERS.find(i => i.name === item.key))
    if (!Object.entries(multiselectSelections)?.length) {
      return others
    }

    const entries = Object.entries(multiselectSelections).map(([key, value]) => ({
      key,
      matcher: "is",
      value: value.map(item => item.name).join(STRING_SEPARATOR)
    }))
    return [
      ...others,
      ...entries
    ]
  }, [selectedFilters, multiselectSelections])

  useEffect(() => {
    setSelectedFilters(value)
    const multiSelects = value.filter(item => !!MULTISELECT_FILTERS.find(i => i.name === item.key))
    if (multiSelects?.length) {
      const multiSelectValue = multiSelects.reduce((acc, item) => {
        const entry = MULTISELECT_FILTERS.find(i => i.name === item.key)
        const options = entry.options.filter(i => item.value.split(STRING_SEPARATOR).includes(i.value))
        return {
          ...acc,
          ...{ [item.key]: options }
        }
      }, {})
      setMultiselectSelections(multiSelectValue)
    } else {
      setMultiselectSelections([])
    }
  }, [value])

  useEffect(() => {
    const fetchSuggestions = async (search) => {
      if (isJobFilter(currentFilter)) {
        setJobSearchText(search)
        return
      }
      setJobSearchText("")
      try {
        const suggestionsFromApi = await getSuggestions({
          globalFilters,
          filters,
          currentFilter,
          search,
          hierarchyData: hierarchy,
          publishers,
          product: currentProduct
        });
        setSuggestions(suggestionsFromApi)
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    if (searchText.length >= 1) {
      fetchSuggestions(searchText);
    } else {
      setSuggestions([])
    }
  }, [searchText, hierarchy, globalFilters, filters, currentFilter, currentProduct]);

  const filterOptions = filters?.filter(
    (item) => !!item.enabled
  ) || [];

  const scrollToBottomAutoComplete = () => {
    const analyticsContainerElem = document.getElementsByClassName('ProgrammaticContainer')[0];
    if (analyticsContainerElem) {
      analyticsContainerElem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }
  };

  return (
    <AdvancedFilterContainer className={className}>
      <Icon name="filter" alt="filter" className="FilterIcon" />
      <FilterContainer ref={containerRef}>
        {
          filtersToDisplay?.map((item, index) => (
            <FilterLabel
              key={item.key}
              label={`${item.key} ${item.matcher}`}
              text={item.value}
              disabled={disabled}
              onClick={() => handleLabelClick(item, index)}
              onRemove={() => handleRemoveFilter(item)}
            />
          ))
        }
        {
          (selectedFilters.length !== filterOptions.length) && (
            <MoreFilter
              active={isFilterSelectionOpen || isExtendedFiltersOpen}
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  toggleFilterSelection(true)
                  setClickedLabelIndex(selectedFilters.length)
                  setClickedLabel(null);
                  Mixpanel.trackEvent(`${filtersToDisplay?.length >= 1 ? "More" : "Add"} Filters Clicked`, {
                    'Screen name': getScreenName()
                  })
                }
              }}>
              + {filtersToDisplay?.length >= 1 ? "More" : "Add"} filters
            </MoreFilter>
          )
        }
        {
          isFilterSelectionOpen && (
            <SearchFilter
              options={filterOptions.filter(item => !selectedFilters.find(i => i.key === item.name))}
              style={{ left: getPosition() }}
              idKey="id"
              valueKey="name"
              className="SearchFilter"
              placeholder="Search filter"
              onSelect={handleFilterSelection}
              onClose={() => toggleFilterSelection(false)}
            />
          )
        }
        {
          isExtendedFiltersOpen && (
            !MULTISELECT_FILTERS.find(item => item.name === currentFilter?.name)
              ?
              <AutocompleteSearch
                suggestions={isJobFilter(currentFilter) ? jobSuggestions : suggestions}
                title={currentFilter?.name}
                style={{ left: getPosition() }}
                filters={EXTENDED_FILTERS}
                selected={clickedLabel}
                suggestionsEnabled={currentFilter?.suggestionsEnabled}
                className="ExtendedFilter"
                isLoading={isJobSuggestionsLoading}
                onClose={() => {
                  toggleExtendedFilters(false)
                }}
                onSubmit={handleExtendedFilterSelection}
                onTextChange={(text) => setSearchText(text)}
                onSelect={() => { }}
              />
              :
              <MultiSelect
                title={currentFilter?.name}
                className="Multiselect"
                options={MULTISELECT_FILTERS.find(item => item.name === currentFilter?.name).options}
                style={{ left: getPosition() }}
                selected={multiselectSelections[currentFilter?.name]}
                onSubmit={handleMultiselectSelection}
                onClose={() => {
                  toggleExtendedFilters(false)
                }}
                placeholder={MULTISELECT_FILTERS.find(item => item.name === currentFilter?.name).placeholder}
              />
          )
        }
      </FilterContainer>
    </AdvancedFilterContainer>
  );
}

export default AdvancedFilters;
import React, { useEffect, useState } from "react";
import { IrisSelect, IrisTheme } from "@joveo/react-iris";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import { mediaPlannerClient } from "../../../helpers/axiosClient";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useGlobalDataStore } from "../../../stores/global";
import { useShallow } from "zustand/react/shallow";

const CustomerSelect = () => {
  const [clientListOptions, setClientListOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isSMPDataLoading,
    setIsSMPDataLoading,
    selectedSMPCustomerId,
    setSelectedSMPCustomerId,
  } = useGlobalDataStore(
    useShallow((state) => ({
      isSMPDataLoading: state.isSMPDataLoading,
      setIsSMPDataLoading: state.setIsSMPDataLoading,
      selectedSMPCustomerId: state.selectedSMPCustomerId,
      setSelectedSMPCustomerId: state.setSelectedSMPCustomerId,
    }))
  );

  // Get customerId from query params
  const getCurrentCustomerId = () => {
    return searchParams.get("customerId");
  };

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    setIsSMPDataLoading(true);
    setIsLoading(true);
    try {
      const response = await mediaPlannerClient.get("/clients");
      if (response.status === 200 && response?.data?.data?.length > 0) {
        const clientListOptions = response.data.data.map((client) => ({
          value: client.id,
          display: client.name,
        }));
        setClientListOptions(clientListOptions);
        // Get current customerId from query params or use first client
        const currentCustomerId = getCurrentCustomerId();
        const targetCustomerId =
          currentCustomerId || clientListOptions[0].value;
        // Find the selected client option
        const selectedClientOption = clientListOptions.find(
          (client) => client.value === targetCustomerId
        );
        // Update state and URL if needed
        setSelectedClient([selectedClientOption]);
        setSelectedSMPCustomerId(targetCustomerId);
        // Only update query params if needed
        if (!currentCustomerId || currentCustomerId !== targetCustomerId) {
          // Preserve existing query params while updating customerId
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set("customerId", targetCustomerId);
          if (location.pathname === "/media-planner") {
            navigate({
              pathname: "/media-planner/market-mp-insights",
              search: newSearchParams.toString(),
            });
          } else {
            setSearchParams(newSearchParams);
          }
        }
      }
    } catch (error) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("errorAPP", "SALES_MEDIA_PLANNER");
      navigate({
        pathname: "/error",
        search: newSearchParams.toString(),
      });
    } finally {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("errorAPP", "SALES_MEDIA_PLANNER");
      setIsSMPDataLoading(false);
      setIsLoading(false);
    }
  };

  const handleClientSelect = (options) => {
    if (!options || options.length === 0) return;
    setIsSMPDataLoading(true);
    const selectedOption = options[0];
    setSelectedClient(options);
    setSelectedSMPCustomerId(selectedOption.value);

    // Update query params while preserving existing ones
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("customerId", selectedOption.value);
    newSearchParams.delete("campaignId");
    setSearchParams(newSearchParams);
    setIsSMPDataLoading(false);
  };

  return (
    <ThemeProvider theme={IrisTheme}>
      <CssBaseline />
      <Box
        sx={{
          "& > .MuiFormControl-root:first-of-type": {
            height: "56px",
            background: "#202E49 !important",
            overflow: "hidden",
            width: "180px",
          },
          "& .MuiTypography-root": {
            color: "#FFFFFF80",
            pt: 1,
            mb: "0px !important",
            ml: 2.5,
          },
        }}
      >
        {isSMPDataLoading || isLoading ? (
          <Skeleton variant="text" width={180} height={48} />
        ) : (
          <IrisSelect
            fieldLabel={"Client"}
            options={clientListOptions.length > 0 ? clientListOptions : []}
            width="256px"
            onSelect={handleClientSelect}
            selectedValues={selectedClient}
            showSearch={true}
            MenuProps={{
              PaperProps: {
                sx: {
                  "&.MuiPaper-root": {
                    mt: 1.5,
                    ml: 4.75,
                  },
                },
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                border: 0,
                pl: 2.5,
                "& .MuiSelect-icon": {
                  mr: 1,
                },
              },
              "& .MuiSelect-select": {
                border: 0,
                boxShadow: "none",
                padding: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: "none",
                },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: "none",
                },
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiSelect-icon": {
                color: "#FFFFFF",
              },
            }}
            searchPlaceHolder="Search Customer Name"
          />
        )}
      </Box>
    </ThemeProvider>
  );
};

export default CustomerSelect;

import React from 'react';

import { RadioButtonContainer, Label } from './styles'

const RadioButton = ({ id, label, checked, disabled, onChange }) => {

  return (
    <RadioButtonContainer>
      <input
        type="radio"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <Label htmlFor={id} disabled={disabled} />
      <span htmlFor={id}>{label}</span>
    </RadioButtonContainer>
  );
};

export default RadioButton;

import {
  EMAIL_LS_KEY,
  LOOKER_FILTER_INITIAL_VALUES,
  LOOKER_FILTER_KEYS,
  IDENTITY_PROVIDER_LS_KEY,
  ACCESS_TOKEN_LS_KEY,
  UA_PRODUCT_ID,
  DEVICE_SIZES_PX,
  MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY,
  PROGRAMMATIC_IDENTIFIERS,
  CAREER_SITE_IDENTIFIERS,
  CRM_IDENTIFIERS,
  UNIFIED_VIEW_IDENTIFIERS,
  COMMON_GLOBAL_FILTERS,
  JOB_SITE_IDENTIFIERS,
  MARKET_INSIGHTS_IDENTIFIERS,
  COMPETITOR_INSIGHTS_IDENTIFIERS
} from "../../constants/AppConstants"
import {
  REPORTS_TABS
} from "../../constants/ReportsConstants";
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from "../../helpers/commonHelper";
import { getFiltersFromQueryString } from "../../utils/helpers";
import Mixpanel from "../../services/MixPanel";
import { searchHierarchyData } from "../../utils/searchHelpers";
import { COMPETITOR_INSIGHTS_ROUTE, CUSTOM_DASHBOARDS_ROUTE, DETAILED_INSIGHTS_ROUTE, JOBS_BREAKDOWN_ROUTE, JOB_QUALITY_ROUTE, MARKET_INSIGHTS_ROUTE } from "../../constants/AppRoutes";

export const JOB_OPTIONS = [
  { id: 1, title: "View jobs", icon: "link" },
  { id: 2, title: "View job group insights", icon: "link" }
]

export const processHierarchyData = (hierarchyData, products) => {
  if (!hierarchyData || !hierarchyData?.length || !products?.length) {
    return null;
  }

  const processed = {
    [PROGRAMMATIC_IDENTIFIERS.productKey]: [],
    [CAREER_SITE_IDENTIFIERS.productKey]: [],
    [CRM_IDENTIFIERS.productKey]: [],
    [UNIFIED_VIEW_IDENTIFIERS.productKey]: [],
    [JOB_SITE_IDENTIFIERS.productKey]: [],
  }

  const programmatic = hierarchyData.find(item => item.data.config.url.includes(PROGRAMMATIC_IDENTIFIERS.apiIdentifierKey))
  const careerSite = hierarchyData.find(item => item.data.config.url.includes(CAREER_SITE_IDENTIFIERS.apiIdentifierKey))
  const crm = hierarchyData.find(item => item.data.config.url.includes(CRM_IDENTIFIERS.apiIdentifierKey))
  const unifiedView = hierarchyData.find(item => item.data.config.url.includes(UNIFIED_VIEW_IDENTIFIERS.apiIdentifierKey))
  const jobSite = hierarchyData.find(item => item.data.config.url.includes(JOB_SITE_IDENTIFIERS.apiIdentifierKey))

  if (programmatic?.data?.data?.data?.length) {
    processed[PROGRAMMATIC_IDENTIFIERS.productKey] = programmatic.data.data.data
  }

  if (careerSite?.data?.data?.data?.length) {
    processed[CAREER_SITE_IDENTIFIERS.productKey] = careerSite.data.data.data
  }

  if (crm?.data?.data?.data?.length) {
    processed[CRM_IDENTIFIERS.productKey] = crm.data.data.data
  }

  if (unifiedView?.data?.data?.data?.length) {
    processed[UNIFIED_VIEW_IDENTIFIERS.productKey] = unifiedView.data.data.data
  }

  if (jobSite?.data?.data?.data?.length) {
    processed[JOB_SITE_IDENTIFIERS.productKey] = jobSite.data.data.data
  }

  return processed
}

export const getLookerFilterWithoutDashboardId = (filters) => {
  const filtersCopy = { ...filters };
  delete filtersCopy[LOOKER_FILTER_KEYS.DASHBOARD_ID];
  return filtersCopy;
};

export const buildAccessTokenPayload = () => {
  if (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)) return null

  const payload = {
    email: getItemFromLocalStorage(EMAIL_LS_KEY),
    productId: UA_PRODUCT_ID,
  };

  const identityProvider = getItemFromLocalStorage(IDENTITY_PROVIDER_LS_KEY);
  if (!!identityProvider) {
    payload['identityProvider'] = identityProvider
  }

  return payload
}

export const doesDefaultFilterExist = (filters, location) => {
  const reports = REPORTS_TABS.map(item => item.title)
  const analyticsViews = filters.filter(item => !reports.includes(item.dashboardId))
  const reportsViews = filters.filter(item => reports.includes(item.dashboardId))
  const viewsToSearch = location.pathname.includes("report") ? reportsViews : location.pathname === "/" ? analyticsViews : []
  return viewsToSearch.find(item => !!item.isDefault)
}

export const doesFilterIdExist = (filters, filterId, location) => {
  const reports = REPORTS_TABS.map(item => item.title)
  const analyticsViews = filters.filter(item => !reports.includes(item.dashboardId))
  const reportsViews = filters.filter(item => reports.includes(item.dashboardId))
  const isReport = location.pathname.includes("report")
  if (!isReport) return !!analyticsViews?.find(item => item.filterId === filterId)
  const reportTab = REPORTS_TABS.find(item => location.pathname.includes(item.redirect))
  if (!reportTab) return false
  return !!reportsViews?.find(item => item.filterId === filterId && item.dashboardId === reportTab.title)
}

export const hasFilterBeenChangedOrApplied = (prev, current) => {
  return (!prev?.[LOOKER_FILTER_KEYS.FILTER_ID] && !!current?.[LOOKER_FILTER_KEYS.FILTER_ID]) ||
    ((!!prev?.[LOOKER_FILTER_KEYS.FILTER_ID] && !!current?.[LOOKER_FILTER_KEYS.FILTER_ID]) &&
      (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] !== current?.[LOOKER_FILTER_KEYS.FILTER_ID]))
}

export const hasNavFiltersChanged = (prev, current) => {
  const prevFull = { ...LOOKER_FILTER_INITIAL_VALUES, ...prev }
  const currentFull = { ...LOOKER_FILTER_INITIAL_VALUES, ...current }
  return !!currentFull?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    (
      (prevFull?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== currentFull?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
      (prevFull?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== currentFull?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
      (prevFull?.[LOOKER_FILTER_KEYS.CLIENT_ID] !== currentFull?.[LOOKER_FILTER_KEYS.CLIENT_ID]) ||
      (prevFull?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] !== currentFull?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) ||
      (prevFull?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] !== currentFull?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID])
    )
  // (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))
}

export const hasGlobalFiltersChanged = (prev, current, product) => {
  if (!product) return false
  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return !!current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
        ((prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
          (prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
          (prev?.[LOOKER_FILTER_KEYS.CLIENT_ID] !== current?.[LOOKER_FILTER_KEYS.CLIENT_ID]) ||
          (prev?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] !== current?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) ||
          (prev?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] !== current?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) ||
          (prev?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] !== current?.[LOOKER_FILTER_KEYS.CURRENCY_RATE]) ||
          ((!!prev?.[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] && !!current?.[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]) &&
            (prev?.[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] !== current?.[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY])) &&
          (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))

    case CAREER_SITE_IDENTIFIERS.productKey:
      return ((prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SITE_ID] !== current?.[LOOKER_FILTER_KEYS.SITE_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] !== current?.[LOOKER_FILTER_KEYS.CURRENCY_RATE]))
    // (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))

    case CRM_IDENTIFIERS.productKey:
      return ((prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] !== current?.[LOOKER_FILTER_KEYS.CURRENCY_RATE]))
    // (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))

    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return ((prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] !== current?.[LOOKER_FILTER_KEYS.CURRENCY_RATE]))
    // (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))

    case JOB_SITE_IDENTIFIERS.productKey:
      return ((prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] !== current?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.SITE_ID] !== current?.[LOOKER_FILTER_KEYS.SITE_ID]) ||
        (prev?.[LOOKER_FILTER_KEYS.CURRENCY_RATE] !== current?.[LOOKER_FILTER_KEYS.CURRENCY_RATE]))
    // (prev?.[LOOKER_FILTER_KEYS.FILTER_ID] === current?.[LOOKER_FILTER_KEYS.FILTER_ID]))

    default:
      return false
  }
}

export const hasFilterLevelChanged = (prev, current, product = PROGRAMMATIC_IDENTIFIERS) => {
  const globalFilters = product.globalFilters
  const prevChangeCount = globalFilters.findIndex(item => !prev?.[item])
  const currentChangeCount = globalFilters.findIndex(item => !current?.[item])
  return prevChangeCount !== currentChangeCount
}

export const hasFiltersChangedInSavedFilter = (filterId, filters, params) => {
  const filter = filters?.find(item => item?.filterId === filterId)
  const filterMap = getFiltersFromQueryString(filter?.value)
  if (!!filterMap) {
    const hasChanged = hasNavFiltersChanged(filterMap, params)
    return hasChanged
  }
  return false
}

export const searchFiltersForParams = (filters, params, type = "dashboard") => {
  const reportTitles = REPORTS_TABS.map(item => item.title)
  const dbFilters = filters.filter(item => !reportTitles.includes(item.dashboardId))
  const reportFilters = filters.filter(item => reportTitles.includes(item.dashboardId))
  const filtersByType = type === "dashboard" ? dbFilters : reportFilters
  return filtersByType?.find(item => {
    const itemFilterMap = getFiltersFromQueryString(item?.value)
    const filterKeys = [LOOKER_FILTER_KEYS.ACCOUNT_ID, LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID, LOOKER_FILTER_KEYS.CLIENT_ID, LOOKER_FILTER_KEYS.CAMPAIGN_ID, LOOKER_FILTER_KEYS.JOB_GROUP_ID]
    return filterKeys.every(key => {
      if ((!!itemFilterMap?.[key]) && (!!params?.[key])) {
        return itemFilterMap[key] === params[key]
      }
      else if ((!itemFilterMap?.[key]) && !(params?.[key])) {
        return true
      }
      return false
    })
  })
}

export const handleWindowEvents = ({
  event,
  setLocalWidgetFilters,
  setViewMoreDb,
  setDbDetails,
  navigate,
  searchParams,
  hierarchyData,
  setSearchParams,
  setJobGroupDetails
}) => {
  console.log("window message", event.data)
  if (event.data?.type === "localFilter") {
    console.log("Parent received successfully in main content.", event);
    console.log("Updating dashboard local filters", event.data.payload);
    setLocalWidgetFilters(lwf => ({ ...lwf, ...event.data.payload }));
  }
  if (event.data?.type === "viewMore") {
    console.log("View more", event.data);
    setViewMoreDb(event.data?.widget);
    // toggleDialog(true);
  }
  if (event.data?.type === "viewMoreLink") {
    console.log("View more link", event.data);
    const params = Object.fromEntries([...searchParams]);
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    setDbDetails(event.data?.widget);
    navigate({
      pathname: DETAILED_INSIGHTS_ROUTE,
      search: urlSearchParams,
    });
  }
  if (event.data?.type === "trackMixPanelEvent") {
    console.log("MixPanel Event Data", event.data);
    const { eventName, eventProps } = event.data;
    Mixpanel.trackEvent(eventName, {
      ...eventProps,
    });
  }
  if (event.data?.type === "viewMoreJQ") {
    console.log("View more link Job quality", event.data);
    const params = Object.fromEntries([...searchParams]);
    const urlSearchParams = "?" + new URLSearchParams(params).toString();
    navigate({
      pathname: JOB_QUALITY_ROUTE,
      search: urlSearchParams,
    });
  }
  if (event.data?.type === "filter") {
    let accountId, subAccountId, agencyId, clientId, campaignId, jobGroupId, jobGroupName;
    console.log("event data ", event.data)
    if (
      !!event.data.payload[LOOKER_FILTER_KEYS.CAMPAIGN_ID] ||
      !!event.data.payload[LOOKER_FILTER_KEYS.CLIENT_ID]
    ) {
      campaignId = event.data.payload[LOOKER_FILTER_KEYS.CAMPAIGN_ID];
      clientId = event.data.payload[LOOKER_FILTER_KEYS.CLIENT_ID];
      console.log("client", clientId, "campaign", campaignId)
      if (!!clientId) {
        for (let account of hierarchyData) {
          const subAccounts = account.subAccounts
          for (let subAccount of subAccounts) {
            const clients = subAccount.clients;
            const client = clients.find((cl) => cl.id === clientId)
            if (!!client) {
              accountId = account.id;
              subAccountId = subAccount.id
              agencyId = client.agencyId
              break
            }
          }
        }
      }
      if (!!campaignId) {
        for (let account of hierarchyData) {
          const subAccounts = account.subAccounts
          for (let subAccount of subAccounts) {
            const clients = subAccount.clients;
            const client = clients.find((cl) => {
              const campaigns = cl.campaigns;
              return !!campaigns.find((c) => c.id === campaignId);
            });
            if (!!client) {
              clientId = client.id;
              agencyId = client.agencyId
              accountId = account.id;
              subAccountId = subAccount.id
              break
            }
          }
        }
      }
      console.log({ accountId, subAccountId, agencyId, clientId, campaignId });
      console.log("Updating global filters", event.data);
      const params = Object.fromEntries([...searchParams]);
      console.log("Existing filter params", params);
      if ((!!accountId && !!subAccountId && !!clientId && !!agencyId) || !!campaignId) {
        setSearchParams({
          ...params,
          [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
          [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
          [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
          // [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId,
          ...(!!campaignId && { [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId }),
        });
      }
    } else if (!!event.data.payload[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) {
      console.log("event data", event.data)
      jobGroupId = event.data.payload[LOOKER_FILTER_KEYS.JOB_GROUP_ID];

      for (let account of hierarchyData) {
        for (let subAccount of account.subAccounts) {
          for (let client of subAccount.clients) {
            for (const campaign of client.campaigns) {
              const jobGroup = campaign.jobGroups.find(
                (group) => group.id === jobGroupId
              );
              if (jobGroup) {
                accountId = account.id;
                subAccountId = subAccount.id
                clientId = client.id;
                agencyId = client.agencyId
                campaignId = campaign.id;
                jobGroupName = jobGroup.name
                break;
              }
            }
          }
        }
      }
      const params = Object.fromEntries([...searchParams]);
      console.log("Existing filter params", params);
      console.log("params", { accountId, subAccountId, agencyId, clientId, campaignId, jobGroupId, jobGroupName })
      if (!!accountId && !!subAccountId && !!agencyId && !!clientId && !!campaignId && !!jobGroupId) {
        if (event.data.payload?.type === JOB_OPTIONS[0].title) {
          const params = Object.fromEntries([...searchParams]);
          const urlSearchParams = "?" + new URLSearchParams(params).toString();
          const jgDetails = {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
            [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
            [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId,
            [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
            [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
            [LOOKER_FILTER_KEYS.DATE]: params[LOOKER_FILTER_KEYS.DATE] || "this month",
            [LOOKER_FILTER_KEYS.CURRENCY_RATE]: params[LOOKER_FILTER_KEYS.CURRENCY_RATE] || "",
            jobGroupName
          }
          setJobGroupDetails({ ...jgDetails })
          if (window.innerWidth < DEVICE_SIZES_PX.MOBILE) {
            navigate({
              pathname: JOBS_BREAKDOWN_ROUTE,
              search: urlSearchParams,
              state: jgDetails
            });
          }
        } else if (event.data.payload?.type === JOB_OPTIONS[1].title) {
          setSearchParams({
            ...params,
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
            // [LOOKER_FILTER_KEYS.AGENCY_ID]: agencyId,
            [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
            [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
            [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
          });
        }

      }
    }
  }
}

export const setMixPanelAttributes = (params, hierarchyData) => {
  if (hierarchyData) {
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.INSTANCE_NAME
    );
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.ACCOUNT_NAME
    );
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.SUB_ACCOUNT_NAME
    );
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CLIENT_NAME
    );
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CAMPAIGN_NAME
    );
    removeItemFromLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.JOB_GROUP_NAME
    );

    const searchRes = searchHierarchyData(hierarchyData, params)
    if (!!searchRes?.name) {
      const { name } = searchRes
      if (!!name[LOOKER_FILTER_KEYS.ACCOUNT_ID]) {
        setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.ACCOUNT_NAME, !!name[LOOKER_FILTER_KEYS.ACCOUNT_ID])
      }
      if (!!name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]) {
        setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.SUB_ACCOUNT_NAME, !!name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID])
      }
      if (!!name[LOOKER_FILTER_KEYS.CLIENT_ID]) {
        setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CLIENT_NAME, !!name[LOOKER_FILTER_KEYS.CLIENT_ID])
      }
      if (!!name[LOOKER_FILTER_KEYS.CAMPAIGN_ID]) {
        setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.CAMPAIGN_NAME, !!name[LOOKER_FILTER_KEYS.CAMPAIGN_ID])
      }
      if (!!name[LOOKER_FILTER_KEYS.JOB_GROUP_ID]) {
        setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.JOB_GROUP_NAME, !!name[LOOKER_FILTER_KEYS.JOB_GROUP_ID])
      }
    }
    setItemInLocalStorage(
      MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.TIMEFRAME,
      params[LOOKER_FILTER_KEYS.DATE] || "this month"
    );
  }
}


export const getWebViewDashboardList = ({
  currentUADashboardId,
  currentDashboardId,
  additionalDashboards,
}) => {
  if (!additionalDashboards) return []
  const additionalDashboardsWebView = additionalDashboards.map(
    (dashboardObj) => ({
      ...dashboardObj,
      isActive: currentDashboardId === dashboardObj?.id,
      iconName:
        currentUADashboardId === dashboardObj?.id
          ? "custom-dashboard-active"
          : "custom-dashboard-inactive",
    })
  );
  return additionalDashboardsWebView;
}

export const getMobileViewDashboardList = ({
  currentUADashboardId,
  currentDashboardId,
  additionalDashboards,
}) => {
  const UA_DASHBOARD = {
    name: "Programmatic Analytics",
    id: currentUADashboardId,
    iconName:
      currentDashboardId === currentUADashboardId &&
        !window.location.pathname.includes("reports")
        ? "main-dashboard-hamburger-active"
        : "main-dashboard-hamburger-inactive",
    isActive:
      currentDashboardId === currentUADashboardId &&
      !window.location.pathname.includes("reports"),
    iconStyle: {
      width: "19px",
      height: "19px",
      marginRight: "0.5rem",
      cursor: "pointer",
    },
  };
  if (!additionalDashboards) {
    return [UA_DASHBOARD];
  }
  const additionalDashboardsMobileView = additionalDashboards.map(
    (dashboardObj) => ({
      ...dashboardObj,
      isActive:
        currentDashboardId === dashboardObj?.id &&
        !window.location.pathname.includes("reports"),
      // iconName:
      //   currentDashboardId === dashboardObj?.id &&
      //     !window.location.pathname.includes("reports")
      //     ? "custom-dashboard-hamburger-active"
      //     : "custom-dashboard-hamburger-inactive",
      iconStyle: {
        width: "19px",
        height: "19px",
        marginRight: "0.5rem",
      },
    })
  );
  return [].concat(additionalDashboardsMobileView);
}

const buildProgrammaticEmbedUrlParams = (params) => {
  const filters = getLookerFilterWithoutDashboardId(params)
  const paramsToPass = {
    ...(!!Object.entries(filters || {}).length && { filters: new URLSearchParams(filters).toString() }),
    ...((window.location.pathname === CUSTOM_DASHBOARDS_ROUTE && !!params[LOOKER_FILTER_KEYS.DASHBOARD_ID]) && { dashboardId: params[LOOKER_FILTER_KEYS.DASHBOARD_ID] }),
    isCustomDashboard: window.location.pathname === CUSTOM_DASHBOARDS_ROUTE
  };
  return paramsToPass
}

const buildCareerSiteEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...CAREER_SITE_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => item !== LOOKER_FILTER_KEYS.DASHBOARD_ID)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return paramsToPass
}

const buildJobSiteEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...JOB_SITE_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.slice(0, 3)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return paramsToPass
}

const buildCRMEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...CRM_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => item !== LOOKER_FILTER_KEYS.DASHBOARD_ID)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return paramsToPass
}

const buildUnifiedViewEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...UNIFIED_VIEW_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => item !== LOOKER_FILTER_KEYS.DASHBOARD_ID)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return paramsToPass
}

const buildMarketInsightsEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...MARKET_INSIGHTS_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => item !== LOOKER_FILTER_KEYS.DASHBOARD_ID)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return {
    ...(!!Object.entries(paramsToPass || {}).length && { filters: new URLSearchParams(paramsToPass).toString() }),
  }
}

const buildCompetitorInsightsEmbedUrlParams = (params) => {
  const paramsToPass = [
    ...COMPETITOR_INSIGHTS_IDENTIFIERS.globalFilters,
    ...COMMON_GLOBAL_FILTERS.filter(item => item !== LOOKER_FILTER_KEYS.DASHBOARD_ID)
  ]
    .filter(item => !!params[item])
    .reduce((acc, item) => ({ ...acc, [item]: params[item] }), {})

  return {
    ...(!!Object.entries(paramsToPass || {}).length && { filters: new URLSearchParams(paramsToPass).toString() }),
  }
}

export const buildEmbedUrlParams = (currentProduct, params) => {
  if (!params || !currentProduct) return null
  switch (currentProduct.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey: {
      if (currentProduct.route.includes(MARKET_INSIGHTS_ROUTE)) {
        return buildMarketInsightsEmbedUrlParams(params)
      }
      if (currentProduct.route.includes(COMPETITOR_INSIGHTS_ROUTE)) {
        return buildCompetitorInsightsEmbedUrlParams(params)
      }
      return buildProgrammaticEmbedUrlParams(params)
    }
    case CAREER_SITE_IDENTIFIERS.productKey: {
      return buildCareerSiteEmbedUrlParams(params)
    }
    case CRM_IDENTIFIERS.productKey: {
      return buildCRMEmbedUrlParams(params)
    }
    case UNIFIED_VIEW_IDENTIFIERS.productKey: {
      return buildUnifiedViewEmbedUrlParams(params)
    }
    case JOB_SITE_IDENTIFIERS.productKey: {
      return buildJobSiteEmbedUrlParams(params)
    }
    default:
      return buildProgrammaticEmbedUrlParams(params)
  }
}

export const getWeekStartDay = (filters, accounts) => {
  const accountId = filters[LOOKER_FILTER_KEYS.ACCOUNT_ID];
  const subAccountId = filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID];
  const account = accounts.find(acc => acc.id === accountId)
  if (typeof account?.weekStartDay === "number") return account.weekStartDay
  return Object.values(account?.weekStartDay || {})[0]
}

export const getApiIdentifierKey = (product) => {
  if (!product) return ""
  if (product.route === MARKET_INSIGHTS_ROUTE) return MARKET_INSIGHTS_IDENTIFIERS.apiIdentifierKey
  if (product.route === COMPETITOR_INSIGHTS_ROUTE) return COMPETITOR_INSIGHTS_IDENTIFIERS.apiIdentifierKey
  return product.apiIdentifierKey
}

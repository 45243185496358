import React from 'react';

import {
  BaseProgressBar,
  ScoreIndicatorBarContainer,
  Label,
  IconContainer,
  ProgressBar
} from "./styles"

const ScoreIndicatorBar = ({
  type = "medium"
}) => {
  const indicator = type === "low" ? "😔" : type === "medium" ? "👍🏻" : "🤩"

  return (
    <ScoreIndicatorBarContainer>
      <BaseProgressBar>
        <Label type="low">Low</Label>
        <Label type="high">High</Label>
        <IconContainer type={type}>{indicator}</IconContainer>
        <ProgressBar type={type} />
      </BaseProgressBar>
    </ScoreIndicatorBarContainer>
  );
}

export default ScoreIndicatorBar;

import React, { useState, useEffect, useMemo } from 'react'
import { useShallow } from "zustand/react/shallow"

import {
  NavbarFilterContainerMobile,
  ApplyButton,
  BreadCrumbContainer,
  BreadCrumbText,
  DropdownLabel,
  DropdownOption,
  DropdownOptionListContainer,
  DropdownOptionsContainer,
  EntityTextContainer,
  Header,
  ContentContainer,
  FilterContainer,
  HeaderFilterContainer,
  ViewSelectionContainer,
  TabsContainer,
  SearchHeaderContainer,
  EmptyContainer
} from './styles';

import Icon from '../../common/Icon';
import BottomModal from '../../common/BottomModal';
import Button from '../../common/Button';
import SearchBox from '../../common/SearchBox';
import Title from '../../common/Title';
import Tab from '../../common/Tab';
import ViewSelection from '../ViewSelection';
import Loader from '../../common/Loader';
import EmptyText from '../../common/EmptyText';

import { LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from '../../../constants/AppConstants';
import { REPORTS_TABS } from '../../../constants/ReportsConstants';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../../stores/global';

const VIEW_TYPES = [
  { name: "All" },
  { name: "Saved views" },
]

const VIEW_TABS = [
  { name: "Analytics" },
  { name: "Reports" }
]

const NavbarFilterMobile = ({
  isOpen,
  currentEntity,
  filtersMetadata,
  navFilters,
  entityDropdownOptions,
  breadCrumbText,
  searchValue,
  onSearchTextChange,
  onSubmitAndCancel,
  searchResults = [],
  loadingStates,
  onFilterChange
}) => {
  const { views } = useGlobalDataStore(useShallow((state) => ({
    views: state.views,
  })))

  const { currentProduct, globalFilters } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
    globalFilters: state.globalFilters
  })))

  const [selectedViewType, setSelectedViewType] = useState("")
  const [selectedTab, setSelectedTab] = useState("")
  const [isSearching, toggleSearching] = useState(false)

  const filteredViews = useMemo(() => {
    if (!views?.length || !globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID]) return []
    const reports = REPORTS_TABS.map(item => item.title)
    const analyticsViews = views.filter(item => item.dashboardId === globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID])
    const reportsViews = views.filter(item => reports.includes(item.dashboardId))
    const viewsToReturn = selectedTab === VIEW_TABS[0].name ? analyticsViews : reportsViews
    return viewsToReturn.map(item => ({ ...item, product: currentProduct }))
  }, [views, selectedTab, globalFilters, currentProduct])

  useEffect(() => {
    setSelectedViewType(Object.values(filtersMetadata || {}).length > 2 ? VIEW_TYPES[0].name : VIEW_TYPES[1].name)
  }, [filtersMetadata])

  useEffect(() => {
    setSelectedTab(window.location.pathname?.includes("report") ? VIEW_TABS[1].name : VIEW_TABS[0].name)
  }, [])

  useEffect(() => {
    toggleSearching(searchValue?.length > 0)
  }, [searchValue])

  return (
    <NavbarFilterContainerMobile>
      {
        isOpen && (
          <BottomModal
            isOpen={isOpen}
            onClose={() => onSubmitAndCancel("cancel")}
            style={{ height: "550px" }}
          >
            <ContentContainer>
              <Header isSearching={isSearching}>
                {
                  !isSearching && (
                    <HeaderFilterContainer>
                      {
                        ((Object.values(filtersMetadata || {}).length > 2) ? VIEW_TYPES : VIEW_TYPES.slice(1)).map(type => (
                          <Button
                            key={type.name}
                            text={type.name}
                            type={selectedViewType === type.name ? "active" : ""}
                            className="NavbarFilterDropdown__button"
                            onClick={() => {
                              setSelectedViewType(type.name)
                            }}
                          />
                        ))
                      }
                    </HeaderFilterContainer>
                  )
                }
                {
                  isSearching && (
                    <SearchHeaderContainer>
                      <Icon
                        name="arrow-left"
                        className="Back__icon"
                        onClick={() => onSearchTextChange("")}
                      />
                      <Title text="Search" />
                    </SearchHeaderContainer>
                  )
                }
                <SearchBox
                  placeholder='Search'
                  clearIcon
                  value={searchValue}
                  onChange={onSearchTextChange}
                />
              </Header>
              {
                (!!searchResults?.length && !!searchValue && !loadingStates?.get) && (
                  <ViewSelection
                    views={searchResults}
                    loadingStates={loadingStates}
                    onOptionChange={onFilterChange}
                  />
                )
              }
              {
                (!searchResults?.length && !searchValue && selectedViewType === VIEW_TYPES[0].name) && (
                  <FilterContainer>
                    <DropdownOptionsContainer>
                      <BreadCrumbContainer>
                        {currentEntity !== LOOKER_FILTER_KEYS.ACCOUNT_ID && (
                          <Icon
                            name="arrow-left"
                            onClick={filtersMetadata[currentEntity]?.onBack}
                            style={{ cursor: "pointer" }}
                          />
                        )}{" "}
                        <BreadCrumbText>{breadCrumbText}</BreadCrumbText>
                      </BreadCrumbContainer>
                      <EntityTextContainer>
                        {filtersMetadata?.[currentEntity]?.title || ""} (
                        {filtersMetadata?.[currentEntity]?.count - 1 || ""})
                      </EntityTextContainer>
                      <DropdownOptionListContainer>
                        {entityDropdownOptions?.map((dropdownOption) => (
                          <DropdownOption
                            key={dropdownOption.id}
                            onClick={() => filtersMetadata?.[currentEntity]?.onSelect(dropdownOption.id)}
                            isSelected={dropdownOption?.id === navFilters[currentEntity]}
                          >
                            <DropdownLabel>{dropdownOption.value}</DropdownLabel>
                            <span>
                              <Icon name="arrow-right" style={{ cursor: "pointer" }} />
                            </span>
                          </DropdownOption>
                        ))}
                      </DropdownOptionListContainer>
                    </DropdownOptionsContainer>
                    <ApplyButton onClick={() => onSubmitAndCancel("submit")}>Apply</ApplyButton>
                  </FilterContainer>
                )
              }
              {
                (!searchResults?.length && !searchValue && selectedViewType === VIEW_TYPES[1].name) && (
                  <ViewSelectionContainer>
                    {
                      (currentProduct?.productKey === PROGRAMMATIC_IDENTIFIERS.productKey) && (
                        <TabsContainer>
                          <Tab
                            options={VIEW_TABS}
                            displayKey="name"
                            selected={selectedTab}
                            onTabChange={(tab) => setSelectedTab(tab.name)}
                          />
                        </TabsContainer>
                      )
                    }
                    <ViewSelection
                      views={filteredViews}
                      loadingStates={loadingStates}
                      onOptionChange={onFilterChange}
                    />
                  </ViewSelectionContainer>
                )
              }
              {
                (!!loadingStates?.get && !!searchValue) && (
                  <EmptyContainer>
                    <Loader />
                  </EmptyContainer>
                )
              }
              {
                (!!searchValue && !searchResults?.length && !loadingStates?.get) && (
                  <EmptyContainer>
                    <EmptyText text="No results found" />
                  </EmptyContainer>
                )
              }
            </ContentContainer>
          </BottomModal>
        )
      }
    </NavbarFilterContainerMobile>
  );
}

export default NavbarFilterMobile;

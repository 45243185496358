import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/browser"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import App from "./App";
// import Maintenance from "./pages/Maintenance"
import reportWebVitals from "./reportWebVitals";

import { FontStyles, GlobalStyle } from "./styles/GlobalStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,

  integrations: [
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
    new Sentry.BrowserTracing({
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

root.render(
  <>
    <FontStyles />
    <GlobalStyle />
    <App />
    {/* <Maintenance /> */}
  </>
);

reportWebVitals();

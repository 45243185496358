import { Transition } from 'react-transition-group';
import React, { useRef } from 'react';

import Icon from '../../../../components/common/Icon';

const duration = 200;

const defaultStyle = {
  transition: `opacity 0.2s ease-in`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0.5 },
  entered: { opacity: 1 },
  exiting: { opacity: 0.5 },
  exited: { opacity: 0 },
};

function Fade({ in: inProp, assetName }) {
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {state => (
        <div ref={nodeRef} style={{
          justifySelf: "center",
          ...defaultStyle,
          ...transitionStyles[state],
        }}>
          <Icon name={assetName} style={{ justifySelf: "center" }} />
        </div>
      )}
    </Transition>
  );
}

export default Fade

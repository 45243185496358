import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createFilter, deleteFilter, editFilter, getFilters } from '../services/apis';
import { getItemFromLocalStorage } from '../helpers/commonHelper';
import { ACCESS_TOKEN_LS_KEY, EMAIL_LS_KEY } from '../constants/AppConstants';

export const useFetchFilters = (params) => {
  return useQuery(
    ["filters"],
    () => (
      getFilters(params)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(EMAIL_LS_KEY),
    },
  );
}

export const useCreateFilter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    createFilter,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('filters');
      },
    },
  );
};

export const useEditFilter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    editFilter,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('filters');
      },
    },
  );
};

export const useDeleteFilter = () => {
  const queryClient = useQueryClient();

  return useMutation(
    deleteFilter,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['filters']);
      },
    },
  );
};


import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useShallow } from "zustand/react/shallow"

import {
  NavbarFilterDropdownContainer,
  Header,
  ContentContainer,
  FiltersContainer,
  FilterContentContainer,
  ButtonsContainer,
  HeaderFilterContainer,
  ViewSelectionContainer,
  TabsContainer,
  SearchHeaderContainer,
  SearchBoxContainer,
  EmptyContainer
} from "./styles";

import Button from '../../common/Button';
import ViewSelection from '../ViewSelection';
import FilterSelection from '../FilterSelection';
import SearchBox from '../../common/SearchBox';
import Icon from '../../common/Icon';
import Tab from '../../common/Tab';
import Title from '../../common/Title';
import Loader from '../../common/Loader';
import EmptyText from '../../common/EmptyText';

import { REPORTS_TABS } from '../../../constants/ReportsConstants';
import { DROPDOWN_ENTITY } from '../helpers';
import useOnClickOutside from '../../../utils/useOnClickOutside';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../../stores/global';
import { CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, JOB_SITE_IDENTIFIERS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from '../../../constants/AppConstants';

const VIEW_TYPES = [
  { name: "All" },
  { name: "Saved views" },
]

const VIEW_TABS = [
  { name: "Analytics" },
  { name: "Reports" }
]

const NavbarFilterDropdown = ({
  metadata,
  searchResults = [],
  loadingStates,
  searchValue,
  onSearchTextChange,
  currentLevel,
  setCurrentLevel,
  onFilterChange,
  onClose,
  onSubmit
}) => {
  const { views } = useGlobalDataStore(useShallow((state) => ({
    views: state.views,
  })))

  const { currentProduct, globalFilters } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
    globalFilters: state.globalFilters,
  })))

  const [selectedViewType, setSelectedViewType] = useState("")
  const [selectedTab, setSelectedTab] = useState("")
  const [isSearching, toggleSearching] = useState(false)
  const [isInTransiton, toggleInTransisiton] = useState(false)

  const navbarRef = useRef(null)
  const searchBarRef = useRef(null)

  const filteredViews = useMemo(() => {
    if (!views?.length || !globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID]) return []
    const reports = REPORTS_TABS.map(item => item.title)
    const analyticsViews = views.filter(item => item.dashboardId === globalFilters[LOOKER_FILTER_KEYS.DASHBOARD_ID])
    const reportsViews = views.filter(item => reports.includes(item.dashboardId))
    const viewsToReturn = selectedTab === VIEW_TABS[0].name ? analyticsViews : reportsViews
    return viewsToReturn.map(item => ({ ...item, product: currentProduct }))
  }, [views, selectedTab, globalFilters, currentProduct])

  useEffect(() => {
    setSelectedViewType(view => !view ? (!!metadata?.length ? VIEW_TYPES[0].name : VIEW_TYPES[1].name) : view)
  }, [metadata])

  useEffect(() => {
    setSelectedTab(window.location.pathname?.includes("report") ? VIEW_TABS[1].name : VIEW_TABS[0].name)
  }, [])

  useEffect(() => {
    toggleSearching(searchValue?.length > 0)
    toggleInTransisiton(true)
    setTimeout(() => {
      toggleInTransisiton(false)
    }, 0)
  }, [searchValue])

  useOnClickOutside(navbarRef, () => {
    onClose()
  });

  const dropdownType = (() => {
    switch (currentProduct?.productKey) {
      case PROGRAMMATIC_IDENTIFIERS.productKey: return "medium"
      case CAREER_SITE_IDENTIFIERS.productKey: return "small"
      case CRM_IDENTIFIERS.productKey: return "small"
      case UNIFIED_VIEW_IDENTIFIERS.productKey: return "small"
      case JOB_SITE_IDENTIFIERS.productKey: return "small"
      default: return "large"
    }
  })()

  return (
    <NavbarFilterDropdownContainer ref={navbarRef} type={dropdownType}>
      <Header isSearching={isSearching}>
        {
          (!isSearching && !isInTransiton) && (
            <HeaderFilterContainer>
              {
                (!!metadata?.length ? VIEW_TYPES : VIEW_TYPES.slice(1)).map(type => (
                  <Button
                    key={type.name}
                    text={type.name}
                    type={selectedViewType === type.name ? "active" : ""}
                    className={`NavbarFilterDropdown__button ${selectedViewType === type.name && " NavbarFilterDropdown__button-active"}`}
                    onClick={() => {
                      setSelectedViewType(type.name)
                    }}
                  />
                ))
              }
            </HeaderFilterContainer>
          )
        }
        {
          isSearching && (
            <SearchHeaderContainer>
              <Icon
                name="back"
                className="Back__icon"
                onClick={() => onSearchTextChange("")}
              />
              <Title text="Search" />
            </SearchHeaderContainer>
          )
        }
        <SearchBoxContainer isSearching={isSearching} ref={searchBarRef}>
          <SearchBox
            placeholder='Search'
            clearIcon
            className="NavbarFilterDropdown__search"
            value={searchValue}
            onChange={onSearchTextChange}
          />
        </SearchBoxContainer>
      </Header>
      <ContentContainer>
        {
          (!!searchResults?.length && !!searchValue && !loadingStates?.get) && (
            <ViewSelection
              views={searchResults}
              loadingStates={loadingStates}
              onOptionChange={onFilterChange}
              searchValue={searchValue}
            />
          )
        }
        {
          (!searchResults?.length && !searchValue && selectedViewType === VIEW_TYPES[0].name) && (
            <FilterContentContainer>
              <FiltersContainer>
                {
                  metadata?.map((item, index) => (
                    <FilterSelection
                      key={item.title}
                      title={item.title}
                      count={item.count - 1}
                      options={item.options}
                      selected={item.selected}
                      currentLevel={currentLevel}
                      setCurrentLevel={setCurrentLevel}
                      expandable={index !== (metadata.length - 1)}
                      onSelect={item.onSelect}
                    />
                  ))
                }
              </FiltersContainer>
              <ButtonsContainer>
                <Button text="Cancel" onClick={onClose} />
                <Button text="Apply" type="submit" onClick={onSubmit} />
              </ButtonsContainer>
            </FilterContentContainer>
          )
        }
        {
          (!searchResults?.length && !searchValue && selectedViewType === VIEW_TYPES[1].name) && (
            <ViewSelectionContainer>
              {
                currentProduct?.productKey === PROGRAMMATIC_IDENTIFIERS.productKey && (
                  <TabsContainer>
                    <Tab
                      options={VIEW_TABS}
                      displayKey="name"
                      selected={selectedTab}
                      link={false}
                      onTabChange={(tab) => setSelectedTab(tab.name)}
                    />
                  </TabsContainer>
                )
              }
              <ViewSelection
                views={filteredViews}
                loadingStates={loadingStates}
                onOptionChange={onFilterChange}
              />
            </ViewSelectionContainer>
          )
        }
        {
          (!!loadingStates?.get && !!searchValue) && (
            <EmptyContainer>
              <Loader />
            </EmptyContainer>
          )
        }
        {
          (!!searchValue && !searchResults?.length && !loadingStates?.get) && (
            <EmptyContainer>
              <EmptyText text="No results found" />
            </EmptyContainer>
          )
        }
      </ContentContainer>
    </NavbarFilterDropdownContainer>
  );
}

export default NavbarFilterDropdown;

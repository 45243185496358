import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { compareAsc, parse } from 'date-fns';


import { EmptyContainer, TableContainer, Thead } from './styles'

import Th from "../Th";
import Td from "../Td";
import EmptyText from '../EmptyText';

import { DATE_SORT_TYPES } from '../../../constants/ReportsConstants';

const DataTable = ({
  data,
  columns: cols,
  searchText = "",
  page,
  pageSize = 10,
  pinnedColumns = [],
  className,
  headerOptionsEnabled = false,
  sortingEnabled = true,
  onPageCountChange,
  onPinnedColumnsChange,
  tableContainerStyle = {}
}) => {
  // const [columns, setColumns] = useState([])
  const [sorting, setSorting] = useState([])

  const columns = useMemo(() => {
    if (!!pinnedColumns?.length) {
      const newCols = cols.map((col) => ({ ...col, isPinned: pinnedColumns.includes(col.id) }))
      newCols.sort((a, b) => Number(b.isPinned) - Number(a.isPinned))
      return [...newCols];
    } else {
      return cols
    }
  }, [cols, pinnedColumns])

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(!!onPageCountChange && { getPaginationRowModel: getPaginationRowModel() }),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter: searchText,
      sorting,
      pagination: {
        pageSize: pageSize,
        pageIndex: page - 1
      },
    },
    onSortingChange: setSorting,
    sortingFns: {
      dateSortingFn: (rowA, rowB, columnId) => {
        const { key, sortType } = JSON.parse(columnId)
        if (!sortType) return 0
        try {
          switch (sortType) {
            case DATE_SORT_TYPES.DAY: {
              const valueA = parse(rowA.original[key], "MM/dd/yyyy", new Date());
              const valueB = parse(rowB.original[key], "MM/dd/yyyy", new Date());
              return compareAsc(valueA, valueB)
            }
            case DATE_SORT_TYPES.WEEK: {
              const valueA = parse(rowA.original[key].split(" - ")[0], "MM/dd/yyyy", new Date());
              const valueB = parse(rowB.original[key].split(" - ")[0], "MM/dd/yyyy", new Date());
              return compareAsc(valueA, valueB);
            }
            case DATE_SORT_TYPES.MONTH: {
              const valueA = parse(rowA.original[key], "MMM", new Date());
              const valueB = parse(rowB.original[key], "MMM", new Date());
              return compareAsc(valueA, valueB);
            }
            default: {
              return 0;
            }
          }
        } catch (error) {
          console.error('Error parsing date:', error);
          return 0;
        }
      }
    }
  });

  const handleOptionSelect = (id, opt) => {
    switch (opt.title) {
      case "Freeze":
        onPinColumn(id)
        break
      case "Unfreeze":
        onPinColumn(id, false)
        break
      case "Autosize column":
        table.resetColumnSizing()
        break
    }
  }

  const onPinColumn = (id, isPinned = true) => {
    const newCols = columns.map((col) => ({ ...col, isPinned: col.id === id ? isPinned : !!col.isPinned }))
    newCols.sort((a, b) => Number(b.isPinned) - Number(a.isPinned))
    // setColumns(newCols);
    if (onPinnedColumnsChange) {
      onPinnedColumnsChange(newCols.filter(item => item.isPinned).map(item => item.id))
    }
  };

  const getLeftStickyPos = (index, header, type) => {
    if (index === 0 || !index) return 0;
    if (type === "cell") {
      let left = 0
      const cells = header.getVisibleCells()
      for (let i = 0; i < index; i++) {

        const isPinned = columns[index].isPinned
        const size = cells[i].column.getSize()
        if (isPinned) {
          left += (size - (size * 0.11))
        } else {
          left += size
        }
      }
      const ths = [...document.querySelectorAll("th")]
      let left2 = ths.slice(0, index).reduce((acc, item) => (acc += item.clientWidth), 0)
      return left2
    } else if (type === "header") {
      let left = 0
      const cells = header.headers
      // if (index === 3 || index === 2) {
      //   console.log({ cells, columns, isPinned: columns[index].isPinned, size: cells.find(item => item.index === index).column.getSize() })
      // }
      for (let i = 0; i < index; i++) {
        const isPinned = columns[index].isPinned
        const size = cells.find(item => item.index === index).column.getSize()
        // if (index === 4) {
        //   console.log({ column: cells[i].column, isPinned, size, columns, index, i, cells })
        // }
        left += size
        // if (isPinned) {
        //   left += (size - (size * 0.11))
        // } else {
        //   left += size
        // }
      }
      const ths = [...document.querySelectorAll("th")]
      let left2 = ths.slice(0, index).reduce((acc, item) => (acc += item.clientWidth), 0)
      if (index < 4) {
      }
      return left2
    }
    return 0;
  };

  useEffect(() => {
    if (!!page) {
      table.setPageIndex(page - 1)
    }
  }, [page])


  useEffect(() => {
    const pageCount = table.getPageCount()
    if (onPageCountChange) {
      onPageCountChange(pageCount)
    }
  }, [table.getPageCount()])

  useEffect(() => {
    const ths = [...document.querySelectorAll("th")]
    ths.forEach((item, index) => {
      if (index === 0) {
        item.style.left = "0px"
      } else {
        let left = 0
        for (let i = 0; i < index; i++) {
          left += ths[i].clientWidth
        }
        item.style.left = `${left}px`
      }
    })
    const trs = [...document.querySelectorAll("tr")]
    trs.forEach((tr, index) => {
      const tds = [...tr.querySelectorAll("td")]
      tds.forEach((item, index) => {
        if (index === 0) {
          item.style.left = "0px"
        } else {
          let left = 0
          for (let i = 0; i < index; i++) {
            left += tds[i].clientWidth
          }
          item.style.left = `${left}px`
        }
      })
    })
  }, [columns, sorting])

  return (
    <TableContainer className={className || ""} style={tableContainerStyle}>
      <table
        {...{
          style: {
            width: table.getCenterTotalSize(),
            border: "none",
          },
        }}
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index, headers) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      border: "1px solid #EDF2F7",
                      background: columns[index].backgroundColor,
                      // left: getLeftStickyPos(index, headerGroup, "header"),
                      position: columns.find(col => col.id === header.id).isPinned ? "sticky" : "static",
                      zIndex: columns.find(col => col.id === header.id).isPinned ? "4" : "initial",
                    },
                  }}
                >
                  <Th
                    header={header}
                    isLastHeaderColumn={index === headers.length - 1}
                    render={flexRender}
                    optionsEnabled={headerOptionsEnabled}
                    sortingEnabled={sortingEnabled}
                    onOptionSelect={(opt) => handleOptionSelect(header.id, opt)}
                    isPinned={columns.find(col => col.id === header.id).isPinned}
                  />
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody>
          {
            !table.getRowModel().rows.length && (
              <tr>
                <td colSpan={columns.length}>
                  <EmptyContainer>
                    <EmptyText />
                  </EmptyContainer>
                </td>
              </tr>
            )
          }
          {
            !!table.getRowModel().rows.length && (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, index, self) => (
                    <Td
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                          // left: getLeftStickyPos(columns.findIndex(col => col.id === cell.column.id), row, "cell"),
                          position: columns.find(col => col.id === cell.column.id)?.isPinned ? "sticky" : "static"
                        },
                        isPinned: columns.find(col => col.id === cell.column.id)?.isPinned,
                        isLast: index === (self.length - 1)
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </tr>
              ))
            )
          }
        </tbody>
      </table>
    </TableContainer>
  );
}

export default DataTable;

import styled from "styled-components";
import { devices } from "../../../../constants/AppConstants";
import { DashboardName, Text } from "./Option/styles";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${devices.mobile} {
    display: none;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  min-height: 1rem;
`
export const Title = styled.p`
  color: #718096;
  font-size: 0.8rem;
  font-weight: 500;
`
export const Line = styled.div`
  height: 1px;
  flex:1;
  border-top: 1px solid #E2E8F0;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Text} {
    display: ${props => props.isExpanded ? "block" : "none"}
  }
`

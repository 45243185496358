import React, { useState, useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';
import { useSearchParams } from "react-router-dom";

import {
  UnifiedViewContainer,
  ContentContainer,
  EmptyContainer,
  HeaderContainer,
} from "./styles";

import Subheader from "../../components/common/Subheader";
import Loader from "../../components/common/Loader";
import EmbeddedDashboard from "../../components/EmbeddedDashboard";
import Upgrade from "../Upgrade";

import { getFiltersForDashboard } from "./helpers"
import { useGlobalDataStore, useGlobalFiltersStore } from '../../stores/global';
import { DATE_OPTIONS, LOOKER_FILTER_KEYS, UNIFIED_VIEW_IDENTIFIERS } from "../../constants/AppConstants";
import { formatDate } from "../../utils/dateHelpers";
import Mixpanel from "../../services/MixPanel";
import { getSelectedEntityName, searchHierarchyAcrossProducts } from "../../utils/searchHelpers";
import { onTrackMixPanelEvent } from "../../helpers/eventHandler";


const UnifiedView = ({
  localWidgetFilters,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const { views, products, hierarchy } = useGlobalDataStore(
    useShallow((state) => ({
      views: state.views,
      products: state.products,
      hierarchy: state.hierarchy,
    }))
  );

  const {
    globalFilters,
    embedUrl,
  } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      embedUrl: state.embedUrl,
    }))
  );

  const [isDbLoading, toggleDbLoading] = useState(false)

  const combinedFilters = useMemo(() => {
    const combined = {
      ...getFiltersForDashboard(localWidgetFilters),
    }
    return combined
  }, [localWidgetFilters])

  useEffect(() => {
    Mixpanel.trackEvent("Career Site Analytics Opened", {});
  }, []);

  const isLoading = !products?.length || !hierarchy || !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID];

  const isLocked = !products?.length ||
    !hierarchy ||
    !globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
    !products.find(item => item.productKey === UNIFIED_VIEW_IDENTIFIERS.productKey) ||
    !hierarchy?.[UNIFIED_VIEW_IDENTIFIERS.productKey]?.find(item => item?.id === globalFilters[LOOKER_FILTER_KEYS.ACCOUNT_ID]);

  if (isLocked && !isLoading) {
    const results = searchHierarchyAcrossProducts(hierarchy, globalFilters)
    const entity = results?.name?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || results?.name?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
    return (
      <Upgrade
        entity={entity}
        {...UNIFIED_VIEW_IDENTIFIERS.unlockDetails}
      />
    )
  }

  return (
    <UnifiedViewContainer>
      <HeaderContainer>
        <Subheader
          views={views}
          entity={getSelectedEntityName(hierarchy, globalFilters, UNIFIED_VIEW_IDENTIFIERS).name}
          entityType={getSelectedEntityName(hierarchy, globalFilters, UNIFIED_VIEW_IDENTIFIERS).type}
          showWelcomeText
          filtersDisabled={isDbLoading}
        />
      </HeaderContainer>
      <ContentContainer>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "1rem", overflow: "auto" }}>
          <div style={{ display: "flex", gap: "1rem", height: "50vh" }}>
            <ClickApplyHireWidget
              data={CLICK_APPLY_HIRE_DATA}
              config={CLICK_APPLY_HIRE_CONFIG}
            />
          </div>
        </div> */}
        {
          (!!embedUrl && !isLoading && !isLocked) && (
            <EmbeddedDashboard
              embedURL={embedUrl}
              lookerFilter={globalFilters}
              localWidgetFilters={combinedFilters}
              onDbLoad={toggleDbLoading}
            />
          )
        }
        {
          isLoading && (
            <EmptyContainer>
              <Loader size="medium" />
            </EmptyContainer>
          )
        }
      </ContentContainer>
    </UnifiedViewContainer>
  );
};

export default UnifiedView;

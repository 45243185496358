import React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import {
  addDays,
  addWeeks,
  endOfWeek,
  format,
  getWeek,
  parse,
  startOfWeek
} from "date-fns";
import WeeklyAxisLabel from "../components/common/WeeklyAxisLabel";

export const formatDate = (date, type) => {
  const month =
    date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
  return `${date.getFullYear()}-${month}-${day} ${type === "start" ? "00:00:00" : "23:59:59"
    }`;
};

export const parseDateToCurrentTimezone = (
  dateString,
  dateFormat = "yyyy-MM-dd"
) => {
  const date = parse(dateString, dateFormat, new Date());
  return date;
};

export const formatDateForWidget = (timestamp) => {
  const date = parseDateToCurrentTimezone(timestamp, "T");
  const day = date.getUTCDate();
  const month = date.toLocaleString("default", {
    month: "short",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();
  return `${day} ${month}, ${year}`;
};

export const formatDateWithTimeFilter = ({
  value,
  timeFilter,
  dateFormat = "T",
  returnType = "string",
  weekStartDay = 1
}) => {
  if (!value) return ""
  let formatted = ""

  const date = parseDateToCurrentTimezone(value, dateFormat);
  const day = date.getUTCDate();
  const month = date.toLocaleString("default", {
    month: "short",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();
  formatted = `${day} ${month}, ${year}`;
  if (!timeFilter) {
    return formatted
  }
  switch (timeFilter.display) {
    case "Daily":
      return formatted
    case "Weekly": {
      const weekNo = `Week ${getWeek(date)}`
      const weekRange = `${format(startOfWeek(date, { weekStartsOn: weekStartDay }), "d MMM")} - ${format(endOfWeek(date, { weekStartsOn: weekStartDay }), "d MMM")}`
      if (returnType === "string") return `${weekNo} (${weekRange})`
      return renderToStaticMarkup(
        <WeeklyAxisLabel weekNo={weekNo} range={weekRange} />
      );
    }
    case "Monthly":
      return date.toLocaleString("default", {
        month: "short",
        timeZone: "UTC",
      })
  }
}

import styled from "styled-components";

export const ButtonContainer = styled.button`
  border-radius: 0.3rem;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 0.7rem 1.9rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  color: ${(props) => (props.type === "submit" ? "#FFFFFF" : "#344054")};
  background-color: ${(props) =>
    props.type === "submit" ? "#283593" : props.type === "active" ? "#E2E6F1" : "#FFFFFF"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => props.disabled ? 0.5 : 1};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: box-shadow 0.3s;
    transform: scale(${(props) => (props.disabled ? "1" : "1.02")});
  }
`;
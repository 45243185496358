import { LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from "../../constants/AppConstants"
import { getModel } from "../../utils/helpers"
import { JOB_INSIGHTS_JOB_NAME_KEY, JOB_INSIGHTS_JOB_SCORE_KEY, JOB_SCORE_WIDGET_CONFIG } from "./constants"

export const parseJobScore = (data) => {
  const jobScoreData = data.map(item => ({
    [JOB_SCORE_WIDGET_CONFIG[0].targetKey]: { [JOB_SCORE_WIDGET_CONFIG[0].valueKey]: `${parseInt(item[JOB_SCORE_WIDGET_CONFIG[0].targetKey] * 100)}%` },
    [JOB_SCORE_WIDGET_CONFIG[1].targetKey]: { [JOB_SCORE_WIDGET_CONFIG[1].valueKey]: `${parseInt(item[JOB_SCORE_WIDGET_CONFIG[1].targetKey] * 100)}%` },
    [JOB_SCORE_WIDGET_CONFIG[2].targetKey]: { [JOB_SCORE_WIDGET_CONFIG[2].valueKey]: item[JOB_SCORE_WIDGET_CONFIG[2].targetKey] }
  }))

  return jobScoreData
}

export const parseJobInsights = (data) => {
  const jobInsights = data.map(item => ({
    parameter: item[JOB_INSIGHTS_JOB_NAME_KEY], score: item[JOB_INSIGHTS_JOB_SCORE_KEY]
  }))

  return jobInsights
}

export const buildPayload = (filters, config, models) => {
  if (!Object.entries(filters || {})?.length || !config || !models) return null

  const filterMap = config.FILTER_MAP

  const payload = {
    "model": getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey),
    "view": config.VIEW,
    "pivots": null,
    "filters": {
      [filterMap.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
      [filterMap.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
      [filterMap.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      [filterMap.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
      [filterMap.CAMPAIGN_ID]: filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "",
      [filterMap.JOB_GROUP_ID]: filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "",
    },
    "fill_fields": null,
    "sorts": [],
    "column_limit": "100",
    "total": false,
    "row_total": null,
    "subtotals": null,
    "fields": config.FIELDS,
    "cache": true
  }

  return payload
}

export const parseFilters = (filters, fields = []) => {
  if (!Object.keys(filters || {})?.length) return null

  let accountId, subAccountId, clientId, campaignId, jobGroupId

  accountId = filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "";
  subAccountId = filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "";
  clientId = filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "";
  campaignId = filters?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] || "";
  jobGroupId = filters?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] || "";

  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
    ...fields
  }

  return payload
};

import styled from 'styled-components'

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: ${props => props.transparent ? "transparent" : "rgba(0,0,0,0.4)"};
  width: 100vw;
  overflow: hidden;
`
export const ModalDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.position === "right" ? "flex-end" : props.position === "left" ? "flex-start" : "center"};
`
export const ModalContent = styled.div`
  z-index: 1001;
`
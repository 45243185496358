import { DASHBOARD_MAP } from "../constants/AppConstants"

export const onLocalFilterChange = (payload) => {
  const eventData = {
    type: 'localFilter',
    payload
  }
  console.log('localFilter', eventData)
  window.parent.parent.postMessage(eventData, '*')
}

export const onGlobalFilterChange = (payload) => {
  const eventData = {
    type: 'filter',
    payload
  }
  console.log('global filter', eventData)
  window.parent.parent.postMessage(eventData, '*')
}

export const onViewMore = (key, uiConfig) => {
  const eventData = {
    type: "viewMore",
    widget: { ...DASHBOARD_MAP[key], uiConfig },
  };
  console.log("Opening view more for ", key);
  window.parent.parent.postMessage(eventData, "*");
}

export const onFilterChangeInViewMore = (type, payload) => {
  const eventData = {
    type,
    payload
  }
  console.log("Changing filters ", payload);
  window.parent.parent.postMessage(eventData, "*");
}

export const onPinnedColumnsChangeInReports = (columns) => {
  console.log("Setting pinned columns", columns)
  const eventData = {
    type: "pinnedColumnChange",
    payload: columns
  }
  window.parent.parent.postMessage(eventData, "*");
}

export const onClickJobCompareLink = (payload) => {
  const eventData = {
    type: "jobCompareLink",
    payload
  }
  window.parent.parent.postMessage(eventData, "*");
}

export const onViewMoreLink = (key, uiConfig) => {
  const eventData = {
    type: "viewMoreLink",
    widget: { ...DASHBOARD_MAP[key], uiConfig },
  };
  console.log("Opening view more link for ", key);
  window.parent.parent.postMessage(eventData, "*");
};

export const onViewMoreJobQuality = () => {
  const eventData = {
    type: "viewMoreJQ",
  };
  console.log("Opening view more link for Job quality");
  window.parent.parent.postMessage(eventData, "*");
};

export function onTriggerLocalFilter({ field, value }) {
  console.log("Filter update triggered", field, value)
  this.trigger("filter", [{
    field,
    value,
    run: true,
  }]);
}

export const onTrackMixPanelEvent = (eventName, eventProps) => {
  console.log('eventName, eventProps', eventName, eventProps)
  const eventData = {
    type: "trackMixPanelEvent",
    eventName,
    eventProps
  };
  window.parent.parent.postMessage(eventData, "*");
};
import { LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS } from "../../../constants/AppConstants";
import { getModel } from "../../../utils/helpers";

export const buildPayload = (filters, row, config, models) => {
  if (!Object.entries(filters || {})?.length || !config) return null

  const filterMap = config.FILTER_MAP

  const payload = {
    "model": getModel(models, PROGRAMMATIC_IDENTIFIERS.modelKey),
    "view": config.VIEW,
    "pivots": null,
    "filters": {
      [filterMap.ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "",
      [filterMap.SUB_ACCOUNT_ID]: filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "",
      [filterMap.AGENCY_ID]: filters?.[LOOKER_FILTER_KEYS.AGENCY_ID] || "",
      [filterMap.CLIENT_ID]: filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "",
      ...config.getAdditionalFilters(row)

    },
    "fill_fields": null,
    "limit": "-1",
    "sorts": config.SORTS,
    "column_limit": "100",
    "total": false,
    "row_total": null,
    "subtotals": null,
    "fields": config.FIELDS,
    "cache": true
  }
  return payload
}

export const parseFilters = (filters, fields) => {
  if (!Object.keys(filters || {})?.length) return null
  if (!filters.isExpanded) return null

  let accountId, subAccountId, clientId

  accountId = filters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] || "";
  subAccountId = filters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] || "";
  clientId = filters?.[LOOKER_FILTER_KEYS.CLIENT_ID] || "";

  const payload = {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
    ...fields,
  }
  return payload
};

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getFeedback, getInsights, postFeedback } from "../services/apis";
import { getItemFromLocalStorage } from "../helpers/commonHelper";
import { ACCESS_TOKEN_LS_KEY } from "../constants/AppConstants";


export const useFetchInsights = (params) => {
   return useQuery(
     ["insights", params],
     () => getInsights(params),
     {
       staleTime: Infinity,
       retry: 1,
       enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
     },
   );
 }

export const usePostFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation((payload) => postFeedback(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(['insights']);
    }
  });
}
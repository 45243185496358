import styled from "styled-components"

export const FilterChangeModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 40vw;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
`
export const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  color: #161E2E;
`
export const Body = styled.p`
  font-size: 0.9rem;
  color: #374151;
  font-weight: 400;
  line-height: 1.2rem;
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.7rem;
`
export const Button = styled.button`
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  border: ${props => props.type === "submit" ? "none" : "1px solid #A0AEC0"};
  background-color: ${props => props.type === "submit" ? "#F34721" : "#FFFFFF"};
  color: ${props => props.type === "submit" ? "#FFFFFF" : "#374151"};
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from "zustand/react/shallow";

import {
  ScheduleReportFormContainer,
  Header,
  Note,
  Body,
  Footer,
  Inputs,
  Label,
  InputContainer,
  TextBox,
  Selections,
  FrequencyContainer,
  FiltersContainer
} from "./styles"

import Icon from '../../../common/Icon';
import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import TextInput from '../../../common/TextInput';
import Dropdown from '../../../common/Dropdown';
import ErrorMessage from '../../../common/ErrorMessage';
import FilterLabel from '../FilterLabel';

import {
  EMAIL_LS_KEY,
  LOOKER_FILTER_KEYS,
  LOOKER_USER_ID_KEY,
  STRING_SEPARATOR,
  TIMEZONE_LS_KEY
} from '../../../../constants/AppConstants';
import {
  DAYS,
  FREQUENCY_OPTIONS,
  TIMINGS,
  DATES,
  DESTINATION_OPTIONS,
  DB_FORMAT_OPTIONS,
  REPORT_FORMAT_OPTIONS,
  getCronExpressionFromState,
  getStateFromCronExpression,
  MAIN_DB_NOTE_TEXT,
  REPORTS_NOTE_TEXT,
  FILTERS_TO_HIDE
} from './helpers';
import { generateScheduledEmailMessage, getFiltersFromQueryString, parseFilterExpressions } from '../../../../utils/helpers';
import { getItemFromLocalStorage } from '../../../../helpers/commonHelper';
import { searchHierarchyData } from '../../../../utils/searchHelpers';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../../../stores/global';
import { MULTISELECT_FILTERS } from '../../../AdvancedFilters';

const ScheduleReportForm = ({
  schedule,
  type = "dashboard",
  selection,
  checkUniqueness,
  advancedFilters,
  advancedFilterOptions,
  showAdvancedFilters = false,
  isLoading,
  onClose,
  onSubmit
}) => {
  let [searchParams] = useSearchParams()

  const { hierarchy, dashboards, products } = useGlobalDataStore(useShallow((state) => ({
    hierarchy: state.hierarchy,
    dashboards: state.dashboards,
    products: state.products,
  })))

  const { currentProduct } = useGlobalFiltersStore(useShallow((state) => ({
    currentProduct: state.currentProduct,
  })))

  const [formState, setFormState] = useState({
    name: "",
    selectionText: "",
    frequency: {
      day: { name: DAYS[0], id: DAYS[0] },
      date: { name: DATES[0], id: DATES[0] },
      time: { name: TIMINGS[0], id: TIMINGS[0] },
      recurrence: { name: FREQUENCY_OPTIONS[0], id: FREQUENCY_OPTIONS[0] }
    },
    destination: DESTINATION_OPTIONS[0],
    format: type === "dashboard" ? DB_FORMAT_OPTIONS[0] : REPORT_FORMAT_OPTIONS[0]
  })
  const [isNameNotUnique, toggleNameUnique] = useState(false)

  const advancedFilterSelection = useMemo(() => {
    if (!advancedFilters?.length) return <TextBox>None</TextBox>
    let appliedFiltersStr = ""
    const entry = advancedFilters[0]
    const isMultiSelect = MULTISELECT_FILTERS.find(i => i.name === entry.key)
    if (!!isMultiSelect) {
      const filterStrings = entry.value.split(STRING_SEPARATOR)
      const multiselects = MULTISELECT_FILTERS.
        flatMap(item => item.options)
        .filter(item => filterStrings.includes(item.value))
      appliedFiltersStr = multiselects.map(item => item.name).join(", ")
    } else {
      appliedFiltersStr = entry.value.split(STRING_SEPARATOR).join(", ")
    }

    return (
      <FiltersContainer>
        {
          advancedFilters?.slice(0, 1)?.map((item) => (
            <FilterLabel
              key={item.key}
              label={`${item.key} ${item.matcher}`}
              text={appliedFiltersStr}
            />
          ))
        }
        {
          advancedFilters?.length > 1 && (
            <span>+ {advancedFilters?.length - 1} filters</span>
          )
        }
      </FiltersContainer>
    )
  }, [advancedFilters])

  const handleSubmit = () => {
    const params = Object.fromEntries([...searchParams])
    const email = getItemFromLocalStorage(EMAIL_LS_KEY)
    const lookerUserId = getItemFromLocalStorage(LOOKER_USER_ID_KEY)
    const otherFilters = {
      ...(!!params[LOOKER_FILTER_KEYS.CURRENCY_RATE] && { [LOOKER_FILTER_KEYS.CURRENCY_RATE]: params[LOOKER_FILTER_KEYS.CURRENCY_RATE] }),
      ...(!!params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] && { [LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY]: params[LOOKER_FILTER_KEYS.EXCHANGE_VISIBILITY] }),
    }
    const advancedFiltersMap = advancedFilters?.reduce((acc, item) => ({
      ...acc,
      ...parseFilterExpressions(item, advancedFilterOptions)
    }), {})
    const filtersToHideStr = FILTERS_TO_HIDE.reduce((acc, item) => `${acc}&hide_filter=${item}`, "")
    const finalFilters = {
      ...selection?.id,
      ...advancedFiltersMap,
      ...otherFilters
    }
    const filters_string = !!schedule ? schedule.filters_string : `?${new URLSearchParams(finalFilters).toString()}${filtersToHideStr}`
    const timezone = getItemFromLocalStorage(TIMEZONE_LS_KEY)
    const username = getItemFromLocalStorage("username")

    const payload = {
      name: formState.name,
      timezone,
      filters_string,
      scheduled_plan_destination: [
        {
          type: formState.destination.value,
          address: email,
          format: formState.format.value,
          looker_recipient: true,
          message: generateScheduledEmailMessage({ name: username, title: formState.name }),
          apply_vis: true,
          apply_formatting: true
        }
      ],
      ...(!schedule && { user_id: lookerUserId }),
      once: formState.frequency.recurrence.name === FREQUENCY_OPTIONS[3],
      ...(formState.frequency.recurrence.name !== FREQUENCY_OPTIONS[3] && { crontab: getCronExpressionFromState(formState.frequency) })
    }
    onSubmit(payload)
  }

  const defaultSelectionText = (() => {
    if (!currentProduct) return "N/A"
    return "All accounts"
  })()

  useEffect(() => {
    setFormState(fs => ({
      ...fs,
      format: type === "dashboard" ? DB_FORMAT_OPTIONS[0] : REPORT_FORMAT_OPTIONS[0]
    }))
  }, [type])

  useEffect(() => {
    if (!!schedule) {
      const filters = getFiltersFromQueryString(schedule.filters_string)
      const dashboardKey = Object.entries(dashboards || {}).find(([key, value]) => value.id === schedule.lookml_dashboard_id)?.[0]
      const product = products.find(item => item.dashboardMapIdentifierKey === dashboardKey)
      const selection = searchHierarchyData(hierarchy, filters, product);
      setFormState({
        name: schedule.name,
        selectionText: Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || defaultSelectionText,
        frequency: {
          ...getStateFromCronExpression(schedule.crontab)
        },
        destination: DESTINATION_OPTIONS.find(item => item.value === schedule.scheduled_plan_destination[0].type),
        format: [...DB_FORMAT_OPTIONS, ...REPORT_FORMAT_OPTIONS].find(item => item.value === schedule.scheduled_plan_destination[0].format)
      })
    } else if (!!selection) {
      setFormState(fs => ({
        ...fs,
        selectionText: Object.values(selection?.name || {})?.filter(Boolean)?.join("\xa0\xa0\xa0\xa0>\xa0\xa0\xa0\xa0") || defaultSelectionText
      }))
    } else if (!selection) {
      setFormState(fs => ({
        ...fs,
        selectionText: defaultSelectionText
      }))
    }
  }, [schedule, selection, currentProduct, hierarchy, dashboards, products])

  useEffect(() => {
    if (!!formState.name && (schedule?.name !== formState.name)) {
      if (checkUniqueness(formState.name)) {
        toggleNameUnique(true)
      } else {
        toggleNameUnique(false)
      }
    } else {
      toggleNameUnique(false)
    }
  }, [formState.name, checkUniqueness, schedule])

  const FORMAT_OPTIONS = type === "dashboard" ? DB_FORMAT_OPTIONS : REPORT_FORMAT_OPTIONS
  const disabled = !formState.name || isNameNotUnique

  return (
    <ScheduleReportFormContainer>
      <Header>
        <span>Schedule Report</span>
        <Icon name="close" width="14" height="14" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>
      <Note>
        {type === "dashboard" ? MAIN_DB_NOTE_TEXT : REPORTS_NOTE_TEXT}
      </Note>
      <Body>
        <Inputs>
          <InputContainer>
            <Label>Schedule name <span style={{ color: "red" }}>&nbsp;*</span></Label>
            <TextInput
              value={formState.name}
              onChange={(e) => setFormState(fs => ({ ...fs, name: e.target.value }))}
              autoFocus
            />
            {
              isNameNotUnique && (
                <ErrorMessage text="Name already exists" />
              )
            }
          </InputContainer>
          <InputContainer>
            <Label>Selection <span style={{ color: "red" }}>&nbsp;*</span></Label>
            <TextBox title={formState.selectionText}>
              {formState.selectionText}
            </TextBox>
          </InputContainer>
          {
            showAdvancedFilters && (
              <InputContainer>
                <Label>Applied filters</Label>
                {advancedFilterSelection}
              </InputContainer>
            )
          }
        </Inputs>
        <Selections>
          <FrequencyContainer>
            <InputContainer>
              <Label>Frequency</Label>
              <Dropdown
                options={FREQUENCY_OPTIONS.map(item => ({ name: item, id: item }))}
                selected={formState.frequency.recurrence}
                valueKey="name"
                className="DownloadSchedule__dropdown"
                onChange={(opt) => {
                  setFormState(form => ({
                    ...form,
                    frequency: {
                      ...form.frequency,
                      recurrence: opt
                    }
                  }))
                }}
                customOptionsContainerStyle={{ maxHeight: "15vh", width: "100%" }}
              />
            </InputContainer>
            {
              (formState.frequency.recurrence.name === FREQUENCY_OPTIONS[2]) && (
                <InputContainer>
                  <Label>Date</Label>
                  <Dropdown
                    options={DATES.map(item => ({ name: item, id: item }))}
                    selected={formState.frequency.date}
                    valueKey="name"
                    className="DownloadSchedule__dropdown"
                    onChange={(opt) => {
                      setFormState(form => ({
                        ...form,
                        frequency: {
                          ...form.frequency,
                          date: opt
                        }
                      }))
                    }}
                    customOptionsContainerStyle={{ maxHeight: "15vh", width: "100%" }}
                  />
                </InputContainer>
              )
            }
            {
              (formState.frequency.recurrence.name === FREQUENCY_OPTIONS[1]) && (
                <InputContainer>
                  <Label>Day</Label>
                  <Dropdown
                    options={DAYS.map(item => ({ name: item, id: JSON.stringify(item) }))}
                    selected={formState.frequency.day}
                    valueKey="name"
                    className="DownloadSchedule__dropdown"
                    onChange={(opt) => {
                      setFormState(form => ({
                        ...form,
                        frequency: {
                          ...form.frequency,
                          day: opt
                        }
                      }))
                    }}
                    customOptionsContainerStyle={{ maxHeight: "15vh", width: "100%" }}
                  />
                </InputContainer>
              )
            }
            {
              formState.frequency.recurrence.name !== FREQUENCY_OPTIONS[3] && (
                <InputContainer>
                  <Label>Time</Label>
                  <Dropdown
                    options={TIMINGS.map(item => ({ name: item, id: item }))}
                    selected={formState.frequency.time}
                    valueKey="name"
                    className="DownloadSchedule__dropdown"
                    onChange={(opt) => {
                      setFormState(form => ({
                        ...form,
                        frequency: {
                          ...form.frequency,
                          time: opt
                        }
                      }))
                    }}
                    customOptionsContainerStyle={{ maxHeight: "15vh", width: "100%" }}
                  />
                </InputContainer>
              )
            }
          </FrequencyContainer>
          {/* <InputContainer>
            <Label>Destination</Label>
            <Dropdown
              options={DESTINATION_OPTIONS.map(item => ({ ...item, id: JSON.stringify(item) }))}
              selected={formState.destination}
              valueKey="name"
              className="DownloadSchedule__dropdown"
              onChange={(opt) => {
                setFormState(form => ({
                  ...form,
                  destination: opt
                }))
              }}
              customOptionsContainerStyle={{ width: "100%" }}
              dropdownButtonStyle={{ width: "100%", justifyContent: "space-between" }}
            />
          </InputContainer>
          <InputContainer>
            <Label>Format</Label>
            <Dropdown
              options={FORMAT_OPTIONS.map(item => ({ ...item, id: JSON.stringify(item) }))}
              selected={formState.format}
              valueKey="name"
              className="DownloadSchedule__dropdown"
              onChange={(opt) => {
                setFormState(form => ({
                  ...form,
                  format: opt
                }))
              }}
              customOptionsContainerStyle={{ maxHeight: "8vh", width: "100%" }}
              dropdownButtonStyle={{ width: "100%", justifyContent: "space-between" }}
            />
          </InputContainer> */}
        </Selections>
      </Body>
      <Footer>
        <Button
          text="Cancel"
          onClick={onClose}
        />
        <Tooltip text={disabled ? `Enter ${isNameNotUnique ? "unique" : ""} schedule name` : ""}>
          <Button
            text="Schedule"
            icon={isLoading && "loading"}
            className="loading-svg"
            type="submit"
            disabled={disabled || isLoading}
            onClick={handleSubmit}
          />
        </Tooltip>
      </Footer>
    </ScheduleReportFormContainer>
  );
}

export default ScheduleReportForm;

import React from "react";

import { Table as TableStyles } from "./styles";

import EmptyText from "../EmptyText";

const Table = ({
  data,
  columns,
  fillEmptyRows = false,
  maxRows = 10,
  className
}) => (
  <TableStyles className={className}>
    <table>
      <thead>
        <tr>
          {columns.map((column, i) => {
            const key = !!column ? i : column;
            return typeof column === "string" && !!column ? (
              <th key={key}>
                <span className="Table__title">{column}</span>
              </th>
            ) : (
              <th key={key}>{column}</th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {!!data?.length &&
          data.map(({ id, elements }) => (
            <tr key={id}>
              {elements.map(({ id: uuid, element }) => (
                <td key={uuid}>
                  {typeof element === "string" ||
                    typeof element === "number" ? (
                    <span className="Table__text">{element}</span>
                  ) : (
                    element
                  )}
                </td>
              ))}
            </tr>
          ))}
        {!!data?.length &&
          data.length < maxRows &&
          fillEmptyRows &&
          Array.from({ length: maxRows - data.length }).map((_, index) => (
            <tr key={maxRows + index}>
              {columns.map((_, index) => (
                <td key={index + maxRows * 2}>&nbsp;</td>
              ))}
            </tr>
          ))}
        {!data?.length && (
          <tr style={{ height: "40vh" }}>
            <td colSpan={columns?.length} style={{ textAlign: "center" }}>
              <EmptyText />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </TableStyles>
);

export default Table;

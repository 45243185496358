import React, { forwardRef } from 'react';

import {
  Field,
  IconContainer,
  TextInputContainer
} from "./styles"

import Icon from '../Icon';

const TextInput = forwardRef(({
  error, icon, disabled, ...props
}, ref) => (
  <TextInputContainer>
    {
      !!icon && (
        <IconContainer>
          <Icon name={icon} alt="icon" />
        </IconContainer>
      )
    }
    <Field
      type="text"
      autoComplete="off"
      ref={ref}
      disabled={disabled}
      {...props}
    />
  </TextInputContainer>
))

export default TextInput

import styled from 'styled-components';

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
    }
`;
export const Text = styled.span`
    color: #374151;
    font-weight: 400;
    font-size: 0.8rem;
`;
export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    padding: 0;
    background-color: #FFFFFF;
    border: 1px solid #EDF2F7;
`;
import styled from "styled-components";

export const SwitchContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.6rem;
`
export const Base = styled.input`
  appearance: none;
  height: 1.1rem;
  width: 2.2rem;
  background-color: #E8EAF6;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  
  &::before {
    content: "";
    position: absolute;
    left: 0;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    background-color: #3949AB;
    transform: scale(1.2);
    transition: 0.4s ease all;
  }
  &:checked::before {
    left: 60%;
  }
`
export const Text = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: #374151;
`

import React, { useEffect, useState } from 'react';

import {
  Header,
  MultiselectContainer,
  Section,
  SectionTitle,
  SectionBody,
  SectionContainer,
  EmptyContainer
} from './styles'

import Checkbox from '../Checkbox';
import Loader from '../Loader';
import Icon from '../Icon';

const MultiselectList = ({
  type = "dimension",
  title,
  options,
  value,
  idKey = "id",
  valueKey = "name",
  onChange
}) => {
  const [openSections, setOpenSections] = useState([])

  const handleSelect = (option, title) => {
    const isSelected = value.find(item => item.title === title)
    if (!!isSelected) {
      const isOptSelected = isSelected.options.find(item => JSON.stringify(item) === JSON.stringify(option))
      if (!!isOptSelected) {
        const toUpdate = (() => {
          if (isSelected.options.length === 1) {
            return value.filter(item => item.title !== title)
          }
          return [...value.filter(item => item.title !== title), { title, options: [...isSelected.options.filter(item => JSON.stringify(item) !== JSON.stringify(option))] }]

        })()
        onChange(toUpdate)
      } else {
        const toUpdate = [...value.filter(item => item.title !== title), { title, options: [...isSelected.options, option] }]
        onChange(toUpdate)
      }
    } else {
      const toUpdate = [...value, { title, options: [option] }]
      onChange(toUpdate)
    }
  }

  useEffect(() => {
    if (value?.length) {
      setOpenSections(value.map(item => item.title))
    }
  }, [value])

  return (
    <MultiselectContainer>
      <Header type={type}>
        <span>{title}</span>
        {/* <span>{max}</span> */}
      </Header>
      <SectionContainer>
        {
          !!options?.length && options.map(option => (
            <Section key={option.title}>
              <SectionTitle onClick={() => {
                if (!openSections.includes(option.title)) {
                  setOpenSections(os => [...os, option.title])
                } else {
                  setOpenSections(os => os.filter(i => i !== option.title))
                }
              }}>
                <Icon name="down" className={`Arrow ${!openSections.includes(option.title) ? " Arrow__rotate" : ""}`} />
                {option.title}
              </SectionTitle>
              {
                openSections.includes(option.title) && (
                  <SectionBody>
                    {
                      !!option.options.length && option.options.map(item => (
                        <Checkbox
                          key={JSON.stringify(item)}
                          id={JSON.stringify(item)}
                          label={item[valueKey]}
                          checked={!!value.find(s => s.title === option.title)?.options.some(o => o[valueKey] === item[valueKey])}
                          onChange={() => handleSelect(item, option.title)}
                        />
                      ))
                    }
                  </SectionBody>
                )
              }
            </Section>
          ))
        }
        {
          !options?.length && (
            <EmptyContainer className='Empty'>
              <Loader size='small' />
            </EmptyContainer>
          )
        }
      </SectionContainer>
    </MultiselectContainer>
  );
}

export default MultiselectList;
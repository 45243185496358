import { useQuery, useQueries } from 'react-query';

import {
  getAccessToken,
  getLookerAccessToken,
  getLookerEmbeddedURL,
  getHierarchyData,
  getEntityCount,
  getLookerData,
  getProductsForUser,
  getUserMetadata
} from '../services/apis';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../helpers/commonHelper';
import {
  ACCESS_TOKEN_LS_KEY,
  LOOKER_ACCESS_TOKEN_LS_KEY,
  MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY,
  USERNAME_LS_KEY,
  LOOKER_USER_ID_LS_KEY,
  LOOKER_FILTER_KEYS
} from '../constants/AppConstants';

export const useFetchAccessToken = (params) => {
  return useQuery(
    ['accessToken', { ...params }],
    () => (
      getAccessToken(params)
    ),
    {
      staleTime: Infinity,
      retry: 0,
      enabled: !!params && (!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)),
      onSuccess: (response) => {
        if (response?.data?.accessToken) {
          setItemInLocalStorage(ACCESS_TOKEN_LS_KEY, response.data.accessToken)
        }
      }
    },
  );
}

export const useFetchLookerAccessToken = () => {
  return useQuery(
    ['lookerAccessToken'],
    getLookerAccessToken,
    {
      staleTime: Infinity,
      retry: 0,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY),
      onSuccess: (response) => {
        if (!!response?.data) {
          if (!!response.data?.authToken) {
            setItemInLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY, response.data.authToken)
          }
          if (!!response.data?.userId) {
            setItemInLocalStorage(LOOKER_USER_ID_LS_KEY, response.data.userId)
          }
          if (!!response.data?.userName) {
            setItemInLocalStorage(USERNAME_LS_KEY, response.data.userName)
            setItemInLocalStorage(MIXPANEL_ATTRIBUTES_LOCAL_STORAGE_KEY.USER_NAME, response.data.userName)
          }
        }
      }
    },
  );
}

export const useFetchUserMetadata = () => {
  return useQuery(
    ['userMetadata'],
    getUserMetadata,
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  );
}

export const useFetchEmbeddedUrl = (productId, params, enabled = false) => {
  return useQuery(
    ['embedUrl', { productId, dashboardId: params?.[LOOKER_FILTER_KEYS.DASHBOARD_ID] || "" }],
    () => (
      getLookerEmbeddedURL(productId, params)
    ),
    {
      staleTime: 0,
      cacheTime: 0,
      retry: 1,
      enabled
    },
  );
}

export const useFetchHierarchies = (products = []) => {
  const enabled = !!products?.length && !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
  const apis = products.map(product => ({
    queryKey: ["hierarchy", product?.productKey],
    queryFn: () => getHierarchyData(product?.apiIdentifierKey),
    staleTime: Infinity,
    retry: 1,
    enabled
  }))

  return useQueries(apis)
}

export const useFetchFilterDataViewMore = (payload, filters) => {
  return useQuery(
    ["viewMoreFilters", { ...filters }],
    () => getLookerData(payload),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY)
    },
  );
}

export const useFetchProducts = () => {
  return useQuery(
    ["products"],
    getProductsForUser,
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY)
    },
  );
}

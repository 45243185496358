import React, { useEffect, useState } from "react";

import { AuthenticatedRouteContainer } from "./styles";

import Loader from "../Loader";

import {
  logoutFromUAApp,
  setLocalStorageFromCookies,
} from "../../../helpers/authHelper";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "../../../helpers/commonHelper";
import {
  ACCESS_TOKEN_LS_KEY,
  REFRESH_TOKEN_LS_KEY,
} from "../../../constants/AppConstants";
import { handleFetchAndSaveAccessToken } from "../../../helpers/authHelper";
import { getTokenExpirationTime } from "./helpers";

const THIRTY_MINUTES = 30 * 60 * 1000;

const AuthenticatedRoute = ({ children }) => {
  const [isLoggedIn, toggleLoggedIn] = useState(false);

  useEffect(() => {
    setLocalStorageFromCookies();

    if (!getItemFromLocalStorage(REFRESH_TOKEN_LS_KEY)) {
      console.log("RefreshToken not available");
      logoutFromUAApp();
      return;
    }
    toggleLoggedIn(true);
  }, []);

  useEffect(() => {
    const checkTokenValidityAndRefresh = async () => {
      const accessToken = getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY);
      if (!!accessToken) {
        const tokenExpirationTime = getTokenExpirationTime(accessToken);
        const timeDiff = tokenExpirationTime - Date.now();
        if (timeDiff <= THIRTY_MINUTES && timeDiff > 0) {
          try {
            removeItemFromLocalStorage("accessToken");
            await handleFetchAndSaveAccessToken();
          } catch (error) {
            console.error("Failed to refresh access token:", error);
          }
        }
      }
    };

    const tokenRefreshInterval = setInterval(
      checkTokenValidityAndRefresh,
      THIRTY_MINUTES
    );

    return () => clearInterval(tokenRefreshInterval);
  }, []);

  if (!isLoggedIn) {
    return (
      <AuthenticatedRouteContainer>
        <Loader size="large" />
      </AuthenticatedRouteContainer>
    );
  }
  return <>{children}</>;
};

export default AuthenticatedRoute;

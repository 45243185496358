import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  useSearchParams,
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
  Link,
  createSearchParams
} from "react-router-dom";
import {
  parse,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
} from "date-fns";
import { useShallow } from 'zustand/react/shallow';

import {
  ReportsContainer,
  FilterContainer,
  LeftFilters,
  RightFilters,
  SelectColumnButton,
  Content,
  WidgetContainer,
  TabContainer,
  DownloadContainer,
  MobileFiltersContainer,
  TabDropdownContainer,
  TabDropdownButton,
  TabDropdownButtonText,
  TabListContainer,
  TabList,
  TabListItem,
  InfoContainer,
  Arrow,
  TabListItemTitleContainer,
  IconContainer,
  ButtonContainer,
  FiltersContainer,
  ButtonsDropdownContainer,
  PaginationTextContainer,
  PaginationContainer,
} from './styles'

import CampaignPerformanceReport from './CampaignPerformance';
import Tab from '../../components/common/Tab'
import Datepicker from "../../components/common/Datepicker";
import Modal from '../../components/common/Modal';
import Icon from '../../components/common/Icon';
import ColumnSelection from '../../components/ColumnSelection';
import AdvancedFilters from '../../components/AdvancedFilters';
import JobPerformanceReport from './JobPerformance';
import JobSponsorshipReport from './JobSponsorship';
import Expansions from './Expansions';
import EventReport from './Event';
import TotalStatsReport from './TotalStats';
import BottomModal from '../../components/common/BottomModal';
import ColumnSelectionMobileView from '../../components/ColumnSelectionMobileView';
import Button from '../../components/common/Button';
import Pagination from '../../components/common/Pagination';
import ReportDownloadForm from './ReportDownloadForm';
import SaveOrSchedule from '../../components/common/SaveSchedule';

import {
  JOB_PERFORMANCE_REPORT_TITLE,
  CAMPAIGN_REPORT_TITLE,
  JOB_SPONSORSHIP_REPORT_TITLE,
  EXPANSIONS_REPORT_TITLE,
  EVENT_REPORT_TITLE,
  EXPANSIONS_VIEW,
  REPORTS_TABS,
  REPORTS_CONFIG_MAP,
  CAMPAIGN_PERFORMANCE_VIEW,
  JOB_PERFORMANCE_VIEW,
  TOTAL_STATS_REPORT_TITLE,
  TOTAL_STATS_VIEW
} from "../../constants/ReportsConstants"

import {
  LOOKER_FILTER_KEYS,
  DATE_OPTIONS,
  DEVICE_SIZES_PX,
  EXCHANGE_VISIBILITY,
  MIXPANEL_ATTRIBUTES_KEY,
} from '../../constants/AppConstants';
import { parseFilterExpressions, toFilterExpression } from "../../utils/helpers"
import { formatDate } from "../../utils/dateHelpers"
import { hasFiltersChangedInSavedFilter } from "../../components/MainApp/helpers"

import {
  JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER,
  CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER,
  JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER,
  EXPANSIONS_REPORT_ROUTE_IDENTIFIER,
  EVENT_REPORT_ROUTE_IDENTIFIER,
  TOTAL_STATS_REPORT_ROUTE_IDENTIFIER,
  CAMPAIGN_PERFORMANCE_REPORT_ROUTE,
} from '../../constants/AppRoutes';
import { getReportsDownloadUsingRunInlineQuery } from '../../services/apis';
import {
  parseMetrics,
  parseColumnSelected,
  getBaseAdvancedFilterOptions,
  getLookerFiltersFromParams,
  downloadCSV,
  getDimensionFilters,
  parseUTMTags,
  getColumnsFromFilters,
  checkMetricsLoadState,
  parseColumnOrder
} from './helpers';
import {
  useFetchCampaignPerformanceMetrics,
  useFetchJobPerformanceMetrics,
  useFetchExpansionsMetrics,
  useFetchCIFFields,
  useFetchTotalStatsMetrics,
  useFetchUtmTags,
} from "../../api/reports"
import {
  buildMetricPayload,
  parseLookerFiltersForMetrics,
  buildPayloadForDownload,
  buildUTMPayload,
  parseUTMLookerFilters
} from "./apiHelpers"

import Mixpanel from '../../services/MixPanel';
import { getAdvancedFiltersFromFilters } from '../../pages/Programmatic/helpers';
import { useGlobalDataStore, useGlobalFiltersStore } from '../../stores/global';
import { onTrackMixPanelEvent } from '../../helpers/eventHandler';
import useInterval from '../../utils/useInterval';

const EVENT_SUGGESTION_TYPES = ["Device Type", "Publisher Name"]

const SCHEDULE_DOWNLOAD_OPTIONS = [
  // { name: "Schedule" },
  { name: "Download now" }
]

const Reports = ({
  onAdvancedFilterChange
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation()
  const navigate = useNavigate();

  const { hierarchy, views, publisherFilter, exchangeVisibility, models } =
    useGlobalDataStore(
      useShallow((state) => ({
        hierarchy: state.hierarchy,
        views: state.views,
        publisherFilter: state.publisherFilter,
        exchangeVisibility: state.exchangeVisibility,
        models: state.models,
      }))
    );

  const {
    globalFilters,
    viewMode,
    setViewMode,
  } = useGlobalFiltersStore(
    useShallow((state) => ({
      globalFilters: state.globalFilters,
      viewMode: state.viewMode,
      setViewMode: state.setViewMode,
    }))
  );

  const [selectedTab, setSelectedTab] = useState("")
  const [advancedFilters, setAdvancedFilters] = useState([])
  const [selectedDate, setSelectedDate] = useState(null);
  const [isColumnSelectionOpen, toggleColumnSelection] = useState(false)
  const [columnSelections, setColumnSelections] = useState(null)
  const [lookFilters, setLookFilters] = useState(null)
  const [isLoading, toggleLoading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false)
  const [pinnedColumns, setPinnedColumns] = useState([])
  const [isTabDropdownOpen, setIsTabDropdownOpen] = useState(false);
  const [isColumnSelectionBottomModalOpen, setIsColumnSelectionBottomModalOpen] = useState(false);
  const [isInfoHovering, toggleHovering] = useState(false);
  const [hoverId, setHoverId] = useState("")
  const [isScheduleDownloadOptionsOpen, toggleScheduleDownloadOptions] = useState(false)
  const [scheduleDownloadOption, setScheduleDownloadOption] = useState(SCHEDULE_DOWNLOAD_OPTIONS[0])
  const [isInitialColumnsSet, toggleInitialColumnsSet] = useState(false)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [isReportDownloadFormOpen, toggleReportDownloadForm] = useState(false)
  const [hasMetricsLoaded, toggleMetricsLoaded] = useState(false)
  const [isDownloadInQueue, toggleDownloadInQueue] = useState(false)
  const [downloadFormat, setDownloadFormat] = useState("")
  const prevFilters = useRef(null);

  const {
    data: { data: campaignMetrics = [] } = {},
    isLoading: isCampaignMetricsLoading,
    status: campaignMetricsStatus
  } = useFetchCampaignPerformanceMetrics(
    buildMetricPayload(lookFilters, CAMPAIGN_REPORT_TITLE, models),
    parseLookerFiltersForMetrics(lookFilters, CAMPAIGN_REPORT_TITLE)
  );

  const {
    data: { data: jobPerformanceMetrics = [] } = {},
    isLoading: isJobPerformanceMetricsLoading,
    status: jobPerformanceMetricsStatus
  } = useFetchJobPerformanceMetrics(
    buildMetricPayload(lookFilters, JOB_PERFORMANCE_REPORT_TITLE, models),
    parseLookerFiltersForMetrics(lookFilters, JOB_PERFORMANCE_REPORT_TITLE)
  );

  const {
    data: { data: expansionMetrics = [] } = {},
    isLoading: isexpansionMetricsLoading,
    status: expansionMetricsStatus
  } = useFetchExpansionsMetrics(
    buildMetricPayload(lookFilters, EXPANSIONS_REPORT_TITLE, models),
    parseLookerFiltersForMetrics(lookFilters, EXPANSIONS_REPORT_TITLE)
  );

  const {
    data: { data: totalStatsMetrics = [] } = {},
    isLoading: isTotalStatsMetricsLoading,
    status: totalStatsMetricsStatus
  } = useFetchTotalStatsMetrics(
    buildMetricPayload(lookFilters, TOTAL_STATS_REPORT_TITLE, models),
    parseLookerFiltersForMetrics(lookFilters, TOTAL_STATS_REPORT_TITLE)
  );

  const {
    data: utms,
  } = useFetchUtmTags(
    buildUTMPayload(lookFilters, selectedTab, models),
    parseUTMLookerFilters(lookFilters, selectedTab)
  );

  const {
    data: { data: { fields: cifFields } = {} } = {},
  } = useFetchCIFFields(Object.fromEntries([...searchParams])[LOOKER_FILTER_KEYS.CLIENT_ID]);

  const onDateChange = (date, option) => {
    let toEndDate = date;
    const weekStartsOn = 1;
    if (option.key === "this week" || option.key === "this month") {
      const start =
        option.key === "this week"
          ? startOfWeek(new Date(), { weekStartsOn })
          : startOfMonth(new Date());
      const end =
        option.key === "this week"
          ? endOfWeek(new Date(), { weekStartsOn })
          : endOfMonth(new Date());
      toEndDate = `${formatDate(start, "start")} to ${formatDate(end, "end")}`;
    }
    if (option.key === "custom") {
      const dateOption = DATE_OPTIONS.find((item) => item.key === "this month");
      if (date === dateOption.getDateFilterString()) {
        toEndDate = dateOption.getDateFilterStringTillEnd();
      }
    }
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      Date: date,
      dateProjectedSpend: toEndDate,
    });
    onTrackMixPanelEvent(
      "Date filter selected",
      {
        "Screen name": "Reports",
        "Date Selected": date
      }
    );
  };

  const handleColumnSelection = (columns) => {
    setColumnSelections(columns)
    toggleColumnSelection(false)
    if (globalFilters?.[LOOKER_FILTER_KEYS.FILTER_ID]) {
      setViewMode("update")
    }

    onTrackMixPanelEvent(
      "Selecting columns",
      {
        "Screen name": "Reports",
        "Columns Selected": columns
      }
    );
  }

  const handleFilterSelection = (filters) => {
    setAdvancedFilters(filters)
    let filterKeyMap = advancedFilterOptions
    const combined = filters?.reduce((acc, item) => ({ ...acc, ...parseFilterExpressions(item, filterKeyMap, "parseKey") }), {})
    setLookFilters(lf => {
      const final = { ...combined }
      for (let key in lf) {
        if (!filterKeyMap.find(i => i.filter === key)) {
          final[key] = lf[key]
        }
      }
      return final
    })
    onTrackMixPanelEvent(
      "Advance filter change",
      {
        "Screen name": "Reports",
        "Filters Selected": filters
      }
    );
  }

  useEffect(() => {
    if (hierarchy) {
      if (
        (hierarchy?.length === 1 ||
          exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) &&
        hierarchy?.[0]?.clients?.length === 1 &&
        globalFilters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === "" &&
        globalFilters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === "" &&
        globalFilters?.[LOOKER_FILTER_KEYS.CLIENT_ID] === ""
      ) {
        Mixpanel.trackEvent("Reports Screen Opened", {
          [MIXPANEL_ATTRIBUTES_KEY.INSTANCE_NAME]: hierarchy?.[0]?.name,
          [MIXPANEL_ATTRIBUTES_KEY.CLIENT_NAME]: hierarchy?.[0]?.clients?.[0]?.name,
        });

        return;
      } else if (
        (hierarchy?.length === 1 ||
          exchangeVisibility === EXCHANGE_VISIBILITY.OPEN) &&
        globalFilters?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] === "" &&
        globalFilters?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] === ""
      ) {
        Mixpanel.trackEvent("Reports Screen Opened", {
          [MIXPANEL_ATTRIBUTES_KEY.INSTANCE_NAME]: hierarchy?.[0]?.name,
        });

        return;
      } else {
        Mixpanel.trackEvent("Reports Screen Opened", {});
        return;
      }
    }
  }, [
    hierarchy, globalFilters, exchangeVisibility
  ]);

  const UTMTags = useMemo(() => {
    if (!utms?.data?.length) return []
    return parseUTMTags(utms.data)
  }, [utms])

  const campaignMetricKeys = useMemo(() => {
    const parseKey = `${CAMPAIGN_PERFORMANCE_VIEW}.conversion_type`
    const typeKey = `${CAMPAIGN_PERFORMANCE_VIEW}.conversion`
    return parseMetrics(campaignMetrics, parseKey, typeKey, CAMPAIGN_REPORT_TITLE)
  }, [campaignMetrics])

  const jobPerformanceMetricKeys = useMemo(() => {
    const parseKey = `${JOB_PERFORMANCE_VIEW}.conversion_type`
    const typeKey = `${JOB_PERFORMANCE_VIEW}.conversion`
    return parseMetrics(jobPerformanceMetrics, parseKey, typeKey, JOB_PERFORMANCE_REPORT_TITLE)
  }, [jobPerformanceMetrics])

  const jobSponsorshipMetricKeys = useMemo(() => {
    return parseMetrics([], '', '', JOB_SPONSORSHIP_REPORT_TITLE)
  }, [])

  const expansionsMetricKeys = useMemo(() => {
    const parseKey = `${EXPANSIONS_VIEW}.conversion_type`
    const typeKey = `${EXPANSIONS_VIEW}.conversion`
    return parseMetrics(expansionMetrics, parseKey, typeKey, EXPANSIONS_REPORT_TITLE)
  }, [expansionMetrics])

  const eventMetricKeys = useMemo(() => {
    return parseMetrics([], '', '', EVENT_REPORT_TITLE)
  }, [])

  const totalStatsMetricKeys = useMemo(() => {
    const parseKey = `${TOTAL_STATS_VIEW}.conversion_type`
    const typeKey = `${TOTAL_STATS_VIEW}.conversion`
    return parseMetrics(totalStatsMetrics, parseKey, typeKey, TOTAL_STATS_REPORT_TITLE)
  }, [totalStatsMetrics])

  const getColumnMetrics = useCallback(() => {
    let metrics = campaignMetricKeys
    if (selectedTab === CAMPAIGN_REPORT_TITLE) metrics = campaignMetricKeys;
    if (selectedTab === JOB_PERFORMANCE_REPORT_TITLE) metrics = jobPerformanceMetricKeys;
    if (selectedTab === JOB_SPONSORSHIP_REPORT_TITLE) metrics = jobSponsorshipMetricKeys;
    if (selectedTab === EXPANSIONS_REPORT_TITLE) metrics = expansionsMetricKeys;
    if (selectedTab === EVENT_REPORT_TITLE) metrics = eventMetricKeys;
    if (selectedTab === TOTAL_STATS_REPORT_TITLE) metrics = totalStatsMetricKeys

    return metrics
      .filter(item => item.title !== "Goals")
      .map(item => item.title === "Spend" ? ({ ...item, options: item.options.filter(opt => opt.name !== "Media Spend") }) : item)
  }, [
    selectedTab,
    campaignMetricKeys,
    jobPerformanceMetricKeys,
    jobSponsorshipMetricKeys,
    expansionsMetricKeys,
    eventMetricKeys,
    totalStatsMetricKeys,
    globalFilters
  ])


  const dimensions = useMemo(() => {
    if (!selectedTab) return null

    const config = REPORTS_CONFIG_MAP[selectedTab]
    let dimensions = config.DIMENSIONS
    if (!!config.PREFIX) {
      if (!!Object.entries(cifFields || {})?.length) {
        const cifs = Object.entries(cifFields).map(([key, value]) => ({
          name: key,
          key: `${config.PREFIX}_${value.split("cif")[1]}`,
          columnFilterKey: `${config.PREFIX}${value.split("cif")[1]}`
        }))
        const jobAdditonalFields = {
          title: "Job additional nodes",
          options: cifs
        }
        dimensions = [...dimensions.slice(0, config.JOB_NODES_LOCATION), jobAdditonalFields, ...dimensions.slice(config.JOB_NODES_LOCATION)]
      }
    }
    if (config.UTM_TAGS_LOCATION && UTMTags?.length) {
      const utmTags = UTMTags.map(item => ({
        name: item.name,
        key: item.filterKey,
        columnFilterKey: item.columnFilterKey
      }))
      const utms = {
        title: "UTM tags",
        options: utmTags
      }
      dimensions = [...dimensions.slice(0, config.UTM_TAGS_LOCATION), utms, ...dimensions.slice(config.UTM_TAGS_LOCATION)]
    }
    if (publisherFilter) {
      return dimensions
    }
    return dimensions.filter(dimensionOption => dimensionOption.title.toLowerCase() !== 'publisher')
  }, [selectedTab, publisherFilter, cifFields, UTMTags, globalFilters])

  const columnsSelected = useMemo(() => {
    const metricKeys = getColumnMetrics()

    if (!metricKeys?.length) return columnSelections
    if (!dimensions?.length) return columnSelections

    if (!columnSelections) {
      const config = REPORTS_CONFIG_MAP[selectedTab]
      const defaultDimensions = config.DEFAULT_COLUMNS.dimensions
        .filter(item => item.title.toLocaleLowerCase().startsWith("publisher") ? publisherFilter : true)
      let defaultMetrics = config.DEFAULT_COLUMNS.metrics.map(item => ({ ...item, options: item.options.map(opt => ({ ...opt, id: JSON.stringify(opt) })) }))

      return {
        dimensions: defaultDimensions,
        metrics: defaultMetrics,
        order: parseColumnOrder({ dimensions: defaultDimensions, metrics: defaultMetrics })
      }
    }
    const selectedDimensions = parseColumnSelected(dimensions, columnSelections, "dimensions")
    let selectedMetrics = parseColumnSelected(metricKeys, columnSelections, "metrics")
    return {
      dimensions: selectedDimensions,
      metrics: selectedMetrics,
      order: parseColumnOrder({ dimensions: selectedDimensions, metrics: selectedMetrics, order: columnSelections?.order })
    }
  }, [columnSelections, selectedTab, dimensions, getColumnMetrics, publisherFilter, globalFilters])

  const advancedFilterOptions = useMemo(() => {
    if (!selectedTab) return []

    const cifs = Object.entries(cifFields || {})
    let jobFilters = [
      ...getBaseAdvancedFilterOptions(publisherFilter, selectedTab).map(item => ({ ...item, key: item.name, filter: item.field, parseKey: item.name }))
    ]
    const config = REPORTS_CONFIG_MAP[selectedTab]
    if (!config.ADDITIONAL_ADVANCED_FILTERS || !dimensions?.length) {
      return jobFilters
    }
    if (config.UTM_TAGS_LOCATION && UTMTags?.length) {
      jobFilters = [
        ...jobFilters,
        ...UTMTags.map(item => ({
          key: item.filterKey,
          name: item.name,
          enabled: true,
          field: item.filterKey,
          filter: item.filterKey,
          parseKey: item.name,
          suggestionsEnabled: false
        }))
      ]
    }
    if (config.DIMENSIONS) {
      jobFilters = [
        ...jobFilters,
        ...getDimensionFilters(config.DIMENSIONS, selectedTab === EVENT_REPORT_TITLE ? 0 : 3, publisherFilter).filter(item => item.field !== 'ip_report.expanded_job_ref_number').map(item => ({
          ...item,
          key: item.name,
          filter: item.field,
          parseKey: item.name,
          suggestionsEnabled: selectedTab === EVENT_REPORT_TITLE ? EVENT_SUGGESTION_TYPES.includes(item.name) : true
        }))
      ]
    }
    if (!!cifs?.length) {
      jobFilters = [
        ...jobFilters,
        ...dimensions[config.JOB_ADDITIONAL_NODE_INDEX]?.options?.map(item => ({
          ...item,
          enabled: true,
          field: item.key,
          filter: item.key,
          parseKey: item.name,
          suggestionsEnabled: true
        })) || [],
      ]
      jobFilters = jobFilters.filter(
        (jobFilter) => jobFilter.field !== "ip_report.event_timestamp_time"
      );
    }

    return jobFilters
  }, [publisherFilter, dimensions, cifFields, selectedTab, UTMTags])

  useEffect(() => {
    if (!selectedTab) return
    const params = Object.fromEntries([...searchParams]);
    console.log("Search params in reports", params)

    const filters = getLookerFiltersFromParams({ filters: params, type: selectedTab })
    const {
      [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId,
      [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId,
      [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId,
      [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId,
      [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroupId,
      [LOOKER_FILTER_KEYS.DATE]: date,
    } = params || {};

    const prev = prevFilters.current;
    const shouldClear = (
      accountId !== prev?.[LOOKER_FILTER_KEYS.ACCOUNT_ID] ||
      subAccountId !== prev?.[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
      clientId !== prev?.[LOOKER_FILTER_KEYS.CLIENT_ID] ||
      campaignId !== prev?.[LOOKER_FILTER_KEYS.CAMPAIGN_ID] ||
      jobGroupId !== prev?.[LOOKER_FILTER_KEYS.JOB_GROUP_ID] ||
      selectedTab !== prev?.selectedTab
    )
    if (shouldClear) {
      setLookFilters(filters)
    } else {
      setLookFilters(lf => ({ ...lf, ...filters }))
    }
    if (date) {
      if (selectedDate) return
      const [d1, d2] = date.split(" to ");
      const start = parse(d1?.split(" ")[0], "yyyy-MM-dd", new Date());
      const end = parse(d2?.split(" ")[0], "yyyy-MM-dd", new Date());
      setSelectedDate([start, end]);
    } else {
      setSelectedDate(null)
    }
  }, [searchParams, selectedTab, selectedDate]);

  useEffect(() => {
    const tab = REPORTS_TABS.find(item => item.route === location.pathname)
    const toSet = tab?.title || CAMPAIGN_REPORT_TITLE
    setSelectedTab(toSet)
    if (
      toSet === JOB_PERFORMANCE_REPORT_TITLE
      || toSet === EXPANSIONS_REPORT_TITLE
      || toSet === EVENT_REPORT_TITLE
      || toSet === TOTAL_STATS_REPORT_TITLE
    ) {
      const params = Object.fromEntries([...searchParams]);
      const {
        [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
        [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
        [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
      } = params || {};
      if (!(accountId && subAccountId && clientId)) {
        navigate({
          pathname: CAMPAIGN_PERFORMANCE_REPORT_ROUTE,
          search: createSearchParams(params).toString()
        });
      }
    }
  }, [location.pathname, searchParams, navigate])

  useEffect(() => {
    if (!selectedTab) return
    const params = Object.fromEntries([...searchParams])
    const hasSelectedTabBeenUpdated = (() => {
      const tab = REPORTS_TABS.find(item => item.title === selectedTab)
      if (!window.location.href.includes(tab.redirect)) return false
      return true
    })()
    if (!hasSelectedTabBeenUpdated) {
      return
    }
    const prev = prevFilters.current;
    if (!!prev?.selectedTab && (selectedTab !== prev?.selectedTab && !params[LOOKER_FILTER_KEYS.FILTER_ID])) {
      setAdvancedFilters([])
      const globalFilters = getLookerFiltersFromParams({ filters: params, type: selectedTab })
      setLookFilters(globalFilters)
    }
    const hasGlobalFiltersChanged = hasFiltersChangedInSavedFilter(params[LOOKER_FILTER_KEYS.FILTER_ID], views, params)
    if (hasGlobalFiltersChanged) {
      setAdvancedFilters([])
    } else if (!!views?.length) {
      const filterId = params[LOOKER_FILTER_KEYS.FILTER_ID]
      if (!!filterId && !!views?.length) {
        const advancedFilters = getAdvancedFiltersFromFilters(filterId, views)
        if (!!advancedFilters?.length) {
          const parsed = advancedFilters.map(item => toFilterExpression(item, advancedFilterOptions)).filter(Boolean)
          setAdvancedFilters(parsed)
          const globalFilters = getLookerFiltersFromParams({ filters: params, type: selectedTab })
          const advancedFiltersMap = advancedFilters.reduce((acc, item) => {
            const [key, value] = Object.entries(item)[0]
            return {
              ...acc,
              [key]: value
            }
          }, {})
          setLookFilters({ ...globalFilters, ...advancedFiltersMap })
        }
      }
    }
    if (hasSelectedTabBeenUpdated) {
      prevFilters.current = { ...params, selectedTab }
    }
  }, [selectedTab, views, searchParams, advancedFilterOptions])

  useEffect(() => {
    setColumnSelections(null)
    setPage(1)
  }, [selectedTab])

  useEffect(() => {
    if (!selectedTab || !!isInitialColumnsSet) return
    const hasSelectedTabBeenUpdated = (() => {
      const tab = REPORTS_TABS.find(item => item.title === selectedTab)
      if (!window.location.href.includes(tab.redirect)) return false
      return true
    })()
    if (!hasSelectedTabBeenUpdated) {
      return
    }

    if (!hasMetricsLoaded) return

    const metrics = getColumnMetrics()

    const filterId = globalFilters[LOOKER_FILTER_KEYS.FILTER_ID]
    const columnsFromView = getColumnsFromFilters({ filterId, views, dimensions, metrics })
    if (columnsFromView?.dimensions?.length && columnsFromView?.metrics?.length) {
      setColumnSelections(columnsFromView)
      toggleInitialColumnsSet(true)
    }
  }, [
    selectedTab,
    views,
    globalFilters,
    isInitialColumnsSet,
    dimensions,
    hasMetricsLoaded,
    getColumnMetrics
  ])

  useEffect(() => {
    if (location?.state && !!advancedFilterOptions) {
      const opt = advancedFilterOptions.find(item => item.name === location.state.key)
      if (!!opt) {
        setLookFilters(lf => ({
          ...lf,
          ...{ [opt.field]: location.state.value }
        }))
        setAdvancedFilters(af => [
          ...af,
          ...[location.state]
        ])
      }
    }
  }, [location?.state, advancedFilterOptions])

  const getColumnNamesList = (columns) => {
    const dimensions = [...columns.dimensions].flatMap(item => item.options.map(opt => opt.name))
    const metrics = [...columns.metrics].map(item => item.name)
    const combined = [...dimensions, ...metrics]
    return combined
  }

  const getColumnNamesFromReport = (columns, selectedTab) => {
    const config = REPORTS_CONFIG_MAP[selectedTab]
    const columnNamesList = getColumnNamesList(columns, [...config.ADDITIONAL_FIELDS_IN_API, ...config.ADDITIONAL_FIELDS_IN_DOWNLOAD])
    return columnNamesList;
  }

  const handleReportDownload = async (format, isDownloadInQueue = false) => {
    try {
      if (!selectedTab) {
        return;
      }
      setIsDownloadingReport(true);
      if (!hasMetricsLoaded) {
        toggleDownloadInQueue(true)
        setDownloadFormat(format)
        return
      }
      const params = {
        apply_formatting: true,
        apply_vis: true
      }
      const payload = buildPayloadForDownload(lookFilters, columnsSelected, selectedTab, advancedFilterOptions, models)
      const response = await getReportsDownloadUsingRunInlineQuery(payload, format, params);
      downloadCSV(response, selectedTab, format);
      setIsDownloadingReport(false);
      toggleDownloadInQueue(false)
      Mixpanel.trackEvent("Detailed Report Downloaded", {
        "Report Type": selectedTab,
        Filters: advancedFilters,
        Columns: getColumnNamesFromReport(columnsSelected, selectedTab),
      })
    } catch (error) {
      setIsDownloadingReport(false);
      toggleDownloadInQueue(false)
    }
  };

  useEffect(() => {
    const hasMetricsLoaded = checkMetricsLoadState({
      selectedTab,
      campaignMetricsStatus,
      jobPerformanceMetricsStatus,
      expansionMetricsStatus,
      totalStatsMetricsStatus,
      eventMetricKeys
    })
    toggleMetricsLoaded(hasMetricsLoaded)
  }, [
    selectedTab,
    campaignMetricsStatus,
    jobPerformanceMetricsStatus,
    expansionMetricsStatus,
    totalStatsMetricsStatus,
    eventMetricKeys,
    toggleMetricsLoaded,
  ])

  useInterval(() => {
    if (hasMetricsLoaded && isDownloadInQueue && !!downloadFormat) {
      toggleDownloadInQueue(false)
      handleReportDownload(downloadFormat)
    }
  }, isDownloadInQueue ? 1000 : null);

  const isClientReportsDisabled = (() => {
    const params = Object.fromEntries([...searchParams]);
    const {
      [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
      [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
      [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
    } = params || {};
    return !(accountId && subAccountId && clientId)
  })();

  const columnSelectionEnabled = selectedTab !== JOB_SPONSORSHIP_REPORT_TITLE;

  const tabOptions = REPORTS_TABS
    .filter(item => ((item.title === TOTAL_STATS_REPORT_TITLE) && (publisherFilter === false)) ? false : true)
    .map(item => !!item.disabledText ? { ...item, disabled: isClientReportsDisabled } : item)

  const handleTabDropdownModalClose = () => {
    setIsTabDropdownOpen(false);
  };

  const handleTabDropdownOpen = () => {
    setIsTabDropdownOpen(true);
  }

  const handleColumnSelectionBottomModalClose = () => {
    setIsColumnSelectionBottomModalOpen(false);
  };

  const handleColumnSelectionBottomModalOpen = () => {
    setIsColumnSelectionBottomModalOpen(true);
  }

  useEffect(() => {
    onAdvancedFilterChange(advancedFilters, advancedFilterOptions)
  }, [advancedFilterOptions, advancedFilters, onAdvancedFilterChange])

  return (
    <ReportsContainer>
      <TabContainer>
        <Tab
          options={
            tabOptions
          }
          selected={selectedTab}
          onTabChange={(tab) => {
            setSelectedTab(tab.title)
            toggleInitialColumnsSet(false)
            const params = Object.fromEntries([...searchParams]);
            if (!!params[LOOKER_FILTER_KEYS.FILTER_ID]) {
              delete params[LOOKER_FILTER_KEYS.FILTER_ID]
            }
            const urlSearchParams = "?" + new URLSearchParams(params).toString();
            navigate({
              pathname: tab.redirect,
              search: urlSearchParams,
            });
          }}
        />
      </TabContainer>
      <TabDropdownContainer>
        <TabDropdownButton onClick={handleTabDropdownOpen}>
          <TabDropdownButtonText>{selectedTab}</TabDropdownButtonText> <Icon name="chevron-down" />{" "}
        </TabDropdownButton>
      </TabDropdownContainer>
      <Content>
        <FilterContainer>
          <LeftFilters>
            <AdvancedFilters
              filters={advancedFilterOptions}
              onChange={handleFilterSelection}
              value={advancedFilters}
              disabled={isLoading}
              className="AdvancedFilters_Reports"
            />
            <MobileFiltersContainer viewMode={viewMode}>
              {/* <SaveFilters
                type="reports"
                viewMode={viewMode}
                selectedTab={selectedTab}
                advancedFilters={advancedFilters}
                advancedFilterOptions={advancedFilterOptions}
              /> */}
              <ButtonContainer>
                <Button
                  text="Download and schedule"
                  icon={scheduleDownloadOption?.name === SCHEDULE_DOWNLOAD_OPTIONS[0].name ? (isDownloadingReport ? "loading" : "download") : ""}
                  type="submit"
                  className={`Report__button ${isDownloadingReport ? " loading-svg" : ""}`}
                  onClick={() => {
                    toggleScheduleDownloadOptions(!isScheduleDownloadOptionsOpen);
                  }}
                />
                <Button
                  icon="arrow-down-solid"
                  className="Report__button Report__button_dropdown-arrow"
                  type="submit"
                  onClick={() => {
                    toggleScheduleDownloadOptions(!isScheduleDownloadOptionsOpen);
                  }}
                />
                {
                  isScheduleDownloadOptionsOpen && (
                    <ButtonsDropdownContainer>
                      {
                        SCHEDULE_DOWNLOAD_OPTIONS.map(opt => (
                          <Button
                            key={opt.name}
                            text={opt.name}
                            type="submit"
                            className="Report__button"
                            onClick={() => {
                              if (opt.name === SCHEDULE_DOWNLOAD_OPTIONS[0].name) {
                                toggleReportDownloadForm(true)
                              }
                              setScheduleDownloadOption(opt)
                              toggleScheduleDownloadOptions(false)
                            }}
                          />
                        ))
                      }
                      {/* <ScheduleReport
                        type="reports"
                        filters={lookFilters}
                        columnSelections={columnsSelected}
                        selectedTab={selectedTab}
                        advancedFilterOptions={advancedFilterOptions}
                      /> */}
                    </ButtonsDropdownContainer>
                  )
                }
              </ButtonContainer>
            </MobileFiltersContainer>
          </LeftFilters>
          <RightFilters>
            <FiltersContainer>
              <SaveOrSchedule
                type="reports"
                filters={lookFilters}
                columnSelections={columnsSelected}
                selectedTab={selectedTab}
                advancedFilters={advancedFilters}
                advancedFilterOptions={advancedFilterOptions}
                showAdvancedFiltersInForm={true}
              />
            </FiltersContainer>
            <Datepicker
              selected={selectedDate}
              onChange={onDateChange}
              disabled={isLoading}
            />
            {
              columnSelectionEnabled &&
              <SelectColumnButton onClick={() => {
                window.innerWidth <= DEVICE_SIZES_PX.MOBILE ? handleColumnSelectionBottomModalOpen() : toggleColumnSelection(!isColumnSelectionOpen)
              }}
                disabled={isLoading}
              >
                <Icon name="column" />
                <span>Manage columns</span>
              </SelectColumnButton>
            }
          </RightFilters>
        </FilterContainer>
        <WidgetContainer>
          <Routes>
            <Route path={CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER} element={
              <CampaignPerformanceReport
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                isDownloading={isDownloadingReport}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route path={JOB_PERFORMANCE_REPORT_ROUTE_IDENTIFIER} element={
              <JobPerformanceReport
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                isDownloading={isDownloadingReport}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route path={JOB_SPONSORSHIP_REPORT_ROUTE_IDENTIFIER} element={
              <JobSponsorshipReport
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                isDownloading={isDownloadingReport}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route path={EXPANSIONS_REPORT_ROUTE_IDENTIFIER} element={
              <Expansions
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                isDownloading={isDownloadingReport}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route path={EVENT_REPORT_ROUTE_IDENTIFIER} element={
              <EventReport
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                isDownloading={isDownloadingReport}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route path={TOTAL_STATS_REPORT_ROUTE_IDENTIFIER} element={
              <TotalStatsReport
                lookFilters={lookFilters}
                advancedFilters={advancedFilterOptions}
                columnSelections={columnsSelected}
                pinnedColumns={pinnedColumns}
                page={page}
                onPageCountChange={(count) => {
                  setPageCount(count)
                  setPage(1)
                }}
                isDownloading={isDownloadingReport}
                onPinnedColumnsChange={setPinnedColumns}
                onLoad={toggleLoading}
                onDownload={() => toggleReportDownloadForm(true)}
              />
            } />
            <Route
              path=""
              element={
                <Navigate
                  to={{
                    pathname: `${CAMPAIGN_PERFORMANCE_REPORT_ROUTE_IDENTIFIER}`,
                    search: new URLSearchParams(
                      Object.fromEntries([...searchParams])
                    ).toString(),
                  }}
                />
              }
            />
            <Route path="*" element={<Navigate to="/404" />}
            />
          </Routes>
          {
            ((
              selectedTab === CAMPAIGN_REPORT_TITLE && !isCampaignMetricsLoading)
              || (selectedTab === JOB_PERFORMANCE_REPORT_TITLE && !isJobPerformanceMetricsLoading)
              || (selectedTab === EXPANSIONS_REPORT_TITLE && !isexpansionMetricsLoading)
              || (selectedTab === TOTAL_STATS_REPORT_TITLE && !isTotalStatsMetricsLoading)
            ) && (
              <Outlet />
            )
          }
        </WidgetContainer>
        {
          !isLoading && (
            <DownloadContainer>
              <PaginationTextContainer>
                <p><strong>Note:&nbsp;</strong>Showing first 5000 records</p>
              </PaginationTextContainer>
              <PaginationContainer>
                {
                  pageCount > 1 && (
                    <Pagination
                      currentPage={page}
                      pageCount={pageCount}
                      itemsPerPage={25}
                      onPageChange={setPage}
                    />
                  )
                }
                <Button
                  text={`${isDownloadingReport ? "Downloading" : "Download now"}`}
                  icon={isDownloadingReport ? "loading" : "download"}
                  type="submit"
                  className={`Report__button ${isDownloadingReport ? "loading-svg" : ""}`}
                  onClick={() => toggleReportDownloadForm(true)}
                />
              </PaginationContainer>
            </DownloadContainer>
          )
        }
      </Content>
      {
        isColumnSelectionOpen && (
          <Modal position="center" className="ColumnSelectionModal" onClose={toggleColumnSelection}>
            <ColumnSelection
              dimensions={dimensions}
              metrics={getColumnMetrics()}
              selections={columnsSelected}
              onSubmit={handleColumnSelection}
              onClose={() => {
                toggleColumnSelection(false)
              }} />
          </Modal>
        )
      }
      {
        isReportDownloadFormOpen && (
          <Modal position="center" className="ColumnSelectionModal" onClose={toggleReportDownloadForm}>
            <ReportDownloadForm
              onSubmit={handleReportDownload}
              onClose={() => {
                toggleReportDownloadForm(false)
              }} />
          </Modal>
        )
      }
      {isTabDropdownOpen && (
        <BottomModal
          isOpen={isTabDropdownOpen}
          onClose={handleTabDropdownModalClose}
          style={{ height: "auto" }}
        >
          <TabListContainer>
            <TabList>
              {tabOptions.map((tabOption) => (
                <Link
                  to={{
                    pathname: tabOption.redirect,
                    search: new URLSearchParams(
                      Object.fromEntries([...searchParams])
                    ).toString(),
                  }}
                  key={JSON.stringify(tabOption)}
                  onClick={(e) => {
                    if (tabOption?.disabled) {
                      e.preventDefault();
                    }
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <TabListItem
                    isSelected={selectedTab === tabOption.title}
                    isDisabled={!!tabOption.disabled}
                    onClick={() => {
                      setSelectedTab(tabOption.title);
                      setIsTabDropdownOpen(false);
                    }}
                  >
                    <TabListItemTitleContainer>
                      <span>{tabOption.title}</span>
                      {!!tabOption.disabled && (
                        <IconContainer
                          onClick={() => {
                            toggleHovering(true);
                            setHoverId(tabOption.title);
                          }}
                          onMouseLeave={() => {
                            setHoverId(tabOption.title);
                            toggleHovering(false);
                          }}
                        >
                          <Icon name="info" style={{ marginLeft: "0.25rem" }} />
                          {isInfoHovering && hoverId === tabOption.title && (
                            <>
                              <InfoContainer>
                                {tabOption.disabledText}
                              </InfoContainer>
                              <Arrow />
                            </>
                          )}
                        </IconContainer>
                      )}
                    </TabListItemTitleContainer>
                    {selectedTab === tabOption.title && (
                      <Icon name="checked-blue" />
                    )}
                  </TabListItem>
                </Link>
              ))}
            </TabList>
          </TabListContainer>
        </BottomModal>
      )}
      {isColumnSelectionBottomModalOpen && (
        <BottomModal
          isOpen={isColumnSelectionBottomModalOpen}
          onClose={handleColumnSelectionBottomModalClose}
        >
          <ColumnSelectionMobileView
            dimensions={dimensions}
            metrics={getColumnMetrics()}
            selections={columnsSelected}
            onSubmit={handleColumnSelection}
            onClose={() => {
              handleColumnSelectionBottomModalClose();
            }}
          />
        </BottomModal>
      )}
    </ReportsContainer >
  );
}

export default Reports;

import { CAREER_SITE_IDENTIFIERS, CRM_IDENTIFIERS, JOB_SITE_IDENTIFIERS, LOOKER_FILTER_KEYS, PROGRAMMATIC_IDENTIFIERS, UNIFIED_VIEW_IDENTIFIERS } from "../constants/AppConstants";

const searchProgrammatic = (hierarchy, filters) => {
  let {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.CLIENT_ID]: clientId = "",
    [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaignId = "",
    [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobgroupId = "",
  } = filters || {};
  if (!!accountId && !!hierarchy) {
    for (const account of hierarchy) {
      if (!!accountId && account.id !== accountId) continue;
      if (!subAccountId) {
        if (!!clientId) {
          let matchingSubAccount, matchingClient, matchingAgency, matchingCampaign, matchingJobGroup;
          for (const hierarchyItem of hierarchy) {
            matchingSubAccount = hierarchyItem?.subAccounts?.find(subAccount =>
              subAccount?.clients?.some(client => client?.id === clientId)
            );
            if (!!matchingSubAccount) {
              matchingClient = matchingSubAccount?.clients?.find(client => client?.id === clientId);
              if (matchingClient) {
                matchingAgency = matchingClient?.agencyId;
                matchingCampaign = matchingClient?.campaigns?.find(campaign => campaign?.id === campaignId);
                matchingJobGroup = matchingCampaign?.jobGroups?.find(jobGroup => jobGroup?.id === jobgroupId);
              }
              break;
            }
          }
          if (!!matchingSubAccount && !!matchingClient) {
            return {
              id: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: matchingSubAccount.id,
                [LOOKER_FILTER_KEYS.CLIENT_ID]: matchingClient.id,
                [LOOKER_FILTER_KEYS.AGENCY_ID]: matchingAgency?.agencyId,
                [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: matchingCampaign?.id,
                [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: matchingJobGroup?.id,
              },
              name: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: matchingSubAccount?.name,
                [LOOKER_FILTER_KEYS.CLIENT_ID]: matchingClient.name,
                [LOOKER_FILTER_KEYS.AGENCY_ID]: matchingAgency?.name,
                [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: matchingCampaign?.name,
                [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: matchingJobGroup?.name,
              },
            };
          }
        }
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
          },
        }
      }
      for (const subAccount of account.subAccounts) {
        if (!!subAccountId && subAccount.id !== subAccountId) continue
        if (!clientId) {
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
            },
          };
        }
        for (const client of subAccount.clients) {
          if (!!clientId && client.id !== clientId) continue;
          if (!campaignId) {
            return {
              id: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
                [LOOKER_FILTER_KEYS.AGENCY_ID]: client.agencyId
              },
              name: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
                [LOOKER_FILTER_KEYS.CLIENT_ID]: client.name,
              },
            };
          }
          for (const campaign of client.campaigns) {
            campaignId = decodeURIComponent(campaignId)
            if (!!campaignId && campaign.id !== campaignId) continue
            if (!jobgroupId) {
              return {
                id: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                  [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
                  [LOOKER_FILTER_KEYS.AGENCY_ID]: client.agencyId,
                  [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.id
                },
                name: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
                  [LOOKER_FILTER_KEYS.CLIENT_ID]: client.name,
                  [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.name
                }
              }
            }
            for (const jobGroup of campaign.jobGroups) {
              jobgroupId = decodeURIComponent(jobgroupId)
              if (!!jobgroupId && jobGroup.id !== jobgroupId) continue
              return {
                id: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                  [LOOKER_FILTER_KEYS.CLIENT_ID]: client.id,
                  [LOOKER_FILTER_KEYS.AGENCY_ID]: client.agencyId,
                  [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.id,
                  [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroup.id,
                },
                name: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
                  [LOOKER_FILTER_KEYS.CLIENT_ID]: client.name,
                  [LOOKER_FILTER_KEYS.CAMPAIGN_ID]: campaign.name,
                  [LOOKER_FILTER_KEYS.JOB_GROUP_ID]: jobGroup.name,
                },
              };
            }
          }
        }
      }
    }
  }
  return null;
};

const searchCareerSites = (hierarchy, filters) => {
  let {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.SITE_ID]: siteId = "",
  } = filters || {};
  if (!!accountId && !!hierarchy) {
    for (const account of hierarchy) {
      if (!!accountId && account.id !== accountId) continue;
      if (!subAccountId) {
        if (!!siteId) {
          const subAccount = account.subAccounts?.find(item => item.sites?.some(site => site?.id === siteId));
          if (!!subAccount) {
            const site = subAccount?.sites?.find(site => site?.id === siteId);
            if (!!site) {
              return {
                id: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
                  [LOOKER_FILTER_KEYS.SITE_ID]: site.id,
                },
                name: {
                  [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                  [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
                  [LOOKER_FILTER_KEYS.SITE_ID]: site.name,
                },
              };
            }
          }
        }
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
          },
        };
      }
      for (const subAccount of account.subAccounts) {
        if (!!subAccountId && subAccount.id !== subAccountId) continue;
        if (!siteId) {
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
            },
          };
        }
        for (const site of subAccount.sites) {
          if (!!siteId && site.id !== siteId) continue
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
              [LOOKER_FILTER_KEYS.SITE_ID]: site.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
              [LOOKER_FILTER_KEYS.SITE_ID]: site.name,
            },
          };
        }
      }
    }
  }
  return null;
}

const searchCRM = (hierarchy, filters) => {
  let {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.SITE_ID]: siteId = "",
  } = filters || {};
  if (!!accountId && !!hierarchy) {
    for (const account of hierarchy) {
      if (!!accountId && account.id !== accountId) continue;
      if (!subAccountId) {
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
          },
        };
      }
      for (const subAccount of account.subAccounts) {
        if (!!subAccountId && subAccount.id !== subAccountId) continue;
        if (!siteId) {
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
            },
          };
        }
      }
    }
  }
  return null;
}

const searchUnifiedView = (hierarchy, filters) => {
  let {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.SITE_ID]: siteId = "",
  } = filters || {};
  if (!!accountId && !!hierarchy) {
    for (const account of hierarchy) {
      if (!!accountId && account.id !== accountId) continue;
      if (!subAccountId) {
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
          },
        };
      }
      for (const subAccount of account.subAccounts) {
        if (!!subAccountId && subAccount.id !== subAccountId) continue;
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
            [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
          },
        };
      }
    }
  }
  return null;
}

const searchJobSite = (hierarchy, filters) => {
  let {
    [LOOKER_FILTER_KEYS.ACCOUNT_ID]: accountId = "",
    [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccountId = "",
    [LOOKER_FILTER_KEYS.SITE_ID]: siteId = "",
  } = filters || {};
  if (!!accountId && !!hierarchy) {
    for (const account of hierarchy) {
      if (!!accountId && account.id !== accountId) continue;
      if (!subAccountId) {
        if (!!siteId) {
          let matchingSubAccount, matchingSite;
          for (const hierarchyItem of hierarchy) {
            matchingSubAccount = hierarchyItem?.subAccounts?.find(subAccount =>
              subAccount?.sites?.some(site => site?.id === siteId)
            );
            if (!!matchingSubAccount) {
              matchingSite = matchingSubAccount?.sites?.find(site => site?.id === siteId);
              break;
            }
          }
          if (!!matchingSubAccount && !!matchingSite) {
            return {
              id: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: matchingSubAccount.id,
                [LOOKER_FILTER_KEYS.SITE_ID]: matchingSite.id,
              },
              name: {
                [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
                [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: matchingSubAccount.name,
                [LOOKER_FILTER_KEYS.SITE_ID]: matchingSite.name,
              },
            };
          }
        }
        return {
          id: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
          },
          name: {
            [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
          },
        };
      }
      for (const subAccount of account.subAccounts) {
        if (!!subAccountId && subAccount.id !== subAccountId) continue;
        if (!siteId) {
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
            },
          };
        }
        for (const site of subAccount.sites) {
          if (!!siteId && site.id !== siteId) continue;
          return {
            id: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.id,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.id,
              [LOOKER_FILTER_KEYS.SITE_ID]: site.id,
            },
            name: {
              [LOOKER_FILTER_KEYS.ACCOUNT_ID]: account.name,
              [LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID]: subAccount.name,
              [LOOKER_FILTER_KEYS.SITE_ID]: site.name,
            },
          };
        }
      }
    }
  }
  return null;
}

export const searchHierarchyData = (hierarchy, filters, product = PROGRAMMATIC_IDENTIFIERS) => {
  if (!product?.productKey || !hierarchy) return []

  const hierarchyData = hierarchy[product.productKey]

  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      return searchProgrammatic(hierarchyData, filters)
    case CAREER_SITE_IDENTIFIERS.productKey:
      return searchCareerSites(hierarchyData, filters)
    case CRM_IDENTIFIERS.productKey:
      return searchCRM(hierarchyData, filters)
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      return searchUnifiedView(hierarchyData, filters)
    case JOB_SITE_IDENTIFIERS.productKey:
      return searchJobSite(hierarchyData, filters)
    default:
      return []
  }
}

export const getSelectedEntityName = (hierarchy, filters, product) => {
  if (!hierarchy || !filters) return ""
  const searchResults = searchHierarchyData(hierarchy, filters, product)
  if (!searchResults) return ""
  let entityName = "", entityType = ""
  switch (product.productKey) {
    case PROGRAMMATIC_IDENTIFIERS.productKey:
      entityName = searchResults.name[LOOKER_FILTER_KEYS.JOB_GROUP_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.CAMPAIGN_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.CLIENT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
      entityType = !!searchResults.name[LOOKER_FILTER_KEYS.JOB_GROUP_ID] ? "Job Group" :
        !!searchResults.name[LOOKER_FILTER_KEYS.CAMPAIGN_ID] ? "Campaign" :
          !!searchResults.name[LOOKER_FILTER_KEYS.CLIENT_ID] ? "Client" :
            !!searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ? "Sub Account" :
              !!searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] ? "Account" : ""
      break
    case CAREER_SITE_IDENTIFIERS.productKey:
      entityName = searchResults.name[LOOKER_FILTER_KEYS.SITE_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
      entityType = !!searchResults.name[LOOKER_FILTER_KEYS.SITE_ID] ? "Site" :
        !!searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ? "Sub Account" :
          !!searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] ? "Account" : ""
      break
    case CRM_IDENTIFIERS.productKey:
      entityName = searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
      entityType = !!searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ? "Sub Account" :
        !!searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] ? "Account" : ""
      break
    case UNIFIED_VIEW_IDENTIFIERS.productKey:
      entityName = searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
      entityType = !!searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ? "Sub Account" :
        !!searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] ? "Account" : ""
      break
    case JOB_SITE_IDENTIFIERS.productKey:
      entityName = searchResults.name[LOOKER_FILTER_KEYS.SITE_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ||
        searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] || ""
      entityType = !!searchResults.name[LOOKER_FILTER_KEYS.SITE_ID] ? "Site" :
        !!searchResults.name[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] ? "Sub Account" :
          !!searchResults.name[LOOKER_FILTER_KEYS.ACCOUNT_ID] ? "Account" : ""
      break
    default:
      entityName = ""
      entityType = ""
  }
  return { name: entityName, type: entityType }
}

export const searchHierarchyAcrossProducts = (hierarchy, filters) => {
  const results = searchHierarchyData(hierarchy, filters, PROGRAMMATIC_IDENTIFIERS)
  if (!results) {
    const results = searchHierarchyData(hierarchy, filters, CAREER_SITE_IDENTIFIERS)
    if (!results) {
      const results = searchHierarchyData(hierarchy, filters, CRM_IDENTIFIERS)
      if (!results) {
        const results = searchHierarchyData(hierarchy, filters, UNIFIED_VIEW_IDENTIFIERS)
        if (!results) {
          return null
        }
        return results
      }
      return results
    }
    return results
  }
  return results
}

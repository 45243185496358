import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.7rem;
  border: 1px solid #CED4DA;
  padding: 0.5rem;
  max-height: 5rem;
  border-radius: 0.5rem;
  width: 100%;
  position: relative;
`
export const MultiselectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex: 1;
  position: relative;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
  max-height: 5rem;
  overflow-y: scroll;
`
export const MultiselectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.7rem;
  border-radius: 1rem;
  background-color: #ECEFF1;
  color: #445A64;
  font-weight: 400;
  font-size: 0.8rem;
  cursor: default;
  word-break: break-word;

  img {
    cursor: pointer;
  }
`;
export const SearchInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  background-color: #FFFFFF;
`;

export const DropdownOptions = styled.div`
  padding: 0;
  max-height: 8rem;
  overflow: scroll;
  width: 100%;
  position: absolute;
  top: 100%;
  width: 100%;
  max-width: 14rem;
  left: 0;
  z-index: 2;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px #00000033;

  .Empty {
    background-color: #eceff1;
  }
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  cursor: pointer;
  background-color: #F6F6F6;

  &:hover {
    background-color: #edf2f7;
  }
`;
export const EmptySearchResult = styled.p`
  padding: 0.5rem;
  background-color: #F6F6F6;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fa6b2;
`;
export const OptionText = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  font-size: 0.8rem;
  font-weight: 400;
  color: #374151;
`;

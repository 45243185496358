import styled from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const NavbarFilterDropdownContainer = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px 0px #0000000D;
  box-shadow: 0px 10px 15px 0px #0000001A;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 27vw;
  width: ${props => props.type === "medium" ? "calc(48vw + 2.4rem)" : props.type === "small" ? "calc(20vw + 2.4rem)" : "51vw"};
  padding: 1.2rem;
  gap: 2rem;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 3.5rem;

  .Navbar__dropdown {
    display: flex;
  }

  .NavbarFilterDropdown__button {
    background-color: #FDFEFF;
    padding: 0.4rem 1rem;
  }

  .NavbarFilterDropdown__button-active {
    background-color: #E2E6F1;
    padding: 0.4rem 1rem;
  }

  .NavbarFilterDropdown__search {
    background-color: #F7FAFC;
    border: 1px solid #E2E6F1;
  }

  @media screen and ${devices.mobile} {
    .Navbar__dropdown {
      display: none;
    }
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.isSearching ? "flex-start" : "space-between"};
  gap: ${props => props.isSearching ? "1rem" : ""};
`
export const HeaderFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
`
export const SearchHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .Back__icon {
    cursor: pointer;
  }
`
export const SearchBoxContainer = styled.div`
  width: ${props => props.isSearching ? "100%" : "45%"};
  /* transition: width 0.3s linear; */
  justify-self: flex-end;
`
export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
`;
export const FilterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
`
export const FiltersContainer = styled.div`
  display: flex;
`
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
export const ViewSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* min-width: 40vw; */
  gap: 1rem;
  flex: 1;
`
export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;

  a {
    text-decoration: none;
  }
`;
export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  flex: 1;
`

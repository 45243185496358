import { useMutation, useQuery } from 'react-query';

import { getLookerData, getCIFFields, getReportsQuery } from '../services/apis';
import { ACCESS_TOKEN_LS_KEY, LOOKER_ACCESS_TOKEN_LS_KEY, LOOKER_FILTER_KEYS } from "../constants/AppConstants"
import { withTracing } from '../utils/helpers';
import { getItemFromLocalStorage } from '../helpers/commonHelper';

export const useFetchCampaignPerformanceData = (payload, filters) => {
  return useQuery(
    ['campaignPerformance', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && !!payload && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobPerformanceData = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )

  return useQuery(
    ['jobPerformance', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchCampaignPerformanceMetrics = (payload, filters) => {
  return useQuery(
    ['campaignMetrics', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: !!filters && !!payload && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchJobPerformanceMetrics = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )

  return useQuery(
    ['jobMetrics', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    }
  );
}

export const useFetchCIFFields = (clientId) => useQuery(
  ['cifFields', clientId],
  () => (
    getCIFFields(clientId)
  ),
  {
    staleTime: Infinity,
    retry: 1,
    enabled: !!clientId && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
  },
);

export const useFetchJobSponsorshipData = (payload, filters) => {
  const enabled = !!filters && !!payload

  return useQuery(
    ['jobSponsorship', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchExpansionsData = (payload, filters) => {
  const enabled = !!filters && !!payload;

  return useQuery(
    ['expansions', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchEventReportData = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )

  return useQuery(
    ['event', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchExpansionsMetrics = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )
  return useQuery(
    ['expansionsMetrics', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    }
  );
}

export const useFetchTotalStatsData = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )

  return useQuery(
    ['totalStats', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    },
  );
}

export const useFetchTotalStatsMetrics = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )
  return useQuery(
    ['totalStats', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    }
  );
}

export const useFetchUtmTags = (payload, filters) => {
  const enabled = !!filters && !!payload && (
    !!filters[LOOKER_FILTER_KEYS.ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.SUB_ACCOUNT_ID] &&
    !!filters[LOOKER_FILTER_KEYS.CLIENT_ID]
  )
  return useQuery(
    ['utmTags', { ...filters }],
    () => (
      withTracing(getLookerData, payload)
    ),
    {
      staleTime: Infinity,
      retry: 1,
      enabled: enabled && (!!getItemFromLocalStorage(ACCESS_TOKEN_LS_KEY) && !!getItemFromLocalStorage(LOOKER_ACCESS_TOKEN_LS_KEY))
    }
  );
}

export const useCreateReportQuery = () => {
  return useMutation(getReportsQuery);
}


import styled from "styled-components";

export const OptionContainer = styled.div`
  background: ${props => !!props.active ? "#E4E8FF" : "inherit"};
  padding: ${props => !props.isExpanded ? "0.8rem" : "0.8rem 1rem"};
  display: flex;
  align-items: center;
  justify-content: ${props => !props.isExpanded ? "center" : "flex-start"};
  gap: 1rem;
  cursor: pointer;
  border-radius: 0.4rem;
  position: relative;

  &:hover {
    background-color: ${props => !props.active ? "#EDF2F7" : "#E4E8FF"};
    border-radius: 0.4rem;
  }
`;

export const Text = styled.p`
  color: ${props => !!props.active ? "#303F9F" : "#3D4759"};
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
`
export const TextContainer = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 0.5s fadeIn;
`
export const DropdownDiv = styled.div`
  position: absolute;
  left: calc(100% + 0rem);
  top: 0;
  border-radius: 6px;
  border: var(--State, 1px) solid var(--Neutral-300, #e2e6f1);
  opacity: var(--State, 1);
  background: var(--White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  width: max-content;
  max-height: 40vh;
  overflow-y: auto;
  z-index: 2;
`;
export const DashboardDiv = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#DFE4FF" : "#FFFFFF")};
  padding: 0.5rem 0.75rem;
  margin: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${(props) => (props.selected ? "#DFE4FF" : "#EDF2F7")};
  }
`;
export const DashboardName = styled.span`
  color: ${(props) =>
    props.selected
      ? "var(--Neutral-800, #373F50)"
      : "var(--Neutral-600, #6A7185)"};
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  margin-left: 0.5rem;
`;

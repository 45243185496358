import React from 'react';

import {
  ReportsLoaderContainer,
  Title,
  Text,
  DownloadReport
} from "./styles";
import Icon from "../Icon"

const TITLE_TEXT = "Preparing your report preview..."
const DETAILED_TEXT = "Retrieving the report data may require a moment. Meanwhile, you can initiate the report download by clicking the button below."

const ReportsLoader = ({
  isDownloading,
  onDownload
}) => {

  const getDownloadButtonText = () => {
    if (isDownloading) {
      return (
        <span className="buttonTextContainer">
          <Icon className="loading-svg" name="loading" />
          <span className="buttonText">Downloading</span>
        </span>
      );
    }
    return (
      <span className="buttonTextContainer">
        <Icon name="download" />
        <span className="buttonText">Download</span>
      </span>
    );
  };

  return (
    <ReportsLoaderContainer>
      <Icon name="reports-loader-animated" type="gif" className="ReportsLoaderImage" />
      <Title>{TITLE_TEXT}</Title>
      <Text>{DETAILED_TEXT}</Text>
      <DownloadReport
        onClick={onDownload}
        disabled={isDownloading}
      >
        {getDownloadButtonText()}
      </DownloadReport>
    </ReportsLoaderContainer>
  );
}

export default ReportsLoader;
import styled from "styled-components";

export const ColumnSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  font-size: 1.1rem;
  padding: 1.5rem 0rem 1rem;
  font-weight: 600;
  color: #252f3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  color: #252f3f;
`;
export const Body = styled.div`
  padding: 1rem 0rem;
  display: flex;
  gap: 0.5rem;
  flex: 1;

  & > * {
    flex: 1;
    /* min-width: 50%; */
  }
`;
export const Footer = styled.div`
  padding: 1.2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const EntityListContainer = styled.div`
  flex: none;
`;
export const EntityList = styled.div``;
export const EntityListItem = styled.div`
  color: ${(props) =>
    props.isSelected ? "var(--Indigo-700, #3A4BC9)" : "#475569"};
  border-radius: ${(props) => (props.isSelected ? "0.25rem" : 0)};
  background-color: ${(props) => props.isSelected && "#F2F3FF"};
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const EntityCount = styled.span`
  border-radius: 2px;
  background-color: ${(props) =>
    props.isSelected
      ? "var(--Primary-400, #3a4bc9)"
      : "var(--Gray-06, #64748B)"};
  color: var(--White, #fff);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  display: flex;
  padding: 0px 0.25rem;
  align-items: center;
  gap: 0.6rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

export const EntityCountContainer = styled.div`
  display: flex;
`;

import styled from "styled-components";

import { getIconUrl } from "../../../constants/ApiRoutes";

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    background-image: url(${getIconUrl("uncheck")});
    height: 1rem;
    width: 1rem;
    display: inline-block;
  }

  input[type="checkbox"]:checked + label {
    background-image: ${(props) =>
    props.isPreSelected ? `url(${getIconUrl("preSelectedCheckbox")})` : `url(${getIconUrl("checked")})`};
    height: 1rem;
    width: 1rem;
    display: inline-block;
  }
`;

export const Label = styled.label`
  padding: 1rem;
  margin-left: -0.4rem;
  display: flex;
  margin-right: 0.2rem;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: initial;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: #ededed;
    border-radius: 50%;
  }
`;

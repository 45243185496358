import styled from 'styled-components';
import { devices } from '../../../constants/AppConstants';

export const TooltipContainer = styled.div`
    position: relative;
    display: inline-flex;

    @media screen and ${devices.mobile} {
        width: 100%;
        overflow: hidden;
    }
    

    .content-bottom{
        bottom: unset;
        top: calc(100% + 5px);
    }
    .content-left{
        margin-left: 0;
        width: 100%;
        left: unset;
        top: 50%;
        right: calc(100% + 5px);
        width: max-content;
    }
    .content-right{
        margin-left: 0;
        width: 100%;
        top: 50%;
        left: calc(100% + 5px);
        width: max-content;
    }
    .content-top{
        bottom: calc(100% + 5px);
    }

    .box-bottom{
        &::after{
            border-color: transparent transparent #353535 transparent;
            top: unset;
            width: 1px;
            bottom: 100%;
            left: calc(50% - 5px);
        }
    }
    .box-left{
        &::after{
            border-color: transparent transparent transparent #353535;
            left: 100%;
            top: calc(50% - 5px);
        }
    }
    .box-right{
        &::after{
            border-color: transparent #353535 transparent transparent;
            right: 100%;
            left: unset;
            top: calc(50% - 5px);
        }
    }
    .box-top{
        &::after{
            top: 100%;
        }
    }
`;
export const TooltipTarget = styled.div`
    color: inherit;
    cursor: inherit;
    display: flex;

`;
export const TooltipContent = styled.span`
    position: absolute;
    left: 50%;
    bottom: calc(100% + 5px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-left: -100px;
    pointer-events: none;
`
export const Box = styled.div`
    position: relative;
    background-color: #353535;
    color: #FFFFFF;
    text-align: center;
    border-radius: 4px;
    padding: 6px;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 3;

    &::after{
        position: absolute;
        left: calc(50% - 4.5px);
        top: 100%;
        content: '';
        width: 1px;
        height: 1px;
        border: 5px solid #353535;
        border-color: #353535 transparent transparent transparent;
    }
`;

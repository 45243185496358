import React, { useCallback, useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../../utils/useOnClickOutside";
import {
  ApplyButton,
  ButtonsWrapper,
  CancelButton,
  CheckboxContainer,
  DropdownControl,
  DropdownMenu,
  DropdownOptions,
  DropdownSearch,
  DropdownTitle,
  MultiSelectDropdownContainer,
  Option,
  SearchInput,
  TitleText,
  DropdownSubText,
  EmptySearchResult,
  OptionText,
  Footer,
  Submit,
  DropdownControlText,
} from "./styles";

import Icon from "../Icon";
import Checkbox from "../Checkbox";

const MultiSelectDropdown = ({
  options,
  title = "",
  selected,
  idKey = "id",
  valueKey = "value",
  placeholder = "Search...",
  className = "",
  onChange,
  customOptionsContainerStyle = {},
  customOptionStyle = {},
  dropdownSubtextVisible,
  isLastChangedFilter = false,
  isMultiple = false,
  submitButton = false,
  displayFormatter = (value) => value?.[valueKey],
  isDisabled = false,
  dropdownButtonStyle = {},
  dropdownMenuStyle = {},
  activeDropdown = false,
  displayFormatterHtml,
  style = {}
}) => {
  const initialSelectedOptionIdList = selected.split(",");

  const [isDropdownActive, toggleDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptionIdList
  );
  const [searchValue, setSearchValue] = useState("");

  const wrapperRef = useRef(null);

  const allOptionsRef = useRef(null);

  const isSomeOptionsSelected = useCallback(() => {
    if (selectedOptions?.length === options.length) {
      return false;
    }

    if (selectedOptions?.length === 0) {
      return false;
    }

    return true;
  }, [selectedOptions?.length, options?.length, searchValue]);

  useEffect(() => {
    if (allOptionsRef.current) {
      const someOptionSelected = isSomeOptionsSelected();
      if (someOptionSelected) {
        allOptionsRef.current.indeterminate = true;
      } else {
        allOptionsRef.current.indeterminate = false;
      }
    }
  }, [isSomeOptionsSelected, isDropdownActive]);

  useEffect(() => {
    const allSelected = selected
      .split(",")
      .some((selectedOption) => selectedOption === "");
    if (allSelected) {
      const allOptionsIdList = options?.map((option) => option[idKey]);
      setSelectedOptions(allOptionsIdList);
    } else {
      setSelectedOptions(selected.split(","));
    }
  }, [options, selected, idKey, isDropdownActive]);

  const handleOptionClick = (option) => {
    if (!isMultiple) {
      setSelectedOptions([option]);
      onChange(option[idKey]);
      closeDropdown();
      return;
    }

    const isOptionSelected = selectedOptions.some(
      (selectedOption) => selectedOption === option[idKey]
    );

    const selectedOption = options.find(
      (selectedOption) => selectedOption[idKey] === option[idKey]
    );

    if (isOptionSelected) {
      if (selectedOption[idKey] === "") {
        setSelectedOptions([]);
      } else {
        const updatedOptions = selectedOptions.filter(
          (selectedOption) =>
            selectedOption !== option[idKey] && selectedOption !== ""
        );
        setSelectedOptions(updatedOptions);
      }
    } else {
      if (selectedOption[idKey] === "") {
        const allOptionsIdList = options.map((option) => option[idKey]);
        setSelectedOptions(allOptionsIdList);
      } else {
        const updatedSelectedOptions = selectedOptions.concat(option[idKey]);
        if (
          options.length > 1 &&
          updatedSelectedOptions.length === options.length - 1
        ) {
          const allOptionsIdList = options.map((option) => option[idKey]);
          setSelectedOptions(allOptionsIdList);
        } else {
          setSelectedOptions(updatedSelectedOptions);
        }
      }
    }
  };

  const handleApply = () => {
    onChange(selectedOptions.join(","));
    closeDropdown();
  };

  const handleCancel = () => {
    setSelectedOptions(selected.split(","));
    closeDropdown();
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredOptions = !!searchValue?.length
    ? options.filter((option) => {
      return option[valueKey]
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    })
    : options;

  const closeDropdown = () => {
    toggleDropdown(false);
    setSearchValue("");
  };

  const isApplyButtonDisabled = () => selectedOptions.length === 0;

  useOnClickOutside(wrapperRef, () => {
    if (isDropdownActive) {
      closeDropdown();
    }
  });

  const isDropdownSubTextVisible = () => {
    return selected.split(",").every((option) => !!option);
  };

  const isOptionSelected = (option) => {
    return selectedOptions.some(
      (selectedOption) => selectedOption === option[idKey]
    );
  };

  const getDropdownControlText = () => {
    const selectedIdList = selected.split(",");
    const firstSelectedDropdownItem =
      options?.length &&
      options.find((option) => option[idKey] === selectedIdList[0]);
    if (!!displayFormatterHtml) {
      return displayFormatterHtml(firstSelectedDropdownItem);
    }
    return firstSelectedDropdownItem?.[valueKey]?.length > 25
      ? `${displayFormatter(firstSelectedDropdownItem).substring(0, 25)}...`
      : `${displayFormatter(firstSelectedDropdownItem)} ${selectedIdList.length > 1 && isDropdownSubTextVisible()
        ? `+${selectedIdList.length - 1}`
        : ""
      }`;
  };

  const handleDropdownControlClick = () => {
    if (isDisabled) {
      return;
    }
    toggleDropdown(!isDropdownActive);
  };

  return (
    <MultiSelectDropdownContainer className={className ? className : ""} ref={wrapperRef} style={style}>
      {dropdownSubtextVisible ? (
        <DropdownSubText>{title}</DropdownSubText>
      ) : null}
      <DropdownControl
        active={isDropdownActive && !isDisabled}
        isDisabled={isDisabled}
        onClick={() => handleDropdownControlClick()}
        style={dropdownButtonStyle}
        activeDropdown={activeDropdown}
      >
        <DropdownControlText
          className={isLastChangedFilter ? "last-changed-filter" : ""}
        >
          {getDropdownControlText()}
        </DropdownControlText>
        {!isDisabled && <Icon name="arrow-down-solid" />}
      </DropdownControl>
      {isDropdownActive && (
        <DropdownMenu
          className={dropdownSubtextVisible ? "active-dropdown" : ""}
          style={dropdownMenuStyle}
        >
          <DropdownTitle>
            <TitleText>{title}</TitleText>
            <Icon onClick={closeDropdown} name="cross" />
          </DropdownTitle>
          <DropdownSearch>
            <Icon name="search" height="14" width="14" />
            <SearchInput
              type="text"
              autoFocus={true}
              placeholder={placeholder}
              value={searchValue}
              onChange={handleInputChange}
            />
          </DropdownSearch>
          <DropdownOptions style={customOptionsContainerStyle}>
            {!!filteredOptions?.length > 0 ? (
              filteredOptions.map((option, index) => (
                <Option
                  key={index}
                  style={customOptionStyle}
                  onClick={() => handleOptionClick(option)}
                >
                  {isMultiple ? (
                    <CheckboxContainer>
                      <Checkbox
                        key={option[idKey]}
                        id={option[idKey]}
                        label={displayFormatter(option)}
                        checked={isOptionSelected(option)}
                        onChange={() => handleOptionClick(option)}
                      />
                    </CheckboxContainer>
                  ) : (
                    <OptionText>{displayFormatter(option)}</OptionText>
                  )}
                </Option>
              ))
            ) : (
              <EmptySearchResult>No results found</EmptySearchResult>
            )}
          </DropdownOptions>
          {isMultiple ? (
            <ButtonsWrapper
              className={filteredOptions.length === 0 && "hidden"}
            >
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              <ApplyButton
                onClick={handleApply}
                className={isApplyButtonDisabled() && "disabled"}
                disabled={isApplyButtonDisabled()}
              >
                Apply
              </ApplyButton>
            </ButtonsWrapper>
          ) : null}
        </DropdownMenu>
      )}
      {submitButton && (
        <Footer>
          <Submit onClick={handleApply}>DONE</Submit>
        </Footer>
      )}
    </MultiSelectDropdownContainer>
  );
};

export default MultiSelectDropdown;

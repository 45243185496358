import styled from 'styled-components';

import { ButtonContainer } from '../Button/styles';

export const SaveOrScheduleContainer = styled.div`
  position: relative;
  display: inline-block;

  ${ButtonContainer} {
    flex-direction: row-reverse;
    padding: 0.5rem 0.8rem;
    height: 100%;
  }

  .SaveFilterModal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 100%;
`;

export const DropdownItem = styled.div`
  padding: 0.8rem 1rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3D4759;
  font-size: 0.9rem;
  font-weight: 400;
  gap: 0.5rem;

  img {
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }

  &:hover {
    background: #f5f5f5;
  }
`;
export const DropdownItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const SubDropdownContent = styled(DropdownContent)`
  left: 100%;
  top: 0;
`;

import styled from "styled-components";
import { UNIFIED_ANALYTICS_CLOUDFRONT_URL } from "../../../constants/ApiRoutes";
import { devices } from "../../../constants/AppConstants";

export const ScoreModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-height: 50vh;
  width: 50vw;
  height: 100%;
  background: #FFF;
  border-radius: 1rem;
  position: relative;
  padding: 1rem;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    opacity: 0.3;
    background-image: url(${`${UNIFIED_ANALYTICS_CLOUDFRONT_URL}/images/BgLines.svg`});
    background-repeat: no-repeat;
    background-position: 25% 0;
    background-size: cover;
  }

  @media only screen and ${devices.mobile} {
    min-height: 60vh;
    width: 80vw;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 2;
`
export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: ${props => props.done ? "14vh auto auto auto" : "auto auto auto auto"};
  gap:  ${props => props.done ? "0" : "1.2rem"};
  align-items: ${props => props.done ? "flex-end" : "center"};
  justify-content: center;
  z-index: 2;

  img {
    justify-self: center;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top:  ${props => props.done ? "2rem" : "0"};;
`
export const Title = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${props => props.done ? "#008843" : "#000000"};
  text-align: center;
`
export const Text = styled.h3`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${props => props.done ? "#373F50" : "#929AAF"};
`
export const TextLine = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`
export const Bullet = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #E2E6F1;
`
export const ScoreText = styled.h2`
  font-weight: 700;
  font-size: 4rem;
  color: #374151;
  text-align: center;
`
export const Button = styled.button`
  background: #283593;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.3rem;
  border: none;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  justify-self: center;
  margin-top: 2rem;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 2rem;
`
export const ErrorContainer = styled.div`
  display: grid;
  grid-template-rows: 20vh auto auto;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`
export const ErrorTitle = styled.h3`
  color: #000000;
  font-weight: 600;
  font-size: 1.5rem;
  padding-top: 1rem;
  text-align: center;
`
export const ErrorText = styled.h5`
  color: #929AAF;
  font-weight: 400;
  font-size: 1.1rem;
  padding: 0 4rem;
  line-height: 1.5rem;
  text-align: center;
`

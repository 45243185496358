import styled, { css } from "styled-components";
import { DEVICE_SIZES_PX } from "../../../constants/AppConstants";

export const TabFilterContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  border: 1px solid #d0d5dd;
  border-radius: 0.3rem;
  overflow-x: scroll;
`;
export const Option = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-right: 1px solid #d0d5dd;
  background-color: ${(props) => (props.active ? "#F4F5F7" : "#FFFFFF")};
  text-align: center;
  white-space: nowrap;
  flex: 1;

  &:hover {
    background-color: #edf2f7;
  }

  &:first-child {
    border-radius: 0.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-radius: 0.3rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 0;
  }

  ${(props) =>
    !!props.screenWidth &&
    props.screenWidth < DEVICE_SIZES_PX.MOBILE &&
    css`
      padding: 0.5rem 1rem 1rem;
    `}
`;
export const Text = styled.span`
  color: ${(props) => (props.active ? "#1D2939" : "#344054")};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: 0.9rem;
`;

import styled, { css } from "styled-components";
import { devices } from "../../../constants/AppConstants";

export const JobRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const EmptyContainer = styled.div`
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9fa6b2;
  }
`;
export const RowContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e6f1;
  cursor: pointer;

  .JobRow__arrow {
    transform: ${(props) =>
    props.isOpen ? "rotate(90deg)" : "rotate(270deg)"};
  }

  @media screen and ${devices.mobile} {
    gap: 1.5rem;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const TitleText = styled.h5`
  font-size: 1.2rem;
  font-weight: 700;
  color: #334155;

  ${(props) =>
    !!props.isTitle &&
    css`
      @media screen and ${devices.mobile} {
        font-size: 1rem;
        font-weight: 600;
        gap: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    `}
`;
export const LabelContainer = styled.div`
  background: #64748b;
  padding: 0.3rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 0.2rem;
  font-size: 0.6rem;
`;
export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;

  @media screen and ${devices.mobile} {
    gap: 0.5rem;
  }
`;
export const TableContainer = styled.div`
  height: ${(props) => (props.isOpen ? "100%" : "0")};
  transition: height 0.2s linear;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: clip;

  thead,
  tr,
  th {
    position: static !important;
  }

  table {
    border-collapse: collapse;
    border-radius: 0.3rem;
  }

  .JobRow__table {
    border-radius: 0.3rem;
  }
  .Table__title {
    color: #6a7185;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    background: #f7f8fa;
    letter-spacing: 1px;
    text-align: left;
  }
  .Table__text {
    color: #334155;
    font-size: 0.9rem;
    font-weight: 400;
  }
  .Table__score {
    color: #373f50;
    font-size: 1rem;
    font-weight: 700;
  }
  .Table__link {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
    text-align: center;
    height: 1rem;
    width: 1rem;

    &:hover,
    &:active {
      opacity: 1;
    }
  }
  .Table__link-disabled {
    cursor: not-allowed;
    opacity: 0.3;
    transition: none;

    &:hover,
    &:active {
      opacity: 0.3;
    }
  }

  .right-aligned {
    text-align: right;
    padding-right: 0.8rem;
  }
  td {
    background: #fbfdff !important;
    padding: 1rem 1.4rem;
  }
  th {
    padding: 0;
    border-top-right-radius: 0.3rem;
  }
`;
export const GetScoreButton = styled.button`
  background: #ffffff;
  border: 1px solid #727ed9;
  border-radius: 0.3rem;
  padding: 1rem 0.8rem;
  color: #3a4bc9;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  /* border: 1px solid #edf2f7; */
`;

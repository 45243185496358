import React from 'react';

import {
  SectionContainer,
  Header,
  Title,
  Line,
  Body
} from "./styles";

import Option from './Option';

const Section = ({
  title,
  options,
  active,
  expanded,
  onSelectExpandedOption,
  onChange
}) => {
  return (
    <SectionContainer>
      <Header>
        {
          !!expanded && <Title>{title}</Title>
        }
        <Line />
      </Header>
      <Body isExpanded={expanded}>
        {
          !!options?.length && (
            options.map(option => (
              <Option
                key={option.title}
                icon={option.icon}
                hoverIcon={`${option.icon}-active`}
                text={option.title}
                active={option?.title === active?.title}
                expandable={!!option?.expandable}
                expandableOptions={option?.expandableOptions || []}
                isLocked={option?.isLocked || false}
                onSelectExpandedOption={onSelectExpandedOption}
                isExpanded={expanded}
                onClick={() => onChange(option)}
              />
            ))
          )
        }
      </Body>
    </SectionContainer>
  );
}

export default Section;

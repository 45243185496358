import styled from "styled-components";
import { devices } from "../../constants/AppConstants";

export const AdvancedFilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;

  .SearchFilter {
    position: absolute;
    top: 130%;
    /* max-width: 18rem; */
    min-width: 18rem;
    z-index: 2;
  }

  .ExtendedFilter {
    position: absolute;
    top: 130%;
    max-width: 20rem;
    min-width: 18rem;
    z-index: 2;
  }

  .Multiselect {
    position: absolute;
    top: 130%;
    max-width: 20rem;
    min-width: 18rem;
    z-index: 2;
    background-color: #FFFFFF;
  }

  .FilterIcon {
    padding-top: 0.6rem;
  }
`
export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  flex-wrap: wrap;

  @media only screen and ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const MoreFilter = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #283593;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  padding: 0.5rem 0.8rem;
  border-radius: 0.4rem;
  background-color: ${props => props.active ? "#EBEDFF" : "initial"};

  &:hover {
    background: #EBEDFF;
  }
`
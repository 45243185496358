import React, { useState } from 'react';

import {
  ReportDownloadFormContainer,
  Header,
  Body,
  Footer,
  Label,
  InputContainer,
  Selections,
} from "./styles"

import Icon from '../../../components/common/Icon';
import Button from '../../../components/common/Button';
import Dropdown from '../../../components/common/Dropdown';

export const REPORT_FORMAT_OPTIONS = [
  {
    name: "CSV",
    value: "csv"
  },
  {
    name: "XLS",
    value: "xlsx"
  }
]

const ReportDownloadForm = ({
  onClose,
  onSubmit
}) => {
  const [formState, setFormState] = useState({
    format: REPORT_FORMAT_OPTIONS[0]
  })

  const handleSubmit = () => {
    onSubmit(formState.format.value)
    onClose()
  }

  return (
    <ReportDownloadFormContainer>
      <Header>
        <span>Download Report</span>
        <Icon name="close" width="14" height="14" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>
      <Body>
        <Selections>
          <InputContainer>
            <Label>Format</Label>
            <Dropdown
              options={REPORT_FORMAT_OPTIONS.map(item => ({ ...item, id: JSON.stringify(item) }))}
              selected={formState.format}
              valueKey="name"
              className="DownloadSchedule__dropdown"
              onChange={(opt) => {
                setFormState(form => ({
                  ...form,
                  format: opt
                }))
              }}
              customOptionsContainerStyle={{ maxHeight: "8vh", width: "100%" }}
              dropdownButtonStyle={{ width: "100%", justifyContent: "space-between" }}
            />
          </InputContainer>
        </Selections>
      </Body>
      <Footer>
        <Button
          text="Cancel"
          onClick={onClose}
        />
        <Button
          text="Download"
          type="submit"
          onClick={handleSubmit}
        />
      </Footer>
    </ReportDownloadFormContainer>
  );
}

export default ReportDownloadForm;
